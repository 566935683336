import bootbox from 'bootbox'; // Assuming bootbox is being used in the project

const confirmDeletion = (title, message, onConfirmAction) => {
    bootbox.confirm({
      title: title,
      message: message,
      buttons: {
        cancel: {
          label: 'NO',
          className: 'btn btn-sm bg-Red ffPoppins text-white'
        },
        confirm: {
          label: 'YES',
          className: 'btn btn-sm bg-Green ffPoppins text-white'
        }
      },
      callback: function (result) {
        if (result) {
          onConfirmAction();
        }
      }
    });
  };


  export default confirmDeletion
