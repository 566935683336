import React from 'react'

export const RatesComp = ({rate}) => {
const {name , rateType ,amount,amountType ,per,key} = rate
  return (
    <div className="pb-1 boxShadowRate mb-4 boxInnerMargin" key={key}>
        <div className="col-md-12 flex p-0">
          <div className="col-md-5 col-sm-3 mb-2 pl-0">
            <label className="mb-0 labelFs14">Name</label>
            <p className="mb-0 inputFs14 lastTicketMsg">{name}</p>
          </div>
          <div className="col-md-2 col-sm-3 mb-2 pl-0">
            <label className="mb-0 labelFs14">Type</label>
            <p className="mb-0 inputFs14 lastTicketMsg">{rateType}</p>
          </div>
          <div className="col-md-2 col-sm-3 mb-2 pl-0">
            <label className="mb-0 labelFs14">Amount</label>
            <p className="mb-0 inputFs14 lastTicketMsg">{amountType}{amount}</p>
          </div>
          <div className="col-md-3 col-sm-3 mb-2 pl-0">
            <label className="mb-0 labelFs14">Per Item</label>
            <p className="mb-0 inputFs14 lastTicketMsg">{per}</p>
          </div>
        </div>
    </div>
  )
}
