import React, { useState } from 'react'
import { AccessDenied } from '../../../components/AccessDenied';
import { useMutation, useQuery } from '@apollo/client';
import { GET_RATES } from '../../../../graphql/query/RatesQuery';
import ReactPaginate from 'react-paginate';
import { GetRates } from './GetRates';
import { InserUpdateRates } from './InserUpdateRates';
import * as $ from 'jquery';
import Loader from '../../../components/Loader/Loader';

export const Rates = () => {
  const genSettings = JSON.parse(localStorage.getItem('generalSettings'));
  const user = JSON.parse(localStorage.getItem('loggedUser'));
  const [rate, setRates] = useState({
    temp_amountType: '',
    amountColorError: null,
    temp_per: '',
    perColorError: null,
    temp_rateType: '',
    rateColorError: null,
    openModal: 'Add New ',
    limit: genSettings?.paginationLimit || 10,
    pageNo: 0,
    filter: "",
    filterText: '',
    sortKey: 'createdAt',
    sortType: -1,
    id: null,
    rateData: {},
    Old_Data: null,
  });

  const { data, loading, refetch } = useQuery(GET_RATES, {
    variables: { page: rate.pageNo + 1, "limit": rate.limit, "filter": rate.filter, "sort": { key: rate.sortKey, type: rate.sortType } },
    fetchPolicy: 'cache-and-network'
  })


  const addRate = (e) => {
    e.preventDefault();
    setRates(prevRates => ({
      ...prevRates, openModal: 'Add New',
      temp_amountType: '',
      amountColorError: null,
      temp_per: '',
      perColorError: null,
      temp_rateType: '',
      rateColorError: null,
      rateData: {}
    }))
    $('#frm_Rate').removeClass('was-validated');
    $('#frm_Rate')[0].reset();
    $('#RateModal').modal('show');
  }

  const handleSearch = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      searchRate()
    }
  }
  const searchRate = () => {
    setRates(prevRates => ({ ...prevRates, pageNo: 0, filter: rate.filterText }))
  }
  const onResetSearch = () => {
    setRates(prevRates => ({ ...prevRates, pageNo: 0, filter: "", filterText: "" }))
  }

  const handlePageClick = (data) => {
    setRates(prevRates => ({ ...prevRates, pageNo: data?.selected }))
  }

 
  if (user.roles === 'standard')
    return <AccessDenied />
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8 p-0">
          <div className="mb-20">
            <p className="mainTitle m-0 mt_-15">Rates List</p>
            <span className="borderBottomOfTitle"></span>
          </div>
        </div>
        <div className="col-md-4 p-0">
          <div className="float-right">
            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addRate(e)}> <i className="fa fa-plus"></i> Add New Rates</button>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-12 p-0">
          <div className="ibox">
            <div className="ibox-title">
              <span>Rates Details</span>
            </div>
            <div className="ibox-content p-0">
              {loading && <Loader />}
              <div className="col-md-12 ph-20 mt-3">
                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                    <label className="frm-label">Search</label>
                    <input type="text" className="form-control" value={rate.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setRates((prevRates => ({ ...prevRates, filterText: e.target.value })))} />
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="mt-30">
                      <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchRate()} >Search</button>
                      <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                      <div className="pull-right">
                        <ReactPaginate
                          previousLabel={'Back'}
                          nextLabel={'Next'}
                          pageCount={data?.getRates && Math.ceil(data?.getRates?.count / rate?.limit)}
                          marginPagesDisplayed={2}
                          pageRangeDisplayed={5}
                          initialPage={0}
                          forcePage={rate.pageNo}
                          onPageChange={(data) => handlePageClick(data)}
                          containerClassName={'pagination mb-0'}
                          subContainerClassName={'page-item'}
                          activeClassName={'active'}
                          pageLinkClassName={'pagination-link'}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <GetRates rateData={data?.getRates} rate={rate} setRates={setRates} refetch={refetch} />
            </div>
          </div>
        </div>
      </div>

      <div className="modal show" id="RateModal" data-backdrop="static" tabIndex="-1" role="dialog" >
        <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
          <div className="modal-content ">
          <InserUpdateRates rate={rate} setRates={setRates} refetch={refetch}/>
          </div>
        </div>
      </div>

    </div>
  )
}
