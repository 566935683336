import React from 'react'
import { DELETE_STATE } from '../../../../graphql/mutation/StateMutation';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import { statusType } from '../../../components/Constant';
import confirmDeletion from '../../../components/Common/BootBox';
import { Link, useNavigate } from 'react-router-dom';

export const GetState = ({ state, setStateData, states, refetch }) => {
  const navigate = useNavigate()
  const [deleteState] = useMutation(DELETE_STATE)
  const sortData = (sortKey) => {
    const newSortType = (state.sortType === 1) ? -1 : 1;
    setStateData((prevBins) => ({ ...prevBins, sortKey: sortKey, sortType: newSortType }));
  };

  const editState = (e, oldState) => {
    e.preventDefault();
    setStateData(prevCountry => ({
      ...prevCountry,
      id: oldState.id,
      name: oldState.name,
      countryId: oldState?.countryId?.id,
      isActive: oldState.isActive,
      status: oldState.isActive ? statusType[0] : statusType[1],
      Old_Data: { name: oldState?.name, countryId: oldState?.countryId?.id, isActive: oldState?.isActive },
      openModal: 'Edit'
    }))
    $('#frm_state').removeClass('was-validated');
    $('#StateModal').modal('show');
  }
  const deleteStateDetails = (e, id) => {
    e.preventDefault()
    const deleteStateDetails = () => {
      let log = {
        action: 'DELETE',
        actionOn: 'states',
        actionName: 'deleteState',
        oldValue: ""
      }
      deleteState({ variables: { 'id': id, 'log': log } }).then(async ({ data }) => {
        refetch();
        toast.success("State deleted successfully!", {
          autoClose: 2000
        })
      }).catch((err) => {
        toast.error(err.message, {
          autoClose: 2000
        })
      })
    }
    confirmDeletion("Delete State", "Are you sure want to delete this record ?", deleteStateDetails)
  }
  return (
    <div className="table-responsive">
      <table className="table themeTable table-nowrap mb-0">
        <thead>
          <tr>
            <th className="text-center">No</th>
            <th className={`sortColumn ${(state.sortKey === 'name') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
              onClick={(e) => sortData('name')}>Name</th>
            <th>Country</th>
            <th className={`sortColumn ${(state.sortKey === 'isActive') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
              onClick={(e) => sortData('isActive')}>Status</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {
            states && states.data && states.data.length > 0
              ? states && states.data.map((d, i) => {
                return (
                  <tr key={d?.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editState(e, d)}>
                    <td className="text-center">{i + 1}</td>
                    <td>
                      <span onClick={() => {navigate('/closest-city',{state: { stateData: d } })}} className="textDecorationNone text-primary">
                        {d.name}
                      </span>
                    </td>
                    <td>{d.countryId.name}  ||  {d.countryId.code}</td>
                    <td>
                      {
                        d.isActive
                          ? <span className="statusStyle activeCustomer">Active</span>
                          : <span className="statusStyle inActiveCustomer">InActive</span>
                      }
                    </td>
                    <td>
                      <div className="d-flex flex-row">
                        <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editState(e, d)}>
                          <img src="../images/edit.png" alt="Img" className="width15" />
                        </span>

                        <span className="btn BoxImg boxBackColorRed rounded mr-2"
                          onClick={(e) => { deleteStateDetails(e, d.id); }}>
                          <img src="../images/bin.png" alt="Img" className="width15" />
                        </span>

                      </div>
                    </td>
                  </tr>
                )
              })
              :
              <tr>
                <td style={{ textAlign: 'center' }} colSpan={5} >No Records Found!</td>
              </tr>
          }
        </tbody>
      </table>
    </div>
  )
}
