import { gql } from "@apollo/client";

export const INSERT_UPDATE_BIN = gql`
  mutation insertUpdateBin($input: BinInput!, $log: LogInput){
    insertUpdateBin(input: $input, log: $log){
      id
      bin
      isFree
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_BIN = gql`
  mutation deleteBin($id: ID!, $log: LogInput){
    deleteBin(id: $id, log: $log){
      id
      bin
      isFree
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const RELEASE_BIN = gql`
mutation releaseBin{
  releaseBin
}
`