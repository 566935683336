import { gql } from "@apollo/client";

export const DELETE_ORDER_ITEM = gql`
mutation deleteOrderItem($id: ID!, $itemId: ID!, $log: LogInput){
    deleteOrderItem(id:$id, itemId: $itemId, log: $log){
      id
      customerId
        customerInfo{
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      trackingno
      orderRateIds
      binId
      binNum
      invoiceOrReceipt
      items{
        id
        description
        instruction
        qty
        price
        total
        isInsure
        insurerate
        insureprice
        itemStatus
        rate
        itemRate
        isPacked
      }
      receiverId
      receiverAddress{
        name
        phone
        address1
        address2
        city
        closestCityId{
          id
          name
        }
        stateId{
          id
          name
        }
        countryId{
          id
          name
        }
        zipCode
      }
      status
      shipmentId
      orderFromSms
      remark
      invoiceId
      isInvoice
      isRecreateInvoice
      insurePer
      statusDate
      isPromoCode
      countryId
      stateId
      isPayAmount
      isDeleted
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }`;