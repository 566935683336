import { gql } from "@apollo/client";
export const GET_HOW_IT_WORKS = gql`
  query getHowItWorks {
    getHowItWorks {
      data {
        id
        stepNo
        label
        details
      }
      generalSettings {
        id
        verifiedUserCharge
        orderInsurancePercentage
        paginationLimit
        appVersionAndroid
        appVersionIos
        headeOffAddress1
        headeOffAddress2
        headeOffCountry
        headeOffState
        headeOffCity
        headeOffzipcode
        headeOffCustomerServiceEmail
        headeOffCustomerServicePhone
        headeOffWeb
        headeOffWorkingHrs
        amcWhouseAddress1
        amcWhouseAddress2
        amcWhouseCountry
        amcWhouseState
        amcWhouseCity
        amcWhousezipcode
        amcWhousePhone
        amcWhouseDimensiopnCalc
        amcWhouseWorkingHrs
        lagosOffAddress1
        lagosOffAddress2
        lagosOffCountry
        lagosOffState
        lagosOffCity
        lagosOffzipcode
        lagosOffPhone
        lagosOffWorkingHrs
        orderReceiptAuth
        terms
        prohibitedList
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;