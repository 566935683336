import React from 'react';
import { useMutation } from '@apollo/client';
import bootbox from 'bootbox';
import { toast } from 'react-toastify';
// import { DELETE_RECEIVER } from './AddressMutation';
import * as $ from 'jquery';
import { DELETE_RECEIVER } from '../../../graphql/mutation/AddressMutation';
import confirmDeletion from '../Common/BootBox';

const AddressPanel = (props) => {

    const [deleteReceiver] = useMutation(DELETE_RECEIVER)

    const editReceiverData = (data) => {
        props.editReceiverAddress(data);
    };

    const editOrderData = (data) => {
        props.editOrderAddress(data);
    };

    const deleteOrderData = () => {
        props.deleteSelectedAddress();
    };

    const deleteReceiverData = (e, addressId) => {
        e.preventDefault();
        $('body').addClass('modal-open-delete');
        const onDeleteReceiverAction = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'receivers',
                actionName: 'deleteReceiver',
                oldValue: ""
            };
            deleteReceiver({ variables: { "id": addressId, "log": log } })
                .then(async ({ data }) => {
                    props.refetch();
                    toast.success("Receiver address deleted successfully!", { autoClose: 2000 });
                })
                .catch((err) => {
                    toast.error(err.message, { autoClose: 2000 });
                });
        };
        confirmDeletion("DELETE RECEIVER ADDRESS", "Are you sure want to delete this record ?", onDeleteReceiverAction);
    };

    const { receiverCustomerData, isCustomer, isOrder, curdType, receiverId, getReceiverCustomer } = props;
    const defaultChecked = receiverId ? (receiverId === receiverCustomerData?.id) : (getReceiverCustomer.length > 0 && getReceiverCustomer[0].id === receiverCustomerData.id);

    return (
        <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12">
            <div className="card mb-4">
                <div className="card-header p-2 font-weight-bold ffPoppins">
                    {!isCustomer && isOrder && (
                        <input
                            type="radio"
                            className="mr-2"
                            name="defaultExampleRadios"
                            id={receiverCustomerData.id}
                            onChange={() => props.selectedReceiverData(receiverCustomerData)}
                            defaultChecked={defaultChecked}
                        />
                    )}
                    <label className="mb-0" htmlFor={receiverCustomerData.id}>
                        {receiverCustomerData.name}
                    </label>
                </div>
                <div className="card-body ffPoppins">
                    <div>
                        <span className="receiverAdd">
                            {receiverCustomerData.address1}
                            {receiverCustomerData.address1 && receiverCustomerData.address2 && ', ' + receiverCustomerData.address2}
                            {(receiverCustomerData.address1 || receiverCustomerData.address2) && receiverCustomerData.city && ', ' + receiverCustomerData.city}
                            {receiverCustomerData.stateId && ', ' + receiverCustomerData.stateId.name}
                            {receiverCustomerData.countryId && ', ' + receiverCustomerData.countryId.name}
                            {receiverCustomerData.zipCode && ', ' + receiverCustomerData.zipCode}
                        </span>
                    </div>
                    <div className="row d-flex float-right">
                        {!isCustomer && !isOrder && curdType === "Edit" && receiverId ? (
                            <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editOrderData(receiverCustomerData)}>
                                <img src="../images/edit.png" alt="Img" className="width15" />
                            </span>
                        ) : (
                            <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editReceiverData(receiverCustomerData)}>
                                <img src="../images/edit.png" alt="Img" className="width15" />
                            </span>
                        )}
                        {!isCustomer && isOrder && curdType === "Edit" && receiverId ? (
                            <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={() => deleteOrderData()}>
                                <img src="../images/bin.png" alt="Img" className="width15" />
                            </span>
                        ) : (
                            <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteReceiverData(e, receiverCustomerData.id, getReceiverCustomer)}>
                                <img src="../images/bin.png" alt="Img" className="width15" />
                            </span>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AddressPanel;
