import { gql } from "@apollo/client";

export const INSERT_UPDATE_WAREHOUSE = gql`
  mutation insertUpdateWareHouse($input: wareHouseInput!,$log: LogInput){
    insertUpdateWareHouse(input:$input,log: $log){
      id
      name
      code
      addLine1
      addLine2
      countryId
      stateId
      city
      zipcode
      phone
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const INSERT_UPDATE_WAREHOUSE_USER = gql`
mutation insertUpdateWarehouseUser($input: warehouseUserInput!,$log: LogInput){
  insertUpdateWarehouseUser(input:$input,log: $log){
    id
    warehouseId
    firstName
    lastName
    email
    password
    addLine1
    addLine2
    countryId
    stateId
    assignClosestCity
    city
    zipCode
    phone
    userType
    reference
    isActive
  }
}
`;

export const INSERT_UPDATE_WAREHOUSE_LOCATION = gql`
  mutation insertUpdateWareHouseLocation($input: wareHouseLocationInput!,$log: LogInput){
    insertUpdateWareHouseLocation(input:$input,log: $log){
      id
      warehouseId
      height
      width
      length
      boxRacNo
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_WAREHOUSE = gql`
  mutation deleteWareHouse($id: ID!,$log: LogInput){
    deleteWareHouse(id:$id,log: $log){
      id
      name
      code
      addLine1
      addLine2
      countryId
      stateId
      city
      zipcode
      phone
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
export const DELETE_WAREHOUSE_USER = gql`
  mutation deleteWarehouseUser($id: ID!,$log: LogInput){
    deleteWarehouseUser(id:$id,log: $log){
      id
      warehouseId
      firstName
      lastName
      email
      password
      addLine1
      addLine2
      countryId
      stateId
      assignClosestCity
      city
      zipCode
      phone
      userType
      reference
      isActive
    }
  }
`;
export const DELETE_WAREHOUSE_LOCATION = gql`
  mutation deleteWareHouseLocation($id: ID!,$log: LogInput){
    deleteWareHouseLocation(id:$id,log: $log){
      id
      warehouseId
      height
      width
      length
      boxRacNo
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DEFAULT_WAREHOUSE = gql`
mutation defaultWareHouse($id: ID!,$log: LogInput){
  defaultWareHouse(id:$id,log:$log){
    id
    name
    code
    addLine1
    addLine2
    countryId{
      id
      name
      code
    }
    stateId{
      id
      name
    }
    city
    zipcode
    phone
    isDefault
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}`