import { gql } from "@apollo/client"

export const GET_TOTAL_RECEIVED_PACKAGES_REPORT = gql`
query getTotalReceivedPakagesReport($customerId:ID,$from: String,$to: String,$status: String){
    getTotalReceivedPakagesReport(customerId:$customerId,from:$from,to:$to,status:$status){
    date
    customerInfo {
      firstname
      lastname
      pmb_no
    }
      receiverInfo {
        name
      }
      totalBox
    verifiedCost
    binNo
    }
  }`

export const GET_PACKAGED_BOX_REPORT = gql`
  query getPackedBoxReport($customerId:ID,$from: String,$to: String,$status: String){  
    getPackedBoxReport(customerId:$customerId,from:$from,to:$to,status:$status){
    date
    customerInfo {
        firstname
        lastname
        pmb_no
    }
    receiverInfo {
      name
    }
    boxNo
    len
    width
    height
    weight
    binNo
  }
}`

export const GET_CUSTOMER_REPORT = gql`
query getCustomerReport($customerId:ID,$from: String,$to: String) {
    getCustomerReport(customerId:$customerId,from:$from,to:$to) {
      createdAt
      firstName
      lastName
      pmbNo
      email
      phone
      address1
      address2
    }
  }`

export const GET_PACKING_LIST_REPORT = gql`
  query getPackingListReport($customerId:ID,$from: String,$to: String,$status: String,$shipmentId: ID){
    getPackingListReport(customerId:$customerId,from:$from,to:$to,status:$status,shipmentId:$shipmentId){
      date
      customerInfo{
        firstname
        lastname
        pmb_no
      }
      itemDescription
      qty
    }
  }`

  export const GET_CHARGES_REPORT = gql`
  query getChargesReport(
    $customerId: ID
    $from: String
    $to: String
    $status: String
    $type: String!
    $ship: String
  ) {
    getChargesReport(
      customerId: $customerId
      from: $from
      to: $to
      status: $status
      type: $type
      ship: $ship
    ) {
      customerInfo{
        firstname
        lastname
        pmb_no
      }
      orderDate
      charges
      totalAmount
      totalAmountNGN
      shipmentNo
    }
  }
`;