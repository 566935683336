import React from 'react';

const Loader = ({ newStyle }) => (
  <div className="loaderContainer" style={newStyle && { position: 'inherit' }}>
    <div className="bgLoaderContant">
      <img src="../images/planeLoading.png" alt="Img" className="planImg" />
    </div>
  </div>
)

export default Loader;