import { gql } from "@apollo/client";

export const INSERT_UPDATE_STATE = gql`
  mutation insertUpdateState($input: StateInput!,$log: LogInput){
    insertUpdateState(input: $input, log: $log){
      id
      name
      countryId
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_STATE = gql`
  mutation deleteState($id: ID!,$log: LogInput){
    deleteState(id: $id, log: $log){
      id
      name
      countryId
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
