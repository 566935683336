import React, { useEffect, useState } from 'react';
import * as $ from 'jquery';
import OrderDeatils from './OrderDetails';
import { OrderStatusList, OrderStatusType } from '../../components/Constant/index';
import _ from 'lodash';
import { useMutation } from '@apollo/client';
import { INSERT_ORDER } from '../../../graphql/mutation/OrderMutation';
import { toast } from 'react-toastify';
import jsPDF from 'jspdf';
import moment from 'moment';
import Loader from '../../components/Loader/Loader';
import Select from 'react-select'
import ItemList from '../../components/itemList';
import PrintLabelDemo from './printLabelDemo';
import GetAddressList from '../../components/Address/getReceiverAddress';
import { useLocation } from 'react-router-dom';
import { GET_ALL_ONLINE_STORE } from '../../../graphql/query/OnlineStoreQuery';

const CRUDOrder = (props) => {
    const location = useLocation();
    const [state, setState] = useState({
        Items: [],
        orderDetailsData: {},
        ordreStatus: location?.selectFromDashboard
            ? _.find(OrderStatusList, (o) => o.value === location.selectFromDashboard.status)
            : props?.SelectedOrderDetailsData?.status
                ? _.find(OrderStatusList, (o) => o.value === props.SelectedOrderDetailsData.status)
                : OrderStatusList && OrderStatusList[0],
        status: OrderStatusList[0].value,
        customerReceiverType: null,
        Old_Data: null,
        selectedItem: [],
        isAllSelectedItem: false,
        updateItemStatus: null,
        temp_updateItemStatus: null,
        openModal: "Add New",
        ReceiverData: {},
        receiverId: null
    });
    const [addOrder] = useMutation(INSERT_ORDER, {
        refetchQueries: [{ query: GET_ALL_ONLINE_STORE }]
    });
    useEffect(() => {
        const { modalOpen, SelectedOrderDetailsData, OrderId, curdType } = props;
        if (modalOpen) {
            if (OrderId && SelectedOrderDetailsData && SelectedOrderDetailsData.items && curdType === "Edit") {
                AddItemCustomId(SelectedOrderDetailsData);
            } else {
                let item = [];
                let custom_ItemId = Math.random().toString(36).substring(2, 8);
                item.push({ id: custom_ItemId });
                setState(preData => ({
                    ...preData,
                    Items: item,
                    orderDetailsData: {}
                }))
                $('#OrderModal').modal('show');

            }
        }
        if (location?.selectFromDashboard) {
            AddItemCustomId(location?.selectFromDashboard);
        }
    }, []);

    const AddItemCustomId = (OrderDetails) => {
        let item = [];
        OrderDetails.items.length > 0 &&
            OrderDetails.items.forEach((d, i) => {
                let custom_ItemId = Math.random().toString(36).substr(2, 8);
                d.id = custom_ItemId;
                item.push(d);
            });
        if (item && item.length > 0) {
            setState(preData => ({
                ...preData,
                Items: item,
                status: OrderDetails.status
            }))
            OrderDetails.items = item;
            setState(preData => ({
                ...preData,
                customerReceiverType: OrderDetails.customerInfo && OrderDetails.customerInfo.userType,
                orderDetailsData: OrderDetails ? OrderDetails : {},
                Old_Data: OrderDetails ? OrderDetails : null
            }))
            $('#OrderModal').modal('show');

        }
    };

    const handleChangeOrderStatus = (value) => {
        setState(preData => ({
            ...preData,
            status: value.value,
            ordreStatus: value,
            orderDetailsData: { ...preData.orderDetailsData, status: value.value },
        }));
    };

    const allItemSelect = (e) => {
        const isChecked = e.target.checked;
        setState(preData => ({
            ...preData,
            isAllSelectedItem: isChecked
        }));

        setState(prevState => ({
            ...prevState,
            Items: prevState.Items.map(item => ({ ...item, check: isChecked }))
        }));
    };

    const handleChangeSelectItemStatus = (value) => {
        setState(preData => ({
            ...preData,
            updateItemStatus: value.value,
            temp_updateItemStatus: value
        }))
    };

    const addNewItem = (e) => {
        e.preventDefault();
        const { Items } = state;
        let item = Items;
        let custom_ItemId = Math.random().toString(36).substr(2, 8);
        item.push({ id: custom_ItemId });
        setState(preData => ({
            ...preData,
            Items: item,
        }))
    };

    const AddNewAddress = (type, ReceiverData) => {
        setState(preData => ({
            ...preData,
            openModal: type,
            ReceiverData: ReceiverData
        }))
        $("#NewAddress").css('display', 'block');
        $('#frm_Receiver').removeClass('was-validated');
    };

    const updateItemsInOrder = () => {
        const { Items, orderDetailsData, customerReceiverType } = state;
        let ItemsData = [];
        Items && Items?.length > 0 && Items?.map((obj, i) => {
            let itemData = {
                instruction: state[`Item_${obj.id}_instruction`] ? state[`Item_${obj.id}_instruction`] : obj.instruction,
                description: state[`Item_${obj.id}_description`] ? state[`Item_${obj.id}_description`] : obj.description,
                qty: state[`Item_${obj.id}_qty`] ? state[`Item_${obj.id}_qty`] : obj.qty,
                price: state[`Item_${obj.id}_price`] ? state[`Item_${obj.id}_price`] : obj.price,
                total: state[`Item_${obj.id}_total`] ? state[`Item_${obj.id}_total`] : obj.total,
                itemStatus: customerReceiverType === "verified" ? obj.itemStatus : obj.itemStatus ? obj.itemStatus : "received",
                rate: state[`Item_${obj.id}_rate`] ? state[`Item_${obj.id}_rate`] : obj.rate,
                isInsure: state[`Item_${obj.id}_isInsure`] ? state[`Item_${obj.id}_isInsure`] : obj.isInsure,
                insurerate: state[`Item_${obj.id}_insurerate`] ? state[`Item_${obj.id}_insurerate`] : obj.insurerate,
                insureprice: state[`Item_${obj.id}_insureprice`] ? state[`Item_${obj.id}_insureprice`] : obj.insureprice,
            };
            ItemsData.push(itemData);
            return i;
        });
        setState(preData => ({
            ...preData,
            orderDetailsData: { ...orderDetailsData, items: ItemsData }
        }))
    };

    const getItemDes = (id, data) => {
        setState(preData => ({
            ...preData,
            [`Item_${id}_description`]: data.target.value
        }))
        updateItemsInOrder();

    };

    const getItemQty = (id, data) => {
        const enteredValue = parseInt(data.target.value, 10);
        if (!isNaN(enteredValue) && enteredValue >= 0) {
            setState(preData => ({
                ...preData,
                [`Item_${id}_qty`]: enteredValue
            }))
            updateItemsInOrder();
        }
    };

    // Convert the rest of the functions similarly...

    const submitOrderData = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const { orderDetailsData, Items, status, customerReceiverType, Old_Data } = state;

        const { curdType, OrderId, SelectedOrderDetailsData } = props;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            let input = {};
            let old_value = {};
            if (curdType === "Edit" || location?.selectFromDashboard) {
                let ItemsData = [];
                Items && Items.length > 0 && Items.map((obj, i) => {
                    let itemData = {
                        instruction: state[`Item_${obj.id}_instruction`] ? state[`Item_${obj.id}_instruction`] : obj.instruction,
                        description: state[`Item_${obj.id}_description`] ? state[`Item_${obj.id}_description`] : obj.description,
                        qty: state[`Item_${obj.id}_qty`] ? `${state[`Item_${obj.id}_qty`]}` : `${obj.qty}`,
                        price: state[`Item_${obj.id}_price`] ? state[`Item_${obj.id}_price`] : obj.price,
                        total: state[`Item_${obj.id}_total`] ? state[`Item_${obj.id}_total`] : obj.total,
                        itemStatus: (customerReceiverType === "verified") ? (obj.itemStatus || "received") : (obj.itemStatus ? (obj.itemStatus === "pending" ? "received" : obj.itemStatus) : "received"),
                        rate: state[`Item_${obj.id}_rate`] ? state[`Item_${obj.id}_rate`] : obj.rate,
                        isInsure: state[`Item_${obj.id}_isInsure`] ? state[`Item_${obj.id}_isInsure`] : obj.isInsure,
                        insurerate: state[`Item_${obj.id}_insurerate`] ? state[`Item_${obj.id}_insurerate`] : obj.insurerate,
                        insureprice: state[`Item_${obj.id}_insureprice`] ? state[`Item_${obj.id}_insureprice`] : obj.insureprice,
                    }
                    if (obj._id) {
                        itemData._id = obj._id
                    }
                    ItemsData.push(itemData);
                    return i;
                });
                if (ItemsData && ItemsData.length === 0) {
                    toast.error('Please add atleast one item...', {
                        autoClose: 2000
                    })
                    return false;
                }

                for (var obj in Old_Data) {
                    if (orderDetailsData[obj] !== Old_Data[obj]) {
                        old_value[obj] = Old_Data[obj]
                        input[obj] = orderDetailsData[obj]
                    }
                }
                input.onlineStoreId = orderDetailsData?.onlineStoreId?.id ? orderDetailsData?.onlineStoreId?.id : orderDetailsData?.onlineStoreId
                input.id = OrderId || location?.selectFromDashboard?.id
                input.items = ItemsData
                if (orderDetailsData?.status === 'pending' || orderDetailsData?.status === 'received') {
                    const allItemsValid = input.items.every(item => ['received', 'packed', 'shipped'].includes(item.itemStatus));
                    input.status = allItemsValid ? status : 'pending';
                } else {
                    input.status = status;
                }
                
                if (orderDetailsData.binId !== Old_Data.binId)
                    input.oldBinId = Old_Data.binId
            }
            if (curdType === "Add New") {
                let ItemsData = [];
                delete orderDetailsData.items;
                Items && Items.length > 0 && Items.map((obj, i) => {
                    let itemData = {
                        instruction: state[`Item_${obj.id}_instruction`],
                        description: state[`Item_${obj.id}_description`],
                        qty: `${state[`Item_${obj.id}_qty`]}`,
                        price: state[`Item_${obj.id}_price`],
                        total: state[`Item_${obj.id}_total`],
                        itemStatus: "received",
                        isInsure: state[`Item_${obj.id}_isInsure`],
                        insurerate: state[`Item_${obj.id}_insurerate`],
                        insureprice: state[`Item_${obj.id}_insureprice`],
                    }
                    ItemsData.push(itemData);
                    return i;
                });
                if (ItemsData && ItemsData.length === 0) {
                    toast.error('Please add atleast one item...', {
                        autoClose: 2000
                    })
                    return false;
                }
                orderDetailsData.items = ItemsData
                input = { ...orderDetailsData };
                if (!input?.receiverId) {
                    toast.error('Please select receiver.', {
                        autoClose: 2000
                    })
                }
            }
            let log = {
                action: props.curdType === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'orders',
                actionName: "addOrder",
                oldValue: props.curdType === "Edit" ? JSON.stringify(SelectedOrderDetailsData) : JSON.stringify(input)
            }
            if (orderDetailsData.status === "received" && customerReceiverType === "verified") {
                const checkItems = input.items.every(item => ((item.itemStatus === "received" || item.itemStatus === "packed" || item.itemStatus === "shipped")))
                if (!checkItems) {
                    toast.error('All Items must has a received status.', {
                        autoClose: 2000
                    })
                    return false;
                }
            }

            addOrder({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                form.classList.remove('was-validated');
                form.reset();
                setState(preData => ({ ...preData, orderDetailsData: {} }))
                if (!location?.selectFromDashboard) {
                    props.closeModal();
                } else {
                    $('#OrderModal').modal('hide')
                    // this.props.history.goBack()
                    // // this.props.history.push('dashboard')
                }
                toast.success(`Order ${curdType === "Edit" ? "updated" : "added"} successfully!!`, {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    };

    const closeModal = () => {
        if (props?.enableModalQuery) {
            props.enableModalQuery();
        }
        $('#OrderModal').modal('hide');
        $('body').removeClass('modal-open-delete');
        props.history.push('dashboard');
    };
    const updateOrderItemStatus = (e) => {
        e.preventDefault();
        const { Items, updateItemStatus, isAllSelectedItem } = state;
        let item = []
        if (isAllSelectedItem && updateItemStatus) {
            Items.forEach(d => {
                if (d.check) {
                    d.itemStatus = updateItemStatus
                    d.check = false
                }
                item.push(d)
            })
        }
        else {
            toast.error('Please select any one item or status...', {
                autoClose: 2000
            })
        }
        if (item && item.length > 0) {
            setState(preData => ({
                ...preData,
                Items: item,
                selectedItem: [],
                isAllSelectedItem: false,
                updateItemStatus: null,
                temp_updateItemStatus: null,
            }))
        }
    }

    const getItemPrice = (id, data) => {
        setState(prevState => ({
            ...prevState,
            [`Item_${id}_price`]: data.target.value
        }));
        updateItemsInOrder();
    };

    const getItemTotal = (id, data) => {
        setState(prevState => ({
            ...prevState,
            [`Item_${id}_total`]: data
        }));
    };

    const getItemSPInst = (id, data) => {
        setState(prevState => ({
            ...prevState,
            [`Item_${id}_instruction`]: data.target.value
        }));
        updateItemsInOrder();
    };

    const getItemStatus = (id, data) => {
        const Items = [...state.Items];
        const itemIndex = Items.findIndex(item => item.id === id);
        Items[itemIndex].itemStatus = data;
        setState(prevState => ({
            ...prevState,
            [`Item_${id}_itemStatus`]: data,
            Items
        }));
        updateItemsInOrder();
    };

    const getItemRateId = (id, data) => {
        setState(prevState => ({
            ...prevState,
            [`Item_${id}_rate`]: data
        }));
        updateItemsInOrder();

    };

    const getItemSelectINS = (id, data) => {
        setState(prevState => ({
            ...prevState,
            [`Item_${id}_isInsure`]: data
        }));
        updateItemsInOrder();
    };

    const getItemInsurePrice = (id, data) => {
        setState(prevState => ({
            ...prevState,
            [`Item_${id}_insureprice`]: data
        }));
        updateItemsInOrder();
    };

    const getItemInsurerate = (id, data) => {
        setState(prevState => ({
            ...prevState,
            [`Item_${id}_insurerate`]: data
        }));
        updateItemsInOrder();
    };

    const ItemStatusUpdateArray = id => {
        const Items = [...state.Items];
        const itemIndex = Items.findIndex(item => item.id === id);
        if (itemIndex !== -1) {
            Items[itemIndex].check = !Items[itemIndex].check;
            setState(prevState => ({
                ...prevState,
                Items : Items
            }));
        }
    };

    const isSelectedItemDelete = (id) => {
        let { Items } = state;
        let updatedItem = _.reject(Items, d => d.id === id)
        setState(prevState => ({
            ...prevState, Items: updatedItem
            // Items: prevState.Items.filter(item => item.id !== id)
        }));
    }
    return (
        <div className="modal show" id="OrderModal" data-backdrop="static" tabIndex="-1" role="dialog" >
            <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                <div className="modal-content ">
                    <div className="modal-header" style={{ paddingTop: "0px" }}>
                        <div>
                            <p className="mainTitle m-0 mt_-15">{props.curdType} Order</p>
                            <span className="borderBottomOfTitle "></span>
                        </div>
                        <div>
                            <button type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}
                                onClick={!location?.selectFromDashboard ? () => props.closeModal() : () => closeModal()} >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                            </div>
                            <form id="frm_OrderDetails" className="needs-validation" noValidate="noValidate" onSubmit={e => submitOrderData(e, addOrder)}>
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="ibox">
                                            {state.isLoading && <Loader />}
                                            <div className="ibox-title">
                                                <span>Order Details</span>
                                            </div>
                                            <div className="ibox-content">
                                                <OrderDeatils
                                                    // key={Math.random().toString()}
                                                    selectedOrderData={state?.orderDetailsData}
                                                    curdType={location?.selectFromDashboard ? "Edit" : props.curdType}
                                                    getCustomerInfo={(data) => {
                                                        let customerData = {
                                                            firstname: data?.firstname,
                                                            lastname: data?.lastname,
                                                            email: data?.email,
                                                            phone: data?.phone,
                                                            pmb_no: data?.pmb_no,
                                                            userType: data?.receiverType
                                                        }
                                                        setState(preData => ({...preData,
                                                            orderDetailsData: { ...preData?.orderDetailsData, customerId: data?.id, customerInfo: customerData },
                                                            customerReceiverType: data?.receiverType
                                                        }))
                                                    }}
                                                    getOnlineStoreId={id => setState(preData => ({...preData , orderDetailsData: { ...preData?.orderDetailsData, onlineStoreId: id } }))}
                                                    getOrderRateId={id => setState(preData => ({...preData , orderDetailsData: { ...preData?.orderDetailsData, orderRateIds: id } }))}
                                                    getBinsInfo={data => setState(preData => ({...preData , orderDetailsData: { ...preData?.orderDetailsData, binId: data?.id, binNum: data?.name } }))}
                                                    handleText={e => setState(preData => ({...preData , orderDetailsData: { ...preData?.orderDetailsData, [e.target.name]: e.target.value } }))}
                                                    OrderReceipt={url => setState(preData =>  ({  ...preData,                                    
                                                        orderDetailsData: {
                                                            ...preData.orderDetailsData,
                                                            invoiceOrReceipt: url || ''
                                                        }
                                                    }))}
                                                    setLoader={flag => setState(preData => ({...preData, isLoading: flag }  ))}                                          
                                                    getOnlineStore={value => setState(preData => ({...preData , orderDetailsData: { ...preData?.orderDetailsData, onlineStoreId: props?.OrderId ? value?.value : value?.label } }))}
                                                    getRate={value => setState(preData => ({...preData , orderDetailsData: { ...preData?.orderDetailsData, orderRateIds: props?.OrderId ? value?.value : value?.label } }))}

                                                />
                                                {
                                                    state.customerReceiverType === "verified" && props.curdType === "Edit" &&
                                                    <div className="form-row">
                                                        <div className="form-group m-0 col-lg-2 col-md-2 col-sm-6 col-xs-12 d-flex justify-content-center align-items-center">
                                                            <input type="checkbox" name="SelectAllBoxToShip" id="SelectAllBoxToShip" checked={state.isAllSelectedItem} onChange={(e) => allItemSelect(e)} />
                                                            <label htmlFor="SelectAllBoxToShip" className="frm-label chkAll">Check All</label>
                                                        </div>
                                                        <div className="form-group m-0 col-lg-5 col-md-4 col-sm-6 col-xs-12 ">
                                                            <label className="frm-label text-white">Status</label>
                                                            <Select
                                                                className="ffPoppins"
                                                                options={OrderStatusType}
                                                                value={state.temp_updateItemStatus}
                                                                onChange={(data) => handleChangeSelectItemStatus(data)}
                                                            />
                                                        </div>
                                                        <div className="form-group p-0 ml-3">
                                                            <label className="frm-label text-white">Save</label><br />
                                                            <button type="button" className="btn btn-success btn-text bg-lightGreen" onClick={(e) => updateOrderItemStatus(e)}>Update Status</button>
                                                        </div>
                                                    </div>
                                                }
                                                <div className="form-row mb-3 d-flex justify-content-end">
                                                    <div className="form-group p-0 ml-3">
                                                        <button type='button' className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewItem(e)}>
                                                            <i className="fa fa-plus"></i> Add More Item
                                                        </button>
                                                    </div>
                                                </div>
                                                {
                                                    state.Items && state.Items.length > 0 &&
                                                    state.Items.map((item, i) => {
                                                        item.check = item?.check || false;

                                                        let rowBgColor = 'isNew';
                                                        if (state.customerReceiverType === "verified") {
                                                            if (item.itemStatus === "pending") {
                                                                rowBgColor = 'isPending';
                                                            } else if (item.itemStatus === "missed") {
                                                                rowBgColor = 'isMissing';
                                                            } else if (item.itemStatus === "received") {
                                                                rowBgColor = 'isReceived';
                                                            }
                                                        }

                                                        return (
                                                            <div key={item.id}>
                                                                <ItemList
                                                                    id={item.id}
                                                                    rowBgColor={rowBgColor}
                                                                    curdType={props.curdType}
                                                                    isCheckValue={item.check}
                                                                    customerReceiverType={state.customerReceiverType}
                                                                    ItemDes={(id, e) => getItemDes(id, e)}
                                                                    ItemQty={(id, e) => getItemQty(id, e)}
                                                                    ItemPrice={(id, e) => getItemPrice(id, e)}
                                                                    ItemTotal={(id, e) => getItemTotal(id, e)}
                                                                    ItemSPInst={(id, e) => getItemSPInst(id, e)}
                                                                    ItemStatus={(id, e) => getItemStatus(id, e)}
                                                                    ItemRateId={(id, e) => getItemRateId(id, e)}
                                                                    ItemSelectINS={(id, e) => getItemSelectINS(id, e)}
                                                                    ItemInsurePrice={(id, e) => getItemInsurePrice(id, e)}
                                                                    ItemInsurerate={(id, e) => getItemInsurerate(id, e)}
                                                                    isItemDelete={(id) => isSelectedItemDelete(id)}
                                                                    UpdateStatusSelection={(id, e) => ItemStatusUpdateArray(id, e)}
                                                                    SingleItemData={item}
                                                                    Dashboard={location?.selectFromDashboard ? true : false}
                                                                    itemSelected={state.isAllSelectedItem}
                                                                />
                                                            </div>
                                                        )
                                                    })
                                                }
                                                <div className="form-row mt-3 d-flex justify-content-end">
                                                    <div className="form-group p-0 ml-3">
                                                        {state.orderDetailsData && state.orderDetailsData?.customerInfo && state.orderDetailsData?.trackingno && state.orderDetailsData?.binNum && state.orderDetailsData?.items && state.orderDetailsData?.createdAt && props.curdType === "Edit" && (
                                                            <PrintLabelDemo orderDetailsData={state.orderDetailsData} />
                                                        )}

                                                    </div>
                                                </div>
                                                <div className="well m-0">
                                                    <div className="form-row">
                                                        <div className="form-group m-0 col-lg-5 col-md-4 col-sm-6 col-xs-12 ">
                                                            <label className="frm-label">Status</label>
                                                            <Select
                                                                className="ffPoppins"
                                                                options={OrderStatusList}
                                                                value={state.ordreStatus}
                                                                onChange={(status) => handleChangeOrderStatus(status)}
                                                            />
                                                        </div>
                                                        <div className="form-group m-0 col-lg-5 col-md-4 col-sm-6 col-xs-12 ">
                                                            <label className="frm-label">Remark</label>
                                                            <input type="text" className="form-control ffPoppins" placeholder="Enter a Remark" name="statusRemark"
                                                                value={state.orderDetailsData?.remark && state.orderDetailsData?.remark}
                                                                onChange={(e => setState( preData => ({ ...preData ,orderDetailsData: { ...state.orderDetailsData, remark: e.target.value } })))} />
                                                        </div>
                                                        <div className="form-group p-0 mt-3 ml-3">
                                                            <label className="frm-label"> </label>
                                                            <button type="submit" className="btn btn-primary btn-text mt-3 mr-4 border-0 bg-searchBlue" >Save</button>
                                                            <button type="reset" className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed" onClick={!location?.selectFromDashboard ? () => props.closeModal() : () => closeModal()} >Cancel</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="mt-3 mb-3 ph-15">
                                <div className="border-bottom row d-flex justify-content-sm-between">
                                    <div className="d-flex mb-2">
                                        <span className="iboxTitleSpan">Receiver Info</span>
                                    </div>
                                    <div className="mb-2">
                                        <span className="btn btn-sm btn-success bg-lightGreen text-white ffPoppins" onClick={(e) => {
                                            e.preventDefault();
                                            AddNewAddress('Add New', {})
                                        }}>
                                            <i className="fa fa-plus"></i> Add New Address</span>
                                    </div>
                                </div>
                            </div>
                            {
                                state?.orderDetailsData && state?.orderDetailsData.customerId &&
                                <GetAddressList
                                    // key={Math.random().toString()}
                                    customerId={state?.orderDetailsData.customerId}
                                    isCustomer={false}
                                    isOrder={true}
                                    curdType={location?.selectFromDashboard ? "Edit" : props.curdType}
                                    receiverId={state?.orderDetailsData.receiverId ? state?.orderDetailsData.receiverId : null}
                                    selectedOrderAddress={state?.orderDetailsData.receiverAddress}
                                    receiverDataInfo={data => {
                                        let receiverData = {
                                            name: data.name,
                                            phone: data.phone,
                                            address1: data.address1,
                                            address2: data.address2 ? data.address2 : null,
                                            city: data.city ? data.city : null,
                                            closestCityId: data.closestCityId ? { id: data.closestCityId.id, name: data.closestCityId.name } : null,
                                            stateId: data.stateId && { id: data.stateId.id, name: data.stateId.name },
                                            countryId: data.countryId && { id: data.countryId.id, name: data.countryId.name },
                                            zipCode: data.zipCode ? data.zipCode : null
                                        }
                                        setState(preData => ({...preData, orderDetailsData: { ...state?.orderDetailsData, receiverAddress: receiverData, receiverId: data.id } }))
                                    }}
                                    updateOrderAdd={data => {
                                        let receiverData = {
                                            name: data.name,
                                            phone: data.phone,
                                            address1: data.address1,
                                            address2: data.address2 ? data.address2 : null,
                                            city: data.city ? data.city : null,
                                            closestCityId: data.closestCityId ? { id: data.closestCityId.id, name: data.closestCityId.name } : null,
                                            stateId: data.stateId && { id: data.stateId.id, name: data.stateId.name },
                                            countryId: data.countryId && { id: data.countryId.id, name: data.countryId.name },
                                            zipCode: data.zipCode ? data.zipCode : null
                                        }
                                        setState(preData => ({...preData, orderDetailsData: { ...state?.orderDetailsData, receiverAddress: receiverData, receiverId: data.id } }))
                                    }}
                                    deleteOrderAddress={() => {
                                        setState(preData => ({...preData, orderDetailsData: { ...state?.orderDetailsData, receiverId: null, receiverAddress: {} } }))
                                    }}
                                    openModal={state.openModal}
                                    AddNewAddress={AddNewAddress}
                                    ReceiverData={state.ReceiverData}
                                />
                            }
                        </div>

                    </div>
                </div>
            </div>
        </div>


    );
};

export default CRUDOrder;
