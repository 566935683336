import { ApolloConsumer } from '@apollo/client';
import React from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export const StaticHeader = (props) => {
  const loggedUser=  localStorage.getItem('loggedUser')
  const signOut = (client) => {
        localStorage.clear()
        sessionStorage.clear();
        client.resetStore();
        toast.success("user logged out successfully!", {
          autoClose: 2000
        })
      };

  return (
    <div className="col-md-12 staticHeader p-0 flex">
    <div className=" col-md-2 col-sm-3 sms-logo p-0">
      <Link to="/">
        <div className="Logo main-logo">
          <img src="../images/smsLogo.png" alt="Logo" width='100px' />
        </div>
      </Link>
    </div>
    <div className="col-md-6 col-sm-8 flex sms-menu mt-1">
      <Link to="/" className="p-3">
        <span className={`${window.location.pathname === '/' ? 'inputFs14 themeRed' : 'inputFs14'}`}>Home</span>
      </Link>
      <Link to="/about-us" className="p-3">
        <span className={`${window.location.pathname === '/about-us' ? 'inputFs14 themeRed' : 'inputFs14'}`}>About Us</span>
      </Link>

      {!loggedUser ? 
      
      <Link to="/contact-us-web" className="p-3">
          <span className={`${window.location.pathname === '/contact-us-web' ? 'inputFs14 themeRed' : 'inputFs14  '}`}>Contact Us</span>
      </Link>
        :
        <>
          <Link to="/contact-us" className="p-3">
            <span className={`${window.location.pathname === '/contact-us' ? 'inputFs14 themeRed' : 'inputFs14  '}`}>Contact Us</span>
          </Link>
          <Link to="/my-order" className="p-3">
            <span className={`${window.location.pathname === '/my-order' ? 'inputFs14 themeRed' : 'inputFs14'}`}>My Portal</span>
          </Link>
          <div className="bottomBrdrLight" />
          <ApolloConsumer>
            {client => (
              <Link to="/" className="p-3" onClick={() => signOut(client)}>
                <span className={'inputFs14'}>Log out</span>
              </Link>
            )}
          </ApolloConsumer>
        </>
      }
    </div>
    {!loggedUser &&
      <div className="col-md-4 col-sm-12 mt-3 sms-btn d-flex align-items-center justify-content-md-end justify-content-center">
        <div className="d-flex">
          <Link to="/login" className="">
            <button className="aboutSignIn mr-2 mb-1">Sign In</button>
          </Link>
          <Link to="/signup" className="">
            <button className="aboutRegister mb-1">Register</button>
          </Link>
        </div>
      </div>
    }
  </div>
  );
}
