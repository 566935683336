import { useMutation, useQuery, useSubscription } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import * as $ from 'jquery';
import { GET_SHIPMENT } from '../../../graphql/query/shipmentQuery'
import Loader from '../../components/Loader/Loader'
import moment from 'moment'
import Title from '../../components/Common/Title';
import bootbox from 'bootbox'
import { toast } from 'react-toastify';
import { DELETE_SHIPMENT } from '../../../graphql/mutation/shipmentMutation';
import { SHIPMENT_SUBSCRIBE } from '../../../graphql/subscription/shipmentSubscription';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import { shippingDateType } from '../../components/Constant';
import ReactPaginate from 'react-paginate';
import Pagination from '../../components/Common/Pagination';
import CRUDShipment from './CRUDShipment';

const Shipment = () => {

    const [genSettings, setGenSettings] = useState(JSON.parse(localStorage.getItem('generalSettings')));
    const [state, setState] = useState({
        OrderModel: 'Add',
        fromDate: null,
        toDate: null,
        total_count: 100,
        limit: genSettings?.paginationLimit || 10,
        openModal: null,
        pageNo: 0,
        filter: '',
        filterText: '',
        sortKey: 'createdAt',
        sortType: -1,
        id: null,
        oldShipData: null,
        fDateBy: null,
        modalOpen: false,
        filterTo: '',
        dateBy: null
    });
    const sort = { key: state.sortKey, type: state.sortType }

    const [deleteShipment] = useMutation(DELETE_SHIPMENT)
    const { data: getShipment ,loading,refetch } = useQuery(GET_SHIPMENT, {
        variables: { "page": state.pageNo + 1, "limit": state.limit, "filter": state.filter, "sort": sort, from: state.filterFrom, to: state.filterTo, dateBy: state.fDateBy },
        fetchPolicy: 'cache-and-network'
    })

    useEffect(() => {
        $('.react-datepicker-wrapper').addClass('d-flex');
    }, []);

    const fromDateChange = (date) => {
        setState(prevState => ({ ...prevState, fromDate: date, filterFrom: moment(date).format('YYYY/MM/DD') }));
    };

    const toDateChange = (date) => {
        setState(prevState => ({ ...prevState, toDate: date, filterTo: moment(date).format('YYYY/MM/DD') }));
    };

    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    const addShipment = (e) => {
        e.preventDefault();
        setState(prevState => ({
            ...prevState,
            oldShipData: null,
            OrderModel: 'Add New',
            modalOpen: true
        }))
        $('#AddNewShipmentModal').modal('show');
    };

    const editShipment = (e, oldShip) => {
        e.preventDefault();
        setState(prevState => ({
            ...prevState,
            oldShipData: oldShip,
            OrderModel: 'Edit',
            modalOpen: true,
        }));
        $('#AddNewShipmentModal').modal('show');
    };

    const deleteShipmentDetails = (e, id) => {
        e.preventDefault();
        bootbox.confirm({
            title: 'Delete SHIPMENT',
            message: 'Are you sure want to delete this record ?',
            buttons: {
                cancel: {
                    label: 'NO',
                    className: 'btn btn-sm bg-Red ffPoppins text-white'
                },
                confirm: {
                    label: 'YES',
                    className: 'btn btn-sm bg-Green ffPoppins text-white'
                }
            },
            callback: function (result) {
                if (result) {
                    let log = {
                        action: 'DELETE',
                        actionOn: 'shipments',
                        actionName: 'deleteShipment',
                        oldValue: ''
                    };
                    deleteShipment({ variables: { "id": id, "log": log } }).then(async ({ data }) => {
                        refetch();
                        toast.success('Shipment deleted successfully!', {
                            autoClose: 2000
                        });
                    }).catch((err) => {
                        toast.error(err.message, {
                            autoClose: 2000
                        });
                    });
                }
            }
        });
    };

    const toggleModal = (e) => {
        $('#AddNewShipmentModal').modal('hide');
        setState(prevState => ({ ...prevState, modalOpen: false, oldShipData: null }));
    };

    const handlePageClick = (data) => {
        setState(prevState => ({ ...prevState, pageNo: data.selected }));
    };

    const searchShipments = () => {
        const { filterText } = state;
        setState(prevState => ({ ...prevState, pageNo: 0, filter: filterText }));
    };

    const onResetSearch = () => {
        setState(prevState => ({
            ...prevState,
            pageNo: 0,
            filter: '',
            filterText: '',
            serviceType: null,
            status: null,
            fromDate: null,
            filterFrom: null,
            filterTo: null,
            toDate: null,
            fDateBy: null,
            dateBy: null
        }));
    };

    const sortData = (sortKey) => {
        let { sortType } = state;
        sortType = (sortType === 1) ? -1 : 1;
        setState(prevState => ({ ...prevState, sortKey: sortKey, sortType: sortType }));
    };

    const handledateByClick = (data) => {
        setState(prevState => ({ ...prevState, dateBy: data, fDateBy: data && data.value }));
    };


    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchShipments();
        }
    };

    return (
        <div className="container-fluid">
            <div className="row">
                <Title
                    name="Shipment List"
                />
                <div className="col-md-4 p-0">
                    <div className="float-right">
                        <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addShipment(e)}> <i className="fa fa-plus"></i> Add New Shipment </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Shipment Details</span>
                        </div>
                        {loading && <Loader />}
                        <div className="ibox-content p-0">
                            <div className="form-row ph-15">
                                <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                    <label className="frm-label">Form</label>
                                    <DatePicker
                                        className="form-control ffPoppins d-flex"
                                        placeholderText="mm/dd/yyyy"
                                        selected={state.fromDate}
                                        maxDate={new Date()}
                                        onChange={fromDateChange}
                                        onChangeRaw={handleDateChangeRaw}
                                    />
                                </div>
                                <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                    <label className="frm-label">To</label>
                                    <DatePicker
                                        className="form-control ffPoppins d-flex"
                                        placeholderText="mm/dd/yyyy"
                                        minDate={state.fromDate}
                                        maxDate={new Date()}
                                        selected={state.toDate}
                                        onChange={toDateChange}
                                        onChangeRaw={handleDateChangeRaw}
                                    />
                                </div>
                                <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                    <label className="frm-label">Date By</label>
                                    <Select className="ffPoppins"
                                        onChange={(data) => handledateByClick(data)}
                                        value={state.dateBy}
                                        options={shippingDateType} />
                                </div>
                                <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                    <label className="frm-label">Search</label>
                                    <input type="text" className="form-control ffPoppins" onKeyDown={(e) => handleSearch(e)} value={state?.filterText} onChange={(e) => setState(preData => ({ ...preData , filterText :e.target.value})) } />
                                </div>
                                <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                    <div className="mt-30">
                                        <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchShipments()}>Search</button>
                                        <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={()=> onResetSearch()}>Reset</button>
                                    </div>
                                </div>
                            </div>
                            <div className="pull-right">
                                <Pagination
                                    pageCount={getShipment?.getShipment && Math.ceil(getShipment?.getShipment?.count / state?.limit)}
                                    pageNo={state.pageNo}
                                    handlePageClick={handlePageClick}
                                />
                            </div>
                            <div className="table-responsive">
                                <table className="table themeTable table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center">No.</th>
                                            <th className={`sortColumn ${(state.sortKey === 'shippingDate') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('shippingDate')} >Shipping Date</th>
                                            <th className={`sortColumn ${(state.sortKey === 'estimatedDate') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('estimatedDate')} >Estimated Date</th>
                                            <th className={`sortColumn ${(state.sortKey === 'shipmentNo') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('shipmentNo')} >Shipment No.</th>
                                            <th>Warehouse</th>
                                            <th>No of Box</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {getShipment && getShipment?.getShipment?.data && getShipment?.getShipment?.data?.length > 0 ?
                                            getShipment?.getShipment?.data.map((d, i) => {
                                                let shipmentId = d.id
                                                return (
                                                    <tr key={shipmentId} onDoubleClick={(e) => editShipment(e, d)} style={{ cursor: "pointer" }}>
                                                        <td className=" text-center">{i + 1}</td>
                                                        <td>{moment(d.shippingDate).format('MM/DD/YYYY')}</td>
                                                        <td>{moment(d.estimatedDate).format('MM/DD/YYYY')}</td>
                                                        <td>{d.shipmentNo}</td>
                                                        <td>{d.wrhsId && d.wrhsId.name}</td>
                                                        <td>{d.totalBox}</td>
                                                        <td>
                                                            <div className="d-flex flex-row">
                                                                <Link to={`/view-shipment/${shipmentId}`}>
                                                                    <span className="btn BoxImg bg-Green rounded mr-2">
                                                                        <img src="../images/eye.png" alt="Img" className="width15" />
                                                                    </span>
                                                                </Link>
                                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editShipment(e, d)}>
                                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                                </span>
                                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteShipmentDetails(e, d.id)}>
                                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            :
                                            <tr>
                                                <td style={{ textAlign: 'center' }} colSpan={7} >No Records Found!</td>
                                            </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {state.modalOpen &&
                <div className="modal show" id="AddNewShipmentModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                        <div className="modal-content ">
                            <div className="modal-header" style={{ paddingTop: "0px" }}>
                                <div>
                                    <p className="mainTitle m-0 mt_-15">{state.OrderModel} Shipment</p>
                                    <span className="borderBottomOfTitle "></span>
                                </div>
                                <div>
                                    <button onClick={(e) => toggleModal(e)}
                                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <CRUDShipment key={Math.random().toString()} modalOpen={state.modalOpen} curdType={state.OrderModel} oldShipData={state?.oldShipData} closeModel={(e) => toggleModal(e)} refetch={refetch} />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Shipment