import React, { useState } from 'react'
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import { adminUsersRole, statusType } from '../../../components/Constant';
import Select from 'react-select';
import * as $ from 'jquery';

export const InserUpdateUser = ({ userTypeColorError, setTempIsActive, loadingUserUpdate, refetch, setTempUserType, tempUserType, tempIsActive, openModal, usersData, setUsersData, oldData, setUserTypeColorError, id, insertUpdateUser }) => {
    const [isActive, setIsActive] = useState(true);

    const formOnSubmitAdminUsers = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity() || !userTypeColorError) {
            event.stopPropagation();
            form.classList.add('was-validated');
            setUserTypeColorError(tempUserType ? true : false)
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            let input = {};
            let old_value = {};
            if (openModal === "Edit") {
                for (var obj in oldData) {
                    if (oldData[obj] !== usersData[obj]) {
                        old_value[obj] = oldData[obj]
                        input[obj] = usersData[obj]
                    }
                }
                input.id = id
            } else {
                const reg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/; //eslint-disable-line
                if (reg.test(usersData.email) === false) {
                    toast.error('Email is Not Correct', {
                        autoClose: 2000
                    });
                    return false
                }
                if (usersData.password !== usersData.confirmPassword) {
                    toast.error('Passwords do not match.', {
                        autoClose: 2000
                    })
                    return false
                }
                usersData.isActive = isActive
                delete usersData.confirmPassword;
                input = { ...usersData }
            }
            let log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'users',
                actionName: 'insertUpdateUser',
                oldValue: openModal === "Edit" ? JSON.stringify(old_value) : ""
            }
            insertUpdateUser({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                form.classList.remove('was-validated');
                setUsersData({}); setUserTypeColorError(null);  setTempIsActive(null); setTempUserType(''); refetch();
                form.reset();
                $('#UsersModal').modal('hide');
                if (openModal === "Edit") {
                    toast.success("Users updated successfully!", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("Users added successfully!", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    }
    const TextChangeHandler = (e) => {
        setUsersData({
            ...usersData,
            [e.target.name]: e.target.value
        })
    }


    const handleChangeStatus = (userStatus) => {
        setUsersData({
            ...usersData,
            isActive: userStatus.value
        })
        setTempIsActive(userStatus);
    }
    const handleChangeRole = (userRole) => {
        setUsersData({
            ...usersData,
            userType: userRole?.value,
            roles: userRole?.value
        });
        setTempUserType(userRole);
        setUserTypeColorError(userRole ? true : false)
    }
    return (
        <>
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">{openModal} User</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>{openModal} User</span>
                                </div>
                                {loadingUserUpdate && <Loader />}
                                <div className="ibox-content">
                                    <form id="frm_User" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitAdminUsers(e)} >
                                        <div className="form-row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">First Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" name="firstName" value={usersData.firstName} onChange={e => TextChangeHandler(e)} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Last Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" name="lastName" value={usersData.lastName} onChange={e => TextChangeHandler(e)} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Email<span className="text-danger">*</span></label>
                                                <input type="email" disabled={openModal === "Add New" ? false : true} className="form-control ffPoppins" name="email" value={usersData.email} onChange={e => TextChangeHandler(e)} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Phone<span className="text-danger">*</span></label>
                                                <input type="number" minLength={10} maxLength={12} className="form-control ffPoppins" name="phone" value={usersData.phone} onChange={e => TextChangeHandler(e)} required />
                                            </div>
                                            {
                                                openModal === "Add New" &&
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                    <label className="frm-label">Password<span className="text-danger">*</span></label>
                                                    <input type="password" className="form-control ffPoppins" name="password" value={usersData.password} onChange={e => TextChangeHandler(e)} required />
                                                </div>
                                            }
                                            {
                                                openModal === "Add New" &&
                                                <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                    <label className="frm-label">Confirm Password<span className="text-danger">*</span></label>
                                                    <input type="password" className="form-control ffPoppins" name="confirmPassword" value={usersData.confirmPassword} onChange={e => TextChangeHandler(e)} required />
                                                </div>
                                            }
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label"> User Type<span className="text-danger">*</span></label>
                                                <Select
                                                    className="ffPoppins"
                                                    options={adminUsersRole}
                                                    value={tempUserType}
                                                    onChange={handleChangeRole}
                                                    styles={{
                                                        control: (provided, state) =>
                                                            !userTypeColorError && userTypeColorError !== null
                                                                ? {
                                                                    ...provided,
                                                                    boxShadow: "0 0 0 1px #dc3545 !important",
                                                                    border: "1px #dc3545 !important"
                                                                }
                                                                : provided
                                                    }} />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Status<span className="text-danger">*</span></label>
                                                <Select
                                                    className="ffPoppins"
                                                    options={statusType}
                                                    value={tempIsActive}
                                                    onChange={handleChangeStatus}
                                                />
                                            </div>

                                            <div className="form-group col-xs-12">
                                                <div className="mt-3">
                                                    <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                    <button type="reset" className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal">Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
