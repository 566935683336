import React from 'react'
import Loader from '../../../components/Loader/Loader'
import { INSERT_UPDATE_RATES } from '../../../../graphql/mutation/RatesMutation'
import { useMutation } from '@apollo/client'
import { PerItems, Percentage, RateType } from '../../../components/Constant'
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as $ from 'jquery';

export const InserUpdateRates = ({ rate ,setRates ,refetch}) => {
    const [insert_update_rate, { loading }] = useMutation(INSERT_UPDATE_RATES);
    const formOnSubmitRate = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity() || !rate.rateColorError || !rate.perColorError || !rate.amountColorError) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            const { rateData, id, openModal, Old_Data } = rate;
            let input = {};
            let old_value = {};
            let log = {};
            if (openModal === "Edit") {
                for (var key in Old_Data) {
                    if (Old_Data[key] !== rateData[key]) {
                        old_value[key] = Old_Data[key]
                        input[key] = rateData[key]
                    }
                }
                input.id = id
            } else {
                input = { ...rateData }
            }
            log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'rates',
                actionName: 'insertUpdateRates',
                oldValue: openModal === "Edit" ? JSON.stringify(old_value) : ""
            }
            insert_update_rate({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                refetch();
                form.classList.remove('was-validated');
                setRates(preRates => ({
                    ...preRates, rateData: {},
                    amountColorError: null,
                    perColorError: null,
                    rateColorError: null,
                    temp_amountType: '',
                    temp_per: '',
                    temp_rateType: ''
                }))
                form.reset();
                $('#RateModal').modal('hide');
                if (openModal === "Edit") {
                    toast.success("Rates updated successfully!", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("Rates added successfully!", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    }
    const handleChangeAmountType = (amountType) => {
        setRates(prevRates => ({
            ...prevRates,
            rateData: {
                ...rateData,
                amountType: amountType?.value,
            },
            temp_amountType: amountType,
            amountColorError: amountType ? true : false
        }))
    }
    const handleChangePer = (per) => {
        setRates(prevRates => ({
            ...prevRates,
            rateData: {
                ...rateData,
                per: per.value,
            },
            temp_per: per,
            perColorError: per ? true : false
        }))
    }
    const handleChangeRateType = (rateType) => {
        setRates(prevRates => ({
            ...prevRates,
            rateData: {
                ...rateData,
                rateType: rateType.value,
            },
            temp_rateType: rateType,
            rateColorError: rateType ? true : false,
        }))
    }
    const TextChangeHandler = (e) => {
        setRates(prevRates => ({ ...prevRates, rateData: { ...rateData, [e.target.name]: e.target.value } }))
    }
    const { rateData, temp_amountType, temp_per, temp_rateType, openModal, pageNo, limit, filter, filterText, sortKey, sortType, amountColorError, perColorError, rateColorError } = rate
    return (
        <>
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">{openModal} Rates</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                    </div>
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>{openModal} Rates</span>
                                </div>
                                {loading && <Loader />}
                                <div className="ibox-content">
                                    <form id="frm_Rate" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitRate(e)}>
                                        <div className="form-row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" name="name" value={rateData.name} onChange={e => TextChangeHandler(e)} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Amount<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" name="amount" value={rateData.amount} onChange={e => TextChangeHandler(e)} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">$/%<span className="text-danger">*</span></label>
                                                <Select
                                                    className="ffPoppins"
                                                    options={Percentage}
                                                    value={temp_amountType}
                                                    onChange={handleChangeAmountType}
                                                    styles={{
                                                        control: (provided, state) =>
                                                            !amountColorError && amountColorError !== null
                                                                ? {
                                                                    ...provided,
                                                                    boxShadow: "0 0 0 1px #dc3545 !important",
                                                                    border: "1px #dc3545 !important"
                                                                }
                                                                : provided
                                                    }} />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Per Item<span className="text-danger">*</span></label>
                                                <Select
                                                    className="ffPoppins"
                                                    options={PerItems}
                                                    value={temp_per}
                                                    onChange={handleChangePer}
                                                    styles={{
                                                        control: (provided, state) =>
                                                            !perColorError && perColorError !== null
                                                                ? {
                                                                    ...provided,
                                                                    boxShadow: "0 0 0 1px #dc3545 !important",
                                                                    border: "1px #dc3545 !important"
                                                                }
                                                                : provided
                                                    }} />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Type<span className="text-danger">*</span></label>
                                                <Select
                                                    className="ffPoppins"
                                                    options={RateType}
                                                    value={temp_rateType}
                                                    onChange={handleChangeRateType}
                                                    styles={{
                                                        control: (provided, state) =>
                                                            !rateColorError && rateColorError !== null
                                                                ? {
                                                                    ...provided,
                                                                    boxShadow: "0 0 0 1px #dc3545 !important",
                                                                    border: "1px #dc3545 !important"
                                                                }
                                                                : provided
                                                    }} />
                                            </div>
                                        </div>
                                        <div className="form-group col-xs-12">
                                            <div className="mt-3">
                                                <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                <button type="reset" className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" >Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
