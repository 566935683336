import React, { useState } from 'react'
import { AccessDenied } from '../../../components/AccessDenied';
import { useQuery } from '@apollo/client';
import { GET_COUNTRY } from '../../../../graphql/query/CountryQuery';
import Loader from '../../../components/Loader/Loader';
import Pagination from '../../../components/Common/Pagination';
import { GetCounty } from './GetCounty';
import { InsertUpdate } from './InsertUpdate';
import { statusType } from '../../../components/Constant';
import * as $ from 'jquery';

export const Country = () => {
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'))
    const user = JSON.parse(localStorage.getItem('loggedUser'));
    const [country, setCountry] = useState({
        name: '',
        code: '',
        isActive: true,
        status: null,
        openModal: 'Add New',
        limit: genSettings?.paginationLimit || 10,
        pageNo: 0,
        filter: "",
        filterText: '',
        sortKey: 'createdAt',
        sortType: -1,
        id: null,
        Old_Data: null,
    })
    const [initialState] = useState({
        id: null,
        name: '',
        code: '',
        isActive: true,
        status: null,
    });
    const { data, loading, refetch } = useQuery(GET_COUNTRY, {
        variables: { "page": country.pageNo + 1, "limit": country.limit, "filter": country?.filter, "sort": { key: country.sortKey, type: country.sortType } },
        fetchPolicy: 'cache-and-network'
    })

    const addNewCountry = (e) => {
        e.preventDefault();
        setCountry(prevCountry => ({ ...prevCountry, ...initialState, status: statusType[0], openModal: 'Add New' }))
        $('#frm_Country').removeClass('was-validated');
        $('#CountryModal').modal('show');
    }
    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchCountries()
        }
    }
    const searchCountries = () => {
        setCountry(prevCountry => ({ ...prevCountry, pageNo: 0, filter: country.filterText }))
    }
    const onResetSearch = () => {
        setCountry(prevCountry => ({ ...prevCountry, pageNo: 0, filter: "", filterText: "" }))
    }
    const handlePageClick = data => {
        setCountry(prevCountry => ({ ...prevCountry, pageNo: data?.selected }))

    };


    if (user?.roles === 'standard') return <AccessDenied />
    else
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 p-0">
                        <div className="mb-20">
                            <p className="mainTitle m-0 mt_-15">Country</p>
                            <span className="borderBottomOfTitle"></span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="float-right">
                            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewCountry(e)}> <i className="fa fa-plus"></i> Add New Country</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12 p-0">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span>Country Details</span>
                            </div>
                            <div className="ibox-content p-0">
                                {loading && <Loader />}
                                <div className="col-md-12 ph-20 mt-3">
                                    <div className="form-row">
                                        <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Search</label>
                                            <input type="text" value={country.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setCountry(prevCountry => ({ ...prevCountry, filterText: e.target.value }))} className="form-control" />
                                        </div>
                                        <div className="form-group col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                            <div className="mt-30">
                                                <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchCountries()} >Search</button>
                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                                <div className="pull-right">
                                                    <Pagination
                                                        pageCount={data && data?.getCountries && Math.ceil(data?.getCountries?.count / country?.limit)}
                                                        pageNo={country.pageNo}
                                                        handlePageClick={(data) => handlePageClick(data)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <GetCounty country={country} setCountry={setCountry} countries={data?.getCountries} refetch={refetch} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal show" id="CountryModal" data-backdrop="static" tabIndex="-1" role="dialog">
                    <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                        <InsertUpdate country={country} setCountry={setCountry} refetch={refetch} initialState={initialState}/>
                    </div>
                </div>
            </div>
        )
}
