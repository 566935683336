import React, { useContext, useEffect, useState } from 'react'
import Loader from '../../../components/Loader/Loader';
import { MY_PROFILE_CHANGE } from '../../../../graphql/mutation/UserMutation';
import { useMutation } from '@apollo/client';
import { getS3SignUrl } from '../../../../graphql/query/TicketQuery';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../../config';
import ChangeHeaderProfile from './MyProfileContex';


export const MyProfile = () => {
   const {user,setUser} = useContext(ChangeHeaderProfile);
    // const [user, setUser] = useState(JSON.parse(localStorage.getItem('loggedUser')));
    const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem('customer')));
    const [firstName, setFirstName] = useState(null);
    const [phone, setPhone] = useState(null);
    const [userProfile, setUserProfile] = useState('');
    const [isLoading, setisLoading] = useState(false);
    const [updateProfile] = useMutation(MY_PROFILE_CHANGE, {
        fetchPolicy: 'no-cache'
    });
    const [getPreSignedUrl] = useMutation(getS3SignUrl);

    const changeProfileIcon = (event) => {
        const { target: { validity, files: [file], } } = event;
        if (validity.valid) {
            const fileExtension = file?.type.split("/")[1];
            const key = `profile_${+new Date()}.${fileExtension}`;
            setisLoading(true);
            getPreSignedUrl({ variables: { type: key } }).then((data) => {
                if (data?.data?.getPreSignedUrl) {
                    fetch(data?.data?.getPreSignedUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": file?.type,
                        },
                        body: file,
                    }).then((response) => {
                        if (response?.ok && response?.status === 200) {
                            setUserProfile(`${BASE_URL}/${key}`);
                            setisLoading(false);
                        } else {
                            setisLoading(false);
                        }
                    }).catch((errr) => {
                        setisLoading(false);
                    });
                } else {
                    setisLoading(false);
                }
            }).catch((err) => {
                setisLoading(false);
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    }

    const onUpdateProfile = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if(userProfile){
            setisLoading(true);
        }
        if (!form.checkValidity()) {
            e.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            setisLoading(false);
            return false;
        } else {
            let input = {
                id: user.id,
                firstName: firstName || user.firstName,
                email: user.email,
                phone: phone || user.phone,
                userProfile: userProfile || user?.userProfile,
                receivingServiceType: customer?.receivingServiceType
            }
            updateProfile({ variables: { input: input } }).then((data, error) => {
                if (error) {
                    setisLoading(false);
                    error.graphQLErrors.forEach(err => {
                        toast.error(err.message, {
                            autoClose: 2000
                        })
                    });
                } else {
                    setisLoading(false);
                    localStorage.setItem('token', data.data.updateProfile.token);
                    localStorage.setItem('loggedUser', JSON.stringify(data.data.updateProfile.user));
                    setUser(JSON.parse(localStorage.getItem('loggedUser')));
                    localStorage.setItem('customer', JSON.stringify(data.data.updateProfile.customer));
                    toast.success("Profile updated.", {
                        autoClose: 2000
                    });
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    }

    return (
        <div className="container mrgnTop110">
            <div className='text-center mb-2'>
                <p className="headTitle">My Profile</p>
            </div>
            <div className="col-md-12">
                <div className="col-md-5 mAuto">
                    {isLoading && <Loader />}
                    <div className="col-md-12 pb-3 addItemMain mt-30 extra">
                        <div className="mb-4 mt-3">
                            <label className="headText mt-2">Profile</label>
                        </div>
                        <form id='changeProfile' onSubmit={(e) => { onUpdateProfile(e) }}>
                            <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2 boxInnerMargin">
                                <div className="text-center" style={{ marginTop: -45 }}>
                                    <label htmlFor="upload-button">
                                        <img src={userProfile || user?.userProfile || "../images/user.png"} alt="Img" className='round-img-big mr-2' />
                                    </label>
                                    <input type="file" id="upload-button" style={{ display: "none" }} onChange={(e) => changeProfileIcon(e)} />
                                </div>
                                <div className=" pl-0 pr-0">
                                    <div className="col-md-12 mb-4">
                                        <label className="mb-0 labelFs14">Name<span className="text-danger">*</span></label>
                                        <input placeholder="Name" className="input p-0 col-12 inputFs14" defaultValue={user?.firstName} name='firstName' onChange={(e) => setFirstName(e.target.value)} required />
                                    </div>
                                </div>
                                <div className=" pl-0 pr-0">
                                    <div className="col-md-12 mb-4">
                                        <label className="mb-0 labelFs14">Email</label>
                                        <p className="input p-0 col-12 inputFs14">{user?.email}</p>
                                    </div>
                                </div>
                                <div className="pl-0 pr-0">
                                    <div className="col-md-12 mb-4">
                                        <label className="mb-0 labelFs14">Mobile<span className="text-danger">*</span></label>
                                        <input placeholder="Mobile" className="input p-0 col-12 inputFs14" type="number" minLength={10} maxLength={12} defaultValue={user?.phone} name='phone' onChange={(e) => setPhone(e.target.value)} required />
                                    </div>
                                </div>
                                <div className="col-md-12 mt-5 mb-2">
                                    <button type='submit' className="sendBtn pointer">Save Changes</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div >
    )
}
