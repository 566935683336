import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import CreatableSelect from 'react-select/creatable';
import { delayedFunction } from './utils';
import { GET_ALL_ONLINE_STORE } from '../../../graphql/query/OnlineStoreQuery';

const OnlineStoreSelect = ({ onlineStoreId, getOnlineStore, setSearchStore ,page,limit,searchStore}) => {
  const [onlineStoreList, setOnlineStoreList] = useState([]);
  const [value, setValue] = useState(null);
  const [colorError, setColorError] = useState(undefined);
  const [searchKey, setSearchKey] = useState('');

  const { loading, data } = useQuery(GET_ALL_ONLINE_STORE, {
    variables: {
      page: page, 
      limit: limit,
      filter: searchStore,
      id: onlineStoreId?.id ? onlineStoreId?.id :  onlineStoreId,
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data && data?.getAllOnlineStores && data?.getAllOnlineStores.length > 0) {
      let OnlineStores = data?.getAllOnlineStores;
      const getOnlineStores = OnlineStores.map(d => ({ value: d.id, label: d.name }));
      setOnlineStoreList(getOnlineStores);
    }
  }, [loading, data]);

  useEffect(() => {
    if (onlineStoreId) {
      setDefaultOnlineStores();
    }
  }, [onlineStoreId, onlineStoreList]);

  useEffect(() => {
     delayedFunction(setSearchStore(searchKey))
  },[searchKey])

  const onlineStoreHandleChange = (selectedValues) => {
    if (selectedValues === null) {
      setSearchStore('');
      getOnlineStore(null);
      setValue(null);
      setSearchKey('');
    } else {
      if (getOnlineStore) {
        getOnlineStore(selectedValues);
      }
      setColorError(true);
      setValue(selectedValues);
    }
  };

  const setDefaultOnlineStores = () => {
    const id = (onlineStoreId?.id || onlineStoreId);
    const onlineStoreData = onlineStoreList.find(d => d.value === id);
    let data = { value: onlineStoreId?.id, label: onlineStoreId?.name };
    setValue(data?.value ? data : onlineStoreData);
  };

  return (
    <CreatableSelect
      className="basic-single ffPoppins"
      options={onlineStoreList}
      value={value}
      onChange={(value) => onlineStoreHandleChange(value)}
      onInputChange={(value) => setSearchKey(value)}
      styles={{
        control: (provided, state) =>
          !colorError && colorError !== undefined
            ? {
              ...provided,
              boxShadow: "0 0 0 1px #dc3545 !important",
              border: "1px #dc3545 !important"
            }
            : provided
      }}
    />
  );
};

export default OnlineStoreSelect;
