import React, { useEffect, useState } from 'react'
import ReactPaginate from 'react-paginate';
import { useMutation, useQuery } from '@apollo/client';
import * as $ from 'jquery';
import _ from 'lodash'
import moment from 'moment'
import { toast } from 'react-toastify';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import GET_PROMOTIONS from '../../../../graphql/query/PromotionQuery';
import { DELETE_PROMOTION, INSERT_UPDATE_PROMOTION } from '../../../../graphql/mutation/PromotionMutation';
import Loader from '../../../components/Loader/Loader';
import { PromotionalType } from '../../../components/Constant';
import { AccessDenied } from '../../../components/AccessDenied';
import confirmDeletion from '../../../components/Common/BootBox';

export const PromotionalCount = () => {
    const [genSettings] = useState(JSON.parse(localStorage.getItem('generalSettings')));
    const [user] = useState(JSON.parse(localStorage.getItem('loggedUser')))
    const [limit] = useState(genSettings?.paginationLimit || 10);
    const [openModal, setOpenModal] = useState('Add New');
    const [PromotionCountData, setPromotionCountData] = useState({});
    const [pageNo, setPageNo] = useState(1);
    const [filter, setFilter] = useState("");
    const [filterText, setFilterText] = useState('');
    const [sortKey, setSortKey] = useState('createdAt');
    const [sortType, setSortType] = useState(-1);
    const [oldValue, setOldValue] = useState({});
    const [temp_promoCodeType, setTempPromoCodeType] = useState(PromotionalType[0]);
    const [Promotional, setPromotionalData] = useState(null);
    const [totalPage, setTotalPage] = useState(null);

    const { loading, data ,refetch} = useQuery(GET_PROMOTIONS, {
        variables: { page: pageNo, limit: limit, filter: filter, sort: { key: sortKey, type: sortType } },
        fetchPolicy: "cache-and-network"
    })

    const [deletePromotion] = useMutation(DELETE_PROMOTION)
    const [insertUpdatePromotion, { loading: promotionalLoading }] = useMutation(INSERT_UPDATE_PROMOTION)

    const addNewPromotional = (e) => {
        e.preventDefault();
        setOpenModal('Add New');
        setTimeout(() => {
            $('form_promotional').removeClass('was-validated');
            $('#PromotionalModal').modal('show');
        }, 0);
    }
    useEffect(() => {
        $('.react-datepicker-wrapper').addClass('d-flex');
    }, [user]);

    useEffect(() => {
        if (data && data.getPromotions) {
            setPromotionalData(data.getPromotions);
            const totalPage = Math.ceil(data.getPromotions.count / limit);
            setTotalPage(totalPage);
        }
    }, [data, limit]);

    // useEffect(() => {
    //     if (openModal) {
    //         $('form_promotional').removeClass('was-validated');
    //         $('#PromotionalModal').modal('show');
    //     }
    // }, [openModal]);


    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchPromotionalCount(e)
        }
    }
    const searchPromotionalCount = (e) => {
        e.preventDefault()
        setFilter(filterText)
    }
    const onResetSearch = (e) => {
        e.preventDefault()
        setFilter("");
        setFilterText("");
    }
    const handlePageClick = data => {
        setPageNo(data?.selected + 1);
    };
    const sortData = (sortKey) => {
        const newSortType = (sortType === 1) ? -1 : 1;
        setSortKey(sortKey);
        setSortType(newSortType);
    };
    const editPromotional = (e, oldPromotional) => {
        e.preventDefault();
        if (oldPromotional.startDate && oldPromotional.endDate) {
            const updatedPromotional = _.cloneDeep(oldPromotional);
            updatedPromotional.startDate = moment(updatedPromotional.startDate)._d;
            updatedPromotional.endDate = moment(updatedPromotional.endDate)._d;
            setPromotionCountData(updatedPromotional);
            setOldValue(updatedPromotional);
        }
        setOpenModal('Edit');
        $('form_promotional').remove('was-validated')
        $('#PromotionalModal').modal('show');
    };
    const deletePromotionalDetails = async (e, id) => {
        e.preventDefault();
        const deletePromotion = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'promotions',
                actionName: 'deletePromotion',
                oldValue: ''
            };

            deletePromotion({ variables: { id: id, log: log } })
                .then(async ({ data }) => {
                    toast.success("Promotion deleted successfully", {
                        autoClose: 2000
                    })
                    refetch();
                }).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                })
        }
        confirmDeletion('Delete Promotional Detail' , 'Are you sure want to delete this record?',deletePromotion)
    };

    const TextChangeHandler = (e) => {
        setPromotionCountData(prevState => ({
            ...prevState,
            [e.target.name]: e.target.type === "number" && e.target.name !== "percentage"
                ? parseFloat(e.target.value)
                : e.target.value
        }));
    };
    const fromDateChange = (date) => {
        setPromotionCountData(prevState => ({
            ...prevState,
            startDate: date
        }));
    }

    const toDateChange = (date) => {
        setPromotionCountData(prevState => ({
            ...prevState,
            endDate: date
        }));
    }
    const handleChangePromoType = (value) => {
        setPromotionCountData(prevState => ({
            ...prevState,
            "promoCodeType": value?.value,
            "percentage": null,
            "fixedAmount": null
        }));
        setTempPromoCodeType(value);
    }

    const checkDate = (start, end) => {
        var mStart = moment(start);
        var mEnd = moment(end);
        return mStart.isBefore(mEnd);
    }

    const formOnSubmitPromotionalCount = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            let input = {};
            let old_value = {};
            let log = {};
            if (checkDate(PromotionCountData.endDate, PromotionCountData.startDate)) {
                toast.error("End date should be greater than Start date", {
                    autoClose: 2000
                })
            }
            else {
                if (openModal === "Edit") {
                    for (var obj in oldValue) {
                        if (oldValue[obj] !== PromotionCountData[obj]) {
                            input[obj] = PromotionCountData[obj]
                            old_value[obj] = oldValue[obj]
                        }
                    }
                    input.id = PromotionCountData?.id
                } else {
                    input = {
                        ...PromotionCountData, "promoCodeType": temp_promoCodeType?.value
                    }
                }
                log = {
                    action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                    actionOn: 'promotions',
                    actionName: "insertUpdatePromotion",
                    oldValue: openModal === "Edit" ? JSON.stringify(old_value) : ""
                }
                insertUpdatePromotion({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                    $('#PromotionalModal').modal('hide');
                    form.classList.remove('was-validated');
                    form.reset();
                    refetch();
                    setPromotionCountData([])
                    if (openModal === "Edit") {
                        toast.success("Promotion updated successfully", {
                            autoClose: 2000
                        })
                    } else {
                        toast.success("Promotion added successfully", {
                            autoClose: 2000
                        })
                    }
                }).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                })
                return true
            }
        }
    }
    if (user.roles === 'standard') return <AccessDenied />
    else
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 p-0">
                    <div className="mb-20">
                        <p className="mainTitle m-0 mt_-15">Promotional</p>
                        <span className="borderBottomOfTitle"></span>
                    </div>
                </div>
                <div className="col-md-4 p-0">
                    <div className="float-right">
                        <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewPromotional(e)}> <i className="fa fa-plus"></i> Add New Promotional</button>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Promotional Details</span>
                        </div>
                        <div className="ibox-content p-0">
                            {loading && <Loader />}
                            <div className="col-md-12 ph-20 mt-3">
                                <div className="form-row">
                                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Search</label>
                                        <input type="text" className="form-control" value={filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setFilterText(e.target.value)} />
                                    </div>
                                    <div className="form-group col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                        <div className="mt-30">
                                            <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={(e) => searchPromotionalCount(e)}>Search</button>
                                            <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={(e) => onResetSearch(e)}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="ibox-title border-0">
                                <span> List</span>
                                <div className="pull-right">
                                    <ReactPaginate
                                        previousLabel={'Back'}
                                        nextLabel={'Next'}
                                        pageCount={totalPage}
                                        marginPagesDisplayed={2}
                                        pageRangeDisplayed={5}
                                        onPageChange={handlePageClick}
                                        containerClassName={'pagination mb-0'}
                                        subContainerClassName={'page-item'}
                                        activeClassName={'active'}
                                        pageLinkClassName={'pagination-link'}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table themeTable table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center">No.</th>
                                            <th className={`sortColumn ${(sortKey === 'code') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('code')} >Code</th>
                                            <th className={`sortColumn ${(sortKey === 'promoCodeType') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('promoCodeType')} >Promotional Code Type</th>
                                            <th className={`sortColumn ${(sortKey === 'percentage') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('percentage')} >Percentage</th>
                                            <th className={`sortColumn ${(sortKey === 'minOrderAmount') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('minOrderAmount')} >Min Oder Amount</th>
                                            <th className={`sortColumn ${(sortKey === 'maxCashBackAmount') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('maxCashBackAmount')} >Max Cashback Amount</th>
                                            <th className={`sortColumn ${(sortKey === 'fixedAmount') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('fixedAmount')} >Fix Amount</th>
                                            <th className={`sortColumn ${(sortKey === 'startDate') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('startDate')} >Start Date</th>
                                            <th className={`sortColumn ${(sortKey === 'endDate') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('endDate')} >End Date</th>
                                            <th className={`sortColumn ${(sortKey === 'maxUsage') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                onClick={(e) => sortData('maxUsage')} >Max Usage</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            Promotional && Promotional?.data && Promotional?.data?.length > 0
                                                ? Promotional?.data && Promotional?.data.map((d, i) => {
                                                    let startdate = new Date((d?.startDate).toString())
                                                    let enddate = new Date((d?.endDate).toString())
                                                    return (
                                                        <tr key={d?.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editPromotional(e, d)}>
                                                            <td className=" text-center">{i + [pageNo - 1] * 10 + 1}</td>
                                                            <td>{d?.code}</td>
                                                            <td>{d?.promoCodeType.charAt(0).toUpperCase() + d?.promoCodeType.slice(1)}</td>
                                                            <td>{d?.percentage && (d?.percentage + '%')}</td>
                                                            <td>{d?.minOrderAmount && ('$' + d?.minOrderAmount)}</td>
                                                            <td>{d?.maxCashBackAmount && ('$' + d?.maxCashBackAmount)}</td>
                                                            <td>{d?.fixedAmount && ('$' + d?.fixedAmount)}</td>
                                                            <td>{`${startdate.getDate()}/${startdate.getMonth() + 1}/${startdate.getFullYear()}`}</td>
                                                            <td>{`${enddate.getDate()}/${enddate.getMonth() + 1}/${enddate.getFullYear()}`}</td>
                                                            <td>{d?.maxUsage}</td>
                                                            <td>
                                                                <div className="d-flex flex-row">
                                                                    <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editPromotional(e, d)}>
                                                                        <img src="../images/edit.png" alt="Img" className="width15" />
                                                                    </span>
                                                                    <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => { deletePromotionalDetails(e, d.id); }}>
                                                                        <img src="../images/bin.png" alt="Img" className="width15" />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                : <tr>
                                                    <td style={{ textAlign: 'center' }} colSpan={11} >No Records Found!</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <div className="modal show" id="PromotionalModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                    <div className="modal-content ">
                        <div className="modal-header" style={{ paddingTop: "0px" }}>
                            <div>
                                <p className="mainTitle m-0 mt_-15">{openModal} Promotional</p>
                                <span className="borderBottomOfTitle "></span>
                            </div>
                            <div>
                                <button data-dismiss="modal" onClick={() => { setPromotionCountData([]) }}
                                    type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    {promotionalLoading && <Loader />}
                                    <div className="col-md-12 p-0">
                                        <div className="ibox">
                                            <div className="ibox-title">
                                                <span>{openModal} Promotional Details</span>
                                            </div>
                                            <div className="ibox-content">
                                                <form id="form_promotional" className=" needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitPromotionalCount(e)}>
                                                    <div className="form-row">
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Code<span className="text-danger">*</span></label>
                                                            <input type="text" defaultValue={PromotionCountData.code} className="form-control ffPoppins" name="code" placeholder="Enter Promotional Code" required onChange={(e) => { TextChangeHandler(e) }} />
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Promotional Code Type <span className="text-danger">*</span></label>
                                                            <Select
                                                                className="ffPoppins"
                                                                options={PromotionalType}
                                                                value={temp_promoCodeType}
                                                                onChange={(data) => handleChangePromoType(data)}
                                                            />
                                                        </div>
                                                        {
                                                            temp_promoCodeType && temp_promoCodeType.value === "Percentage" &&
                                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                                <label className="frm-label">Percentage <span className="text-danger">*</span></label>
                                                                <input defaultValue={PromotionCountData?.percentage} type="number" className="form-control ffPoppins" name="percentage" onChange={(e) => { TextChangeHandler(e) }} required />
                                                            </div>
                                                        }
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Min Oder Amount</label>
                                                            <input defaultValue={PromotionCountData?.minOrderAmount} type="number" className="form-control ffPoppins" name="minOrderAmount" onChange={(e) => { TextChangeHandler(e) }} />
                                                        </div>

                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Max Cashback Amount</label>
                                                            <input defaultValue={PromotionCountData?.maxCashBackAmount} type="number" className="form-control ffPoppins" name="maxCashBackAmount" onChange={(e) => { TextChangeHandler(e) }} />
                                                        </div>

                                                        {
                                                            temp_promoCodeType && temp_promoCodeType.value === "fixAmount" &&
                                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                                <label className="frm-label">Fix Amount <span className="text-danger">*</span></label>
                                                                <input defaultValue={PromotionCountData?.fixedAmount} type="number" name="fixedAmount" className="form-control ffPoppins" onChange={(e) => { TextChangeHandler(e) }} required />
                                                            </div>
                                                        }
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Start Date <span className="text-danger">*</span></label>
                                                            <DatePicker
                                                                className="form-control ffPoppins d-flex"
                                                                placeholderText="mm/dd/yyyy"
                                                                name="startDate"
                                                                selected={PromotionCountData?.startDate}
                                                                onChange={fromDateChange}
                                                                required
                                                            />
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">End Date <span className="text-danger">*</span></label>
                                                            <DatePicker
                                                                className="form-control ffPoppins d-flex"
                                                                placeholderText="mm/dd/yyyy"
                                                                minDate={PromotionCountData?.startDate}
                                                                selected={PromotionCountData?.endDate}
                                                                onChange={toDateChange}
                                                                name="endDate"
                                                                required
                                                            />
                                                        </div>

                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Note</label>
                                                            <input defaultValue={PromotionCountData.note} type="text" name="note" className="form-control ffPoppins" onChange={(e) => { TextChangeHandler(e) }} />
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Max Usage</label>
                                                            <input defaultValue={PromotionCountData.maxUsage} type="number" name="maxUsage" className="form-control ffPoppins" onChange={(e) => { TextChangeHandler(e) }} />
                                                        </div>

                                                        <div className="form-group col-xs-12">
                                                            <label className="frm-label"></label>
                                                            <div className="mt-3">
                                                                <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" onClick={() => { setPromotionCountData([]) }}>Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </div >
    )
}
