import React from 'react';
import { useQuery } from '@apollo/client';
import moment from 'moment';
import { GET_ASSIGNINFO } from '../../../graphql/query/shipmentQuery';
import { useParams } from 'react-router-dom';

const TrackPackage = () => {
    const { packageId } = useParams();
    const { data } = useQuery(GET_ASSIGNINFO, {
        variables: { packageBoxId: packageId || null },
        fetchPolicy: 'no-cache'
    });

    const trackData = data && data?.getAssignInfo?.Box;
    const signature = data && data?.getAssignInfo?.signature;

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="mb-20">
                    <p className="mainTitle m-0 mt_-15">Track Package</p>
                    <span className="borderBottomOfTitle"></span>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span> Package Tracking Details</span>
                        </div>
                        <div className="ibox-content p-0">
                            <div className="table-responsive">
                                <table className="table themeTable table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center"> No.</th>
                                            <th>Date</th>
                                            <th>Time</th>
                                            <th>Detail</th>
                                            <th>From</th>
                                            <th>To</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {trackData && trackData.length > 0 &&
                                            trackData.map((d, i) => (
                                                <tr key={i}>
                                                    <td className="text-center">{i + 1}</td>
                                                    <td>{moment(d.date).format('MM/DD/YYYY')}</td>
                                                    <td>{moment(d.date).format('h:mm a')}</td>
                                                    <td>{d?.status}</td>
                                                    <td>
                                                        {d?.assignFrom?.userType !== 'admin' && (
                                                            <>
                                                                {`${d?.assignFrom?.firstName || ''} ${d?.assignFrom?.lastName || ''}` || ''}<br />
                                                                <span className={d?.assignFrom?.userType ? "shipStaus" : ''}>{d?.assignFrom?.userType}</span>
                                                            </>
                                                        )}
                                                    </td>
                                                    <td>
                                                        {d?.assignTo?.userType !== 'admin' && (
                                                            <>
                                                                {`${d?.assignTo?.firstName || ''} ${d?.assignTo?.lastName || ''}` || ''}<br />
                                                                {(d?.status === 'YOUR SHIPMENT HAS BEEN DELIVERED.' || (d?.assignTo === null && d?.status === 'YOUR SHIPMENT HAS BEEN PICKED UP.')) && signature && (
                                                                    <>
                                                                        <a href={signature} download>View Signature</a> <br />
                                                                    </>
                                                                )}
                                                                <span className={d?.assignTo?.userType ? "shipStaus" : ''}>{d?.assignTo?.userType}</span>
                                                            </>
                                                        )}
                                                    </td>
                                                </tr>
                                            ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default TrackPackage;
