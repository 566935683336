import gql from 'graphql-tag';

export const GET_RATES = gql`
query getRates($page: Int, $limit: Int, $filter: String,$sort:rateSort){
  getRates(page: $page,limit: $limit, filter: $filter,sort:$sort){
    count
    data {
      id
      name
      amount
      amountType
      per
      rateType
      isActive
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
}
`;

export const GET_RATES_TYPE_WISE = gql`
query getRateTypeWiseList($rateType: String!, $page: Int, $limit: Int, $filter: String , $id: [String]) {
  getRateTypeWiseList(rateType: $rateType , page: $page , limit: $limit , filter: $filter , id: $id){
    id
    name
    amount
    amountType
    per
    rateType
    isActive
    createdBy
    createdAt
    updatedBy
    updatedAt   
  }
}
`;

export const GET_ALL_RATES_TYPES = gql`
query getAllRatesList {
  getAllRatesList {
    id
    name
    amount
    amountType
    per
    rateType
    isActive
    createdBy
    createdAt
    updatedBy
    updatedAt   
  }
}
`;