import React, { useState } from 'react'
import { toast } from 'react-toastify';
import { HeaderComp } from '../../../components/HeaderComp';
import GoogleMapReact from 'google-map-react';
import { useMutation } from '@apollo/client';
import { CONTACT_US } from '../../../../graphql/mutation/contact-us-webMutation';
import { Footer } from '../../../layouts/Footer';
import Loader from '../../../components/Loader/Loader';
import * as $ from 'jquery';

const AnyReactComponent = ({ text }) => <div>{text}</div>;
const ContactUsWeb = () => {

    const [firstName, setFirstName] = useState('');
    const [email, setEmail] = useState('');
    const [subject, setSubject] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');
    const [errorFlag, seterrorFlag] = useState(false);

    const [insertContactUs, { loading, error }] = useMutation(CONTACT_US);

    const defaultProps = {
        center: {
            lat: 59.95,
            lng: 30.33,
        },
        zoom: 11,
    };

    const onSubmit = (event) => {
        // eslint-disable-next-line
        const emailCheckRegex = /^(([^<>()[\]\.,;:\s@"]+(\.[^<>()[\]\.,;:\s@"]+)*)|(".+"))@(([^<>()[\]\.,;:\s@"]+\.)+[^<>()[\]\.,;:\s@"]{2,})$/i;
        const contactCheckRegex = /^\(?\d{10,12}\)?[-.\s]?$/;
        event.preventDefault();
        seterrorFlag(false)
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000,
                position: "top-right",
            })
        } else if (!emailCheckRegex.test(email)) {
            toast.error('Please enter valid email', {
                autoClose: 2000
            })
            return false;
        } else if (!contactCheckRegex.test(phone) && phone !== '') {
            toast.error('Please enter valid phone number', {
                autoClose: 2000
            })
            return false;
        } else {
            const input = { "input": { firstName, email, subject, phone, message } }
            insertContactUs({ variables: input }).then(async ({ data }) => {
                toast.success(data?.insertContactUs, {
                    autoClose: 2000,      
                })
                setEmail('');
                setFirstName('');
                setMessage('');
                setPhone('');
                setSubject('');
                $('#frm_Receiver').removeClass('was-validated');
            }).catch((err) => {
                if (err && !errorFlag) {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                    seterrorFlag(true);
                }
            })
        }

    }
    return (

        <div className="container-fluid p-0 bgWhite">
            <div className="aboutImg"
                style={{
                    backgroundImage: "url(../images/sliderContact.png)",
                }}
            >
                <HeaderComp Title={'Contact Us'} />
            </div>
            <div style={{ height: '40vh', width: '100%' }}>
                <GoogleMapReact
                    bootstrapURLKeys={{}}
                    defaultCenter={defaultProps?.center}
                    defaultZoom={defaultProps?.zoom}
                >
                    <AnyReactComponent
                        lat={59.955413}
                        lng={30.337844}
                        text="My Marker"
                    />
                </GoogleMapReact>
            </div>
            <div className="pb-5">
                <div className='container mt-5'>
                    <div className="col-md-12 row p-0 m-0">
                        <div className="col-lg-4 mb-lg-0 mb-3">
                            <div className="webContactBox">
                                <div className="flex">
                                    <div className="footIconDiv mr-4">
                                        <i className="fs25 fa fa-home themeBlue" aria-hidden="true"></i>
                                    </div>
                                    <h5 className="">Address</h5>
                                </div>
                                <p className="mb-0 labelFs14 pl-2">98 Jansen Ave</p>
                                <p className="mb-0 labelFs14 pl-2">Essington, PA 19029 USA</p>

                            </div>
                        </div>
                        <div className="col-lg-4 mb-lg-0 mb-3">
                            <div className="webContactBox">
                                <div className="flex">
                                    <div className="footIconDiv mr-4">
                                        <i className="fs25 fa fa-phone themeBlue" aria-hidden="true"></i>
                                    </div>
                                    <h5 className="">Phone</h5>
                                </div>
                                <p className="mb-0 labelFs14 pl-2">+1(267)-230-3365 <br />
                                    +1(267)-287-3561</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div className="webContactBox">
                                <div className="flex">
                                    <div className="footIconDiv mr-4">
                                        <i className="fs25 fa fa-envelope themeBlue" aria-hidden="true"></i>
                                    </div>
                                    <h5 className="">Email</h5>
                                </div>
                                <p className="mb-0 wrd-break labelFs14 pl-2">info@Cargoatlantic.com</p>
                            </div>
                        </div>
                    </div>

                    <form id='frm_Receiver' className="needs-validation" noValidate="noValidate" onSubmit={(e) => onSubmit(e)}>
                        <div className="form-row col-md-12">
                            {loading && <Loader />}
                            <h1 className="text-center mt-5 mb-5">Write A Message</h1>
                            <div className="col-md-11 row mAuto mb-4">
                                <div className="col-md-6 mb-1 form-group">
                                    <input type='text' placeholder="First Name*" value={firstName} className="form-control pb-2 txt-BoxField  col-md-12 webInput p-0 col-12 inputFs14" onChange={(e) => setFirstName(e.target.value)} required />
                                </div>
                                <div className="col-md-6 form-group">
                                    <input type='email' placeholder="Email Address*" value={email} className="form-control txt-BoxField col-md-12 webInput p-0 col-12 inputFs14" onChange={(e) => setEmail(e.target.value.toLowerCase())} required />
                                </div>
                            </div>
                            <div className="col-md-11 row mAuto mb-4">
                                <div className="col-md-6 mb-1 form-group">
                                    <input type='text' placeholder="Your Subject*" value={subject} className="form-control txt-BoxField col-md-12 webInput p-0 col-12 inputFs14" onChange={(e) => setSubject(e.target.value)} required />
                                </div>
                                <div className="col-md-6 form-group">
                                    <input type='number' minLength="10" maxLength="12" placeholder="Your Phone Number" value={phone} className="txt-BoxField col-md-12 webInput p-0 col-12 inputFs14" onChange={(e) => setPhone(e.target.value)} />
                                </div>
                            </div>
                            <div className="col-md-11 row mAuto mb-3">
                                <div className="col-md-12 form-group">
                                    <textarea placeholder="Message*" rows="4" value={message} className="form-control txt-BoxField col-md-12 webInput p-0 col-12 inputFs14" onChange={(e) => setMessage(e.target.value)} required />
                                </div>
                            </div>
                            <div className="form-group col-md-12 text-center">
                                <button type='submit' className="sendBtn pointer">Send  Message</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </div>
    )
}
export default ContactUsWeb