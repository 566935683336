import { gql } from "@apollo/client";

export const GET_WAREHOUSES = gql`
  query getWareHouses($page: Int, $limit: Int, $filter: String, $sort: wareHouseSort) {
    getWareHouses(page: $page, limit: $limit, filter: $filter, sort: $sort) {
      count
      data {
        id
        name
        code
        addLine1
        addLine2
        countryId {
          id
          name
          code
        }
        stateId {
          id
          name
        }
        city
        zipcode
        phone
        isActive
        isDefault
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
export const GET_WAREHOUSE_LOCATIONS = gql`
query getWareHouseLocations($page: Int, $limit: Int, $filter: String,$sort:wareHouseLocationSort,$warehouseId:ID){
  getWareHouseLocations(page: $page,limit: $limit, filter: $filter,sort:$sort,warehouseId:$warehouseId){
    count
    data {
      id
      warehouseId
      height
      width
      length
      boxRacNo
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;

export const GET_WAREHOUSE_USER = gql`
query getWarehouseUsers($page: Int, $limit: Int, $filter: String,$sort:wareHouseUserSort,$warehouseId: ID){
  getWarehouseUsers(page: $page,limit: $limit, filter: $filter,sort:$sort,warehouseId:$warehouseId){
    count
    data {
      id
      warehouseId
      firstName
      lastName
      email
      password
      addLine1
      addLine2
      countryId {
        id
        name
        code
      }
      stateId {
        id
        name
      }
      assignClosestCity {
        id
        name
        code
      }
      city
      zipCode
      phone
      userType
      contractor
      reference {
        id 
        firstName
        lastName
        userType
      }
      locationLat
      locationLang
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}

`

export const GET_WAREHOUSE_LIST = gql`
query getAllShipments{
  getAllShipments{
    id
    name
    code
    addLine1
    addLine2
    city
    zipcode
    phone
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}

`