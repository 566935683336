import React, { Fragment } from 'react'
import { Footer } from '../../../layouts/Footer';
import { HeaderComp } from '../../../components/HeaderComp';
import { useQuery } from '@apollo/client';
import { GET_HOW_IT_WORKS } from '../../../../graphql/query/HomeWebQuery';

 const HomeWeb = () => {
    const{data} = useQuery(GET_HOW_IT_WORKS);
    return (
        <div className="container-fluid p-0 bgWhite">
            <div className="homeImg" style={{ backgroundImage: "url(../images/sliderHome.png)" }}>
                <HeaderComp
                    Title={"Our Delivery Service"}
                    Intro={ "We are a team of logistics experts who have leveraged technology to keep our shipping service efficient while keeping you informed until you receive your package. Our goal is to satisfy our customers one package at a time." }
                />
            </div>
            <div className="p-5">
                <div className="text-center mb-5">
                    <h4>How It Works</h4>
                    <div className="redDividerToSmall mb-3 mAuto" />
                </div>
        
            <div className="container homeCenter">
              {data && data?.getHowItWorks?.data.length > 0 &&
                // eslint-disable-next-line array-callback-return
                (data?.getHowItWorks?.data || []).map((_, j) => {
                  if (j === 0 || j === 4 || j === 8)
                    return (
                      <React.Fragment key={j}>
                        {j === 8 ? (
                          <div className="ml-4" >
                            <div className="ml-4 mb-4 displayNone">
                              <img
                                src="../images/bottomArrow.png"
                                alt="Img"
                                height="50px"
                                className="ml-3"
                              />
                            </div>
                          </div>
                        ) : null}
                        <div className="homeFlex">
                          {data?.getHowItWorks?.data  &&
                            data?.getHowItWorks?.data?.length  > 0 &&
                            // eslint-disable-next-line array-callback-return
                            data?.getHowItWorks?.data?.map((_, i) => {
                              if (
                                (j === 0 &&
                                  (i === 0 ||
                                    i === 1 ||
                                    i === 2 ||
                                    i === 3)) ||
                                (j === 4 &&
                                  (i === 4 ||
                                    i === 5 ||
                                    i === 6 ||
                                    i === 7)) ||
                                (j === 8 && (i === 8 || i === 9))
                              ) {
                                if (i === 4) i = 7;
                                else if (i === 5) i = 6;
                                else if (i === 6) i = 5;
                                else if (i === 7) i = 4;
                                return (
                                  <Fragment key={data?.getHowItWorks?.data[i].stepNo}>
                                    <div
                                      className={`${
                                        i === 0 || i === 7 || i === 8
                                          ? "webCenter"
                                          : ""
                                      }`}
                                      style={{ width: 150 }}
                                    >
                                      <div
                                        className={`${
                                          i === 0 || i === 8
                                            ? "HomeCircleBlue mLeft40"
                                            : i === 1 ||
                                              i === 3 ||
                                              i === 6 ||
                                              i === 5 ||
                                              i === 9
                                            ? "HomeCircleBlue mLeft40Blue"
                                            : i === 7
                                            ? "HomeCircleBlue mLeft40"
                                            : "HomeCircleBlue mLeft40Blue"
                                        }`}
                                      >
                                        <div className="homeSubCircle">
                                          <div
                                            className={`${
                                              i === 0 ||
                                              i === 2 ||
                                              i === 6 ||
                                              i === 4 ||
                                              i === 8
                                                ? "HomeBlueCircle"
                                                : i === 1 ||
                                                  i === 3 ||
                                                  i === 5 ||
                                                  i === 7 ||
                                                  i === 9
                                                ? "HomeBlueCircle"
                                                : ""
                                            } text-center`}
                                          >
                                            <h1 className="white pt-2">
                                              {data?.getHowItWorks?.data[i].stepNo}
                                            </h1>
                                          </div>
                                        </div>
                                      </div>
                                      <p className="mt-2 mb-0 inputFs14 text-center">
                                        {data?.getHowItWorks?.data[i].label}
                                      </p>
                                      <p className="labelFs10 text-center">
                                        {data?.getHowItWorks?.data[i].details}
                                      </p>
                                      {i !== 9 ? (
                                        <img
                                          src="../images/bottomArrow.png"
                                          alt="Img"
                                          height="50px"
                                          className="mb-4 imgDisplay"
                                        />
                                      ) : null}
                                      {j === 0 && i === 3 ? (
                                        <div>
                                          <div className="float-right homeMrgn mb-4 displayNone">
                                            <img
                                              src="../images/bottomArrow.png"
                                              alt="Img"
                                              height="50px"
                                            />
                                          </div>
                                        </div>
                                      ) : null}
                                    </div>
                                    {(j === 0 && i !== 3) ||
                                    (j === 8 && i === 8) ? (
                                      <div className=" mt-4 ml-3 mr-3 displayNone">
                                        <img
                                          src="../images/rightArrow.png"
                                          alt="Img"
                                          className="homeImgWidth"
                                        />
                                      </div>
                                    ) : null}
                                    {j === 4 && i !== 4 ? (
                                      <div className="mt-4 ml-3 mr-3 displayNone">
                                        <img
                                          src="../images/leftArrow.png"
                                          alt="Img"
                                          className="homeImgWidth"
                                        />
                                      </div>
                                    ) : (
                                      ""
                                    )}
                                  </Fragment>
                                );
                              }
                            })}
                        </div>
                      </React.Fragment>
                    );
                })}
            </div>
            </div>
            <Footer />
        </div>
    )
}

export default HomeWeb
