import { gql } from "@apollo/client"

export const GENERATE_INVOICE = gql`
mutation generateInvoice {
    generateInvoice
  }
  `
export const RESEND_INVOICE = gql`
  mutation resendNotification($invoiceId: String!) {
    resendNotification(invoiceId: $invoiceId)
  }`

export const DELETE_INVOICE = gql`
  mutation deleteInvoice($id:ID!, $log: LogInput) {
    deleteInvoice(id: $id, log: $log) {
      id
      invoiceNo
      orderId
      customerInfo{
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      orderDate
      trackingno
      orderStatus
      receiverInfo{
        name
        phone
        address1
        address2
        city
        closestCityId{
          id
          name
        }
        stateId{
          id
          name
        }
        countryId{
          id
          name
        }
        zipCode
      }
      receiverId
      ItemLevel
      OrderLevel
      ShipmentLevel
      Default
      charges
      boxDimension{
        id
        box_Num
        len
        width
        height
        weight
        dimensionalWeight
        cubicFeet
        rateIds
        boxDimensionId
        isDimension
        barcode
        status{
          status
          date
          type
        }
        assign{
          assignTo
          assignFrom
          status
          date
        }
      }
      packageBoxId
      packageDate
      totalAmount
      totalAmountNGN
      paymentData
      paymentSuccess
      status
      offlineRefNo
      customerId
      createdInvoiceData
      ShipmentLevel
      paymentUrl
      shipmentNo
    }
  }`

export const SINGLE_UPDATE = gql`
mutation updateSingleInvoiceStatus($rowId:ID!,$invoiceId: String){
  updateSingleInvoiceStatus(rowId:$rowId,invoiceId:$invoiceId)
}`
export const UPLOAD_EVIDANCE = gql`
mutation uploadEvidenceAndMarkAsPaid($rowId:ID!,$invoiceId: String,$evidence: String,$amount:Float,$email:String){
  uploadEvidenceAndMarkAsPaid(rowId:$rowId,invoiceId:$invoiceId,evidence:$evidence,amount:$amount,email:$email)
}`


export const DOWLOAD_INVOICE_PDF = gql`
mutation downloadInvoice($url: String!) {
  downloadInvoice(url: $url)
}`