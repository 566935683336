import { useMutation, useQuery } from '@apollo/client'
import React, { useState } from 'react'
import { useParams } from 'react-router-dom'
import { GET_WAREHOUSE_USER } from '../../../../../graphql/query/WarehouseQuery'
import Loader from '../../../../components/Loader/Loader'
import Pagination from '../../../../components/Common/Pagination'
import { GetWarehouseUser } from './GetWarehouseUser'
import { AccessDenied } from '../../../../components/AccessDenied'
import { InsertUpdateWarehouseUser } from './InsertUpdateWarehouseUser'
import * as $ from 'jquery';
import { RESETPASSWORD_CUSTOMER } from '../../../../../graphql/mutation/CustomerMutation'
import { toast } from 'react-toastify'

export const WarehouseUser = () => {
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'))
    const user = JSON.parse(localStorage.getItem('loggedUser'))
    const { warehouseId } = useParams();
    const [warehouseUser, setWarehouseUser] = useState({
        limit: genSettings?.paginationLimit || 10,
        openModal: 'Add New',
        pageNo: 1,
        filter: "",
        filterText: '',
        sortKey: 'createdAt',
        sortType: -1,
        id: null,
        temp_countryId: null,
        temp_stateId: null,
        temp_closestCityId: null,
        warehouseId: warehouseId ? warehouseId : null,
        temp_userType: '',
        temp_isActive: { value: true, label: 'Active' },
        userTypeColorError: null,
        WHUserData: {},
        Old_Data: null,
        password: null,
        confirmPassword: null
    })
    const { data, loading, refetch } = useQuery(GET_WAREHOUSE_USER, {
        variables: { "page": warehouseUser?.pageNo + 1, "limit": warehouseUser?.limit, "filter": warehouseUser?.filter, "sort": { key: warehouseUser?.sortKey, type: warehouseUser?.sortType }, warehouseId: warehouseId },
        fetchPolicy: 'cache-and-network'
    })
    const [setPasswordOfCustomerByAdmin] = useMutation(RESETPASSWORD_CUSTOMER);

    const addNewWarehouseUser = (e) => {
        e.preventDefault();
        setWarehouseUser(preData => ({
            ...preData, openModal: 'Add New',
            temp_isActive: { value: true, label: 'Active' },
            temp_userType: '',
            temp_countryId: null,
            temp_stateId: null,
            temp_closestCityId: null,
            userTypeColorError: null,
            WHUserData: {}
        }))
        $('#WarehouseUserModal').modal('show');
        $('frm_wareHouseUser').removeClass('was-validated');

    }
    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchWHUser()
        }
    }
    const searchWHUser = () => {
        setWarehouseUser(prevData => ({ ...prevData, pageNo: 0, filter: warehouseUser?.filterText }))
    }
    const onResetSearch = () => {
        setWarehouseUser(prevData => ({ ...prevData, pageNo: 0, filter: "", filterText: "" }))

    }
    const handlePageClick = data => {
        setWarehouseUser(prevData => ({ ...prevData, pageNo: data?.selected }))
    }
    const formOnSubmitResetAdminCustomerPassword = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            const { password, confirmPassword, id } = warehouseUser;
            if (password === confirmPassword) {
                let input = {
                    customerId: id,
                    password: password,
                }
                let log = {
                    action: 'UPDATE',
                    actionOn: 'users',
                    actionName: 'setPasswordOfCustomerByAdmin',
                    oldValue: JSON.stringify(input)
                };
                setPasswordOfCustomerByAdmin({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                    form.classList.remove('was-validated');
                    $('#ResetPasswordModal').modal('hide');
                    form.reset();
                    toast.success("Password updated successfully!", {
                        autoClose: 2000
                    })
                }).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                })
            } else {
                toast.error('Passwords do not match.', {
                    autoClose: 2000
                })
            }
            return true;
        }
    }


    if (user.roles === 'standard') return <AccessDenied />
    else
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 p-0">
                        <div className="mb-20">
                            <p className="mainTitle m-0 mt_-15">Destination Warehouse User</p>
                            <span className="borderBottomOfTitle"></span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="float-right">
                            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewWarehouseUser(e)}> <i className="fa fa-plus"></i> Add New Warehouses User</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12 p-0">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span>Destination Warehouses User Details</span>
                            </div>
                            <div className="ibox-content p-0">
                                {loading && <Loader />}
                                <div className="col-md-12 ph-20 mt-3">
                                    <div className="form-row">
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Search</label>
                                            <input type="text" className="form-control" value={warehouseUser?.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setWarehouseUser(prevData => ({ ...prevData, filterText: e.target.value }))} />
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="mt-30">
                                                <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchWHUser()} >Search</button>
                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                                <div className="pull-right">
                                                    <Pagination
                                                        pageCount={data && data?.getWarehouseUsers && Math.ceil(data && data?.getWarehouseUsers?.count / warehouseUser?.limit)}
                                                        pageNo={warehouseUser?.pageNo}
                                                        handlePageClick={(data) => handlePageClick(data)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <GetWarehouseUser getWHUser={data?.getWarehouseUsers} warehouseUser={warehouseUser} setWarehouseUser={setWarehouseUser} refetch={refetch} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal show" id="WarehouseUserModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                        <div className="modal-content ">
                            <div className="modal-body">
                                <InsertUpdateWarehouseUser warehouseUser={warehouseUser} setWarehouseUser={setWarehouseUser} refetch={refetch} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal show" id="ResetPasswordModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                        <div className="modal-content ">
                            <div className="modal-body">
                                <div className="">
                                    <div className="mb-4">
                                        <p className="mainTitle m-0">Reset Password</p>
                                        <span className="borderBottomOfTitle"></span>
                                    </div>
                                    <div className="ibox-title border-0 p-0">
                                        <span>Reset Password</span>
                                    </div>
                                </div>
                                <form id="frm_ResetUser" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitResetAdminCustomerPassword(e)}>
                                    <div className="form-row">
                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <label className="frm-label">New Password<span className="text-danger">*</span></label>
                                            <input type="password" className="form-control ffPoppins" onChange={e => setWarehouseUser(preData => ({ ...preData, password: e.target.value }))} required />
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                            <label className="frm-label">Confirm Password<span className="text-danger">*</span></label>
                                            <input type="password" className="form-control ffPoppins" onChange={e => setWarehouseUser(preData => ({ ...preData, confirmPassword: e.target.value }))} required />
                                        </div>
                                    </div>

                                    <div className="form-group p-0">
                                        <button type="submit" className="btn btn-primary btn-text mt-3 mr-4 border-0 bg-searchBlue" >Save</button>
                                        <button className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed" data-dismiss="modal" >Cancel</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
}
