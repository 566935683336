import React, { useEffect, useState } from 'react'
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Select from 'react-select';
import { useMutation, useQuery } from '@apollo/client';
import { GENERATE_INVOICE, UPLOAD_EVIDANCE } from '../../../../graphql/mutation/InvoiceMutation';
import { GET_INVOICE, UPDATE_INVOICE_STATUS } from '../../../../graphql/query/InvoiceQuery';
import { GetInvoiceData } from './GetInvoiceData';
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import Loader from '../../../components/Loader/Loader';
import Pagination from '../../../components/Common/Pagination';
import { BASE_URL } from '../../../../config';
import { getS3SignUrl } from '../../../../graphql/query/TicketQuery';


const statusType = [
    { value: 'PAID', label: 'PAID' },
    { value: 'UNPAID', label: 'UNPAID' },
];

export const InvoiceList = () => {
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'));
    const FilterStatus = JSON.parse(localStorage.getItem('filterStatus'));
    const FromDate = JSON.parse(localStorage.getItem('fromDate'));
    const ToDate = JSON.parse(localStorage.getItem('toDate'));
    const Filter = JSON.parse(localStorage.getItem('filter'));
    const [invoiceList, setInvoiceList] = useState({
        filterStatus: FilterStatus || null,
        fromDate: FromDate || null,
        toDate: ToDate || null,
        limit: genSettings?.paginationLimit || 10,
        pageNo: 0,
        filter: Filter || "",
        skipQuery: false,
        invoiceResult: null,
        skipUpdateInvoice: true,
        openModal: false,
        paymentSlip: null,
        isLoading: false,
        rowId: null,
        invoiceId: null,
    });

    useEffect(() => {
        localStorage.setItem('filterStatus', JSON.stringify(invoiceList.filterStatus));
        localStorage.setItem('fromDate', JSON.stringify(invoiceList.fromDate));
        localStorage.setItem('toDate', JSON.stringify(invoiceList.toDate));
        localStorage.setItem('filter', JSON.stringify(invoiceList.filter));
      }, [invoiceList.filterStatus, invoiceList.fromDate, invoiceList.toDate, invoiceList.filter]);

    const [generateInvoice, { loading }] = useMutation(GENERATE_INVOICE, {
        onCompleted: () => {
            setInvoiceList(preData => ({ ...preData, skipQuery: false }))
        }
    })
    const [uploadEvidence] = useMutation(UPLOAD_EVIDANCE);
    const [getSignUrl] = useMutation(getS3SignUrl);
    const { loading: loadingUpdateInvoice } = useQuery(UPDATE_INVOICE_STATUS, {
        skip: invoiceList?.skipUpdateInvoice,
        fetchPolicy: 'cache-and-network',
        onCompleted: ({ updateInvoiceStatus }) => {
            if (updateInvoiceStatus)
                toast.success("Invoice Updated Successfully!", {
                    autoClose: 2000
                })
            else
                toast.success("Failed to Updated Invoice", {
                    autoClose: 2000
                })
            setInvoiceList(preData => ({ ...preData, skipQuery: false, skipUpdateInvoice: true }))

        }
    });

    useEffect(() => {
        $('.react-datepicker-wrapper').addClass('d-flex')
    },[]);

    const { refetch: getInvoicerefetch } = useQuery(GET_INVOICE, {
        variables: { "page": invoiceList?.pageNo + 1, limit: invoiceList.limit, filter: invoiceList?.filter, sort: { key: 'createdAt', type: -1 }, from: invoiceList?.fromDate, to: invoiceList?.toDate, status: invoiceList?.filterStatus?.value.toLowerCase(), customerId: null },
        skip: invoiceList?.skipQuery,
        onCompleted: ({ getInvoice }) => {
            setInvoiceList(preData => ({ ...preData, invoiceResult: getInvoice, skipQuery: true }))
        },
        fetchPolicy: 'cache-and-network'
    })
    const fromDateChange = (date) => {
        setInvoiceList(preData => ({ ...preData, fromDate: date, filterFrom: moment(date).format('YYYY/MM/DD') }))
    }
    const toDateChange = (date) => {
        setInvoiceList(preData => ({ ...preData, toDate: date, filterTo: moment(date).format('YYYY/MM/DD') }))
    }
    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            setInvoiceList(preData => ({ ...preData, skipQuery: false }))
        }
    }
    const handlePageClick = (data) => {
        setInvoiceList(preData => ({ ...preData, pageNo: data?.selected ,skipQuery: false}))
    }
    const paymentSlip = (event) => {
        const { target: { validity, files: [file], } } = event;
        if (validity.valid) {
          const fileExtension = file?.type.split("/")[1];
          const key = `paymentSlip_${+new Date()}.${fileExtension}`;
          setInvoiceList(preData => ({ ...preData, isLoading: true }))
          getSignUrl({ variables: { type: key } }).then((data) => {
            if (data?.data?.getPreSignedUrl) {
              fetch(data?.data?.getPreSignedUrl, {
                method: "PUT",
                headers: {
                  "Content-Type": file?.type,
                },
                body: file,
              }).then((response) => {
                if (response?.ok && response?.status === 200) {
                  setInvoiceList(preData => ({ ...preData,paymentSlip: `${BASE_URL}/${key}` ,isLoading: false }))
                } else {
                    setInvoiceList(preData => ({ ...preData, isLoading: false }))
                }
              }).catch((errr) => {
                setInvoiceList(preData => ({ ...preData, isLoading: false }))
              });
            } else {
                setInvoiceList(preData => ({ ...preData, isLoading: false }))
            }
          }).catch((err) => {
            setInvoiceList(preData => ({ ...preData, isLoading: false }))
            toast.error(err.message, {
              autoClose: 2000
            })
          })
        }
      }
    const updatePaymentDefault = (event) => {
        event.preventDefault();
        const { paymentSlip, rowId, invoiceId, amount, email } = invoiceList;
        if (!paymentSlip) {
            toast.error('Please upload Evidence', {
                autoClose: 2000
            })
            return false;
        } else {
            uploadEvidence({ variables: { rowId: rowId, invoiceId: invoiceId.toString(), evidence: paymentSlip, amount: amount, email: email } }).then(async ({ data }) => {
                if (data && data?.uploadEvidenceAndMarkAsPaid) {
                    getInvoicerefetch();
                    $('#uploadPaymentReceipt').modal('hide');
                    setInvoiceList(preData => ({ ...preData, paymentSlip: null, rowId: null, invoiceId: null, amount: null, email: null, openModal: false, skipQuery: false }))
                    toast.success("Invoice Status updated successfully.", {
                        autoClose: 2000
                    });
                } else {
                    $('#uploadPaymentReceipt').modal('hide');
                    setInvoiceList(preData => ({ ...preData, paymentSlip: null, rowId: null, invoiceId: null, amount: null, email: null, openModal: false }))
                    toast.error('Invoice Status not updated', {
                        autoClose: 2000
                    });
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="mb-20">
                    <p className="mainTitle m-0 mt_-15">Invoice List</p>
                    <span className="borderBottomOfTitle"></span>
                </div>

            </div>
            <div className="row mb-4">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Invoice Filters</span>
                        </div>
                        <div className="ibox-content">
                            <form >
                                <div className="form-row">
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Form</label>
                                        <DatePicker
                                            className="form-control ffPoppins d-flex"
                                            placeholderText="mm/dd/yyyy"
                                            selected={invoiceList.fromDate}
                                            maxDate={new Date()}
                                            onChange={(date) => fromDateChange(date)}
                                            onChangeRaw={(e) => e.preventDefault()}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">To</label>
                                        <DatePicker
                                            className="form-control ffPoppins d-flex"
                                            placeholderText="mm/dd/yyyy"
                                            minDate={invoiceList?.fromDate}
                                            maxDate={new Date()}
                                            selected={invoiceList?.toDate}
                                            onChange={(date) => toDateChange(date)}
                                            onChangeRaw={(e) => e.preventDefault()}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Status</label>
                                        <Select className="ffPoppins" options={statusType} value={invoiceList?.filterStatus} onChange={value => setInvoiceList(preData => ({ ...preData, filterStatus: value }))} />
                                    </div>
                                    <div onClick={() => setInvoiceList(preData => ({ ...preData, page: 1 }))} className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <label className="frm-label">Search</label>
                                        <input type="text" className="form-control ffPoppins" value={invoiceList.filter} onKeyDown={(e) => handleSearch(e)} onChange={e => setInvoiceList(preData => ({ ...preData, filter: e.target.value }))} />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div className="btn-Groups mt-30">
                                            <button className="btn btn-primary btn-text border-0 bg-searchBlue" onClick={e => {
                                                e.preventDefault();
                                                setInvoiceList(preData => ({ ...preData, skipQuery: false }))
                                            }}>Search</button>
                                            <button className="btn btn-danger btn-text bg-resetRed" onClick={e => {
                                                e.preventDefault();
                                                setInvoiceList(preData => ({ ...preData, filterStatus: null, fromDate: null, toDate: null, filter: "", skipQuery: false }))
                                            }}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Invoice Details</span>
                            <span className="float-right">
                                {loading && <Loader />}
                                <button onClick={() => generateInvoice().then(() => {
                                    toast.success("Invoice Created Successfully!", {
                                        autoClose: 2000
                                    })
                                }).catch((err) => {
                                    toast.error(err.message, {
                                        autoClose: 2000
                                    })
                                })} className="btn btn-warning mr-3 btn-sm text-white bg-Orange">Create Invoice</button>
                                {loadingUpdateInvoice && <Loader />}
                                <button onClick={() => setInvoiceList(preData => ({ ...preData, skipUpdateInvoice: false }))} className="btn btn-warning mr-3 btn-sm text-white bg-Orange">Update Status</button>
                            </span>
                        </div>
                        {invoiceList?.invoiceResult &&
                            <div className="ibox-content p-0">
                                <div className="mt-2 mb-2 shift-right">
                                    <Pagination pageCount={invoiceList?.invoiceResult && Math.ceil(invoiceList?.invoiceResult.count / invoiceList?.limit)}
                                        pageNo={invoiceList?.pageNo}
                                        handlePageClick={(data) => handlePageClick(data)} />
                                </div>
                                <GetInvoiceData invoiceList={invoiceList} setInvoiceList={setInvoiceList} getInvoicerefetch={getInvoicerefetch} />
                            </div>
                        }
                    </div>
                </div>
            </div>

            <div className="modal show" id="uploadPaymentReceipt" data-backdrop="static" tabIndex="-1" role="dialog" >
                <div className="modal-dialog  modal-dialog-centered " role="document">
                    <div className="modal-content ">
                        <div className="modal-header" style={{ paddingTop: "0px" }}>
                            <div>
                                <p className=" m-0 mt_-15" style={{ color: "#ff5353", fontFamily: "Poppins", fontSize: "30px", fontWeight: "600" }}>Upload Evidence</p>
                                <span className="borderBottomOfTitle "></span>
                            </div>
                            <div>
                                <button data-dismiss="modal"
                                    type="button" className="close" aria-label="Close" onClick={() => { setInvoiceList(preData => ({ ...preData, paymentSlip: null })) }} style={{ marginTop: "12px", marginRight: "2px" }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        {invoiceList?.isLoading && <Loader />}
                                        <div className="ibox">
                                            <div className="ibox-title">
                                                <span>Upload Evidence</span>
                                            </div>
                                            <div className="ibox-content">
                                                <form id="frm_payment" className="needs-validation" noValidate="noValidate" onSubmit={(e) => updatePaymentDefault(e)}>
                                                    <div className="form-row">
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                            <div>
                                                                <label className="frm-label">Evidence<span className="text-danger">*</span></label>
                                                                <div>
                                                                    <span className="btn btn-default btn-file btn-sm btn-danger ffPoppins mb-2" >
                                                                        <i className="fa fa-upload"></i> Choose a File
                                                                        {/* <input type="file" onChange={(e) => handleFileUpload(e, 'paymentSlip', invoiceList?.paymentSlip, invoiceList?.isLoading)} /> */}
                                                                        <input type="file" onChange={(e) => paymentSlip(e)} />
                                                                    </span>

                                                                </div>
                                                            </div>
                                                            <img src={invoiceList?.paymentSlip || "../images/noImage.png"} alt="Img" className='mr-2 width200 z-depth-1-half img-responsive img-thumbnail' />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-xs-12">
                                                        <div className="mt-30">
                                                            <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                            <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" onClick={() => { setInvoiceList(preData => ({ ...preData, paymentSlip: null })) }} >Cancel</button>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
