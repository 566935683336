import { gql } from "@apollo/client";

export const GET_ALL_TICKETS = gql`
  query getAllTickets($page: Int, $limit: Int, $filter: String, $sort: ticketSort,$filterStatus: String, $from: String, $to: String){
    getAllTickets(page: $page, limit: $limit, filter: $filter, sort: $sort,filterStatus:$filterStatus, from:$from, to:$to){
      count
      data{
        id
      	ticketNo
        customerId{
          id
          firstName
          lastName
          email
          phone
          city
          pmbNo
        }
        conversationId{
          id
        }
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_TICKET_WITH_CONVERSION = gql`
  query getTicketWithConversations($ticketId: ID) {
    getTicketWithConversations(ticketId:$ticketId) {
        id
      	ticketNo
        customerId{
          id
          firstName
          lastName
          email
          phone
          city
          pmbNo
        }
        conversationId{
          id
          message
          fileUpload
          sender
          receiver
          readStatus
          createdAt
          updatedAt
        }
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
  }
`;

export const GET_ID = gql`
query getAdmin{
  getAdmin{
    id 
  }
}`

export const GET_CUSTOMER_TICKETS = gql`
  query getCustomerTickets($page:Int, $limit:Int, $customerId: ID, $isActive: Boolean){
    getCustomerTickets(page:$page, limit:$limit, customerId:$customerId, isActive: $isActive){
      count
      data {
        id
        ticketNo
        customerId{
          id
          firstName
          lastName
          email
          phone
          city
          pmbNo
        }
        conversationId{
          id
          message
          fileUpload
          sender
          receiver
          readStatus
          createdAt
          updatedAt
        }
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt      
      }
    }
  }
`;
export const getS3SignUrl = gql`
mutation getPreSignedUrl($type: String!){
  getPreSignedUrl(type: $type)
}`