import { gql } from "@apollo/client"

export const INSERT_UPDATE_SHIPMENT = gql`
mutation insertUpdateShipment($input: ShipmentInput!,$log: LogInput){
    insertUpdateShipment(input:$input,log:$log){
      id
      wrhsId
      shipmentNo
      shippingDate
      estimatedDate
      shipStatus
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`

export const DELETE_SHIPMENT = gql`
mutation deleteShipment($id: ID!,$log: LogInput){
    deleteShipment(id:$id,log:$log){
      id
      wrhsId
      shipmentNo
      shippingDate
      estimatedDate
      shipStatus
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`

export const UPDATE_SHIPMENT_STATUS = gql`
mutation updateShipmentStatus($input:updateShipmentStatusInput){
  updateShipmentStatus(input:$input)
}`



export const DELETE_BOX = gql`
mutation deleteBox($id:ID!,$log:LogInput){
  deleteBox(id:$id,log:$log)
}`