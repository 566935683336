import React, { useState, useEffect } from 'react';

const AddItemComp = ({ itemDelete, items, change, insurePrice }) => {
    const [isInsure, setIsInsure] = useState(false);
    const [generalSettings, setGeneralSettings] = useState(JSON.parse(localStorage.getItem('generalSettings')));
    const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem('customer')));

    useEffect(() => {
        setGeneralSettings(JSON.parse(localStorage.getItem('generalSettings')));
        setCustomer(JSON.parse(localStorage.getItem('customer')));
    }, []);

    const deleteItem = (id) => {
        itemDelete(id);
    };

    const handleChange = (e, id) => {
        if (e.target.name === "isInsure") {
            setIsInsure(!isInsure);
            e.target.value = !isInsure; 
            change(e, id);
            TotalIns();
        } else {
            change(e, id);
        }
    };

    const TotalIns = () => {
        const { total, id } = items;
        const insurerate = generalSettings?.orderInsurancePercentage || 5;
        if (!isInsure) {
            const insureprice = (total * insurerate) / 100;
            insurePrice(insureprice, id);
        } else {
            const insureprice = 0;
            insurePrice(insureprice, id);
        }
    };

    return (
        <div className="col-md-12 pb-3 alignment">
            <div className="dFlex col-md-12 boxShadowDiv pl-3 pr-3">
                <div className="ordPrfBox mr-3 mb-3">
                    <img src="../images/noImage.png" alt="Img" width='50px' className='binColor mr-2' />
                </div>
                <div className="width20 mr-3 mb-3">
                    <label className="mb-0 labelFs14">item</label>
                    <input placeholder="Enter Item Description" className="input p-0 col-12 inputFs14" name="description" value={items.description} onChange={(e) => {
                        handleChange(e, items.id)
                    }} required />
                </div>
                <div className="width20 mr-3 mb-3">
                    <label className="mb-0 labelFs14">Quantity</label>
                    <input placeholder="Enter QTY" className="input p-0 col-12 inputFs14" name="qty" value={items.qty} onChange={(e) => { change(e, items.id) }} required />
                </div>
                <div className="width20 mr-3 mb-3">
                    <label className="mb-0 labelFs14">Price($)</label>
                    <input placeholder="Enter Price" className="input p-0 col-12 inputFs14" name="price" value={items.price} onChange={(e) => { change(e, items.id) }} required />
                </div>
                <div className="width20 mr-3 mb-3">
                    <label className="mb-0 labelFs14">Total($)</label>
                    <input placeholder="Enter Total" className="input p-0 col-12 inputFs14" value={items.total} />
                </div>
                <div className="width20 mr-3 mb-3">
                    <label className="mb-0 labelFs14">Special Instruction</label>
                    <input placeholder="Enter Instruction" className="input p-0 col-12 inputFs14" name="instruction" value={items.instruction} onChange={(e) => { change(e, items.id) }} />
                </div>
                {customer?.receivingServiceType === "verified" &&
                    <div className="width20 mr-3 mb-3">
                        <label className="mb-0 labelFs14">Insurance</label>
                        <input className="input p-0 col-md-2" name="isInsure" type="checkbox" checked={items?.isInsure} onChange={(e) => { handleChange(e, items.id) }} />
                        {items?.isInsure &&
                            <label className="p-0 col-md-10">{`INS = ${items?.insureprice}`}</label>
                        }
                    </div>
                }
                <div className="pointer" onClick={() => deleteItem(items.id)}>
                    <img src="../images/bin.png" alt="Img" width='15px' className='binColor mr-1 pointer' />
                    <label className="mb-0 labelFs12 mr-2 pointer">Remove</label>
                </div>
            </div>
        </div>
    );
};

export default AddItemComp;
