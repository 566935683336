import React from 'react'
import { adminUsersRole } from '../../../components/Constant';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import { DELETE_USERS } from '../../../../graphql/mutation/UserMutation';
import { useMutation } from '@apollo/client';
import confirmDeletion from '../../../components/Common/BootBox';


export const ShowUserComp = ({ sortKey, setSortKey, setSortType, sortType, userData, refetch, setId, setUserTypeColorError, setTempUserType, setTempIsActive, setUsersData, setOldData, setOpenModal }) => {
    const [deleteAdminUser] = useMutation(DELETE_USERS);

    const sortData = (sortKey) => {
        const newSortType = (sortType === 1) ? -1 : 1;
        setSortKey(sortKey);
        setSortType(newSortType);
    };

    const editUser = (e, oldUser) => {
        e.preventDefault();
        let userValue;
        if (oldUser.userType) {
            userValue = adminUsersRole.filter(d => {
                return d.value === oldUser.userType
            })
        }
        let statusValue = oldUser.isActive ? { value: true, label: 'Active' } : { value: false, label: 'Inactive' };
        setId(oldUser?.id);
        setUserTypeColorError(true);
        setTempUserType(userValue)
        setTempIsActive(statusValue);
        setUsersData(oldUser);
        setOldData(oldUser);
        setOpenModal('Edit');
        $('#frm_Store').removeClass('was-validated');
        $('#UsersModal').modal('show');

    }

    const OpenResetPassword = (e, userId) => {
        e.preventDefault();
        setId(userId);
        // setResetData({})
        $('#ResetPasswordModal').modal('show');
    }
    const deleteUsersDetails = (e, orderId) => {
        e.preventDefault();
        const onDeleteUser = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'users',
                actionName: 'deleteAdminUser',
                oldValue: ""
            }
            deleteAdminUser({ variables: { "id": orderId, "log": log } })
                .then(async ({ data }) => {
                    $('#UsersModal').modal('hide');
                    toast.success("User deleted successfully!", {
                        autoClose: 2000
                    })
                    refetch();
                })
                .catch((err) => {
                    toast.error(err.message, { autoClose: 2000 });
                });
        };
        confirmDeletion("Delete USERS", "Are you sure want to delete this record ?", onDeleteUser);
    };
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0 tbl-sort">
                <thead>
                    <tr>
                        <th className="text-center">No.</th>
                        <th
                            className={`sortColumn ${(sortKey === 'firstName') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('firstName')}>Name</th>
                        <th
                            className={`sortColumn ${(sortKey === 'email') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('email')}>Email</th>
                        <th
                            className={`sortColumn ${(sortKey === 'phone') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('phone')}>Phone</th>
                        <th
                            className={`sortColumn ${(sortKey === 'userType') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('userType')}>User Type</th>
                        <th
                            className={`sortColumn ${(sortKey === 'isActive') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('isActive')}>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        userData && userData?.data && userData?.data.length > 0
                            ? userData?.data && userData?.data.map((d, i) => {
                                return (
                                    <tr key={d?.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editUser(e, d)}>
                                        <td className=" text-center">{i + 1}</td>
                                        <td>{d?.firstName} {d?.lastName}</td>
                                        <td>{d?.email}</td>
                                        <td>{d?.phone}</td>
                                        <td>{d?.userType}</td>
                                        <td>
                                            {
                                                d?.isActive
                                                    ? <span className="statusStyle activeCustomer">Active</span>
                                                    : <span className="statusStyle inActiveCustomer">InActive</span>
                                            }
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-Orange rounded mr-2" onClick={(e) => OpenResetPassword(e, d.id)}>
                                                    <img src="../images/key.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editUser(e, d)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => { deleteUsersDetails(e, d.id); }}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ textAlign: 'center' }} colSpan={7} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
