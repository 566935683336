import React from 'react'
import { CustomerHeader } from './CustomerHeader'
import CustomerTab from './CustomerTab'
import SideBar from '../components/layouts/SideBar'
import Header from './Header'
import { useLocation } from 'react-router-dom'

const Navbar = ({ session }) => {
  const path = useLocation();
  if (session && session.me) {
    const role = session.me.roles
    return (
      <div style={{ position: "relative" }}>
        <div className="clearfix">
          {role === "customer" ?
            <div>
              <CustomerHeader routePath={path} session={session} />
            </div>
            :
            <Header />
          }
        </div>
        {(path?.pathname === '/new-order' || path?.pathname === '/my-order' || path?.pathname === '/my-shipment' || path?.pathname === '/my-invoice' || path?.pathname === '/ordertracking') &&
          <div className="side-content-wrap side-position">
            <section className="ps-side-bar">
              <CustomerTab />
            </section>
          </div>
        }
        {(role === "admin" || role === "standard" || role === "special") && <SideBar session={session} />}
      </div>
    )
  } else
    return (<div />)
}

export default Navbar
