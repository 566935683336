// INFO: live 
export const API_URL='https://api.shipmyshopping.com'
export const API_WS_URL='wss://api.shipmyshopping.com'
export const UPLOAD_FILE_URL='https://api.shipmyshopping.com/uploadFileS3'
export const BASE_URL = 'https://shipmyshopping.s3.us-east-2.amazonaws.com';

// INFO: local 
// export const API_URL='http://localhost:8000'
// export const API_WS_URL='ws://localhost:8000'
// export const UPLOAD_FILE_URL='http://localhost:8000/uploadFileS3'
// export const BASE_URL = 'https://shipmyshopping.s3.us-east-2.amazonaws.com';
