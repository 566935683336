import { gql } from "@apollo/client";

export const CREATE_TICKET = gql`
mutation createTicket($customerId: ID){
  createTicket(customerId: $customerId){
    id
    ticketNo
    customerId
    conversationId
    isDeleted
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt 
  }
}
`;
