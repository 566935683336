import { gql } from "@apollo/client";

export const GET_BOX_DIMENSIONS = gql`
query getBoxDimensions($page: Int, $limit: Int, $filter: String, $sort: boxDimensionSort){
  getBoxDimensions(page: $page,limit: $limit, filter: $filter,sort:$sort){
    count
    data {
      id
      name
  length
    width
    height
    weight
    dimensionalWeight
    cubicFeet
      isActive
      createdAt
      createdBy
    }
  }
}
`;

export const GET_ALL_BOX_DIMENSIONS_LIST = gql`
query getAllBoxDimensionsList {
  getAllBoxDimensionsList {
    id
    name
    length
    width
    height
    weight
    dimensionalWeight
    cubicFeet
    isActive
    createdAt
    createdBy
  }
}
`;

// export default GET_BOX_DIMENSIONS;