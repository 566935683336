import React from 'react';
import DatePicker from 'react-datepicker';
import "react-datepicker/dist/react-datepicker.css";
import Select from 'react-select';
import AllBinsList from '../DropdownSelect/AllBinsSelect';
import Pagination from './Pagination';

export const DateInput = ({ label, selected, minDate, maxDate, onChange, onChangeRaw, className }) => {
  return (
    <div className={className ? className : 'form-group col-lg-2 col-md-2 col-sm-6 col-xs-12'}>
      <label className="frm-label">{label}</label>
      <DatePicker
        className="form-control ffPoppins d-flex"
        placeholderText="mm/dd/yyyy"
        selected={selected}
        minDate={minDate}
        maxDate={maxDate}
        onChange={onChange}
        onChangeRaw={onChangeRaw}
      />
    </div>
  );
};

export const SelectInput = ({ label, options, value, onChange, required, className }) => {
  return (
    <div className={className ? className : 'form-group col-lg-2 col-md-2 col-sm-6 col-xs-12'}>
      <label className="frm-label">{label} {required ? <span className="text-danger">*</span> : null}</label>
      <Select
        className="ffPoppins"
        options={options}
        value={value}
        onChange={onChange}
      />
    </div>
  );
};

export const BinsInput = ({ label, binId, getAllBinsInfo }) => {
  return (
    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
      <label className="frm-label">{label}</label>
      <AllBinsList binId={binId} getAllBinsInfo={getAllBinsInfo} />
    </div>
  );
};

export const TextInput = ({ label, value, handleKeyDown, handleChange }) => {
  return (
    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
      <label className="frm-label">{label}</label>
      <input type="text" className="form-control ffPoppins" value={value} onKeyDown={handleKeyDown} onChange={handleChange} />
    </div>
  );
};

export const CustomersDetails = ({ customerUser, limit, pageNo, handlePageClick }) => {
  return (
    <div className="ibox-title">
      <span>Customers Details</span>
      <div className="pull-right">
        <Pagination
          pageCount={customerUser?.getCustomers && Math.ceil(customerUser?.getCustomers.count / limit)}
          pageNo={pageNo}
          handlePageClick={handlePageClick}
        />
      </div>
    </div>
  );
};

export const SearchInput = ({ setFilterText, filterText, handleSearch, search, onResetSearch }) => {
  return (
    <>
      <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
        <label className="frm-label">Search</label>
        <input
          type="text"
          className="form-control ffPoppins"
          value={filterText}
          onKeyDown={handleSearch}
          onChange={(e) => setFilterText(e.currentTarget.value)}
        />
      </div>
      <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
        <div className="btn-Groups mt-30">
          <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={search}>Search</button>
          <button className="btn btn-danger btn-text mr-2 bg-resetRed" onClick={onResetSearch}>Reset</button>
        </div>
      </div>
    </>
  )
}
