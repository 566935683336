import { useMutation } from '@apollo/client'
import React from 'react'
import { INSERT_UPDATE_WAREHOUSE } from '../../../../graphql/mutation/WareHouseMutation'
import Loader from '../../../components/Loader/Loader'
import CountryList from '../../../components/DropdownSelect/CountrySelect'
import StatesList from '../../../components/DropdownSelect/StateSelect'
import { toast } from 'react-toastify'
import { statusType } from '../../../components/Constant'
import * as $ from 'jquery';
import Select from 'react-select';

export const InsetUpdateWareHouse = ({ refetch, destinationWarehouse, setDestinationWarehouse }) => {
    const [insertUpdateWarehouse, { loading }] = useMutation(INSERT_UPDATE_WAREHOUSE)

    const onSubmitDes_Warehouses = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity() || !destinationWarehouse?.temp_countryId || !destinationWarehouse.temp_stateId) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            const { WHData, openModal, Old_Data, id } = destinationWarehouse;
            let input = {};
            let old_value = {};
            if (openModal === "Edit") {
                for (var obj in Old_Data) {
                    if (Old_Data[obj] !== WHData[obj]) {
                        old_value[obj] = Old_Data[obj]
                        input[obj] = WHData[obj]
                    }
                }
                input.id = id
            } else {
                input = { ...WHData };
                input.isActive = destinationWarehouse?.temp_isActive.value;
            }
            let log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'warehouses',
                actionName: 'insertUpdateWareHouse',
                oldValue: openModal === "Edit" ? JSON.stringify(old_value) : ""
            }
            insertUpdateWarehouse({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                refetch();
                form.classList.remove('was-validated');
                setDestinationWarehouse(prevData => ({ ...prevData, WHData: {}, id: null, temp_countryId: null, temp_stateId: null }))
                form.reset();
                $('#DestinationWarehouseModal').modal('hide');
                if (openModal === "Edit") {
                    toast.success("Destination Warehouse updated successfully!", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("Destination Warehouse added successfully!", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    };

    const handleChangeStatus = (userStatus) => {
        setDestinationWarehouse(prevData => ({
            ...prevData, WHData: {
                ...destinationWarehouse?.WHData,
                isActive: userStatus.value,
            },
            temp_isActive: userStatus
        }))
    }
    const TextChangeHandler = (e) => {
        setDestinationWarehouse(prevData => ({
            ...prevData, WHData: {
                ...destinationWarehouse?.WHData,
                [e.target.name]: e.target.value
            },
        }))
    }
    const handleChangeCountry = (id) => {
        setDestinationWarehouse(prevData => ({
            ...prevData, WHData: {
                ...destinationWarehouse?.WHData,
                countryId: id,
            },
            temp_countryId: id,
        }))
    }
    const handleChangeStates = (id) => {
        setDestinationWarehouse(prevData => ({
            ...prevData, WHData: {
                ...destinationWarehouse?.WHData,
                stateId: id,
            },
            temp_stateId: id,
        }))
    }

    const { WHData, temp_isActive, temp_countryId, temp_stateId } = destinationWarehouse;
    return (
        <div className="modal-content ">
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">{destinationWarehouse?.openModal} Destination Warehouses</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                {loading && <Loader />}
                                <div className="ibox-title">
                                    <span>{destinationWarehouse?.openModal} Destination Warehouses</span>
                                </div>
                                <div className="ibox-content">
                                    <form id="frm_des_warehouse" className="needs-validation" noValidate="noValidate" onSubmit={event => onSubmitDes_Warehouses(event)}>
                                        <div className="form-row">
                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <label className="frm-label">Warehouse Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" name="name" value={WHData.name} onChange={(e) => { TextChangeHandler(e) }} required />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Address Line 1</label>
                                                <input type="text" className="form-control ffPoppins" name="addLine1" value={WHData.addLine1} onChange={(e) => { TextChangeHandler(e) }} />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Address Line 2</label>
                                                <input type="text" className="form-control ffPoppins" name="addLine2" value={WHData.addLine2} onChange={(e) => { TextChangeHandler(e) }} />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <label className="frm-label">Warehouse Code<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" name="code" value={WHData.code} onChange={(e) => { TextChangeHandler(e) }} required />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Country<span className="text-danger">*</span></label>
                                                <CountryList countryId={temp_countryId} getCRUDCountryId={(id) => { handleChangeCountry(id) }} />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">State<span className="text-danger">*</span></label>
                                                <StatesList countryId={temp_countryId} stateId={temp_stateId} getCRUDStateId={(id) => { handleChangeStates(id) }} />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">City</label>
                                                <input type="text" className="form-control ffPoppins" name="city" value={WHData.city} onChange={(e) => { TextChangeHandler(e) }} />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">ZIP/Postal Code</label>
                                                <input type="text" className="form-control ffPoppins" name="zipcode" value={WHData.zipcode} onChange={(e) => { TextChangeHandler(e) }} />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Phone</label>
                                                <input type="text" className="form-control ffPoppins" name="phone" value={WHData.phone} onChange={(e) => { TextChangeHandler(e) }} />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                <label className="frm-label">Status<span className="text-danger">*</span></label>
                                                <Select
                                                    className="ffPoppins"
                                                    options={statusType}
                                                    value={temp_isActive}
                                                    onChange={handleChangeStatus}
                                                />
                                            </div>
                                        </div>
                                        <div className="mt-2 mb-2">
                                            <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                            <button type="reset" className="btn btn-danger btn-text bg-resetRed" data-dismiss="modal">Cancel</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
