import React, { useState, useEffect } from 'react';
import RatesList from './DropdownSelect/RatesSelect';
import BoxDimesionList from './DropdownSelect/BoxDimesion';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import Select from 'react-select';
import _ from 'lodash';
import PrintLabelDemo from '../pages/Orders/printLabelDemo';

const BoxPanel = (props) => {
  const genSettings = JSON.parse(localStorage.getItem('generalSettings'));
  const [state, setState] = useState({
    length: '',
    width: '',
    height: '',
    weight: '',
    dimensionalWeight: '',
    cubicFeet: '',
    rateIds: null,
    boxIds: null,
    dimensionalCalc: genSettings?.amcWhouseDimensiopnCalc || 166,
    selectedItem: null,
    selectedValue: [],
    searchRate: '',
  });

  useEffect(() => {
    props.BoxChangeHandler(id, state?.dimensionalWeight, 'dimensionalWeight');
    props.BoxChangeHandler(id, state?.cubicFeet, 'cubicFeet');
  }, [state?.dimensionalWeight,state?.cubicFeet]);

  const CalculateDimention = (id, length, width, height, dimensionalCalc) => {
    if (length && width && height) {
      let getMultiplication = parseFloat(length) * parseFloat(width) * parseFloat(height) || 0;
      let dimensional_weight = getMultiplication / dimensionalCalc;
      let cubic_feet = getMultiplication * 0.0006;
      setState(prevState => ({
        ...prevState,
        dimensionalWeight: dimensional_weight.toFixed(2),
        cubicFeet: cubic_feet.toFixed(2),
      }));

    }
  };
  const handelChangeRate = (rateid) => {
    const { id, BoxChangeHandler } = props;
    BoxChangeHandler(id, rateid, 'rateIds');
  };

  // const itemChangeHandler = (id, data) => {
  //   if (data === null) data = [];
  //   setState((prevState) => ({ ...prevState, selectedItem: data }))
  //     props.selectItemsChange(id, data)

  // };

  const deleteBox = (id) => {
    props.isDeleteBox(id);
  };

  const setSelectedBoxDimensionInfo = (id, value) => {
    const { length, width, weight, height, value: boxIds } = value;
    setState(prevBoxInfo => ({
      ...prevBoxInfo,
      length,
      width,
      weight,
      height,
      boxIds,
      isDimension: !!boxIds,
    }));

    props.boxChange(id, value, state?.weight);
  }

  const { boxIds, selectedItem, length, width, height, weight, dimensionalWeight, cubicFeet, selectedPrinter, searchRate } = state;
  const { id, isEdit, shipmentData, selectedValue, selectItems, selectQty, itemIds } = props;
  return (
    <div className="well m-0 mt-4">
      <div className="form-row">
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12">
          <label className="frm-label">Box No.<span className="text-danger">*</span></label>
          <input type="text" className="form-control ffPoppins" name="boxNum" value={selectedValue?.box_Num || ''} disabled />
        </div>
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12">
          <label className="frm-label">Dimension</label>
          <BoxDimesionList BoxId={boxIds || selectedValue?.boxDimensionId} getPackageBoxId={() => { }} getPackegeBoxInfo={(selectedBoxInfo) => setSelectedBoxDimensionInfo(id, selectedBoxInfo)} />
        </div>
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12">
          <label className="frm-label">Length<span className="text-danger">*</span></label>
          <input type="text" className="form-control ffPoppins" placeholder="Enter Length" name="length" value={length || selectedValue.len} onChange={e => {
            const value = e.target.value;
            setState(preData => ({ ...preData, length: value }));

            const { length, width, height, dimensionalCalc } = state;
            CalculateDimention(id, value, width, height, dimensionalCalc);
            props.BoxChangeHandler(id, value, "len");
          }} required />
        </div>
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12">
          <label className="frm-label">Width<span className="text-danger">*</span></label>
          <input type="text" className="form-control ffPoppins" placeholder="Enter Width" name="width" value={width || selectedValue.width} onChange={(e) => {
            const value = e.target.value;
            setState(preData => ({ ...preData, width: value }));

            const { length, width, height, dimensionalCalc } = state;
            CalculateDimention(id, length, value, height, dimensionalCalc);
            props.BoxChangeHandler(id, value, "width");
          }} required />
        </div>
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12">
          <label className="frm-label">Height<span className="text-danger">*</span></label>
          <input type="text" className="form-control ffPoppins" placeholder="Enter Height" name="height" value={height || selectedValue.height} onChange={(e) => {
            const value = e.target.value;
            setState(preData => ({ ...preData, height: value }));

            const { length, width, height, dimensionalCalc } = state;
            CalculateDimention(id, length, width, value, dimensionalCalc);
            props.BoxChangeHandler(id, value, "height");
          }} required />
        </div>
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12">
          <label className="frm-label">Weight<span className="text-danger">*</span></label>
          <input type="text" className="form-control ffPoppins" placeholder="Enter Weight" name="weight" value={weight || selectedValue?.weight} onChange={(e) => {
            const value = e.target.value;
            setState(preData => ({ ...preData, weight: value }));
            props.BoxChangeHandler(props?.id, value, "weight");

          }} required />
        </div>
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12">
          <label className="frm-label">Dimension Weight<span className="text-danger">*</span></label>
          <input type="text" className="form-control ffPoppins" placeholder="Enter Dimension Weight" name="dimensionalWeight" value={state.dimensionalWeight || selectedValue.dimensionalWeight} readOnly />
        </div>
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12">
          <label className="frm-label">Cubic Feet<span className="text-danger">*</span></label>
          <input type="text" className="form-control ffPoppins" placeholder="Enter Cubic Feet" name="cubicFeet" value={state.cubicFeet || selectedValue.cubicFeet} readOnly />
        </div>
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12">
          <label className="frm-label">Rate</label>
          <RatesList
            page={1}
            limit={10}
            searchRate={searchRate}
            setSearchRate={(val) => setState(preData => ({ ...preData, searchRate: val }))}
            RatesId={selectedValue.rateIds} rateType="Shipment" getOrderRateId={id => handelChangeRate(id)} />
        </div>
        {isEdit && props.customerInfo && props.receiverInfo && props.selectedValue && !shipmentData?.shipmentNo ?
          <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12 mt-4 col-xl-auto">
            <PrintLabelDemo selectedPrinter={selectedPrinter} orderDetailsBoxData={props} />
          </div>
          : null
        }
        <div className="form-group col-lg-2 col-md-4 col-sm-12 col-xs-12 col-xl-auto">
          <i className="fa fa-times" onClick={() => deleteBox(id)}></i>
        </div>
      </div>
    </div>
  );
};

export default BoxPanel;
