import React from 'react'
import Loader from '../../../components/Loader/Loader'
import CountryList from '../../../components/DropdownSelect/CountrySelect'
import StatesList from '../../../components/DropdownSelect/StateSelect'
import { statusType } from '../../../components/Constant'
import { useMutation } from '@apollo/client'
import { INSERT_UPDATE_CLOSEST_CITY } from '../../../../graphql/mutation/ClosestCityMutation'
import Select from 'react-select';
import { toast } from 'react-toastify';
import * as $ from 'jquery';

export const InsertUpdateClosestsCity = ({ refetch, setClosestsCity, closestsCity }) => {
   const [insertUpdateClosestCity ,{loading}] = useMutation(INSERT_UPDATE_CLOSEST_CITY);
    const handleChangeStatus = (userStatus) => {
        setClosestsCity(prevClosetsCity => ({
            ...prevClosetsCity, isActive: userStatus.value,
            temp_isActive: userStatus
        }))
    }
    const formOnSubmitClosestCity = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity() || !closestsCity?.countryId || !closestsCity?.stateId) {
          e.stopPropagation();
          form.classList.add('was-validated');
          toast.error('Please fill the required fields...', {
            autoClose: 2000
          })
          return false;
        } else {
          const { name, code, isActive, openModal, id, countryId, stateId, Old_Data } = closestsCity;
          let oldValues = {};
          let input = {};
    
          if (openModal === "Edit") {
            for (var obj in Old_Data) {
              if (Old_Data[obj] !== closestsCity[obj]) {
                oldValues[obj] = closestsCity.Old_Data[obj]
                input[obj] = closestsCity[obj]
              }
            }
            if (input && Object.keys(input).length === 0) {
              $('#ClosestCityModal').modal('hide');
              return
            }
            input.id = id;
          } else {
            input = {
              name: name,
              code: code,
              countryId: countryId,
              stateId: stateId,
              isActive: isActive
            }
          }
          let log = {
            action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
            actionOn: 'closestcities',
            actionName: 'insertUpdateClosestCity',
            oldValue: openModal === "Edit" ? JSON.stringify(oldValues) : ""
          }
          insertUpdateClosestCity({ variables: { 'input': input, 'log': log } }).then(async ({ data }) => {
            setClosestsCity(prevClosetsCity => ({
                ...prevClosetsCity, name: '', code: ''
            }))
            form.reset();
            refetch();
            $('#ClosestCityModal').modal('hide');
            if (openModal === "Edit")
              toast.success("Closest city updated successfully!", {
                autoClose: 2000
              });
            else
              toast.success("Closest city added successfully!", {
                autoClose: 2000
              });
          }).catch((err) => {
            toast.error(err.message, {
              autoClose: 2000
            })
          })
          return true;
        }
      }
    return (
        <div className="modal-content ">
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">{closestsCity?.openModal} ClosestCity</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                {loading && <Loader />}
                                <div className="ibox-title">
                                    <span>{closestsCity?.openModal} ClosestCity</span>
                                </div>
                                <div className="ibox-content">
                                    <form id="frm_ClosestCity" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitClosestCity(e)}>
                                        <div className="form-row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Country<span className="text-danger">*</span></label>
                                                <CountryList countryId={closestsCity?.countryId} getCRUDCountryId={(id) => { setClosestsCity(prvClosestsCity => ({ ...prvClosestsCity, countryId: id })) }} />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">State<span className="text-danger">*</span></label>
                                                <StatesList countryId={closestsCity?.countryId} stateId={closestsCity?.stateId} getCRUDStateId={(id) => { setClosestsCity(prvClosestsCity => ({ ...prvClosestsCity, stateId: id })) }} />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" value={closestsCity?.name} onChange={e => { setClosestsCity(prvClosestsCity => ({ ...prvClosestsCity, name: e.target.value })) }} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Code<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" value={closestsCity?.code} onChange={e => { setClosestsCity(prvClosestsCity => ({ ...prvClosestsCity, code: e.target.value })) }} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Status<span className="text-danger">*</span></label>
                                                <Select
                                                    className="ffPoppins"
                                                    options={statusType}
                                                    value={closestsCity.temp_isActive}
                                                    onChange={(data) => { handleChangeStatus(data) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-xs-12">
                                            <div className="mt-3">
                                                <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue">Save</button>
                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" >Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}
