import { gql } from "@apollo/client";


export const PACKAGEBOX_SUBSCRIBE = gql`
subscription packageBoxChange {
  packageBoxChange {
    keyType
    data{
      id
      createdAt
      itemIds
      orderIds
      binId
      binNum
			boxDimension{
        id
        box_Num
        len
        width
        height
        weight
        dimensionalWeight
        cubicFeet
        rateIds
        boxDimensionId
        isDimension
      }
      customerId
      customerInfo{
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      receiverId
      receiverInfo{
        name
        phone
      }
      shipmentId
      shipmentNo
      barcode
    }
  }
}
`;