import React from 'react'

export const PackageBoxReport = ({ reportData }) => {
    const { getPackedBoxReport } = reportData;
  return (
    <div className="col-md-12 no-padding d-inline-block">
        <div id="totalreceived" className="table-responsive">
            <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                <thead>
                    <tr>
                        <th>NO</th>
                        <th>DATE</th>
                        <th>CUSTOMER INFO</th>
                        <th>RECEIVER INFO</th>
                        <th>BOX NO</th>
                        <th>LENGTH</th>
                        <th>WIDTH</th>
                        <th>HEIGHT</th>
                        <th>WEIGHT</th>
                        <th>BIN NO</th>
                    </tr>
                </thead>
                <tbody>
                    {getPackedBoxReport.length ?
                        getPackedBoxReport.map((report, i) => {
                            const { date, customerInfo, receiverInfo, boxNo, len, width, height, weight, binNo } = report;
                            return <tr className="odd gradeX" key={i}>
                                <td>{i + 1}</td>
                                <td>{new Date(date).toLocaleDateString('en-US')}</td>
                                <td>{customerInfo?.firstname + ' ' + customerInfo?.lastname + ' | ' + customerInfo?.pmb_no}</td>
                                <td>{receiverInfo?.name}</td>
                                <td align="right">{boxNo}</td>
                                <td align="right">{len}</td>
                                <td align="right">{width}</td>
                                <td align="right">{height}</td>
                                <td align="right">{weight}</td>
                                <td align="right">{binNo}</td>
                            </tr>
                        })
                        :
                        <tr className="odd gradeX">
                            <td colSpan="10">NO RECORD FOUND</td>
                        </tr>
                    }
                </tbody>
            </table>
        </div>
    </div>
  )
}
