import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { GET_ALL_BOX_DIMENSIONS_LIST } from '../../../graphql/query/BoxDimesionQuery';
import _ from 'lodash';

const BoxDimesionList = (props) => {
  const [state, setState] = useState({
    BoxDimensionList: [],
    colorError: undefined,
    value: null,
    cubicFeet: null,
    dimensionalWeight: null,
    height: null,
    length: null,
    weight: null,
    width: null
  });

  const { BoxDimensionList, value, colorError } = state;

  const { loading, data } = useQuery(GET_ALL_BOX_DIMENSIONS_LIST, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data?.getAllBoxDimensionsList?.length) {
      let BoxDimensions = data?.getAllBoxDimensionsList
      let getBoxDimensionList = []
      BoxDimensions.forEach(d => {
        getBoxDimensionList.push({ value: d.id, label: d.name, length: d.length, height: d.height, weight: d.weight, width: d.width, dimensionalWeight: d.dimensionalWeight, cubicFeet: d.cubicFeet })
      });
      setState(preData => ({...preData , BoxDimensionList: getBoxDimensionList}))
    }
  }, [data]);
  useEffect(() => {
    setDefaultBoxDimension();

  },[BoxDimensionList,props?.BoxId])

  const BoxDimensionsHandleChange = (selectedValue) => {
    setState(preData => ({...preData ,colorError: true,
      value: selectedValue,}))
    props.getPackageBoxId(selectedValue?.value);
    props.getPackegeBoxInfo({
      value: selectedValue.value,
      cubicFeet: selectedValue.cubicFeet,
      dimensionalWeight: selectedValue.dimensionalWeight,
      height: selectedValue.height,
      length: selectedValue.length,
      weight: selectedValue.weight,
      width: selectedValue.width
    });
  };

  const setDefaultBoxDimension = () => {
    const { BoxDimensionList } = state;
    const { BoxId } = props;
    if(BoxId){
      let findData = _.find(BoxDimensionList, (d) => { return d.value === BoxId });
      setState((prevState) => ({ ...prevState, value: findData }));
    }else {
      setState((prevState) => ({ ...prevState, value: null }));
    }
  };

  return (
    <Select
      className="basic-single ffPoppins"
      options={BoxDimensionList}
      value={value}
      placeholder="Box-Dimension Type"
      onChange={(selectedValue) => BoxDimensionsHandleChange(selectedValue)}
      styles={{
        control: (provided, state) => (!colorError && colorError !== undefined
          ? { ...provided, boxShadow: '0 0 0 1px #dc3545 !important', border: '1px #dc3545 !important' }
          : provided),
      }}
    />
  );
};

export default BoxDimesionList;
