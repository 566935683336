import React, { useRef } from "react";
import { API_URL } from '../../config';
import { print } from "graphql";
import { DOWLOAD_INVOICE_PDF } from "../../graphql/mutation/InvoiceMutation";

export function AuthenticatedLink({
  url,
  filename,
  children,
  className,
  style,
}) {
  const link = useRef(null);

  if (!url?.includes("https://api.paystack.co/files/")) {
    url = `https://api.paystack.co/files/${url}`;
  }

  const handleAction = async () => {
    if (link.current.href) {
      return;
    }
    const bodyData = {
      query: print(DOWLOAD_INVOICE_PDF),
      variables: { url: url }
    }
    const response = await fetch(`${API_URL}/graphql`, {
      method: 'POST',
      body: JSON.stringify(bodyData),
      headers: {
        'Content-Type': 'application/json'
      }
    }).then(res => res.json());
    const PAY_STACK_KEY = response?.data?.downloadInvoice?.split('|&_').reverse().join('');
    const result = await fetch(url, {
      headers: {
        Authorization: `Bearer ${PAY_STACK_KEY}`,
      },
    });

    const blob = await result.blob();
    const href = window.URL.createObjectURL(blob);

    link.current.download = filename;
    link.current.href = href;

    link.current.click();
  };

  return (
    // eslint-disable-next-line jsx-a11y/anchor-is-valid
    <a role="button" className={className} style={style} ref={link} onClick={handleAction}>{children}</a>
  );
}
