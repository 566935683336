import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
export const OrderTracking = () => {
  const location = useLocation()
  const [shipmentId, setShipmentId] = useState(null);
  const [track, setTrack] = useState([]);
  const [compType, setCompType] = useState(null);
  const [orderStatus, setOrderStatus] = useState(null);
  const [showDetail, setShowDetail] = useState(null);
  const [nativation, setNativation] = useState(null);

  const removeTypeName = (obj) => {
    for (var prop in obj) {
      if (prop === '__typename')
        delete obj[prop];
      else if (typeof obj[prop] === 'object')
        removeTypeName(obj[prop]);
    }
  };
 useEffect(() => {
    if (location) {
      const { state } = location;
      if (state.shipmentId !== undefined) {
        if (state.shipmentId !== null)
          delete state.shipmentId.__typename
        for (var i of state.track)
          removeTypeName(i);
      }
      setShipmentId(state.shipmentId)
      setTrack(state.track)
      setCompType(state.comp)
      setOrderStatus(state.orderStatus)
      setNativation(state.nativation)
      setShowDetail(state.showDetail)
    }
  }, [location]);
  const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
  return (
    <div className="container mt-5">
    <div className='text-center mb-5'>
      <h4>My Order Tracking</h4>
    </div>
    {/* ************** tab btn *************** */}
    <div className="col-md-12 mAuto pb-2 invMain mt-5 mb-3">
      <div>
        <label className="headText mt-2">My Shipment Status</label>
        {nativation ? <Link to={{ pathname: "/my-shipment", state: { orderstatus: orderStatus, showDetail: showDetail } }}>
          <button className="btn btn-primary btn-text border-0 bg-searchBlue float-right" style={{ marginTop: "8px", marginRight: "16px" }}>Back to shipping</button>
        </Link> :
          <Link to={{ pathname: "/my-order", state: { orderstatus: orderStatus } }}>
            <button className="btn btn-primary btn-text border-0 bg-searchBlue float-right" style={{ marginTop: "8px", marginRight: "16px" }}>Back</button>
          </Link>}

      </div>
      <div className="pb-1 boxShadowDiv pl-3 pr-3 mb-4">
        <div className="col-md-12 flex">
         {shipmentId?.shipmentNo && <div className="col-md-6">
            <label className="labelFs14 pr-2">Shipment No.</label>
            <label className="inputFs14">{shipmentId?.shipmentNo || ""}</label>
          </div>}
         { shipmentId?.estimatedDate && <div className="col-md-6">
            <div className="float-right">
              <label className="labelFs14 pr-2">Estimated Delivery</label>
              <label className="inputFs14">{shipmentId?.estimatedDate ? `${months[new Date(shipmentId.estimatedDate).getMonth()]} ${(new Date(shipmentId.estimatedDate).getDate()).length === 1 ? '0' + new Date(shipmentId.estimatedDate).getDate() : new Date(shipmentId.estimatedDate).getDate()}, ${new Date(shipmentId.estimatedDate).getFullYear()}` : ""}</label>
            </div>
          </div>}
        </div>
        {track.length ?
          <VerticalTimeline>
            {track.length && track.map((trk, i) => {
              const aDate = new Date(trk.date);
              const monthIndex = aDate.getMonth();
              const date = aDate.getDate();
              const year = aDate.getFullYear();
              let icon = "";
              if (compType === "orderComp") {
                switch (trk.status.toUpperCase().trim()) {
                  case 'PACKAGE RECEIVED BY U.S. WAREHOUSE.':
                    icon = 'redTrue';
                    break;
                  case 'YOUR ORDER WAS PACKED':
                    icon = 'maroonTrue';
                    break;
                  case 'PACKAGE PROCESSED FOR SHIPPING.':
                    icon = 'greenTrue';
                    break;
                  case 'YOUR SHIPMENT HAS BEEN DELIVERED TO THE CARRIER AIRLINE.':
                    icon = 'yellowTrue';
                    break;
                  case 'YOUR SHIPMENT HAS ARRIVED DESTINATION AIRPORT AND IS GOING THROUGH CUSTOMS CLEARANCE.':
                    icon = 'greenTrue';
                    break;
                  case 'YOUR SHIPMENT HAS BEEN RECEIVED BY OUR LAGOS WAREHOUSE AND IS READY TO BE DELIVERED OR PICKED UP.':
                    icon = 'brownTrue';
                    break;
                  case 'YOUR SHIPMENT HAS BEEN PICKED UP.':
                    icon = 'pinkTrue';
                    break;
                  case 'YOUR SHIPMENT HAS BEEN DELIVERED.':
                    icon = 'pinkTrue';
                    break;
                  case 'THIS SHIPMENT IS DELAYED.':
                    icon = 'lightGreenTrue';
                    break;
                  default:
                    break;
                }
              } else
                switch (trk.status.toUpperCase().trim()) {
                  case 'YOUR SHIPMENT HAS BEEN DELIVERED TO THE CARRIER AIRLINE.':
                    icon = 'redTrue';
                    break;
                  case 'YOUR SHIPMENT HAS ARRIVED DESTINATION AIRPORT AND IS GOING THROUGH CUSTOMS CLEARANCE.':
                  case 'YOUR SHIPMENT HAS BEEN DELIVERED.':
                    icon = 'greenTrue';
                    break;
                  case 'YOUR SHIPMENT HAS BEEN RECEIVED BY OUR LAGOS WAREHOUSE AND IS READY TO BE DELIVERED OR PICKED UP.':
                    icon = 'brownTrue';
                    break;
                  case 'YOUR SHIPMENT HAS BEEN PICKED UP.':
                    icon = 'pinkTrue';
                    break;
                  case 'THIS SHIPMENT IS DELAYED.':
                    icon = 'lightGreenTrue';
                    break;
                  default:
                    break;
                }
              return (
                <VerticalTimelineElement key={trk.status}
                  className="vertical-timeline-element--work p-2"
                  contentArrowStyle={{ borderRight: '7px solid  #fff' }}
                  icon={<img src={`../images/${icon}.png`} alt="Img" width="30px" className="mr-2" />}
                >
                  <p className="vertical-timeline-element-title inputFs14">{`${i + 1}. ${trk.status}`}</p>
                  <p className="labelFs12">{`${months[monthIndex]} ${date.length === 1 ? '0' + date : date}, ${year} ${aDate.toLocaleString('en-US', { hour: 'numeric', minute: 'numeric', hour12: true })}`}</p>
                </VerticalTimelineElement>
              )
            })
            }
          </VerticalTimeline> : <div className="boxShadowRate boxInnerMargin">
            <div className="text-center">
              <img src="./images/notFound.png" alt="not found"></img>
            </div>
          </div>
        }
      </div>
    </div>
  </div>
  )
}
