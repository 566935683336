import React, { useEffect, useState } from 'react'
import { INSERT_UPDATE_RECEIVER } from '../../../../graphql/mutation/AddressMutation';
import { useMutation, useQuery } from '@apollo/client';
import Loader from '../../../components/Loader/Loader';
import { ADD_ORDER } from '../../../../graphql/mutation/NewOrderMutation';
import { GET_RECEIVER_CUSTOMER } from '../../../../graphql/query/AddressQuery';
import { toast } from 'react-toastify';
import { getS3SignUrl } from '../../../../graphql/query/TicketQuery';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Dropzone from "react-dropzone";
import { BASE_URL } from '../../../../config';
import OnlineStoreList from '../../../components/DropdownSelect/OnlineStoreSelect';
import AddItemComp from '../../../components/AddItemComp';
import CountryList from '../../../components/DropdownSelect/CountrySelect';
import StatesList from '../../../components/DropdownSelect/StateSelect';
import ClosestCity from '../../../components/DropdownSelect/ClosestCitySelect';
import CustomerAddressPanel from '../../../components/Address/CustomerAddressPanel';
import confirmDeletion from '../../../components/Common/BootBox';


export const NewOrder = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const [order, setOrder] = useState({
        isStep: "step1",
        addItem: false,
        addAddress: false,
        items: [
            {
                id: 0,
                description: "",
                qty: "",
                price: "",
                total: "",
                instruction: "",
            },
        ],
        receiverAddress: {
            name: null,
            phone: null,
            address1: null,
            address2: null,
            city: null,
            closestCityId: { label: null, value: null },
            stateId: { label: null, value: null },
            countryId: { label: null, value: null },
            zipCode: null,
        },
        onlineStoreId: null,
        onlineStoreName: "",
        receiverId: null,
        files: [],
        receiptImg: null,
        loader: false,
        orderPlace: false,
        isCheck: null,
        removeReceiver: false,
        isEdit: false,
        trackingno: "",
        searchStore: ""
    });

    const [loggedUser, setLoggedUser] = useState(localStorage.getItem("loggedUser") || null);
    const [customer, setCustomer] = useState(localStorage.getItem("customer") || null);


    const { data } = useQuery(GET_RECEIVER_CUSTOMER, {
        variables: {
            customerId: JSON.parse(loggedUser).customerId,
        },
        fetchPolicy: "cache-and-network"
    })
    const [insertUpdateReceiver] = useMutation(INSERT_UPDATE_RECEIVER, {
        refetchQueries: [{ query: GET_RECEIVER_CUSTOMER, variables: { customerId: JSON.parse(loggedUser).customerId } }]
    });
    const [getPreSignedUrl] = useMutation(getS3SignUrl);
    const [onMutate] = useMutation(ADD_ORDER);

    const onDrop = (acceptedFiles, fileRejections) => {
        const rejectedUnsupportedFiles = fileRejections.filter((file) => {
            return !isSupportedFileExtension(file.file.name);
        });

        rejectedUnsupportedFiles.forEach((file) => {
            toast.error(`Unsupported file type ${file.file.name}`, {
                autoClose: 2000
            });
        });

        if (acceptedFiles && acceptedFiles.length) {
            const fileExtension = acceptedFiles[0]?.type.split("/")[1];
            const key = `NewOrder_${+new Date()}.${fileExtension}`;
            setOrder(prevOrder => ({
                ...prevOrder,
                receiptImg: null,
                files: Object.assign(acceptedFiles[0], {
                    preview: URL.createObjectURL(acceptedFiles[0]),
                }),
                loader: true,
            }));

            getPreSignedUrl({ variables: { type: key } }).then((data) => {
                if (data?.data?.getPreSignedUrl) {
                    fetch(data?.data?.getPreSignedUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": acceptedFiles[0]?.type,
                        },
                        body: acceptedFiles[0],
                    }).then((response) => {
                        if (response?.ok && response?.status === 200) {
                            setOrder(prevOrder => ({
                                ...prevOrder,
                                receiptImg: `${BASE_URL}/${key}`,
                                loader: false
                            }));
                        } else {
                            setOrder(prevOrder => ({
                                ...prevOrder,
                                loader: false
                            }));
                        }
                    }).catch((errr) => {
                        setOrder(prevOrder => ({
                            ...prevOrder,
                            loader: false
                        }));
                    });
                } else {
                    setOrder(prevOrder => ({
                        ...prevOrder,
                        loader: false
                    }));
                }
            }).catch((err) => {
                setOrder(prevOrder => ({
                    ...prevOrder,
                    isLoading: false
                }));
                toast.error(err.message, {
                    autoClose: 2000
                });
            });
        }
    };
    useEffect(() => {
        // Clean up the preview URL
        return () => {
            if (order.files.preview) {
                URL.revokeObjectURL(order.files.preview);
            }
        };
    }, [order.files.preview]);

    const isSupportedFileExtension = (fileName) => {
        const extension = fileName.split('.').pop().toLowerCase();
        return ['jpg', 'png', 'jpeg'].includes(extension);
    };

    const removeTypeName = (obj) => {
        for (var prop in obj) {
            if (prop === "__typename") delete obj[prop];
            if (prop === "isActive") delete obj[prop];
            if (prop === "createdBy") delete obj[prop];
            if (prop === "createdAt") delete obj[prop];
            if (prop === "updatedAt") delete obj[prop];
            if (prop === "updatedBy") delete obj[prop];
            else if (typeof obj[prop] === "object") removeTypeName(obj[prop]); // Fix recursive call
        }
    }

    useEffect(() => {
        editOrder();
    }, []); 

    const editOrder = () => {
        if (location) {
            const { state } = location;
            if (state && state.datas) {
                const { datas } = state;
                removeTypeName(datas);
                setOrder(prevState => ({
                    ...prevState,
                    ...datas,
                    onlineStoreId: state.onlineStoreId,
                    receiptImg: datas?.invoiceOrReceipt,
                    isCheck: datas?.receiverId,
                }));
            }
        }
    };

    const addNewItem = () => {
        const newItemId = order.items.length > 0 ? order.items[order.items.length - 1].id + 1 : 0;
        const newItem = {
            id: newItemId,
            description: "",
            qty: "",
            price: "",
            total: "",
            instruction: "",
        };
        setOrder(prevState => ({
            ...prevState,
            items: [...prevState.items, newItem]
        }));
    };

    const _nextClick = () => {
        if (order.isStep === "step1") {
            setOrder(prevOrder => ({ ...prevOrder, isStep: "step2" }));
        }
        if (order.isStep === "step2") {
            setOrder(prevOrder => ({ ...prevOrder, isStep: "step3" }));
        }
        if (order.isStep === "step3") {
            setOrder(prevOrder => ({ ...prevOrder, isStep: "step4" }));
        }
    };

    const _prevClick = () => {
        if (order.isStep === "step2") {
            setOrder(prevOrder => ({ ...prevOrder, isStep: "step1" }));
        }
        if (order.isStep === "step3") {
            setOrder(prevOrder => ({ ...prevOrder, isStep: "step2" }));
        }
        if (order.isStep === "step4") {
            setOrder(prevOrder => ({ ...prevOrder, isStep: "step3" }));
        }
    };

    const _isCheck = (id) => {
        setOrder(prevOrder => ({ ...prevOrder, isCheck: id }));
    };

    const _isPopup = (id) => {
        setOrder(prevOrder => ({ ...prevOrder, isPopup: !prevOrder.isPopup }));
    };

    const handelChangeOnlineStore = (value) => {
        setOrder(prevOrder => ({
            ...prevOrder,
            onlineStoreId: value?.value || null,
            onlineStoreName: value?.label || "",
        }));
    };

    const handleChangeTracking = (e) => {
        const re = /^[0-9A-Za-z\b]+$/;
        let trackingno = e.target.value;
        if (trackingno === "" || re.test(trackingno)) {
            setOrder(prevOrder => ({ ...prevOrder, trackingno }));
        }
    };

    const TextChangeHandler = (e) => {
        const { name, value } = e.target;
        setOrder(prevOrder => ({
            ...prevOrder,
            receiverAddress: {
                ...prevOrder.receiverAddress,
                [name]: value
            }
        }));
    };

    const handleSelectChange = (id, key) => {
        setOrder(prevOrder => ({
            ...prevOrder,
            receiverAddress: {
                ...prevOrder.receiverAddress,
                [key]: id
            }
        }));
    };

    const formOnSubmitReceiverofCustomer = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add("was-validated");
            toast.error("Please fill the required fields...", {
                autoClose: 2000
            });
            return false;
        } else {
            const { receiverAddress, receiverId, isEdit } = order;
            let customerId = JSON.parse(loggedUser).customerId;
            let log = {};
            let input = {};

            Object.keys(receiverAddress).forEach((rec) => {
                if (rec === "closestCityId") {
                    input["city"] = receiverAddress[rec].label;
                    input["closestCityId"] = receiverAddress[rec].value;
                } else if (rec === "stateId")
                    input["stateId"] = receiverAddress[rec].value;
                else if (rec === "countryId")
                    input["countryId"] = receiverAddress[rec].value;
                else {
                    input[rec] = receiverAddress[rec];
                    if (receiverId && isEdit) {
                        input["id"] = receiverId;
                    } else {
                        input["customerId"] = customerId;
                    }
                }
            });


            log = {
                action: receiverId && isEdit ? "UPDATE" : "INSERT",
                actionOn: "receivers",
                actionName: "insertUpdateReceiver",
                oldValue: receiverId && isEdit ? JSON.stringify(input) : "",
            };

            if (input.closestCityId && input.countryId && input.stateId) {
                insertUpdateReceiver({ variables: { input: input, log: log } }).then(
                    async ({ data }) => {
                        form.reset();
                        if (!isEdit) {
                            setOrder(prevOrder => ({ ...prevOrder, receiverAddress: {} }))
                        }
                        if (receiverId && isEdit) {
                            toast.success("Receiver Address updated successfully!!", {
                                autoClose: 2000
                            });
                            setOrder(prevOrder => ({ ...prevOrder, addAddress: false, isEdit: false }))
                        } else {
                            setOrder(prevOrder => ({ ...prevOrder, addAddress: false }))
                            toast.success("Receiver Address added successfully!!", {
                                autoClose: 2000
                            });
                        }
                    }
                ).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                })
            } else {
                toast.error("Plese fill all the fields!!", {
                    autoClose: 2000
                });
            }

            return true;
        }
    };



    const submitOrder = (e) => {
        e.preventDefault();
        const {
            receiverAddress,
            receiverId,
            trackingno,
            onlineStoreName,
            id,
            items,
            receiptImg,
            onlineStoreId
        } = order;
        if (receiptImg === null) {
            toast.error("Please upload invoice/receipt", {
                autoClose: 2000
            });
            return false;
        }
        let { customerId, firstName, lastName, phone, userType, email } = JSON.parse(loggedUser);
        let { pmbNo } = JSON.parse(localStorage.getItem("customer"));
        let address = {};
        Object.keys(receiverAddress).forEach((rec) => {
            if (rec === "closestCityId") {
                address["city"] = receiverAddress[rec]?.label;
                address["closestCityId"] = {
                    id: receiverAddress[rec]?.value,
                    name: receiverAddress[rec]?.label,
                };
            } else if (rec === "stateId")
                address["stateId"] = {
                    id: receiverAddress[rec].value,
                    name: receiverAddress[rec].label,
                };
            else if (rec === "countryId")
                address["countryId"] = {
                    id: receiverAddress[rec].value,
                    name: receiverAddress[rec].label,
                };
            else {
                address[rec] = receiverAddress[rec];
                address["customerId"] = customerId;
            }
        });

        const Items = items.map((item) => ({
            description: item.description,
            qty: item.qty,
            price: item.price,
            total: item.total,
            instruction: item.instruction,
            itemStatus: item.itemStatus,
            isInsure: item?.isInsure || null,
            insureprice: item?.insureprice || "0",
        }));

        const input = {
            customerId,
            customerInfo: {
                firstname: firstName,
                lastname: lastName,
                email,
                phone,
                pmb_no: pmbNo,
                userType,
            },
            trackingno,
            onlineStoreId: id ? onlineStoreId : onlineStoreName.toLowerCase().trim(),
            invoiceOrReceipt: receiptImg,
            items: Items,
            receiverId,
            receiverAddress: address,
            status: "pending",
            statusDate: new Date(),
        };

        if (id) input["id"] = id;

        const log = {
            action: id ? "UPDATE" : "INSERT",
            actionOn: "receivers",
            actionName: "addOrder",
            oldValue: id ? JSON.stringify(input) : "",
        };

        onMutate({ variables: { input: input, log: log } })
            .then(() => {
                setOrder({ ...order });
                if (id) {
                    toast.success("Order updated successfully!!", {
                        autoClose: 2000
                    });
                    navigate("/my-order")
                } else {
                    toast.success("New Order added successfully!!", {
                        autoClose: 2000
                    });
                    setOrder(prevOrder => ({ ...prevOrder, orderPlace: true }))
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
    };


    const changeHandler = (e, id) => {
        const generalSettings = JSON.parse(localStorage.getItem("generalSettings"));
        const updateItems = [...order?.items];
        const itemIndex = updateItems.findIndex((item) => item.id === id);
        const name = e.target.name;
        const value = e.target.value;
        if ((name === "price" || name === "qty") && value !== "") {
          const re = name === "price" ? /^\d+(\.\d{0,3})?$/ : /^[0-9\b]+$/;
          if (re.test(value)) {
            updateItems[itemIndex][name] = value;
          }
        } else {
            if (name === "isInsure") {
                updateItems[itemIndex][name] = value === "true" ? true : false;
            } else {
                updateItems[itemIndex][e.target.name] = value;
            }
        }

        updateItems[itemIndex].itemStatus =
            JSON.parse(customer).receivingServiceType === "standard"
                ? "received"
                : "pending";

        if (updateItems[itemIndex]["qty"] !== "" && updateItems[itemIndex]["price"] !== "") {
            updateItems[itemIndex]["total"] = (
                updateItems[itemIndex]["qty"] * updateItems[itemIndex]["price"]
            ).toFixed(2);
            updateItems[itemIndex]["isInsure"] === true
                ? (updateItems[itemIndex]["insureprice"] = (
                    (updateItems[itemIndex]["total"] *
                        generalSettings?.orderInsurancePercentage || 5) / 100
                ).toString())
                : (updateItems[itemIndex]["insureprice"] = "0");
        } else {
            updateItems[itemIndex]["total"] = "";
            updateItems[itemIndex]["insureprice"] = "0";
        }

        setOrder({ ...order, items: updateItems });
    };

    const itemDeleteHandler = (id) => {
        setOrder(prevOrder => ({
            ...prevOrder,
            items: prevOrder.items.filter(item => item.id !== id)
        }));
    };

    const insurePrice = (insureprice, id) => {
        const updateItems = [...order?.items];
        const itemIndex = updateItems.findIndex((item) => item.id === id);
        updateItems[itemIndex]["insureprice"] = insureprice.toString();
        setOrder(prevOrder => ({ ...prevOrder, items: updateItems }))
    };

    const adressReceiverDeleteHandler = async (e,deleteReceiver, id) => {
        e.preventDefault();
        const DeleteAddress = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'receivers',
                actionName: 'deleteReceiver',
                oldValue: ''
            };

            deleteReceiver({ variables: { id, log } })
                .then(async ({ data }) => {
                    if(data?.deleteReceiver){
                        toast.success("Receiver address deleted successfully!", {
                            autoClose: 2000
                        })
                    }
                }).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                })
        }
        confirmDeletion('DELETE RECEIVER ADDRESS' ,'Are you sure want to delete this record?' ,DeleteAddress);
    };

    const {
        isStep,
        addAddress,
        onlineStoreId,
        trackingno,
        items,
        receiverAddress,
        files,
        receiptImg,
        loader,
        id,
        orderPlace,
        searchStore,
        isCheck
    } = order;
    return (

        <div className="container-fluid">
            <div className="container mrgnTop90 pb-5">
                {!orderPlace && (
                    <div className="wrapper">
                        <div className="text-center mb-5">
                            {isStep === "step1" && (
                                <h4> {id ? "Edit" : "Add New"} Order</h4>
                            )}
                            {isStep === "step2" && <h4>{id ? "Edit" : "Add"} Item</h4>}
                            {isStep === "step3" && <h4>Receiver's Info</h4>}
                            {isStep === "step4" && <h4>Upload Receipt</h4>}
                        </div>
                        <div className="col-md-7 arrow-steps clearfix">
                            <div
                                className={
                                    isStep === "step1" ||
                                        isStep === "step2" ||
                                        isStep === "step3" ||
                                        isStep === "step4"
                                        ? "step current"
                                        : "step"
                                }
                                onClick={() => setOrder(prevOrder => ({
                                    ...prevOrder,
                                    isStep: "step1"
                                }))}
                            >
                                <span className="pointer"> Step 1</span>
                            </div>
                            <div
                                className={
                                    isStep === "step2" ||
                                        isStep === "step3" ||
                                        isStep === "step4"
                                        ? "step current"
                                        : "step"
                                }
                                onClick={() => {
                                    if (!onlineStoreId || !trackingno) {
                                        toast.error("Please fill required fields", {
                                            autoClose: 2000
                                        });
                                    } else setOrder(prevOrder => ({ ...prevOrder, isStep: "step2" }))
                                }}
                            >
                                <span className="pointer"> Step 2</span>
                            </div>
                            <div
                                className={
                                    isStep === "step3" || isStep === "step4"
                                        ? "step current"
                                        : "step"
                                }
                                onClick={() => {
                                    if (
                                        (items.length &&
                                            items.some(
                                                (item) =>
                                                    item.description === "" ||
                                                    item.qyt === "" ||
                                                    item.price === ""
                                            )) ||
                                        !items.length
                                    )
                                        toast.error("Please fill required fields", {
                                            autoClose: 2000
                                        });
                                    else setOrder(prevOrder => ({
                                        ...prevOrder,
                                        isStep: "step3"
                                    }));;
                                }}
                            >
                                <span className="pointer">Step 3</span>
                            </div>
                            <div
                                className={isStep === "step4" ? "step current" : "step"}
                                onClick={() => {
                                    if (isCheck === null && isStep !== "step3")
                                        toast.error("Please fill required fields", {
                                            autoClose: 2000
                                        });
                                    else if (isCheck === null && isStep === "step3")
                                        toast.error("Please select address", {
                                            autoClose: 2000
                                        });
                                    else setOrder(prevOrder => ({
                                        ...prevOrder,
                                        isStep: "step4"
                                    }));
                                }}
                            >
                                <span className="pointer"> Step 4</span>
                            </div>
                        </div>
                    </div>
                )}
                {/* ********** step1 start ********** */}
                {orderPlace ? (
                    <div className="col-md-5 order-section pb-3">
                        <div>
                            <div className="order-sucess">
                                <div className="order-data">
                                    <img
                                        src="../images/sucess.png"
                                        alt="OrderPlacedSucessfully "
                                    />
                                    <p className="order-place">Order Placed Sucessfully!</p>
                                    <Link to="/my-order" className="view-order">
                                        View Order
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div style={{ minHeight: 400 }}>
                        {isStep === "step1" && (
                            <div>
                                <div className="col-md-9 mAuto pb-3 addItemMain mt-5">
                                    <div className="mt-3 mb-3">
                                        <label className="headText mt-2">
                                            {id ? "Edit" : "Add New"} Order
                                        </label>
                                    </div>
                                    <div className="pb-3 boxShadowDiv pl-0 pr-0">
                                        <div className="col-md-12 mb-2">
                                            <label className="mb-0 labelFs14">
                                                Online Store<span className="text-danger">*</span>
                                            </label>
                                            <OnlineStoreList
                                                page={1}
                                                limit={10}
                                                searchStore={searchStore}
                                                setSearchStore={(val) => setOrder(prevOrder => ({ ...prevOrder, searchStore: val }))}
                                                onlineStoreId={onlineStoreId}
                                                getOnlineStore={(e) => handelChangeOnlineStore(e)}
                                                required
                                            />
                                        </div>
                                        <div className="col-md-12">
                                            <label className="mb-0 labelFs14">
                                                OrderTracking No.
                                                <span className="text-danger">*</span>
                                            </label>
                                            <input
                                                placeholder="Enter Tracking No"
                                                value={trackingno}
                                                onChange={(e) => handleChangeTracking(e)}
                                                className="input p-0 col-12 inputFs14"
                                                required
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* ********** step1 end ********** */}
                        {/* ********** step2 start ********** */}
                        {isStep === "step2" && (
                            <div className="col-md-12 p-0 addItemMain mt40">
                                <div className="mt-3 mb-3 d-inline-block w-100">
                                    <div className="col-md-6 mt-3 mb-3 pull-left">
                                        <label className="headText ml-3 mt-3">
                                            {id ? "Edit" : "Add"} Item Details
                                        </label>
                                    </div>
                                    <div className="col-md-6 pull-right">
                                        <button
                                            onClick={addNewItem}
                                            className="addItemBtn float-right m-3 mr-3 pointer pointer"
                                        >
                                            Add More Item
                                        </button>
                                    </div>
                                </div>
                                <div>
                                    {items.map((item) => {
                                        return (
                                            <div key={item.id}>
                                                <AddItemComp
                                                    items={item}
                                                    change={changeHandler}
                                                    itemDelete={itemDeleteHandler}
                                                    insurePrice={insurePrice}
                                                />
                                            </div>
                                        );
                                    })}
                                </div>
                            </div>
                        )}
                        {/* ********** step2 end ********** */}

                        {/* ********** step3 start ********** */}
                        {isStep === "step3" && (
                            <div className="flex mAuto col-md-10 p-0 flex-wrap flex-column-reverse">
                                <div className={addAddress ? "col-md-12" : "col-md-12"}>
                                    {addAddress && (

                                        <form
                                            id="frm_Receiver"
                                            className="needs-validation"
                                            noValidate="noValidate"
                                            onSubmit={(e) => formOnSubmitReceiverofCustomer(e)}
                                        >
                                            <div className="col-md-12 pb-3 addItemMain mt-5">
                                                <div className="mt-3 mb-3">
                                                    <label className="headText mt-2">
                                                        Shipment Will Be Delivered To
                                                    </label>
                                                </div>
                                                <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                                    <div className=" pl-0 pr-0">
                                                        <div className="col-md-12 mb-2">
                                                            <label className="mb-0 labelFs14">
                                                                Full Name<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                placeholder="Enter Full Name"
                                                                className="input p-0 col-12 inputFs14"
                                                                name="name"
                                                                value={receiverAddress?.name}
                                                                onChange={(e) => TextChangeHandler(e)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" pl-0 pr-0">
                                                        <div className="col-md-12 mb-2">
                                                            <label className="mb-0 labelFs14">
                                                                Mobile<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type='number'
                                                                placeholder="Enter Mobile"
                                                                className="input p-0 col-12 inputFs14"
                                                                name="phone"
                                                                value={receiverAddress?.phone}
                                                                onChange={(e) => TextChangeHandler(e)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="pl-0 pr-0">
                                                        <div className="col-md-12 mb-2">
                                                            <label className="mb-0 labelFs14">
                                                                Address Line 1<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                placeholder="Enter Address1"
                                                                className="input p-0 col-12 inputFs14"
                                                                name="address1"
                                                                value={receiverAddress?.address1}
                                                                onChange={(e) => TextChangeHandler(e)}
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="pl-0 pr-0">
                                                        <div className="col-md-12 mb-2">
                                                            <label className="mb-0 labelFs14">
                                                                Address Line 2
                                                            </label>
                                                            <input
                                                                placeholder="Enter Address2"
                                                                className="input p-0 col-12 inputFs14"
                                                                name="address2"
                                                                value={receiverAddress?.address2}
                                                                onChange={(e) => TextChangeHandler(e)}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" flex">
                                                        <div className="col-md-6 mb-2">
                                                            <label className="mb-0 labelFs14">
                                                                Country<span className="text-danger">*</span>
                                                            </label>
                                                            <CountryList
                                                                countryId={
                                                                    receiverAddress?.countryId?.value
                                                                }
                                                                name="countryId"
                                                                getCRUDCountryId={() => ""}
                                                                getCountry={(id) =>
                                                                    handleSelectChange(
                                                                        id,
                                                                        "countryId"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="mb-0 labelFs14">
                                                                State<span className="text-danger">*</span>
                                                            </label>
                                                            <StatesList
                                                                countryId={
                                                                    receiverAddress?.countryId?.value
                                                                }
                                                                stateId={
                                                                    receiverAddress?.stateId?.value
                                                                }
                                                                name="stateId"
                                                                getCRUDStateId={() => ""}
                                                                getState={(id) =>
                                                                    handleSelectChange(id, "stateId")
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className=" flex">
                                                        <div className="col-md-6 mb-2">
                                                            <label className="mb-0 labelFs14">
                                                                City<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                placeholder="Enter City"
                                                                className="input p-0 col-12 inputFs14"
                                                                name="city"
                                                                value={receiverAddress?.city}
                                                                onChange={(e) =>
                                                                    TextChangeHandler(e)
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                        <div className="col-md-6">
                                                            <label className="mb-0 labelFs14">
                                                                ZIP / POST Code<span className="text-danger">*</span>
                                                            </label>
                                                            <input
                                                                type='number'
                                                                placeholder="Enter Code"
                                                                className="input p-0 col-12 inputFs14"
                                                                name="zipCode"
                                                                value={receiverAddress?.zipCode}
                                                                onChange={(e) =>
                                                                    TextChangeHandler(e)
                                                                }
                                                                required
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="pl-0 pr-0">
                                                        <div className="col-md-12 mb-2">
                                                            <label className="mb-0 labelFs14">
                                                                Closest City<span className="text-danger">*</span>
                                                            </label>
                                                            <ClosestCity
                                                                countryId={
                                                                    receiverAddress?.countryId?.value
                                                                }
                                                                stateId={
                                                                    receiverAddress?.stateId?.value
                                                                }
                                                                closestCityId={
                                                                    receiverAddress?.closestCityId?.value
                                                                }
                                                                name="closestCityId"
                                                                getCRUDClosestCityId={() => ""}
                                                                getClosestCity={(id) =>
                                                                    handleSelectChange(
                                                                        id,
                                                                        "closestCityId"
                                                                    )
                                                                }
                                                            />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="col-md-12 text-center mt-1">
                                                    <button
                                                        type="submit"
                                                        className="sendBtn pointer"
                                                    >
                                                        Save Address
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    )}
                                </div>

                                {/* ------------------------- */}
                                <div className={addAddress ? "col-md-12" : "col-md-12"}>
                                    <div className="col-md-12 addItemMain mt-5">
                                        <div className="addItemScroll">
                                            <div className="mt-3 mb-3">
                                                <label className="headText mt-2">
                                                    Select Receiver
                                                </label>
                                                <label className="text-muted ml-3">
                                                    Please tell us who will recieve or pickup this
                                                    order.if not already on the list, kindly add a
                                                    new receiver.
                                                </label>
                                            </div>

                                            {data?.getReceiversOfCustomer &&
                                                data?.getReceiversOfCustomer.length > 0 &&
                                                data.getReceiversOfCustomer.map((d) => {
                                                    return (
                                                        <CustomerAddressPanel
                                                            id={d.id}
                                                            isCheck1={isCheck}
                                                            checked={() => _isCheck(d.id)}
                                                            popupCheck={d}
                                                            selectedReceiverData={(data) => {
                                                                const receiverData = {
                                                                    name: data?.name || null,
                                                                    phone: data?.phone || null,
                                                                    address1: data?.address1 || null,
                                                                    address2: data?.address2 || null,
                                                                    city: data?.city || null,
                                                                    closestCityId: data?.closestCityId
                                                                        ? {
                                                                            value:
                                                                                data.closestCityId?.id ||
                                                                                null,
                                                                            label:
                                                                                data.closestCityId?.name ||
                                                                                null,
                                                                        }
                                                                        : null,
                                                                    stateId: data?.stateId
                                                                        ? {
                                                                            value: data.stateId?.id || null,
                                                                            label:
                                                                                data.stateId?.name || null,
                                                                        }
                                                                        : null,
                                                                    countryId: data?.countryId
                                                                        ? {
                                                                            value:
                                                                                data.countryId?.id || null,
                                                                            label:
                                                                                data.countryId?.name || null,
                                                                        }
                                                                        : null,
                                                                    zipCode: data?.zipCode || null,
                                                                };
                                                                setOrder(prevOrder => ({
                                                                    ...prevOrder,
                                                                    addAddress: data?.value,
                                                                    isEdit: data?.value,
                                                                    receiverAddress: receiverData,
                                                                    receiverId: data.id
                                                                }));
                                                            }}
                                                            deleteReceiverData={
                                                                adressReceiverDeleteHandler
                                                            }
                                                        />
                                                    );
                                                })}
                                        </div>
                                        <div>
                                            <label
                                                onClick={() =>
                                                    setOrder(prevOrder => ({
                                                        ...prevOrder,
                                                        addAddress: !addAddress,
                                                        receiverAddress: {
                                                            name: null,
                                                            phone: null,
                                                            address1: null,
                                                            address2: null,
                                                            city: null,
                                                            closestCityId: {
                                                                label: null,
                                                                value: null,
                                                            },
                                                            stateId: { label: null, value: null },
                                                            countryId: { label: null, value: null },
                                                            zipCode: null,
                                                        },
                                                        isEdit: false

                                                    }))
                                                }
                                                className="labelFs14 mt-2 themeBlue pointer"
                                            >
                                                + Add a New Receiver
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}
                        {/* ********** step3 end ********** */}
                        {/* ********** step4 start ********** */}
                        {isStep === "step4" && (
                            <div className="col-md-9 mAuto pb-3 addItemMain mt-5">
                                <div className="mt-3 mb-3">
                                    <label className="headText mt-2">
                                        Upload Invoice / Receipt
                                    </label>
                                </div>
                                <div className="mb-3 boxShadowDiv">
                                    <Dropzone
                                        accept=".jpg, .png, .jpeg"
                                        onDrop={(acceptedFiles, fileRejections, e) =>
                                            onDrop(acceptedFiles, fileRejections, e)
                                        }
                                    >
                                        {({ getRootProps, getInputProps }) => (
                                            <section
                                                // onClick={InvoiceOrReceipt}
                                                className="container"
                                            >
                                                <div {...getRootProps({ className: "dropzone" })}>
                                                    <div
                                                        className="col-md-12 text-center d-flex flex-column align-items-center mt-1 pointer"
                                                        style={{ height: 300 }}
                                                    >
                                                        <input {...getInputProps()} />
                                                        {!files.length && loader === false && (
                                                            <>
                                                                <p className="labelFs14 mt-2">
                                                                    Drag File To Upload
                                                                </p>
                                                                <img
                                                                    src="../images/Upload.png"
                                                                    alt="Img"
                                                                    width="100px"
                                                                />
                                                                <button className="addItemBtn pointer mt-4">
                                                                    Browse Files
                                                                </button>
                                                            </>
                                                        )}
                                                        {receiptImg !== null || loader === false ? (
                                                            <aside className="thumbsContainer">
                                                                {files.length ? (
                                                                    files.map((file) => (
                                                                        <div className="thumb" key={file.name}>
                                                                            <div className="thumbInner">
                                                                                <img
                                                                                    src={file.preview}
                                                                                    className="thumbImg"
                                                                                    alt="invoice"
                                                                                />
                                                                            </div>
                                                                        </div>
                                                                    ))
                                                                ) : (
                                                                    <aside className="thumbsContainer">
                                                                        <div className="thumb">
                                                                            <div className="thumbInner">
                                                                                <img src={receiptImg} className="thumbImg" alt="" />
                                                                            </div>
                                                                        </div>
                                                                    </aside>
                                                                )}
                                                            </aside>
                                                        ) : (loader &&
                                                            <Loader newStyle={true} />
                                                        )}
                                                    </div>
                                                </div>
                                            </section>
                                        )}
                                    </Dropzone>
                                </div>
                                <div
                                    style={{ textAlign: "center" }}
                                    className="col-md-12 mt-4 mb-2"
                                >
                                    <button
                                        className="sendBtn pointer"
                                        onClick={(e) => submitOrder(e)}
                                    >
                                        Save
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                )}
                {/* ********** step3 end ********** */}
                {!orderPlace && (
                    <div className="row stepDiv">
                        {id && isStep === "step1" && (
                            <div className="m20 stepClearfix" style={{ marginTop: 20 }}>
                                <div
                                    onClick={() => {
                                        setOrder({ ...order }, () => {
                                            navigate("/my-order")
                                        });
                                    }}
                                >
                                    <div className="pointer">
                                        <img
                                            src="../images/next.png"
                                            alt="Img"
                                            width="50px"
                                            className="rotate180"
                                        />
                                    </div>
                                </div>
                            </div>
                        )}
                        {(isStep === "step2" ||
                            isStep === "step3" ||
                            isStep === "step4") && (
                                <div className="m20 stepClearfix" style={{ marginTop: 20 }}>
                                    <div href="#" onClick={() => _prevClick()}>
                                        <div className="pointer">
                                            <img
                                                src="../images/next.png"
                                                alt="Img"
                                                width="50px"
                                                className="rotate180"
                                            />
                                        </div>
                                    </div>
                                </div>
                            )}
                        {isStep !== "step4" && (
                            <div
                                className="m20 mAuto stepClearfix"
                                style={{ marginTop: 20, marginBottom: 20 }}
                            >
                                <div
                                    href="#"
                                    onClick={() => {
                                        if (!onlineStoreId || !trackingno) {
                                            toast.error("Please fill required fields", {
                                                autoClose: 2000
                                            });
                                        } else if (
                                            isStep === "step2" &&
                                            ((items.length &&
                                                items.some(
                                                    (item) =>
                                                        item.description === "" ||
                                                        item.qyt === "" ||
                                                        item.price === ""
                                                )) ||
                                                !items.length)
                                        ) {
                                            toast.error("Please fill required fields", {
                                                autoClose: 2000
                                            });
                                        } else if (isStep === "step3" && isCheck === null)
                                            toast.error("Please select address", {
                                                autoClose: 2000
                                            });
                                        else _nextClick();
                                    }}
                                    className=""
                                >
                                    <div className="pointer">
                                        <img src="../images/next.png" alt="Img" width="50px" />
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
}
