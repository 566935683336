import React, { useEffect, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client';
import { OrderStatusType } from './Constant';
import { ADD_ORDER } from '../../graphql/mutation/NewOrderMutation';
import { DELETE_ORDER_ITEM } from '../../graphql/mutation/MyOrderMutation';
import { toast } from 'react-toastify';
import Loader from './Loader/Loader';
import { Link, useNavigate } from 'react-router-dom';
import _ from 'lodash';
import Select from 'react-select';
import * as $ from 'jquery';
import { GET_ALL_ORDERS_STATUS_WISE } from '../../graphql/query/orderQuery';
import confirmDeletion from './Common/BootBox';

export const OrderComp = ({ orderData, onlineStoreData, borderColor, pageNo, limit, orderStatus, customerId, isLoading, refetchData }) => {

  const navigate = useNavigate();
  const [dtl, setDtl] = useState(0);
  const [toggle, setToggle] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [statusType] = useState(OrderStatusType);
  const [order, setOrder] = useState([]);
  const [editIndex, setEditIndex] = useState(0);

  const [submitOrderMutation] = useMutation(ADD_ORDER);
  const [deleteOrderItemMutation] = useMutation(DELETE_ORDER_ITEM, {
    refetchQueries: [{ query: GET_ALL_ORDERS_STATUS_WISE, variables: { page: pageNo + 1, limit: limit, orderStatus: orderStatus, customerId: customerId } }]
  });

  useEffect(() => {
    if (orderData) {
      setOrder(orderData?.getCustomerOrdersStatusWise?.data);
    }
  }, [orderData]);

  const changeHandler = (e, id, parentId) => {
    if (order) {
      const data = [...order];
      const dataIndex = data.findIndex(d => d.id === parentId);
      const itemIndex = data[dataIndex].items.findIndex(item => item.id === id);
      const name = e.target.name;
      const value = e.target.value;

      let objectEle = data[dataIndex]
      let itemEle = [...objectEle.items]
      if ((name === "price" || name === "qty") && value !== "") {
        const re = name === "price" ? /^\d+(\.\d{0,3})?$/ : /^[0-9\b]+$/;
        if (re.test(value)) {
          itemEle[itemIndex] = { ...itemEle[itemIndex], [name]: value }
          objectEle = { ...objectEle, items: itemEle }
          data[dataIndex] = { ...objectEle }
        }
      } else {
        itemEle[itemIndex] = { ...itemEle[itemIndex], [e.target.name]: value }
        objectEle = { ...objectEle, items: itemEle }
        data[dataIndex] = { ...objectEle }
      }

      objectEle = data[dataIndex]
      itemEle = [...objectEle.items]

      if (data[dataIndex].items[itemIndex]['qty'] !== "" && data[dataIndex].items[itemIndex]['price'] !== "") {
        itemEle[itemIndex] = { ...itemEle[itemIndex], ['total']: (itemEle[itemIndex]['qty'] * itemEle[itemIndex]['price']).toFixed(2) }
        objectEle = { ...objectEle, items: itemEle }
        data[dataIndex] = { ...objectEle }
      } else {
        itemEle[itemIndex] = { ...itemEle[itemIndex], ['total']: '' }
        objectEle = { ...objectEle, items: itemEle }
        data[dataIndex] = { ...objectEle }
      }
      setOrder(data);
    }
  };

  const removeTypeName = (obj) => {
    if (!obj || typeof obj !== 'object') {
      return obj;
    }

    const newObj = Array.isArray(obj) ? [] : {};
    for (const prop in obj) {
      if (!['__typename', 'isActive', 'createdBy', 'createdAt', 'updatedAt', 'updatedBy'].includes(prop)) {
        newObj[prop] = typeof obj[prop] === 'object' ? removeTypeName(obj[prop]) : obj[prop];
      }
    }
    return newObj;
  };

  const submitOrder = (e, parentId) => {
    e.preventDefault();
    if (order) {
      const updatedOrder = [...order];
      const dataIndex = updatedOrder.findIndex(d => d.id === parentId);
      const data = removeTypeName(updatedOrder[dataIndex]);
      const input = {
        ...data,
        onlineStoreId: updatedOrder[dataIndex]?.onlineStoreId?.id
      };
      const log = {
        action: 'UPDATE',
        actionOn: 'receivers',
        actionName: "addOrder",
        oldValue: JSON.stringify(input)
      };
      input.items = input.items.map(d => { return { ...d, _id: d.id } });
      submitOrderMutation({ variables: { "input": input, "log": log } }).then((data) => {
        toast.success("Item updated successfully!!", {
          autoClose: 2000
        });
        if (refetchData) {
          refetchData();
        }
        setIsEdit(false);
      }).catch((err) => {
        toast.error(err.message, {
          autoClose: 2000
        });
      });
    }
  };
  const deleteOrderDetails = async (e, orderId, itemId) => {
    e.preventDefault();
    const deleteOrderData = async() => {
      const log = {
        action: 'DELETE',
        actionOn: 'orders',
        actionName: 'deleteOrderItem',
        oldValue: '',
      };

      const { data } = await deleteOrderItemMutation({ variables: { id: orderId, itemId, log } });

      if (data) {
        toast.success('Item Deleted successfully!!', {
          autoClose: 2000,
        });

        if (refetchData) {
          refetchData();
        }
      }
    }
    confirmDeletion('Delete ORDER','Are you sure you want to delete this record?' ,deleteOrderData);
  };

  const _onDetails = (i) => {
    setDtl(i);
    setToggle(!toggle)
  }
  const toolTipHandler = () => {
    $('p[data-toggle="tooltip"]').tooltip({
      animated: 'fade',
      placement: 'bottom',
    });
  }
  return (
    <div>
      {isLoading && <Loader />}
      {order && order.length > 0 ? (
        order.map((datas, i) => {
          const { trackingno, createdAt, onlineStoreId, status, items, trackStatus, shipmentId } = datas;
          const onlineStore = onlineStoreData?.getAllOnlineStores?.find(onlineStore => onlineStore.id === onlineStoreId?.id);
          const itemDescription = items.map(i => i.description).join(', ');
          const totalInsurance = _.sumBy(items, function (o) { return o.isInsure ? parseFloat(parseFloat(o.insureprice).toFixed(2)) : 0 });
          return (
            <div key={datas?.id} className="pb-1 boxShadowInv pl-3 pr-3 mb-4 boxInnerMargin" style={{ borderColor }}>
              <div className="col-md-12 row">
                <div className="col-md-6 mb-2 p-0">
                  <label className="mb-0 labelFs14 mr-2">Order Tracking No. : </label>
                  <label className="mb-0 inputFs14"> {trackingno}</label>
                </div>
                <div className="col-md-6 mb-2 p-0">
                  <div className="row float-right flex-nowrap">
                    {orderStatus === "pending" &&
                      <div onClick={() => {
                        navigate('/new-order', {
                          state: { datas, onlineStoreId: onlineStore ? onlineStore.id : onlineStoreId?.id }
                        });
                      }}>
                        <img src="../images/editGray.png" alt="Img" width='17px' className='mr-2 pointer' />
                        <label className="mb-0 labelFs14 mr-2 pointer">Edit</label>
                      </div>
                    }
                    {orderStatus !== "pending" &&
                      <>
                        <div className="col-md-12" >
                          <img onClick={() => {
                            navigate('/ordertracking', {
                              state: {
                                track: trackStatus, shipmentId, comp: 'orderComp', orderStatus: orderStatus
                              }
                            })
                          }}
                            src={orderStatus === 'received' ? "../images/receivedTruck.png" : orderStatus === 'admin_hold' ? "../images/adminHoldTruck.png" : orderStatus === 'customer_hold' ? "../images/adminHoldTruck.png" : orderStatus === 'shipped' ? "../images/shippedTruck.png" : orderStatus === 'refused' ? "../images/refusedTruck.png" : orderStatus === 'missed' ? "../images/refusedTruck.png" : orderStatus === 'packed' ? "../images/packedTruck.png" : "../images/partiallyreceivedTruck.png"}
                            alt="TruckImg" widht="80px" height="48px" className='mr-0 ml-0 pointer' />
                        </div>
                      </>
                    }
                  </div>
                </div>
              </div>
              <div className='bottomBrdr' />
              <div className="col-md-12 row pt-2">
                <div className="width14 mb-2">
                  <label className="mb-0 labelFs14">Date</label>
                  <p className="mb-0 inputFs14">{new Date(createdAt).toLocaleDateString()}</p>
                </div>
                <div className="width14 mb-2">
                  <label className="mb-0 labelFs14">Store</label>
                  <p className="mb-0 inputFs14 lastTicketMsg">{onlineStore?.name ? onlineStore?.name : onlineStoreId?.name}</p>
                </div>
                <div className="width14 mb-2">
                  <label className="mb-0 labelFs14">Item Description</label>
                  <p onMouseEnter={toolTipHandler} className="mb-0 inputFs14 text-over" data-toggle="tooltip" title={`${itemDescription}`}>{itemDescription}</p>
                </div>
                <div className="width14 mb-2">
                  <label className="mb-0 labelFs14">Total Insurance($)</label>
                  <p className="mb-0 inputFs14">{totalInsurance}</p>
                </div>
                <div className="width14 mb-2">
                  <label className="mb-0 labelFs14">Order Total($)</label>
                  <p className="mb-0 inputFs14">{items.reduce((prev, curr) => prev + +curr.total, 0)}</p>
                </div>
                <div className="width14 mb-2">
                  <label className="mb-0 labelFs14">Status</label>
                  <p className='mb-0 inputFs14'>{status}</p>
                </div>
                <div
                  className="width14">
                  <label
                    onClick={() => _onDetails(i)}
                    className="mb-0 labelFs14 float-right mt-3 themeBlue pointer">
                    {i === dtl && toggle ? 'Hide' : 'Details'}
                  </label>
                </div>
              </div>
              {i === dtl && toggle &&
                <div>
                  {items.map((itm, j) => {
                    const { description, qty, price, total, itemStatus, id } = itm;
                    return (
                      <div key={j}>
                        <div className='bottomBrdrLight' />
                        <div className="col-md-12 row p-0 pt-2">
                          {isEdit && editIndex === j ?
                            <>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Item-{j + 1}</label>
                                <input type="text" className="input p-0 col-12 inputFs14" value={description} placeholder="Item" onChange={e => changeHandler(e, id, datas.id)} name="description" />
                              </div>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Quantity</label>
                                <input type="text" className="input p-0 col-12 inputFs14" value={qty} placeholder="Quantity" onChange={e => changeHandler(e, id, datas.id)} name="qty" />
                              </div>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Price($)</label>
                                <input type="text" className="input p-0 col-12 inputFs14" value={price} placeholder="Price" onChange={e => changeHandler(e, id, datas.id)} name="price" />
                              </div>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Total($)</label>
                                <input type="text" className="input p-0 col-12 inputFs14" value={total} name="total" />
                              </div>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Status</label>
                                <Select
                                  className="basic-single ffPoppins"
                                  isDisabled={true}
                                  options={statusType}
                                  value={statusType.filter(option => option.value === itemStatus)}
                                />
                              </div>
                            </>
                            :
                            <>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Item-{j + 1}</label>
                                <p className="mb-0 inputFs14">{description}</p>
                              </div>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Quantity</label>
                                <p className="mb-0 inputFs14">{qty}</p>
                              </div>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Price($)</label>
                                <p className="mb-0 inputFs14">{price}</p>
                              </div>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Total($)</label>
                                <p className="mb-0 inputFs14">{total}</p>
                              </div>
                              <div className="col-md-2 col-sm-6 mb-2">
                                <label className="mb-0 labelFs14">Status</label>
                                <p className="mb-0 inputFs14">{itemStatus}</p>
                              </div>
                            </>}
                          {datas.status === "pending" &&
                            <div className="col-md-2 col-sm-6">
                              <div className="mt-2">
                                {isEdit && editIndex === j ? (
                                  <div className="col-md-12 p-0 flex mb-2">
                                    <div className="flex" onClick={(e) => submitOrder(e, datas?.id)}>
                                      <img src="../images/saveOrder.png" alt="Img" width='15px' className='mr-2 pointer' />
                                      <label className="mb-0 labelFs14 mr-2 pointer">Save</label>
                                    </div>
                                    <div className="flex" onClick={() => setIsEdit(false)}>
                                      <img src="../images/cancelOrder.png" alt="Img" width='20px' className='mr-2 pointer' />
                                      <label className="mb-0 labelFs14 mr-2 pointer">Cancel</label>
                                    </div>
                                  </div>
                                ) : (
                                  <div onClick={() => { setIsEdit(true); setEditIndex(j); }}>
                                    <img src="../images/editGray.png" alt="Img" width='17px' className='mr-2 pointer' />
                                    <label className="mb-0 labelFs14 mr-2 pointer">Edit</label>
                                  </div>
                                )}
                                <div onClick={e => deleteOrderDetails(e, datas.id, itm.id)}>
                                  <img src="../images/bin.png" alt="Img" width='17px' className='binColor mr-2 pointer' />
                                  <label className="mb-0 labelFs14 mr-2 pointer">Remove</label>
                                </div>
                              </div>
                            </div>
                          }
                        </div>
                      </div>
                    );
                  })}
                </div>
              }
            </div>
          );
        })
      ) : (
        <div className="boxShadowRate boxInnerMargin">
          <div className="text-center">
            <img src="./images/notFound.png" alt="not found" />
          </div>
        </div>
      )}
    </div>
  )
}
