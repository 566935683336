import React, { useEffect, useState } from 'react'
import { CustomerInformation } from './CustomerInformation';
import * as $ from "jquery";
import { useMutation } from '@apollo/client';
import { OrderTrackingDetails } from './OrderTrackingDetails';
import { MANUAL_ADD_EDIT_TRACK_STATUS } from '../../../graphql/mutation/TrackMutation';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from "react-toastify";
import DatePicker from "react-datepicker";
import Select from "react-select";
import { Link } from "react-router-dom";

const trackingStatus = [
    {
        value: "PACKAGE RECEIVED BY U.S. WAREHOUSE.",
        label: "PACKAGE RECEIVED AT WAREHOUSE",
    },
    {
        value: "PACKAGE PROCESSED FOR SHIPPING.",
        label: "PACKAGE PROCESSED FOR SHIPPING",
    },
    {
        value: "YOUR SHIPMENT HAS BEEN DELIVERED TO THE CARRIER AIRLINE.",
        label: "SHIPMENT DELIVERED TO AIRLINE CARRIER",
    },
    {
        value:
            "YOUR SHIPMENT HAS ARRIVED DESTINATION AIRPORT AND IS GOING THROUGH CUSTOMS CLEARANCE.",
        label: "SHIPMENT ARRIVED DESTINATION AIRPORT",
    },
    {
        value:
            "YOUR SHIPMENT HAS BEEN RECEIVED BY OUR LAGOS WAREHOUSE AND IS READY TO BE DELIVERED OR PICKED UP.",
        label: "SHIPMENT RECEIVED AT LAGOS, NIGERIA",
    },
    { value: "YOUR SHIPMENT HAS BEEN PICKED UP.", label: "SHIPMENT PICKED UP" },
    { value: "YOUR SHIPMENT HAS BEEN DELIVERED.", label: "SHIPMENT DELIVERED" },
    { value: "THIS SHIPMENT IS DELAYED.", label: "DELAYED" },
];

const statusMessages = {
    wareHouse: "PACKAGE RECEIVED BY U.S. WAREHOUSE.",
    packageProcess: "PACKAGE PROCESSED FOR SHIPPING.",
    airlineCarrier: "YOUR SHIPMENT HAS BEEN DELIVERED TO THE CARRIER AIRLINE.",
    destinationAirport: "YOUR SHIPMENT HAS ARRIVED DESTINATION AIRPORT AND IS GOING THROUGH CUSTOMS CLEARANCE.",
    lagosWarehouse: "YOUR SHIPMENT HAS BEEN RECEIVED BY OUR LAGOS WAREHOUSE AND IS READY TO BE DELIVERED OR PICKED UP.",
    pickedUp: "YOUR SHIPMENT HAS BEEN PICKED UP.",
    delivered: "YOUR SHIPMENT HAS BEEN DELIVERED.",
    delayed: "THIS SHIPMENT IS DELAYED."
};

export const TrackOrder = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [trackOrder, setTrackOrder] = useState({
        trackingDate: null,
        trackingTime: null,
        track: [],
        selectedTrack: null,
        remark: "",
        isEdit: false,
        customerInfo: null,
        oldStatus: "",
        currentDate: new Date(),
    })
    const [onMutate] = useMutation(MANUAL_ADD_EDIT_TRACK_STATUS);

    useEffect(() => {
        if (location) {
            const { state } = location;
            if (state !== undefined) {
                if (state.track !== undefined) {
                    // eslint-disable-next-line
                    for (const i of state.track) {
                        removeTypeName(i);
                        setTrackOrder(preData => ({ ...preData, track: state.track }))

                    }
                }
                if (state.customerInfo !== undefined) {
                    delete state.customerInfo.__typename;
                    setTrackOrder(preData => ({ ...preData, customerInfo: state.customerInfo }))

                }
            }
        }
        $(".react-datepicker-wrapper").addClass("d-flex");
    }, [location]);

    const AddTrackingOrder = (e) => {
        e.preventDefault();
        setTrackOrder(preData => ({
            ...preData,
            trackingDate: null,
            trackingTime: null,
            selectedTrack: null,
            remark: "",
            isEdit: false,
        }))
        $("#AddTrackingOrderModal").modal("show");
    };
    const removeTypeName = (obj) => {
        // eslint-disable-next-line
        for (const prop in obj) {
            if (prop === "__typename") delete obj[prop];
            else if (typeof obj[prop] === "object") removeTypeName(obj[prop]);
        }
    };
    const submitTrack = (e) => {
        e.preventDefault();
        const { customerInfo, selectedTrack, oldStatus, isEdit, trackingDate, trackingTime } = trackOrder;
        if (selectedTrack === null || !trackingDate || !trackingTime) return toast.error("Please fill required fields", {
            autoClose: 2000
        });
        const trackDate = new Date(trackingDate).toISOString();
        const trackTime = new Date(trackingTime).toISOString();
        const variables = {
            orderId: customerInfo.orderId,
            status: selectedTrack.value,
            date:
                trackDate.slice(0, trackDate.indexOf("T")) +
                trackTime.slice(trackTime.indexOf("T")),
        };

        if (isEdit) variables["oldStatus"] = oldStatus;
        onMutate({ variables })
            .then(() => {
                resetTrack();
                if (isEdit) {
                    $("#AddTrackingOrderModal").modal("hide");
                    // navigate(`/track-order/${customerInfo.orderId}`,{state: { trackId: customerInfo.orderId }  })
                    navigate('/order', { state: { trackId: customerInfo.orderId } })
                    toast.success("Track updated successfully!!", {
                        autoClose: 2000
                    });
                } else {
                    $("#AddTrackingOrderModal").modal("hide");
                    navigate('/order', { state: { trackId: customerInfo?.orderId } })
                    toast.success("New Track added successfully!!", {
                        autoClose: 2000
                    });
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
    };

    const resetTrack = () => {
        setTrackOrder(preData => ({
            ...preData, trackingDate: null,
            trackingTime: null,
            selectedTrack: null,
            remark: "",
            isEdit: false,
            oldStatus: ""
        }))
    };
    const orderTrackingDate = (date) => {
        setTrackOrder(preData => ({ ...preData, trackingDate: date }))
    };
    const orderTrackingTime = (date) => {
        setTrackOrder(preData => ({ ...preData, trackingTime: date }))
    };
    const { track, selectedTrack, remark, isEdit, customerInfo } = trackOrder;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 p-0">
                    <div className="mb-20">
                        <p className="mainTitle m-0 mt_-15">
                            Track Order: {customerInfo?.trackingno || ""}
                        </p>
                        <span className="borderBottomOfTitle"></span>
                    </div>
                </div>
                <div className="col-md-4 p-0">
                    <div className="float-right">
                        <button
                            className="btn btn-success btn-text bg-lightGreen"
                            onClick={AddTrackingOrder}
                        >
                            <i className="fa fa-plus"></i> Add Tracking Order
                        </button>
                    </div>
                </div>
            </div>

            <CustomerInformation customerInfo={customerInfo} track={track} statusMessages={statusMessages} />


            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Order Tracking Details</span>
                        </div>
                        <OrderTrackingDetails setTrackOrder={setTrackOrder} trackOrder={trackOrder} statusMessages={statusMessages} />
                    </div>
                </div>
            </div>

            <div
                className="modal show"
                id="AddTrackingOrderModal"
                data-backdrop="static"
                tabIndex="-1"
                role="dialog"
            >
                <div
                    className="modal-dialog modal-xl modal-dialog-centered "
                    role="document"
                >
                    <div className="modal-content">
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="mb-20">
                                        <p className="mainTitle m-0 mt_-15">
                                            {isEdit ? "Edit Tracking Order" : "Add Tracking Order"}
                                        </p>
                                        <span className="borderBottomOfTitle"></span>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="ibox">
                                            <div className="ibox-title">
                                                <span>Tracking Order Details</span>
                                            </div>
                                            <div className="ibox-content">
                                                <form
                                                    onSubmit={(e) =>
                                                        submitTrack(e)
                                                    }
                                                >
                                                    <div className="form-row">
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                            <label className="frm-label">Date <span className="text-danger">*</span></label>
                                                            <DatePicker
                                                                className="form-control ffPoppins d-flex"
                                                                placeholderText="mm/dd/yyyy"
                                                                selected={trackOrder?.trackingDate}
                                                                onChange={orderTrackingDate}
                                                                minDate={trackOrder.currentDate}
                                                            />
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                            <label className="frm-label">Time <span className="text-danger">*</span></label>
                                                            <DatePicker
                                                                className="form-control ffPoppins d-flex"
                                                                placeholderText="hh:mm aa"
                                                                showTimeSelect
                                                                showTimeSelectOnly
                                                                timeIntervals={15}
                                                                timeCaption="Time"
                                                                dateFormat="hh:mm aa"
                                                                selected={trackOrder?.trackingTime}
                                                                onChange={orderTrackingTime}
                                                            />
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                            <label className="frm-label">
                                                                Status<span className="text-danger">*</span>
                                                            </label>
                                                            <Select
                                                                className="ffPoppins"
                                                                options={trackingStatus}
                                                                onChange={(value) => { setTrackOrder(preData => ({ ...preData, selectedTrack: value, remark: value.value })) }}
                                                                value={selectedTrack}
                                                            />
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                                            <label className="frm-label">
                                                                Remark
                                                            </label>
                                                            <input
                                                                type="text"
                                                                disabled
                                                                value={remark}
                                                                className="form-control ffPoppins"
                                                            />
                                                        </div>
                                                    </div>
                                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 p-0 d-flex  ">
                                                        <button className="btn btn-primary btn-text border-0 bg-searchBlue mr-3">
                                                            Save
                                                        </button>
                                                        <button
                                                            className="btn btn-danger btn-text bg-resetRed"
                                                            data-dismiss="modal"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12 p-0">
                    <Link to="/order">
                        <button
                            type="reset"
                            className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed"
                            data-dismiss="modal"
                        >
                            Cancel
                        </button>
                    </Link>
                </div>
            </div>
        </div>
    )
}
