import { gql } from "@apollo/client";

export const INSERT_UPDATE_CLOSEST_CITY = gql`
  mutation insertUpdateClosestCity($input: ClosestCityInput!,$log: LogInput){
    insertUpdateClosestCity(input: $input,log: $log){
      id
      name
      code
      countryId
      stateId
      isActive
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

export const DELETE_CLOSEST_CITY = gql`
  mutation deleteClosestCity($id: ID!,$log: LogInput){
    deleteClosestCity(id: $id,log: $log){
      id
      name
      code
      countryId
      stateId 
      isActive
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;