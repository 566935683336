import { gql } from "@apollo/client";

export const DASHBOARD_COUNT = gql`
query getDashboardCount{
  getDashboardCount{
    packageBox
    order
    customer
    ticket
  }
}
`