import React, { useState } from 'react'
import { useParams } from 'react-router-dom';
import Loader from '../../../../components/Loader/Loader';
import Pagination from '../../../../components/Common/Pagination';
import { GetDestinationWarehouseLocation } from './GetDestinationWarehouseLocation';
import { InsertUpdateLocation } from './InsertUpdateLocation';
import * as $ from 'jquery';
import { useQuery } from '@apollo/client';
import { GET_WAREHOUSE_LOCATIONS } from '../../../../../graphql/query/WarehouseQuery';

export const DestinationLocation = () => {
  const genSettings = JSON.parse(localStorage.getItem('generalSettings'))
  const user = JSON.parse(localStorage.getItem('loggedUser'));
  const { warehouseId } = useParams();
  const [warehouseLocation, setwarehouseLocation] = useState({
    id: null,
    warehouseId: warehouseId ? warehouseId : null,
    isActive: true,
    temp_isActive: { value: true, label: 'Active' },
    limit: genSettings?.paginationLimit || 10,
    pageNo: 0,
    filter: "",
    filterText: '',
    sortKey: 'createdAt',
    sortType: -1,
    openModal: 'Add New',
    WHLocationData: {},
    Old_Data: null,
  })
  const {data,loading ,refetch} = useQuery(GET_WAREHOUSE_LOCATIONS,{
    variables :{"page": warehouseLocation?.pageNo + 1, "limit": warehouseLocation?.limit, "filter": warehouseLocation?.filter, "sort":  { key: warehouseLocation?.sortKey, type: warehouseLocation?.sortType }, warehouseId: warehouseId },
    fetchPolicy :'cache-and-network'
  })

  const addWHLocation = (e) => {
    e.preventDefault();
    setwarehouseLocation(prevData => ({
      ...prevData, openModal: 'Add New',
      isActive: true,
      WHLocationData: {},
      temp_isActive: { value: true, label: 'Active' }
    }))
    $('#frm_wh_location').removeClass('was-validated');
    $('#frm_wh_location')[0].reset();
    $('#WarehouseLocationModal').modal('show');
  }
 const handleSearch = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
    searchWHLocation()
    }
  }
  const searchWHLocation = () => {
    setwarehouseLocation(prevData => ({
      ...prevData, pageNo: 0, filter: warehouseLocation?.filterText
    }))
  }
  const handlePageClick = data => {
    setwarehouseLocation(prevData => ({...prevData, pageNo: data.selected }))
  }
  const onResetSearch = () => {
    setwarehouseLocation(prevData => ({...prevData, pageNo: 0, filter: "", filterText: "" }))
  }
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8 p-0">
          <div className="mb-20">
            <p className="mainTitle m-0 mt_-15">Destination Warehouses Location</p>
            <span className="borderBottomOfTitle"></span>
          </div>
        </div>
        <div className="col-md-4 p-0">
          <div className="float-right">
            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addWHLocation(e)}> <i className="fa fa-plus"></i> Add New Destination Warehouses</button>
          </div>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-12 p-0">
          <div className="ibox">
            <div className="ibox-title">
              <span>Destination Warehouses Location Details</span>
            </div>
            <div className="ibox-content p-0">
              {loading && <Loader />}
              <div className="col-md-12 ph-20 mt-3">
                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                    <label className="frm-label">Search</label>
                    <input type="text" className="form-control" value={warehouseLocation.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setwarehouseLocation(prevData => ({ ...prevData, filterText: e.target.value }))} />
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="mt-30">
                      <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchWHLocation()} >Search</button>
                      <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                      <div className="pull-right">
                        <Pagination 
                          pageCount={data && data.getWareHouseLocations && Math.ceil(data?.getWareHouseLocations?.count / warehouseLocation?.limit)}
                          pageNo={warehouseLocation?.pageNo}
                          handlePageClick={(data) => handlePageClick(data)} />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             <GetDestinationWarehouseLocation  warehouseLocation={warehouseLocation} setwarehouseLocation={setwarehouseLocation} refetch={refetch} warehouseLocationData={data?.getWareHouseLocations}/>
            </div>
          </div>
        </div>
      </div>

      <div className="modal show" id="WarehouseLocationModal" data-backdrop="static" tabIndex="-1" role="dialog" >
        <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
          <div className="modal-content ">
            <div className="modal-body">
              <InsertUpdateLocation warehouseLocation={warehouseLocation} setwarehouseLocation={setwarehouseLocation} refetch={refetch} />
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
