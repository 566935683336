import React, { useEffect, useState } from 'react';
import { Route, Routes, BrowserRouter, Navigate, useLocation } from 'react-router-dom';
import Dashboard from './ui/pages/Dashboard';
import Login from './ui/pages/Login';
// import NotFoundPage from './ui/pages/Error';
import './css/main.css';
import './css/custom.css';
import HomeWeb from './ui/pages/CustomerPortal/HomeWeb/HomeWeb';
import withSession from './ui/components/Session/withSession';
import Navbar from './ui/layouts/Navbar';
import ContactUs from './ui/pages/ContactUs/ContactUs';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { AboutAsWeb } from './ui/pages/CustomerPortal/AboutAsWeb/AboutAsWeb';

import PrivacyPolicy from './ui/pages/CustomerPortal/PrivacyPolicy/PrivacyPolicy';
import ContactUsWeb from './ui/pages/CustomerPortal/ContactUsWeb/ContactUsWeb';
import FAQsWeb from './ui/pages/CustomerPortal/FAQsWeb/FAQsWeb';
import CustomerNotif from './ui/pages/CustomerPortal/CustomerNotif/CustomerNotif';
import { MyProfile } from './ui/pages/CustomerPortal/MyProfile/MyProfile';
import ChangePasswordCom from './ui/pages/CustomerPortal/ChangePasswordCom/ChangePasswordCom';
import CustomerRates from './ui/pages/CustomerPortal/CustomerRates/CustomerRates';
import { USAAddress } from './ui/pages/CustomerPortal/USAAddress/USAAddress';
import { ContactInfo } from './ui/pages/CustomerPortal/Contactinfo/ContactInfo';
import { ProhibitedWeb } from './ui/pages/CustomerPortal/ProhibitedWeb/ProhibitedWeb';
import { TermsConditionWeb } from './ui/pages/CustomerPortal/TermsConditionWeb/TermsConditionWeb';
import { ForgotPassword } from './ui/pages/ForgotPassword';
import { ResetPassword } from './ui/pages/ResetPassword';
import { SignUp } from './ui/pages/Signup/SignUp';
import { OrderTracking } from './ui/pages/CustomerPortal/OrderTrackingg/OrderTracking';
import { NewOrder } from './ui/pages/CustomerPortal/NewOrder/NewOrder';
import Packing from './ui/pages/Packing';
import Orders from './ui/pages/Orders';
import MyInvoice from './ui/pages/CustomerPortal/MyInvoice/MyInvoice';
import MyOrder from './ui/pages/CustomerPortal/MyOrder/MyOrder';
import { MyShipment } from './ui/pages/CustomerPortal/MyShipment/MyShipment';
import Customers from './ui/pages/Customers';
import { AccountInfo } from './ui/pages/CustomerPortal/AccountInfo/AccountInfo';
import Shipment from './ui/pages/Shipment';
import { HowItsWork } from './ui/pages/AdminSetting/HowItsWorks/HowItsWork';
import { PromotionalCount } from './ui/pages/AdminSetting/Promotion/PromotionalCount';
import { BoxDimension } from './ui/pages/AdminSetting/BoxDiamensions/BoxDimension';
import { GeneralSetting } from './ui/pages/AdminSetting/GeneralSetting/GeneralSetting';
import { Users } from './ui/pages/AdminSetting/Users/Users';
import { Bins } from './ui/pages/AdminSetting/Bins/Bins';
import { Rates } from './ui/pages/AdminSetting/Rates/Rates';
import { OnlineStore } from './ui/pages/AdminSetting/OnlineStores/OnlineStore';
import { FAQ } from './ui/pages/AdminSetting/FAQ\'s/FAQ\'s';
import ViewShipment from './ui/pages/Shipment/viewShipment';
import { Country } from './ui/pages/AdminSetting/Country/Country';
import { State } from './ui/pages/AdminSetting/State/State';
import { ClosestCity } from './ui/pages/AdminSetting/ClosestCity/ClosestCity';
import { DestinationWarehouse } from './ui/pages/AdminSetting/DestinationWareHouse/DestinationWarehouse';
import { DestinationLocation } from './ui/pages/AdminSetting/DestinationWareHouse/DestinationLocation/DestinationLocation';
import { WarehouseUser } from './ui/pages/AdminSetting/DestinationWareHouse/DestinationWarehouseUser/WarehouseUser';
import Report from './ui/pages/Report';
import { InvoiceList } from './ui/pages/AdminSetting/Invoice/InvoiceList';
import { ViewInvoice } from './ui/pages/AdminSetting/Invoice/ViewInvoice/ViewInvoice';
import ProfileChange from './ui/pages/CustomerPortal/MyProfile/MyProfileProvider';
import { Category } from './ui/pages/Product/Category';
import { SubCategory } from './ui/pages/Product/SubCategory/SubCategory';
import { Product } from './ui/pages/Product/Product/Product';
import CRUDOrder from './ui/pages/Orders/CRUDOrder';
import TrackPackage from './ui/pages/Shipment/TrackPackge';
import { TrackOrder } from './ui/pages/Orders/TrackOrder';
import { Notification } from './ui/pages/Orders/Notification';
import { Ticket } from './ui/pages/Ticket';
import { EditTicket } from './ui/pages/Ticket/EditTicket';

const App = ({ session, refetch }, props) => {
  // const [windowHeight, setWindowHeight] = useState(window.innerHeight);

  // useEffect(() => {
  //   const updateDimensions = () => setWindowHeight(window.innerHeight);
  //   window.addEventListener('resize', updateDimensions);
  //   return () => window.removeEventListener('resize', updateDimensions);
  // }, [windowHeight]);

  const login = JSON.parse(localStorage.getItem('loggedUser'));

  const AdminRoute = ({ children, session }) => {
    const location = useLocation();
    const me = session?.me;
    const role = me?.roles;
    if (!me || !isAdmin(role)) {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
    return children;
  };

  const PublicRoute = ({ children, session }) => {
    const location = useLocation();
    let role = session && session?.me;
    if (role) {
      if (role && isAdmin(role?.roles)) {
        return <Navigate to="/dashboard" state={{ from: location }} replace />;
      } else {

        return children;
      }
    } else {
      return children

    }
  };

  const restrictedRoute = (location) => {
    const customer = JSON.parse(localStorage.getItem("customer"));
    if (customer?.idProof && customer?.signature)
      return true;

    if (location.pathname !== "/login" && session?.me !== null && location.pathname !== '/')
      toast.error("Please, Upload your Id card and signature.", {
        autoClose: 2000
      });
    return false;
  };

  const CustomerRoute = ({ children, session }) => {
    const location = useLocation();
    const isAccountInfoRoute = location.pathname === "/account-info";
    const isRestricted = isAccountInfoRoute ? true : restrictedRoute(location);

    if ((session && session.me && session.me.roles === 'customer') || login?.roles === "customer") {
      if (isRestricted) {
        return children;
      } else {
        return <Navigate to="/account-info" state={{ from: location }} replace />;
      }
    } else {
      return <Navigate to="/login" state={{ from: location }} replace />;
    }
  };

  const isAdmin = (role) => {
    if ((role === 'admin' || role === 'standard' || role === 'special'))
      return true
    else
      return false
  }

  return (

    <BrowserRouter>
      <ProfileChange>
        <Navbar session={session} />
        <ToastContainer />
        <div style={{ backgroundColor: '#ededed' }}>
          <Routes>
            <Route exact path="/" element={<PublicRoute session={session}><HomeWeb /></PublicRoute>} />
            <Route exact path="/login" element={<PublicRoute session={session}><Login refetch={refetch} /></PublicRoute>} />
            <Route exact path="/about-us" element={<PublicRoute session={session}>{<AboutAsWeb />}</PublicRoute>} />
            <Route exact path="/privacy-policy" element={<PublicRoute session={session}>{<PrivacyPolicy />}</PublicRoute>} />
            <Route exact path="/contact-us-web" element={<PublicRoute session={session}>{<ContactUsWeb />}</PublicRoute>} />
            <Route exact path="/faqs-web" element={<PublicRoute session={session}>{<FAQsWeb />}</PublicRoute>} />
            <Route exact path="/prohibited-web" element={<PublicRoute session={session}>{<ProhibitedWeb />}</PublicRoute>} />
            <Route exact path="/terms-condition" element={<PublicRoute session={session}>{<TermsConditionWeb />}</PublicRoute>} />
            <Route exact path="/forgot-password" element={<PublicRoute><ForgotPassword /></PublicRoute>} />
            <Route exact path="/reset-password/:token" element={<PublicRoute><ResetPassword /></PublicRoute>} />
            <Route exact path="/signup" element={<PublicRoute session={session}><SignUp refetch={refetch} /></PublicRoute>} />
          </Routes>
        </div>
        <div className={`${session && session.me && isAdmin(session.me.roles) ? 'main-admin-wrap' : 'main-customer-wrap'}`}>
          <Routes>
            <Route exact path="/dashboard" element={<AdminRoute session={session}>{<Dashboard />}</AdminRoute>} />
            <Route exact path='/customer' element={<AdminRoute session={session}>{<Customers />}</AdminRoute>} />
            <Route exact path="/packing" element={<AdminRoute session={session}>{<Packing />}</AdminRoute>} />
            <Route exact path="/order" element={<AdminRoute session={session}>{<Orders />}</AdminRoute>} />
            <Route exact path='/shipment' element={<AdminRoute session={session}>{<Shipment />}</AdminRoute>} />
            <Route exact path="/how-its-work" element={<AdminRoute session={session}>{<HowItsWork />}</AdminRoute>} />
            <Route exact path="/promotionalcount" element={<AdminRoute session={session}>{<PromotionalCount />}</AdminRoute>} />
            <Route exact path="/box-dimension" element={<AdminRoute session={session}>{<BoxDimension />}</AdminRoute>} />
            <Route exact path="/general-settings" element={<AdminRoute session={session}>{<GeneralSetting />}</AdminRoute>} />
            <Route exact path="/users" element={<AdminRoute session={session}>{<Users />}</AdminRoute>} />
            <Route exact path="/bins" element={<AdminRoute session={session}>{<Bins />}</AdminRoute>} />
            <Route exact path="/rates" element={<AdminRoute session={session}>{<Rates />}</AdminRoute>} />
            <Route exact path="/online-stores" element={<AdminRoute session={session}>{<OnlineStore />}</AdminRoute>} />
            <Route exact path="/faqs" element={<AdminRoute session={session}>{<FAQ />}</AdminRoute>} />
            <Route exact path="/view-shipment/:shipmentId" element={<AdminRoute session={session}>{<ViewShipment props={props} />}</AdminRoute>} />
            <Route exact path="/report" element={<AdminRoute session={session}>{<Report props={props} />}</AdminRoute>} />
            <Route exact path="/category" element={<AdminRoute session={session}>{<Category />}</AdminRoute>} />
            <Route exact path="/sub-category" element={<AdminRoute session={session}>{<SubCategory />}</AdminRoute>} />
            <Route exact path="/product" element={<AdminRoute session={session}>{<Product />}</AdminRoute>} />
            <Route exact path='/crud-order' element={<AdminRoute session={session}>{<CRUDOrder />}</AdminRoute>} />
            <Route exact path="/track-package/:packageId" element={<AdminRoute session={session}>{<TrackPackage />}</AdminRoute>} />
            <Route exact path="/track-order/:orderId" element={<AdminRoute session={session}>{<TrackOrder />}</AdminRoute>} />
            <Route exact path="/notification/:orderId" element={<AdminRoute session={session}>{<Notification />}</AdminRoute>} />
            <Route exact path="/ticket" element={<AdminRoute session={session}>{<Ticket />}</AdminRoute>} />
            <Route exact path="/edit-ticket" element={<AdminRoute session={session}>{<EditTicket props={props} />}</AdminRoute>} />

            <Route exact path="/invoice-list" element={<AdminRoute session={session}>{<InvoiceList />}</AdminRoute>} />
            <Route exact path="/view-invoice" element={<AdminRoute session={session}>{<ViewInvoice />}</AdminRoute>} />
            <Route exact path="/country" element={<AdminRoute session={session}>{<Country />}</AdminRoute>} />
            <Route exact path="/state" element={<AdminRoute session={session}>{<State />}</AdminRoute>} />
            <Route exact path="/closest-city" element={<AdminRoute session={session}>{<ClosestCity />}</AdminRoute>} />
            <Route exact path="/destination-warehouse" element={<AdminRoute session={session}>{<DestinationWarehouse />}</AdminRoute>} />
            <Route exact path="/warehouse-location/:warehouseId" element={<AdminRoute session={session}>{<DestinationLocation />}</AdminRoute>} />
            <Route exact path="/warehouse-user/:warehouseId" element={<AdminRoute session={session}>{<WarehouseUser />}</AdminRoute>} />

            <Route exact path="/contact-us" element={<CustomerRoute session={session}>{<ContactUs />}</CustomerRoute>} />
            <Route exact path="/customer-notif" element={<CustomerRoute session={session}>{<CustomerNotif />}</CustomerRoute>} />
            <Route exact path="/my-profile" element={<CustomerRoute session={session}><MyProfile /></CustomerRoute>} />
            <Route exact path="/customer-rates" element={<CustomerRoute session={session}>{<CustomerRates />}</CustomerRoute>} />
            <Route exact path="/change-password-cust" element={<CustomerRoute session={session}>{<ChangePasswordCom />}</CustomerRoute>} />
            <Route exact path="/usa-address" element={<CustomerRoute session={session}>{<USAAddress />}</CustomerRoute>} />
            <Route exact path="/contact-info" element={<CustomerRoute session={session}>{<ContactInfo />}</CustomerRoute>} />
            <Route exact path="/ordertracking" element={<CustomerRoute session={session}>{<OrderTracking />}</CustomerRoute>} />
            <Route exact path="/new-order" element={<CustomerRoute session={session}>{<NewOrder />}</CustomerRoute>} />
            <Route exact path="/my-invoice" element={<CustomerRoute session={session}>{<MyInvoice />}</CustomerRoute>} />
            <Route exact path="/my-order" element={<CustomerRoute session={session}>{<MyOrder />}</CustomerRoute>} />
            <Route exact path="/shipment-detail" element={<CustomerRoute session={session}>{<MyShipment />}</CustomerRoute>} />
            <Route exact path="/my-shipment" element={<CustomerRoute session={session}>{<MyShipment />}</CustomerRoute>} />
            <Route exact path="/account-info" element={<CustomerRoute session={session}>{<AccountInfo />}</CustomerRoute>} />
          </Routes>
        </div>
      </ProfileChange>
    </BrowserRouter>

  );
};

export default withSession(App);
