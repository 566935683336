import { useMutation } from '@apollo/client'
import React from 'react'
import { DELETE_NOTIFICATION } from '../../../graphql/mutation/OrderMutation'
import confirmDeletion from '../../components/Common/BootBox';
import { toast } from 'react-toastify';
import moment from 'moment';

export const GetOrderNotification = ({ noti_List ,setNotification,notification ,refetch}) => {
    const [deleteNotification] = useMutation(DELETE_NOTIFICATION);
    const deleteNotificationDetails = (e, notiId) => {
        e.preventDefault();
        const deleteNotificationData = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'notifications',
                actionName: 'deleteNotification',
                oldValue: ""
            }

            deleteNotification({ variables: { "id": notiId, "log": log } }).then(async ({ data }) => {
                refetch();
                toast.success("Notification deleted successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion("NOTIFICATION", "Are you sure want to delete this record ?", deleteNotificationData);
    }
    const sortData = (sortKey) => {
        const newSortType = (notification.sortType === 1) ? -1 : 1;
        setNotification((prevData) => ({ ...prevData, sortKey: sortKey, sortType: newSortType }));
    };
    const {sortKey,sortType}  = notification;
    return (
        <div className="ibox-content p-0">
            <div className="table-responsive">
                <table className="table themeTable table-nowrap mb-0 tbl-sort">
                    <thead>
                        <tr>
                            <th className="text-center">No.</th>
                            <th
                                className={`sortColumn ${(sortKey === 'createdAt') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                onClick={(e) => sortData('createdAt')}>Date</th>
                            <th
                                className={`sortColumn ${(sortKey === 'createdAt') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                onClick={(e) => sortData('createdAt')}>Time</th>
                            <th
                                className={`sortColumn ${(sortKey === 'msg') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                onClick={(e) => sortData('msg')}>Notification</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            noti_List && noti_List.length > 0
                                ? noti_List.map((d, i) => {
                                    return (
                                        <tr key={d?.id}>
                                            <td className=" text-center">{i + 1}</td>
                                            <td>{moment(d.createdAt).format('MM/DD/YYYY')}</td>
                                            <td>{moment(d.createdAt).format('hh:mm a')}</td>
                                            <td>{d.msg}</td>
                                            <td>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => { deleteNotificationDetails(e, d.id); }}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </td>
                                        </tr>
                                    )
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={4} >No Records Found!</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
