import React from 'react'
import * as $ from "jquery";

export const CustomerInformation = ({customerInfo ,track,statusMessages}) => {
  

    const generateProgressBar = (width, color) => (
        <div className={`progress-bar bg-${color}`} style={{ width: `${width}%` }}></div>
    );

    const ZeroOrangeProgress = generateProgressBar(0, "Orange");
    const ZeroGreenProgress = generateProgressBar(0, "Green");
    const ZeroRedProgress = generateProgressBar(0, "Red");
    const ZeroBlueProgress = generateProgressBar(0, "SkyBlue");
    const ZeroPeachProgress = generateProgressBar(0, "lightBlueShade");
    const TwentyFiveOrangeProgress = generateProgressBar(20, "Orange");
    const TwentyFiveGreenProgress = generateProgressBar(20, "Green");
    const TwentyFiveRedProgress = generateProgressBar(20, "Red");
    const TwentyFivePeachProgress = generateProgressBar(20, "lightBlueShade");
    const TwentyFiveBlueProgress = generateProgressBar(20, "SkyBlue");
    const { wareHouse, packageProcess, airlineCarrier, destinationAirport,lagosWarehouse, pickedUp, delivered, delayed} = statusMessages
    return (
        <div className="row">
            <div className="col-md-12 p-0">
                <div className="ibox">
                    <div className="ibox-title">
                        <span>Customer information</span>
                    </div>
                    <div className="ibox-content p-0">
                        <table className="table themeTable table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>Order Date</th>
                                    <th>Customer Name</th>
                                    <th>PMB No.</th>
                                    <th>Customer Email</th>
                                    <th>Customer Mobile</th>
                                    <th>Type</th>
                                    <th>Status</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr className="border-bottom">
                                    <td>{customerInfo?.orderDate || ""}</td>
                                    <td>
                                        {customerInfo?.firstname + " " + customerInfo?.lastname}
                                    </td>
                                    <td>{customerInfo?.pmb_no || ""}</td>
                                    <td>{customerInfo?.email || ""}</td>
                                    <td>{customerInfo?.phone || ""}</td>
                                    <td>{customerInfo?.userType || ""}</td>
                                    <td>
                                        {customerInfo?.status
                                            ? customerInfo.status.charAt(0).toUpperCase() +
                                            customerInfo.status.slice(1)
                                            : ""}
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                        {track.length ? (
                            track[track.length - 1].status === wareHouse ? (
                                <div className="col-md-9 progressBarStyle">
                                    <div className="progress">
                                        {ZeroOrangeProgress}
                                        {ZeroGreenProgress}
                                        {ZeroRedProgress}
                                        {ZeroPeachProgress}
                                        {ZeroBlueProgress}
                                    </div>
                                </div>
                            ) : track[track.length - 1].status ===
                                "YOUR ORDER WAS PACKED" ? (
                                <div className="col-md-9 progressBarStyle">
                                    <div className="progress">
                                        <div
                                            className="progress-bar bg-Orange"
                                            style={{ width: "12%" }}
                                        ></div>
                                        {ZeroGreenProgress}
                                        {ZeroRedProgress}
                                        {ZeroBlueProgress}
                                    </div>
                                </div>
                            ) : track[track.length - 1].status === packageProcess ? (
                                <div className="col-md-9 progressBarStyle">
                                    <div className="progress">
                                        {TwentyFiveOrangeProgress}
                                        {ZeroGreenProgress}
                                        {ZeroRedProgress}
                                        {ZeroBlueProgress}
                                    </div>
                                </div>
                            ) : track[track.length - 1].status === airlineCarrier ? (
                                <div className="col-md-9 progressBarStyle">
                                    <div className="progress">
                                        {TwentyFiveOrangeProgress}
                                        {TwentyFiveGreenProgress}
                                        {ZeroRedProgress}
                                        {ZeroBlueProgress}
                                    </div>
                                </div>
                            ) : track[track.length - 1].status === destinationAirport ? (
                                <div className="col-md-9 progressBarStyle">
                                    <div className="progress">
                                        {TwentyFiveOrangeProgress}
                                        {TwentyFiveGreenProgress}
                                        {TwentyFiveRedProgress}
                                        {ZeroPeachProgress}
                                        {ZeroBlueProgress}
                                    </div>
                                </div>
                            ) : track[track.length - 1].status === lagosWarehouse ? (
                                <div className="col-md-9 progressBarStyle">
                                    <div className="progress">
                                        {TwentyFiveOrangeProgress}
                                        {TwentyFiveGreenProgress}
                                        {TwentyFiveRedProgress}
                                        {TwentyFivePeachProgress}
                                        {ZeroBlueProgress}
                                    </div>
                                </div>
                            ) : track[track.length - 1].status === pickedUp ? (
                                <div className="col-md-9 progressBarStyle">
                                    <div className="progress">
                                        {TwentyFiveOrangeProgress}
                                        {TwentyFiveGreenProgress}
                                        {TwentyFiveRedProgress}
                                        {TwentyFivePeachProgress}
                                        {TwentyFiveBlueProgress}
                                    </div>
                                </div>
                            ) : track[track.length - 1].status === delivered ? (
                                <div className="col-md-9 progressBarStyle">
                                    <div className="progress">
                                        {TwentyFiveOrangeProgress}
                                        {TwentyFiveGreenProgress}
                                        {TwentyFiveRedProgress}
                                        {TwentyFivePeachProgress}
                                        {TwentyFiveBlueProgress}
                                    </div>
                                </div>
                            ) : (
                                track[track.length - 1].status === delayed && (
                                    <div className="col-md-9 progressBarStyle">
                                        <div className="progress">
                                            {TwentyFiveOrangeProgress}
                                            {TwentyFiveGreenProgress}
                                            {TwentyFiveRedProgress}
                                            {TwentyFivePeachProgress}
                                            {TwentyFiveBlueProgress}
                                        </div>
                                    </div>
                                )
                            )
                        ) : (
                            <div className="col-md-9 progressBarStyle">
                                <div className="progress">
                                    {ZeroOrangeProgress}
                                    {ZeroGreenProgress}
                                    {ZeroRedProgress}
                                    {ZeroPeachProgress}
                                    {ZeroBlueProgress}
                                </div>
                            </div>
                        )}
                        <div
                            className="col-md-12 mt-30 row justify-content-center align-items-center"
                            style={{ bottom: "65px" }}
                        >
                            {track.length ? (
                                track[track.length - 1].status === wareHouse ? (
                                    <>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ReceivedTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                alt="missImg"
                                                src="../images/GProcessTrack.png"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GCarrierTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GCustomTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDestinationTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDeliverdTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                    </>
                                ) : track[track.length - 1].status ===
                                    "YOUR ORDER WAS PACKED" ? (
                                    <>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ReceivedTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                alt="missImg"
                                                src="../images/GProcessTrack.png"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GCarrierTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GCustomTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDestinationTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDeliverdTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                    </>
                                ) : track[track.length - 1].status === packageProcess ? (
                                    <>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ReceivedTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                alt="missImg"
                                                src="../images/ProcessTrack.png"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GCarrierTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GCustomTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDestinationTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDeliverdTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                    </>
                                ) : track[track.length - 1].status === airlineCarrier ? (
                                    <>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ReceivedTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                alt="missImg"
                                                src="../images/ProcessTrack.png"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GCarrierTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GCustomTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDestinationTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDeliverdTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                    </>
                                ) : track[track.length - 1].status ===
                                    destinationAirport ? (
                                    <>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ReceivedTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                alt="missImg"
                                                src="../images/ProcessTrack.png"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CarrierTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CustomTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDestinationTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDeliverdTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                    </>
                                ) : track[track.length - 1].status === lagosWarehouse ? (
                                    <>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ReceivedTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ProcessTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CarrierTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CustomTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/DestinationTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDeliverdTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                    </>
                                ) : track[track.length - 1].status === pickedUp ? (
                                    <>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ReceivedTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                alt="missImg"
                                                src="../images/ProcessTrack.png"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CarrierTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CustomTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/DestinationTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDeliverdTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                    </>
                                ) : track[track.length - 1].status === delayed ? (
                                    <>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ReceivedTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                alt="missImg"
                                                src="../images/ProcessTrack.png"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CarrierTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CustomTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/DestinationTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/GDeliverdTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                    </>
                                ) : track[track.length - 1].status === delivered ? (
                                    <>
                                        <div className="progressImg">
                                            <img
                                                src="../images/ReceivedTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                alt="missImg"
                                                src="../images/ProcessTrack.png"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CarrierTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/CustomTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/DestinationTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                        <div className="progressImg">
                                            <img
                                                src="../images/DeliverdTrack.png"
                                                alt="missImg"
                                                className="trackImg"
                                            />
                                        </div>
                                    </>
                                ) : (
                                    <div className="progressImg">
                                        <img
                                            src="../images/GReceivedTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                        <img
                                            src="../images/GProcessTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                        <img
                                            src="../images/GCarrierTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                        <img
                                            src="../images/GCustomTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                        <img
                                            src="../images/GDestinationTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                        <img
                                            src="../images/GDeliverdTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                    </div>
                                )
                            ) : (
                                <>
                                    <div className="progressImg">
                                        <img
                                            src="../images/GReceivedTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                    </div>
                                    <div className="progressImg">
                                        <img
                                            src="../images/GProcessTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                    </div>
                                    <div className="progressImg">
                                        <img
                                            src="../images/GCarrierTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                    </div>
                                    <div className="progressImg">
                                        <img
                                            src="../images/GCustomTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                    </div>
                                    <div className="progressImg">
                                        <img
                                            src="../images/GDestinationTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                    </div>
                                    <div className="progressImg">
                                        <img
                                            src="../images/GDeliverdTrack.png"
                                            alt="missImg"
                                            className="trackImg"
                                        />
                                    </div>
                                </>
                            )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
