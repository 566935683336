export const statusType = [
    { value: true, label: 'Active' },
    { value: false, label: 'InActive' },
  ];
  export const adminUsersRole = [
    { value: 'admin', label: 'Admin' },
    { value: 'standard', label: 'Standard' },
    { value: 'special', label: 'Special' },
  ];
  
  export const warehouseUsersRole = [
    { value: 'warehouse', label: 'Warehouse' },
    { value: 'contractor', label: 'Contractor' },
    { value: 'lastMile', label: 'Last miles' },
  ];
  
  export const RateType = [
    { value: 'Order', label: 'Order' },
    { value: 'Order Item', label: 'Order Item' },
    { value: 'Shipment', label: 'Shipment' },
    { value: 'Default', label: 'Default' },
  ];
  
  export const Percentage = [
    { value: '$', label: '$' },
    { value: '%', label: '%' },
  ];
  
  export const PerItems = [
    { value: 'Per Pound', label: 'Per Pound' },
    { value: 'Per Package', label: 'Per Package' },
    { value: 'Of Order Value', label: 'Of Order Value' },
    { value: 'Per Order', label: 'Per Order' },
    { value: 'Per Phone', label: 'Per Phone' },
    { value: 'Per Laptop', label: 'Per Laptop' },
    { value: 'Per Cubic Feet', label: 'Per Cubic Feet' },
  ];
  
  export const receivingServices = [
    { value: 'standard', label: 'Standard' },
    { value: 'verified', label: 'Verified' },
  ];
  
  export const deliveryServices = [
    { value: 'DTD', label: 'DTD' },
    { value: 'pickup', label: 'Pickup' },
  ];
  
  export const PromotionalType = [
    { value: 'Percentage', label: 'Percentage' },
    { value: 'fixAmount', label: 'Fix Amount' },
  ];
  
  export const OrderStatusType = [
    { value: 'pending', label: 'Pending' },
    { value: 'missed', label: 'Missed' },
    { value: 'received', label: 'Received' },
    { value: 'packed', label: 'Packed', isDisabled: true },
    { value: 'shipped', label: 'Shipped', isDisabled: true }
  ];
  
  export const OrderStatusList = [
    { value: 'pending', label: 'Pending', btnColor: '#0B429A' },
    { value: 'received', label: 'Received', btnColor: '#0B429A' },
    { value: 'admin_hold', label: 'Admin Hold', btnColor: '#0B429A' },
    { value: 'customer_hold', label: 'Customer Hold', btnColor: '#0B429A' },
    { value: 'shipped', label: 'Shipped', btnColor: '#0B429A' },
    { value: 'refused', label: 'Refused', btnColor: '#0B429A' },
    { value: 'missed', label: 'Missed', btnColor: '#0B429A' },
    { value: 'packed', label: 'Packed', btnColor: '#0B429A' },
    { value: 'partial_received', label: 'Partially Received', btnColor: '#0B429A' },
  ];
  
  export const shippingDateType = [
    { value: 'shippingDate', label: 'Shipping Date' },
    { value: 'estimatedDate', label: 'Estimated Date' },
  ];
  
  
  export const shipmentStatus = [
    { value: 'SHIPMENT DELIVERED TO AIRLINE CARRIER', label: 'SHIPMENT DELIVERED TO AIRLINE CARRIER', remark: 'YOUR SHIPMENT HAS BEEN DELIVERED TO THE CARRIER AIRLINE.' },
    { value: 'SHIPMENT ARRIVED DESTINATION AIRPORT', label: 'SHIPMENT ARRIVED DESTINATION AIRPORT', remark: 'YOUR SHIPMENT HAS ARRIVED DESTINATION AIRPORT AND IS GOING THROUGH CUSTOMS CLEARANCE.' },
    { value: 'SHIPMENT RECEIVED AT LAGOS, NIGERIA', label: 'SHIPMENT RECEIVED AT LAGOS, NIGERIA', remark: 'YOUR SHIPMENT HAS BEEN RECEIVED BY OUR LAGOS WAREHOUSE AND IS READY TO BE DELIVERED OR PICKED UP.' },
    { value: 'SHIPMENT PICKED UP', label: 'SHIPMENT PICKED UP', remark: 'YOUR SHIPMENT HAS BEEN PICKED UP.' },
    { value: 'SHIPMENT DELIVERED', label: 'SHIPMENT DELIVERED', remark: 'YOUR SHIPMENT HAS BEEN DELIVERED.' },
    { value: 'DELAYED', label: 'DELAYED', remark: 'THIS SHIPMENT IS DELAYED.' },
  ];
  
  export const detailOfUserType = {
    standard: {
      value: 'When we receive your packages, we will NOT verify the content. Sometimes items sent by sellers do not match what you ordered, hence we recommend verified service.'
    },
    verified: {
      value: 'When we receive your packages, we will open them to verify the content. We can then notify you immediately if there is any discrepancy. This will cost $1/package. We recommend this service.'
    }
  }
  
  export function generateXmlExample({ customerInfo, trackingno, binNum, items, createdAt }) {
    if (!customerInfo) {
      return null;
    }
    // const customerName = customerInfo.firstname + ' ' + customerInfo.lastname;
    let labelXml = '<?xml version="1.0" encoding="utf-8"?>\
    <DieCutLabel Version="8.0" Units="twips">\
  <PaperOrientation>Landscape</PaperOrientation>\
  <Id>Address</Id>\
  <IsOutlined>false</IsOutlined>\
  <PaperName>30252 Address</PaperName>\
  <DrawCommands>\
  <RoundRectangle X="0" Y="0" Width="1581" Height="5040" Rx="270" Ry="270" />\
  </DrawCommands>\
  <ObjectInfo>\
  <TextObject>\
  <Name>TEXT</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
  <LinkedObjectName />\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>False</IsVariable>\
  <GroupID>-1</GroupID>\
  <IsOutlined>False</IsOutlined>\
  <HorizontalAlignment>Center</HorizontalAlignment>\
  <VerticalAlignment>Top</VerticalAlignment>\
  <TextFitMode>ShrinkToFit</TextFitMode>\
  <UseFullFontHeight>True</UseFullFontHeight>\
  <Verticalized>False</Verticalized>\
  <StyledText>\
    <Element>\
      <String xml:space="preserve">BIN NUMBER</String>\
      <Attributes>\
        <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
      </Attributes>\
    </Element>\
  </StyledText>\
  </TextObject>\
  <Bounds X="3211.75862068966" Y="642.827586206897" Width="1529.37931034483" Height="243.310344827586" />\
  </ObjectInfo>\
  <ObjectInfo>\
  <TextObject>\
  <Name>TEXT_1</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
  <LinkedObjectName />\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>False</IsVariable>\
  <GroupID>-1</GroupID>\
  <IsOutlined>False</IsOutlined>\
  <HorizontalAlignment>Right</HorizontalAlignment>\
  <VerticalAlignment>Top</VerticalAlignment>\
  <TextFitMode>ShrinkToFit</TextFitMode>\
  <UseFullFontHeight>True</UseFullFontHeight>\
  <Verticalized>False</Verticalized>\
  <StyledText>\
    <Element>\
      <String xml:space="preserve"># OF ITEMS</String>\
      <Attributes>\
        <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
      </Attributes>\
    </Element>\
  </StyledText>\
  </TextObject>\
  <Bounds X="436.931034482759" Y="680.586206896553" Width="1132.13793103449" Height="349.241379310346" />\
  </ObjectInfo>\
  <ObjectInfo>\
  <TextObject>\
  <Name>TEXT__1</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
  <LinkedObjectName />\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>False</IsVariable>\
  <GroupID>-1</GroupID>\
  <IsOutlined>False</IsOutlined>\
  <HorizontalAlignment>Center</HorizontalAlignment>\
  <VerticalAlignment>Top</VerticalAlignment>\
  <TextFitMode>ShrinkToFit</TextFitMode>\
  <UseFullFontHeight>True</UseFullFontHeight>\
  <Verticalized>False</Verticalized>\
  <StyledText>\
    <Element>\
      <String xml:space="preserve">CUSTOMER NAME</String>\
      <Attributes>\
        <Font Family="Arial" Size="12" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
      </Attributes>\
    </Element>\
  </StyledText>\
  </TextObject>\
  <Bounds X="988.620689655175" Y="137.413793103448" Width="2880" Height="296.275862068966" />\
  </ObjectInfo>\
  <ObjectInfo>\
  <TextObject>\
  <Name>TEXT_2</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
  <LinkedObjectName />\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>False</IsVariable>\
  <GroupID>-1</GroupID>\
  <IsOutlined>False</IsOutlined>\
  <HorizontalAlignment>Center</HorizontalAlignment>\
  <VerticalAlignment>Top</VerticalAlignment>\
  <TextFitMode>ShrinkToFit</TextFitMode>\
  <UseFullFontHeight>True</UseFullFontHeight>\
  <Verticalized>False</Verticalized>\
  <StyledText>\
    <Element>\
      <String xml:space="preserve">TRACKING NUMBER</String>\
      <Attributes>\
        <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
      </Attributes>\
    </Element>\
  </StyledText>\
  </TextObject>\
  <Bounds X="1009.24137931034" Y="1090.79310344828" Width="2985.93103448276" Height="243.310344827587" />\
  </ObjectInfo>\
  <ObjectInfo>\
  <ShapeObject Stroke="SolidLine">\
  <Name>SHAPE</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
  <LinkedObjectName />\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>False</IsVariable>\
  <GroupID>-1</GroupID>\
  <IsOutlined>False</IsOutlined>\
  <ShapeType>Rectangle</ShapeType>\
  <LineWidth>15</LineWidth>\
  <LineAlignment>Center</LineAlignment>\
  <FillColor Alpha="0" Red="255" Green="255" Blue="255" />\
  </ShapeObject>\
  <Bounds X="357.482758620691" Y="561.137931034482" Width="1344" Height="375.724137931035" />\
  </ObjectInfo>\
  <ObjectInfo>\
  <ShapeObject Stroke="SolidLine">\
  <Name>SHAPE_1</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
  <LinkedObjectName />\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>False</IsVariable>\
  <GroupID>-1</GroupID>\
  <IsOutlined>False</IsOutlined>\
  <ShapeType>Rectangle</ShapeType>\
  <LineWidth>15</LineWidth>\
  <LineAlignment>Center</LineAlignment>\
  <FillColor Alpha="0" Red="255" Green="255" Blue="255" />\
  </ShapeObject>\
  <Bounds X="3213.1724137931" Y="550.758620689653" Width="1529.37931034483" Height="375.724137931035" />\
  </ObjectInfo>\
  <ObjectInfo>\
  <ShapeObject Stroke="SolidLine">\
  <Name>SHAPE__1</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
  <LinkedObjectName />\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>False</IsVariable>\
  <GroupID>-1</GroupID>\
  <IsOutlined>False</IsOutlined>\
  <ShapeType>Rectangle</ShapeType>\
  <LineWidth>15</LineWidth>\
  <LineAlignment>Center</LineAlignment>\
  <FillColor Alpha="0" Red="255" Green="255" Blue="255" />\
  </ShapeObject>\
  <Bounds X="2052.37931034482" Y="587.620689655172" Width="920.275862068968" Height="322.758620689655" />\
  </ObjectInfo>\
  <ObjectInfo>\
  <TextObject>\
  <Name>TEXT__2</Name>\
  <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
  <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
  <LinkedObjectName />\
  <Rotation>Rotation0</Rotation>\
  <IsMirrored>False</IsMirrored>\
  <IsVariable>False</IsVariable>\
  <GroupID>-1</GroupID>\
  <IsOutlined>False</IsOutlined>\
  <HorizontalAlignment>Center</HorizontalAlignment>\
  <VerticalAlignment>Top</VerticalAlignment>\
  <TextFitMode>ShrinkToFit</TextFitMode>\
  <UseFullFontHeight>True</UseFullFontHeight>\
  <Verticalized>False</Verticalized>\
  <StyledText>\
    <Element>\
      <String xml:space="preserve">DATE</String>\
      <Attributes>\
        <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
        <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
      </Attributes>\
    </Element>\
  </StyledText>\
  </TextObject>\
  <Bounds X="1999.41379310345" Y="614.103448275861" Width="893.793103448268" Height="243.310344827586" />\
  </ObjectInfo>\
  </DieCutLabel>';
    return labelXml;
  }
  
  
  export function generateBoxLabel({ receiverInfo, customerInfo, selectedValue }) {
    // const customerName = customerInfo?.firstname + ' ' + customerInfo?.lastname;
    // const receiverName = receiverInfo?.name
    // const receiverCity = receiverInfo?.city
    let labelXmlData = '<?xml version="1.0" encoding="utf-8"?>\
    <DieCutLabel Version="8.0" Units="twips">\
      <PaperOrientation>Landscape</PaperOrientation>\
      <Id>LargeShipping</Id>\
      <IsOutlined>false</IsOutlined>\
      <PaperName>30256 Shipping</PaperName>\
      <DrawCommands>\
        <RoundRectangle X="0" Y="0" Width="3331" Height="5715" Rx="270" Ry="270" />\
      </DrawCommands>\
      <ObjectInfo>\
        <TextObject>\
          <Name>TEXT</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <HorizontalAlignment>Left</HorizontalAlignment>\
          <VerticalAlignment>Top</VerticalAlignment>\
          <TextFitMode>ShrinkToFit</TextFitMode>\
          <UseFullFontHeight>True</UseFullFontHeight>\
          <Verticalized>False</Verticalized>\
          <StyledText>\
            <Element>\
              <String xml:space="preserve">SMS Tracking #</String>\
              <Attributes>\
                <Font Family="Arial" Size="10" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
              </Attributes>\
            </Element>\
          </StyledText>\
        </TextObject>\
        <Bounds X="1962" Y="1893.41379310345" Width="1449.93103448276" Height="216.827586206896" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <TextObject>\
          <Name>TEXT_2</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <HorizontalAlignment>Left</HorizontalAlignment>\
          <VerticalAlignment>Top</VerticalAlignment>\
          <TextFitMode>ShrinkToFit</TextFitMode>\
          <UseFullFontHeight>True</UseFullFontHeight>\
          <Verticalized>False</Verticalized>\
          <StyledText>\
            <Element>\
              <String xml:space="preserve">SENDER NAME</String>\
              <Attributes>\
                <Font Family="Arial" Size="14" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
              </Attributes>\
            </Element>\
          </StyledText>\
        </TextObject>\
        <Bounds X="336.000000000002" Y="776.689655172416" Width="2429.79310344828" Height="455.172413793103" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <TextObject>\
          <Name>TEXT_3</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <HorizontalAlignment>Left</HorizontalAlignment>\
          <VerticalAlignment>Top</VerticalAlignment>\
          <TextFitMode>ShrinkToFit</TextFitMode>\
          <UseFullFontHeight>True</UseFullFontHeight>\
          <Verticalized>False</Verticalized>\
          <StyledText>\
            <Element>\
              <String xml:space="preserve">RECEIVER NAME</String>\
              <Attributes>\
                <Font Family="Arial" Size="22" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
              </Attributes>\
            </Element>\
          </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="1222.44827586207" Width="4110.72413793103" Height="772.965517241379" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <BarcodeObject>\
          <Name>BARCODE</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <Text>LOS0808181815LOS48</Text>\
          <Type>Code39</Type>\
          <Size>Small</Size>\
          <TextPosition>Bottom</TextPosition>\
          <TextFont Family="Arial" Size="9" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
          <CheckSumFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
          <TextEmbedding>None</TextEmbedding>\
          <ECLevel>0</ECLevel>\
          <HorizontalAlignment>Center</HorizontalAlignment>\
          <QuietZonesPadding Left="0" Top="0" Right="0" Bottom="0" />\
        </BarcodeObject>\
        <Bounds X="336" Y="2179.53103448276" Width="5338" Height="576" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <TextObject>\
          <Name>TEXT_4</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <HorizontalAlignment>Right</HorizontalAlignment>\
          <VerticalAlignment>Top</VerticalAlignment>\
          <TextFitMode>ShrinkToFit</TextFitMode>\
          <UseFullFontHeight>True</UseFullFontHeight>\
          <Verticalized>False</Verticalized>\
          <StyledText>\
            <Element>\
              <String xml:space="preserve">BOX #</String>\
              <Attributes>\
                <Font Family="Arial" Size="16" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
              </Attributes>\
            </Element>\
          </StyledText>\
        </TextObject>\
        <Bounds X="4579.2" Y="207.206896551724" Width="1052.68965517242" Height="508.137931034482" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <TextObject>\
          <Name>TEXT_5</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <HorizontalAlignment>Left</HorizontalAlignment>\
          <VerticalAlignment>Top</VerticalAlignment>\
          <TextFitMode>ShrinkToFit</TextFitMode>\
          <UseFullFontHeight>True</UseFullFontHeight>\
          <Verticalized>False</Verticalized>\
          <StyledText>\
            <Element>\
              <String xml:space="preserve">RECEIVERS CITY</String>\
              <Attributes>\
                <Font Family="Arial" Size="20" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
              </Attributes>\
            </Element>\
          </StyledText>\
        </TextObject>\
        <Bounds X="361.655172413793" Y="176.275862068965" Width="2880" Height="614.068965517241" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <ShapeObject Stroke="SolidLine">\
          <Name>SHAPE</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <ShapeType>HorizontalLine</ShapeType>\
          <LineWidth>15</LineWidth>\
          <LineAlignment>Center</LineAlignment>\
          <FillColor Alpha="0" Red="255" Green="255" Blue="255" />\
        </ShapeObject>\
        <Bounds X="336" Y="701.48275862069" Width="5338" Height="15" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <ShapeObject Stroke="SolidLine">\
          <Name>SHAPE_1</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <ShapeType>HorizontalLine</ShapeType>\
          <LineWidth>15</LineWidth>\
          <LineAlignment>Center</LineAlignment>\
          <FillColor Alpha="0" Red="255" Green="255" Blue="255" />\
        </ShapeObject>\
        <Bounds X="336" Y="1862.27586206897" Width="5338" Height="15" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <ShapeObject Stroke="SolidLine">\
          <Name>SHAPE__1</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <ShapeType>HorizontalLine</ShapeType>\
          <LineWidth>15</LineWidth>\
          <LineAlignment>Center</LineAlignment>\
          <FillColor Alpha="0" Red="255" Green="255" Blue="255" />\
        </ShapeObject>\
        <Bounds X="336" Y="2940.10344827586" Width="5338" Height="15" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <TextObject>\
          <Name>TEXT_1</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <HorizontalAlignment>Left</HorizontalAlignment>\
          <VerticalAlignment>Top</VerticalAlignment>\
          <TextFitMode>ShrinkToFit</TextFitMode>\
          <UseFullFontHeight>True</UseFullFontHeight>\
          <Verticalized>False</Verticalized>\
          <StyledText>\
            <Element>\
              <String xml:space="preserve">Powered By </String>\
              <Attributes>\
                <Font Family="Arial" Size="12" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
              </Attributes>\
            </Element>\
            <Element>\
              <String xml:space="preserve">Cargoatlantic</String>\
              <Attributes>\
                <Font Family="Arial" Size="12" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
              </Attributes>\
            </Element>\
          </StyledText>\
        </TextObject>\
        <Bounds X="3422.55172413793" Y="3032.1724137931" Width="2244.41379310345" Height="216.827586206896" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <TextObject>\
          <Name>TEXT__1</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <HorizontalAlignment>Left</HorizontalAlignment>\
          <VerticalAlignment>Top</VerticalAlignment>\
          <TextFitMode>ShrinkToFit</TextFitMode>\
          <UseFullFontHeight>True</UseFullFontHeight>\
          <Verticalized>False</Verticalized>\
          <StyledText>\
            <Element>\
              <String xml:space="preserve">#ShipMyShoppings</String>\
              <Attributes>\
                <Font Family="Arial" Size="12" Bold="True" Italic="False" Underline="False" Strikeout="False" />\
                <ForeColor Alpha="255" Red="0" Green="0" Blue="0" HueScale="100" />\
              </Attributes>\
            </Element>\
          </StyledText>\
        </TextObject>\
        <Bounds X="336" Y="3005.68965517241" Width="1820.68965517242" Height="216.827586206896" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <DateTimeObject>\
          <Name>DATE-TIME</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <HorizontalAlignment>Right</HorizontalAlignment>\
          <VerticalAlignment>Top</VerticalAlignment>\
          <TextFitMode>ShrinkToFit</TextFitMode>\
          <UseFullFontHeight>True</UseFullFontHeight>\
          <Verticalized>False</Verticalized>\
          <DateTimeFormat>AbbrMonthDayLongYear</DateTimeFormat>\
          <Font Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
          <PreText />\
          <PostText />\
          <IncludeTime>False</IncludeTime>\
          <Use24HourFormat>False</Use24HourFormat>\
        </DateTimeObject>\
        <Bounds X="4757.24137931035" Y="1897.86206896552" Width="867.310344827582" Height="243.310344827585" />\
      </ObjectInfo>\
      <ObjectInfo>\
        <BarcodeObject>\
          <Name>QRCODE</Name>\
          <ForeColor Alpha="255" Red="0" Green="0" Blue="0" />\
          <BackColor Alpha="0" Red="255" Green="255" Blue="255" />\
          <LinkedObjectName />\
          <Rotation>Rotation0</Rotation>\
          <IsMirrored>False</IsMirrored>\
          <IsVariable>False</IsVariable>\
          <GroupID>-1</GroupID>\
          <IsOutlined>False</IsOutlined>\
          <Text>LOS0808181815LOS48</Text>\
          <Type>QRCode</Type>\
          <Size>Large</Size>\
          <TextPosition>None</TextPosition>\
          <TextFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
          <CheckSumFont Family="Arial" Size="8" Bold="False" Italic="False" Underline="False" Strikeout="False" />\
          <TextEmbedding>None</TextEmbedding>\
          <ECLevel>0</ECLevel>\
          <HorizontalAlignment>Center</HorizontalAlignment>\
          <QuietZonesPadding Left="0" Top="0" Right="0" Bottom="0" />\
        </BarcodeObject>\
        <Bounds X="4467.31034482758" Y="940.03448275862" Width="999.724137931037" Height="720" />\
      </ObjectInfo>\
    </DieCutLabel>';
    return labelXmlData;
  }