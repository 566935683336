import { gql } from "@apollo/client";

export const INSERT_UPDATE_COUNTRY = gql`
  mutation insertUpdateCountry($input: CountryInput!, $log: LogInput){
    insertUpdateCountry(input: $input, log: $log){
      id
      name
      code
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_COUNTRY = gql`
  mutation deleteCountry($id: ID!, $log: LogInput){
    deleteCountry(id: $id, log: $log){
      id
      name
      code
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;