import React, { useState } from 'react'
import { GET_SUBCATEGORY } from '../../../../graphql/query/CategoryQuery';
import { GetSubCategory } from './GetSubCategory';
import { InsertUpdateSubCategory } from './InsertUpdateSubCategory';
import * as $ from 'jquery';
import { useQuery } from '@apollo/client';

export const SubCategory = () => {
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'));
    const [subCategory, setSubCategory] = useState({
        openModal: 'Add New',
        temp_categoryId: null,
        limit: genSettings?.paginationLimit || 10,
        pageNo: 0,
        filter: "",
        filterText: '',
        sortKey: 'createdAt',
        sortType: -1,
        id: null,
        subCategoryData: {},
        Old_Data: {},
        isLoading: false
    })

    const { data, loading, refetch } = useQuery(GET_SUBCATEGORY, {
        variables: { "page": subCategory.pageNo + 1, "limit": subCategory.limit, "filter": subCategory?.filter, "sort": { key: subCategory?.sortKey, type: subCategory?.sortType } }
    })

    const AddnewSubCategory = (e) => {
        e.preventDefault();
        setSubCategory(preData => ({ ...preData, temp_categoryId : null ,openModal: 'Add New', subCategoryData: {name: ''} }))
        $('#frm_SubCategory').removeClass('was-validated');
        $('#SubCategoryModal').modal('show');
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 p-0">
                    <div className="mb-20">
                        <p className="mainTitle m-0 mt_-15">Sub Category</p>
                        <span className="borderBottomOfTitle"></span>
                    </div>
                </div>
                <div className="col-md-4 p-0">
                    <div className="float-right">
                        <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => { AddnewSubCategory(e) }}> <i className="fa fa-plus"></i> Add New Sub Category</button>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Sub Category List</span>
                        </div>
                        <GetSubCategory refetch={refetch} loading={loading} subCategoryData={data?.getSubCategory} subCategory={subCategory} setSubCategory={setSubCategory} />
                    </div>
                </div>
            </div>
            <div className="modal show" id="SubCategoryModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                    <div className="modal-content ">
                        <div className="modal-body">
                            <div className="row">
                                <div className="mb-20 ph-15">
                                    <p className="mainTitle m-0 mt_-15">{subCategory?.openModal} Sub-Category</p>
                                    <span className="borderBottomOfTitle"></span>
                                </div>
                            </div>

                            <InsertUpdateSubCategory subCategory={subCategory} setSubCategory={setSubCategory} refetch={refetch} />
                        </div>
                    </div>
                </div>
            </div>

        </div>
    )
}
