import { gql } from "@apollo/client";

export const CUSTOMER_NOTIFICATION = gql`
query getCustomerNotifications($customerId: ID,$sort:sortNotification){
    getCustomerNotifications(customerId:$customerId,sort:$sort){
      id
      customerId
      orderId
      tracking_no
      orderStatus
      name
      msg
      isDeleted
      isActive
      isRead
      createdAt
    }
  }
`