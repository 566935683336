import React from 'react'
import Loader from '../../components/Loader/Loader'
import { useMutation } from '@apollo/client'
import { INSERTUPDATE_CATEGORY } from '../../../graphql/mutation/CategoryMutation'
import { BASE_URL } from '../../../config'
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import { getS3SignUrl } from '../../../graphql/query/TicketQuery'

export const InsertUpdateCategory = ({ category, setCategory, refetch }) => {
    const [insertUpdateCategory, { loading }] = useMutation(INSERTUPDATE_CATEGORY);
    const [getPreSignedUrl] = useMutation(getS3SignUrl)
    const TextChangeHandler = (e) => {
        setCategory(preData => ({ ...preData,  
             categoryData: {
            ...categoryData,
            [e.target.name]: e.target.value
          }}))
    }

    const ChangeCategoryIcon = (event) => {
        const { target: { validity, files: [file], } } = event;
        if (validity.valid) {
            const fileExtension = file?.type.split("/")[1];
            const key = `changeCategoryIcon_${+new Date()}.${fileExtension}`;
            setCategory(preData => ({ ...preData, isLoading: true }))
            getPreSignedUrl({ variables: { type: key } }).then((data) => {
                if (data?.data?.getPreSignedUrl) {
                    fetch(data?.data?.getPreSignedUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": file?.type,
                        },
                        body: file,
                    }).then((response) => {
                        if (response?.ok && response?.status === 200) {
                            setCategory(preData => ({
                                ...preData, isLoading: false, categoryData: {
                                    ...categoryData,
                                    icon: `${BASE_URL}/${key}`
                                }
                            }))
                        } else {
                            setCategory(preData => ({ ...preData, isLoading: false }))
                        }
                    }).catch((errr) => {
                        setCategory(preData => ({ ...preData, isLoading: false }))
                    });
                } else {
                    setCategory(preData => ({ ...preData, isLoading: false }))
                }
            }).catch((err) => {
                setCategory(preData => ({ ...preData, isLoading: false }))
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    }
    const formOnSubmitCategory = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const { categoryData, Old_Data, openModal, id } = category;
        if (!form.checkValidity()) {
          event.stopPropagation();
          form.classList.add('was-validated');
          toast.error('Please fill the required fields...', {
            autoClose: 2000
          })
          return false;
        } else {
          let input = {};
          let old_value = {};
          let log = {};
          if (openModal === "Edit") {
            for (var obj in Old_Data) {
              if (categoryData[obj] !== Old_Data[obj]) {
                old_value[obj] = Old_Data[obj]
                input[obj] = categoryData[obj]
              }
            }
            input.id = id;
          }
          if (openModal === "Add New") {
            input = { ...categoryData };
          }
          log = {
            action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
            actionOn: 'categories',
            actionName: "insertUpdateCategory",
            oldValue: openModal === "Edit" ? JSON.stringify(input) : ""
          }
          insertUpdateCategory({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
            refetch();
            form.classList.remove('was-validated');
            form.reset();
            $('#CategoryModal').modal('hide');
             setCategory(preData => ({ ...preData, categoryData: {}}))
            if (openModal === "Edit") {
              toast.success("Category updated successfully!", {
                  autoClose: 2000
                })
            } else {
              toast.success("Category added successfully!", {
                  autoClose: 2000
                })
            }
          }).catch((err) => {
            toast.error(err.message, {
              autoClose: 2000
            })
          })
          return true;
        }
      }
    const { categoryData } = category;
    return (
        <>
            <div className="row">
                <div className="mb-20 ph-15">
                    <p className="mainTitle m-0 mt_-15">{category.openModal} Category</p>
                    <span className="borderBottomOfTitle"></span>
                </div>
            </div>
            <div className="ibox">
                {(loading || category?.isLoading) && <Loader />}
                <div className="ibox-title">
                    <span>Category Details</span>
                </div>
                <div className="ibox-content">
                    <form id="frm_Category" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitCategory(e)}>
                        <div className="form-row">
                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                <label className="frm-label">Category Name<span className="text-danger">*</span></label>
                                <input type="text" className="form-control ffPoppins" name="name" value={categoryData.name} onChange={(e) => TextChangeHandler(e)} required />
                            </div>
                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                <label className="frm-label">Category Icon<span className="text-danger">*</span></label><br />
                                <span className="btn btn-default btn-file btn-sm btn-danger ffPoppins mt-1">
                                    <i className="fa fa-upload"></i> Choose a File <input type="file" onChange={(e) => ChangeCategoryIcon(e)} required={category?.openModal === "Edit" ? false : true} />
                                </span>
                                {
                                    categoryData.icon
                                        ? <div>
                                            <label className="frm-label">Upload Category Icon</label><br />
                                            <div className="mt-3">
                                                <img src={categoryData.icon} alt="missImg" className="width70 img-thumbnail" />
                                            </div>
                                        </div>
                                        : <div className="mt-3">
                                            <img src="../images/noImage.png" alt="img" className="width70 img-thumbnail" />
                                        </div>
                                }
                            </div>

                            <div className="form-group">
                                <div className="mt-3">
                                    <button type="submit" className="btn btn-primary btn-text mr-3 border-0 bg-searchBlue" >Save</button>
                                    <button type="reset" className="btn btn-danger btn-text bg-resetRed" data-dismiss="modal">Cancel</button>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    )
}

