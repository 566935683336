import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import Select from 'react-select'
import { GET_BUSY_BINS } from '../../../graphql/query/binsQuery'
import _ from 'lodash';

const BusyBinsSelect = ({ binId, selectedOrderData, getBusyBinsInfo, isEdit }) => {
    const [value, setValue] = useState(null);
    const [colorError, setColorError] = useState(undefined);
    const { data: busyBinsList } = useQuery(GET_BUSY_BINS ,{
        variables : {},
        fetchPolicy : 'cache-and-network'
    });
    const [busyBinsOption, setBusyBinOption] = useState([]);

    useEffect(() => {
        if (busyBinsList) {
            if (busyBinsList?.getBusyBinList && busyBinsList?.getBusyBinList.length > 0) {

                let BusyBins = busyBinsList?.getBusyBinList
                let getBusyBins = []
                BusyBins.forEach(d => {
                    getBusyBins.push({ value: d.id, label: d.bin })
                });
                setBusyBinOption(getBusyBins)

                // const getBusyBins = busyBinsList.getBusyBinList.map(d => ({
                //     value: d.id,
                //     label: d.bin
                // }));
                // setBusyBinOption(getBusyBins);

                // Set default value if binId exists
                // if (binId) {
                //     const defaultBin = getBusyBins.find(bin => bin.value === binId);
                //     setValue(defaultBin);
                // }
            }
        }
    }, [busyBinsList]);

    useEffect(() => {
        if (busyBinsOption) {
            setDefaulBins();
        }
    }, [busyBinsOption, binId]);

    const setDefaulBins = () => {
        if (binId) {
            let BinsData = _.find(busyBinsOption, (d) => { return d.value === binId });
            setValue(BinsData);
        } else {
            setValue(null)
        }
    }

    const busyBinsHandleChange = (value) => {
        setColorError(true);
        setValue(value);
        const info = {
            id: value.value,
            name: value.label,
        };
        getBusyBinsInfo(info);
    };

    return (
        <Select
            className="basic-single ffPoppins"
            options={busyBinsOption}
            value={value}
            onChange={(value) => busyBinsHandleChange(value)}
            styles={{
                control: (provided, state) =>
                    !colorError && colorError !== undefined
                        ? {
                            ...provided,
                            boxShadow: "0 0 0 1px #dc3545 !important",
                            border: "1px #dc3545 !important"
                        }
                        : provided
            }}
            isDisabled={isEdit}
        />
    );
};

export default BusyBinsSelect;
