import React, { useState, useEffect } from 'react';
import CountryList from '../../components/DropdownSelect/CountrySelect';
import StatesList from '../../components/DropdownSelect/StateSelect';

const CustomerAddressDetails = ({ curdType, CustomerDetailsData, handleText, getCountryId, getStateId, saveCountryCode }) => {
  const [countryId, setCountryId] = useState(null);
  const [stateId, setStateId] = useState(null);

  useEffect(() => {
    if (curdType === "Edit" && CustomerDetailsData) {
      setCountryId(CustomerDetailsData.countryId && CustomerDetailsData.countryId.id);
      setStateId(CustomerDetailsData.stateId && CustomerDetailsData.stateId.id);
    }
  }, [curdType, CustomerDetailsData]);

  const TextChangeHandler = (e) => {
    handleText(e);
  }

  const handleChangeCountry = (id) => {
    setCountryId(id);
    setStateId(null);
    getCountryId(id);
  }

  const handleChangeState = (id) => {
    setStateId(id);
    getStateId(id);
  }

  return (
    <div className="form-row">
      <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
        <label className="frm-label">Address Line 1<span className="text-danger">*</span></label>
        <input type="text" className="form-control ffPoppins" name="address1" defaultValue={CustomerDetailsData?.address1} onChange={TextChangeHandler} required />
      </div>
      <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
        <label className="frm-label">Address Line 2</label>
        <input type="text" className="form-control ffPoppins" name="address2" defaultValue={CustomerDetailsData?.address2} onChange={TextChangeHandler} />
      </div>
      <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
        <label className="frm-label">Country<span className="text-danger">*</span></label>
        <CountryList countryId={countryId} name="countryId" withCode={true} getCountryCode={(code) => saveCountryCode(code)} getCRUDCountryId={handleChangeCountry} />
      </div>
      <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
        <label className="frm-label">State<span className="text-danger">*</span></label>
        <StatesList countryId={countryId} stateId={stateId} name="stateId" getCRUDStateId={handleChangeState} />
      </div>
      <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
        <label className="frm-label">City</label>
        <input type="text" className="form-control ffPoppins" name="city" defaultValue={CustomerDetailsData?.city} onChange={TextChangeHandler} />
      </div>
      <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
        <label className="frm-label">ZIP/Postal Code</label>
        <input type="number" className="form-control ffPoppins" name="zipcode" defaultValue={CustomerDetailsData?.zipcode} onChange={TextChangeHandler} />
      </div>
    </div>
  );
};

export default CustomerAddressDetails;
