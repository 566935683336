import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { GET_CLOSEST_CITY_FOR_MULTIPLE } from '../../../graphql/query/ClosetCityQuery';

const ClosestCity = ({ countryId, stateId, allData, getAllInfo, getCRUDClosestCityId, getClosestCity, closestCityId }) => {
  const [closestList, setClosestList] = useState([]);
  const [value, setValue] = useState(null);
  const [colorError, setColorError] = useState(undefined);

  const { loading, data } = useQuery(GET_CLOSEST_CITY_FOR_MULTIPLE, {
    variables: { countryId: countryId, stateId: stateId },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data && data?.getClosestCitiesCountryStateWise) {
      const Countriespropsdata = data?.getClosestCitiesCountryStateWise;
      let ClosestList = []
      Countriespropsdata.forEach(d => {
        ClosestList.push({
          value: d.id,
          label: `${d.name} || ${d.code}`
        })
      });
      setClosestList(ClosestList);
    } else {
      setClosestList([]);
      setValue(null);
    }
  }, [loading, data]);

  useEffect(() => {
    if(closestList?.length > 0 ){
      setDefaultClosest();
    }

  },[closestList]);

  const closestCitiesHandleChange = (selectedValue) => {
    const { getClosestCitiesCountryStateWise } = data;
    setValue(selectedValue);
    setColorError(true);

    if (allData) {
      let findData = _.find(getClosestCitiesCountryStateWise, d =>{return d.id === selectedValue.value});
      getAllInfo(findData);
    } else {
      getCRUDClosestCityId(selectedValue.value);
      if (getClosestCity)
        getClosestCity(selectedValue);
    }
  };

  const setDefaultClosest = () => {
    let closestCityData = _.find(closestList, (d) =>{return d.value === closestCityId});
    if (closestCityData) {
      setValue(closestCityData);
      setColorError(true);
    }
  };

  return (
    <Select
      className="basic-single ffPoppins"
      options={closestList}
      value={value}
      onChange={(selectedValue) => closestCitiesHandleChange(selectedValue)}
      styles={{
        control: (provided, state) =>
          !colorError && colorError !== undefined
            ? {
              ...provided,
              boxShadow: "0 0 0 1px #dc3545 !important",
              border: "1px #dc3545 !important"
            }
            : provided
      }}
    />
  );
};

export default ClosestCity;
