import React from 'react'

const Title = ({ name }) => {
    return (
        <div className="col-md-8 p-0">
            <div className="mb-20">
                <p className="mainTitle m-0 mt_-15">{name}</p>
                <span className="borderBottomOfTitle"></span>
            </div>
        </div>
    )
}

export default Title