import { gql } from "@apollo/client";

export const INSERT_UPDATE_PROMOTION = gql`
mutation insertUpdatePromotion($input: promotionInput!,$log: LogInput){
  insertUpdatePromotion(input: $input,log: $log){
    id
    code
    promoCodeType
    percentage
    minOrderAmount
    maxCashBackAmount
    maxUsage
    startDate
    endDate
    fixedAmount
    note
    isActive
    createdBy
    createdAt
    updatedBy
    updatedAt
}
}
`;

export const DELETE_PROMOTION = gql`
mutation deletePromotion($id: ID!,$log: LogInput){
  deletePromotion(id: $id, log: $log){
    id
    code
    promoCodeType
    percentage
    minOrderAmount
    maxCashBackAmount
    maxUsage
    startDate
    endDate
    fixedAmount
    note
    isActive
    createdBy
    createdAt
    updatedBy
    updatedAt
  }
}
`;
