import { gql } from "@apollo/client";

export const GET_CUTOMERUSER = gql`
  query getCustomers($page: Int, $limit: Int, $filter: String, $sort: customerSort, $from: String, $to: String, $serviceType: String, $status: Boolean){
    getCustomers(page: $page,limit: $limit, filter: $filter,sort:$sort, from:$from, to:$to, serviceType:$serviceType, status:$status ){
      count
      data {
        id
        companyName
        firstName
        lastName
        email
        receivingServiceType
        phone
        idProof
        signature
        address1
        address2
        city
        countryId {
          id
          name
          code
        }
        stateId {
          id
          name
        }
        pmbNo
        zipcode
        deliveryServiceType
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_CUSTOMERS = gql`
query getAllCustomerList($page: Int , $limit: Int , $filter: String , $id: String ){
  getAllCustomerList(page: $page , limit: $limit , filter: $filter , id: $id){
    id
    firstName
    lastName
    email
    receivingServiceType
    phone
    pmbNo
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}
`;