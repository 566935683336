import React, { useState } from 'react'
import { AccessDenied } from '../../../components/AccessDenied';
import { useQuery } from '@apollo/client';
import { GET_ONLINE_STORE } from '../../../../graphql/query/OnlineStoreQuery';
import ReactPaginate from 'react-paginate';
import { GetOnlineStore } from './GetOnlineStore';
import { InserUpdateOnlineStore } from './InserUpdateOnlineStore';
import * as $ from 'jquery';
import Loader from '../../../components/Loader/Loader';

export const OnlineStore = () => {
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'))
    const user = JSON.parse(localStorage.getItem('loggedUser'))
    const [onlineStore, setOnlineStore] = useState({
        openModal: 'Add New ',
        limit: genSettings?.paginationLimit || 10,
        pageNo: 0,
        filter: "",
        filterText: '',
        sortKey: 'createdAt',
        sortType: -1,
        id: null,
        storeData: {},
        Old_Data: null,
    });

    const { loading, data, refetch } = useQuery(GET_ONLINE_STORE, {
        variables: { "page": onlineStore?.pageNo + 1, "limit": onlineStore?.limit, "filter": onlineStore?.filter, "sort": { key: onlineStore.sortKey, type: onlineStore?.sortType } }
    })

    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchOnlineStore()
        }
    }
    const searchOnlineStore = () => {
        setOnlineStore(prevOnlineStore => ({ ...prevOnlineStore, pageNo: 0, filter: onlineStore.filterText }))
    }
    const onResetSearch = () => {
        setOnlineStore(prevOnlineStore => ({ ...prevOnlineStore, pageNo: 0, filter: "", filterText: "" }))
    }
    const handlePageClick = data => {
        setOnlineStore(prevOnlineStore => ({ ...prevOnlineStore, pageNo: data?.selected }))

    };

    const addStore = (e) => {
        e.preventDefault();
        setOnlineStore((prevBins) => ({ ...prevBins, storeData: {}, openModal: 'Add New ' }));
        $('#frm_Store').removeClass('was-validated');
        $('#frm_Store')[0].reset();
        $('#StoreModal').modal('show');
    }

    if (user.roles === 'standard') return <AccessDenied />
    else
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 p-0">
                        <div className="mb-20">
                            <p className="mainTitle m-0 mt_-15">Online Stores</p>
                            <span className="borderBottomOfTitle"></span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="float-right">
                            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addStore(e)}> <i className="fa fa-plus"></i> Add New Online Store</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12 p-0">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span>Online Stores Details</span>
                            </div>
                            <div className="ibox-content p-0">
                                {loading && <Loader />}
                                <div className="col-md-12 ph-20 mt-3">
                                    <div className="form-row">
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Search</label>
                                            <input type="text" className="form-control" value={onlineStore.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setOnlineStore(prevOnlineStore => ({ ...prevOnlineStore, filterText: e.target.value }))} />
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="mt-30">
                                                <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchOnlineStore()} >Search</button>
                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                                <div className="pull-right">
                                                    <ReactPaginate
                                                        previousLabel={'Back'}
                                                        nextLabel={'Next'}
                                                        pageCount={data?.getOnlineStores && Math.ceil(data?.getOnlineStores?.count / onlineStore?.limit)}
                                                        marginPagesDisplayed={2}
                                                        pageRangeDisplayed={5}
                                                        initialPage={0}
                                                        forcePage={onlineStore?.pageNo}
                                                        onPageChange={(data) => handlePageClick(data)}
                                                        containerClassName={'pagination mb-0'}
                                                        subContainerClassName={'page-item'}
                                                        activeClassName={'active'}
                                                        pageLinkClassName={'pagination-link'}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <GetOnlineStore refetch={refetch} store={data?.getOnlineStores} onlineStore={onlineStore} setOnlineStore={setOnlineStore} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal show" id="StoreModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                        <InserUpdateOnlineStore onlineStore={onlineStore} setOnlineStore={setOnlineStore} refetch={refetch} />
                    </div>
                </div>

            </div>
        )
}
