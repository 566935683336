import React, { useState } from 'react';
import { toast } from 'react-toastify';
import { useMutation } from '@apollo/client';
import { ChatBox } from './ChatBox';
import bootbox from 'bootbox'
import { CREATE_TICKET } from '../../../graphql/mutation/ContactUsMutation';
const ContactUs = () => {
  const loggedUser = JSON.parse(localStorage.getItem('loggedUser'));
  const [tabs, setTabs] = useState('openCases');
  const customerId = loggedUser.customerId;
  const [eventFire, setEventFire] = useState(false)
  const [createTicketMutation] = useMutation(CREATE_TICKET);

  const tabClick = (tabName) => {
    setTabs(tabName);
  };

  const createNewTicket = async (e) => {
    e.preventDefault();
    bootbox.confirm({
      title: "Create Ticket",
      message: "Are you sure?",
      buttons: {
        cancel: {
          label: 'NO',
          className: 'btn btn-sm bg-Red ffPoppins text-white'
        },
        confirm: {
          label: 'YES',
          className: 'btn btn-sm bg-Green ffPoppins text-white'
        }
      },
      callback: function (result) {
        if (result) {
           createTicketMutation({ variables: { customerId: customerId } }).then(({ data }) => {
            setEventFire(true)
            toast.success('Ticket created successfully!', {
              autoClose: 2000
            });
          }).catch((err) => {
            toast.error(err.message, {
              autoClose: 2000
            });
          });
        }
      }
    }); 
  };

  return (
    <div className="container mrgnTop110">
      <div className="text-center mb-2">
        <p className="headTitle">Contact Us</p>
      </div>
      <div className="flex bottomBrdr">
        <div className="flex contactWidth">
          <div
            onClick={() => tabClick('openCases')}
            className={'orderTabBtn mr-2 pointer'} style={{ backgroundColor: tabs === 'openCases' ? '#0B429A' : 'transparent',position:"relative" }}
          >
            <label className="mb-0" style={{ color: tabs === 'openCases' ? '#fff' : '#0B429A' }}>
              Open Cases
            </label>
            {tabs === 'openCases' &&
                  <div className="triangle-down-order" style={{borderBlockColor:'#0B429A'}}></div>
            }
          </div>
          <div
            onClick={() => tabClick('closeCases')}
            className={'orderTabBtn mr-2 pointer'} style={{ backgroundColor: tabs === 'closeCases' ? '#DC3535' : 'transparent',position:"relative" }}
          >
            <label className="mb-0" style={{ color: tabs === 'closeCases' ? '#fff' : '#DC3535' }}>
              Closed Cases
            </label>
            {tabs === 'closeCases' &&
                  <div className="triangle-down-order" style={{borderBlockColor:'#DC3535'}}></div>
            }
          </div>
        </div>
        <div className="float-right">
          <button className="ticketBtn pointer" onClick={(e) => createNewTicket(e)}>
            New Ticket
          </button>
        </div>
      </div>
      <div className="col-md-12 mAuto pb-2 invMain mt-30 mb-3" style={{ borderColor: tabs === 'openCases' ? '#0B429A' : '#DC3535' }}>
        <div className="mt-3 mb-3">
          <label className="headText mt-2">Chat</label>
        </div>
        <ChatBox loggedUser={loggedUser} isStatus={tabs} setEventFire={setEventFire} eventFire={eventFire}/>
      </div>
    </div>
  );
};

export default ContactUs;
