import { gql } from "@apollo/client";

export const GET_INVOICE = gql`
query getInvoice($page: Int, $limit: Int, $filter: String, $sort: invoiceSort,$from: String, $to: String, $status: String, $customerId: ID){
    getInvoice(page:$page,limit:$limit,filter:$filter, sort:$sort,from:$from,to:$to,status:$status,customerId:$customerId){
      count
      data{
        id
        invoiceNo
        orderId
        customerInfo{
          firstname
          lastname
          email
          phone
          pmb_no
          userType
        }
        orderDate
        trackingno
        orderStatus
        receiverInfo{
          name
          phone
          address1
          address2
          city
          closestCityId{
            id
            name
          }
          stateId{
            id
            name
          }
          countryId{
            id
            name
          }
          zipCode
        }
        receiverId
        ItemLevel
        OrderLevel
        ShipmentLevel
        Default
        charges
        boxDimension{
          id
          box_Num
          len
          width
          height
          weight
          dimensionalWeight
          cubicFeet
          rateIds
          boxDimensionId
          isDimension
          barcode
          status{
            status
            date
            type
          }
          assign{
            assignTo
            assignFrom
            status
            date
          }
        }
        packageBoxId
        packageDate
        totalAmount
        totalAmountNGN
        paymentData
        paymentSuccess
        status
        offlineRefNo
        customerId
        createdInvoiceData
        ShipmentLevel
        paymentUrl
        shipmentNo
        createdAt
        updatedAt
        orderDetails
        uploadEvidence
      }
    }
  }
`;

export const UPDATE_INVOICE_STATUS = gql`
query updateInvoiceStatus {
    updateInvoiceStatus
  }
  `