import { useMutation } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react'
import { getS3SignUrl } from '../../../../graphql/query/TicketQuery';
import { MY_ADDRESS_CHANGE, MY_PROFILE_CHANGE } from '../../../../graphql/mutation/UserMutation';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import CountryList from '../../../components/DropdownSelect/CountrySelect';
import StatesList from '../../../components/DropdownSelect/StateSelect';
import { useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { ServiceTypeSection } from './ServiceTypeSection';
import { useFileUpload } from '../../../components/Common/PreSignUrl';
import bootbox from 'bootbox'

export const AccountInfo = () => {
    const [tabs, setTabs] = useState('personalInfo');
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('loggedUser')))
    const [customer, setCustomer] = useState(JSON.parse(localStorage.getItem('customer')));
    const [receivingServiceType, setReceivingServiceType] = useState(JSON.parse(localStorage.getItem('customer'))?.receivingServiceType);
    const [deliveryServiceType, setDeliveryServiceType] = useState(JSON.parse(localStorage.getItem('customer'))?.deliveryServiceType);
    const [genralSettings, setGeneralSettings] = useState(JSON.parse(localStorage.getItem('generalSettings')));
    const [isEdit, setIsEdit] = useState(false);
    const [isAddressEdit, setIsAddressEdit] = useState(false);
    const [idProofUser, setIdProof] = useState(null);
    const [signatureUser, setSignature] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [authorization, setAuthorization] = useState(false);
    const [authorizationModel, setAuthorizationModel] = useState(false);
    const [temp_stateId, setTemp_stateId] = useState(null)
    const [temp_countryId, setTemp_countryId] = useState(null)
    const [zipcode, setZipcode] = useState(null);
    const [address1, setAddress1] = useState(null);
    const [city, setCity] = useState(null);
    const [firstName, setFirstName] = useState('');
    const [phone, setPhone] = useState('');
    const [lastName, setLastName] = useState('');
    const fileInput = useRef(null);

    const handleFileUpload = useFileUpload();
    const navigate = useNavigate();

    const [addUpdateAddress] = useMutation(MY_ADDRESS_CHANGE, {
        fetchPolicy: 'no-cache'
    })
    const [updateProfile] = useMutation(MY_PROFILE_CHANGE, {
        fetchPolicy: 'no-cache'
    })
    const scrollToBottom = () => {
        const targetElement = document.getElementById("scrollTarget");
        if (isEdit && targetElement) {
            targetElement.scrollIntoView({ behavior: "smooth" });
        }
    };
    useEffect(() => {
        scrollToBottom();
    }, [isEdit]);

    const _tabClick = (tabName) => {
        if (tabName === 'address') {
            setTabs(tabName);
            setIsAddressEdit(true);
        } else {
            setTabs(tabName); setUser(JSON.parse(localStorage.getItem('loggedUser')))
            setCustomer(JSON.parse(localStorage.getItem('customer')))
            setReceivingServiceType(JSON.parse(localStorage.getItem('customer'))?.receivingServiceType)
            setDeliveryServiceType(JSON.parse(localStorage.getItem('customer'))?.deliveryServiceType)
            setGeneralSettings(JSON.parse(localStorage.getItem('generalSettings')))
        }
    }

    const isProfileEditable = () => {
        setIsEdit(true);
    };
    const handleChangeCountry = (id) => {
        setTemp_countryId(id);
    };
    const handleChangeState = (id) => {
        setTemp_stateId(id);
    };

    const personalInfoUpdate = (e) => {
        e.preventDefault();
        const errorMessages = {};
        if (!firstName && !customer.firstName) {
            errorMessages.firstName = "First name is required.";
        } else if (lastName && !customer?.lastName) {
            errorMessages.lastName = "Last name is required.";
        } else if (phone && !customer?.phone) {
            errorMessages.phone = "Phone number is required.";
        } else if (!receivingServiceType && !customer?.receivingServiceType) {
            errorMessages.receivingServiceType = "Receiving service type is required.";
        } else if (!deliveryServiceType && !customer?.deliveryServiceType) {
            errorMessages.deliveryServiceType = "Delivery service type is required.";
        } else if (!idProofUser && !customer.idProof) {
            errorMessages.idProofUser = "ID proof is required.";
        } else if (!signatureUser && !customer.signature) {
            errorMessages.signatureUser = "Signature is required.";
        }

        if (Object.keys(errorMessages).length > 0) {
            Object.values(errorMessages).forEach((message) => {
                toast.error(message, {
                    autoClose: 2000
                });
            });
        } else {
            let input = {
                id: user?.id,
                firstName: firstName || customer.firstName,
                lastName: lastName || customer.lastName,
                email: user.email,
                phone: phone || customer.phone,
                zipcode: zipcode || customer.zipcode,
                receivingServiceType: receivingServiceType || customer.receivingServiceType,
                deliveryServiceType: deliveryServiceType || customer.deliveryServiceType,
                idProof: idProofUser || customer.idProof,
                signature: signatureUser || customer.signature,
            };

            updateProfile({ variables: { input: input } }).then(async (data, error) => {
                if (error) {
                    error.graphQLErrors.forEach((err) => {
                        toast.error(err.message, {
                            autoClose: 2000
                        });
                    });
                } else {
                    localStorage.setItem('token', data.data.updateProfile.token);
                    localStorage.setItem('loggedUser', JSON.stringify(data.data.updateProfile.user));
                    localStorage.setItem('customer', JSON.stringify(data.data.updateProfile.customer));

                    setZipcode(null)
                    setIsEdit(false);
                    setReceivingServiceType(null)
                    setDeliveryServiceType(null);
                    setIsAddressEdit(true);
                    toast.success("Profile updated.", {
                        autoClose: 2000
                    })
                    setTabs('address')
                }
            })
        }
    };

    const addressForm = (e, isSave) => {
        e.preventDefault();
        const errorMessages = {};
        if (!address1 && !customer?.address1) {
            errorMessages.address1 = "Address is required.";
        } else if (!temp_countryId && !customer?.countryId) {
            errorMessages.countryId = "Country is required.";
        } else if (!temp_stateId && !customer?.stateId) {
            errorMessages.stateId = "State is required.";
        } else if (!city && !customer?.city) {
            errorMessages.city = "City is required.";
        } else if (!zipcode && !customer?.zipcode) {
            errorMessages.zipcode = "Zipcode is required.";
        }

        if (Object.keys(errorMessages).length > 0) {
            Object.values(errorMessages).forEach((message) => {
                toast.error(message, {
                    autoClose: 2000
                });
            });
        } else {
            let input = {
                countryId: temp_countryId || customer?.countryId,
                stateId: temp_stateId || customer?.stateId,
                city: city || customer?.city,
                address1: address1 || customer?.address1,
                zipcode: zipcode || customer?.zipcode,
            };
            addUpdateAddress({ variables: { input: input } })
                .then((data) => {
                    localStorage.setItem('token', data.data.addUpdateAddress.token);
                    localStorage.setItem('loggedUser', JSON.stringify(data.data.addUpdateAddress.user));
                    localStorage.setItem('customer', JSON.stringify(data.data.addUpdateAddress.customer));

                    setUser(JSON.parse(localStorage.getItem('loggedUser')))
                    setCustomer(JSON.parse(localStorage.getItem('customer')))
                    setIsAddressEdit(false);
                    setAddress1(null);
                    setCity(null);

                    if (isSave === 'Save') {
                        toast.success("Address updated.", {
                            autoClose: 2000
                        });
                        setTabs('personalInfo')
                    } else {
                        navigate('/my-order');
                    }
                })
        }
    };

    const authorizationData = (e) => {
        if (authorization) {
            setAuthorization(true)
        }
        setAuthorization(!authorization)
    }

    const idProofPopup = (e) => {
        e.preventDefault();
        bootbox.dialog({
            title: "Attention!!",
            message: "This is an alert with a Please upload a government-issued ID card. The acceptable ID cards are international passport, national ID card, voter’s card, and driver’s license."
            , buttons: {
              fee: {
                label: 'OK',
                className: 'btn-primary',
                callback: function () {
                    fileInput.current.click();
                }
              }
            }
          })
    }

    const handleFirstNameChange = (e) => {
        const value = e.target.value;
        setFirstName(value);
        if (!/^[a-zA-Z\s]*$/.test(value)) {
            toast.error('First name should contain only characters');
        }
    };
    const tabData = [
        { id: 'personalInfo', label: 'Personal Info' },
        { id: 'address', label: 'Address' }
    ];
    const receivingServiceOptions = [
        { value: 'standard', label: 'Standard', modalTarget: '#exampleModalCenter' },
        { value: 'verified', label: 'Verified', modalTarget: '#exampleModalCenter' }
    ];

    const deliveryServiceOptions = [
        { value: 'DTD', label: 'D2D', modalTarget: '#DServiceType' },
        { value: 'pickup', label: 'PICKUP', modalTarget: '#DServiceType' }
    ];
    const receivingServiceMsgs = {
        standard: "When we receive your packages, we will NOT verify the content. Sometimes items sent by sellers do not match what you ordered, hence we recommend verified service.",
        verified: "When we receive your packages, we will open them to verify the content. We can then notify you immediately if there is any discrepancy. This will cost $1/package. We recommend this service."
    };

    const deliveryServiceMsgs = {
        DTD: 'This is our door-to-door service. Choose this service if you want your package delivered to you every time. This service attracts a fee. Please see our fee schedule in the "rates" section.',
        pickup: 'This is our basic service. Please choose this service if you want to pick up all your packages from our Lagos office. This service is free and is available to all customers.'
    };
    const elements = [
        {label: 'Id Card',type: 'idProof',imageSource: idProofUser || customer?.idProof || "../images/noImage.png",StateFileUpload: setIdProof},
        {label: 'Signature',type: 'signature',imageSource: signatureUser || customer?.signature || "../images/noImage.png",StateFileUpload: setSignature}
    ];
    return (
        <div className="container mrgnTop110">
            <div className='text-center mb-2'>
                <p className="headTitle">My Account Info</p>
            </div>
            <div className='col-md-10 flex mAuto bottomBrdr p-0'>
                {tabData.map(tab => (
                    <div
                        key={tab.id}
                        onClick={() => _tabClick(tab?.id)}
                        className={'orderTabBtn pointer mr-2'}
                        style={{ backgroundColor: tabs === tab?.id ? '#0B429A' : 'transparent', position: "relative" }}
                    >
                        <label className="mb-0" style={{ color: tabs === tab.id ? '#fff' : '#0B429A' }}>{tab?.label}</label>
                        {tabs === tab?.id && <div className="triangle-down-order" style={{ borderBlockColor: "#0B429A" }}></div>}
                    </div>
                ))}
            </div>
            <div className="col-md-12 mAuto">
                <div className="flex flex-wrap flex-column-reverse  mAuto col-md-10 p-0">
                    {isEdit && tabs === 'personalInfo' &&
                        <div className="col-md-12">
                            {isLoading && <Loader />}
                            <div className="col-md-12 pb-3 myAccInfoMain mt-30 extra" id='scrollTarget' >
                                <div className="mb-3 mt-3">
                                    <label className="headText mt-2">My Personal Info</label>
                                </div>
                                <form id='personalInfoUpdate' onSubmit={(e) => personalInfoUpdate(e)}>
                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className=" flex">
                                            <div className="col-md-6 mb-3">
                                                <label className="mb-0 labelFs14">First Name<span className="text-danger">*</span></label>
                                                <input placeholder="First Name"  type="text" className="input p-0 col-12 inputFs14" defaultValue={customer?.firstName} name='firstName' onChange={(e) => {handleFirstNameChange(e)}} required />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="mb-0 labelFs14">Last Name<span className="text-danger">*</span></label>
                                                <input placeholder="Last Name  " className="input p-0 col-12 inputFs14" defaultValue={customer?.lastName} name='lastName' onChange={(e) => setLastName(e.target.value)} required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className=" pl-0 pr-0">
                                            <div className="col-md-12 mb-3">
                                                <label className="mb-0 labelFs14">Email</label>
                                                <p className="input p-0 col-12 inputFs14">{customer?.email}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className="pl-0 pr-0">
                                            <div className="col-md-12 mb-3">
                                                <label className="mb-0 labelFs14">Mobile<span className="text-danger">*</span></label>
                                                <input type='number' placeholder="Mobile" className="input p-0 col-12 inputFs14" defaultValue={customer?.phone} name='phone' onChange={(e) => setPhone(e.target.value)} required />
                                            </div>
                                        </div>
                                    </div>
                                    <ServiceTypeSection
                                        label="Receiving Service Type"
                                        options={receivingServiceOptions}
                                        selectedOption={receivingServiceType}
                                        setOption={setReceivingServiceType}
                                        msgs={receivingServiceMsgs}
                                    />
                                    <ServiceTypeSection
                                        label="Delivery Service Type"
                                        options={deliveryServiceOptions}
                                        selectedOption={deliveryServiceType}
                                        setOption={setDeliveryServiceType}
                                        msgs={deliveryServiceMsgs}
                                    />
                                    
                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className="flex">
                                            {elements.map((element, index) => (
                                                <div key={index} className="col-md-6 mb-3 mt-2 flex flex-column">
                                                    <div>
                                                        <label className="mb-0 labelFs14">{element?.label}<span className="text-danger">*</span></label>
                                                        {element?.label === 'Id Card' && (
                                                            <>
                                                                <span className="btn btn-default btn-file btn-sm btn-danger ffPoppins ml-3 mb-2" onClick={(e) => idProofPopup(e)}>
                                                                    <i className="fa fa-upload"></i> Choose a File
                                                                </span>
                                                                <input
                                                                    type="file"
                                                                    onChange={(e) => handleFileUpload(e, 'idproof', setIdProof, setIsLoading)}
                                                                    ref={fileInput} hidden />
                                                            </>
                                                        )}
                                                        {element?.label === 'Signature' && ( // Corrected conditional rendering syntax
                                                            <span className="btn btn-default btn-file btn-sm btn-danger ffPoppins ml-3 mb-2">
                                                                <i className="fa fa-upload"></i> Choose a File
                                                                <input type="file" onChange={(e) => handleFileUpload(e, 'Signature', setSignature, setIsLoading)} />
                                                            </span>
                                                        )}
                                                    </div>
                                                    <img src={element?.imageSource} alt="Img" width='70px' className='mr-2 ml-5 z-depth-1-half img-responsive img-thumbnail' />
                                                </div>
                                            ))}
                                        </div>
                                    </div>

                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className="pl-0 pr-0">
                                            <div className="col-md-12 mb-2">
                                                <div className='flex mt-2'>
                                                    <div className='flex pointer' >
                                                        <div className="mr-2 pointer">
                                                            <input type="checkbox" data-toggle="modal" data-target="#authrization" onChange={(e) => authorizationData(e)} />
                                                        </div>
                                                        <label className="mb-0 inputFs14 mr-4 pointer">online orders receipt authorization</label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className="col-md-12 mt-2 mb-2">
                                            <button type="submit"  className="sendBtn pointer">Save & Continue</button> &emsp;
                                            <button type="button" className="sendBtn bg-resetRed pointer" onClick={() => { setIsEdit(false) }}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                    {tabs === 'personalInfo' &&
                        <div className="col-md-12">
                            <div className="col-md-12 pb-3 myAccInfoMain mt-30 extra">
                                <div className="col-md-12 flex p-0 mb-3 mt-3">
                                    <label className="col-md-10 p-0 headText mt-2">My Details</label>
                                    <div className="col-md-2 p-0 mt-2" onClick={() => { isProfileEditable(); }}>
                                        <img src="../images/editGray.png" alt="Img" width='17px' className='mr-2 pointer' />
                                        <label className="mb-0 labelFs14 mr-2 pointer">Edit</label>
                                    </div>
                                </div>
                                <div className="boxShadowAdd boxInnerMargin">
                                    <div className="pb-1 boxShadowRate pl-3 pr-3 ">
                                        <div className="">
                                            <label className="mb-0 labelFs14">First Name</label>
                                            <p className=" inputFs14">{customer?.firstName}</p>
                                        </div>
                                        <div className="">
                                            <label className="mb-0 labelFs14">Last Name</label>
                                            <p className=" inputFs14">{customer?.lastName}</p>
                                        </div>
                                        <div className="">
                                            <label className="mb-0 labelFs14">Email</label>
                                            <p className=" inputFs14">{customer?.email}</p>
                                        </div>
                                        <div className="">
                                            <label className="mb-0 labelFs14">Service Type</label>
                                            <p className=" inputFs14">{receivingServiceType}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                    {authorizationModel &&
                        <div className="modal fade" id="authrization" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
                            <div className="modal-dialog modal-dialog-centered" role="document">
                                <div className="modal-content">
                                    <div className="modal-header m-header">
                                        <img className="signup-logo" alt="Logo" src="../images/smsLogo.png" height="50" width="100" />
                                        <h5 style={{ marginLeft: 15, fontWeight: 'bold', fontSize: 'x-large' }} className="modal-title" id="exampleModalLongTitle">{"Online orders receipt authorization"}</h5>
                                    </div>
                                    <div style={{ textAlign: 'justify' }} className="modal-body">
                                        {genralSettings?.orderReceiptAuth ? parse(genralSettings?.orderReceiptAuth) : " "}
                                    </div>
                                    <div className="modal-footer m-footer">
                                        <button type="button" className="btn" style={{ color: '#6c757d' }} data-dismiss="modal" onClick={() => setAuthorizationModel(false)}>OK</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
                </div>
            </div >
            <div className="col-md-12 mAuto">
                <div className="flex flex-wrap flex-column-reverse mAuto col-md-10 p-0">
                    {tabs === 'address' && isAddressEdit && 
                        <div className="col-md-12">
                            <div className="col-md-12 pb-3 myAccInfoMain mt-30 extra">
                                <div className="mt-3 mb-3">
                                    <label className="headText mt-2">My Address</label>
                                </div>
                                <div className="boxShadowAdd boxInnerMargin">
                                    <div className="pb-1 boxShadowRate pl-3 pr-3 ">
                                        <div className="">
                                            <label className="mb-0 labelFs14">My Name</label>
                                            <p className=" inputFs14">{`${customer?.firstName} ${customer?.lastName}`}</p>
                                        </div>
                                        <div className="">
                                            {
                                                customer?.address1 &&
                                                <>
                                                    <label className="mb-0 labelFs14">My Home Address</label>
                                                    <p className=" inputFs14">{customer?.address1}</p>
                                                </>
                                            }
                                        </div>
                                        <div className="">
                                            <label className="mb-0 labelFs14">My Phone Number</label>
                                            <p className=" inputFs14">{customer?.phone}</p>
                                        </div>
                                    </div>
                                </div>

                                <form id='addressChangeForm' onSubmit={(e) => addressForm(e, 'gotoPortal')}>
                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className=" pl-0 pr-0">
                                            <div className="col-md-12 mb-3">
                                                <label className="mb-0 labelFs14">Address Line 1<span className="text-danger">*</span></label>
                                                <input placeholder="Address" className="input p-0 col-12 inputFs14" defaultValue={customer?.address1} name='address1' onChange={(e) => setAddress1(e.target.value)} required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className=" flex">
                                            <div className="col-md-6 mb-3">
                                                <label className="mb-0 labelFs14">Country<span className="text-danger">*</span></label>
                                                <CountryList countryId={temp_countryId || customer?.countryId} name="countryId" getCRUDCountryId={(id) => handleChangeCountry(id)} />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="mb-0 labelFs14">State<span className="text-danger">*</span></label>
                                                <StatesList countryId={temp_countryId || customer?.countryId} stateId={temp_stateId || customer?.stateId} name="stateId" getCRUDStateId={(id) => handleChangeState(id)} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className=" flex">
                                            <div className="col-md-6 mb-2">
                                                <label className="mb-0 labelFs14">City<span className="text-danger">*</span></label>
                                                <input placeholder="City" className="input p-0 col-12 inputFs14" defaultValue={customer?.city} name='city' onChange={(e) => setCity(e.target.value)} required />
                                            </div>
                                            <div className="col-md-6">
                                                <label className="mb-0 labelFs14">ZIP / POST Code<span className="text-danger">*</span></label>
                                                <input type='number' placeholder="Post Code" className="input p-0 col-12 inputFs14" defaultValue={customer?.zipcode} name='zipcode' onChange={(e) => setZipcode(e.target.value)} required />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                                        <div className="col-md-12  mt-2 mb-2">
                                            <button
                                                type="submit"
                                                className="sendBtn pointer">Go to My portal</button>
                                            <button
                                                type="Save"
                                                className="sendBtn pointer ml-2" onClick={(e) => addressForm(e, 'Save')} >Save</button>
                                            &emsp;
                                            <button
                                                type="button"
                                                className="sendBtn bg-resetRed pointer" onClick={() => { setTabs('personalInfo') }}>Cancel</button>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    }
                </div>
            </div >
        </div >
    )
}
