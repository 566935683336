import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { DELETE_TICKET } from '../../../graphql/mutation/TicketMutation';
import { GET_ALL_TICKETS } from '../../../graphql/query/TicketQuery';
import Pagination from '../../components/Common/Pagination';
import { useNavigate } from 'react-router-dom';
import confirmDeletion from '../../components/Common/BootBox';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import DatePicker from "react-datepicker";
import Select from 'react-select';
import moment from 'moment'
import { Link } from "react-router-dom";

const statusType = [
    { value: 'Open', label: 'Open' },
    { value: 'Closed', label: 'Closed' },
];
export const Ticket = () => {
    const navigate = useNavigate();
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'));
    const [ticket, setTicket] = useState({
        fromDate: null,
        filterFrom: null,
        filterTo: null,
        toDate: null,
        limit: genSettings?.paginationLimit || 10,
        pageNo: 0,
        filter: "",
        filterText: '',
        sortKey: 'createdAt',
        sortType: -1,
    });
    const { data ,refetch} = useQuery(GET_ALL_TICKETS, {
        variables: { "page": ticket?.pageNo + 1, "limit": ticket?.limit, "filter": ticket?.filter, "sort": { key: ticket?.sortKey, type: ticket?.sortType }, "filterStatus": ticket?.filterStatus, from: ticket?.filterFrom, to: ticket?.filterTo },
        fetchPolicy: 'cache-and-network'
    })
    const [deleteTicket] = useMutation(DELETE_TICKET);


    useEffect(() => {
        $('.react-datepicker-wrapper').addClass('d-flex')
    }, []);

    const btnFilterTicketStatusWise = (e, Status, val) => {
        e.preventDefault();
        $('.active').removeClass('active');
        $(`.btn-outline-${Status}`).addClass('active')
        handleStatusType({ label: Status, value: val })
    }
    const deleteTicketDetails = (e, id)=>  {
        e.preventDefault();
        const deleteTicketData = () => {
            deleteTicket({ variables: { ticketId: id } }).then(async ({ data }) => {
                if (data) {
                  refetch();
                  toast.success("Ticket deleted successfully!", {
                    autoClose: 2000
                  })
                }
              }).catch((error) => {
                toast.error(error, {
                  autoClose: 2000
                })
              })
        }
        confirmDeletion("Delete TICKET",  "Are you sure want to delete this record ?" ,deleteTicketData);
      }

    const handleStatusType = (statusType) => {
        setTicket(preData => ({ ...preData, status: statusType, filterStatus: statusType?.value }))
    }
    const handlePageClick = (data) => {
        setTicket(preData => ({ ...preData, pageNo: data?.selected }))

    };

    const fromDateChange = (date) => {
        setTicket(preData => ({ ...preData, fromDate: date, filterFrom: moment(date).format('YYYY/MM/DD') }))
    }
    const toDateChange = (date) => {
        setTicket(preData => ({ ...preData, toDate: date, filterTo: moment(date).format('YYYY/MM/DD') }))
    }
    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchTickets(e)
        }
    }
    const searchTickets = (e) => {
        e.preventDefault();
        setTicket(preData => ({ ...preData, pageNo: 0, filter: filterText }))
    }
    const onResetSearch = (e) => {
        e.preventDefault();
        setTicket(preData => ({ ...preData, pageNo: 0, filter: "", filterText: "", fromDate: null, toDate: null, filterFrom: null, filterTo: null, filterStatus: null, status: null }))
    }
    const { pageNo, limit, filter, sortKey, sortType, filterStatus, status, fromDate, toDate, filterFrom, filterTo, filterText } = ticket;
    const sort = { key: sortKey, type: sortType };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="mb-20">
                    <p className="mainTitle m-0 mt_-15">Tickets List</p>
                    <span className="borderBottomOfTitle"></span>
                </div>
            </div>

            <div className="row  mb-3">
                <button className="btn ffPoppins btn-outline-dark bg-e3e3e3 mr-2"
                    onClick={(e) => { btnFilterTicketStatusWise(e, 'All', '') }}
                >All</button>
                <button className="btn btn-outline-Received mr-2"
                    onClick={(e) => { btnFilterTicketStatusWise(e, 'Open', 'Open') }}
                >
                    <span className="statusLabel">Open</span>
                </button>

                <button className="btn btn-outline-Pending mr-2"
                    onClick={(e) => { btnFilterTicketStatusWise(e, 'Closed', 'Closed') }}
                >
                    <span className="statusLabel">Closed</span>
                </button>

            </div>

            <div className="row mb-4">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Tickets Filters</span>
                        </div>
                        <div className="ibox-content">
                            <form >
                                <div className="form-row">
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Form</label>
                                        <DatePicker
                                            className="form-control ffPoppins d-flex"
                                            placeholderText="mm/dd/yyyy"
                                            selected={fromDate}
                                            maxDate={new Date()}
                                            onChange={(date) => fromDateChange(date)}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">To</label>
                                        <DatePicker
                                            className="form-control ffPoppins d-flex"
                                            placeholderText="mm/dd/yyyy"
                                            minDate={fromDate}
                                            maxDate={new Date()}
                                            selected={toDate}
                                            onChange={(date) => toDateChange(date)}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Status</label>
                                        <Select className="ffPoppins" options={statusType} value={status} onChange={(data) => handleStatusType(data)} />
                                    </div>
                                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Search</label>
                                        <input type="text" className="form-control" value={filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setTicket(preData => ({ ...preData, filterText: e.target.value }))} />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div className="btn-Groups mt-30">
                                            <button className="btn btn-primary btn-text border-0 bg-searchBlue" onClick={(e) => searchTickets(e)}>Search</button>
                                            <button className="btn btn-danger btn-text bg-resetRed" onClick={(e) => onResetSearch(e)} >Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="col-md-12 p-0">
                                <div className="ibox">
                                    <div className="ibox-title">
                                        <span>Tickets Details</span>
                                        <div className="pull-right">
                                            <Pagination
                                                pageCount={data?.getAllTickets && Math.ceil(data?.getAllTickets?.count / ticket?.limit)}
                                                pageNo={ticket?.pageNo}
                                                handlePageClick={(data) => handlePageClick(data)} />
                                        </div>
                                    </div>
                                    <div className="ibox-content p-0">
                                        <div className="table-responsive">
                                            <table className="table themeTable table-nowrap mb-0">
                                                <thead>
                                                    <tr>
                                                        <th className="text-center">No.</th>
                                                        <th>Date</th>
                                                        <th>Ticket No</th>
                                                        <th>First Name</th>
                                                        <th>Last Name</th>
                                                        <th>PMB No.</th>
                                                        <th>City</th>
                                                        <th>Mobile</th>
                                                        <th>Email</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {
                                                        data?.getAllTickets && data?.getAllTickets?.data && data?.getAllTickets?.data.length > 0 ?
                                                            data?.getAllTickets?.data.map((tkt, i) => {
                                                                return (
                                                                    <tr key={tkt.id} className={`${tkt.conversationId.length > 0 ? 'unreadTicket' : ''}`}
                                                                        onDoubleClick={() => {
                                                                            navigate('/edit-ticket', {
                                                                                state: { ticket: tkt, conversation: { id: tkt?.conversationId } }
                                                                            })
                                                                        }}
                                                                        style={{ cursor: "pointer" }}
                                                                    >
                                                                        <td className=" text-center">{i + 1}</td>
                                                                        <td>{moment(tkt.createdAt).format('MM/DD/YYYY hh:mm a')}</td>
                                                                        <td>{tkt.ticketNo}</td>
                                                                        <td>{tkt?.customerId?.firstName || ''}</td>
                                                                        <td>{tkt?.customerId?.lastName || ''}</td>
                                                                        <td>{tkt?.customerId?.pmbNo || ''}</td>
                                                                        <td>{tkt?.customerId?.city || ''}</td>
                                                                        <td>{tkt?.customerId?.phone || ''}</td>
                                                                        <td>{tkt?.customerId?.email || ''}</td>
                                                                        <td>
                                                                            <span>
                                                                                {tkt.isActive ?
                                                                                    <span className="statusLabel text-white pvph bg-lightGreen openStatus">Open</span>
                                                                                    :
                                                                                    <span className="statusLabel text-white pvph openStatus boxBackColorRed">Closed</span>
                                                                                }
                                                                                <span className="statusLabel text-white pvph bg-Gray msgCount">{tkt?.conversationId?.length}</span>
                                                                            </span>
                                                                        </td>
                                                                        <td>
                                                                            <div className="row">
                                                                                <div onClick={() => {  navigate('/edit-ticket', {
                                                                                state: { ticket: tkt, conversation: { id: tkt?.conversationId } }
                                                                            })}} >
                                                                                    <span className="btn BoxImg bg-skyBlue rounded mr-2">
                                                                                        <img src="../images/edit.png" alt="Img" className="width15" />
                                                                                    </span>
                                                                                </div>
                                                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteTicketDetails(e, tkt?.id)}>
                                                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                            :
                                                            <tr>
                                                                <td style={{ textAlign: 'center' }} colSpan={7} >No Records Found!</td>
                                                            </tr>
                                                    }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div>
    )
}
