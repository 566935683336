import React, { useState } from 'react';
import { useQuery } from '@apollo/client';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import { GET_CHARGES_REPORT, GET_CUSTOMER_REPORT, GET_PACKAGED_BOX_REPORT, GET_PACKING_LIST_REPORT, GET_TOTAL_RECEIVED_PACKAGES_REPORT } from '../../../graphql/query/ReportQuery';
import { TotalPackageReceived } from './TotalPackageReceived';
import { PackageBoxReport } from './PackageBoxReport';
import { CustomerReport } from './CustomerReport';
import { PackigListReport } from './PackigListReport';
import { ChargesReport } from './ChargesReport';
import Loader from '../../components/Loader/Loader';
import moment from 'moment';

const PreviewReport = ({ selectedReportType, setSkipReport, skipReport, selectedStatus, toDate, fromDate, type, createPDF, customerName, selectedShipment }) => {
    const [reportData, setReportData] = useState(null);

    const passVariable = { customerId: customerName?.value, from: fromDate, to: toDate, status: selectedStatus?.value }
    if (selectedReportType?.value === "CUSTOMER REPORT")
        delete passVariable.status
    else if (selectedReportType?.value === "PACKING LIST")
        passVariable['shipmentId'] = selectedShipment?.value
    else if (selectedReportType?.value === "CHARGES REPORT")
        passVariable['type'] = type.toLowerCase()
    passVariable['ship'] = selectedShipment?.value;

    const { loading } = useQuery(selectedReportType?.value === "TOTAL RECEIVED PACKAGES" ?
        GET_TOTAL_RECEIVED_PACKAGES_REPORT : selectedReportType?.value === "PACKED BOXES REPORT" ?
            GET_PACKAGED_BOX_REPORT : selectedReportType?.value === "CUSTOMER REPORT" ? GET_CUSTOMER_REPORT :
                selectedReportType?.value === "PACKING LIST" ? GET_PACKING_LIST_REPORT :
                    GET_CHARGES_REPORT, {
        skip: skipReport, variables: passVariable,
        fetchPolicy: 'no-cache',
        onCompleted: (getReport => {
            setReportData(getReport);
            setSkipReport(true);
        }),
    });

    const getReportName = () => {
      
        switch (true) {
            case !!reportData?.getTotalReceivedPakagesReport:
                return "TOTAL RECEIVED PACKAGES REPORT";
            case !!reportData?.getPackedBoxReport:
                return "PACKED BOXES REPORT";
            case !!reportData?.getCustomerReport:
                return "CUSTOMER REPORT";
            case !!reportData?.getPackingListReport:
                return "PACKING LIST REPORT";
            case !!reportData?.getChargesReport:
                return "CHARGES REPORT";

            default:
               return '';
        }       
    }


    return (
        <>
            {loading && <Loader />}
            {reportData && (
                <>
                    <div className="row">
                        <div className="col-lg-12">
                            <h1 className="page-header">
                                {reportData?.getTotalReceivedPakagesReport !== undefined ?
                                    type === "Explained" ? "TOTAL RECEIVED PACKAGES (EXPLAINED)" : "TOTAL RECEIVED PACKAGES (SUMMARIZED)" :
                                    reportData?.getPackedBoxReport !== undefined ? "PACKED BOXES REPORT" :
                                        reportData?.getCustomerReport !== undefined ? "CUSTOMER REPORT" :
                                            reportData?.getPackingListReport !== undefined ? "PACKING LIST REPORT" :
                                                reportData?.getChargesReport !== undefined ? type === "Explained" ? "REVENUE REPORT (EXPLAINED)" : "REVENUE REPORT (SUMMARIZED)" : ""
                                }</h1>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-12">
                            <div className="panel panel-green">
                                <div className="panel-heading">REPORT DETAILS</div>
                                <div className="panel-body">
                                    <div className="col-md-12 clearfix cursor-pointer">
                                        <div className="pull-right p-b-20 cursor-pointer">
                                            <span onClick={() => {
                                                createPDF(getReportName());
                                            }} className="btn btn-danger waves-effect mr-1 cursor-pointer" style={{ cursor: "pointer" }}>
                                                <i className="fa fa-file-pdf-o" aria-hidden="true"></i> PDF
                                            </span>
                                            <ReactHTMLTableToExcel
                                                id="test-table-xls-button"
                                                className="btn btn-success waves-effect"
                                                table="dataTables-example"
                                                filename={`${getReportName().split(' ').join('_')}_${moment().format("YYYY/MM/DD HH:mm:ss")}`}
                                                sheet="tablexls"
                                                buttonText="EXCEL" />
                                        </div>
                                        {reportData?.getTotalReceivedPakagesReport !== undefined ? <TotalPackageReceived type={type} reportData={reportData} /> : null}
                                        {reportData?.getPackedBoxReport !== undefined ? <PackageBoxReport reportData={reportData} /> : null}
                                        {reportData?.getCustomerReport !== undefined ? <CustomerReport reportData={reportData} /> : null}
                                        {reportData?.getPackingListReport !== undefined ? <PackigListReport reportData={reportData} /> : null}
                                        {reportData?.getChargesReport !== undefined ? <ChargesReport type={type} custName={customerName?.label} reportData={reportData} /> : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>
            )}
        </>
    );
}

export default PreviewReport;
