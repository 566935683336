import moment from 'moment'
import React from 'react'

export const NotificationComp = ({ NotificationData }) => {
    return (
        <div>
            {
                NotificationData && NotificationData.length > 0 ?
                    NotificationData.map((notification, i) => {
                        return (
                            <div key={notification?.id} className="boxShadowDiv pl-3 pr-3 pb-1 pt-1 mb-4" >
                                <div className="col-md-12 row pt-2 pb-2 p-0">
                                    <div className="col-md-10 col-sm-12 mt-1">
                                        <label className={!notification?.isRead ? "mb-0 labelFs14 themeBlue" : "mb-0 labelFs14 "}>{notification?.tracking_no ? `Order Tracking No. ${notification?.tracking_no}` : `You got new message.`}</label>
                                        <p className="mb-0 labelFs14">{notification?.msg}</p>
                                    </div>
                                    <div className="col-md-2 col-sm-12 mt-1">
                                        <label className={!notification?.isRead ? "mb-0 inputFs12 float-right" : "mb-0 labelFs12 float-right"}>{moment(notification?.createdAt).format('MMM Do hh:mm a')}</label>
                                    </div>
                                </div>
                            </div>
                        )
                    }) :
                    <div className="boxShadowRate boxInnerMargin">
                      <div className="text-center">
                        <img src="./images/notFound.png" alt="not found"></img>
                      </div>
                    </div>
        }
        </div>
    )
}
