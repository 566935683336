import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { GET_HOW_IT_WORKS } from '../../../../graphql/query/HomeWebQuery';
import Loader from '../../../components/Loader/Loader';
import { DELETE_HOW_IT_WORKS, INSERT_UPDATE_HOW_IT_WORKS } from '../../../../graphql/mutation/HowItsWorksMutation';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import confirmDeletion from '../../../components/Common/BootBox';

export const HowItsWork = () => {
    const [id, setId] = useState(null);
    const [stepNo, setStepNo] = useState("");
    const [label, setLabel] = useState("");
    const [details, setDetails] = useState("");
    const [works, setWorks] = useState([]);
    const [skipQuery, setSkipQuery] = useState(false);

    const { loading, error, data } = useQuery(GET_HOW_IT_WORKS, {
        skip: skipQuery,
        fetchPolicy: 'no-cache',
        onCompleted: ({ getHowItWorks }) => {
            setWorks(getHowItWorks?.data);
            setSkipQuery(true);
        },
    });
    const [deleteWork] = useMutation(DELETE_HOW_IT_WORKS);
    const [insertUpdateWork, { loading: insertUpdateLoading }] = useMutation(INSERT_UPDATE_HOW_IT_WORKS);

    const addNewWork = e => {
        e.preventDefault();
        setId(""); setStepNo(""); setLabel(""); setDetails("");
        $('#AddNewWorkModal').modal('show');
    }
    const editWork = (e, work) => {
        e.preventDefault();
        const { stepNo, id, label, details } = work;
        setId(id); setLabel(label); setDetails(details); setStepNo(stepNo);
        $('#EditSelectedWorkModal').modal('show');
    }
    const deleteWorkDetails = (e, workId) => {
        e.preventDefault();
        const DeleteWork = () => {
            deleteWork({ variables: { id: workId } }).then(({ data }) => {
                if (data) {
                    setSkipQuery(false);
                    toast.success("Bin deleted successfully!", {
                        autoClose: 2000
                    });
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            });
        }
        confirmDeletion('Delete Work' ,'Are you sure want to delete this record ?',DeleteWork)
    }

    const onSubmit = (event, flag) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            let input;
            if (flag)
                input = { stepNo: +stepNo, label, details }
            else
                input = { id, stepNo, label, details };

            insertUpdateWork({ variables: { input } }).then(({ data }) => {
                if (data && data.insetUpdateHowItWorks) {
                    form.classList.remove('was-validated');
                    setStepNo(""); setLabel(""); setDetails(""); setId(null); setSkipQuery(false);
                    if (flag) {
                        toast.success("Work added successfully!", {
                            autoClose: 2000
                        })
                        $('#AddNewWorkModal').modal('hide');
                    } else {
                        toast.success("Work Update successfully!", {
                            autoClose: 2000
                        })
                        $('#EditSelectedWorkModal').modal('hide');
                    }
                    form.reset();
                } else
                    toast.error("error, try again later! ", {
                        autoClose: 2000
                    })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 p-0">
                    <div className="mb-20">
                        <p className="mainTitle m-0 mt_-15">How It Work Details</p>
                        <span className="borderBottomOfTitle"></span>
                    </div>
                </div>
                <div className="col-md-4 p-0">
                    <div className="float-right">
                        <button className="btn btn-success btn-text bg-lightGreen" onClick={addNewWork}> <i className="fa fa-plus"></i> Add New Work</button>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Work Details</span>
                        </div>
                        <div className="ibox-content p-0">
                            {loading && <Loader />}
                            <div className="table-responsive">
                                <table className="table themeTable table-nowrap mb-0 tbl-sort">
                                    <thead>
                                        <tr>
                                            <th className='text-center'>Step No</th>
                                            <th>Label</th>
                                            <th>Details</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            works && works.length ?
                                                works.map((work, i) => {
                                                    return (
                                                        <tr key={work?.id} style={{ cursor: "pointer" }} onDoubleClick={e => editWork(e, work)}>
                                                            <td className='text-center'>{work.stepNo}</td>
                                                            <td>{work.label}</td>
                                                            <td>{work.details}</td>
                                                            <td>
                                                                <div className="d-flex flex-row">
                                                                    <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={e => editWork(e, work)}>
                                                                        <img src="../images/edit.png" alt="Img" className="width15" />
                                                                    </span>
                                                                    <span className="btn BoxImg boxBackColorRed rounded mr-2"
                                                                        onClick={e => deleteWorkDetails(e, work.id)}>
                                                                        <img src="../images/bin.png" alt="Img" className="width15" />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                }) :
                                                <tr>
                                                    <td style={{ textAlign: 'center' }} colSpan={4}>No Records Found!</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>


                    </div>
                </div>
            </div>

            <div className="modal show" id="AddNewWorkModal" data-backdrop="static" tabIndex="-1" role="dialog">
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{ paddingTop: "0px" }}>
                            <div>
                                <p className="mainTitle m-0 mt_-15">Add New Work</p>
                                <span className="borderBottomOfTitle "></span>
                            </div>
                            <div>
                                <button data-dismiss="modal"
                                    type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 p-0">

                                        <div className="ibox">
                                            {insertUpdateLoading && <Loader />}
                                            <div className="ibox-title">
                                                <span>Add Work</span>
                                            </div>
                                            <div className="ibox-content">
                                                <form className="needs-validation" noValidate="noValidate" onSubmit={event => onSubmit(event, true)}>
                                                    <div className="form-row">
                                                        <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Step No<span className="text-danger">*</span></label>
                                                            <input type="number" value={stepNo} className="form-control capital ffPoppins" onChange={e => setStepNo(e.target.value)} required />
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Label<span className="text-danger">*</span></label>
                                                            <input type="text" value={label} className="form-control ffPoppins" onChange={e => setLabel(e.target.value)} required />
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Details<span className="text-danger">*</span></label>
                                                            <textarea name="remark" value={details} className="form-control ffPoppins" rows="2" onChange={e => setDetails(e.target.value)} required />
                                                        </div>
                                                        <div className="form-group col-xs-12">
                                                            <div className="mt-3">
                                                                <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue">Save</button>
                                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal show" id="EditSelectedWorkModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                <div className="modal-dialog modal-xl modal-dialog-centered" role="document">
                    <div className="modal-content">
                        <div className="modal-header" style={{ paddingTop: "0px" }}>
                            <div>
                                <p className="mainTitle m-0 mt_-15">Edit Work</p>
                                <span className="borderBottomOfTitle "></span>
                            </div>
                            <div>
                                <button data-dismiss="modal"
                                    type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <div className="row">
                                    <div className="col-md-12 p-0">
                                        <div className="ibox">
                                            {insertUpdateLoading && <Loader />}
                                            <div className="ibox-title">
                                                <span>Edit Work</span>
                                            </div>
                                            <div className="ibox-content">
                                                <form className="needs-validation" noValidate="noValidate" onSubmit={event => onSubmit(event, false)}>
                                                    <div className="form-row">
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <label className="frm-label">Step No</label>
                                                            <input type="text" className="form-control ffPoppins" value={stepNo} readOnly />
                                                        </div>
                                                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                            <label className="frm-label">Label<span className="text-danger">*</span></label>
                                                            <input type="text" value={label} className="form-control ffPoppins" onChange={e => setLabel(e.target.value)} required />
                                                        </div>
                                                        <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                            <label className="frm-label">Details<span className="text-danger">*</span></label>
                                                            <textarea name="remark" value={details} className="form-control ffPoppins" rows="2" onChange={e => setDetails(e.target.value)} required />
                                                        </div>
                                                        <div className="form-group col-xs-12">
                                                            <div className="mt-3">
                                                                <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue">Save</button>
                                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal">Cancel</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
