import { gql } from "@apollo/client";

export const INSERT_UPDATE_HOW_IT_WORKS = gql`
  mutation insetUpdateHowItWorks($input: HowItWorksInput!){
    insetUpdateHowItWorks(input: $input){
        id
        stepNo
        label
        details
    }
  }
`;

export const DELETE_HOW_IT_WORKS = gql`
  mutation deleteHowItWorks($id: ID!){
    deleteHowItWorks(id: $id) {
        id
        stepNo
        label
        details
    }
  }
`;