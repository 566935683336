import React, { useEffect, useState } from 'react'
import CustomerList from '../../components/DropdownSelect/CustomerSelect'
import { useMutation, useQuery } from '@apollo/client';
import { getS3SignUrl } from '../../../graphql/mutation/CustomerMutation';
import { toast } from 'react-toastify';
import axios from 'axios';
import OnlineStoreList from '../../components/DropdownSelect/OnlineStoreSelect';
import { BASE_URL } from '../../../config.js';
import RatesList from '../../components/DropdownSelect/RatesSelect'
import FreeBins1 from '../../components/DropdownSelect/FreeBinsSelect';
import { GET_ASSIGN_BIN_ORDER } from '../../../graphql/query/binsQuery';

const OrderDetails = (props) => {
    const [state, setState] = useState({
        customerId: null,
        onlineStoreId: null,
        orderRateIds: null,
        receiptImg: "../images/noImage.png",
        binId: null,
        searchCustomer: "",
        searchStore: "",
        searchRate: "",
        searchBins: "",
        isLoading: false,
    });

    const [searchCustomer, setSearchCustomer] = useState("")

    const [getSignUrl] = useMutation(getS3SignUrl)

    const { data: assignBinsData } = useQuery(GET_ASSIGN_BIN_ORDER ,{
        variables : {customerId: props?.selectedOrderData?.customerId || state?.customerId },
        fetchPolicy :'cache-and-network',
    })
    const AssignBinsData = assignBinsData && assignBinsData?.getCustomerAssignBinOrderDeatils;

    useEffect(() => {
        const { curdType, selectedOrderData } = props;
        if (curdType === "Edit" && selectedOrderData) {
            setState(preData => ({
                ...preData,
                customerId: selectedOrderData?.customerId,
                onlineStoreId: selectedOrderData?.onlineStoreId,
                orderRateIds: selectedOrderData?.orderRateIds,
                receiptImg: selectedOrderData?.invoiceOrReceipt,
                binId: selectedOrderData?.binId
            }));
        }
    }, []);

    const handelChangeCustomer = (data) => {
        setState(preData => ({
            ...preData,
            customerId: data?.id
        }));
        props.getCustomerInfo(data);
    }

    const handelChangeOnlineStore = (id) => {
        setState(preData => ({
            ...preData,
            onlineStoreId: id?.value
        }));
        props.getOnlineStoreId(id?.value);
    }

    const TextChangeHandler = (e) => {
        props.handleText(e);
    }

    const handelChangeRate = (id) => {
        setState(preData => ({
            ...preData,
            orderRateIds: id
        }));
        props.getOrderRateId(id);
    }

    const handelChangeBins = (value) => {
        setState(preData => ({
            ...preData,
            binId: value.id
        }));
        props.getBinsInfo(value);
    }

    const InvoiceOrReceipt = async (event) => {
        const { target: { validity, files: [file], } } = event;
        if (validity.valid && file) {
            setState(preData => ({
                ...preData,
                isLoading: true
            }));
            try {
                const fileExtension = file?.type.split("/")[1];
                const key = `invoiceorreceipt${+new Date()}.${fileExtension}`;
                const { data } = await getSignUrl({ variables: { type: key } });
                if (data?.getPreSignedUrl) {
                    const response = await axios.put(data?.getPreSignedUrl, file, { headers: { "Content-Type": file?.type } });
                    if (response?.status === 200) {
                        setState(state => ({
                            ...state,
                            receiptImg: `${BASE_URL}/${key}`,
                            isLoading: false
                        }));
                        props.OrderReceipt(`${BASE_URL}/${key}`);
                    } else {
                        setState(state => ({
                            ...state,
                            isLoading: false
                        }));
                    }
                } else {
                    setState(state => ({
                        ...state,
                        isLoading: false
                    }));
                }
            } catch (error) {
                setState(state => ({
                    ...state,
                    isLoading: false
                }));
                toast.error(error.message, { autoClose: 2000 });
            }
        }
    }

    const deleteInvoice = () => {
        setState(state => ({
            ...state,
            receiptImg: null,
        }));
        props.OrderReceipt(null);
    }
    const handleImageClick = async (invoice) => {
        if (invoice) {
            try {
                const response = await axios.get(invoice, { responseType: 'arraybuffer' });
                const blob = new Blob([response.data], { type: response.headers['content-type'] });
    
                const link = document.createElement('a');
                link.href = URL.createObjectURL(blob);
                link.download = 'downloaded-image.png';
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            } catch (error) {
                console.error('Error downloading image:', error);
            }
        }
    };

    return (
        <div className="form-row">
            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                <label className="frm-label">Customer Name | PMB<span className="text-danger">*</span></label>
                <CustomerList
                    page={1} limit={10}
                    searchCustomer={searchCustomer}
                    setSearchCustomer={(val) => setSearchCustomer(val)}
                    selectedOrderData={props.selectedOrderData}
                    isdisabled={props?.curdType === "Edit" ? true : false}
                    customerId={props.selectedOrderData?.customerId || state.customerId || null}
                    getCustomerInfo={(info) => handelChangeCustomer(info)}
                    required
                />
            </div>
            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                <label className="frm-label">
                    Tracking No<span className="text-danger">*</span>
                </label>
                <span className="labelFs10 pull-right">Please add only one tracking number per order at a time.</span>
                <input type="text" className="form-control ffPoppins" name="trackingno" placeholder="Enter Tracking No" defaultValue={props.selectedOrderData?.trackingno} onChange={e => TextChangeHandler(e)} required />
            </div>
            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                <label className="frm-label">Online Store<span className="text-danger">*</span></label>
                <OnlineStoreList
                    page={1}
                    limit={10}
                    searchStore={state?.searchStore}
                    setSearchStore={(val) => setState(state => ({ ...state ,searchStore: val }))}
                    selectedOrderData={props.selectedOrderData}
                    onlineStoreId={props.selectedOrderData?.onlineStoreId || state.onlineStoreId}
                    getOnlineStore={handelChangeOnlineStore} getOnlineStoreId={id => handelChangeOnlineStore(id)} required />
            </div>
            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                <label className="frm-label">Rate</label>
                <RatesList
                    page={1}
                    limit={10}
                    searchRate={state.searchRate}
                    setSearchRate={(val) => setState(preData => ({...preData, searchRate: val }))}
                    RatesId={props.selectedOrderData?.orderRateIds || state?.orderRateIds}
                    rateType="Order" getOrderRate={handelChangeRate} getOrderRateId={id => handelChangeRate(id)} />
            </div>
            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                <label className="frm-label">Assign Bins<span className="text-danger">*</span></label>
                <FreeBins1 binId={props.selectedOrderData?.binId || state.binId} customerId={props.selectedOrderData?.customerId || state.customerId} getFreeBinsInfo={value => handelChangeBins(value)}
                    selectedOrderData={props.selectedOrderData}
                    searchBins={state.searchBins}
                    setSearchBins={(val) => setState(preData => ({...preData , searchBins: val }))}
                    limit={10}
                    page={1} />
                {/* {AssignBinsDatas && AssignBinsDatas.getCustomerAssignBinOrderDeatils && (props?.selectedOrderData.status !== 'shipped' && props?.selectedOrderData.status !== 'packed') ? (
                    <span className="receiverAdd">
                        This Customer Assigned Bin(s) {AssignBinsDatas.getCustomerAssignBinOrderDeatils.customerAssignBin}{' '}
                    </span>
                ) : null} */}
                {props.selectedOrderData?.customerId || state.customerId ? 
                ((props.selectedOrderData.status !== "shipped" && props.selectedOrderData.status !== "packed") && AssignBinsData && AssignBinsData.customerAssignBin !== "false") ? 
                <span className="receiverAdd">This Customer Assigned Bin(s) {AssignBinsData?.customerAssignBin} </span>        
                  : null : null}
                {
                    props?.selectedOrderData?.binId && <span className="receiverAdd"> This Order Assigned Bin(s) {props?.selectedOrderData.binNum}</span>
                }
            </div>
            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                {
                    (state.receiptImg || props?.selectedOrderData?.invoiceOrReceipt)
                        ?
                        <label className="frm-label">Photo Of Invoice/ Receipt</label>
                        :
                        <label className="frm-label">Upload Your Invoice/ Receipt</label>
                }
                <br />
                <span className="btn btn-default btn-file btn-sm btn-danger ffPoppins">
                    <i className="fa fa-upload"></i> Choose a File <input type="file" onChange={(e) => InvoiceOrReceipt(e)} />
                    <br />
                </span>
                {
                    (state.receiptImg || props.selectedOrderData?.invoiceOrReceipt) &&
                    <div>
                        <div className="form-row">
                            <img src={props.selectedOrderData?.invoiceOrReceipt || state.receiptImg} alt="missImg" className="width200 mr-5 mt-2"
                                style={{ cursor: 'pointer' }} />
                            {
                                props.curdType === "Edit" &&
                                <>
                                    <span className="btn BoxImg bg-skyBlue rounded mr-2 cursor-pointer">
                                        <img src="../images/download.png" alt="Img" className="width15" onClick={() => { handleImageClick(props.selectedOrderData?.invoiceOrReceipt) }} />
                                    </span>
                                    <span className="btn BoxImg boxBackColorRed rounded mr-2 cursor-pointer" onClick={() => deleteInvoice(props.selectedOrderData?.invoiceOrReceipt)}>
                                        <img src="../images/bin.png" alt="Img" className="width15" />
                                    </span>
                                </>
                            }
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}

export default OrderDetails