import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { GET_WAREHOUSE_LIST } from '../../../graphql/query/WarehouseQuery';

const WarehouseSelect = ({ wrhsId, getCRUDshipmentId }) => {
  const [shipmentList, setShipmentList] = useState([]);
  const [colorError, setColorError] = useState(undefined);
  const [value, setValue] = useState(null);

  const { loading, data } = useQuery(GET_WAREHOUSE_LIST, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data) {
      if (data?.getAllShipments && data?.getAllShipments.length > 0) {
        const shipments = data?.getAllShipments;
        let shipmentsList = []
        shipments.forEach(d => {
          shipmentsList.push({ value: d.id, label: d.name })
        });
        setShipmentList(shipmentsList);
      }
    }
  }, [loading, data]);

  useEffect(() => {
    if(wrhsId){
      setDefaultShipments();
    }
  }, [shipmentList,wrhsId])

  const countryHandleChange = (value) => {
    setColorError(true);
    setValue(value);
    getCRUDshipmentId(value.value);
  };

  const setDefaultShipments = () => {
    if (wrhsId) {
      let shipmentData = _.find(shipmentList, (d) => { return d.value === wrhsId });
      setValue(shipmentData)
    } else setValue(null)
  }

  return (
    <Select
      className="basic-single ffPoppins"
      options={shipmentList}
      value={value}
      onChange={value => countryHandleChange(value)}
      styles={{
        control: (provided, state) =>
          !colorError && colorError !== undefined
            ? {
              ...provided,
              boxShadow: "0 0 0 1px #dc3545 !important",
              border: "1px #dc3545 !important"
            }
            : provided
      }}
    />
  );
};

export default WarehouseSelect;
