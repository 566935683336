import { gql } from "@apollo/client";

export const INSERT_UPDATE_CUSTOMER = gql`
  mutation insertUpdateCustomerByAdmin($input: customerInput!,$log: LogInput){
    insertUpdateCustomerByAdmin(input: $input,log: $log){
      id
      companyName
      firstName
      lastName
      email
      receivingServiceType
      phone
      idProof
      signature
      address1
      address2
      city
      stateId
      countryId
      zipcode
      deliveryServiceType
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_CUSTOMER = gql`
  mutation deleteCustomerByAdmin($id: ID!,$log: LogInput){
    deleteCustomerByAdmin(id: $id,log: $log)
  }
`;

export const RESETPASSWORD_CUSTOMER = gql`
  mutation setPasswordOfCustomerByAdmin($input:resetPasswordOfCustomer!,$log: LogInput){
    setPasswordOfCustomerByAdmin(input:$input,log:$log){
      id
      firstName
      lastName
      email
      phone
    }
  }
`;
export const getS3SignUrl = gql`
mutation getPreSignedUrl($type: String!){
  getPreSignedUrl(type: $type)
}`