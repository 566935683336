import React from 'react'

export const CustomerReport = ({reportData}) => {
    const { getCustomerReport } = reportData;
  return (
    <div className="col-md-12 no-padding d-inline-block">
    <div id="totalreceived" className="table-responsive">
        <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
            <thead>
                <tr>
                    <th>NO</th>
                    <th>DATE</th>
                    <th>CUSTOMER NAME | PMB</th>
                    <th>EMAIL</th>
                    <th>PHONE</th>
                    <th>ADDRESS</th>
                </tr>
            </thead>
            <tbody>
                {getCustomerReport.length ?
                    getCustomerReport.map((report, i) => {
                        const { createdAt, firstName, lastName, phone, email, address1, pmbNo } = report;
                        return <tr className="odd gradeX" key={report?.id || i}>
                            <td>{i + 1}</td>
                            <td>{new Date(createdAt).toLocaleDateString('en-US')}</td>
                            <td>{firstName + ' ' + lastName + ' | ' + pmbNo}</td>
                            <td>{email}</td>
                            <td>{phone}</td>
                            <td>{address1}</td>
                        </tr>
                    })
                    :
                    <tr className="odd gradeX">
                        <td colSpan="8">NO RECORD FOUND</td>
                    </tr>
                }
            </tbody>
        </table>
    </div>
</div>
  )
}
