import { gql } from "@apollo/client";

export const RECEIVER_CUSTOMER_SUBSCRIBE = gql`
  subscription receiverChange {
    receiverChange {
      keyType
      data{
        id
        name
        phone
        address1
        address2
        city
        zipCode
        customerId
        countryId {
          id
          name
          code
        }
        stateId {
          id
          name
        }
        closestCityId {
          id
          name
          code
        }
        createdBy
        createdAt
        updatedAt
        updatedBy
      }
    }
  }
`;