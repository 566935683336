import { gql } from "@apollo/client";

export const GET_CLOSEST_CITY = gql`
   query getClosestCities($page: Int, $limit: Int, $filter: String, $sort: closestCitySort, $filterCountryId: ID, $filterStateId: ID){
    getClosestCities(page: $page,limit: $limit, filter: $filter,sort: $sort, filterCountryId: $filterCountryId,filterStateId: $filterStateId){
      count
      data {
        id
        name
        code
        countryId {
          id
          name
          code
        }
        stateId {
          id
          name
        }
        isActive
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;
export const GET_CLOSEST_CITY_FOR_MULTIPLE = gql`
   query getClosestCitiesCountryStateWise($countryId:ID,$stateId:ID){
    getClosestCitiesCountryStateWise(countryId:$countryId,stateId:$stateId){
      id
      name
      code
      countryId {
        id
        name
        code
      }
      stateId {
        id
        name
      }
      isActive
      createdBy
      createdAt
      updatedBy
      updatedAt
  }
}
`;