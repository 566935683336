import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { GET_ALL_BINS } from '../../../graphql/query/binsQuery';

const AllBinsList = ({ binId, getAllBinsInfo }) => {
  const [allBinList, setAllBinList] = useState([]);
  const [value, setValue] = useState(null);
  const [colorError, setColorError] = useState(undefined);

  const { data } = useQuery(GET_ALL_BINS, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data && data.geAllBinList && data.geAllBinList.length > 0) {
      const AllBins = data?.geAllBinList;
      let getAllBins = []
        AllBins.forEach(d => {
          getAllBins.push({ value: d.id, label: d.bin })
        });
      setAllBinList(getAllBins);
      
    }
  }, [data]);
  useEffect(() => {
    if (allBinList?.length > 0) {
      setDefaulBins();
    }
  }, [allBinList ,binId])

  const allBinsHandleChange = (selectedValue) => {
    setColorError(true);
    setValue(selectedValue);

    const info = {
      id: selectedValue.value,
      name: selectedValue.label,
    };
    getAllBinsInfo(info);
  };

  const setDefaulBins = () => {
    if (binId) {
      const BinsData = _.find(allBinList, d => d.value === binId);
      setValue(BinsData);
    } else {
      setValue(null);
    }
  };

  return (
    <Select
      className="basic-single ffPoppins"
      options={allBinList}
      value={value}
      onChange={(selectedValue) => allBinsHandleChange(selectedValue)}
      styles={{
        control: (provided, state) =>
          !colorError && colorError !== undefined
            ? {
              ...provided,
              boxShadow: "0 0 0 1px #dc3545 !important",
              border: "1px #dc3545 !important"
            }
            : provided,
      }}
    />
  );
};

export default AllBinsList;
