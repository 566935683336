import React, { useEffect, useState } from 'react'
import { GET_CLOSEST_CITY } from '../../../../graphql/query/ClosetCityQuery';
import CountryList from '../../../components/DropdownSelect/CountrySelect';
import Loader from '../../../components/Loader/Loader';
import StatesList from '../../../components/DropdownSelect/StateSelect';
import Pagination from '../../../components/Common/Pagination';
import { GetClosestsCity } from './GetClosestsCity';
import { InsertUpdateClosestsCity } from './InsertUpdateClosestsCity';
import { statusType } from '../../../components/Constant';
import { useQuery } from '@apollo/client';
import { useLocation } from 'react-router-dom';
import * as $ from 'jquery';
import { AccessDenied } from '../../../components/AccessDenied';

export const ClosestCity = () => {
  const location = useLocation();
const genSettings = JSON.parse(localStorage.getItem('generalSettings'))
const user = JSON.parse(localStorage.getItem('loggedUser'));
  const [closestsCity, setClosestsCity] = useState({
    limit: genSettings?.paginationLimit || 10,
    name: '',
    code: '',
    countryId: null,
    stateId: null,
    state: '',
    temp_state: '',
    stateColorError: null,
    isActive: true,
    temp_isActive: statusType[0],
    filterCountryId: null,
    filterStateId: null,
    pageNo: 0,
    filter: "",
    filterText: '',
    id: null,
    sortKey: 'createdAt',
    sortType: -1,
    openModal: 'Add New',
  })

  const { data, loading, refetch } = useQuery(GET_CLOSEST_CITY, {
    variables: { "page": closestsCity.pageNo + 1, "limit": closestsCity.limit, "filter": closestsCity.filter, "sort": { key: closestsCity.sortKey, type: closestsCity.sortType }, "filterCountryId": closestsCity.filterCountryId, "filterStateId": closestsCity?.filterStateId },
    fetchPolicy: 'cache-and-network'
  })
  useEffect(() => {
      if (location &&  location?.state) {
          const { stateData} = location.state;
          if (stateData) {
              setClosestsCity(prevState => ({ ...prevState, filterCountryId: stateData?.countryId.id, countryId: stateData.countryId.id,filterStateId : stateData.id , stateId : stateData.id}))
          }
      }
  }, []);
  const searchClosestCity = () => {
      setClosestsCity(prevState => ({ ...prevState, pageNo: 0, filter: closestsCity.filterText }))
  }
  const onResetSearch = () => {
    setClosestsCity(prevState => ({ ...prevState,  pageNo: 0, filter: "", filterText: "",
    filterCountryId: null, countryId: null, filterStateId: null, stateId: null}))

  }
  const handlePageClick = data => {
    setClosestsCity(prevState => ({ ...prevState, pageNo: data?.selected}))

  };
  const handleSearch = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      searchClosestCity()
    }
  }
  const addNewClosestCity = (e) => {
    e.preventDefault();
    setClosestsCity(prevClosestCity => ({
      ...prevClosestCity, openModal: 'Add New',
      countryId: location.state && location.state.stateData.countryId.id,
      stateId: location.state && location.state.stateData.id,
      name :'',
      code : ''
    }))
    $('#ClosestCityModal').modal('show');
  }
  if (user?.roles === 'standard') return <AccessDenied />
  else
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 p-0">
            <div className="mb-20">
              <p className="mainTitle m-0 mt_-15">Closest City</p>
              <span className="borderBottomOfTitle"></span>
            </div>
          </div>
          <div className="col-md-4 p-0">
            <div className="float-right">
              <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewClosestCity(e)}> <i className="fa fa-plus"></i> Add New ClosestCity</button>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-12 p-0">
            <div className="ibox">
              <div className="ibox-title">
                <span>ClosestCity Details</span>
              </div>
              <div className="ibox-content p-0">
                {loading && <Loader />}
                <div className="col-md-12 ph-20 mt-3">
                  <div className="form-row">
                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                      <label className="frm-label">Country</label>
                      <CountryList countryId={closestsCity?.filterCountryId} getCRUDCountryId={(id) => { setClosestsCity(prevClosestCity => ({ ...prevClosestCity, filterCountryId: id, filterStateId: null })) }} />
                    </div>
                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                      <label className="frm-label">State</label>
                      <StatesList countryId={closestsCity?.filterCountryId} stateId={closestsCity?.filterStateId} getCRUDStateId={(id) => setClosestsCity(prevClosestCity => ({ ...prevClosestCity, filterStateId: id }))} />
                    </div>
                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                      <label className="frm-label">Search</label>
                      <input type="text" value={closestsCity?.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setClosestsCity(prevClosestCity => ({ ...prevClosestCity, filterText: e.target.value }))} className="form-control" />
                    </div>
                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="mt-30">
                        <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchClosestCity()} >Search</button>
                        <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                        <div className="pull-right">
                          <Pagination
                            pageCount={data && data?.getClosestCities && Math.ceil(data && data?.getClosestCities?.count / closestsCity?.limit)}
                            pageNo={closestsCity?.pageNo}
                            handlePageClick={(data) => handlePageClick(data)} />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <GetClosestsCity refetch={refetch} closestsCity={closestsCity} setClosestsCity={setClosestsCity} getClosestCities={data?.getClosestCities}/>
              </div>
            </div>
          </div>
        </div>

        <div className="modal show" id="ClosestCityModal" data-backdrop="static" tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
          <InsertUpdateClosestsCity refetch={refetch} closestsCity={closestsCity} setClosestsCity={setClosestsCity}/>
          </div>
        </div>

      </div>
    )
}
