import { useMutation } from '@apollo/client'
import React from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { DEFAULT_WAREHOUSE, DELETE_WAREHOUSE } from '../../../../graphql/mutation/WareHouseMutation';
import confirmDeletion from '../../../components/Common/BootBox';
import * as $ from 'jquery';

export const GetDestinationWarehouse = ({ wareHouses, refetch, setDestinationWarehouse, destinationWarehouse }) => {

    const [deleteWareHouse] = useMutation(DELETE_WAREHOUSE);
    const [defaultWareHouse] = useMutation(DEFAULT_WAREHOUSE)

    const sortData = (sortKey) => {
        const newSortType = (destinationWarehouse.sortType === 1) ? -1 : 1;
        setDestinationWarehouse((prevBins) => ({ ...prevBins, sortKey: sortKey, sortType: newSortType }));
    };
    const editDestinationWarehouse = (e, oldWarehouse) => {
        console.log("🚀 ~ editDestinationWarehouse ~ oldWarehouse:", oldWarehouse)
        
        e.preventDefault();
        let statusValue = oldWarehouse?.isActive ? { value: true, label: 'Active' } : { value: false, label: 'Inactive' };
        setDestinationWarehouse(prevData => ({
            ...prevData,
            id: oldWarehouse?.id,
            temp_countryId: oldWarehouse?.countryId.id,
            temp_stateId: oldWarehouse?.stateId.id,
            temp_isActive: statusValue,
            WHData: oldWarehouse,
            Old_Data: oldWarehouse,
            openModal: 'Edit'
        }))
        $('#frm_des_warehouse').removeClass('was-validated');
        $('#DestinationWarehouseModal').modal('show');
    }
    const deleteDestinationWarehouseDetails = (e, warehouseId) => {
        e.preventDefault();
        const deleteWareHouseData = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'warehouses',
                actionName: 'deleteWareHouse',
                oldValue: ""
            }
            deleteWareHouse({ variables: { "id": warehouseId, "log": log } }).then(async ({ data }) => {
                refetch();
                $('#DestinationWarehouseModal').modal('hide');
                toast.success("Destination Warehouse deleted successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion("Delete DestinationWarehouse", "Are you sure want to delete this record ?", deleteWareHouseData)
    }
    const defaultDestinationWarehouse = (e, warehouse) => {
        e.preventDefault();
        const DefaultWareHouse = () => {
            let id = warehouse.id;
            defaultWareHouse({ variables: { id } }).then(async ({ data }) => {
                refetch();
                $('#DestinationWarehouseModal').modal('hide');
                toast.success("Default set successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion("Set Default DestinationWarehouse", "Are you sure want to set this record as a default ?", DefaultWareHouse);
    }


    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0 tbl-sort">
                <thead>
                    <tr>
                        <th className=" text-center">No</th>
                        <th
                            className={`sortColumn ${(destinationWarehouse?.sortKey === 'name') ? (destinationWarehouse?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('name')}>Warehouse Name</th>
                        <th
                            className={`sortColumn ${(destinationWarehouse?.sortKey === 'code') ? (destinationWarehouse?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('code')}>Warehouse Code</th>
                        <th
                            className={`sortColumn ${(destinationWarehouse?.sortKey === 'addLine1') ? (destinationWarehouse?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('addLine1')}>Address</th>
                        <th>Country</th>
                        <th
                            className={`sortColumn ${(destinationWarehouse?.sortKey === 'isActive') ? (destinationWarehouse?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('isActive')}>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        wareHouses && wareHouses?.data && wareHouses?.data?.length > 0
                            ? wareHouses && wareHouses?.data.map((WH, i) => {
                                return (
                                    <tr key={WH?.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editDestinationWarehouse(e, WH)}>
                                        <td className=" text-center">{i + 1}</td>
                                        <td>{WH?.name}</td>
                                        <td>{WH?.code}</td>
                                        <td>{WH?.addLine1}{WH?.addLine1 && WH?.addLine2 && ", "}{WH?.addLine2 && WH?.addLine2}{WH?.addLine2 && WH?.city && ', '}{WH?.city && WH?.city}{WH?.city && WH?.stateId && WH?.stateId.name && ', '}{WH?.stateId && WH?.stateId.name && WH?.stateId.name}{WH?.stateId && WH?.stateId.name && WH?.zipcode && ', '}{WH?.zipcode && WH?.zipcode}</td>
                                        <td>{WH?.countryId && WH?.countryId.name}</td>
                                        {WH?.isActive ?
                                            <td><span className="statusStyle activeCustomer">Active</span></td>
                                            :
                                            <td><span className="statusStyle inActiveCustomer">InActive</span></td>
                                        }
                                        <td>
                                            <div className="d-flex flex-row">
                                                <Link to={`/warehouse-location/${WH?.id}`} >
                                                    <span className="btn BoxImg bg-lightBlue rounded mr-2">
                                                        <img src="../images/pin.png" alt="Img" className="width15" />
                                                    </span>
                                                </Link>
                                                <Link to={`/warehouse-user/${WH?.id}`}>
                                                    <span className="btn BoxImg bg-lightGreen rounded mr-2">
                                                        <img src="../images/userIcon.png" alt="Img" className="width15" />
                                                    </span>
                                                </Link>
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editDestinationWarehouse(e, WH)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>

                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteDestinationWarehouseDetails(e, WH.id)}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>

                                                <button disabled={WH.isDefault} className="btn BoxImg bg-lightGreen rounded mr-2" onClick={(e) => defaultDestinationWarehouse(e, WH)}>
                                                    <img src="../images/check.png" alt="Img" className="width15" />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ textAlign: 'center' }} colSpan={7} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
