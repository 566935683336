import React, { useState, useEffect } from 'react';
import { useLazyQuery } from '@apollo/client';
import Select from 'react-select';
import { delayedFunction } from './utils';
import { GET_RATES_TYPE_WISE } from '../../../graphql/query/RatesQuery';

const RatesSelect = ({ rateType, page, limit, searchRate, getOrderRateId, RatesId, setSearchRate }) => {
  const [state, setState] = useState({
    oldCountryId: '',
    RatesList: [],
    defaultValue: null,
    colorError: undefined,
    value: null,
    searchKey: '',
    originalRatesList: [],
  });

  const [getRatesTypeWiseList, { data }] = useLazyQuery(GET_RATES_TYPE_WISE, {
    fetchPolicy: 'cache-and-network',
    variables: {
      rateType,
      page,
      limit,
      filter: searchRate,
      sort: { key: 'createdAt', type: -1 },
      id: RatesId,
    },
    fetchPolicy: 'cache-and-network'
  });

  useEffect(() => {
    getRatesTypeWiseList();
  }, [getRatesTypeWiseList]);

  useEffect(() => {
    if (data && data.getRateTypeWiseList && data.getRateTypeWiseList.length > 0) {
      const Ratess = data.getRateTypeWiseList;
      let getRatess = []
        Ratess.forEach(d => {
          getRatess.push({ value: d.id, label: d.name })
        });
      setState((prevState) => ({
        ...prevState,
        RatesList: getRatess,
        originalRatesList: getRatess,
      }));
      
    }
  }, [data]);

  useEffect(() => {
    setDefaultRatess();
  },[state?.originalRatesList ,state?.RatesList])

  useEffect(() => {
    delayedFunction(setSearchRate(state?.searchKey));
  },[state?.searchKey])

  const RatesHandleChange = (selectedValues) => {
    if (selectedValues && selectedValues.length > 0) {
      const selectedRates = selectedValues.map((d) => d.value);
      getOrderRateId(selectedRates);

      const inputValue = state.searchKey.toLowerCase();
      const filteredOptions = state.originalRatesList.filter((option) =>
        option.label.toLowerCase().includes(inputValue)
      );

      setState((prevState) => ({ ...prevState, RatesList: filteredOptions, value: selectedValues }));
    } else {
      setState((prevState) => ({ ...prevState, value: [], RatesList: state.originalRatesList }));
      getOrderRateId([]);
    }
  };

  const setDefaultRatess = () => {
    const airFreightOption = state?.RatesList?.find((option) => option.label === 'Air Freight');

    if (RatesId && RatesId.length > 0) {
      let findData = [];
      RatesId.forEach(rateId => {
        const matchedOption = state?.RatesList.find(option => option.value === rateId);
        if (matchedOption) {
          findData.push(matchedOption);
        }
      });

      if (findData.length > 0) {
        setState((prevState) => ({ ...prevState, value: findData }));
      } else {
        setState((prevState) => ({ ...prevState, value: [airFreightOption] }));
      }
    } else {
      setState((prevState) => ({ ...prevState, value: [airFreightOption] }));
    }
  };

  return (
    <Select
      className="basic-single ffPoppins"
      isMulti={true}
      options={state.RatesList}
      value={state.value}
      placeholder="Rate Type"
      onChange={(selectedValues) => RatesHandleChange(selectedValues)}
      onInputChange={(value) =>
        setState((prevState) => ({ ...prevState, searchKey: value }))
      }
      styles={{
        control: (provided, state) =>
          !state.colorError && state.colorError !== undefined
            ? {
                ...provided,
                boxShadow: '0 0 0 1px #dc3545 !important',
                border: '1px #dc3545 !important',
              }
            : provided,
      }}
    />
  );
};

export default RatesSelect;
