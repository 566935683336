import { gql } from "@apollo/client";

export const INSERTUPDATE_CATEGORY = gql`
  mutation insertUpdateCategory($input: CategoryInput!,$log: LogInput){
    insertUpdateCategory(input:$input,log:$log){
      id
      name
      icon
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;
export const DELETE_CATEGORY = gql`
  mutation deleteCategory($id: ID!,$log: LogInput){
    deleteCategory(id:$id,log:$log){
      id
      name
      icon
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

export const INSERTUPDATE_SUBCATEGORY = gql`
  mutation insertUpdateSubCategory($input:SubCategoryInput!,$log:LogInput){
    insertUpdateSubCategory(input:$input,log:$log){
      id
      name
      icon
      categoryId
      isActive
      isDeleted
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const DELETE_SUBCATEGORY = gql`
  mutation deleteSubCategory($id: ID!,$log: LogInput){
    deleteSubCategory(id:$id,log:$log){
      id
      name
      icon
      categoryId
      isActive
      isDeleted
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const INSERTUPDATE_PRODUCT = gql`
  mutation insertUpdateProduct($input:ProductInput!,$log:LogInput){
    insertUpdateProduct(input:$input,log:$log){
      id
      name
      icon
      desc
      price
      qty
      isPromotional
      categoryId
      subCategoryId
      isActive
      isDeleted
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;

export const DELETE_PRODUCT = gql`
  mutation deleteProduct($id:ID!,$log:LogInput){
    deleteProduct(id:$id,log:$log){
      id
      name
      icon
      desc
      price
      qty
      isPromotional
      categoryId
      subCategoryId
      isActive
      isDeleted
      createdAt
      createdBy
      updatedAt
      updatedBy
    }
  }
`;