import moment from 'moment';
import React, { useEffect, useState } from 'react';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"
import * as $ from 'jquery';
import AllBinsList from '../../components/DropdownSelect/AllBinsSelect';
import _ from 'lodash';
import ShipmentSelectList from '../../components/DropdownSelect/ShipSelect';
import { useMutation, useQuery } from '@apollo/client';
import { PACKAGEBOX_SUBSCRIBE } from '../../../graphql/subscription/packingSubcription';
import { GET_PACKAGEBOX } from '../../../graphql/query/packingQuery';
import ReactPaginate from 'react-paginate';
import { Link } from 'react-router-dom';
import AddBox from './AddBox';
import Loader from '../../components/Loader/Loader';
import EditBox from './EditBox';
import { toast } from 'react-toastify';
import { DELETE_PACKAGEBOX, MOVE_TO_SHIPMENT_PACKAGES } from '../../../graphql/mutation/packingMutation';
import Title from '../../components/Common/Title';
import Pagination from '../../components/Common/Pagination';
import confirmDeletion from '../../components/Common/BootBox';

const Packing = (props) => {
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'));

    const [state, setState] = useState({
        fromDate: null,
        toDate: null,
        pageNo: 0,
        limit: ((genSettings?.paginationLimit) ? (genSettings?.paginationLimit) : 10),
        filter: "",
        filterText: '',
        filterFrom: null,
        filterTo: null,
        shipmentId: null,
        shipmentNo: null,
        shipmentDate: null,
        estimetedDate: null,
        selectedPackges: [],
        isCheckAll: false,
        binId: null,
        binNum: null,
        PackageBoxData: null,
        skipQuery: false,
        onSubscribe: null,
        selectedPackageBoxDetailsDatas: {},
        id: null
    });
    const [searchShipment, setSearchShipment] = useState('')
    const [isAdd, setIsAdd] = useState(false);
    const [isEdit, setIsEdit] = useState(false);

    const { data, loading, refetch } = useQuery(GET_PACKAGEBOX, {
        skip: state?.skipQuery,
        onCompleted: ({ getPackageBox }) => setState(preData => ({ ...preData, PackageBoxData: getPackageBox, skipQuery: true })),
        variables: { "page": state.pageNo + 1, "limit": state.limit, 'filter': state?.filter, "sort": { key: 'createdAt', type: -1 }, from: state.filterFrom, to: state.filterTo, binNum: state.binNum },
        fetchPolicy: 'no-cache'
    })

    const [deletePackageBox] = useMutation(DELETE_PACKAGEBOX, {
        onCompleted: () => { setState(preData => ({ ...preData, skipQuery: false })) }
    })

    const [moveToShipmet] = useMutation(MOVE_TO_SHIPMENT_PACKAGES)

    useEffect(() => {
        $('.react-datepicker-wrapper').addClass('d-flex');
    }, []);

    const fromDateChange = (date) => {
        setState((prev) => ({ ...prev, fromDate: date, filterFrom: moment(date).format('YYYY/MM/DD'),skipQuery: false }));
    };
    const toDateChange = (date) => {
        setState((prev) => ({ ...prev, toDate: date, filterTo: moment(date).format('YYYY/MM/DD'),skipQuery: false }));
    };
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    };


    const selectedPackageForMoveToShipment = (packageId) => {
        let { selectedPackges } = state;
        let itemArray = selectedPackges;
        if (itemArray && itemArray.length > 0) {
            let index = itemArray.indexOf(packageId);
            if (index === -1) {
                itemArray.push(packageId);
            } else {
                itemArray.splice(index, 1);
            }
        } else {
            itemArray.push(packageId);
        }
        setState((prev) => ({ ...prev, selectedPackges: itemArray, isCheckAll: false }));
    };

    const handleChangeAllBins = (value) => {
        setState((prev) => ({ ...prev, binId: value.id, binNum: value.name }));
    };

    const searchPackages = () => {
        setState((prev) => ({ ...prev, pageNo: 0, filter: state?.filterText, skipQuery: false }));
    };

    const onResetSearch = () => {
        $('.active').removeClass('active');
        $(`.btn-outline-All`).addClass('active');
        setState((prev) => ({
            ...prev,
            pageNo: 0,
            filter: '',
            filterText: '',
            orderStatus: null,
            filterOrderStatus: null,
            fromDate: null,
            filterFrom: null,
            filterTo: null,
            toDate: null,
            binNum: null,
            binId: null,
            skipQuery: false,
        }));
    };

    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchPackages();
        }
    };

    const selectAllPackage = async (allPackageData) => {
        let getAllPackageIds = [];
        let { selectedPackges ,isCheckAll } = state;
        if (allPackageData && allPackageData.length > 0) {
            let getAllPackage = _.filter(allPackageData, (d) => !d.shipmentNo);
            await getAllPackage.map((d) => getAllPackageIds.push(d.id));
            let getMatch = (selectedPackges.length > 0 && _.intersection(selectedPackges, getAllPackageIds)) || false;
            if (getMatch && getMatch?.length === selectedPackges?.length) {
                setState((prev) => ({ ...prev, selectedPackges: [], isCheckAll: false }));
            } else {
                setState((prev) => ({ ...prev, selectedPackges: getAllPackageIds, isCheckAll: true }));
            }
        } else {
            setState((prev) => ({ ...prev, selectedPackges: [], isCheckAll: false }));
        }
    };

    const handlePageClick = (data) => {
        setState((prev) => ({ ...prev, pageNo: data.selected, skipQuery: false }));
    };

    const editSelectedBox = (e, oldPackageBoxDetails) => {
        e.preventDefault();
        setIsEdit(true);
        setIsAdd(false);
        setState((prev) => ({ ...prev, selectedPackageBoxDetailsDatas: oldPackageBoxDetails, id: oldPackageBoxDetails.id }));
        $('#EditSelectedBox').modal('show');
    };

    const deleteOrderDetails = async (e, id, orderIds, itemIds) => {
        e.preventDefault();
        const deketeOrderDetails = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'packageboxes',
                actionName: 'deletePackageBox',
                oldValue: ""
            }

            deletePackageBox({ variables: { "id": id, "orderIds": orderIds, "itemIds": itemIds, "log": log } }).then(async ({ data }) => {
               refetch();
                $('#OrderModal').modal('hide');
                toast.success("Package deleted successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion("Delete ORDER" , 'Are you sure want to delete this record ?' ,deketeOrderDetails)
        
    }

    const addNewBox = (e) => {
        e.preventDefault()
        setIsAdd(true)
    }

    useEffect(() => {
        if (isAdd === true) {
            console.log("call======add new ");
            $('#AddNewBox').modal('show');
        } else if (isEdit === true) {
            console.log("hide======");
            $('#EditSelectedBox').modal('show');
        } else if (isEdit === false) {
            $('#EditSelectedBox').modal('hide');
        } else if (isAdd === false) {
            $('#AddNewBox').modal('hide');
        }
    }, [isAdd, isEdit]);

    const closeBox = () => {
        setIsAdd(false);
        setIsEdit(false)
        $('#AddNewBox').modal('hide');
        $('#EditSelectedBox').modal('hide');
        setState((prev) => ({ ...prev, skipQuery: false }));
    };

    const moveToShipmetPackage = (e) => {
        e.preventDefault()
        let { selectedPackges, shipmentId, shipmentNo } = state;

        const input = {
            shipmentId,
            shipmentNo,
            selectedPackages: selectedPackges
        }
        const log = {
            action: 'UPDATE',
            actionOn: 'packageboxes',
            actionName: 'moveToShipment'
        }

        if (shipmentId && shipmentNo && selectedPackges.length > 0) {
            moveToShipmet({ variables: { input: input, log: log } }).then(async ({ data }) => {
                if (data) {
                    setState(preData => ({ ...preData, selectedPackges: [], skipQuery: false, isCheckAll: false }))
                    toast.success("Shipment assigned successfully!", {
                        autoClose: 2000
                    })
                    refetch()
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        } else {
            toast.error("Please fill required fields", {
                autoClose: 2000
            })
        }

    }
    const handleChangeShipment = (data) => {
        setState(preData => ({ ...preData, selectedShipment: { data } }));
    }
    let getAllPackage = _.filter(state?.PackageBoxData?.data, (d) => !d?.shipmentNo);

    return (
        <div className="container-fluid">
            <div className="row">
                <Title
                    name="Box List"
                />
                <div className="col-md-4 p-0">
                    <div className="float-right">
                        <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewBox(e)}> <i className="fa fa-plus"></i> Add New Box </button>
                    </div>
                </div>
            </div>

            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Box Details</span>
                        </div>
                        {loading && <Loader />}
                        <div className="ibox-content p-0">
                            <div className="col-md-12 ph-20 mt-3">
                                <form>
                                    <div className="form-row">
                                        <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Form</label>
                                            <DatePicker
                                                className="form-control ffPoppins d-flex"
                                                placeholderText="mm/dd/yyyy"
                                                selected={state.fromDate}
                                                maxDate={new Date()}
                                                onChange={fromDateChange}
                                                onChangeRaw={handleDateChangeRaw}
                                            />
                                        </div>
                                        <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">To</label>
                                            <DatePicker
                                                className="form-control ffPoppins d-flex"
                                                placeholderText="mm/dd/yyyy"
                                                minDate={state.fromDate}
                                                maxDate={new Date()}
                                                selected={state.toDate}
                                                onChange={toDateChange}
                                                onChangeRaw={handleDateChangeRaw}
                                            />
                                        </div>
                                        <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Bin</label>
                                            <AllBinsList binId={state.binId} getAllBinsInfo={(info) => handleChangeAllBins(info)} />
                                        </div>
                                        <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <label className="frm-label">Search</label>
                                            <input type="text" className="form-control ffPoppins" value={state.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setState(prev => ({ ...prev, filterText: e.target.value, skipQuery: true }))} />
                                        </div>
                                        <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div className="btn-Groups mt-30">
                                                <button className="btn btn-primary btn-text border-0 bg-searchBlue" type="button" onClick={() => searchPackages()} >Search</button>
                                                <button className="btn btn-danger btn-text bg-resetRed" type="button" onClick={onResetSearch}>Reset</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="well">
                                <form onSubmit={(e) => moveToShipmetPackage(e)}>
                                    <div className="form-row">
                                        <div className="form-group m-0 col-lg-2 col-md-2 col-sm-6 col-xs-12 d-flex justify-content-center align-items-center">
                                            <input type="checkbox" name="SelectAllBoxToShip" id="SelectAllBoxToShip" onChange={() => selectAllPackage(state?.PackageBoxData?.data)} checked={state?.isCheckAll} disabled={!getAllPackage?.length}/>
                                            <label htmlFor="SelectAllBoxToShip" className="frm-label chkAll">Check All</label>
                                        </div>
                                        <div className="form-group m-0 col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Shipment No</label>
                                            <ShipmentSelectList
                                                limit={10}
                                                page={1}
                                                searchShipment={searchShipment}
                                                setSearchShipment={(val) => setSearchShipment(val)}
                                                selectedShipment={state.selectedShipment}
                                                getShipmentInfo={(info) =>
                                                    handleChangeShipment(info)
                                                }
                                                shipId={state.shipmentId}
                                                getShipId={(shipData) => setState(preData => ({
                                                    ...preData,
                                                    shipmentId: shipData && shipData?.value,
                                                    shipmentNo: shipData && shipData?.label,
                                                    shipmentDate: shipData && moment(shipData?.shippingDate).format('MM/DD/YYYY'),
                                                    estimetedDate: shipData && moment(shipData?.estimatedDate).format('MM/DD/YYYY')
                                                }))}
                                            />
                                        </div>

                                        <div className="form-group m-0 col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Shipping Date</label>
                                            <input type="text" className="form-control ffPoppins d-flex" placeholderText="mm/dd/yyyy" value={state.shipmentDate} disabled />
                                        </div>
                                        <div className="form-group m-0 col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Estimeted Delivery Date</label>
                                            <input type="text" className="form-control ffPoppins d-flex" placeholderText="mm/dd/yyyy" value={state.estimetedDate} disabled />
                                        </div>
                                        <div className="form-group m-0 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div className="mt-30 ml-3">
                                                <button type="submit" className="btn btn-success btn-text bg-lightGreen"> Move To Shipment</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="ibox-title border-0">
                                <span>Box List</span>
                                <div className="pull-right">
                                    <Pagination
                                        pageCount={state.PackageBoxData && Math.ceil(state.PackageBoxData.count / state?.limit)}
                                        pageNo={state.pageNo}
                                        handlePageClick={handlePageClick}
                                    />
                                </div>
                            </div>
                            <div className="table-responsive">
                                <table className="table themeTable table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center"></th>
                                            <th>No.</th>
                                            <th>Date</th>
                                            <th>Customer Name</th>
                                            <th>Receiver Name</th>
                                            <th>Receiver Phone</th>
                                            <th>Box Number</th>
                                            <th>Bin Number</th>
                                            {/* <th>OverPack Number</th> */}
                                            <th>Shipment Number</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            state?.PackageBoxData && state?.PackageBoxData?.data && state?.PackageBoxData?.data?.length ?
                                                state?.PackageBoxData?.data && state?.PackageBoxData?.data.map((d, i) => {
                                                    let isChecked = !!_.find(state.selectedPackges, pkId => pkId === d.id);
                                                    return (
                                                        <tr key={d?.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editSelectedBox(e, d)} >
                                                            <td className="text-center"><input type="checkbox" disabled={d.shipmentNo} checked={isChecked} onChange={() => selectedPackageForMoveToShipment(d.id)} /></td>
                                                            <td>{i + 1}</td>
                                                            <td>{moment(d.createdAt).format('MM/DD/YYYY')}</td>
                                                            <td>{d.customerInfo.firstname} {d.customerInfo.lastname && d.customerInfo.lastname}</td>
                                                            <td>{d.receiverInfo.name}</td>
                                                            <td>{d.receiverInfo.phone}</td>
                                                            <td>
                                                                {
                                                                    d.boxDimension && d.boxDimension.map((b, i) => {
                                                                        if (d.boxDimension.length === (i + 1)) {
                                                                            return (
                                                                                <span>{b.box_Num}</span>
                                                                            )
                                                                        }
                                                                        else {
                                                                            return (
                                                                                <span>{b.box_Num},</span>
                                                                            )
                                                                        }
                                                                    })
                                                                }
                                                            </td>
                                                            <td>{d.binNum}</td>
                                                            <td><Link to={{
                                                                pathname: `/view-shipment/${d.shipmentId}`, state: {
                                                                    shipmentNo: d.shipmentNo,
                                                                    barcode: d.barcode,
                                                                    shipmentId: d.shipmentId,
                                                                }
                                                            }} >{d.shipmentNo}</Link></td>
                                                            <td>
                                                                <div className="d-flex flex-row">
                                                                    <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editSelectedBox(e, d)}>
                                                                        <img src="../images/edit.png" alt="Img" className="width15" />
                                                                    </span>
                                                                    <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteOrderDetails(e, d.id, d.orderIds, d.itemIds)}>
                                                                        <img src="../images/bin.png" alt="Img" className="width15" />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                                :
                                                <tr>
                                                    <td style={{ textAlign: 'center' }} colSpan={10}>
                                                        No Records Found!
                                                    </td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                isAdd && !isEdit &&
                <div className="modal show" id="AddNewBox" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                        <div className="modal-content ">
                            <div>
                                <AddBox
                                    {...props}
                                    key={Math.random().toString()}
                                    closeModal={() => closeBox()}
                                    isEdit={isEdit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
            {
                isEdit && !isAdd &&
                <div className="modal show" id="EditSelectedBox" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                        <div className="modal-content ">
                            <div className="modal-header" style={{ paddingTop: "0px" }}>
                                <div>
                                    <p className="mainTitle m-0 mt_-15">Edit Box</p>
                                    <span className="borderBottomOfTitle "></span>
                                </div>
                                <div>
                                    <button onClick={() => closeBox()}
                                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <EditBox
                                    SelectedPackageBoxDetailsData={state.selectedPackageBoxDetailsDatas}
                                    PackageBoxId={state?.id}
                                    key={Math.random().toString()}
                                    closeModal={() => closeBox()}
                                    isEdit={isEdit}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>
    )
}

export default Packing