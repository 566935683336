import React, { useState } from 'react'
import { Footer } from '../../../layouts/Footer'
import { HeaderComp } from '../../../components/HeaderComp'
import parse from 'html-react-parser';

export const ProhibitedWeb = () => {
    const [genaralSettings] = useState(JSON.parse(localStorage.getItem('generalSettings')))
    return (
        <div className="container-fluid p-0 bgWhite">
            <div className="aboutImg" style={{ backgroundImage: "url(/images/sliderProhibited.png)", }} >
                <HeaderComp
                    Title={'Prohibited Items'}
                />
            </div>
            <div className="pl-5 pr-5">
                <div className="p-5">
                    <div className="prbBtnDiv">
                        <h5 className="mb-0 white text-center">Prohibited Items</h5>
                    </div>
                    <div className='mt-5'>
                        {genaralSettings?.prohibitedList ? parse(genaralSettings?.prohibitedList) : (
                            <>
                                <div className="mt-5">
                                    <p className="inputFs14">
                                        This is remide all our esteemed customers that we do not accept the bellow list for air transport.
                                        This is not in anyway exhaustive, hence there will be other item not on this list what we cannot
                                        accept for air transport. This items are highly regulated with fines up to $250,000 for violation.
                                    </p>
                                </div>
                                <div className="mt-5 mb-5">
                                    <ul className="inputFs14">
                                        <li>Lithium Battery</li>
                                        <li>Laptop Battery</li>
                                        <li>Any Lead - acid or dey cell Battery</li>
                                        <li>Power Bank</li>
                                        <li>Any Compressed Gases</li>
                                        <li>Any Compressed aerosols</li>
                                        <li>Perfumes and Colognes</li>
                                        <li>Any Flammable Substance</li>
                                        <li>Fertilizers</li>
                                        <li>Any Corrosive Substance</li>
                                        <li>Any other Hazardous Material</li>
                                    </ul>
                                </div>
                                <div className="">
                                    <p className="inputFs14">
                                        You are required to contact us prios ordering and shipping any hzardous material to our
                                        warehouse for anward air cargo to nigeria, Please do not hesitate to contact us with
                                        anyconcerns or questions you may have.
                                    </p>
                                </div>
                                <div className="mt-5 mb-4">
                                    <p className="inputFs14">Thank you and Warm Regards,</p>
                                </div>
                                <div>
                                    <p className="mb-0"><b>Management</b></p>
                                    <p className=""><b>Cargoatlantic ShipMyShopping (SMS)</b></p>
                                </div>
                            </>
                        )
                        }
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}
