import { gql } from "@apollo/client";

export const INSERT_ORDER = gql`
  mutation addOrder($input: OrderInput!,$log: LogInput){
    addOrder(input:$input,log:$log){
      id
      customerId
      trackingno
      binNum
      orderFromSms
      orderRateIds
      status
      insurePer
      remark
      isPromoCode
      isPayAmount
      customerInfo {
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      items {
        id
        qty
        price
        total
        description
        rate
        instruction
        isInsure
        itemStatus
        insurerate
        insureprice
      }
      invoiceOrReceipt
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

export const DELETE_ORDER = gql`
  mutation deleteOrder($id: ID!, $binId: ID, $log: LogInput){
    deleteOrder(id: $id,binId: $binId,log: $log){
      id
      customerId
      trackingno
      binNum
      orderFromSms
      orderRateIds
      status
      insurePer
      remark
      isPromoCode
      isPayAmount
      customerInfo {
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      items {
        id
        description
        insureprice
        rate
        itemRate
        isInsure
        insurerate
        insureprice
      }
      invoiceOrReceipt
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

export const ADD_NOTIFICATION = gql`
  mutation insertUpdateNotification($input: notificationInput!,$log: LogInput){
    insertUpdateNotification(input:$input,log:$log){
      id
      customerId
      orderId
      tracking_no
      orderStatus
      name
      msg
      isDeleted
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_NOTIFICATION = gql`
  mutation deleteNotification($id: ID!,$log: LogInput){
    deleteNotification(id:$id,log:$log){
      id
      customerId
      orderId
      tracking_no
      orderStatus
      name
      msg
      isDeleted
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;