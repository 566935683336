import React, { useState } from 'react'
import { statusType } from '../../../components/Constant';
import Select from 'react-select';
import Loader from '../../../components/Loader/Loader';

export const InsertUpdateBins = ({inserUpdateLoading,Bins ,setBins,onSubmit}) => {
    const onStatusChange = (newBinStatus) => {
        setBins((prevBins) => ({ ...prevBins, newBinStatus: newBinStatus }));
    };
    return (
        <>
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">Add New Bin</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                {inserUpdateLoading && <Loader />}
                                <div className="ibox-title">
                                    <span>Add Bin</span>
                                </div>
                                <div className="ibox-content">
                                    <form className="needs-validation" noValidate="noValidate" onSubmit={event => onSubmit(event, true)}>
                                        <div className="form-row">
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Prefix<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control capital ffPoppins" onChange={(e) =>  setBins((prevBins) => ({ ...prevBins, prefix: e.target.value }))} required />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">How Many Bins<span className="text-danger">*</span></label>
                                                <input type="number" className="form-control ffPoppins" onChange={(e) =>  setBins((prevBins) => ({ ...prevBins, numberOfBins: e.target.value }))} required />
                                            </div>
                                            <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Status<span className="text-danger">*</span></label>
                                                <Select
                                                    className="ffPoppins basic-single"
                                                    options={statusType}
                                                    value={Bins.newBinStatus}
                                                    onChange={onStatusChange}
                                                />
                                            </div>
                                            <div className="form-group col-xs-12">
                                                <div className="mt-3">
                                                    <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                    <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" >Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
