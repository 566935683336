import React from 'react'
import { Footer } from '../../../layouts/Footer'
import { HeaderComp } from '../../../components/HeaderComp'

export const AboutAsWeb = () => {
  return (
    <div className="container-fluid p-0 bgWhite">
      <div className="aboutImg"
        style={{
          backgroundImage: "url(../images/sliderAbout.png)",
        }}
      >
        <HeaderComp Title={'About Us'} />
      </div>
      <div className="pb-5">
        <div className='container mt-5'>
          <div className='row mb-5'>
            <div className='col-md-5 col-sm-12 float-right'>
              <div className='col-md-11 col-sm-12 aboutUs-img-view float-right p-0'>
                <div>
                  <img src='../images/aboutUs1.png' alt='img' width='100%' height="350px" className="aboutUs-img-mrgn1" />
                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-12 mAuto mrgnTop50'>
              <h3>Why Choose Us</h3>
              <div className="redDividerSmall mb-4" />
              <p className="labelFs14">
                We are one of the few logistics companiew out there you can trust.
                Once we fake over your logistics need you can go to sleep with the assurance
                that we will deliver on our prolise. We even go extra mile many times to
                satisfy our customers.
              </p>
              <p className="labelFs14">
                If you want reliability, trust, quality and speed you have come to the right place.
                Look no further! Though our pricing is competitive we do not pro,ise cheap serveces.
                We focus on quality and an overall superier customer experience.
              </p>
            </div>
          </div>
          <div className='row' style={{ marginTop: 70 }}>
            <div className='col-md-6 col-sm-12 mAuto float-right'>
              <h3>Our Core Value</h3>
              <p className="labelFs14">
                At Cargoatlantic we value honesty, integrity, trust and continuous improvement.
                This is one of th values that are very scarce in business today, however we came
                into business because we wanted to make a difference. We know how hard it is to
                be honest with customer sometimes but we know integrity is not earned on a platter
                of gold.
              </p>
              <p className="labelFs14">
                We work hard to build trust and knowing the fragility of trust once earned, wework
                even harder to keep it. Considering the dynamism of the business environment, we
                place a high value on continuous improvement to optimize our business processes,
                increase efficiency, increase wuality of service and most importantly, enhance
                customer experience.
              </p>
            </div>
            <div className='col-md-5 col-sm-12 pull-left'>
              <div className='col-md-11 col-sm-12 aboutUs-img-view float-left p-0'>
                <img src='../images/aboutUs2.png' alt='img' width='100%' height="350px" className="aboutUs-img-mrgn2" />
              </div>
            </div>
          </div>
        </div>
        <div className='container' style={{ marginTop: 100 }}>
          <div className='row'>
            <div className='col-md-5 col-sm-12 float-right mrgnTop50'>
              <div className='col-md-11 col-sm-12 aboutUs-img-view float-right p-0'>
                <div>
                  <img src='../images/aboutUs3.png' alt='img' width='100%' height="350px" className="aboutUs-img-mrgn1" />
                </div>
              </div>
            </div>
            <div className='col-md-6 col-sm-12 mAuto mrgnTop50'>
              <h3>What We Do</h3>
              <div className="redDividerSmall mb-4" />
              <p className="labelFs14">
                We build relationships with ou customers. We strive to know our customers and
                understand their business in order to help them meet their business goals. A
                lot of our customers feel comfortable discussing their business with us becausethey
                know we have helped them grow.
              </p>
              <p className="labelFs14">
                We work with customers from different industries ranging from fashion, hospitality,
                online retail, order processing to cosmetics. We believe customer experience is key
                to return business. And that is why over 90 percent of our business is repeat business.
                We listen to our customers and we take responsibilities instead of making excuses.
              </p>
              <p className="labelFs14">
                At Cargoatlantic we know that we exist only because of our customers and we do not
                take them lightly. We would rather lose money than lose our customer. We care that much!
              </p>
            </div>
          </div>
          <div className='row' style={{ marginTop: 70 }}>
            <div className='col-md-6 col-sm-12 mAuto float-right'>
              <h3>Our Core Value</h3>
              <p className="labelFs14">
                We are committed to delivering top quality service, with time efficiency, tailored
                towards our customers' specific needs. Our vision is to provide the best shipping
                logistics while creating a sustainable superier customer experience, an excellent
                work environment, and a stable supplier relationship
              </p>
            </div>
            <div className='col-md-5 col-sm-12 pull-left'>
              <div className='col-md-11 col-sm-12 aboutUs-img-view float-left p-0'>
                <img src='../images/aboutUs4.png' alt='img' width='100%' height="350px" className="aboutUs-img-mrgn2" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  )
}
