import React, { useState } from 'react'
import { Footer } from '../../../layouts/Footer'
import { HeaderComp } from '../../../components/HeaderComp'
import parse from 'html-react-parser';

export const TermsConditionWeb = () => {
    const [genaralSettings] = useState(JSON.parse(localStorage.getItem('generalSettings')));
    return (
        <div className="container-fluid p-0 bgWhite">
            <div className="aboutImg" style={{ backgroundImage: "url(../images/sliderTermConditions.png)" }} >
                <HeaderComp Title={'Terms & Conditions'} />
            </div>
            <div className="pl-md-5 pr-md-5 pl-4 pr-4">
                <div className="pl-md-5 pr-md-5">
                    <div className="prbBtnDiv my-4">
                        <h5 className="mb-0 white text-center">Term & Conditions</h5>
                    </div>
                    <br />

                    { genaralSettings?.terms ? parse(genaralSettings?.terms) :
                    <>
                        <div className="prbBtnDiv mt-5 mb-5">
                            <h5 className="mb-0 white text-center">Definitions</h5>
                        </div>
                        <div className="">
                            <p className="inputFs14">
                                In this shipping agreement, “we”, “our”, “us”, and “Atlantic Concierge LLC” refer to Atlantic
                                Concierge LLC, Cargoatlantic, its subsidiary, branches and partners.
                            </p>
                            <p className="inputFs14">
                                “You” and “Your” refer to the shipper and its employees, principals and agents. The shipper
                                in this agreement is the person or entity who delivered the cargo to Atlantic Concierge LLC,
                                and is the person who requested that the shipment be transported, and/ or any person/entity
                                having an interest in the shipment and/or who acts as an agent of the shipper.
                            </p>
                            <p className="inputFs14">
                                “Package” means any pallet, container, envelope etc that is accepted by us for delivery, and
                                includes items tendered by you using our automated application, manifests or airway bills.
                            </p>
                            <p className="inputFs14 mb-0">
                                You undertake to make payment using only the approved payment method for your locations, and
                                agree not to use any fraudulent method to pay for your shipment. When we detect a potential
                                fraudulent payment, we reserve the right to hold such shipments for up to 7 days until we
                                have been able to fully verify the authenticity of such payment. Certain payment methods,
                                such as PayPal are permitted to be used only in certain countries. If a non authorized method
                                of payment is used in making payment to us, a $35 surcharge is imposed for such payment if
                                any issue is raised by the payment processor.
                            </p>
                        </div>
                        <div className="prbBtnDiv mt-5 mb-5">
                            <h5 className="mb-0 white text-center">Use of Site and Site Security</h5>
                        </div>
                        <div className="">
                            <p className="inputFs14">
                                In this shipping agreement, “we”, “our”, “us”, and “Atlantic Concierge LLC” refer to Atlantic
                                Concierge LLC, Cargoatlantic, its subsidiary, branches and partners.
                            </p>
                            <p className="inputFs14">
                                “You” and “Your” refer to the shipper and its employees, principals and agents. The shipper
                                in this agreement is the person or entity who delivered the cargo to Atlantic Concierge LLC,
                                and is the person who requested that the shipment be transported, and/ or any person/entity
                                having an interest in the shipment and/or who acts as an agent of the shipper.
                            </p>
                            <p className="inputFs14">
                                “Package” means any pallet, container, envelope etc that is accepted by us for delivery, and
                                includes items tendered by you using our automated application, manifests or airway bills.
                            </p>
                            <p className="inputFs14 mb-0">
                                You undertake to make payment using only the approved payment method for your locations, and
                                agree not to use any fraudulent method to pay for your shipment. When we detect a potential
                                fraudulent payment, we reserve the right to hold such shipments for up to 7 days until we
                                have been able to fully verify the authenticity of such payment. Certain payment methods,
                                such as PayPal are permitted to be used only in certain countries. If a non authorized method
                                of payment is used in making payment to us, a $35 surcharge is imposed for such payment if
                                any issue is raised by the payment processor.
                            </p>
                        </div>
                        <div className="prbBtnDiv mt-5 mb-5">
                            <h5 className="mb-0 white text-center">Additional Terms and Conditions for Our procurement Customers</h5>
                        </div>
                        <div className="mb-5">
                            <p className="inputFs14">
                                In this shipping agreement, “we”, “our”, “us”, and “Atlantic Concierge LLC” refer to Atlantic
                                Concierge LLC, Cargoatlantic, its subsidiary, branches and partners.
                            </p>
                            <p className="inputFs14">
                                “You” and “Your” refer to the shipper and its employees, principals and agents. The shipper
                                in this agreement is the person or entity who delivered the cargo to Atlantic Concierge LLC,
                                and is the person who requested that the shipment be transported, and/ or any person/entity
                                having an interest in the shipment and/or who acts as an agent of the shipper.
                            </p>
                            <p className="inputFs14">
                                “Package” means any pallet, container, envelope etc that is accepted by us for delivery, and
                                includes items tendered by you using our automated application, manifests or airway bills.
                            </p>
                            <p className="inputFs14 mb-0">
                                You undertake to make payment using only the approved payment method for your locations, and
                                agree not to use any fraudulent method to pay for your shipment. When we detect a potential
                                fraudulent payment, we reserve the right to hold such shipments for up to 7 days until we
                                have been able to fully verify the authenticity of such payment. Certain payment methods,
                                such as PayPal are permitted to be used only in certain countries. If a non authorized method
                                of payment is used in making payment to us, a $35 surcharge is imposed for such payment if
                                any issue is raised by the payment processor.
                            </p>
                        </div>
                    </>
                    } 
                </div>
            </div>
            <Footer />
        </div>
    )
}
