import { gql } from "@apollo/client";

export const ORDER_SUBSCRIBE = gql`
subscription orderChange {
  orderChange {
    keyType
    data{
      id
      trackingno
      receiverId
      binId
      binNum
      shipmentId
      receiverAddress {
        name
        address1
        address2
        phone
        city
        closestCityId{
          id
          name
        }
        stateId{
          id
          name
        }
        countryId{
          id
          name
        }
        zipCode
      }
      customerId
      customerInfo {
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      orderRateIds
      onlineStoreId {
        name
        id
      }
      orderFromSms
      invoiceId
      isInvoice
      isRecreateInvoice
      insurePer
      statusDate
      isPromoCode
      status
      items {
        id
        qty
        price
        total
        description
        rate
        instruction
        isInsure
        itemStatus
        insurerate
        insureprice        
      }
      remark
      invoiceOrReceipt
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;
