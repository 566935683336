import React, { useEffect, useState } from 'react'
import { DASHBOARD_COUNT } from '../../graphql/query/dashboardQuery'
import { useMutation, useQuery } from '@apollo/client'
import { Link, useNavigate } from 'react-router-dom'
import { GRAPH_DATA, ORDER_DASHBOARD } from '../../graphql/query/orderQuery'
import { TOTALBOX } from '../../graphql/query/packingQuery'
import { RESET_BOX } from '../../graphql/mutation/packingMutation'
import bootbox from 'bootbox'
import Highcharts from 'highcharts';
import _ from 'lodash';
import { toast } from 'react-toastify'

const Dashboard = () => {
    const navigate = useNavigate();
    const [customerCount, setCustomerCount] = useState('')
    const [orderCount, setOrderCount] = useState('')
    const [packingCount, setPackingCount] = useState('')
    const [ticketCount, setTicketCount] = useState('')
    const [recentOrder, setRecentOrder] = useState('')
    const [totalBox, setTotalBox] = useState('')
    const [resetBoxs, setResetBox] = useState('')
    const [graphDatas, setGraphData] = useState([]);

    const { data: dashboardCount } = useQuery(DASHBOARD_COUNT)
    const { data: OrderCount } = useQuery(ORDER_DASHBOARD)
    const { data: BoxCount } = useQuery(TOTALBOX)
    const { data: graphData } = useQuery(GRAPH_DATA)

    const [resetBox, { data, loading, error }] = useMutation(RESET_BOX, {
        refetchQueries: [{ query: TOTALBOX, variables: {} }],
    });

    const handleResetBox = async (e) => {
        e.preventDefault();
        bootbox.confirm({
            title: "RESET",
            message: "ARE YOU SURE WANT TO RESET BOX NO ?",
            buttons: {
                cancel: {
                    label: 'NO',
                    className: 'btn btn-sm bg-Red ffPoppins text-white'
                },
                confirm: {
                    label: 'RESET',
                    className: 'btn btn-sm bg-Green ffPoppins text-white'
                }
            },
            callback: function (result) {
                if (result) {
                    resetBox({ variables: {} }).then(async ({ data }) => {
                        toast.success("Box reseted successfully!", {
                            autoClose: 2000
                        })
                    }).catch((err) => {
                        toast.error(err.message, {
                            autoClose: 2000
                        })
                    })
                }
            }
        });
    };

    useEffect(() => {
        setCustomerCount(dashboardCount?.getDashboardCount?.customer)
        setOrderCount(dashboardCount?.getDashboardCount?.order)
        setPackingCount(dashboardCount?.getDashboardCount?.packageBox)
        setTicketCount(dashboardCount?.getDashboardCount?.ticket)
    }, [dashboardCount?.getDashboardCount])

    useEffect(() => {
        setRecentOrder(OrderCount?.getOrdersForDashBoard)
    }, [OrderCount])

    useEffect(() => {
        setTotalBox(BoxCount?.gettotalBox?.totalBox)
    }, [BoxCount])

    useEffect(() => {
        if (graphData && graphData.getMonthlyOrder && graphData.getMonthlyOrder.length > 0) {
            const monthlyData = graphData.getMonthlyOrder;
            const graph = monthlyData.map(d => ({ count: d.count, label: d._id.month }));
            setGraphData(graph);
        }
    }, [graphData]);

    useEffect(() => {
        if (graphData && graphData.getMonthlyOrder && graphData.getMonthlyOrder.length > 0) {
            const monthlyData = graphData?.getMonthlyOrder;
            const updatedCount = Array(12).fill(0);

            const graph = monthlyData.map(d => {
                updatedCount[d._id.month - 1] = d.count;
                return { count: d.count, label: d._id.month };
            });
            setGraphData(graph);
            // you should also set default value in y Axis
            // const maxCount = Math.max(...updatedCount);

            Highcharts.chart('highchart_container', {
                accessibility: {
                    enabled: false
                },
                reflow: true,
                chart: {
                    type: 'column',
                    height: 458
                },
                title: {
                    text: 'Order V/S Month'
                },
                xAxis: {
                    type: 'category',
                    crosshair: true
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'Orders Received'
                    },
                    // you should also set default value in y Axis
                    // tickPositions: Array.from({ length: Math.ceil(maxCount / 5) + 1 }, (_, i) => i * 5), 
                },
                legend: {
                    enabled: false
                },
                tooltip: {
                    headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                    pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' +
                        '<td style="padding:0"><b>{point.y:.0f}</b></td></tr>',
                    footerFormat: '</table>',
                    shared: true,
                    useHTML: true
                },
                series: [{
                    name: 'Months',
                    data: [
                        'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
                    ].map((month, index) => ({ name: month, y: updatedCount[index] })),
                }]
            });
        }
    }, [graphData]);

    const DashboardStats = [
        {
            linkTo: '/customer',
            iconSrc: '../images/userCount.png',
            bgColor: 'bg-Orange',
            borderClass: 'border-Orange',
            count: customerCount,
            title: 'Customers',
        },
        {
            linkTo: '/order',
            iconSrc: '../images/cartCountIcon.png',
            bgColor: 'bg-Green',
            borderClass: 'border-Green',
            count: orderCount,
            title: 'Orders',
        },
        {
            linkTo: '/packing',
            iconSrc: '../images/ActiveBox.png',
            bgColor: 'bg-Red',
            borderClass: 'border-Red',
            count: packingCount,
            title: 'Packing',
        },
        {
            linkTo: '/ticket',
            iconSrc: '../images/ActiveTicket.png',
            bgColor: 'bg-SkyBlue',
            borderClass: 'border-SkyBlue',
            count: ticketCount,
            title: 'Ticket',
        },
    ];
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="mb-20">
                    <p className="mainTitle m-0 mt_-15">Dashboard</p>
                    <span className="borderBottomOfTitle"></span>
                </div>
            </div>
            <div className="row p-0 clearfix mt-30 mhm-30">
                {DashboardStats.map((stat, index) => (
                    <div key={index} className="col-lg-3 col-md-3 col-sm-6 col-xs-12 p-0 mt-30">
                        <Link to={stat.linkTo}>
                            <div className={`countBox ${stat.borderClass}`}>
                                <div className="row">
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 filpCard">
                                        <div className={`IconBox rounded ${stat.bgColor}`}>
                                            <img alt="missImg" src={stat.iconSrc} className="countIconSize" />
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 mainCount ph-30">
                                        <span className="float-right counts">{stat.count}</span>
                                        <p className="d-inline-block">&nbsp;</p><br />
                                        <span className="float-right countTitle">{stat.title}</span>
                                    </div>
                                </div>
                                <div className="col-md-12 d-inline-block">
                                    <p className="viewDetail">View Details</p>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
            <div className="row mt-30">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Recent Orders</span>
                        </div>
                        <div className="ibox-content p-0">
                            <div className="table-responsive">
                                <table className="table themeTable table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th className="text-center">No.</th>
                                            <th>Tracking No.</th>
                                            <th>Name</th>
                                            <th>PMB No.</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {recentOrder && recentOrder.length ? (
                                            recentOrder.map((d, i) => {
                                                const statusMapping = {
                                                    "pending": { img: '../images/pending.png', color: '#ff3b35' },
                                                    "received": { img: '../images/Received.png', color: '#64b769' },
                                                    "admin_hold": { img: '../images/onHold.png', color: '#c31b4b' },
                                                    "customer_hold": { img: '../images/customerHold.png', color: '#9fa950' },
                                                    "shipped": { img: '../images/shipping.png', color: '#f39229' },
                                                    "refused": { img: '../images/refused.png', color: '#cbe7ff' },
                                                    "missed": { img: '../images/missed.png', color: '#337ab7' },
                                                    "packed": { img: '../images/packed.png', color: '#6b2504' },
                                                    "partial_received": { img: '../images/partial_received.png', color: '#006400' },
                                                };

                                                const { img, color } = statusMapping[d.status] || {};

                                                return (
                                                    <tr key={d.id}
                                                        onDoubleClick={() => {
                                                            navigate('/order', {
                                                                state: { id: d?.id, orderData: d }
                                                            })
                                                        }}
                                                        style={{ cursor: "pointer" }}>
                                                        <td className="text-center">{i + 1}</td>
                                                        <td>{d?.trackingno}</td>
                                                        <td>{`${d?.customerInfo?.firstname} ${d?.customerInfo?.lastname || ''}`}</td>
                                                        <td>{d?.customerInfo?.pmb_no}</td>
                                                        <td>
                                                            <span>
                                                                <img src={img} className="width18" alt="statusImg" />
                                                                <span className={`statusLabel text-${d.status}`} style={{ color }}>{d.status.charAt(0).toUpperCase() + d.status.slice(1)}</span>
                                                            </span>
                                                        </td>
                                                        <td>
                                                            <Link to='/order' state={{ id: d?.id, orderData: d }}>
                                                                <button className="btn-View">View</button>
                                                            </Link>
                                                        </td>
                                                    </tr>
                                                );
                                            })
                                        ) : (
                                            <tr>
                                                <td style={{ textAlign: 'center' }} colSpan={5} >No Records Found!</td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pr-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Order Per Month</span>
                        </div>
                        <div className="ibox-content">
                            <div id="highchart_container">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="row clearfix">
                <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 pl-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Reset Boxes</span>
                        </div>
                        <div className="ibox-content">
                            <div className="row">
                                <div className="col-md-6">
                                    <div className="row m-0 totalResetBoxes">
                                        <div className="totalResetBoxesIcon">
                                            <img src="../images/totalBox.png" className="BoxImg" alt="missImg" />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center width85"><span className="totalBoxesText">Total Box - {totalBox}</span></div>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="row m-0 totalResetBoxes borderColorRed" onClick={(e) => handleResetBox(e)}>
                                        <div className="totalResetBoxesIcon boxBackColorRed">
                                            <img src="../images/resetBox.png" className="BoxImg" alt="missImg" />
                                        </div>
                                        <div className="d-flex justify-content-center align-items-center width85">
                                            <span className="totalBoxesText">Reset Box ({totalBox}) ?</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Dashboard