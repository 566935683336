import { gql } from "@apollo/client";

export const INSERT_UPDATE_FAQs = gql`
  mutation insertUpdateFaqs($input: FaqsInput!, $log: LogInput){
    insertUpdateFaqs(input: $input, log: $log){
      id
      question
      answer
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const DELETE_FAQs = gql`
  mutation deleteFaqs($id: ID!, $log: LogInput){
    deleteFaqs(id: $id, log: $log){
      id
      question
      answer
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;