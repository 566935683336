import { gql } from "@apollo/client";

export const GET_RECEIVER_CUSTOMER = gql`
  query getReceiversOfCustomer($customerId:ID){
    getReceiversOfCustomer(customerId:$customerId){
      id
      name
      phone
      address1
      address2
      city
      zipCode
      customerId
      countryId {
        id
        name
        code
      }
      stateId {
        id
        name
      }
      closestCityId {
        id
        name
        code
      }
      createdBy
      createdAt
      updatedAt
      updatedBy
    }
  }
`;