import { useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { GET_CATEGORY } from '../../../graphql/query/CategoryQuery';
import Pagination from '../../components/Common/Pagination';
import { GetCategory } from './GetCategory';
import Loader from '../../components/Loader/Loader';
import { InsertUpdateCategory } from './InsertUpdateCategory';
import * as $ from 'jquery';

export const Category = () => {
  const genSettings = JSON.parse(localStorage.getItem('generalSettings'));

  const [category, setCategory] = useState({
    openModal: 'Add New',
    limit: genSettings?.paginationLimit || 10,
    pageNo: 0,
    filter: "",
    filterText: '',
    sortKey: 'createdAt',
    sortType: -1,
    id: null,
    categoryData: {},
    Old_Data: {},
    isLoading: false
  });

  const { data, loading ,refetch} = useQuery(GET_CATEGORY, {
    variables: { "page": category.pageNo + 1, "limit": category.limit, "filter": category?.filter, "sort": { key: category?.sortKey, type: category?.sortType } }
  })

  const AddnewCategory = (e) => {
    e.preventDefault();
    setCategory(preData => ({ ...preData, openModal: 'Add New',categoryData : {icon : '' , name : ''} }))
    $('#frm_Category').removeClass('was-validated');
    $('#CategoryModal').modal('show');
  }
  const handleSearch = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      searchCategory()
    }
  }
  const searchCategory = () => {
    setCategory(preData => ({ ...preData, pageNo: 0, filter: category?.filterText }))
  }
  const onResetSearch = () => {
    setCategory(preData => ({ ...preData, pageNo: 0, filter: "", filterText: "" }))
  }
  const handlePageClick = (data) => {
    setCategory(preData => ({ ...preData, pageNo: data?.selected }))
  };
 
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-8 p-0">
          <div className="mb-20">
            <p className="mainTitle m-0 mt_-15">Category</p>
            <span className="borderBottomOfTitle"></span>
          </div>
        </div>
        <div className="col-md-4 p-0">
          <div className="float-right">
            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => { AddnewCategory(e) }}> <i className="fa fa-plus"></i> Add New Category</button>
          </div>
        </div>
      </div>
      <div className="row mb-4">
        <div className="col-md-12 p-0">
          <div className="ibox">
            <div className="ibox-title">
              <span>Category List</span>
            </div>
            <div className="ibox-content p-0">
              {loading && <Loader />}
              <div className="col-md-12 ph-20 mt-3">
                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                    <label className="frm-label">Search</label>
                    <input type="text" className="form-control" value={category?.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setCategory(preData => ({ ...preData, filterText: e.target.value }))} />
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                    <div className="mt-30">
                      <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchCategory()} >Search</button>
                      <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                      <div className="pull-right">
                        <Pagination
                          pageCount={data?.getCategory && Math.ceil(data.getCategory?.count / category?.limit)}
                          pageNo={category?.pageNo}
                          handlePageClick={(data) => handlePageClick(data)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              
                <GetCategory category={category} setCategory={setCategory} CategoryData={data?.getCategory} refetch={refetch}/>
           
            </div>
          </div>
        </div>
      </div>

      <div className="modal show" id="CategoryModal" data-backdrop="static" tabIndex="-1" role="dialog" >
        <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
          <div className="modal-content">
            <div className="modal-body">        
              <InsertUpdateCategory category={category} setCategory={setCategory} refetch={refetch}/>
            </div>
          </div>
        </div>
      </div>

    </div>
  )
}
