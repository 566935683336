import { gql } from "@apollo/client";

export const INSERT_PACKAGEBOX = gql`
mutation insertUpdatePackageBox($input: PackageBoxInput!, $log: LogInput){
    insertUpdatePackageBox(input:$input,log: $log){
      id
      barcode
      binId
      binNum
      itemIds
      orderIds
      boxDimension{
        box_Num
        barcode
        isDimension
        boxDimensionId
        len
        width
        height
        weight
        dimensionalWeight
        cubicFeet
        rateIds
      }
      customerId
      customerInfo{
        firstname
        lastname
        email
        phone
        phone
        pmb_no
        userType
      }
      receiverId
      receiverInfo{
        name
        phone
        address1
        address2
        city
        closestCityId{
          id
          name
        }
        stateId{
          id
          name
        }
        countryId{
          id
          name
        }
        zipCode
      }
      isAssign
    }
  }
`;

export const RESET_BOX = gql`
mutation resetBox{
  resetBox
}`

export const DELETE_PACKAGEBOX = gql`
  mutation deletePackageBox($id: ID!,$orderIds:[ID],$itemIds:[ID], $log: LogInput){
    deletePackageBox(id: $id,orderIds:$orderIds,itemIds:$itemIds ,log: $log){
      id
      barcode
      binId
      binNum
      itemIds
      orderIds
      boxDimension{
        isDimension
        boxDimensionId
        len
        width
        height
        weight
        dimensionalWeight
        cubicFeet
        rateIds
      }
      customerId
      customerInfo{
        firstname
        lastname
        email
        phone
        phone
        pmb_no
        userType
      }
      receiverId
      receiverInfo{
        name
        phone
        address1
        address2
        city
        closestCityId{
          id
          name
        }
        stateId{
          id
          name
        }
        countryId{
          id
          name
        }
        zipCode
      }
      isAssign
    }
  }
`;

export const MOVE_TO_SHIPMENT_PACKAGES = gql`
  mutation moveToShipment($input: moveToShipmentInput!, $log: LogInput) {
    moveToShipment(input: $input, log: $log){
      id
      barcode
      binId
      binNum
      itemIds
      orderIds
      boxDimension{
        id
        box_Num
      }
      customerId
      customerInfo{
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      customerInfo{
        firstname
        lastname
      }
      receiverId
      receiverInfo{
        name
        phone
      }
      isAssign
      receive{
        to
        from
      }  
    }
  }
`;