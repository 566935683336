import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { AccessDenied } from '../../../components/AccessDenied';
import * as $ from 'jquery';
import { statusType } from '../../../components/Constant';
import { useQuery } from '@apollo/client';
import { GET_STATE } from '../../../../graphql/query/StateQuery';
import Loader from '../../../components/Loader/Loader';
import CountryList from '../../../components/DropdownSelect/CountrySelect';
import Pagination from '../../../components/Common/Pagination';
import { GetState } from './GetState';
import { InsertUpdateState } from './InsertUpdateState';
const initialState = {
    id: null,
    name: '',
    countryId: null,
    isActive: true,
    status: statusType[0],
}
export const State = () => {
    const location = useLocation();
    console.log("🚀 ~ State ~ location:", location)
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'))
    const user = JSON.parse(localStorage.getItem('loggedUser'))
    const [state, setStateData] = useState({
        name: '',
        isActive: true,
        countryId: null,
        filterCountryId: null,
        openModal: 'Add New',
        limit: genSettings?.paginationLimit || 10,
        pageNo: 0,
        filter: "",
        filterText: '',
        id: null,
        sortKey: 'createdAt',
        sortType: -1,
        Old_Data: {}
    });

    const { data, loading, refetch } = useQuery(GET_STATE, {
        variables: { "page": state.pageNo + 1, "limit": state.limit, "filter": state.filter, "sort": { key: state.sortKey, type: state.sortType }, "filterCountryId": state.filterCountryId },
        fetchPolicy: 'cache-and-network'
    })
    useEffect(() => {
        if (location &&  location?.state) {
            const { country } = location.state;
            if (country) {
                setStateData(prevState => ({ ...prevState, filterCountryId: country?.id, countryId: country?.id }))
            }
        }
    }, []);

    const addNewState = (e) => {
        e.preventDefault();
        setStateData(prvState => ({
            ...prvState, ...initialState,
            countryId: location.state ? location.state.country.id : "",
            openModal: 'Add New'
        }))
        $('#frm_state').removeClass('was-validated');
        $('#StateModal').modal('show');
    }
    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchStates()
        }
    }
    const searchStates = () => {
        setStateData(prevState => ({ ...prevState, pageNo: 0, filter: state.filterText }))
    }
    const onResetSearch = () => {
        setStateData(prevState => ({ ...prevState, pageNo: 0, filter: "", filterText: "", filterCountryId: null, countryId: null }))
    }
    const handlePageClick = data => {
        setStateData(prevState => ({ ...prevState, pageNo: data?.selected }))
    };

    if (user.roles === 'standard') return <AccessDenied />
    else
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 p-0">
                        <div className="mb-20">
                            <p className="mainTitle m-0 mt_-15">State</p>
                            <span className="borderBottomOfTitle"></span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="float-right">
                            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewState(e)}> <i className="fa fa-plus"></i> Add New State</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12 p-0">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span>State Details</span>
                            </div>
                            <div className="ibox-content p-0">
                                {loading && <Loader />}
                                <div className="col-md-12 ph-20 mt-3">
                                    <div className="form-row">
                                        <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Country</label>
                                            <CountryList countryId={state?.filterCountryId} getCRUDCountryId={(id) => { setStateData(prevState => ({ ...prevState, filterCountryId: id })) }} />
                                        </div>
                                        <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Search</label>
                                            <input type="text" value={state.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setStateData(prevState => ({ ...prevState, filterText: e.target.value }))} className="form-control" />
                                        </div>
                                        <div className="form-group col-lg-8 col-md-8 col-sm-6 col-xs-12">
                                            <div className="mt-30">
                                                <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchStates()} >Search</button>
                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                                <div className="pull-right">
                                                    <Pagination pageCount={data  && data?.getStates && Math.ceil(data && data?.getStates?.count / state?.limit)}
                                                        pageNo={state?.pageNo}
                                                        handlePageClick={(data) => handlePageClick(data)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <GetState state={state} setStateData={setStateData} states={data && data?.getStates} refetch={refetch} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal show" id="StateModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                        <InsertUpdateState state={state} setStateData={setStateData} refetch={refetch} initialState={initialState} />
                    </div>
                </div>

            </div>
        )
}
