import React, { useState, useEffect } from 'react';
import { useQuery, useMutation } from '@apollo/client';
import ReactPaginate from 'react-paginate';
import { toast } from 'react-toastify';
import Loader from '../../../components/Loader/Loader';
import { GET_INVOICE, UPDATE_INVOICE_STATUS } from '../../../../graphql/query/InvoiceQuery';
import InvoiceComp from '../../../components/InvoiceComp';
import { useLocation } from 'react-router-dom';
import Pagination from '../../../components/Common/Pagination';

const MyInvoice = (props) => {
  const [filterFrom, setFilterFrom] = useState(null);
  const [filterTo, setFilterTo] = useState(null);
  const [limit, setLimit] = useState(10);
  const [pageNo, setPageNo] = useState(0);
  const [filter, setFilter] = useState('');
  const [customerId, setCustomerId] = useState(JSON.parse(localStorage.getItem('customer'))?.id);
  const [tabs, setTabs] = useState('unpaid');
  const [skipUpdateInvoice, setSkipUpdateInvoice] = useState(false);
  const [skipQuery, setSkipQuery] = useState(false);
  const [invoiceData, setInvoiceData] = useState(null);
  const [packageId, setPackageId] = useState(null);

  const location = useLocation();
  useEffect(() => {
    if (location) {
      const { state } = location;
      if (state) {
        setPackageId(state.packageId);
      }
    }
  }, []);

  const _tabClick = (tabName) => {
    setTabs(tabName);
    setSkipUpdateInvoice(false);
    setSkipQuery(false);
  };

  const { loading: invoiceLoading, error: invoiceError, data: invoiceQueryData } = useQuery(GET_INVOICE, {
    skip: skipQuery,
    onCompleted: ({ getInvoice }) => { setInvoiceData(getInvoice); skipQuery(true) },
    variables: { page: pageNo + 1, limit, filter, sort: { key: 'createdAt', type: -1 }, from: filterFrom, to: filterTo, status: tabs, customerId },
    fetchPolicy: 'no-cache',
  });

  const handlePageChange = (data) => {
    setPageNo(data.selected);
    setSkipQuery(false);
  };

  return (
    <div className="container mrgnTop90 pb-5">
      <div className='text-center mb-5'>
        <h4>My Invoices</h4>
      </div>
      <div className='flex bottomBrdr'>
        <div
          onClick={() => _tabClick('unpaid')}
          className={'mr-2 pointer orderTabBtn'}
          style={{ backgroundColor: tabs === 'unpaid' ? '#DC3535' : 'transparent', position: "relative" }}
        >
          <label
            className={'pointer'}
            style={{ color: tabs === 'unpaid' ? '#fff' : '#DC3535' }}
          >Unpaid</label>
          {tabs === "unpaid" &&
            <div className="triangle-down-order" style={{ borderBlockColor: "#DC3535" }}></div>
          }
        </div>
        <div
          onClick={() => _tabClick('paid')}
          className={'pointer orderTabBtn'}
          style={{ backgroundColor: tabs === 'paid' ? '#0b429a' : 'transparent', position: "relative" }}
        >
          <label
            className={'pointer'}
            style={{ color: tabs === 'paid' ? '#fff' : '#0b429a' }}
          >paid</label>
          {tabs === "paid" &&
            <div className="triangle-down-order" style={{ borderBlockColor: "#0b429a" }}></div>
          }
        </div>
      </div>
      <div className="col-md-12 mAuto pb-2 invMain mt-5 mb-3" style={{ borderTopColor: tabs === "paid" ? "#0b429a" : "#DC3535" }}>
        <div style={{ height: 80 }}>
          <label className="headText mt-2">Invoice list</label>
        </div>
        {invoiceLoading && <Loader />}
        {invoiceData &&
          <>
            <div className="pag-right">
              <Pagination 
                pageCount={invoiceData && Math.ceil(invoiceData.count / limit)}
                pageNo={pageNo}
                handlePageClick={(data) => handlePageChange(data)} 
              />
            </div>
            <InvoiceComp
              invoice={invoiceData}
              packageId={packageId}
            />
          </>
        }
      </div>
    </div>
  );
};

export default MyInvoice;

