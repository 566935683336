import React from 'react'

export const PackigListReport = ({ reportData }) => {
    const { getPackingListReport } = reportData;
    return (
        <div className="col-md-12 no-padding d-inline-block">
            <div id="totalreceived" className="table-responsive">
                <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>DATE</th>
                            <th>CUSTOMER INFO</th>
                            <th>ITEM DESCRIPTION</th>
                            <th>QTY</th>
                        </tr>
                    </thead>
                    <tbody>
                        {getPackingListReport.length ?
                            getPackingListReport.map((report, i) => {
                                const { date, customerInfo, itemDescription, qty } = report;
                                return <tr className="odd gradeX" key={i}>
                                    <td>{i + 1}</td>
                                    <td>{new Date(date).toLocaleDateString('en-US')}</td>
                                    <td>{customerInfo.firstname + ' ' + customerInfo.lastname + ' | ' + customerInfo.pmb_no}</td>
                                    <td>{itemDescription}</td>
                                    <td align="right">{parseFloat(qty).toFixed(2)}</td>
                                </tr>
                            })
                            :
                            <tr className="odd gradeX">
                                <td colSpan="8">NO RECORD FOUND</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
    )
}
