import React, { useState, useEffect, useRef } from 'react';
import moment from 'moment';
import _ from 'lodash';
import $ from 'jquery'; // Assuming jQuery is being used in the project
import { useMutation, useQuery } from '@apollo/client'; // Assuming Apollo client is being used in the project
import { toast } from 'react-toastify'; // Assuming toast notifications are being used in the project
// import { ORDER_SUBSCRIBE } from './constants'; // Assuming ORDER_SUBSCRIBE is imported from constants file
import { GET_ORDERS, GET_ORDER_FROM_ID } from '../../../graphql/query/orderQuery';
import { ORDER_SUBSCRIBE } from '../../../graphql/subscription/OrderSubscriptions';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import DatePicker from "react-datepicker"
import Select from 'react-select';
import { OrderStatusList } from '../../components/Constant';
import AllBinsList from '../../components/DropdownSelect/AllBinsSelect';
import ReactPaginate from 'react-paginate';
import Title from '../../components/Common/Title';
import { DELETE_ORDER } from '../../../graphql/mutation/OrderMutation';
import CRUDOrder from './CRUDOrder';
import confirmDeletion from '../../components/Common/BootBox';
import Pagination from '../../components/Common/Pagination';
import { BinsInput, DateInput, SearchInput, SelectInput } from '../../components/Common/Filter';

const Orders = () => {
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'));
    const FilterStatus = JSON.parse(localStorage.getItem('orderStatus'));
    const OrderFilterStatus = JSON.parse(localStorage.getItem('orderStatusFilter'));
    const FromDate = JSON.parse(localStorage.getItem('fromDateOrder'));
    const ToDate = JSON.parse(localStorage.getItem('toDateOrder'));
    const Filter = JSON.parse(localStorage.getItem('filterOrder'));
    const binNumber = JSON.parse(localStorage.getItem('binnumber'));
    const FilterFrom = JSON.parse(localStorage.getItem('filterFrom'));
    const FilterTo = JSON.parse(localStorage.getItem('filterTo'));
    const binId = JSON.parse(localStorage.getItem('binId'));
   
    
    const linkRef = useRef(null);
    const navigate = useNavigate();
    const location = useLocation();

    const [state, setState] = useState({
        OrderModel: 'Add',
        binNum: binNumber || null,
        binId: binId || null,
        orderStatus: OrderFilterStatus || null,
        filterOrderStatus: FilterStatus || null,
        fromDate: FromDate || null,
        filterFrom: FilterFrom || null,
        filterTo: FilterTo || null,
        toDate: ToDate || null,
        limit: genSettings?.paginationLimit || 10,
        pageNo: 0,
        filter: Filter || "",
        filterText: Filter || '',
        sortKey: 'createdAt',
        sortType: -1,
        id: null,
        SelectedOrderDetailsData: {},
        modalOpen: false,
        skipQuery: false,
        orderById: null,
        skipOrderId: false,
        OrderDetailsData: null
    });
    useEffect(() => {
        localStorage.setItem('orderStatus', JSON.stringify(state.filterOrderStatus));
        localStorage.setItem('fromDateOrder', JSON.stringify(state.fromDate));
        localStorage.setItem('toDateOrder', JSON.stringify(state.toDate));
        localStorage.setItem('filterOrder', JSON.stringify(state.filter));
        localStorage.setItem('binnumber', JSON.stringify(state.binNum));
        localStorage.setItem('filterFrom', JSON.stringify(state.filterFrom));
        localStorage.setItem('filterTo', JSON.stringify(state.filterTo));
        localStorage.setItem('orderStatusFilter', JSON.stringify(state.orderStatus));
        localStorage.setItem('binId', JSON.stringify(state.binId));
      }, [state.fromDate, state.toDate, state?.filter, state?.filterOrderStatus,state?.binNum ,state?.filterFrom ,state?.filterTo ,state?.orderStatus ,state?.binId]);

    const sort = { key: state.sortKey, type: state.sortType };
    const [deleteOrder] = useMutation(DELETE_ORDER);

    const { data: OrderDetailsData, refetch } = useQuery(GET_ORDERS, {
        variables: { "page": state.pageNo + 1, "limit": state.limit, "filter": state.filter, "sort": sort, from: state?.filterFrom, to: state?.filterTo, orderStatus: state.filterOrderStatus, binNum: state.binNum },
        skip: state?.skipQuery,
        onCompleted: ({ getOrders }) => { setState(preData => ({ ...preData, OrderDetailsData: getOrders, skipQuery: true })) },
        fetchPolicy: 'no-cache'
    });
    // const {data} = useQuery(GET_ORDER_FROM_ID ,{
    //     skip : state?.skipOrderId ,
    //     variables : {id: location?.state?.id},
    //     onCompleted : ({ getOrderFromId }) => {setState(preData => ({...preData, orderById: getOrderFromId, skipOrderId: true }))}
    // });
    // const { data } = useQuery(GET_ORDER_FROM_ID, {
    //     variables: { id: location?.state?.id },
    //     skip: !location?.state?.id && state?.skipOrderId ,
    //     fetchPolicy: 'no-cache', // Fetch fresh data without cache
    //     onCompleted : ({ getOrderFromId }) => {setState(preData => ({...preData, orderById: getOrderFromId, skipOrderId: true }))}

    //   });
    useEffect(() => {
        const orderIdDeatils = state.orderById;
        if (orderIdDeatils) {
            orderDataFromId(orderIdDeatils, location?.state?.id);
        }
    },[state.orderById && location?.state?.id]);

    useEffect(() => {
        $('.react-datepicker-wrapper').addClass('d-flex')
    }, []);


    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    const fromDateChange = (date) => {
        setState(prevState => ({ ...prevState, fromDate: date, filterFrom: moment(date).format('YYYY/MM/DD'), skipQuery: false }));
    }

    const toDateChange = (date) => {
        setState(prevState => ({ ...prevState, toDate: date, filterTo: moment(date).format('YYYY/MM/DD'), skipQuery: false }));
    }

    const handleOrderStatus = (value, isToSkip) => {
        setState(prevState => ({ ...prevState, orderStatus: value, filterOrderStatus: value.value, skipQuery: isToSkip === false ? isToSkip : true }));
    }

    const handleChangeAllBins = (value) => {
        setState(prevState => ({ ...prevState, binId: value.id, binNum: value.name, skipQuery: true }));
    }

    const searchOrders = () => {
        const { filterText } = state;
        setState(prevState => ({ ...prevState, pageNo: 0, filter: filterText, skipQuery: false }));
    }

    const onResetSearch = () => {
        $('.active').removeClass('active');
        $(`.btn-outline-All`).addClass('active')
        setState(preData => ({
            ...preData,
            pageNo: 0,
            filter: "",
            filterText: "",
            orderStatus: null,
            filterOrderStatus: null,
            fromDate: null,
            filterFrom: null,
            filterTo: null,
            toDate: null,
            binNum: null,
            binId: null,
            skipQuery: false,
            OrderDetailsData: null
        }));
    }

    const sortData = (sortKey) => {
        let { sortType } = state;
        sortType = (sortType === 1) ? -1 : 1;
        setState(prevState => ({ ...prevState, sortKey: sortKey, sortType: sortType, skipQuery: false }));
    }

    const handlePageClick = data => {
        setState(prevState => ({ ...prevState, pageNo: data.selected, skipQuery: false }));
    }

    const AddNewOrder = (e) => {
        e.preventDefault();
        setState(prevState => ({ ...prevState, OrderModel: 'Add New', modalOpen: true }));
        $('#OrderModal').modal('show');
    }

    const EditOrder = (e, oldOrderDetails) => {
        e.preventDefault();
        setState(prevState => ({ ...prevState, OrderModel: 'Edit', modalOpen: true, id: oldOrderDetails?.id, SelectedOrderDetailsData: oldOrderDetails }));
        $('#OrderModal').modal('show');
    }

    const deleteOrderDetails = (e, orderId, binId) => {
        e.preventDefault();
        const onDeleteOrderAction = () => {
            const log = {
                action: 'DELETE',
                actionOn: 'orders',
                actionName: 'deleteOrder',
                oldValue: ""
            };
            deleteOrder({ variables: { "id": orderId, "binId": binId, "log": log } })
                .then(async ({ data }) => {
                    refetch();
                    $('#OrderModal').modal('hide');
                    toast.success("Order deleted successfully!", { autoClose: 2000 });
                    setState(prevState => ({ ...prevState, skipQuery: false }));
                })
                .catch((err) => {
                    toast.error(err.message, { autoClose: 2000 });
                });
        };
        confirmDeletion("Delete ORDER", "Are you sure want to delete this record ?", onDeleteOrderAction);
    };

    const toggleModal = (e) => {
        $('#OrderModal').modal('hide');
        $('body').removeClass('modal-open-delete');
        setState(prevState => ({ ...prevState, modalOpen: false, skipQuery: false, skipOrderId: false }));
    }

    const btnFilterOrderStatusWise = (e, Status, val, className) => {
        e.preventDefault();
        $('.active').removeClass('active');
        $(`.btn-outline-${className}`).addClass('active');
        handleOrderStatus({ label: Status, value: val }, false);
    }
    // const { loading, error, data } = useQuery(GET_ORDER_FROM_ID, {
    //     variables: { id: location?.state?.id },
    //     skip: state?.skipOrderId,
    //     fetchPolicy: 'no-cache',
    //     onCompleted: (data) => {
    //         // setOrderById(data.getOrderFromId);
    //         // setSkipOrderId(true);
    //         setState(prevState => ({
    //             ...prevState,
    //             orderById: data.getOrderFromId,
    //             skipOrderId: true
    //         }));
    //     }
    // });

    // useEffect(() => {
    //     if (error) {
    //         console.error(`Error! ${error.message}`);
    //     } else if (state?.orderById) {
    //         orderData(state?.orderById, location?.state?.id);
    //     }
    // }, [error, state?.orderById, location]);



    const orderDataFromId = (result, id) => {
        if (result) {
            setState(prevState => ({
                ...prevState,
                OrderModel: 'Edit',
                modalOpen: true,
                id,
                SelectedOrderDetailsData: result
            }));
            $('#OrderModal').modal('show');
            navigate('/order', { state: null })
        }
    };
    useEffect(() => {
        if (location) {
            const { state } = location;
            if (state) {
                const { id, orderData } = state;
                if (id && orderData) {
                    orderDataFromId(orderData, id);
                }
            }
        }
    }, [location]);

    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchOrders();
        }
    };

    return (
        <>
            <div className="container-fluid">
                <div className="row">
                    <Title
                        name="Orders List"
                    />
                    <div className="col-md-4 p-0">
                        <div className="float-right">
                            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => AddNewOrder(e)}> <i className="fa fa-plus"></i> Add New Order</button>
                        </div>
                    </div>
                </div>
                <div className="row  mb-3">
                    <button
                        className="btn ffPoppins btn-outline-All btn-outline-dark bg-e3e3e3 mr-2 active"
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'All', '', 'All') }}>All
                    </button>
                    <button
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'Pending', 'pending', 'Pending') }}
                        className="btn btn-outline-Pending mr-2">
                        <span>
                            <img alt="" className="width18" />
                            <span className="statusLabel">Pending</span>
                        </span></button>
                    <button
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'Received', 'received', 'Received') }}
                        className="btn btn-outline-Received mr-2">
                        <span>
                            <img alt="" className="width18" />
                            <span className="statusLabel">Received</span>
                        </span>
                    </button>
                    <button
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'Admin hold', 'admin_hold', 'onHold') }}
                        className="btn btn-outline-onHold mr-2">
                        <span>
                            <img alt="" className="width18" />
                            <span className="statusLabel">Admin Hold</span>
                        </span>
                    </button>
                    <button
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'Customer Hold', 'customer_hold', 'customerHold') }}
                        className="btn btn-outline-customerHold mr-2">
                        <span>
                            <img alt="" className="width18" />
                            <span className="statusLabel">Customer Hold</span>
                        </span>
                    </button>
                    <button
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'Shipped', 'shipped', 'ship') }}
                        className="btn btn-outline-ship mr-2">
                        <span>
                            <img alt="" className="width18" />
                            <span className="statusLabel">Shipped</span>
                        </span>
                    </button>
                    <button
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'Refused', 'refused', 'refused') }}
                        className="btn btn-outline-refused mr-2">
                        <span>
                            <img alt="" className="width18" />
                            <span className="statusLabel">Refused</span>
                        </span>
                    </button>
                    <button
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'Missed', 'missed', 'missing') }}
                        className="btn btn-outline-missing mr-2">
                        <span>
                            <img alt="" className="width18" />
                            <span className="statusLabel">Missed</span>
                        </span>
                    </button>
                    <button
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'Packed', 'packed', 'Packed') }}
                        className="btn btn-outline-Packed mr-2">
                        <span>
                            <img alt="" className="width18" />
                            <span className="statusLabel">Packed</span>
                        </span>
                    </button>
                    <button
                        onClick={(e) => { btnFilterOrderStatusWise(e, 'Partially Received', 'partial_received', 'PartialReceived') }}
                        className="btn btn-outline-PartialReceived mr-2">
                        <span>
                            <img alt="" className="width18" />
                            <span className="statusLabel">Partially Received</span>
                        </span>
                    </button>
                </div>
                <div className="row mb-4">
                    <div className="col-md-12 p-0">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span>Orders Filters</span>
                            </div>
                            <div className="ibox-content">
                                <div className="form-row">
                                    {["From", "To"].map((label, index) => (
                                        <DateInput
                                            key={index}
                                            label={label}
                                            selected={index === 0 ? state?.fromDate : state?.toDate}
                                            maxDate={new Date()}
                                            onChange={(date) => {
                                                const selectedDate = index === 0 ? date : state?.fromDate;
                                                toDateChange(index === 0 ? null : date);
                                                fromDateChange(selectedDate);
                                            }}
                                            onChangeRaw={handleDateChangeRaw}
                                            minDate={label === "To" ? state?.fromDate : null}
                                        />

                                    ))}
                                    <SelectInput
                                        label="Status"
                                        options={OrderStatusList}
                                        value={state.orderStatus}
                                        onChange={(data) => handleOrderStatus({ label: data?.label, value: data?.value }, false)}
                                    />
                                    <BinsInput
                                        label='Bin'
                                        binId={state.binId}
                                        getAllBinsInfo={(info) => handleChangeAllBins(info)}
                                    />

                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Search</label>
                                        <input type="text" className="form-control ffPoppins" value={state.filterText} onKeyDown={(e) => handleSearch(e)}
                                            onChange={(e) => setState(preData => ({ ...preData, filterText: e.target.value }))}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div className="btn-Groups mt-30">
                                            <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchOrders()} >Search</button>
                                            <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-12 p-0">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span>Orders Details</span>
                                <div className="pull-right">
                                    <Pagination
                                        pageCount={state.OrderDetailsData && Math.ceil(state.OrderDetailsData?.count / state.limit)}
                                        pageNo={state.pageNo}
                                        handlePageClick={handlePageClick}
                                    />
                                </div>
                            </div>
                            <div className="ibox-content p-0">
                                <div className="table-responsive">
                                    <table className="table themeTable table-nowrap mb-0 tbl-sort">
                                        <thead>
                                            <tr>
                                                <th className="text-center">No.</th>
                                                <th
                                                    className={`sortColumn ${(state.sortKey === 'createdAt') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('createdAt')}>Order Date</th>
                                                <th
                                                    className={`sortColumn ${(state.sortKey === 'customerInfo.firstname') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('customerInfo.firstname')}>Customer Name</th>
                                                <th
                                                    className={`sortColumn ${(state.sortKey === 'customerInfo.pmb_no') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('customerInfo.pmb_no')}>PMB No.</th>
                                                <th >Store Name</th>
                                                <th
                                                    className={`sortColumn ${(state.sortKey === 'binNum') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('binNum')}>Bins</th>
                                                <th
                                                    className={`sortColumn ${(state.sortKey === 'trackingno') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('trackingno')}>Order Tracking</th>
                                                <th
                                                    className={`sortColumn ${(state.sortKey === 'customerInfo.userType') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('customerInfo.userType')}>Type</th>
                                                <th
                                                    className={`sortColumn ${(state.sortKey === 'status') ? (state.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('status')}>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                state?.OrderDetailsData && state?.OrderDetailsData?.data && state?.OrderDetailsData?.data?.length
                                                    ? state?.OrderDetailsData?.data && state?.OrderDetailsData?.data.map((d, i) => {
                                                        let statusImg, color;
                                                        // let storeName = _.find(onlineStores, (i) => i.id === d.onlineStoreId)
                                                        if (d.status === "pending") {
                                                            statusImg = '../images/pending.png'
                                                            color = '#ff3b35'
                                                        } else if (d.status === "received") {
                                                            statusImg = '../images/Received.png'
                                                            color = '#64b769'
                                                        } else if (d.status === "admin_hold") {
                                                            statusImg = '../images/onHold.png'
                                                            color = '#c31b4b'
                                                        } else if (d.status === "customer_hold") {
                                                            statusImg = '../images/customerHold.png'
                                                            color = '#9fa950'
                                                        } else if (d.status === "shipped") {
                                                            statusImg = '../images/shipping.png'
                                                            color = '#f39229'
                                                        } else if (d.status === "refused") {
                                                            statusImg = '../images/refused.png'
                                                            color = '#cbe7ff'
                                                        } else if (d.status === "missed") {
                                                            statusImg = '../images/missed.png'
                                                            color = '#337ab7'
                                                        } else if (d.status === "packed") {
                                                            statusImg = '../images/packed.png'
                                                            color = '#6b2504'
                                                        } else if (d.status === "partial_received") {
                                                            statusImg = '../images/partial_received.png'
                                                            color = '#006400'
                                                        }
                                                        return (
                                                            <tr key={d.id} onDoubleClick={(e) => EditOrder(e, d)} style={{ cursor: "pointer" }}>
                                                                <td className=" text-center">{i + 1}</td>
                                                                <td>{moment(d.createdAt).format('MM/DD/YYYY')}</td>
                                                                <td>{d.customerInfo.firstname} {d.customerInfo.lastname && d.customerInfo.lastname}</td>
                                                                <td>{d.customerInfo.pmb_no}</td>
                                                                <td>{d.onlineStoreId?.name}</td>
                                                                <td>{d.binNum}</td>
                                                                <td>{d.trackingno || ""}</td>
                                                                <td>{d.customerInfo.userType}</td>
                                                                <td><span>
                                                                    <img src={statusImg} className="width18" alt="missImg" />
                                                                    <span className="statusLabel text-pending" style={{ color: color }}>{d.status.charAt(0).toUpperCase() + d.status.slice(1)}</span>
                                                                </span></td>
                                                                <td>
                                                                    <div className="d-flex flex-row">

                                                                        <Link ref={linkRef} to={`/track-order/${d.id}`}
                                                                            state={{ track: d.trackStatus, customerInfo: { ...d.customerInfo, trackingno: d.trackingno, orderId: d.id, orderDate: moment(d.createdAt).format('MM/DD/YYYY'), status: d.status } }}
                                                                        //  onClick={() => {
                                                                        //     navigate(`/track-order/${d.id}`, {
                                                                        //         state: { track: d.trackStatus, customerInfo: { ...d.customerInfo, trackingno: d.trackingno, orderId: d.id, orderDate: moment(d.createdAt).format('MM/DD/YYYY'), status: d.status } }
                                                                        //     })
                                                                        // }} 
                                                                        >
                                                                            <span className="btn BoxImg bg-blue rounded mr-2">
                                                                                <img src="../images/Truck.png" alt="Img" className="width15" />
                                                                            </span>
                                                                        </Link>

                                                                        <div onClick={() => {
                                                                            navigate(`/notification/${d.id}`, {
                                                                                state: {
                                                                                    customerId: d.customerId,
                                                                                    tracking_no: d.trackingno,
                                                                                    orderStatus: d.status,
                                                                                    name: (d.receiverAddress && d.receiverAddress.name) || ""
                                                                                }
                                                                            })
                                                                        }}
                                                                        ><span className="btn BoxImg bg-lightGreen rounded mr-2">
                                                                                <img src="../images/notification.png" alt="Img" className="width15" />
                                                                            </span>
                                                                        </div>

                                                                        <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => EditOrder(e, d)}>
                                                                            <img src="../images/edit.png" alt="Img" className="width15" />
                                                                        </span>
                                                                        <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteOrderDetails(e, d.id, d.binId)}>
                                                                            <img src="../images/bin.png" alt="Img" className="width15" />
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <tr>
                                                        <td style={{ textAlign: 'center' }} colSpan={10} >No Records Found!</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {
                    state.modalOpen &&
                    <CRUDOrder
                        // key={Math.random().toString()}
                        SelectedOrderDetailsData={state.SelectedOrderDetailsData}
                        OrderId={state.id}
                        modalOpen={state.modalOpen}
                        curdType={state.OrderModel}
                        closeModal={(e) => toggleModal()}
                        enableModalQuery={() => setState(preData => ({ ...preData, skipOrderId: false }))}
                    />
                }
            </div>
        </>
    );
}

export default Orders;
