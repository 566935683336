import { gql } from "@apollo/client";

export const INSERT_UPDATE_RATES = gql`
  mutation insertUpdateRates($input: RatesInput!, $log: LogInput){
    insertUpdateRates(input:$input, log: $log){
      id
      name
      amount
      amountType
      per
      rateType
      isActive
      createdBy
      updatedBy
      updatedAt
      createdAt
    }
  }
`;

export const DELETE_RATES = gql`
  mutation deleteRates($id: ID!, $log: LogInput){
    deleteRates(id: $id, log: $log){
      id
      name
      amount
      amountType
      per
      rateType
      isActive
      createdBy
      updatedBy
      updatedAt
      createdAt
    }
  }
`;
