import React from 'react';
import AppRouter from './router.js';
import withSession from './ui/components/Session/withSession';

const App = () => {
  return (
    <AppRouter/>
  )
}

export default withSession(App)

