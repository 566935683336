import { useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { GET_RECEIVER_CUSTOMER } from '../../../graphql/query/AddressQuery'
import { RECEIVER_CUSTOMER_SUBSCRIBE } from '../../../graphql/subscription/AddressSubscription'
import AddressPanel from './addressPanel'
import AddressAdd from './addressAdd'
import * as $ from 'jquery';
const GetAddressList = (props) => {
    const { isOrder, curdType, receiverId, selectedOrderAddress, openModal } = props

    const CustomerUserId = props.customerId;

    const [updateAddress, setUpdateAddress] = useState({});
    const [isUpdatedData, setIsUpdatedData] = useState(false);
    const [receiverData, setReceiverData] = useState();
    const [displayedAddresses, setDisplayeAddresses] = useState([]);

    // Fetching customer data
    const { data: customerData, refetch } = useQuery(GET_RECEIVER_CUSTOMER, {
        variables: { "customerId": props.customerId },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        $("#NewAddress").css('display', 'none');
    }, []);

    // const getReceiverCustomer = customerData?.getReceiversOfCustomer || [];
    // const  displayedAddresses = [updateAddress, ...getReceiverCustomer]?.filter(obj => Object.keys(obj).length !== 0);
    useEffect(() => {
        const getReceiverCustomer = customerData?.getReceiversOfCustomer || [];

        let displayedAddresses = [];
        if (getReceiverCustomer && getReceiverCustomer.length > 0) {
            displayedAddresses = [updateAddress, ...getReceiverCustomer]?.filter(obj => Object.keys(obj).length !== 0);
        }
        if (getReceiverCustomer?.length > 0 && isOrder && curdType !== "Edit" && !receiverId) {
            props.receiverDataInfo(getReceiverCustomer[0]);
        }
        setDisplayeAddresses(displayedAddresses);
    }, [customerData])

    const editAddress = (ReceiverData) => {
        props.AddNewAddress('Edit', ReceiverData);
        // setUpdateAddress(ReceiverData);
        setReceiverData(ReceiverData);
        if (props.sameAddress) {
            props.isupdateAddressFlag();
        }
        $("#NewAddress").css('display', 'block');
        $('#frm_Receiver').removeClass('was-validated');
    };

    const editOrderAddress = (addressData) => {
        props.AddNewAddress('Edit', addressData);
        // setUpdateAddress(addressData);
        setReceiverData(addressData);
        $("#NewAddress").css('display', 'block');
        $('#frm_Receiver').removeClass('was-validated');
    };

    return (
        <div className="row col-md-12 m-0 p-0">
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 pr-3">
                <div className="mb-4">
                    <p className="SubTitle font-weight-bold m-0">Your Existing Address</p>
                    <span className="borderBottomOfTitle width27"></span>
                </div>
                <div className="row">
                    {
                        displayedAddresses && displayedAddresses.length > 0
                            ?
                            displayedAddresses.map((d, key) => (
                                <>
                                    <AddressPanel
                                        key={key}
                                        receiverCustomerData={d}
                                        isCustomer={props.isCustomer}
                                        isOrder={props.isOrder}
                                        curdType={props.curdType}
                                        selectedOrderAddress={selectedOrderAddress}
                                        receiverDataInfo={props.receiverDataInfo}
                                        updateAddressFlag={() => props.isupdateAddressFlag()}
                                        getReceiverCustomer={displayedAddresses}
                                        receiverId={props.receiverId}
                                        refetch={refetch}
                                        deleteReceiverAddress={(id) => props.deleteAddress(id)}
                                        editReceiverAddress={(data) => editAddress(data)}
                                        editOrderAddress={(data) => editOrderAddress(data)}
                                        deleteSelectedAddress={() => props.deleteOrderAddress()}
                                        selectedReceiverData={(data) => props.receiverDataInfo(data)}
                                    />
                                </>
                            ))
                            : <div className="ph-15">
                                <span className="text-packed">No added addess yet.</span>
                            </div>
                    }
                </div>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 col-xs-12 p-0 pl-3" id="NewAddress">
                <AddressAdd
                    sameAddress={props.sameAddress}
                    CustomerDetailsData={props.CustomerDetailsData}
                    CustomerUserId={props?.customerId}
                    ReceiverData={props?.ReceiverData && props?.ReceiverData}
                    openModal={openModal}
                    isOrder={true}
                    curdType={curdType}
                    refetch={refetch}
                    receiverId={receiverId}
                    updateAddressFlag={() => props.isupdateAddressFlag()}
                    updateOrderAddress={(data) => {
                        props.updateOrderAdd(data);
                        setUpdateAddress(data)
                        setIsUpdatedData(true)
                    }}
                />
            </div>
        </div>
    )
}

export default GetAddressList
