import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const CustomerTab = () => {
  const location = useLocation();
  const [tabs, setTabs] = useState('/new-order');
  const loop = [
    { title: 'New Order', route: '/new-order', routeName: 'new-order', image: ['../images/New OrderW.png', '../images/add-file.png'] },
    { title: 'My Orders', route: '/my-order', routeName: 'my-order', image: ['../images/My OrdersW.png', '../images/shopping-cart.png'] },
    { title: 'My Shipments', route: '/my-shipment', routeName: 'my-shipment', image: ['../images/ShipmentsW.png', '../images/delivery-truck.png'] },
    { title: 'My Invoices', route: '/my-invoice', routeName: 'my-invoice', image: ['../images/invoiceW.png', '../images/invoice (1).png'] }
  ];

  const tabClick = (name) => {
    setTabs(name);
  };

  return (
    <div className="container-fluid tabScroll" style={{ paddingTop: 50 }}>
      <div className="d-flex justify-content-center">
        {loop.map((rImg) => (
          <Link key={rImg.routeName} to={`/${rImg.routeName}`}>
            <div
              onClick={() => tabClick(rImg.routeName)}
              className={`${rImg.route === location.pathname ? null : 'white-box'} webCenter order-menu`}
            >
              <div className='pointer'>
                <div>
                  <div className="text-center">
                    <img src={rImg.route === location.pathname ? rImg.image[0] : rImg.image[1]} alt="Img" className="tabImg" />
                  </div>
                </div>
              </div>
              <div className='webCenter pl-4 icon-contain'>
                <div>
                  <h6 style={{ cursor: 'pointer' }} className={`${rImg.route === location.pathname ? 'icon-heading' : 'icon-blue'} m-0`}>{rImg.title}</h6>
                </div>
              </div>
              {rImg.route === location.pathname ? <div className="triangle-down"></div> : null}
            </div>
          </Link>
        ))}
      </div>
    </div>
  );
};

export default CustomerTab;

