import React, { useEffect, useState } from 'react'
import _ from "lodash";
import { useMutation, useQuery } from '@apollo/client';
import BusyBinsSelect from '../../components/DropdownSelect/BusyBinsSelect';
import { GET_ORDERSFROMBIN } from '../../../graphql/query/packingQuery';
import FreeBinsList1 from '../../components/DropdownSelect/FreeBinsSelect';
import ReciverList from '../../components/DropdownSelect/ReceiverSelect';
import moment from 'moment';
import BoxPanel from '../../components/boxPanel';
import { generateBoxLabel } from '../../components/Constant';
import { INSERT_PACKAGEBOX } from '../../../graphql/mutation/packingMutation';
import { toast } from 'react-toastify';

const AddBox = (props) => {
    const [selectedItems, setSelectedItems] = useState([]);
    const [Boxs, setBoxs] = useState([{ id: 0, orderItem: [], selectItems: [], qty: [] }]);
    const [customerInfo, setCustomerInfo] = useState(null);
    const [orderFromBin, setOrderFromBin] = useState([]);
    const [ReceiverId, setReceiverId] = useState("");
    const [freeBinId, setFreeBinId] = useState("");
    const [freeBinNum, setFreeBinNum] = useState('')
    const [skipStatusBin, setSkipStatusBin] = useState(false);
    const [orderStatus, setOrderStatus] = useState(null);
    const [shipmentData, setShipmentData] = useState(null);
    const [orderItemId, setOrderItemId] = useState([]);
    const [skipQuery, setSkipQuery] = useState(true);
    const [isCheckAll, setIsCheckAll] = useState(false);
    const [busyBinId, setBusyBinId] = useState(null);
    const [busyBinNum, setBusyBinNum] = useState(null);
    const [searchBins, setSearchBins] = useState('')
    const [ReceiverInfo, setReceiverInfo] = useState({})
    const [check, setCheck] = useState('')


    let counter = 0;
    const { loading, orderData } = useQuery(GET_ORDERSFROMBIN, {
        skip: skipQuery,
        onCompleted: ({ getOrdersFromBin }) => {
            const OrdersFromBin = (getOrdersFromBin?.order || []).map((o) => ({
                ...o,
                items: o.items.map((item) => ({
                    ...item,
                    check: false,
                })),
            }));

            const orderItemIds = [];
            OrdersFromBin.forEach((orderBin) =>
                orderBin.items.forEach((i) => {
                    const itemIndex = orderItemIds.findIndex((unqOrder) => i.id === unqOrder.itemId);

                    if (itemIndex === -1) {
                        orderItemIds.push({
                            item: i.description,
                            qty: i.qty,
                            availableQty: i.qty,
                            itemId: i.id,
                            check: false,
                        });
                    }
                    return i;
                })
            );

            setOrderFromBin(OrdersFromBin)
            setOrderItemId(orderItemIds)
            setSkipQuery(true)
        },
        variables: { binId: busyBinId || null, curdType: 'ADD' },
        fetchPolicy: 'no-cache',
    });

    const [updatePackageBox] = useMutation(INSERT_PACKAGEBOX)

    useEffect(() => {
        if (orderData?.getOrdersFromBin?.order) {
            setOrderFromBin(orderData?.getOrdersFromBin?.order)
        }
    }, [orderData?.getOrdersFromBin?.order])

    useEffect(() => {
        if (orderFromBin.length && !ReceiverId) {
            setReceiverId(orderFromBin[0].receiverId);
        }
    }, [orderFromBin]);

    const addNewBox = (e, item) => {
        e.preventDefault();
        const updatedBoxs = [...Boxs];
        let id = 0;
        if (updatedBoxs.length) id = updatedBoxs[updatedBoxs.length - 1]?.id + 1;
        let orderItem = [];
        let qty = [];
        if (updatedBoxs.length) {
            orderItem = updatedBoxs[0].orderItem;
            // eslint-disable-next-line array-callback-return
            qty = updatedBoxs[0].qty.map((q) => {
                const matchItm = item.find((i) => i.itemId === q.itemId);
                if (matchItm !== undefined) return matchItm;
            });
        }
        updatedBoxs.push({ id, orderItem, selectItems: [], qty });

        setBoxs(updatedBoxs);
    };

    const isSelectedBoxDelete = (id) => {
        let updatedBoxs = [...Boxs];
        updatedBoxs = updatedBoxs.filter((d) => d.id !== id);
        setBoxs(updatedBoxs);
    };

    const checkBoxHandler = (orderIndex, itemIndex, description, quantity, itemId) => {
        const updatedOrderFromBin = [...orderFromBin];
        updatedOrderFromBin[orderIndex].items[itemIndex].check = !updatedOrderFromBin[orderIndex].items[itemIndex].check;

        const updatedOrderItemId = [...orderItemId];
        const orderItemIndex = updatedOrderItemId.findIndex(item => item.itemId === itemId);
        updatedOrderItemId[orderItemIndex].check = !updatedOrderItemId[orderItemIndex].check;

        const updatedBoxs = Boxs?.map(box => {
            const { id, orderItem, selectItems, qty } = box;
            return {
                ...box,
                id,
                orderItem: orderItem.map(o => o),
                selectItems: [...selectItems],
                qty: qty.map(q => q),
            };
        });

        if (updatedOrderFromBin[orderIndex].items[itemIndex].check) {
            updatedBoxs.forEach(box => {
                box.orderItem.push({ item: description, itemId });
                box.qty.push({
                    item: description,
                    qty: quantity,
                    availableQty: quantity,
                    itemId,
                });
              });
        } else {
            updatedBoxs.forEach(box => {
                box.qty = box.qty.filter(q => q.itemId !== itemId);
                box.orderItem = box.orderItem.filter(o => o.itemId !== itemId);
                box.selectItems = box.selectItems.filter(selectItem => selectItem.value !== itemId);
              });
        }
        // const updatedItems = updatedOrderFromBin[orderIndex].items.map((item) => {
        //     if (item.id === itemId) {
        //         item.check = item.check;
        //     }
        //     return item
        // }
        // )
        // const allChecked = updatedItems?.every((item) => item?.check);
        // console.log("🚀 ~ checkBoxHandler ~ allChecked:", allChecked)
        // setIsCheckAll(allChecked)
        const allItemsChecked = updatedOrderFromBin.every((order) => {
            return order.items.every((item) => item.check);
        });
        
        setIsCheckAll(allItemsChecked);
        setOrderFromBin(updatedOrderFromBin);
        setBoxs(updatedBoxs);
        setOrderItemId(updatedOrderItemId);
    };

    const handelChangeBusyBins = (value) => {
        setBusyBinId(value.id)
        setBusyBinNum(value.name)
        setSkipQuery(false)
        setReceiverId("")
    };

    const handelChangeFreeBins = (value) => {
        setFreeBinId(value.id)
        setFreeBinNum(value.name)
    }

    const reciverAddressHandler = (value) => {
        setReceiverId(value?.id)
        setReceiverInfo({
            name: value.name1,
            address1: value.address1,
            address2: value.address2,
            city: value.city,
            closestCityId: value.closestCityId,
            stateId: value.stateId,
            countryId: value.countryId,
            zipCode: value.zipCode,
            phone: value.phone,
            customerId: value.customerId,
        });
        setSkipQuery(false)
        setIsCheckAll(false);
    }

    // const boxHandler = (id, value) => {
    //     setBoxs((prevBoxs) => {
    //         const updatedBoxs = [...prevBoxs];
    //         const boxIndex = updatedBoxs.findIndex((b) => b.id === id);
    //         if (boxIndex !== -1) {
    //             updatedBoxs[boxIndex] = {
    //                 ...updatedBoxs[boxIndex],
    //                 value: value.value,
    //                 len: value.length,
    //                 width: value.width,
    //                 height: value.height,
    //                 weight: value.weight,
    //                 dimensionalWeight: value.dimensionalWeight,
    //                 cubicFeet: value.cubicFeet,
    //                 isDimension: !!value.value,
    //             };
    //         }
    //         return updatedBoxs;
    //     });
    // };
    const boxHandler = (id, value) => {
        const BoxsData = [...Boxs];
        BoxsData[id].value = value.value;
        BoxsData[id].len = value.length;
        BoxsData[id].width = value.width;
        BoxsData[id].height = value.height;
        BoxsData[id].weight = value.weight;
        BoxsData[id].dimensionalWeight = value.dimensionalWeight;
        BoxsData[id].cubicFeet = value.cubicFeet;
        BoxsData[id].isDimension = !!value.value;
        setBoxs(BoxsData);
    };

    const onBoxChangeHandler = (id, value, name, itemId = "") => {
        const updatedBoxs = [...Boxs];
        const boxIndex = updatedBoxs.findIndex((b) => b.id === id);
        if (boxIndex !== -1) {
            updatedBoxs[boxIndex][name] = value;
            const selectItmIndex = updatedBoxs[boxIndex].selectItems.findIndex(
                (item) => item.value === itemId
            );
            if (selectItmIndex !== -1) {
                updatedBoxs[boxIndex].selectItems[selectItmIndex]["qty"] = value;
            }
        }
        setBoxs(updatedBoxs);

        if (
            name === "dimensionalWeight" ||
            name === "cubicFeet" ||
            name === "len" ||
            name === "height" ||
            name === "weight" ||
            name === "width" ||
            name === "rateIds"
        ) {
            return;
        }
        const BoxsData = Boxs.map((b) => {
            const { id, orderItem, selectItems, qty } = b;
            return {
                ...b,
                id,
                orderItem: [...orderItem],
                selectItems: [...selectItems],
                qty: qty.map((q) => ({
                    availableQty: q.availableQty,
                    item: q.item,
                    qty: q.qty,
                    itemId: q.itemId,
                })),
                [name]: b[name],
            };
        });

        // eslint-disable-next-line no-unused-vars
        let incBox = 0;
        Boxs.forEach((box) => {
            if (name in box) {
                incBox += +box[name];
            }
        });
        let newAvailableQty = 0;
        BoxsData.map((b) => {
            const { id, orderItem } = b;
            const qty = [...b.qty];
            if (id >= id) {
                const isHasName = b["selectItems"].find(
                    (selectItm) => selectItm.label === name
                );
                if (isHasName !== undefined) {
                    const findQtyIndex = qty.findIndex((q) => q.itemId === itemId);
                    if (findQtyIndex !== -1) {
                        if (newAvailableQty) {
                            if (value) {
                                if (+value > newAvailableQty)
                                    qty[findQtyIndex].availableQty = -1;
                                else
                                    qty[findQtyIndex].availableQty = Math.abs(
                                        +value - newAvailableQty
                                    );
                            } else qty[findQtyIndex].availableQty = newAvailableQty;
                            newAvailableQty = qty[findQtyIndex].availableQty;
                        } else if (!newAvailableQty && value) {
                            if (+value > +qty[findQtyIndex].qty) {
                                qty[findQtyIndex].availableQty = -1;
                                newAvailableQty = qty[findQtyIndex].availableQty;
                            } else {
                                qty[findQtyIndex].availableQty =
                                    +qty[findQtyIndex].qty - +value;
                                newAvailableQty = qty[findQtyIndex].availableQty;
                            }
                        } else {
                            if (newAvailableQty)
                                qty[findQtyIndex].availableQty = newAvailableQty;
                            else qty[findQtyIndex].availableQty = +qty[findQtyIndex].qty;
                            newAvailableQty = qty[findQtyIndex].availableQty;
                        }
                    }
                }
                return {
                    ...b,
                    id,
                    orderItem: [...orderItem],
                    selectItems: b["selectItems"],
                    qty: qty,
                    [name]: b[name],
                };
            } else {
                const isItem = b["selectItems"].find(
                    (selectItm) => selectItm.label === name
                );
                if (isItem !== undefined) {
                    const findQtyIndex = qty.findIndex((q) => q.itemId === itemId);
                    if (findQtyIndex !== -1) {
                        newAvailableQty = qty[findQtyIndex].availableQty;
                    }
                }
                return {
                    ...b,
                    id,
                    orderItem: [...orderItem],
                    selectItems: b["selectItems"],
                    qty: qty,
                    [name]: b[name],
                };
            }
        });
        setBoxs(Boxs);
    };

    const selectedItemsHandler = (id, value) => {
        setBoxs((prevBoxs) => {
            const updatedBoxs = [...prevBoxs];
            const boxIndex = updatedBoxs.findIndex((b) => b.id === id);

            if (boxIndex !== -1) {
                updatedBoxs[boxIndex] = {
                    ...updatedBoxs[boxIndex],
                    selectItems: (value || []).map((v) => {
                        const selctItem = updatedBoxs[boxIndex].selectItems.find(
                            (item) => item.value === v.value
                        );

                        if (selctItem !== undefined) return selctItem;

                        return { id, label: v.label, value: v.value };
                    }),
                };
            }

            return updatedBoxs;
        });
    };

    const allSelectHandler = () => {
        let isCheckAllCheck = isCheckAll;
        let BoxsData = [...Boxs].map((box) => ({
            ...box,
            selectItems: [],
            orderItem: [],
            qty: [],
        }));
    
        const orderFromBinData = orderFromBin
            .filter((orderBin) => orderBin && orderBin?.receiverId === ReceiverId)
            .map((orderBin) => ({
                ...orderBin,
                items: orderBin.items.map((item) => {
                    if (isCheckAllCheck) {
                        BoxsData = BoxsData.map((box) => ({
                            ...box,
                            selectItems: [...box.selectItems],
                            orderItem: box.orderItem.concat({
                                item: item.description,
                                itemId: item.id,
                            }),
                            qty: box.qty.concat({
                                item: item.description,
                                qty: item.qty,
                                availableQty: item.qty,
                                itemId: item.id,
                            }),
                        }));
                    } else {
                        BoxsData = BoxsData.map((box) => {
                            const { selectItems } = box;
                            box.qty = box.qty.filter((q) => q.itemId !== item.id);
                            box.orderItem = box.orderItem.filter((o) => o.itemId !== item.id);
                            return {
                                ...box,
                                orderItem: box.orderItem,
                                qty: box.qty,
                                selectItems: [...selectItems],
                            };
                        });
                    }
                    return {
                        ...item,
                        check: orderBin.customerInfo.userType === "verified"
                            ? item.itemStatus === "received" ? !isCheckAll : false
                            : !isCheckAll,
                    };
                }),
            }));
        const orderItemIdData = [...orderItemId].map((orderItem) => ({ ...orderItem, check: !isCheckAll }));
        setIsCheckAll(!isCheckAll);
        setOrderFromBin(orderFromBinData);
        setBoxs(BoxsData);
        setOrderItemId(orderItemIdData);
    };
    const getPrinterName = (printerName) => {
        try {
            // select printer to print on
            // for simplicity sake just use the first LabelWriter printer
            var printers = window.dymo.label.framework.getPrinters();
            if (printers.length === 0)
                throw new Error("No DYMO printers are installed. Install DYMO printers.");

            var printerGetName = "";
            for (var i = 0; i < printers.length; ++i) {
                var printer = printers[i];
                //alert(printer.printerType)

                if (printer.printerType === "LabelWriterPrinter") {
                    if (printer.name === printerName) {
                        printerGetName = printer.name;
                        break;
                    }
                    // console.log(printer);

                }
            }

            return printerGetName;
        } catch (e) {
            alert(e.message || e);
        }
    }

    const submitBoxDetails = (e) => {
        e.preventDefault();
        const form = e.currentTarget;

        if (!form.checkValidity()) {
            e.stopPropagation();
            form.classList.add("was-validated");
            toast.error("Please fill the required fields...", {
                autoClose: 2000
            });
            return false;
        } else {
            let check = false;
            // eslint-disable-next-line
            for (const orderBin of orderFromBin) {
                if (check) break;
                // eslint-disable-next-line
                for (const item of orderBin.items) {
                    if (item.check) {
                        check = true;
                        break;
                    }
                }
            }
            if (!check) {
                toast.error("Please select atleast one item", {
                    autoClose: 2000
                });
                return false;
            }

            if (!Boxs?.length) {
                toast.error("Please add atleast one Box...", {
                    autoClose: 2000
                });
                return false;
            }
            const boxData = Boxs.map((box) => ({
                boxDimensionId: box?.boxDimensionId || box?.value || null,
                isDimension: box?.isDimension || false,
                len: box?.len || null,
                width: box?.width || null,
                height: box?.height || null,
                weight: box?.weight || null,
                dimensionalWeight: box?.dimensionalWeight || null,
                cubicFeet: box?.cubicFeet || null,
                rateIds: box?.rateIds || null,
                itemIds: box.selectItems.map((item) => ({
                    id: item.value,
                    qty: item.qty,
                })),
            }));

            const receiverInfoData = ReceiverInfo
            const removeTypenameAndCode = (obj) => {
                const newObj = {};
                for (const key in obj) {
                    if (key !== '__typename' && key !== 'code') {
                        if (typeof obj[key] === 'object' && obj[key] !== null) {
                            newObj[key] = removeTypenameAndCode(obj[key]);
                        } else {
                            newObj[key] = obj[key];
                        }
                    }
                }
                return newObj;
            };
            const cleanedReceiverInfo = removeTypenameAndCode(receiverInfoData);

            const customerInfo = {
                firstname: orderFromBin[0]?.customerInfo?.firstname,
                lastname: orderFromBin[0]?.customerInfo?.lastname,
                email: orderFromBin[0]?.customerInfo?.email,
                phone: orderFromBin[0]?.customerInfo?.phone,
                pmb_no: orderFromBin[0]?.customerInfo?.pmb_no,
                userType: orderFromBin[0]?.customerInfo?.userType,
            };

            let udpateObj = {
                shipmentId: shipmentData?.shipmentId,
                shipmentNo: shipmentData?.shipmentNo,
                oldBinId: busyBinId,
                oldBinNum: busyBinNum,
                binId: freeBinId || busyBinId,
                binNum: freeBinNum || busyBinNum,
                itemIds: orderItemId
                    .filter((item) => item.check)
                    .map((item) => item.itemId),
                receiverId: ReceiverId,
                receiverInfo: cleanedReceiverInfo,
                customerId: orderFromBin[0]?.customerId,
                customerInfo,
                orderIds: orderFromBin.map((order) => order.id),
                boxDimension: boxData,
            };

            let log = {
                action: "INSERT",
                actionOn: "packageboxes",
                actionName: "insertUpdatePackageBox",
                oldValue: "",
            };

            updatePackageBox({ variables: { input: udpateObj, log } }).then((res, err) => {
                form.classList.remove("was-validated");
                form.reset();
                let xmlMemo, printerName, label;
                const { receiverInfo, customerInfo } = res?.data?.insertUpdatePackageBox;
                const extractedData = res?.data?.insertUpdatePackageBox?.boxDimension.map(item => ({
                    barcode: item.barcode,
                    box_Num: item.box_Num,
                }));

                const customerName = customerInfo?.firstname + ' ' + customerInfo?.lastname;
                const receiverName = receiverInfo?.name
                const receiverCity = receiverInfo?.city

                // eslint-disable-next-line
                extractedData?.map((data, index) => {
                    xmlMemo = generateBoxLabel({ receiverInfo, customerInfo });
                    label = window.dymo.label.framework.openLabelXml(xmlMemo);
                    label.setObjectText("TEXT_2", customerName || "");
                    label.setObjectText("TEXT_3", receiverName || "");
                    label.setObjectText("TEXT_5", receiverCity || "");
                    label.setObjectText("TEXT_4", data?.box_Num?.toString() || "");
                    label.setObjectText("QRCODE", data?.barcode || "");
                    label.setObjectText("BARCODE", data?.barcode || "");
                    printerName = getPrinterName("DYMO LabelWriter 4XL");
                    if (printerName) {
                        label.print(printerName);
                    }
                })
                setSelectedItems([])
                setBoxs(null)
                setCustomerInfo(null)
                setOrderItemId([])
                setReceiverId("")
                setFreeBinId("")
                setSkipStatusBin(false)
                setOrderStatus(null)
                setShipmentData(null)
                setOrderItemId([])
                setSkipQuery(true)
                setIsCheckAll(false)
                setBusyBinId(null)
                setBusyBinNum(null)
                toast.success("Package added successfully!!", {
                    autoClose: 2000
                });
                props?.closeModal();
                setBoxs([{ id: 0, orderItem: [], selectItems: [], qty: [] }])
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    }

    const closeModal = () => {
        setSelectedItems([]);
        setBoxs([{ id: 0, orderItem: [], selectItems: [], qty: [] }]);
        setCustomerInfo(null);
        setOrderFromBin([]);
        setReceiverId("");
        setFreeBinId("");
        setSkipStatusBin(false);
        setOrderStatus(null);
        setShipmentData(null);
        setOrderItemId([]);
        setSkipQuery(true);
        setIsCheckAll(false);
        setBusyBinId(null);
        setBusyBinNum(null);
        props.closeModal();
    }

    let getOrderItemData = _.filter(orderFromBin, (d) => d?.receiverId === ReceiverId );
    return (
        <>
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">Add New Box</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button onClick={closeModal}
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>Package Details</span>
                                </div>
                                <div className="ibox-content">
                                    <form
                                        id="frm_AddBox"
                                        className="needs-validation"
                                        noValidate="noValidate"
                                        onSubmit={(e) => submitBoxDetails(e)}
                                    >
                                        <div className="form-row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Select BIN No</label>
                                                <BusyBinsSelect
                                                    binId={busyBinId}
                                                    getBusyBinsInfo={(id) => handelChangeBusyBins(id)}
                                                    selectedOrderData={busyBinId}
                                                />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                                <label className="frm-label">
                                                    Customer | PMB
                                                </label>
                                                <input
                                                    type="text"
                                                    className="form-control ffPoppins"
                                                    name="customerPMB"
                                                    placeholder="PLEASE SELECT BIN NO"
                                                    value={
                                                        orderFromBin.length
                                                            ? `${orderFromBin[0]?.customerInfo?.firstname} ${orderFromBin[0]?.customerInfo?.lastname} | ${orderFromBin[0]?.customerInfo?.pmb_no}`
                                                            : "" || ""
                                                    }
                                                    disabled
                                                />
                                            </div>
                                        </div>
                                        <div className="form-row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                                <label className="frm-label">
                                                    Choose BINS <span className="text-danger">*</span>
                                                </label>
                                                <FreeBinsList1
                                                    searchBins={searchBins}
                                                    setSearchBins={(val) => setSearchBins(val)}
                                                    limit={10}
                                                    page={1}
                                                    binId={freeBinId || busyBinId}
                                                    customerId={
                                                        orderFromBin.length
                                                            ? orderFromBin[0].customerId
                                                            : ""
                                                    }
                                                    getFreeBinsInfo={(id) =>
                                                        handelChangeFreeBins(id)
                                                    }
                                                />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">
                                                    Receiver Name
                                                    <span className="text-danger"> *</span>
                                                </label>
                                                <ReciverList
                                                    customerId={
                                                        orderFromBin[0]?.customerId || null
                                                    }
                                                    ReceiverId={
                                                        ReceiverId ||
                                                        orderFromBin[0]?.receiverId
                                                    }
                                                    getAddresschange={(info) =>
                                                        reciverAddressHandler(info)
                                                    }
                                                />
                                            </div>
                                        </div>
                                        {
                                            orderFromBin.length ? (
                                                <div className="table-responsive">
                                                    <table className="table themeTable table-nowrap mb-0">
                                                        <thead>
                                                            <tr>
                                                                <th className="text-center">
                                                                    <input
                                                                        type="checkbox"
                                                                        onChange={allSelectHandler}
                                                                        checked={isCheckAll}
                                                                        disabled={!getOrderItemData?.length}
                                                                    />
                                                                </th>
                                                                <th>No.</th>
                                                                <th>Order Date</th>
                                                                <th>Customer Mobile | Email</th>
                                                                <th>Qty</th>
                                                                <th>Instruction</th>
                                                                <th>Receiver</th>
                                                                <th>Order Item</th>
                                                                <th>Status</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {
                                                                orderFromBin.length && orderFromBin.map((o, j) => (
                                                                        o.receiverId === ReceiverId && o.items.map((d, i) => {
                                                                            const statusMapping = {
                                                                                pending: "pending",
                                                                                received: "Received",
                                                                                scamHold: "onHold",
                                                                                customerHold: "customerHold",
                                                                                shipped: "shipping",
                                                                                refuesd: "refused",
                                                                                missed: "missed",
                                                                                packed: d.isPacked && d.itemStatus !== "shipped" ? "packed" : "",
                                                                                partialRecived: "partialResived",
                                                                            };
                                                                            const statusImg = statusMapping[d.itemStatus] ? `../images/${statusMapping[d.itemStatus]}.png` : '';
                                                                            return (
                                                                                <tr key={d.itemStatus + "-" + i}>
                                                                                    {(d.itemStatus === "shipped" || (d?.isPacked === true || d?.itemStatus === "packed")) ? (
                                                                                        <td className="text-center">
                                                                                            <input type="checkbox" disabled />
                                                                                        </td>
                                                                                    ) : (
                                                                                        <td className="text-center">
                                                                                            <input
                                                                                                type="checkbox"
                                                                                                checked={d.check}
                                                                                                onChange={() => checkBoxHandler(j, i, d.description, d.qty, d.id)}
                                                                                            />
                                                                                        </td>
                                                                                    )}
                                                                                    <td>{++counter}</td>
                                                                                    <td>{moment(o?.createdAt).format("MM-DD-YYYY")}</td>
                                                                                    <td>{`${o?.receiverAddress?.phone} | ${o?.customerInfo?.email}`}</td>
                                                                                    <td>{d.qty}</td>
                                                                                    <td>{d.instruction}</td>
                                                                                    <td>{o?.receiverAddress?.name || "-"}</td>
                                                                                    <td>{d.description}</td>
                                                                                    <td>
                                                                                        {statusImg && (
                                                                                            <span>
                                                                                                <img src={statusImg} alt={d.itemStatus} className="width18" />
                                                                                                <span className={`statusLabel text-${d.itemStatus}`}>
                                                                                                    {d?.itemStatus?.charAt(0).toUpperCase() + d?.itemStatus?.slice(1)}
                                                                                                </span>
                                                                                            </span>
                                                                                        )}
                                                                                    </td>
                                                                                </tr>
                                                                            );
                                                                        })
                                                                ))
                                                            }

                                                        </tbody>
                                                    </table>
                                                </div>
                                            ) : null}
                                        <div className="col-md-12 p-0 mt-4 mb-4">
                                            <button
                                                className="btn btn-success btn-text bg-lightGreen"
                                                onClick={(e) => addNewBox(e, orderItemId)}
                                            >
                                                {" "}
                                                <i className="fa fa-plus"></i> Add More Package
                                            </button>
                                        </div>
                                        {(Boxs || []).map((b_obj, i) => {
                                            return (
                                                <div key={b_obj.id + "-" + i} className="mb-4">
                                                    <BoxPanel
                                                        id={b_obj.id}
                                                        selectedValue={b_obj}
                                                        boxChange={boxHandler}
                                                        BoxChangeHandler={onBoxChangeHandler}
                                                        isDeleteBox={(id) =>
                                                            isSelectedBoxDelete(id)
                                                        }
                                                        isEdit={props?.isEdit}
                                                        shipmentData={shipmentData}
                                                        customerInfo={customerInfo}
                                                        receiverInfo={ReceiverInfo}
                                                        selectItemsChange={selectedItemsHandler}
                                                        selectItems={b_obj.selectItems}
                                                        selectQty={b_obj.qty}
                                                        itemIds={b_obj.itemIds}
                                                    />
                                                </div>
                                            );
                                        })}
                                        <div className="form-group col-lg-4 col-md-6 col-xs-12 col-sm-8 p-0">
                                            <button
                                                type="submit"
                                                className="btn btn-primary btn-text mt-3 mr-4 border-0 bg-searchBlue"
                                            >
                                                Save
                                            </button>
                                            <button
                                                type="reset"
                                                className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed"
                                                data-dismiss="modal"
                                            // onClick={this.closeModal}
                                            >
                                                Cancel
                                            </button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default AddBox;
