import { gql } from "@apollo/client";

export const ADD_TICKET_MESSAGE = gql`
  mutation addTicketMessage($input:conversionInput!){
    addTicketMessage(input:$input){
      id
      message
      fileUpload
      sender
      receiver
      readStatus
      createdAt
      updatedAt
    }
  }
`;

export const UPDATE_TICKET = gql`
  mutation updateTicket($ticketId:ID!, $isActive:Boolean!){
    updateTicket(ticketId: $ticketId, isActive: $isActive){
      id
      ticketNo
      customerId
      conversationId
      isDeleted
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;


export const UPDATE_READSTATUS = gql`
mutation updateReadStatus($tikectIds:[ID]){
  updateReadStatus(tikectIds:$tikectIds)
}
`;


export const DELETE_TICKET = gql`
mutation deleteTicket($ticketId: ID){
  deleteTicket(ticketId:$ticketId)
}`;

export const MARK_ALLREAD = gql`
mutation updateAllAsRead($ticketId: ID){
  updateAllAsRead(ticketId:$ticketId)
}
`