import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { GET_ALL_STATE } from '../../../graphql/query/StateQuery';

const StatesList = ({ countryId, allData, getAllInfo, getCRUDStateId, getState, stateId }) => {
  const [stateList, setStateList] = useState([]);
  const [value, setValue] = useState(null);
  const [colorError, setColorError] = useState(undefined);

  const { loading, data } = useQuery(GET_ALL_STATE, {
    variables: {
      countryId: countryId
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data && data.getAllStateCountryWiseList) {
      const States = data?.getAllStateCountryWiseList;
      let StatesList = [];
      States.forEach(d => {
        StatesList.push({
          value: d.id,
          label: `${d.name}`
        })
      });
      setStateList(StatesList);
      setValue(null);
    } else {
      setStateList([]);
      setValue(null);
    }
  }, [loading, data]);

  const stateHandleChange = (selectedValue) => {
    const { getAllStateCountryWiseList } = data;
    setValue(selectedValue);
    setColorError(true);

    if (allData) {
      let findData = _.find(getAllStateCountryWiseList, d =>{ return d.id === selectedValue.value});
      getAllInfo(findData);
    } else {
      getCRUDStateId(selectedValue.value);
      if (getState)
        getState(selectedValue);
    }
  };

  const defaultStateValue = () => {
    let stateData = _.find(stateList, (d) => d.value === stateId);
    if (stateData) {
      setValue(stateData);
      setColorError(true);
    }
  };

  useEffect(() => {
    if (stateList?.length > 0) {
      defaultStateValue();
    }
  }, [stateList])

  return (
    <Select
      className="basic-single ffPoppins"
      options={stateList}
      value={value}
      onChange={(statedata) => stateHandleChange(statedata)}
      styles={{
        control: (provided, state) =>
          !colorError && colorError !== undefined
            ? {
              ...provided,
              boxShadow: "0 0 0 1px #dc3545 !important",
              border: "1px #dc3545 !important"
            }
            : provided
      }}
    />
  );
};

export default StatesList;
