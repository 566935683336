import { gql } from "@apollo/client";

export const SHIPMENT_SUBSCRIBE = gql`
subscription shipmentChange {
    shipmentChange {
    keyType
    data {
      id
      wrhsId {
        id
        name
        code
      }
      shipmentNo
      shippingDate
      estimatedDate
      totalBox
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`