import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { getMainDefinition } from '@apollo/client/utilities';
import { createClient } from 'graphql-ws';
import { setContext } from "@apollo/client/link/context";
import { API_URL, API_WS_URL } from '../config';

const authLink = setContext(async(_, { headers }) => {
  return {
    headers: {
      ...headers,
      'x-token' :  localStorage.getItem("token") || ""
    }
  }
});

const wsLink = new GraphQLWsLink(createClient({
  url: `${API_WS_URL}/graphql`,
  options: {
    reconnect: true,
  },
}));


const httpLink = new HttpLink({
  uri: `${API_URL}/graphql`
});

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query);
    return (
      definition.kind === "OperationDefinition" &&
      definition.operation === "subscription"
    );
  },
  wsLink,
  httpLink
)

const client = new ApolloClient({
  uri: `${API_URL}/graphql`,
  link: authLink.concat(splitLink),
  cache: new InMemoryCache()
});

export default client

// import { ApolloClient, HttpLink, InMemoryCache, split } from '@apollo/client'
// import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
// import { getMainDefinition } from '@apollo/client/utilities';
// import { createClient } from 'graphql-ws';
// import { setContext } from "@apollo/client/link/context";
// import { API_URL, API_WS_URL } from '../config';

// const authLink = setContext((_, { headers }) => {
//   return {
//     headers: {
//       ...headers,
//       authorization: localStorage.getItem("token") || ""
//     }
//   }
// });

// const wsLink = new GraphQLWsLink(createClient({
//   url: `${API_WS_URL}/graphql`,
//   options: {
//     reconnect: true
//   }
// }));

// const httpLink = new HttpLink({
//   uri:`${API_URL}/graphql`
// });

// const splitLink = split(
//   ({ query }) => {
//     const definition = getMainDefinition(query);
//     return (
//       definition.kind === "OperationDefinition" &&
//       definition.operation === "subscription"
//     );
//   },
//   wsLink,
//   httpLink
// )

// const client = new ApolloClient({
//   uri: `${API_URL}/graphql`,
//   link: authLink.concat(splitLink),
//   cache: new InMemoryCache()
// });

// export default client
