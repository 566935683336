import jsPDF from 'jspdf';
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { DateInput, SelectInput } from '../../components/Common/Filter';
import { useQuery } from '@apollo/client';
import CustomerList from '../../components/DropdownSelect/CustomerSelect'
import { GET_ALL_SHIPMENT } from '../../../graphql/query/shipmentQuery'
import * as $ from 'jquery';
import PreviewReport from './PreviewReport';
import Title from '../../components/Common/Title';
import moment from 'moment';


const reportType = [
    { value: 'TOTAL RECEIVED PACKAGES', label: 'TOTAL RECEIVED PACKAGES' },
    { value: 'PACKED BOXES REPORT', label: 'PACKED BOXES REPORT' },
    { value: 'CUSTOMER REPORT', label: 'CUSTOMER REPORT' },
    { value: 'PACKING LIST', label: 'PACKING LIST' },
    { value: 'CHARGES REPORT', label: 'REVENUE REPORT' },
];
const statusType = [
    { value: 'pending', label: 'Pending' },
    { value: 'received', label: 'Received' },
    { value: 'onhold', label: 'On_Hold' },
    { value: 'shipped', label: 'Shipped' },
    { value: 'refuesd', label: 'Refuesd' },
    { value: 'missed', label: 'Missed' },
    { value: 'packed', label: 'Packed' },
    { value: 'partial_received', label: 'Partial Received' },
];

const Report = () => {
    const [fromDate, setFromDate] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [selectedReportType, setSelectedReportType] = useState(null);
    const [customerName, setCustomerName] = useState(null);
    const [skipReport, setSkipReport] = useState(true);
    const [selectedStatus, setSelectedStatus] = useState(null);
    const [type, setType] = useState("Explained");
    const [skipShipment, setSkipShipment] = useState(false);
    const [shipmentNO, setShipmentNO] = useState([]);
    const [selectedShipment, setSelectedShipment] = useState(null);
    const [searchCustomer, setSearchCustomer] = useState('');
    const [customerId, setCustomerId] = useState('');
    const [orderDetailsData, setOrderDetailsData] = useState({});
    const [selectedOrderData, setSelectedOrderData] = useState({})
    const [showPreview, setShowPreview] = useState(false);

    const { data: getAllShipmentData } = useQuery(GET_ALL_SHIPMENT, {
        skip: skipShipment,
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (getAllShipmentData) {
            const shipmentList = getAllShipmentData.getAllShipment || [];
            setShipmentNO(
                shipmentList.map(shipment => ({
                    label: shipment.shipmentNo,
                    value: shipment.id,
                }))
            );
            setSkipShipment(true);
        }
    }, [getAllShipmentData]);

    useEffect(() => {
        $('.react-datepicker-wrapper').addClass('d-flex');
    }, []);

    const fromDateChange = (date) => {
        setFromDate(date);
    };

    const toDateChange = (date) => {
        setToDate(date);
    };

    const createPDF = (text) => {
        const res = new jsPDF().autoTableHtmlToJson(document.getElementById('dataTables-example'));
        const columns = res.columns;
        const data = res.data;
    
        // Determine the orientation based on the number of columns
        const orientation = columns.length > 10 ? 'landscape' : 'portrait';
        const pageSize = orientation === 'landscape' ? [2383.94, 3370.39] : 'a4'; 
    
        const doc = new jsPDF({ orientation: orientation, unit: 'pt', format: pageSize });
    
        const totalPagesExp = '{total_pages_count_string}';
        const img = new Image();
        img.src = '../../../images/smsLogo.png';
    
        doc.addImage(img, 'png', 5, 5, 35, 15);
        doc.setFontSize(16);
        doc.text(text, 65, 26);
    
        // Scale down the table to fit within the available width
        const scale = 1; // Adjust scale as needed
    
        doc.autoTable(columns, data, {
            startY: 30, // Starting y position
            styles: { fontSize: 6 * scale, lineWidth: 0.2, lineColor: '#1a1a1a' }, // Adjusted font size
            margin: { top: 30, right: 2, bottom: 10, left: 2 },
            bodyStyles: { fontSize: 6 * scale, cellWidth: 'wrap' }, // Adjust body font size
            didDrawPage: data => {
                let str = text + ' - Page ' + doc.internal.getNumberOfPages();
                if (typeof doc.putTotalPages === 'function') {
                    str = str + ' of ' + totalPagesExp;
                }
                doc.setFontSize(5);
                const pageSize = doc.internal.pageSize;
                const pageHeight = pageSize.height || pageSize.getHeight();
                doc.text(str, data.settings.margin.right, pageHeight - 10);
            }
        });
    
        if (typeof doc.putTotalPages === 'function') {
            doc.putTotalPages(totalPagesExp);
        }
    
        doc.save(`${text.split(' ').join('_')}_${moment().format("YYYY-MM-DD_HH-mm-ss")}.pdf`);
    };
    


    const handleSubmit = () => {
        if (!selectedReportType) {
            toast.error("Please select a report type.", {
                autoClose: 2000
            });
            return false;
        } else if (selectedReportType?.value === "CHARGES REPORT" && !selectedShipment) {
            toast.error("Please select a shipment No.", {
                autoClose: 2000
            });
            return false
        }
        setSkipReport(false);
        setShowPreview(true)
        return true;
    };

    const handleGetCustomerInfo = (data) => {
        let customerData = {
            firstname: data?.firstname,
            lastname: data?.lastname,
            email: data?.email,
            phone: data?.phone,
            pmb_no: data?.pmb_no,
            userType: data?.receiverType,
        };

        setOrderDetailsData({
            ...orderDetailsData,
            customerId: data?.id,
            customerInfo: customerData,
        });
        // setCustomerReceiverType(data?.receiverType);
    };

    const handleChangeCustomer = (data) => {
        if (data?.value === 'all') {
            setCustomerName(data);
            setShowPreview(false);
        } else {
            setCustomerName({ label: data?.firstname + ' ' + data?.lastname + ' | ' + data?.pmb_no, value: data?.id });
            setCustomerId(data?.id);
            setSelectedOrderData({ customerInfo: data });
            handleGetCustomerInfo(data);
            setShowPreview(false);
        }
    };
    return (
        <div className="container-fluid">
            <div className="row">
                <Title
                    name="Reports"
                />
            </div>
            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Generate Report</span>
                        </div>
                        <div className="ibox-content">
                            <div className="form-row">
                                <SelectInput
                                    label="Report Type"
                                    options={reportType}
                                    value={selectedReportType}
                                    onChange={(value) => {
                                        setSelectedReportType(value); setShowPreview(false); setCustomerName(null);
                                        setFromDate(null);
                                        setToDate(null);
                                        setSelectedStatus(null);
                                        setType("Explained");
                                        setSelectedOrderData({});
                                        setSelectedShipment(null)
                                    }}
                                    required={true}
                                    className='form-group col-lg-6 col-md-6 col-sm-12 col-xs-12'
                                />
                                <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                    <label className="frm-label">Customer Name | PMB</label>
                                    <CustomerList
                                        page={1}
                                        limit={10}
                                        searchCustomer={searchCustomer}
                                        setSearchCustomer={(val) => setSearchCustomer(val)}
                                        selectedOrderData={selectedOrderData}
                                        customerId={customerId}
                                        getCustomerInfo={(info) => handleChangeCustomer(info)}
                                        selectedReportType={selectedReportType}
                                        type={type}
                                        required
                                    />
                                </div>
                                {["From", "To"].map((label, index) => (
                                    <DateInput
                                        key={index}
                                        label={label}
                                        selected={index === 0 ? fromDate : toDate}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            const selectedDate = index === 0 ? date : fromDate;
                                            toDateChange(index === 0 ? null : date);
                                            fromDateChange(selectedDate);
                                            setShowPreview(false);
                                        }}
                                        minDate={label === "To" ? fromDate : null}
                                        className='form-group col-lg-6 col-md-6 col-sm-12 col-xs-12'
                                    />
                                ))}
                                {
                                    selectedReportType?.value !== "CUSTOMER REPORT" && selectedReportType?.value !== "CHARGES REPORT" ?
                                        <SelectInput
                                            label="Status"
                                            options={statusType}
                                            value={selectedStatus}
                                            onChange={(value) => { setSelectedStatus(value); setShowPreview(false) }}
                                            className='form-group col-lg-6 col-md-6 col-sm-12 col-xs-12'
                                        />
                                        : null
                                }
                                {
                                    (selectedReportType?.value === "PACKING LIST" || (selectedReportType?.value === "CHARGES REPORT")) ?
                                        <SelectInput
                                            label="Shipment NO"
                                            options={shipmentNO}
                                            value={selectedShipment}
                                            onChange={(value) => setSelectedShipment(value)}
                                            required={selectedReportType?.value === "CHARGES REPORT" ? true : false}
                                            className='form-group col-lg-6 col-md-6 col-sm-12 col-xs-12'
                                        />
                                        : null
                                }
                                {selectedReportType?.value !== "PACKED BOXES REPORT" && selectedReportType?.value !== "CUSTOMER REPORT" && selectedReportType?.value !== "PACKING LIST" ?
                                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">Type</label><br />
                                        <div className="form-check form-check-inline"
                                            onChange={() => { setType('Explained'); setShowPreview(false) }}
                                        >
                                            <input readOnly className="form-check-input" type="radio" name="Type" id="Explained" checked={type === "Explained" ? true : false} value="Explained" />
                                            <label className="form-check-label ffPoppins" htmlFor="Explained">Explained</label>
                                        </div>
                                        <div className="form-check form-check-inline"
                                            onChange={() => { setType('Summarized'); setShowPreview(false) }}
                                        >
                                            <input readOnly className="form-check-input" type="radio" name="Type" id="Summarized" checked={type === "Summarized" ? true : false} value="Summarized" />
                                            <label className="form-check-label ffPoppins" htmlFor="Summarized">Summarized</label>
                                        </div>
                                    </div> : null
                                }
                            </div>
                            <div className="row ph-15">
                                <div className="mb-1">
                                    <button type="submit" className="btn btn-primary btn-text border-0 bg-searchBlue mr-3" onClick={handleSubmit}>Submit</button>
                                    <button type="reset" className="btn btn-danger btn-text bg-resetRed"
                                        onClick={() => {
                                            setSelectedReportType(null);
                                            setCustomerName(null);
                                            setFromDate(null);
                                            setToDate(null);
                                            setSelectedStatus(null);
                                            setType("Explained");
                                            setSelectedOrderData({});
                                            setShowPreview(false);
                                            setSelectedShipment(null)

                                        }}
                                    >Cancel</button>
                                </div>
                            </div>
                            {showPreview && <PreviewReport setSkipReport={setSkipReport} skipReport={skipReport} selectedReportType={selectedReportType} toDate={toDate} fromDate={fromDate} selectedStatus={selectedStatus} selectedShipment={selectedShipment} type={type} createPDF={createPDF} customerName={customerName} />}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Report