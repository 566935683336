import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { GET_FREE_BINS } from '../../../graphql/query/binsQuery';
import { delayedFunction } from './utils';

const FreeBins1 = ({
    getFreeBinsInfo,
    existingBinId,
    selectedOrderData,
    binId,
    customerId,
    page,
    limit,
    searchBins,
    setSearchBins
}) => {
    const [freeBinsList, setFreeBinsList] = useState([]);
    const [colorError, setColorError] = useState(undefined);
    const [selectedValue, setSelectedValue] = useState(null);
    const [searchKey ,setSearchKey] = useState('');

    const { data } = useQuery(GET_FREE_BINS, {
        variables: {
            customerId: customerId || null,
            page: page,
            limit: limit,
            filter: searchBins,
            id: binId
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (data && data?.getFreeBinList && data?.getFreeBinList.length > 0) {
            let getFreeBins = [];
            data.getFreeBinList.forEach((d) => {
                if (!_.some(getFreeBins, { value: d.id })) {
                    getFreeBins.push({ value: d.id, label: d.bin });
                }
            });
            if (existingBinId?.existingId) {
                getFreeBins.push({
                    value: existingBinId?.existingId,
                    label: existingBinId?.existingName,
                });
            }
            setFreeBinsList(getFreeBins);
        }
    }, [data]);

    useEffect(() => {
       delayedFunction(setSearchBins(searchKey))
    },[searchKey])

    useEffect(() => {
        setDefaulBins(selectedOrderData?.binNum || binId);
    }, [selectedOrderData, binId, freeBinsList]);

    const freeBinsHandleChange = (value) => {
        setColorError(true);
        setSelectedValue(value);
        const info = {
            id: value.value,
            name: value.label,
        };
        getFreeBinsInfo(info);
    };

    const setDefaulBins = (info) => {
        if (binId && info) {
            const BinsData = _.find(freeBinsList, (d) => {return d.value === binId});
            setSelectedValue(BinsData);
        } else {
            setSelectedValue(null);
        }
    };


    return (
        <Select
            className="basic-single ffPoppins"
            options={freeBinsList}
            value={selectedValue}
            onChange={(value) => freeBinsHandleChange(value)}
            onInputChange={(value) => setSearchKey(value)  }
            styles={{
                control: (provided, state) =>
                    !colorError && colorError !== undefined
                        ? {
                            ...provided,
                            boxShadow: '0 0 0 1px #dc3545 !important',
                            border: '1px #dc3545 !important',
                        }
                        : provided,
            }}
        />
    );
};

export default FreeBins1;


