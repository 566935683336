import { useApolloClient, useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { GET_TICKET_WITH_CONVERSION, getS3SignUrl } from '../../../graphql/query/TicketQuery';
import { ADD_TICKET_MESSAGE, MARK_ALLREAD, UPDATE_READSTATUS, UPDATE_TICKET } from '../../../graphql/mutation/TicketMutation';
import Loader from '../../components/Loader/Loader';
import moment from 'moment'
import _ from 'lodash';
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import { BASE_URL } from '../../../config';
import Select from 'react-select';

const statusType = [
	{ value: 'Open', label: 'Open' },
	{ value: 'Closed', label: 'Closed' },
];
export const EditTicket = () => {
    const location = useLocation();
    const apolloClient = useApolloClient();
    const user = JSON.parse(localStorage.getItem('loggedUser'));
    const params = location && location?.state;
    const navigate = useNavigate();
    const fileInput = useRef(null);
    const [editTicket, setEditTicket] = useState({
        chatMsg: '',
        ticket: false,
        selectedFile: null,
        fileName: null,
        isLoading: false,
        ticketData: [],
        conversations: []
    });
    const { data ,refetch} = useQuery(GET_TICKET_WITH_CONVERSION, {
        variables: { "ticketId": editTicket?.ticket?.id },
        fetchPolicy: 'cache-and-network'
    });
    const [updateTicket] = useMutation(UPDATE_TICKET);
    const [addTicketMsg] = useMutation(ADD_TICKET_MESSAGE);
    const [getSignUrl] = useMutation(getS3SignUrl);

    useEffect(() => {
        if (data && data?.getTicketWithConversations) {
            if (data?.getTicketWithConversations) {
                let conversations = []
                conversations = data.getTicketWithConversations?.conversationId ? data.getTicketWithConversations?.conversationId : [];
                ticket = data?.getTicketWithConversations;
                setEditTicket(preData => ({ ...preData, ticketData: ticket, conversations: conversations }));
                scrollBottom();
            }
        }

    }, [data]);

    useEffect(() => {
        setEditTicket(preData => ({ ...preData, ticket: params?.ticket, isActive: params?.ticket.isActive }));
    }, [params && params?.ticket]);

    useEffect(() => {
        let ticket = ""
        if (params) {
            ticket = params?.ticket
            let ticketIds = []
            _.map(ticket?.conversationId, (i) => { ticketIds.push(i.id) })
            apolloClient.mutate({ mutation: UPDATE_READSTATUS, variables: { tikectIds: ticketIds }, fetchPolicy: 'no-cache' })
            window.sessionStorage.setItem('ticketData', JSON.stringify(params?.ticket))
        } else
            ticket = JSON.parse(window.sessionStorage.getItem('ticketData'))

        setEditTicket(preData => ({ ...preData, ticket: ticket, isActive: ticket?.isActive }));
    }, [params]);

    const onCancelClick = (e) => {
        e.preventDefault();
        setEditTicket(preData => ({ ...preData, isActive: ticket.ticket.isActive }));
        navigate('/ticket');
    }

    const handleStatus = (e) => {
        setEditTicket(preData => ({ ...preData, isActive: e?.value === "Closed" ? false : true }));
    }

    const onSaveClick = (e) => {
        e.preventDefault()
        const { isActive, ticket } = editTicket;
        if (isActive === ticket.isActive) {
            toast.error("no changes found!", {
                autoClose: 2000
            })
        } else {
            updateTicket({ variables: { ticketId: ticket.id, isActive: isActive } }).then((data, error) => {
                if (error) {
                    error.graphQLErrors.forEach(err => {
                        toast.error(err.message, {
                            autoClose: 2000
                        })
                    });
                } else {
                    refetch();
                    toast.success("Ticket updated successfully!", {
                        autoClose: 2000
                    })
                    navigate('/ticket');
                }
            });
        }
    }
    const scrollBottom = () => {
        setTimeout(() => {
            $("#ChatMsgBox").animate({ scrollTop: $("#ChatMsgBox").prop("scrollHeight") });
        }, 100);
    }
    const _onFile = () => {
        fileInput.current.click();
    }
    const toggleModal = () => {
        setEditTicket(preData => ({ ...preData, selectedFile: null }));
    }
    const onMessageSentClick = (e) => {
        e.preventDefault()
        const { chatMsg, ticket, selectedFile, fileName } = editTicket;
        if ((chatMsg?.trim() !== ''  && chatMsg.length > 0) || selectedFile) {
            const input = {
                "input": {
                    "ticketId": ticket?.id,
                    "message": chatMsg || fileName,
                    "fileName": fileName,
                    "fileUpload": selectedFile,
                    "sender": user?.id,
                    "receiver": ticket?.customerId?.id
                }
            }
            addTicketMsg({ variables: input }).then((data, error) => {
                if (error) {
                    error.graphQLErrors.forEach(err => {
                        toast.error(err.message, {
                            autoClose: 2000
                        })
                    });
                } else {
                    refetch();
                    setEditTicket(preData => ({ ...preData, chatMsg: '', selectedFile: null, fileName: null }));
                    toast.success("Message sent!", {
                        autoClose: 2000
                    });
                }
            });
        } else {
            toast.error("please, write message first!", {
                autoClose: 2000
            })
        }
    }
    const onFileUpload = (event) => {
        const { target: { validity, files: [file], } } = event;
        if (validity.valid) {
            const fileExtension = file?.type.split("/")[1];
            const key = `chatBoxData_${+new Date()}.${fileExtension}`;
            setEditTicket(preData => ({ ...preData, isLoading: true }));

            getSignUrl({ variables: { type: key } }).then((data) => {
                if (data?.data?.getPreSignedUrl) {
                    fetch(data?.data?.getPreSignedUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": file?.type,
                        },
                        body: file,
                    }).then((response) => {
                        if (response?.ok && response?.status === 200) {
                            setEditTicket(preData => ({
                                ...preData, selectedFile: `${BASE_URL}/${key}`,
                                fileName: file?.name,
                                isLoading: false
                            }));

                        } else {
                            setEditTicket(preData => ({ ...preData, isLoading: false }));
                        }
                    }).catch((errr) => {
                        setEditTicket(preData => ({ ...preData, isLoading: false }));
                    });
                } else {
                    setEditTicket(preData => ({ ...preData, isLoading: false }));
                }
            }).catch((err) => {
                setEditTicket(preData => ({ ...preData, isLoading: false }));
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    }
    let { ticket, isActive, chatMsg, selectedFile, isLoading } = editTicket;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="mb-20">
                    <p className="mainTitle m-0 mt_-15">Edit Tickets</p>
                    <span className="borderBottomOfTitle"></span>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-lg-6 col-md-12 col-sm-12 col-xs-12 pl-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Tickets Details</span>
                        </div>
                        <div className="ibox-content">
                            <form >
                                <div className="form-row">
                                    <div className="form-group col-lg-6 col-md-6 col-sm-612 col-xs-12 ">
                                        <label className="frm-label">Ticket No</label>
                                        <input type="text" className="form-control ffPoppins" defaultValue={ticket?.ticketNo} disabled />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-612 col-xs-12 ">
                                        <label className="frm-label">Date</label>
                                        <input type="text" className="form-control ffPoppins" defaultValue={moment(ticket?.createdAt).format('MM/DD/YYYY hh:mm a')} disabled />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-612 col-xs-12 ">
                                        <label className="frm-label">First Name</label>
                                        <input type="text" className="form-control ffPoppins" defaultValue={ticket?.customerId?.firstName} disabled />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-612 col-xs-12 ">
                                        <label className="frm-label">Last Name</label>
                                        <input type="text" className="form-control ffPoppins" defaultValue={ticket?.customerId?.lastName} disabled />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-612 col-xs-12 ">
                                        <label className="frm-label">PMB No</label>
                                        <input type="text" className="form-control ffPoppins" defaultValue={ticket?.customerId?.pmbNo} disabled />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-612 col-xs-12 ">
                                        <label className="frm-label">City</label>
                                        <input type="text" className="form-control ffPoppins" defaultValue={ticket?.customerId?.city} disabled />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-612 col-xs-12 ">
                                        <label className="frm-label">Mobile</label>
                                        <input type="text" className="form-control ffPoppins" defaultValue={ticket?.customerId?.phone} disabled />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-612 col-xs-12 ">
                                        <label className="frm-label">Email</label>
                                        <input type="email" className="form-control ffPoppins" defaultValue={ticket?.customerId?.email} disabled />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-612 col-xs-12 ">
                                        <label className="frm-label">Status</label>
                                        <Select className="ffPoppins"
                                            value={isActive ? statusType[0] : statusType[1]}
                                            options={statusType}
                                            onChange={(data) => { handleStatus(data) }}
                                        />
                                    </div>
                                    <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <div className="mt-3">
                                            <button className="btn btn-primary btn-text border-0 bg-searchBlue mr-3" onClick={(e) => onSaveClick(e)} >Save</button>
                                            <button className="btn btn-danger btn-text bg-resetRed" onClick={(e) => onCancelClick(e)} >Cancel</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="col-lg-6 col-md-6  col-sm-12 col-xs-12 pr-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Chat</span>
                        </div>
                        {
                            selectedFile ?
                                <div className='ibox-content p-0'>
                                    {ticket?.isActive ? <div className="input-group mb-2 ph-20 " style={{ justifyContent: 'end', position: 'inherit' }}>
                                        <div className='col-md-12 '>

                                            <div className='image-preview-img-wrap mx-auto ' style={{ minHeight: "455px" }}>
                                                <button type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}
                                                    onClick={(e) => { toggleModal(e) }} >
                                                    <span aria-hidden="true">&times;</span>
                                                </button>
                                                <img src={selectedFile} alt="selectedFile" style={{ maxWidth: '300px', maxHeight: '300px', marginTop: '10px' }} />
                                            </div>

                                            <div className="d-flex">
                                                <input type="text"
                                                    className="form-control bg-white rounded ffPoppins"
                                                    value={chatMsg}
                                                    onChange={(e) => { setEditTicket(preData => ({ ...preData, chatMsg: e.target.value })) }}
                                                    placeholder="Type your comment here..."
                                                    onKeyDown={(e) => e.key === "Enter" ? onMessageSentClick(e) : ''}
                                                />
                                                <button
                                                    className="btn BoxImg bg-searchBlue rounded HW35 ml-2"
                                                    onClick={(e) => { onMessageSentClick(e) }}>
                                                    <img src="./images/send.png" alt="Img" className="width15" />
                                                </button>
                                            </div>
                                        </div>
                                    </div> : <p className="inputFs14 text-center mt-3">This ticket is closed.</p>}

                                </div>

                                :
                                <div className="ibox-content p-0">
                                    {(isLoading) && <Loader />}
                                    <div className="mainChatBox pt-3 " id="ChatMsgBox">
                                        {editTicket?.conversations?.map((msg, i) => {
                                            if (msg?.sender === ticket?.customerId?.id) {
                                                return (
                                                    <div key={msg?.id} className="msg-left ph-20 mb-3">
                                                        <span className="SubTitle d-flex justify-content-start align-items-center">
                                                            <i className="fa fa-circle" style={{ color: 'gray' }}></i>
                                                            <span className="ml-2 mr-2">{ticket?.customerId?.firstName} {ticket?.customerId?.lastName}</span>
                                                            <span className="chatTime">{moment(msg?.createdAt).calendar()}</span>
                                                        </span>
                                                        {msg?.fileUpload ?
                                                            <div className="chatBox bg-Peach msg-image-div msgMarginRight" onClick={() => window.open(msg.fileUpload, '_blank')} >
                                                                <img src={msg?.fileUpload} alt="uploaded a file" className="msg-image" data-toggle="dropdown"></img>
                                                                <span>{msg?.message}</span>
                                                            </div>
                                                            :
                                                            <div className="chatBox bg-Peach msgMarginRight">
                                                                <span>{msg?.message}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            } else {
                                                return (
                                                    <div key={msg?.id} className="msg-right ph-20 mb-3">
                                                        <span className="SubTitle d-flex justify-content-end align-items-center">
                                                            <span className="chatTime">{moment(msg.createdAt).calendar()}</span>
                                                            <span className="ml-2 mr-2">Admin</span>
                                                            <i className="fa fa-circle" style={{ color: "#7eaefb" }}></i>
                                                        </span>
                                                        {msg?.fileUpload ?
                                                            <div className="chatBox bg-sender msg-image-div msgMarginLeft" onClick={() => window.open(msg.fileUpload, '_blank')} >
                                                                <img src={msg?.fileUpload} alt="uploaded a file" className="msg-image" data-toggle="dropdown"></img>
                                                                <span>{msg?.message}</span>
                                                            </div>
                                                            :
                                                            <div className="chatBox bg-sender msgMarginLeft">
                                                                <span>{msg?.message}</span>
                                                            </div>
                                                        }
                                                    </div>
                                                )
                                            }
                                        })}


                                    </div>
                                    {ticket?.isActive ?
                                        <div className="input-group mb-2 ph-20" style={{ justifyContent: 'end', position: 'inherit' }}>

                                            <>
                                                <button onClick={() => _onFile()} style={{ marginRight: '10px', border: '0px' }} >
                                                    <img src="./images/attach.png" alt="Img" width="15px" />
                                                    <input type="file" ref={fileInput}
                                                        style={{ display: 'none' }}
                                                        onChange={(e) => onFileUpload(e)} />
                                                </button>
                                                <input type="text"
                                                    className="form-control bg-white rounded ffPoppins"
                                                    value={chatMsg}
                                                    onChange={(e) => { setEditTicket(preData => ({ ...preData, chatMsg: e.target.value })) }}
                                                    placeholder="Type your comment here..."
                                                    onKeyDown={(e) => e.key === "Enter" ? onMessageSentClick(e) : ''}
                                                />
                                                <button
                                                    className="btn BoxImg bg-searchBlue rounded HW35 ml-2"
                                                    onClick={(e) => { onMessageSentClick(e); }}>
                                                    <img src="./images/send.png" alt="Img" className="width15" />
                                                </button>
                                            </>

                                        </div> : <p className="inputFs14 text-center mt-3">This ticket is closed.</p>

                                    }
                                </div>
                        }

                    </div>
                </div>
            </div>

        </div >
    )
}
