import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import Select from 'react-select';
import { statusType, receivingServices, deliveryServices } from '../../components/Constant';
import { BASE_URL } from '../../../config';
import Loader from '../../components/Loader/Loader';
import { toast } from 'react-toastify';
import { getS3SignUrl } from '../../../graphql/mutation/CustomerMutation';
import moment from 'moment';
import parse from 'html-react-parser';
import axios from 'axios';
import html2pdf from 'html2pdf.js';

const CustomerDetails = (props) => {
  const { customerDetailsData, curdType } = props;
  const [receivingServiceType, setReceivingServiceType] = useState(receivingServices[0]);
  const [deliveryServiceType, setDeliveryServiceType] = useState(deliveryServices[0]);
  const [isActive, setIsActive] = useState(statusType[0]);
  const [tempIdProof, setTempIdProof] = useState(null);
  const [tempSignature, setTempSignature] = useState(null);
  const [signature, setSignature] = useState(null);
  const [loading, setLoading] = useState(false);
  const genralSettings = JSON.parse(localStorage.getItem('generalSettings'));

  useEffect(() => {
    if (curdType === "Edit" && customerDetailsData) {
      setReceivingServiceType(customerDetailsData?.receivingServiceType === "standard" ? receivingServices[0] : receivingServices[1]);
      setDeliveryServiceType(customerDetailsData?.deliveryServiceType === "DTD" ? deliveryServices[0] : deliveryServices[1]);
      setIsActive(customerDetailsData?.isActive ? statusType[0] : statusType[1]);
      setTempIdProof(customerDetailsData.idProof);
      setTempSignature(customerDetailsData.signature ? customerDetailsData.signature : null);
    }
  }, [curdType, customerDetailsData]);

  const [idProofMutation] = useMutation(getS3SignUrl);
  const handleReceiveServiceType = (data) => {
    setReceivingServiceType(data);
    props.ReceivingServiceType(data);
  }

  const handleChangeDelivery = (data) => {
    setDeliveryServiceType(data);
    props.DeliveryServiceType(data);
  }

  const handleChangeStatus = (data) => {
    setIsActive(data);
    props.StatusServiceType(data);
  }

  const idProofHandler = async (event) => {
    const { target: { validity, files: [file], } } = event;
    if (validity.valid) {
      const fileExtension = file.type.split("/")[1];
      const key = `idProof_${+new Date()}.${fileExtension}`;
      setLoading(true);
      try {
        const { data } = await idProofMutation({ variables: { type: key } });
        if (data?.getPreSignedUrl) {
          const response = await fetch(data.getPreSignedUrl, {
            method: "PUT",
            headers: { "Content-Type": file.type },
            body: file,
          });
          if (response?.ok && response?.status === 200) {
            setTempIdProof(`${BASE_URL}/${key}`);
            setLoading(false);
            props.CustomerIdProof(`${BASE_URL}/${key}`);
          } else {
            setLoading(false);
          }
        } else {
          setLoading(false);
        }
      } catch (err) {
        setLoading(false);
        toast.error(err.message, { autoClose: 2000 });
      }
    }
  }

  const deleteIdProof = () => {
    setTempIdProof(null);
    props.CustomerIdProof(null);
  }

  const deleteSignature = () => {
    setSignature(null);
    setTempSignature(null);
    props.CustomerSignature(null);
  }

  const [signatureMutation] = useMutation(getS3SignUrl);

  const signatureHandler = async (event) => {
    const { target: { validity, files: [file], } } = event;
    if (validity.valid) {
      const fileExtension = file.type.split("/")[1];
      const key = `signature_${+new Date()}.${fileExtension}`;
      setLoading(true);
      signatureMutation({ variables: { type: key } }).then((data) => {
        if (data?.data?.getPreSignedUrl) {
          fetch(data?.data?.getPreSignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file?.type,
            },
            body: file,
          }).then((response) => {
            if (response?.ok && response?.status === 200) {
              setSignature(`${BASE_URL}/${key}`);
              setTempSignature(`${BASE_URL}/${key}`);
              setLoading(false);
              props.CustomerSignature(`${BASE_URL}/${key}`);
            } else {
                setLoading(false);
            }
          }).catch((errr) => {
              setLoading(false);
          });
        } else {
            setLoading(false);
        }
      }).catch((err) => {
        setLoading(false);
        toast.error(err.message, {
          autoClose: 2000
        })
      })
    }
  }

  const aggrement = async (date, temp_signature) => {
    let aggrementImageTag = '';
    if (temp_signature) {
      try {
        const response = await axios.get(temp_signature, { responseType: 'arraybuffer' });
        const base64Image = Buffer.from(response?.data, 'binary').toString('base64');
        aggrementImageTag = `<img src="data:image/png;base64,${base64Image}" style="width: 100px; height: auto; display: block; margin-bottom: 20px;" />`;
      } catch (error) {
        console.error('Error fetching signature image:', error);
      }
    }

    const contentElement = document.getElementById('contentToConvert');
    const originalContent = contentElement.innerHTML;

    const insertIndex = originalContent.indexOf('<p>Name:');

    if (insertIndex !== -1) {
      contentElement.innerHTML = originalContent.slice(0, insertIndex) + aggrementImageTag + originalContent.slice(insertIndex);
    } else {
      contentElement.innerHTML = originalContent + aggrementImageTag;
    }

    const objstr = contentElement.innerHTML;

    if (objstr) {
      const pdfOptions = {
        margin: 10,
        filename: `aggrement-${moment(date).format('MMDDYY')}.pdf`,
        image: { type: 'jpeg' },
      };
      html2pdf().set(pdfOptions).from(objstr).save();
    }

    contentElement.innerHTML = originalContent;
  };


  const downloadImage = async (temp_signature) => {
    if (temp_signature) {
      try {
        const response = await axios.get(temp_signature, { responseType: 'blob' });
        const blob = new Blob([response.data], { type: response.headers['content-type'] });

        const link = document.createElement('a');
        link.href = URL.createObjectURL(blob);
        link.download = `${props?.customerDetailsData?.firstName} ${props?.customerDetailsData?.lastName}.png`;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading image:', error);
      }
    }
  };

  const TextChangeHandler = (e) => {
    props.handleText(e)
  }


  // input box and it's field name
  const fields = [
    { label: "PMB Number", name: "PMBno", type: "text", disabled: true, value: props?.customerDetailsData && props?.customerDetailsData?.pmbNo },
    { label: "Company Name", name: "companyName", type: "text", value: props?.customerDetailsData && props?.customerDetailsData?.companyName },
    { label: "First Name", name: "firstName", type: "text", required: true, value: props?.customerDetailsData && props?.customerDetailsData?.firstName },
    { label: "Last Name", name: "lastName", type: "text", required: true, value: props?.customerDetailsData && props?.customerDetailsData?.lastName },
    { label: "Email", name: "email", type: "email", disabled: props.curdType !== "Add New", value: props?.customerDetailsData && props?.customerDetailsData?.email, required: true, },
    { label: "Phone", name: "phone", type: "number", minLength: 10, maxLength: 12, required: true, value: props?.customerDetailsData && props?.customerDetailsData?.phone },
  ];
  if (props.curdType === "Add New") {
    fields.push(
      { label: "Password", name: "password", type: "password", condition: props.curdType === "Add New", value: props?.customerDetailsData && props?.customerDetailsData?.password, required: true, },
      { label: "Confirm Password", name: "confirmPassword", type: "password", condition: props.curdType === "Add New", value: props?.customerDetailsData && props?.customerDetailsData?.confirmPassword, required: true, }
    );
  }

  const renderUploadSection = (title, handler, tempData, deleteHandler, isSignature = false) => (
    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
      <label className="frm-label">{title}</label><br />
      <span className="btn btn-default btn-file btn-sm btn-danger ffPoppins">
        <i className="fa fa-upload"></i> Choose a File
        <input type="file" onChange={(e) => handler(e)} />
      </span>
      {!tempData && <><br /><span className="notes-Danger">{isSignature ? "Signature" : "ID Card"} Not Uploaded !</span></>}
      {tempData && (
        <div>
          <br />
          <div className="form-row">
            <img src={tempData} alt="missImg" className="width200 mr-5" id="image" />
            {props.curdType === "Edit" && (
              <>
                <span className="btn BoxImg bg-skyBlue rounded mr-2">
                  <img
                    src="../images/download.png"
                    alt="Img"
                    className="width15"
                    onClick={() => downloadImage(tempData)}
                  />
                </span>
                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={deleteHandler}>
                  <img src="../images/bin.png" alt="Img" className="width15" />
                </span>
              </>
            )}
          </div>
        </div>
      )}
    </div>
  );

  const FormSelect = ({ label, options, value, onChange }) => (
    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
      <label className="frm-label">{label}<span className="text-danger">*</span></label>
      <Select
        className="ffPoppins"
        options={options}
        value={value}
        onChange={onChange}
      />
    </div>
  );


  return (
    <div className="col-md-12 p-0">
      <div className="ibox">
        <div className="ibox-title">
          <span>Customer Details</span>
        </div>
        <div className="ibox-content">
          <div className="form-row">
            {loading && <Loader />}
            {fields.map(field => (
              <div key={field.name} className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                <label className="frm-label">{field.label}{field.required && <span className="text-danger">*</span>}</label>
                <input
                  type={field.type}
                  className={`form-control ${field.type === 'password' ? 'ffPoppins' : ''}`}
                  name={field.name}
                  defaultValue={field.value}
                  disabled={field.disabled}
                  minLength={field.minLength}
                  maxLength={field.maxLength}
                  onChange={e => TextChangeHandler(e)}
                  required={field.required}
                />
              </div>
            ))}
            <FormSelect
              label="Receiving Service Type"
              options={receivingServices}
              value={receivingServiceType}
              onChange={(data) => handleReceiveServiceType(data)}
            />
            <FormSelect
              label="Delivery Service Type"
              options={deliveryServices}
              value={deliveryServiceType}
              onChange={(data) => handleChangeDelivery(data)}
            />
            <FormSelect
              label="Status"
              options={statusType}
              value={isActive}
              onChange={(data) => handleChangeStatus(data)}
            />
          </div>
          <div className="form-row">
          {renderUploadSection("Upload Your Id Card", idProofHandler, tempIdProof, deleteIdProof)}
          {renderUploadSection("Signature", signatureHandler, tempSignature, deleteSignature, true)}
            {
              ((props.curdType === "Edit") && (tempSignature || signature)) &&
              <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                <label className="frm-label">Aggrement</label><br />
                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => aggrement(props?.customerDetailsData?.createdAt, tempSignature)}>
                  <img src="../images/download.png" alt="Img" className="width15" />
                </span>
                <div id="contentToConvert" style={{ display: 'none' }}>
                  {
                    genralSettings?.orderReceiptAuth ? parse(genralSettings?.orderReceiptAuth) : " "
                  }
                  <p>Name: {props?.customerDetailsData?.firstName?.toUpperCase()} {props?.customerDetailsData?.lastName?.toUpperCase()}</p>
                  <p>Date: {moment(props?.customerDetailsData?.createdAt).format('MM/DD/YYYY')}</p>
                </div>
              </div>
            }
          </div>
        </div>
      </div>
    </div >
  );
}

export default CustomerDetails;
