import { useMutation } from '@apollo/client';
import React, { useState } from 'react'
import { FORGET_PASSWORD } from '../../graphql/mutation/SignUpMutation';
import Loader from '../components/Loader/Loader';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [errorFlag, setErrorFlag] = useState(false);
    const [forgetPassword,{ loading }] = useMutation(FORGET_PASSWORD);
    
    const onSubmit = (event) => {
        event.preventDefault();
        setErrorFlag(false);
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
              })
        } else {
            forgetPassword({ variables: { email: email } }).then(async ({ data }) => {
                if(data?.forgetPassword === true){
                    toast.success('Email sent successfully.', {
                        autoClose: 2000
                      })
                   setEmail('');
                }else{
                    toast.error('Something went wrong.', {
                        autoClose: 2000
                      })
                }
            }).catch((err) => {
                if(err && !errorFlag)
                {
                  toast.error(err.message, {
                    autoClose: 2000
                  })
                  setErrorFlag(true);
                }
              })
        }
    }
    return (
        <div className="container">
            <div className="col-md-12 box-cener clearfix">
                <div className="col-lg-6 col-md-6 col-sm-8 col-sm-12 ViewCard p-0">
                    {loading && <Loader />}
                    <div className="logoView">
                        <img alt="Logo" src="../images/smsLogo.png" height="100" width="260" />
                    </div>
                    <form className="needs-validation" noValidate="noValidate" onSubmit={(event) => onSubmit(event)}>
                        <div className="form-group frm-Box">
                            <input type="email" className="form-control txt-Box" value={email} onChange={(e) => { setEmail(e.target.value) }} placeholder="Email" required />
                            <div>
                                <Link to="/login">
                                    <span className="float-right forgotPass">Login</span>
                                </Link>
                            </div>
                            <button type="submit" className="btn btn-block btn-theme mb-20">SUBMIT</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
