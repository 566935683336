import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'

export const AccessDenied = () => {
    const [windowHeight, setWindowHeight] = useState(window.innerHeight - 60);

    const updateDimensions = () => {
      setWindowHeight(window.innerHeight - 60);
    };
  
    useEffect(() => {
      window.addEventListener('resize', updateDimensions);

      return () => {
        window.removeEventListener('resize', updateDimensions);
      };
    }, []);
  return (
    <div>
        <div className="containermain" style={{ height: windowHeight }}>
          <div className="message animated bounceInDown">
            <div className="rivet top-left"></div>
            <div className="rivet top-right"></div>
            <div className="rivet bottom-left"></div>
            <div className="rivet bottom-right"></div>
            <div className="message-inner">
              <h1 className="message-title">Access <br />Denied</h1>
              <Link to="/dashboard">
                <button className="btn btn-primary btn-text border-0 bg-searchBlue" >Back to Dashboard</button>
              </Link>
            </div>
          </div>
        </div >
      </div >
  )
}
