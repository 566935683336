import React from 'react';
import ReactPaginate from 'react-paginate';

const Pagination = ({ pageCount, pageNo, handlePageClick }) => {
  return (
    <ReactPaginate
      previousLabel={'Back'}
      nextLabel={'Next'}
      pageCount={pageCount}
      marginPagesDisplayed={2}
      pageRangeDisplayed={5}
      initialPage={0}
      forcePage={pageNo}
      onPageChange={handlePageClick}
      containerClassName={'pagination mb-0'}
      subContainerClassName={'page-item'}
      activeClassName={'active'}
      pageLinkClassName={'pagination-link'}
    />
  );
};

export default Pagination;