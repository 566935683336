import React from 'react'
import Loader from '../../../components/Loader/Loader';
import { statusType } from '../../../components/Constant';
import Select from 'react-select';

export const EditBins = ({inserUpdateLoading,setBins ,onSubmit,Bins}) => {
    const onUpdateStatusChange = (binStatus) => {
        setBins((prevBins) => ({ ...prevBins, binStatus: binStatus }));
    };
    return (
        <>
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">Edit Bin</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                {inserUpdateLoading && <Loader />}
                                <div className="ibox-title">
                                    <span>Edit Bin</span>
                                </div>
                                <div className="ibox-content">
                                    <form className="needs-validation" noValidate="noValidate" onSubmit={event => onSubmit(event, false)}>
                                        <div className="form-row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Enter Bin</label>
                                                <input type="text" className="form-control ffPoppins" value={Bins.updatebin} readOnly />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Status<span className="text-danger">*</span></label>
                                                <Select className="ffPoppins"
                                                    options={statusType}
                                                    defaultValue={Bins.binStatus}
                                                    value={Bins.binStatus}
                                                    onChange={onUpdateStatusChange}
                                                />
                                            </div>
                                            <div className="form-group col-xs-12">
                                                <div className="mt-3">
                                                    <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                    <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" >Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
