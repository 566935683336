import { useMutation } from '@apollo/client';
import React from 'react'
import { DELETE_CLOSEST_CITY } from '../../../../graphql/mutation/ClosestCityMutation';
import confirmDeletion from '../../../components/Common/BootBox';
import { statusType } from '../../../components/Constant';
import * as $ from 'jquery';
import { toast } from 'react-toastify';

export const GetClosestsCity = ({ getClosestCities, setClosestsCity, closestsCity ,refetch}) => {
    const [deleteClosestCity] = useMutation(DELETE_CLOSEST_CITY);
    const sortData = (sortKey) => {
        const newSortType = (closestsCity.sortType === 1) ? -1 : 1;
        setClosestsCity((prevClosestCity) => ({ ...prevClosestCity, sortKey: sortKey, sortType: newSortType }));
    };
    
    const editClosestCity = (e, oldValue) =>  {
        e.preventDefault();
        setClosestsCity(prevClosestCity => ({ ...prevClosestCity ,
            id: oldValue.id,
            name: oldValue.name,
            code: oldValue.code,
            countryId: oldValue.countryId.id,
            stateId: oldValue.stateId.id,
            isActive: oldValue.isActive,
            temp_isActive: oldValue.isActive ? statusType[0] : statusType[1],
            openModal: 'Edit',
            Old_Data: {
              name: oldValue.name,
              code: oldValue.code,
              countryId: oldValue.countryId.id,
              stateId: oldValue.stateId.id,
              isActive: oldValue.isActive,
            },
        }))
          $('#ClosestCityModal').modal('show');
      }
     const deleteClosestCityDetails = (e, id) => {
        e.preventDefault()
        const deleteClosestsCity = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'closestcities',
                actionName: 'deleteClosestCity',
                oldValue: ""
              }
              deleteClosestCity({ variables: { 'id': id, 'log': log } }).then(async ({ data }) => {
                refetch();
                toast.success("Closest city deleted successfully!", {
                  autoClose: 2000
                })
              }).catch((err) => {
                toast.error(err.message, {
                  autoClose: 2000
                })
              })
        }
        confirmDeletion('Delete ClosestCity' , "Are you sure want to delete this record ?",deleteClosestsCity)
       
    }
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0">
                <thead>
                    <tr>
                        <th className="text-center">No</th>
                        <th className={`sortColumn ${(closestsCity?.sortKey === 'name') ? (closestsCity?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('name')}>Name</th>
                        <th>State</th>
                        <th>Country</th>
                        <th className={`sortColumn ${(closestsCity?.sortKey === 'isActive') ? (closestsCity?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('isActive')}>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getClosestCities && getClosestCities.data && getClosestCities.data.length > 0 ?
                            getClosestCities.data.map((d, i) => {
                                return (
                                    <tr key={d?.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editClosestCity(e, d)}>
                                        <td className="text-center">{i + 1}</td>
                                        <td>{d?.name} || {d?.code}</td>
                                        <td>{d?.stateId?.name}</td>
                                        <td>{d?.countryId?.name}  ||  {d?.countryId?.code}</td>
                                        <td>
                                            {
                                                d?.isActive
                                                    ? <span className="statusStyle activeCustomer">Active</span>
                                                    : <span className="statusStyle inActiveCustomer">InActive</span>
                                            }
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editClosestCity(e, d)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2"
                                                    onClick={(e) => { deleteClosestCityDetails(e, d.id); }}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={6} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
