import React, { useState } from 'react'
import { GetFaq } from './GetFaq\'s';
import { AccessDenied } from '../../../components/AccessDenied';
import { InsertUpdateFaq } from './InsertUpdateFaq';
import { useQuery } from '@apollo/client';
import GET_FAQs from '../../../../graphql/query/FAQsQuery';
import * as $ from 'jquery';
import Loader from '../../../components/Loader/Loader';

export const FAQ = () => {
    const user = JSON.parse(localStorage.getItem('loggedUser'));
    const [faq, setFaq] = useState({
        openModal: 'Add New',
        filter: "",
        filterText: '',
        sortKey: 'createdAt',
        sortType: -1,
        id: null,
        faqData: {},
        Old_Data: null,
    });
   const {data,loading,refetch} = useQuery(GET_FAQs ,{
        variables : {"filter": faq?.filter, "sort": { key: faq?.sortKey, type: faq?.sortType } },
        fetchPolicy: 'cache-and-network'
    })
    const addNewFAQ = (e) => {
        e.preventDefault();
        setFaq(prvFaq => ({ ...prvFaq, openModal: 'Add New ', faqData: {} }))
        $('#frm_Faq').removeClass('was-validated');
        $('#frm_Faq')[0].reset();
        $('#Answer').val('');
        $('#FAQsModal').modal('show');
    }
    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchFaqs()
        }
    }
    const searchFaqs = () => {
        setFaq(prevFaq => ({...prevFaq ,filter : faq?.filterText}))
    }
    const onResetSearch = () => {
        setFaq(prevFaq => ({...prevFaq ,filter : "",filterText : ""}))
    }

    if (user.roles === 'standard')
      return <AccessDenied />
    else
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-8 p-0">
                    <div className="mb-20">
                        <p className="mainTitle m-0 mt_-15">FAQs</p>
                        <span className="borderBottomOfTitle"></span>
                    </div>
                </div>
                <div className="col-md-4 p-0">
                    <div className="float-right">
                        <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewFAQ(e)}> <i className="fa fa-plus"></i> Add New FAQs</button>
                    </div>
                </div>
            </div>

            <div className="row mb-4">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>FAQs Details</span>
                        </div>
                        <div className="ibox-content p-0">
                            {loading && <Loader />}
                            <div className="col-md-12 ph-20 mt-3">
                                <div className="form-row">
                                    <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Search</label>
                                        <input type="text" value={faq?.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setFaq(preFaq => ({ ...preFaq, filterText: e.target.value }))} className="form-control" />
                                    </div>
                                    <div className="form-group col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                        <div className="mt-30">
                                            <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchFaqs()} >Search</button>
                                            <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <GetFaq FAQs={data?.getFaqs} refetch={refetch} faq={faq} setFaq={setFaq}/> 
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal show" id="FAQsModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                    <InsertUpdateFaq refetch={refetch} faq={faq} setFaq={setFaq}/>
                </div>
            </div>
        </div>
    )
}
