import React from 'react'
import { Link } from 'react-router-dom'

export const Footer = () => {
  return (
    <footer className="page-footer">
    <div className="container">
      <div className="col-md-12 col-sm-12 col-xs-12 row pt-4 pb-4 p-0">
        <div className="col-md-3 col-sm-6 pr-0 mb-4">
          <p className="footerTitle mb-0">Contact Us</p>
          <div className="redDividerToSmall mb-3" />
          <div>
            <div className="flex mb-2 mt-3">
              <div className="footIconDiv mr-2">
                <i className="fa fa-home white labelFs12" aria-hidden="true"></i>
              </div>
              <span className="labelFs12">98 Jansen Ave Essington, PA 19029 USA</span>
            </div>
            <div className="flex mb-3">
              <div className="footIconDiv mr-2">
                <i className="fa fa-phone white labelFs12" aria-hidden="true"></i>
              </div>
              <span className="labelFs12 pt-1">+1(267)-230-3365
              <br /> +1(267)-287-3561</span>
            </div>
            <div className="flex mb-2">
              <div className="footIconDiv  mr-2">
                <i className="fa fa-envelope white labelFs12" aria-hidden="true"></i>
              </div>
              <span className="labelFs12 pt-1">info@Cargoatlantic.com</span>
            </div>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 pr-0 mb-4">
          <p className="footerTitle mb-0">Company</p>
          <div className="redDividerToSmall mb-3" />
          <div>
            <p className="mb-1"><Link to="/" className="labelFs12 alink">Home</Link></p>
            <p className="mb-1"><Link to="/about-us" className="labelFs12 alink">About Us</Link></p>
            <p className="mb-1"><Link to="/faqs-web" className="labelFs12 alink">FAQ's</Link></p>
            <p className="mb-1"><Link to="/contact-us-web" className="labelFs12 alink">Contact</Link></p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 pr-0 mb-4">
          <p className="footerTitle mb-0">Links</p>
          <div className="redDividerToSmall mb-3" />
          <div>
            <p className="mb-1"><Link to="/terms-condition" className="labelFs12 alink">Term & Conditions</Link></p>
            <p className="mb-1"><Link to="/prohibited-web" className="labelFs12 alink">Prohibited Items</Link></p>
            <p className="mb-1"><Link to="/privacy-policy" className="labelFs12 alink">Privacy Policy</Link></p>
          </div>
        </div>
        <div className="col-md-3 col-sm-6 pr-0 mb-4">
          <p className="footerTitle mb-0">Follow Us</p>
          <div className="redDividerToSmall mb-3" />
          <div className="">
            <Link to="/" className="mr-2">
              <img src="../images/facebook.png" alt="Img" width="22px" className="" />
            </Link>
            <Link to="/" className="mr-2">
              <img src="../images/instagram.png" alt="Img" width="22px" className="" />
            </Link>
            <Link to="/" className="">
              <img src="../images/twitter.png" alt="Img" width="22px" className="" />
            </Link>
          </div>
        </div>
      </div>
      <div className="pb-1">
        <div className="bottomBrdr mt-2 mb-3" />
        <p className="labelFs12 text-center">Copyright© 2020, All Rights Reserved</p>
      </div>
    </div>
  </footer>
  )
}
