import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { delayedFunction } from './utils';
import { GET_ALL_CUSTOMERS } from '../../../graphql/query/CustomerQuery';

const CustomerList = (props) => {
  const [state, setState] = useState({
    oldCountryId: "",
    customerList: [],
    defaultValue: null,
    colorError: undefined,
    value: null,
    searchKey: ''
  });

  const { data } = useQuery(GET_ALL_CUSTOMERS, {
    variables: {
      page: 1,
      limit: 10,
      filter: props.searchCustomer,
      id: props?.customerId
    },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (data && data?.getAllCustomerList && data?.getAllCustomerList.length > 0) {
      const customers = data?.getAllCustomerList;
      let getCustomerList = []
      if (props?.selectedReportType?.value  === 'PACKED BOXES REPORT' || props?.selectedReportType?.value === "CUSTOMER REPORT" || props?.selectedReportType?.value === "PACKING LIST" || props?.selectedReportType?.value === "CHARGES REPORT" || props?.selectedReportType?.value === 'TOTAL RECEIVED PACKAGES' || props?.type) {
        getCustomerList.push({ value: 'all', label: 'All Customers' });
      }
      customers.forEach(d => {
        getCustomerList.push({ value: d.id, label: `${d.firstName} ${d.lastName} || ${d.pmbNo}` })
      });
      setState(prevState => ({ ...prevState, customerList: getCustomerList, allCustomerDetails: customers }));
      
    } else {
      setState(prevState => ({ ...prevState, customerList: [] }));
    }
  }, [data,props?.selectedReportType,props?.type]);
  
  useEffect(()=> {
    setDefaultCustomer(props.selectedOrderData?.customerInfo);
  },[props.selectedOrderData?.customerInfo,props?.selectedOrderData])

  const customerHandleChange = (selectedValue) => {
    setState(prevState => ({ ...prevState, colorError: true, value: selectedValue }));

    if (selectedValue === null) {
      props.setSearchCustomer('');
      props.getCustomerInfo(null);
      setState(prevState => ({ ...prevState, value: null, searchKey: '' }));
    } else {
      if (selectedValue?.value === 'all') {
        props.setSearchCustomer('');
        props.getCustomerInfo(selectedValue);
        setState(prevState => ({ ...prevState, value: selectedValue , searchKey: '' }));
      } else {
      const customerIdData = _.find(state.allCustomerDetails, (d) => d.id === selectedValue.value);

      const customerInfo = {
        id: customerIdData?.id,
        firstname: customerIdData?.firstName || customerIdData?.firstname,
        lastname: customerIdData?.lastName || customerIdData?.lastname,
        email: customerIdData?.email,
        phone: customerIdData?.phone,
        pmb_no: customerIdData?.pmbNo || customerIdData?.pmb_no,
        receiverType: customerIdData?.receivingServiceType
      };

      if (props.getCustomerInfo) {
        props.getCustomerInfo(customerInfo);
      }
      }
    }
  };

  const setDefaultCustomer = (info) => {
    const { customerId } = props;
    if (customerId && info) {
      const defaultValue = { value: info.id, label: `${info.firstName || info.firstname} ${info.lastName || info.lastname} || ${info.pmbNo || info.pmb_no}` };
      setState(prevState => ({ ...prevState, value: defaultValue }));
    } else {
      setState(prevState => ({ ...prevState, value: null }));
    }
  }

  return (
    <Select
      className="basic-single ffPoppins"
      options={state.customerList}
      isDisabled={props.isdisabled && props.isdisabled}
      value={state?.value}
      onChange={(selectedValue) => customerHandleChange(selectedValue)}
      onInputChange={(inputValue) => {
        setState(prevState => ({ ...prevState, searchKey: inputValue }));
        delayedFunction(props.setSearchCustomer(inputValue));
      }}
      styles={{
        control: (provided, stateStyles) =>
          !stateStyles.colorError && stateStyles.colorError !== undefined
            ? {
              ...provided,
              boxShadow: "0 0 0 1px #dc3545 !important",
              border: "1px #dc3545 !important"
            }
            : provided
      }}
    />
  );
}

export default CustomerList;
