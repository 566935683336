import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { INSERT_UPDATE_CUSTOMER } from '../../../graphql/mutation/CustomerMutation';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import Loader from '../../components/Loader/Loader';
import CustomerDetails from './CustomerDetails';
import CustomerAddressDetails from './CustomerAddressDetails';
import GetAddressList from '../../components/Address/getReceiverAddress';

const CURDCustomer = (props) => {
    const [openModal, setOpenModal] = useState('Add New');
    const [sameAddress, setSameAddress] = useState(false);
    const [customerDetailsData, setCustomerDetailsData] = useState({});
    const [receiverData, setReceiverData] = useState({});
    const [oldData, setOldData] = useState(null);
    const [errorFlag, setErrorFlag] = useState(false);
    
    const [insertUpdateCustomerByAdmin, { loading }] = useMutation(INSERT_UPDATE_CUSTOMER);
    useEffect(() => {
        if (props.modalOpen) {
            setCustomerDetailsData(props.CustomerDetailsData ? props.CustomerDetailsData : {});
            setOldData(props.CustomerDetailsData ? props.CustomerDetailsData : null);
            $('#CustomerModal').modal('show');
        }
        setModalAddress();
    }, [props.modalOpen]);

    const addSameAsAddress = () => {
        setSameAddress(true);
        $("#NewAddress").css('display', 'block');
        $('#frm_Receiver').removeClass('was-validated');
    };

    const updateAddressFlag = () => {
        setSameAddress(false);
    };

    const setModalAddress = () => {
        if (props.curdType === "Add") {
            $("#NewAddress").css('display', 'block');
            $('#frm_Receiver').removeClass('was-validated');
        } else {
            $("#NewAddress").css('display', 'none');
        }
    };

    const AddNewAddress = (type, ReceiverData) => {
        setOpenModal(type);
        setReceiverData(ReceiverData);
        setSameAddress(false);
        $("#NewAddress").css('display', 'block');
        $('#frm_Receiver').removeClass('was-validated');
    };


    const formOnSubmitCustomer = (event) => {
        event.preventDefault();
        setErrorFlag(false);
        const form = event.currentTarget;
        if (!form.checkValidity() || !customerDetailsData?.countryId || !customerDetailsData?.stateId) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            });
            return false;
        } else {
            const { CustomerUserId, pmbNo } = props;
            let input = {};
            let old_value = {};

            if (props.curdType === "Edit") {
                for (var obj in oldData) {
                    if (customerDetailsData[obj] !== oldData[obj]) {
                        old_value[obj] = oldData[obj]
                        input[obj] = customerDetailsData[obj]
                    }
                }
                if (customerDetailsData && customerDetailsData.countryId) {
                    input.pmbNo = pmbNo;
                    input.countryCode = customerDetailsData.countryCode
                }
                input.id = CustomerUserId;
            }
            if (props.curdType === "Add New") {
                if (customerDetailsData.password !== customerDetailsData.confirmPassword) {
                    toast.error('Passwords do not match.', {
                        autoClose: 2000
                    });
                    return;
                }
                input = { ...customerDetailsData };
                delete input.confirmPassword;
            }
            let log = {
                action: props.curdType === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'customers',
                actionName: "insertUpdateCustomerByAdmin",
                oldValue: props.curdType === "Edit" ? JSON.stringify(input) : ""
            };
            insertUpdateCustomerByAdmin({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                form.classList.remove('was-validated');
                form.reset();
                props.refetch();
                props.closeModal();
                setCustomerDetailsData({});
                if (props.curdType === "Edit") {
                    toast.success("Customer User updated successfully!", {
                        autoClose: 2000
                    });
                } else {
                    toast.success("Customer User added successfully!", {
                        autoClose: 2000
                    });
                }
            }).catch((err) => {
                if (err && !errorFlag) {
                    toast.error(err.message, {
                        autoClose: 2000
                    });
                    setErrorFlag(true);
                }
            });
            return true;
        }
    };

    // const handleFieldChange = (name, value) => {
    //     setCustomerDetailsData(prevData => ({
    //         ...prevData,
    //         [name]: value
    //     }));
    // };
    return (
        <div className="modal show" id="CustomerModal" data-backdrop="static" tabIndex="-1" role="dialog" >
            <div className="modal-dialog modal-lg modal-dialog-centered " role="document">
                <div className="modal-content ">
                    <div className="modal-header" style={{ paddingTop: "0px" }}>
                        <div>
                            <p className="mainTitle m-0 mt_-15">{props.curdType} Customer</p>
                            <span className="borderBottomOfTitle "></span>
                        </div>
                        <div>
                            <button onClick={() => props.closeModal()}
                                type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                    </div>
                    <div className="modal-body">
                        <div className="container-fluid">
                            <div className="row">
                                {loading && <Loader />}
                                <form id="frm_CustomerUser" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitCustomer(e)}>
                                    <CustomerDetails
                                        customerDetailsData={customerDetailsData}
                                        curdType={props?.curdType}
                                        ReceivingServiceType={(data) => { setCustomerDetailsData(preData => ({...preData , receivingServiceType: data.value }))}}
                                        DeliveryServiceType={(data) => {  setCustomerDetailsData(preData => ({...preData , deliveryServiceType: data.value }))}}
                                        StatusServiceType={(data) => { setCustomerDetailsData(preData => ({...preData , isActive: data.value }))}}
                                        CustomerIdProof={(data) => { setCustomerDetailsData(preData => ({...preData ,idProof: data  }))} }
                                        CustomerSignature={(data) => { setCustomerDetailsData(preData => ({...preData ,signature: data }))}}
                                        handleText={e => setCustomerDetailsData(preData => ({...preData ,[e.target.name]: e.target.value}))}
                                    // ReceivingServiceType={(data) => handleFieldChange('receivingServiceType', data.value)}
                                    // DeliveryServiceType={(data) => handleFieldChange('deliveryServiceType', data.value)}
                                    // StatusServiceType={(data) => handleFieldChange('isActive', data.value)}
                                    // CustomerIdProof={(data) => handleFieldChange('idProof', data)}
                                    // CustomerSignature={(data) => handleFieldChange('signature', data)}
                                    // handleText={(e) => handleFieldChange(e.target.name, e.target.value)}
                                    />
                                    <div className="col-md-12 p-0">
                                        <div className="ibox">
                                            <div className="ibox-title">
                                                <span>Customer Address Details</span>
                                            </div>
                                            <div className="ibox-content">
                                                <CustomerAddressDetails
                                                    CustomerDetailsData={customerDetailsData}
                                                    curdType={props.curdType}
                                                    {...props}
                                                    saveCountryCode={(code) => setCustomerDetailsData(prevData => ({ ...prevData, countryCode: code }))}
                                                    getCountryId={(id) => setCustomerDetailsData(prevData => ({ ...prevData, countryId: id, stateId: null }))}
                                                    getStateId={(id) => setCustomerDetailsData(prevData => ({ ...prevData, stateId: id }))}
                                                    handleText={(e) => setCustomerDetailsData(prevData => ({ ...prevData, [e.target.name]: e.target.value }))}
                                                />
                                                <div className="form-group p-0">
                                                    <button type="submit" className="btn btn-primary btn-text mt-3 mr-4 border-0 bg-searchBlue">Save</button>
                                                    <button type="reset" className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed" onClick={() => props.closeModal()} >Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            {
                                props.curdType === "Edit" &&
                                <div className="ibox-content border-0 p-0">
                                    <div className="mt-3 mb-3">
                                        <div className="border-bottom row d-flex justify-content-sm-between">
                                            <div className="d-flex mb-2">
                                                <span className="iboxTitleSpan">Receiver Address Details</span>
                                                <span className="btn btn-sm btn-warning bg-Orange text-white ffPoppins mh-15" onClick={() => addSameAsAddress()}>Same As Customer Address</span>
                                            </div>
                                            <div className="mb-2">
                                                <span className="btn btn-sm btn-success bg-lightGreen text-white ffPoppins" onClick={(e) => {
                                                    e.preventDefault();
                                                    AddNewAddress('Add New', {})
                                                }}> <i className="fa fa-plus"></i> Add New Address</span>
                                            </div>
                                        </div>
                                    </div>
                                    <GetAddressList
                                        customerId={props?.CustomerUserId}
                                        isCustomer={true}
                                        sameAddress={sameAddress}
                                        isupdateAddressFlag={() => updateAddressFlag()}
                                        openModal={openModal}
                                        AddNewAddress={AddNewAddress}
                                        ReceiverData={receiverData}
                                        CustomerDetailsData={customerDetailsData}
                                    />
                                </div>
                            }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CURDCustomer