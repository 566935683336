import React from 'react'
import * as $ from 'jquery';
import moment from 'moment';

export const InvoiceDetails = ({invoice}) => {
  return (
    <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title d-flex justify-content-between">
                            <span>Invoice Details</span>
                            <div>
                                {invoice?.status === "paid" && invoice?.uploadEvidence &&
                                    <span
                                        className='cursor-pointer'
                                        onClick={() => {$('#showPaymentReceipt').modal('show')}}
                                    >
                                        <span className="btn BoxImg bg-Green rounded mr-2 cursor-pointer">
                                            <img src="../images/eye.png" alt="Img" className="width15 cursor-pointer" />
                                        </span>
                                    </span>
                                }
                            </div>
                        </div>
                        <div className="ibox-content p-0">
                            <div className="table-responsive">
                                <table className="table themeTable table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>&emsp;Invoice Date</th>
                                            <th>Customer</th>
                                            <th>Email</th>
                                            <th>Phone</th>
                                            <th>Receiver</th>
                                            <th>Invoice No</th>
                                            <th>Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr className="border-bottom">
                                            <td>&emsp;{moment(invoice?.createdAt).format('MM/DD/YYYY')}</td>
                                            <td>{invoice?.customerInfo?.firstname + ' ' + invoice?.customerInfo?.lastname} | {invoice?.customerInfo?.pmb_no}</td>
                                            <td>{invoice?.customerInfo?.email}</td>
                                            <td>{invoice?.customerInfo?.phone}</td>
                                            <td>{invoice?.receiverInfo?.name + ' (' + invoice?.receiverInfo?.phone + ')'}</td>
                                            <td>{invoice?.invoiceNo} (#{invoice?.createdInvoiceData?.data?.invoice_number}) </td>
                                            <td><span className={`${invoice?.status === "paid" ? "activeCustomer" : "inActiveCustomer"} statusStyle`}>{invoice?.status}</span></td>
                                        </tr>

                                    </tbody>
                                </table>
                            </div>

                            <div className="ibox-title border-0">
                                <span> Charges</span>
                            </div>

                            <div className="table-responsive">
                                <table className="table themeTable table-nowrap mb-0">
                                    <thead>
                                        <tr>
                                            <th>&emsp;Charges</th>
                                            <th className='text-right pr20'>Amount(NGN)</th>
                                            <th className='text-right pr20'>Amount(USD)</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {invoice && invoice?.charges && invoice?.charges?.length > 0 &&
                                            invoice?.charges.map((d, i) => {
                                                return (
                                                    <tr key={i}>
                                                        <td>&emsp;{d?.charges}</td>
                                                        <td className='text-right pr20'>{parseFloat(d?.amountNGN).toFixed(2)}</td>
                                                        <td className='text-right pr20'>{parseFloat(d?.amount).toFixed(2)}</td>
                                                    </tr>
                                                )
                                            })
                                        }
                                    </tbody>
                                    <thead>
                                        <tr>
                                            <th>&emsp;Total Amount(NGN)</th>
                                            <th className='text-right pr20'>{parseFloat(invoice?.totalAmountNGN).toFixed(2)}</th>
                                            <th className='text-right pr20'>{parseFloat(invoice?.totalAmount).toFixed(2)}</th>
                                        </tr>
                                    </thead>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
  )
}
