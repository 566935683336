import React from 'react'
import Loader from '../../../components/Loader/Loader'
import { useMutation } from '@apollo/client'
import { INSERT_UPDATE_COUNTRY } from '../../../../graphql/mutation/CountryMutation'
import { statusType } from '../../../components/Constant'
import { toast } from 'react-toastify'
import * as $ from 'jquery';
import Select from 'react-select';

export const InsertUpdate = ({ country, setCountry ,initialState,refetch}) => {
    const [insertUpdateCountry, { loading }] = useMutation(INSERT_UPDATE_COUNTRY);

    const handleCountryStatus = (e) => {
        setCountry(prevCoutry => ({ ...prevCoutry, status: e, isActive: e.value, }))
    }
    const frmOnSubmitCountry = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            const { name, code, isActive, openModal, id } = country;
            let input = {};
            let oldValues = {};
            if (openModal === "Edit") {
                for (var obj in country.Old_Data) {
                    if (country.Old_Data[obj] !== country[obj]) {
                        oldValues[obj] = country.Old_Data[obj]
                        input[obj] = country[obj]
                    }
                }
                if (input && Object.keys(input).length === 0) {
                    $('#CountryModal').modal('hide');
                    return
                }
                input.id = id;
            } else {
                input = {
                    name: name,
                    code: code,
                    isActive: isActive
                }
            }
            let log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'countries',
                actionName: 'insertUpdateCountry',
                oldValue: openModal === "Edit" ? JSON.stringify(oldValues) : ""
            }
            insertUpdateCountry({ variables: { "input": input, "log": log } }).then(async (data) => {
                setCountry(prevCountry => ({ ...prevCountry, ...initialState }))
                refetch();
                form.reset();
                $('#CountryModal').modal('hide');
                if (openModal === "Edit")
                    toast.success("Country updated successfully!", {
                        autoClose: 2000
                    });
                else
                    toast.success("Country added successfully!", {
                        autoClose: 2000
                    });
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    }

    return (
        <div className="modal-content ">
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">{country.openModal} Country</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                {loading && <Loader />}
                                <div className="ibox-title">
                                    <span>{country.openModal} Country Details</span>
                                </div>
                                <div className="ibox-content">
                                    <form id="frm_Country" className="needs-validation" noValidate="noValidate" onSubmit={(e) => { frmOnSubmitCountry(e) }}>
                                        <div className="form-row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins"
                                                    value={country.name}
                                                    onChange={(e) => setCountry(prevCoutry => ({ ...prevCoutry, name: e.target.value }))} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Code<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins"
                                                    value={country.code}
                                                    onChange={(e) => setCountry(prevCoutry => ({ ...prevCoutry, code: e.target.value.toLocaleUpperCase() }))} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Status<span className="text-danger">*</span></label>
                                                <Select className="ffPoppins"
                                                    value={country.status}
                                                    options={statusType}
                                                    onChange={(data) => { handleCountryStatus(data) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-xs-12">
                                            <div className="mt-3">
                                                <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" >Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
