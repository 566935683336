import { useMutation } from '@apollo/client';
import React from 'react'
import { DELETE_WAREHOUSE_LOCATION } from '../../../../../graphql/mutation/WareHouseMutation';
import confirmDeletion from '../../../../components/Common/BootBox';
import * as $ from 'jquery';
import { toast } from 'react-toastify';

export const GetDestinationWarehouseLocation = ({ warehouseLocationData, warehouseLocation, setwarehouseLocation, refetch }) => {
    const [deleteWareHouseLocation] = useMutation(DELETE_WAREHOUSE_LOCATION)

    const sortData = (sortKey) => {
        const newSortType = (warehouseLocation.sortType === 1) ? -1 : 1;
        setwarehouseLocation((prevData) => ({ ...prevData, sortKey: sortKey, sortType: newSortType }));
    };

    const deleteWHLocationDetails = (e, WHLocationId) => {
        e.preventDefault()
        const deleteWarehouseLocation = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'warehouselocations',
                actionName: 'deleteWareHouseLocation',
                oldValue: ""
            };
            deleteWareHouseLocation({ variables: { "id": WHLocationId, "log": log } }).then(async ({ data }) => {
                refetch();
                $('#WarehouseLocationModal').modal('hide');
                toast.success("Destination Warehouse Location deleted successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }

        confirmDeletion("Delete Destination Warehouse Location", "Are you sure want to delete this record ?", deleteWarehouseLocation);
    }
    const editWHLocation = (e, oldWHLocation) => {
        e.preventDefault();
        let statusValue = oldWHLocation.isActive ? { value: true, label: 'Active' } : { value: false, label: 'Inactive' };
        setwarehouseLocation((prevData) => ({
            ...prevData,
            id: oldWHLocation.id,
            warehouseId: oldWHLocation.warehouseId,
            temp_isActive: statusValue,
            WHLocationData: oldWHLocation,
            Old_Data: oldWHLocation,
            openModal: 'Edit'
        }));
        $('#frm_wh_location').removeClass('was-validated');
        $('#WarehouseLocationModal').modal('show');
    }
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0 tbl-sort">
                <thead>
                    <tr>
                        <th className="text-center">No</th>
                        <th
                            className={`sortColumn ${(warehouseLocation.sortKey === 'boxRacNo') ? (warehouseLocation?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('boxRacNo')}>BOX RAC No</th>
                        <th
                            className={`sortColumn ${(warehouseLocation.sortKey === 'height') ? (warehouseLocation?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('height')}>Height</th>
                        <th
                            className={`sortColumn ${(warehouseLocation.sortKey === 'width') ? (warehouseLocation?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('width')}>Width</th>
                        <th
                            className={`sortColumn ${(warehouseLocation.sortKey === 'length') ? (warehouseLocation?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('length')}>Length</th>
                        <th
                            className={`sortColumn ${(warehouseLocation.sortKey === 'isActive') ? (warehouseLocation?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('isActive')}>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        warehouseLocationData && warehouseLocationData.data && warehouseLocationData.data.length > 0
                            ? warehouseLocationData.data && warehouseLocationData.data.map((d, i) => {
                                return (
                                    <tr key={d.id}>
                                        <td className=" text-center">{i + 1}</td>
                                        <td>{d.boxRacNo}</td>
                                        <td>{d.height}</td>
                                        <td>{d.width}</td>
                                        <td>{d.length}</td>
                                        <td>
                                            {
                                                d.isActive
                                                    ? <span className="statusStyle activeCustomer">Active</span>
                                                    : <span className="statusStyle inActiveCustomer">InActive</span>
                                            }
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editWHLocation(e, d)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteWHLocationDetails(e, d.id)}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ textAlign: 'center' }} colSpan={7} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
