import React, { useState } from "react";
import _ from "lodash";
import { useNavigate } from "react-router-dom";

const ShipmentComp = ({ shipment, showDetail, showOrderDetail, setOrderDetils }) => {
  const [dtl] = useState(0);
  const [innerdtl] = useState(0);
  const [toggle] = useState(false);
  const [ItemList, setItemList] = useState(null);
  const [trackingNo, setTrackingNo] = useState(null);
  const navigate = useNavigate();

  let boxes = [];
  if (shipment?.length > 0) {
    shipment.forEach((ship) => {
      ship.boxDimension.forEach((box) => {
        boxes.push({ packageId: ship.id, shipmentInfo: ship.shipmentInfo, itemList: ship.itemList, trackingNo: ship.trackingno, ...box });
        return boxes;
      });
      return boxes;
    });
  }
  boxes = _.groupBy(boxes, "packageId");
  let results = [];
  Object.entries(boxes).forEach((item) => results.push(item[1]));

  return showOrderDetail === false ? (
    results && results.length ? (
      results.map((ship, i) => (
        <div key={ship?.id} className="pb-1 boxShadowShip pl-3 pr-3 mb-4 boxInnerMargin">
          <div className="text-center">Total box = {ship.length}</div>
          {ship.map((box, j) => {
            const {
              box_Num,
              len,
              width,
              height,
              weight,
              dimensionalWeight,
              assign,
              shipmentInfo,
            } = box;
            return (
              <div key={j} className="pb-1 boxShadowShip pl-3 pr-3 mb-4 boxInnerMargin">
                <div className="col-md-12 row">
                  <div className="col-md-6 mb-2 p-0">
                    <label className="mb-0 labelFs14 mr-2">Box No. : </label>
                    <label className="mb-0 inputFs14">{box_Num}</label>
                  </div>
                  <div className="col-md-6 mb-2 p-0">
                    <div className="float-right">
                      <button
                        className="btn text-light cursor-pointer d-flex align-items-center"
                        style={{ background: "#0B429A" }}
                        onClick={() => {
                          navigate('/ordertracking', {
                            state: {
                              track: assign,
                              shipmentId: shipmentInfo,
                              comp: "shipmentComp",
                              showDetail: showDetail,
                              navigation: window.location.pathname,
                            }
                          });
                        }}
                      >
                        <img
                          src="../images/whiteTrack.png"
                          alt="Img"
                          width="17px"
                          className="mr-2"
                        />
                        <p className="m-0">Track Shipment</p>
                      </button>
                    </div>
                  </div>
                </div>
                <div className="bottomBrdr" />
                <div className="col-md-12 row pt-2">
                  <div className="shipWidth mb-2">
                    <label className="mb-0 labelFs14">Shipped On</label>
                    <p className="mb-0 inputFs14">
                      {(box.assign.find(
                        (a) =>
                          a.status.trim() ===
                          "YOUR SHIPMENT HAS BEEN DELIVERED TO THE CARRIER AIRLINE."
                      )?.date &&
                        new Date(
                          box.assign.find(
                            (a) =>
                              a.status.trim() ===
                              "YOUR SHIPMENT HAS BEEN DELIVERED TO THE CARRIER AIRLINE."
                          )?.date
                        ).toLocaleDateString()) ||
                      ""}
                    </p>
                  </div>
                  <div className="shipWidth mb-2">
                    <label className="mb-0 labelFs14">Box Dimensions</label>
                    <p className="mb-0 inputFs14">{`${len}*${width}*${height}`}</p>
                  </div>
                  <div className="shipWidth mb-2">
                    <label className="mb-0 labelFs14">Actual Weight</label>
                    <p className="mb-0 inputFs14">{weight} lbs</p>
                  </div>
                  <div className="shipWidth mb-2">
                    <label className="mb-0 labelFs14">
                      Dimensional Weight
                    </label>
                    <p className="mb-0 inputFs14">{dimensionalWeight} lbs</p>
                  </div>
                </div>
                {i === dtl && j === innerdtl && toggle && (
                      <div>
                        {ship?.itemList.length &&
                          ship.itemList.map((item, i) => {
                            const { description, qty, price, total } = item;
                            return (
                              <div key={item._id}>
                                <div className="bottomBrdrLight" />
                                <div className="col-md-12 row pt-2">
                                  <div className="shipWidth mb-2">
                                    <label className="mb-0 labelFs14">
                                      Item-{i + 1}
                                    </label>
                                    <p className="mb-0 inputFs14">
                                      {description}
                                    </p>
                                  </div>
                                  <div className="shipWidth mb-2">
                                    <label className="mb-0 labelFs14">
                                      Quantity
                                    </label>
                                    <p className="mb-0 inputFs14">{qty}</p>
                                  </div>
                                  <div className="shipWidth mb-2">
                                    <label className="mb-0 labelFs14">
                                      Price($)
                                    </label>
                                    <p className="mb-0 inputFs14">{price}</p>
                                  </div>
                                  <div className="shipWidth mb-2">
                                    <label className="mb-0 labelFs14">
                                      Total($)
                                    </label>
                                    <p className="mb-0 inputFs14">{total}</p>
                                  </div>
                                  <div className="shipWidth"></div>
                                </div>
                              </div>
                            );
                          })}
                      </div>
                    )}
              </div>

              
            );
          })}
          <div className="col-md-12 mAuto pb-2 mt-0 mb-5">
            <button
              onClick={() => {
                setOrderDetils(true);
                setItemList(ship[0].itemList);
                setTrackingNo(ship[0].trackingNo);
                localStorage.setItem("showOrderDetail", true);
              }}
              className="btn btn-primary btn-text border-0 bg-searchBlue float-right"
              style={{ marginTop: "8px", marginRight: "16px" }}
            >
              Shipment Order Details
            </button>
            <button
              onClick={() => {
                navigate("/my-invoice", {
                  state: {
                    packageId: ship[0].packageId, 
                    navigation: window.location.pathname,
                  },
                });
              }}
              className="btn btn-primary btn-text border-0 bg-searchBlue float-right"
              style={{ marginTop: "8px", marginRight: "16px" }}
            >
              Invoice
            </button>
          </div>
        </div>
      ))
    ) : (
      <div className="boxShadowRate boxInnerMargin">
        <div className="text-center">
          <img src="./images/notFound.png" alt="not found"></img>
        </div>
      </div>
    )
  ) : (
    showOrderDetail &&
    ItemList.length > 0 &&
    ItemList.map((item, i) => (
      <div key={item._id} className="pb-1 boxShadowShip pl-3 pr-3 mb-4 boxInnerMargin">
        <h6>{`Tracking No => ${trackingNo}`}</h6>
        <div className="bottomBrdrLight" />
        <div className="col-md-12 row pt-2">
          <div className="shipWidth mb-2">
            <label className="mb-0 labelFs14">Item-{i + 1}</label>
            <p className="mb-0 inputFs14">{item.description}</p>
          </div>
          <div className="shipWidth mb-2">
            <label className="mb-0 labelFs14">Quantity</label>
            <p className="mb-0 inputFs14">{item.qty}</p>
          </div>
          <div className="shipWidth mb-2">
            <label className="mb-0 labelFs14">Price($)</label>
            <p className="mb-0 inputFs14">{item.price}</p>
          </div>
          <div className="shipWidth mb-2">
            <label className="mb-0 labelFs14">Total($)</label>
            <p className="mb-0 inputFs14">{item.total}</p>
          </div>
        </div>
      </div>
    ))
  );
};

export default ShipmentComp;
