import { useMutation } from '@apollo/client';
import React from 'react'
import { useNavigate } from 'react-router-dom';
import { DELETE_INVOICE, RESEND_INVOICE, SINGLE_UPDATE } from '../../../../graphql/mutation/InvoiceMutation';
import confirmDeletion from '../../../components/Common/BootBox';
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import moment from 'moment';
import { Link } from "react-router-dom";

export const GetInvoiceData = ({ invoiceList, setInvoiceList, getInvoicerefetch }) => {
    const navigate = useNavigate();
    const [singleUpdate] = useMutation(SINGLE_UPDATE);
    const [deleteInvoice] = useMutation(DELETE_INVOICE, {
        onCompleted: () => {
            setInvoiceList(preData => ({ ...preData, skipQuery: false }))
        }
    })
    const [resendNotification] = useMutation(RESEND_INVOICE);

    const updateSingleInvoice = (e, id, invoiceId, amount, email) => {
        e.preventDefault();
        const updateSingleInvoiceData = () => {
            singleUpdate({ variables: { rowId: id, invoiceId: invoiceId.toString() } }).then(async ({ data }) => {
                if (data && data?.updateSingleInvoiceStatus) {
                    toast.success("Invoice updated successfully.", {
                        autoClose: 2000
                    })
                    getInvoicerefetch();
                    setInvoiceList(preData => ({ ...preData, skipQuery: false }))
                } else {
                    getInvoicerefetch();
                    $('#uploadPaymentReceipt').modal('show');
                    $('#frm_payment').removeClass('was-validated');
                    setInvoiceList(preData => ({
                        ...preData, openModal: true,
                        rowId: id,
                        invoiceId: invoiceId,
                        amount: amount,
                        email: email
                    }))
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion('Update Invoice', "Are you sure want to update this record ?", updateSingleInvoiceData);
    }
    const deleteInvoiceDetails = (e, id) => {
        e.preventDefault();
        const DeleteInvoice = () => {
            deleteInvoice({ variables: { id: id } }).then(async ({ data }) => {
                if (data && data?.deleteInvoice) {
                    toast.success("Invoice deleted successfully.", {
                        autoClose: 2000
                    })
                } else {
                    toast.error('Opps! Somthing went to worng.', {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion('Delete Invoice', 'Are you sure want to delete this record ?', DeleteInvoice);
    }
    const reSendInvoiceNotification = (e, id) => {
        e.preventDefault();
        resendNotification({ variables: { invoiceId: id.toString() } }).then(async ({ data }) => {
            toast.success("Re-send invoice Notification.", {
                autoClose: 2000
            })
        }).catch((err) => {
            toast.error(err.message, {
                autoClose: 2000
            })
        })
    }
    const { invoiceResult } = invoiceList;
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0" style={{ marginTop: 0 }}>
                <thead>
                    <tr>
                        <th className="text-center">No.</th>
                        <th>Invoice Date</th>
                        <th>Invoice No</th>
                        <th>Customer Name</th>
                        <th>Receiver Name</th>
                        <th>Email</th>
                        <th>Box No</th>
                        <th>Amount(NGN)</th>
                        <th>Amount(USD)</th>
                        <th>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {invoiceResult && invoiceResult?.data ? invoiceResult?.data.map((invoice, i) =>
                        <tr key={invoice?.id} style={{ cursor: "pointer" }} onDoubleClick={() => {
                            navigate('/view-invoice', { state: { invoice: invoice } })
                        }}>
                            <td className="text-center">{i + 1}</td>
                            <td>{moment(invoice.createdAt).format('MM/DD/YYYY')}</td>
                            <td>{invoice.invoiceNo} <br /><span>REF-{invoice.offlineRefNo}</span></td>
                            <td>{invoice.customerInfo?.firstname + ' ' + invoice.customerInfo?.lastname} <br /> <span>{invoice.customerInfo?.pmb_no}</span></td>
                            <td>{invoice.receiverInfo?.name} <br /> <span>({invoice.receiverInfo?.phone})</span></td>
                            <td>{invoice.customerInfo?.email} <br /> <span>({invoice.customerInfo?.phone})</span></td>
                            <td>
                                {
                                    invoice.boxDimension && invoice.boxDimension.map((b, i) => {
                                        if (invoice.boxDimension.length === (i + 1)) {
                                            return (
                                                <span>{b.box_Num}</span>
                                            )
                                        }
                                        else {
                                            return (
                                                <span>{b.box_Num},</span>
                                            )
                                        }
                                    })
                                }
                            </td>

                            <td>{parseFloat(invoice.totalAmountNGN).toFixed(2)}</td>
                            <td>{parseFloat(invoice.totalAmount).toFixed(2)}</td>
                            <td><span className={`${invoice.status === "paid" ? "activeCustomer" : "inActiveCustomer"} statusStyle`} onClick={(e) => { invoice.status === "unpaid" && updateSingleInvoice(e, invoice.id, invoice.createdInvoiceData.data.id, invoice?.totalAmountNGN, invoice?.customerInfo?.email) }}>{invoice.status}</span></td>
                            <td>
                                <div className="d-flex flex-row">
                                    <span onClick={() => { navigate('/view-invoice', { state: { invoice: invoice } }) }} >
                                        <span className="btn BoxImg bg-Peach rounded mr-2">
                                            <img src="../images/eye.png" alt="Img" className="width15" />
                                        </span>
                                    </span>
                                    <span className="btn BoxImg bg-blue rounded mr-2" onClick={(e) => reSendInvoiceNotification(e, invoice.createdInvoiceData.data.id)} >
                                        <img src="../images/RESEND.png" alt="Img" className="width15" />
                                    </span>

                                    <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteInvoiceDetails(e, invoice.id)} >
                                        <img src="../images/bin.png" alt="Img" className="width15" />
                                    </span>

                                </div>
                            </td>
                        </tr>) : <tr>
                        <td style={{ textAlign: 'center' }} colSpan={11}>No Records Found!</td>
                    </tr>}
                </tbody>
            </table>
        </div>
    )
}
