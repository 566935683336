import { gql } from "@apollo/client";
export const GET_CATEGORY = gql`
query getCategory($page: Int, $limit: Int, $filter: String, $sort: sortCategory){
  getCategory(page:$page,limit:$limit,filter:$filter,sort:$sort){
     count
     data {
        id
        name
        icon
        isDeleted
        isActive
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;

export const GET_ALL_CATEGORY = gql`
  query getAllCategories{
    getAllCategories{
      id
      name
      icon
      isDeleted
      isActive
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;

export const GET_SUBCATEGORY = gql`
  query getSubCategory($page: Int, $limit: Int,$filter: String, $sort:sortSubCategory) {
    getSubCategory(page:$page,limit:$limit,filter:$filter,sort:$sort){
      count
      data{
        id
        name
        icon
        categoryId {
          id
          name
          icon
        }
        isActive
        isDeleted
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_SUBCATEGORY = gql`
  query getAllSubCategories($categoryId:String){
    getAllSubCategories(categoryId:$categoryId){
      id
      name
      icon
      categoryId
      isActive
      isDeleted
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const GET_PRODUCT = gql`
  query getProduct($page: Int, $limit: Int,$filter: String, $sort:sortProduct) {
    getProduct(page:$page,limit:$limit,filter:$filter,sort:$sort){
      count
      data{
        id
        name
        icon
        desc
        price
        qty
        isPromotional
        categoryId {
          id
          name
          icon
        }
        subCategoryId {
          id
          name
          icon
        }
        isActive
        isDeleted
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;