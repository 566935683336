import { useMutation, useQuery } from '@apollo/client';
import React, { useEffect, useRef, useState } from 'react'
import { GET_CUSTOMER_TICKETS, GET_ID, GET_TICKET_WITH_CONVERSION, getS3SignUrl } from '../../../graphql/query/TicketQuery';
import moment from 'moment';
import ReactPaginate from 'react-paginate';
import { ADD_TICKET_MESSAGE, UPDATE_TICKET } from '../../../graphql/mutation/TicketMutation';
import Loader from '../../components/Loader/Loader';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import { BASE_URL } from '../../../config'

export const ChatBox = ({ loggedUser, isStatus, setEventFire, eventFire }) => {
  const customerId = loggedUser.customerId;
  const inputElementRef = useRef(null);
  const [selectedTicket, setSelectedTicket] = useState(false);
  const [chatMsg, setchatMsg] = useState('');
  const [isSubscribed, setisSubscribed] = useState(false);
  const [pageNo, setPageNo] = useState(0);
  const [limit] = useState(10);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState(null);
  const [isLoading, setisLoading] = useState(false);
  const [conversations, setConversations] = useState([]);



  const { data } = useQuery(GET_ID, { fetchPolicy: "cache-and-network" });

  const { data: GetCustomerTicket, refetch: customerTicketRefetch} = useQuery(GET_CUSTOMER_TICKETS, {
    variables: {
      page: pageNo + 1,
      limit: limit,
      customerId: customerId,
      isActive: isStatus === 'openCases' ? true : false
    },
    fetchPolicy: "cache-and-network"
  })


  const [addTicketMsg] = useMutation(ADD_TICKET_MESSAGE, {
    refetchQueries: [{ query: GET_TICKET_WITH_CONVERSION, variables: { "ticketId": selectedTicket?.id } }]
  });
  const [updateTicket] = useMutation(UPDATE_TICKET, {
    refetchQueries: [{ query: GET_CUSTOMER_TICKETS, variables: { "page": pageNo + 1, "limit": limit, "customerId": customerId, "isActive": isStatus === 'openCases' ? true : false } }],
    fetchPolicy: 'no-cache'
  });
  const { data: getDataTicketConversion} = useQuery(GET_TICKET_WITH_CONVERSION, {
    variables: { "ticketId": selectedTicket.id }, fetchPolicy: 'cache-and-network'
  })
  const [getPreSignedUrl] = useMutation(getS3SignUrl);

  useEffect(() => {
    const ticketData = getDataTicketConversion && getDataTicketConversion.getTicketWithConversations;
    let newConversations = []
    if (ticketData) {
      newConversations = ticketData.conversationId ? ticketData.conversationId : [];
      scrollBottom();
    }
    setConversations(newConversations);
  }, [getDataTicketConversion])

  useEffect(() => {
    if(eventFire) {
      customerTicketRefetch()
      setEventFire(false)
    }
  }, [eventFire]);

  useEffect(() => {
    if (isStatus !== undefined && isStatus !== null) {
      setSelectedTicket(false)
      setPageNo(0);
    }
  }, [isStatus])

  const _onFile = () => {
    inputElementRef.current.click();
  }
  useEffect(() => {
    if (selectedTicket && !isSubscribed) {
      setisSubscribed(true);
    }
  }, [selectedTicket, isSubscribed]);

  const onTicketClick = (tkt) => {
    setSelectedTicket(tkt);
    setSelectedFile(null);
  }

  const scrollBottom = () => {
    $(".msg_history").animate({ scrollTop: $(".msg_history").prop("scrollHeight") });
  }

  const onMessageSentClick = (e, id) => {
    e.preventDefault()
    if ((chatMsg?.trim() !== '' && chatMsg?.length > 0) || selectedFile) {
      if(selectedTicket && selectedTicket?.customerId?.id ){
        const input = {
          "input": {
            "ticketId": selectedTicket?.id,
            "message": chatMsg || fileName,
            "fileUpload": selectedFile,
            "fileName": fileName,
            "sender": selectedTicket?.customerId?.id,
            "receiver": id
          }
        }
        addTicketMsg({ variables: input }).then((data, error) => {
          if (error) {
            error.graphQLErrors.forEach(err => {
              toast.error(err.message, {
                autoClose: 2000
              })
            });
          } else {
            setchatMsg('');
            setSelectedFile(null);
            setFileName(null);
            toast.success("Message sent!", {
              autoClose: 2000
            })
          }
        }).catch((err) => {
          toast.error(err.message.replace("GraphQL error:" ,"").trim(), {
            autoClose: 2000
          })
        })
      }
    } else
      toast.error("please, write message first!", {
        autoClose: 2000
      })
  }

  const onTicketAction = (e, ticketId, isActive) => {
    e.preventDefault()
    updateTicket({ variables: { ticketId: ticketId, isActive: isActive } }).then((data, error) => {
      if (error) {
        error.graphQLErrors.forEach(err => {
          toast.error(err.message, {
            autoClose: 2000
          })
        });
      } else {
        if (isActive)
          toast.success("Ticket opened successfully!", {
            autoClose: 2000
          })
        else
          toast.success("Ticket closed successfully!", {
            autoClose: 2000
          })
        setSelectedTicket(false);
        customerTicketRefetch()

      }
    }).catch((err) => {
      toast.error(err.message, {
        autoClose: 2000
      })
    })
  }

  const handlePageClick = (data) => {
    setPageNo(data?.selected)
  };

  const onFileUpload = (event) => {
    const { target: { validity, files: [file], } } = event;
    if (validity.valid) {
      const fileExtension = file?.type.split("/")[1];
      const key = `chatBox_${+new Date()}.${fileExtension}`;
      setisLoading(true);
      getPreSignedUrl({ variables: { type: key } }).then((data) => {
        if (data?.data?.getPreSignedUrl) {
          fetch(data?.data?.getPreSignedUrl, {
            method: "PUT",
            headers: {
              "Content-Type": file?.type,
            },
            body: file,
          }).then((response) => {
            if (response?.ok && response?.status === 200) {
              setSelectedFile(`${BASE_URL}/${key}`)
              setFileName(file?.name);
              setisLoading(false);
            } else {
              setisLoading(false);
            }
          }).catch((errr) => {
            setisLoading(false);
          });
        } else {
          setisLoading(false);
        }
      }).catch((err) => {
        toast.error(err.message, {
          autoClose: 2000
        })
        setisLoading(false);
      });
    }
  }
  const toggleModal = () => {
    setSelectedFile(null);
  }

  return (
    <div className="pb-2 boxShadowRate mb-3 boxInnerMargin">
      <div className="messaging p-0 ">
        <div className="row inbox_msg p-0 ">
          <div className="col-md-4 ">
            <div className=" p-0">
              <div className="inbox_chat">
                {
                  GetCustomerTicket && GetCustomerTicket?.getCustomerTickets?.data?.length > 0 ?
                  GetCustomerTicket?.getCustomerTickets?.data.map((tkt) => {
                      return (
                        <div key={tkt?.id} className={`chat_list pointer ${selectedTicket?.ticketNo === tkt.ticketNo ? 'active_chat' : ''}`} onClick={() => { onTicketClick(tkt) }} >
                          <div className="chat_people">
                            <div className="chat_img"> <img className="chatProfileImg" src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                            <div className="chat_ib" style={{ width: '88%' }}>
                              <h5>Ticket No. {tkt.ticketNo} <span className="chat_date">{moment(tkt.createdAt).format("MMM DD")}</span></h5>
                              <p className="inputFs14 lastTicketMsg">{tkt?.conversationId.length > 0 ? tkt?.conversationId[tkt?.conversationId?.length - 1]?.message : ''}</p>
                            </div>
                          </div>
                        </div>
                      )
                    })
                    :
                    <div style={{ textAlign: 'center', padding: '10px' }}>
                      <span >No Tickets yet!</span>
                    </div>
                }
              </div>
              <div className="pull-right">
                <ReactPaginate
                  previousLabel={'Back'}
                  nextLabel={'Next'}
                  pageCount={GetCustomerTicket?.getCustomerTickets ? Math.ceil(GetCustomerTicket?.getCustomerTickets?.count / limit) : 1}
                  marginPagesDisplayed={2}
                  pageRangeDisplayed={5}
                  initialPage={0}
                  forcePage={pageNo}
                  onPageChange={(data) => handlePageClick(data)}
                  containerClassName={'pagination mb-0'}
                  subContainerClassName={'page-item'}
                  activeClassName={'active'}
                  pageLinkClassName={'pagination-link'}
                />
              </div>
            </div>
          </div>

          {selectedFile ?
            <div className='col-md-8'>
              <>
                <div className='image-preview-img-wrap'>
                  <div>
                    <button type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}
                      onClick={(e) => { toggleModal(e) }} >
                      <span aria-hidden="true">&times;</span>
                    </button>
                  </div>
                  <img src={selectedFile} alt="SelectedFile" style={{ maxWidth: '300px', maxHeight: '300px', marginTop: '10px' }} />
                </div>
                <div className="input_msg_write flex">
                  <input type="text"
                    value={chatMsg}
                    onChange={(e) => { setchatMsg(e.target.value) }}
                    className="write_msg pl-1 pr-3"
                    placeholder="Type a message"
                    onKeyDown={(e) => e.key === "Enter" ? onMessageSentClick(e, data?.getAdmin?.id) : ''}
                  />
                  <button className="msg_send_btn m-10 pointer p-1" type="button" onClick={(e) => { onMessageSentClick(e, data?.getAdmin?.id) }}>
                    <span>Send &nbsp;<i className="fa fa-paper-plane" aria-hidden="true"></i></span>
                  </button>
                </div>
              </>
            </div>
            :
            <>
              {selectedTicket &&
                <div className="col-md-8 col-xs-11 mesgs p-0 mb-2 chatRIghtWidth">
                  <div className="col-md-12 msgBoxTitleDiv pl-3 pt-3 pb-1 ">
                    <span className="float-left"> <img className="" src="https://ptetutorials.com/images/user-profile.png" width="40px" alt="sunil" /> </span>
                    <div className="chat_ib flex">
                      <div className="col-md-8">
                        <h5 className="mt-2">Ticket No. {selectedTicket?.ticketNo}
                        </h5>
                      </div>
                      <div className="col-md-4 flex p-0">
                        <div className="col-md-8 p-0">
                          <span className="chat_date float-right">
                            <p className="mt-0">Ticket Open Date</p>
                            <label className="inputFs14">{moment(selectedTicket?.createdAt).format("MM/DD/YYYY")}</label>
                          </span>
                        </div>

                        <div className="col-md-4 p-0">
                          {isStatus === 'closeCases' ?
                            <button className="ticketOpenBtn pointer float-right" onClick={(e) => onTicketAction(e, selectedTicket.id,  true)} >ReOpen</button>
                            :
                            <button className="ticketcloseBtn pointer float-right" onClick={(e) => onTicketAction(e, selectedTicket.id, false)}>Close</button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="msg_history p-3">
                    {(isLoading) && <Loader />}
                    {
                      conversations.map((msg, i) => {
                        if (msg.receiver === selectedTicket.customerId.id) {
                          return (
                            <div key={msg?.id} className="incoming_msg">
                              <div className="incoming_msg_img"> <img className="chatProfileImg" src="https://ptetutorials.com/images/user-profile.png" alt="sunil" /> </div>
                              <div className="received_msg">
                                <div className="received_withd_msg">
                                  {msg.fileUpload ?
                                    <div onClick={() => window.open(msg.fileUpload, '_blank')}>
                                      <img src={msg.fileUpload} alt="uploaded a file" className="msg-image" data-toggle="dropdown"></img>
                                      <p>{msg.message}</p>
                                    </div>
                                    :
                                    <p>{msg.message}</p>
                                  }
                                </div>
                                <span className="time_date">{moment(msg.createdAt).calendar()}</span>
                              </div>
                            </div>
                          )
                        } else {
                          return (
                            <div key={msg?.id} className="outgoing_msg">
                              <div className="sent_msg">
                                <div className='sent_msg-data'>
                                  {msg.fileUpload ?
                                    <div style={{ textAlign: 'right' }} onClick={() => window.open(msg.fileUpload, '_blank')}>
                                      <img src={msg.fileUpload} alt="uploaded a file" className="msg-image" data-toggle="dropdown"></img>
                                      <p>{msg.message}</p>
                                    </div>
                                    :
                                    <p>{msg.message}</p>
                                  }
                                </div>
                                <span className="time_date float-right">{moment(msg.createdAt).calendar()}</span> </div>
                            </div>)
                        }
                      })
                    }
                  </div>

                  <div className="type_msg">
                    {isStatus === 'closeCases' ?
                      <div className="input_msg_write">
                        <p className="inputFs14 text-center">This ticket is closed.</p>
                      </div>
                      :
                      <div className="input_msg_write flex">
                        <button onClick={() => _onFile()} className="attachmentIcon" >
                          <img src="../images/attach.png" alt="Img" width="20px" />
                          <input type="file" ref={inputElementRef} style={{ display: 'none' }}
                            onChange={(e) => onFileUpload(e)}
                          />
                        </button>

                        <input type="text"
                          value={chatMsg}
                          onChange={(e) => { setchatMsg(e.target.value) }}
                          className="write_msg pl-1 pr-3"
                          placeholder="Type a message"
                          onKeyDown={(e) => e.key === "Enter" ? onMessageSentClick(e, data?.getAdmin?.id) : ''}
                        />
                        <button className="msg_send_btn m-10 pointer p-1" type="button" onClick={(e) => { onMessageSentClick(e, data?.getAdmin?.id) }}>
                          <span>Send &nbsp;<i className="fa fa-paper-plane" aria-hidden="true"></i></span>
                        </button>
                      </div>
                    }
                  </div>
                </div>
              }
            </>
          }
        </div>
      </div>
    </div >
  )
}
