import { gql } from "@apollo/client";

const GET_FAQs = gql`
  query getFaqs($filter: String, $sort: sortFaqs){
    getFaqs(filter: $filter, sort: $sort){
        id
        question
        answer
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
  }
`;

export default GET_FAQs;