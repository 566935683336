import React from 'react';
import { groupBy } from 'lodash';

export const InvoiceBreakdown = ({ invoice }) => {
    const groupedData = groupBy(invoice?.OrderLevel, 'rateName');

    return (
        <div className="col-md-12 p-0">
            <div className="ibox">
                <div className="ibox-title">
                    <span>Invoice Breakdown</span>
                </div>
                <div className="ibox-content p-0">
                    <div className="table-responsive">
                        <table className="table themeTable table-nowrap mb-0">
                            <thead>
                                <tr>
                                    <th>&emsp;Sr No</th>
                                    <th>Rate</th>
                                    <th>Description</th>
                                    <th>Amount</th>
                                </tr>
                            </thead>
                            <tbody>
                                {invoice && invoice?.OrderLevel && invoice?.OrderLevel?.length > 0 &&
                                    <tr>
                                        <td colSpan={4} className="text-center tableHeaderLabel">Order Level</td>
                                    </tr>
                                }
                                {groupedData &&
                                    Object.keys(groupedData).map((rateName, index) => (
                                        <React.Fragment key={index}>
                                            <tr>
                                                <td>&emsp;{index + 1}</td>
                                                <td>{rateName}</td>
                                                <td>
                                                    {groupedData[rateName][0]?.description}
                                                </td>
                                                <td className="text-right pr20 me-5 pe-5">
                                                    {groupedData[rateName].reduce((acc, item) => acc + item.amount, 0)}
                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))}
                                {invoice && invoice?.ItemLevel && invoice?.ItemLevel?.length > 0 &&
                                    <tr>
                                        <td colSpan={4} className="text-center tableHeaderLabel">Item Level</td>
                                    </tr>
                                }
                                {
                                    invoice && invoice?.ItemLevel && invoice?.ItemLevel?.length > 0 &&
                                    invoice?.ItemLevel.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>&emsp;{i + 1}</td>
                                                <td>{d?.rateName}</td>
                                                <td>{d?.description}</td>
                                                <td className="text-right pr20">{d?.amount}</td>
                                            </tr>
                                        )
                                    })
                                }
                                {invoice && invoice?.ShipmentLevel && invoice?.ShipmentLevel?.length > 0 &&
                                    <tr>
                                        <td colSpan={4} className="text-center tableHeaderLabel">Shipment Level</td>
                                    </tr>
                                }
                                {invoice && invoice?.ShipmentLevel && invoice?.ShipmentLevel?.length > 0 &&
                                    invoice?.ShipmentLevel.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>&emsp;{i + 1}</td>
                                                <td>{d?.rateName}</td>
                                                <td>{d?.description}</td>
                                                <td className="text-right pr20">{d?.amount}</td>
                                            </tr>
                                        )
                                    })
                                }
                                {invoice && invoice?.Default && invoice?.Default?.length > 0 &&
                                    <tr>
                                        <td colSpan={4} className="text-center tableHeaderLabel">Default</td>
                                    </tr>
                                }
                                {invoice && invoice?.Default && invoice?.Default?.length > 0 &&
                                    invoice?.Default.map((d, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>&emsp;{i + 1}</td>
                                                <td>{d?.rateName}</td>
                                                <td>{d?.description}</td>
                                                <td className="text-right pr20">{d?.amount}</td>
                                            </tr>
                                        )
                                    })
                                }
                                <tr>
                                    <td className="text-center tableHeaderLabel text-black"></td>
                                    <td colSpan={2} className="text-center tableHeaderLabel">Total</td>
                                    <td className="text-center tableHeaderLabel text-black">{parseFloat(invoice?.totalAmount).toFixed(2)}</td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    )
}
