import { gql } from "@apollo/client";

export const INSERT_UPDATE_BOX_DIMENSIONS = gql`
mutation insertUpdateBoxDimensions($input:boxDimensionsInput!, $log: LogInput){
  insertUpdateBoxDimensions(input:$input, log: $log){
    	id
      name
      length
      width
      height
      weight
      dimensionalWeight
      cubicFeet
      isActive
      createdAt
  }
}
`;

export const DELETE_BOX_DIMENSIONS = gql`
mutation deleteBoxDimensions($id: ID!, $log: LogInput){
  deleteBoxDimensions(id:$id, log: $log){
    id
    name
    length
    width
    height
    weight
    dimensionalWeight
    cubicFeet
    countryId
    stateId
    isActive
  }
}
`;