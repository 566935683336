import { useMutation } from '@apollo/client'
import React from 'react'
import { INSERT_UPDATE_ONLINE_STORE } from '../../../../graphql/mutation/OnlineStoresMutation'
import { toast } from 'react-toastify'
import * as $ from 'jquery';
import Loader from '../../../components/Loader/Loader';

export const InserUpdateOnlineStore = ({ setOnlineStore, onlineStore, refetch }) => {
    const [insert_update_online_store, { loading }] = useMutation(INSERT_UPDATE_ONLINE_STORE)
    const TextChangeHandler = (e) => {
        setOnlineStore(prevonlineStore => ({
            ...prevonlineStore,
            storeData: {
                ...onlineStore.storeData,
                [e.target.name]: e.target.value
            }
        }))
    }
    const formOnSubmitOnlineStore = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            const { storeData, id, openModal, Old_Data } = onlineStore;
            let input = {};
            let old_value = {};
            let log = {};
            if (openModal === "Edit") {
                for (var obj in Old_Data) {
                    if (Old_Data[obj] !== storeData[obj]) {
                        old_value[obj] = Old_Data[obj]
                        input[obj] = storeData[obj]
                    }
                }
                input.id = id
            } else {
                input = { ...storeData }
            }
            log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'onlinestores',
                actionName: 'insertUpdateOnlineStore',
                oldValue: openModal === "Edit" ? JSON.stringify(old_value) : ''
            }
            insert_update_online_store({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                refetch();
                setOnlineStore(prevonlineStore => ({
                    ...prevonlineStore,
                    storeData: {}
                }))
                $('#StoreModal').modal('hide');
                form.reset();
                if (openModal === "Edit") {
                    toast.success("Online Store updated successfully!", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("Online Store added successfully!", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    }
    return (
        <div className="modal-content ">
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">{onlineStore?.openModal} Online Store</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        {loading && <Loader />}
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>{onlineStore?.openModal} Online Store</span>
                                </div>
                                <div className="ibox-content">
                                    <form id="frm_Store" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitOnlineStore(e)}>
                                        <div className="form-row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Store Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" name="name" value={onlineStore.storeData.name} onChange={e => TextChangeHandler(e)} required />
                                            </div>
                                            <div className="form-group col-xs-12">
                                                <div className="mt-30">
                                                    <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                    <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" >Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
