import { useMutation } from '@apollo/client';
import React from 'react'
import { DELETE_FAQs } from '../../../../graphql/mutation/FAQsMutation';
import { toast } from 'react-toastify';
import confirmDeletion from '../../../components/Common/BootBox';
import moment from 'moment';
import * as $ from 'jquery';

export const GetFaq = ({ setFaq, faq, FAQs,refetch }) => {
    const [deleteFAQ] = useMutation(DELETE_FAQs)

    const sortData = (sortKey) => {
        const newSortType = (faq.sortType === 1) ? -1 : 1;
        setFaq((prevBins) => ({ ...prevBins, sortKey: sortKey, sortType: newSortType }));
    };
    const editFAQ = (e, oldFaq) => {
        e.preventDefault();
        setFaq((prevBins) => ({
            ...prevBins, id: oldFaq.id,
            faqData: oldFaq,
            Old_Data: oldFaq,
            openModal: 'Edit'
        }));
        $('#frm_Faq').removeClass('was-validated');
        $('#FAQsModal').modal('show');
    }

    const deleteFAQsDetails = (event, id) => {
        event.preventDefault();
        const deleteFAQData = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'faqs',
                actionName: 'deleteFaqs',
                oldValue: ""
            }
            deleteFAQ({ variables: { id: id, "log": log } }).then(async ({ data }) => {
                refetch();
                $('#FAQsModal').modal('hide');
                toast.success("FAQ deleted successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion('Delete FAQs', 'Are you sure want to delete this record ?', deleteFAQData)
    }

    const { sortKey, sortType } = faq;
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0 table-faq tbl-sort">
                <thead>
                    <tr>
                        <th className="text-center">No.</th>
                        <th
                            className={`sortColumn ${(sortKey === 'createdAt') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('createdAt')} >Date</th>
                        <th
                            className={`sortColumn ${(sortKey === 'question') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('question')}>Question</th>
                        <th
                            className={`sortColumn ${(sortKey === 'answer') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('answer')} >Answer</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        FAQs && FAQs.length > 0
                            ? FAQs.map((d, i) => {
                                return (
                                    <tr key={d.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editFAQ(e, d)}>
                                        <td className=" text-center">{i + 1}</td>
                                        <td>{moment(d.createdAt).format('MM/DD/YYYY')}</td>
                                        <td>{d.question}</td>
                                        <td>{d.answer}</td>
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editFAQ(e, d)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2"
                                                    onClick={(e) => { deleteFAQsDetails(e, d?.id) }}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ textAlign: 'center' }} colSpan={5} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
