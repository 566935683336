import { useMutation } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { RESET_PASSWORD } from '../../graphql/mutation/SignUpMutation';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const ResetPassword = () => {
    const location = useLocation();
    const navigate = useNavigate();
    
    const [password, setPassword] = useState(null);
    const [confirmPassword, setConfirmPassword] = useState(null);
    const [token, settoken] = useState('');
    const [errorFlag, seterrorFlag] = useState(false);

    const [resetPassword] = useMutation(RESET_PASSWORD);

    useEffect(() => {
        let token = location.pathname.split("/");
        if(token?.length > 0){
            settoken(token[2]);
        }
    },[]);

   const onSubmit = (event) => {
        event.preventDefault();
        seterrorFlag(false);
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
              })
        } else {
            if (password !== confirmPassword) {
                toast.error('password and confirm password must be same...', {
                    autoClose: 2000
                  })
            } else {
                resetPassword({ variables: { token: token,password:password } }).then(async ({ data }) => {
                    if(data?.resetPassword === true){
                        toast.success('Your password has been changed.', {
                            autoClose: 2000
                          })
                        setTimeout(()=>{
                            navigate('/login');
                        },1000)
                    }else{
                        toast.error('Something went wrong.', {
                            autoClose: 2000
                          })
                    }
                }).catch((err) => {
                    if(err && !errorFlag)
                    {
                      toast.error(err.message, {
                        autoClose: 2000
                      })
                      seterrorFlag(true);
                    }
                })
            }
        }
    }
    return (
        <div className="container">
            <div className="col-md-12 box-cener clearfix">
                <div className="col-lg-6 col-md-6 col-sm-8 col-sm-12 ViewCard p-0">
                    <div className="logoView">
                        <img alt="Logo" src="../images/smsLogo.png" height="100" width="260" />
                    </div>
                    <form className="needs-validation" noValidate="noValidate" onSubmit={(event) => onSubmit(event)} >
                        <div className="form-group frm-Box">
                            <input type="password" className="form-control txt-Box" value={password} onChange={(e) => {setPassword(e.target.value) }} placeholder="Password" required />
                            <input type="password" className="form-control txt-Box" value={confirmPassword} onChange={(e) => {setConfirmPassword(e.target.value) }} placeholder="Confirm password" required />            
                            <button type="submit" className="btn btn-block btn-theme mb-20">RESET PASSWORD</button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}
