import { useMutation ,useApolloClient} from '@apollo/client'
import React, { useState } from 'react'
import { UPDATE_PASSWORD } from '../../../../graphql/mutation/ChangePasswordMutation'
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';

const ChangePasswordCom = () => {
  const navigate = useNavigate();
  const client = useApolloClient();
    const [errorFlag,seterrorFlag] =useState(false);
    const [oldPassword,setoldPassword] =useState('');
    const [newPassword,setnewPassword] =useState('');
    const [confirmPassword,setconfirmPassword] =useState('');
   const [updatePassword] = useMutation(UPDATE_PASSWORD);

  const onFrmSubmit = () => {
    seterrorFlag(false);
    if (oldPassword && newPassword && confirmPassword) {
      if (oldPassword === newPassword) {
        toast.error("Your old password and new password can't be same.", {
          autoClose: 2000
        })
      }
      else if (newPassword !== confirmPassword) {
        toast.error('Your new password and confirm password must be same.', {
          autoClose: 2000
        })
      }
      else {
        let input = {
          oldPassword: oldPassword,
          newPassword: newPassword
        }
        updatePassword({ variables: { input: input } }).then((data, error) => {
          if (error) {
            error.graphQLErrors.forEach(err => {
              toast.error(err.message, {
                autoClose: 2000
              })
            });
          } else {
            if (data) {
              localStorage.clear()
              sessionStorage.clear();
              client.resetStore();
              navigate('/login')
            }
            toast.success("Password updated.", {
              autoClose: 2000
            })
          }
        }).catch((err) => {
          if(err && !errorFlag)
          {
            toast.error(err.message, {
              autoClose: 2000
            })
            seterrorFlag(true);
          }
        })
      }
    } else {
      toast.error("Please, fill all the fields", {
        autoClose: 2000
      })
    }

  }
  return (
    <div className="container mrgnTop110">
    <div className='text-center'>
      <p className="headTitle">Change Password</p>
    </div>

              <div className="col-md-12">
                <div className="col-md-5 mAuto">
                  <div className="col-md-12 pb-3 addItemMain mt-30 extra">
                    <div className="mb-3 mt-3">
                      <label className="headText mt-2">Change Password</label>
                    </div>
                    <form id="changePasswordForm" onSubmit={onFrmSubmit}>
                      <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
                        <div className=" pl-0 pr-0">
                          <div className="col-md-12 mb-4 mt-2">
                            <input type='password' placeholder="Old Password" className="input p-0 col-12 inputFs14" name='oldPassword' onChange={(e) => setoldPassword(e.target.value)} />
                          </div>
                        </div>
                        <div className=" pl-0 pr-0">
                          <div className="col-md-12 mb-4">
                            <input type='password' placeholder="New Password" className="input p-0 col-12 inputFs14" name='newPassword' onChange={(e) => setnewPassword(e.target.value)} />
                          </div>
                        </div>
                        <div className="pl-0 pr-0">
                          <div className="col-md-12 mb-4">
                            <input type='password' placeholder="Confirm Password" className="input p-0 col-12 inputFs14" name='confirmPassword' onChange={(e) => setconfirmPassword(e.target.value)} />
                          </div>
                        </div>
                        <div className="col-md-12 mt-5 mb-2">
                          <button type='submit' className="sendBtn pointer">Save Changes</button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>

  </div>
  )
}
export default ChangePasswordCom
