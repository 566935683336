import { isEqual } from 'lodash'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'
import BusyBinsSelect from '../../components/DropdownSelect/BusyBinsSelect'
import ReciverList from '../../components/DropdownSelect/ReceiverSelect'
import FreeBinsList1 from '../../components/DropdownSelect/FreeBinsSelect';
import { useMutation, useQuery } from '@apollo/client'
import { GET_ORDERSFROMBIN, GET_STATUSOFBIN } from '../../../graphql/query/packingQuery'
import moment from 'moment'
import BoxPanel from '../../components/boxPanel'
import { INSERT_PACKAGEBOX } from '../../../graphql/mutation/packingMutation'
import ShipmentSelectList from '../../components/DropdownSelect/ShipSelect'

const EditBox = (props) => {
    const [skipQuery, setSkipQuery] = useState(true);
    const [SelectedPackageBoxDetailsDatas, setSelectedPackageBoxDetailsData] = useState();
    const [ReceiverId, setReceiverId] = useState('')
    const [ReceiverInfo, setReceiverInfo] = useState({})
    const [freeBinId, setfreeBinId] = useState("")
    const [freeBinNum, setFreeBinNum] = useState("")
    const [skipStatusBin, setSkipStatusBin] = useState(false)
    const [orderStatus, setOrderStatus] = useState(null)
    const [shipmentData, setShipmentData] = useState(null)
    const [orderFromBin, setOrderFromBin] = useState([]);
    const [orderItemId, setOrderItemId] = useState([]);
    const [Boxs, setBoxs] = useState([]);
    const [packageBoxId, setPackageBoxId] = useState("");
    const [searchBins, setSearchBins] = useState('');
    const [searchShipment, setSearchShipment] = useState('');
    const [selectedShipment, setSelectedShipment] = useState('');

    const { _ } = useQuery(GET_STATUSOFBIN, {
        variables: {
            customerId: SelectedPackageBoxDetailsDatas?.customerId,
        },
        skip: skipStatusBin,
        fetchPolicy: "no-cache",
        onCompleted: ({ getOrderStatus }) => {
            setOrderStatus(getOrderStatus)
            setSkipStatusBin(true)
        }

    });

    const { loading, error, data: { getOrdersFromBin } = {} } = useQuery(GET_ORDERSFROMBIN, {
        variables: {
            binId: props?.SelectedPackageBoxDetailsData?.oldBinId,
            curdType: "EDIT",

        },

        fetchPolicy: "cache-and-network",
    });

    const [updatePackageBox] = useMutation(INSERT_PACKAGEBOX);

    useEffect(() => {
        const OrdersFromBin = (getOrdersFromBin?.order || []).map((o) => ({
            ...o,
            items: o.items.map((item) => ({
                ...item,
                check: props?.SelectedPackageBoxDetailsData?.itemIds?.includes(item.id),
            })),
        }));
        const orderItemIds = [];
        (OrdersFromBin || []).map((orderBin) =>
            // eslint-disable-next-line array-callback-return
            orderBin.items.map((i) => {
                const itemIndex = orderItemIds.findIndex(
                    (unqOrder) => i.id === unqOrder.itemId
                );
                if (itemIndex === -1) {
                    orderItemIds.push({
                        item: i.description,
                        qty: i.qty,
                        availableQty: i.qty,
                        itemId: i.id,
                        check: props?.SelectedPackageBoxDetailsData?.itemIds?.includes(i.id),
                    });
                }
            })
        );
        setOrderItemId(orderItemIds);
        setOrderFromBin((prevOrderFromBin) => {
            if (!prevOrderFromBin.length) {
                return OrdersFromBin;
            } else if (!isEqual(prevOrderFromBin, OrdersFromBin)) {
                return OrdersFromBin;
            }
            return prevOrderFromBin;
        });
        if (!skipQuery) {
            setSkipQuery(true);
        }
    }, [getOrdersFromBin, SelectedPackageBoxDetailsDatas, props.isEdit, props?.SelectedPackageBoxDetailsData]);

    useEffect(() => {
        if (
            props?.SelectedPackageBoxDetailsData !== SelectedPackageBoxDetailsDatas ||
            skipQuery !== props.isEdit
        ) {


            setSelectedPackageBoxDetailsData(props.SelectedPackageBoxDetailsData);
            setSkipQuery(false);
            setPackageBoxId(props?.PackageBoxId);
            const newBoxs = props.SelectedPackageBoxDetailsData?.boxDimension
                ? props.SelectedPackageBoxDetailsData.boxDimension.map((box, i) => ({
                    ...box,
                    id: i,
                    orderItem: [],
                    selectItems: [],
                    qty: [],
                    // }))
                }) || [{ id: 0, orderItem: [], selectItems: [], qty: [] }])
                : null
            setBoxs((prevOrderFromBin) => {
                if (!prevOrderFromBin.length) {
                    return newBoxs;
                } else if (!isEqual(prevOrderFromBin, newBoxs)) {
                    return newBoxs;
                }
                return prevOrderFromBin;
            });

            setSelectedShipment({
                value: props?.SelectedPackageBoxDetailsData?.shipmentId,
                label: props?.SelectedPackageBoxDetailsData?.shipmentNo
            });

        }
    }, [props.SelectedPackageBoxDetailsData?.boxDimension?.length, skipQuery, SelectedPackageBoxDetailsDatas, props.isEdit, orderItemId]);

    const handelChangeFreeBins = (value) => {
        setfreeBinId(value.id)
        setFreeBinNum(value.name)
    }

    const reciverAddressHandler = (value) => {
        setReceiverId(value.id)
        setReceiverInfo({
            name: value.name1,
            address1: value.address1,
            address2: value.address2,
            city: value.city,
            closestCityId: value.closestCityId,
            stateId: value.stateId,
            countryId: value.countryId,
            zipCode: value.zipCode,
            phone: value.phone,
            customerId: value.customerId
        })
    }

    const updateSelectedPackageBoxDetailsData = () => {
        if (props.SelectedPackageBoxDetailsData) {
            setSelectedPackageBoxDetailsData(props.SelectedPackageBoxDetailsData)
            setReceiverId(props.SelectedPackageBoxDetailsData?.receiverId)
            setReceiverInfo(props?.SelectedPackageBoxDetailsData?.receiverInfo)
        }
    }



    useEffect(() => {
        const selectedShipment = {
            value: props?.SelectedPackageBoxDetailsData?.shipmentId,
            label: props?.SelectedPackageBoxDetailsData?.shipmentNo
        };
        setSelectedShipment(selectedShipment);
    }, [props.SelectedPackageBoxDetailsData.id]);

    const addNewBox = (e, item) => {
        e.preventDefault();
        const updatedBoxs = [...Boxs];
        let id = 0;
        if (updatedBoxs.length) id = updatedBoxs[updatedBoxs.length - 1]?.id + 1;
        let orderItem = [];
        let qty = [];
        if (updatedBoxs?.length) {
            orderItem = updatedBoxs[0].orderItem;
            // eslint-disable-next-line array-callback-return
            qty = updatedBoxs[0].qty.map((q) => {
                const matchItm = item.find((i) => i.itemId === q.itemId);
                if (matchItm !== undefined) return matchItm;
            });
        }
        updatedBoxs.push({ id, orderItem, selectItems: [], qty });
        setBoxs(updatedBoxs);
    };

    const isSelectedBoxDelete = (id) => {
        setBoxs(prevBoxs => {
            return prevBoxs.filter(box => box.id !== id);
        });
    };

    useEffect(() => {
        if (props.SelectedPackageBoxDetailsData.id) {
            selectAllCheckBoxHandler(false);
            updateSelectedPackageBoxDetailsData();
        }
    }, [props.SelectedPackageBoxDetailsData?.id]);

    const selectAllCheckBoxHandler = (allCheck = true) => {
        let updatedBoxs = [...Boxs];
        const updatedOrderFromBin = orderFromBin.map((orderBin) => ({
            ...orderBin,
            items: orderBin.items.map((item) => {
                updatedBoxs = updatedBoxs.map((box) => ({
                    ...box,
                    selectItems: [],
                    orderItem: box.orderItem.concat({
                        item: item.description,
                        itemId: item.id,
                    }),
                    qty: box.qty.concat({
                        item: item.description,
                        qty: item.qty,
                        availableQty: item.qty,
                        itemId: item.id,
                    }),
                }));
                updatedBoxs = updatedBoxs.map((box, i) => {
                    const selectItems = box.itemIds?.map((itemId) => {
                        const qunt = box.qty.find((sQty) => sQty.itemId === itemId.id);
                        if (qunt !== undefined) {
                            return {
                                label: qunt.item,
                                qty: qunt.qty,
                                availableQty: qunt.availableQty,
                                id: i,
                                value: qunt.itemId,
                            };
                        }
                    });
                    return { ...box, selectItems };
                });
                return { ...item, check: allCheck ? true : item.check };
            }),
        }));
        setOrderFromBin(updatedOrderFromBin);
        setBoxs(updatedBoxs);
    };



    const checkBoxHandler = (orderIndex, itemIndex, description, quantity, itemId) => {
        const updatedOrderFromBin = [...orderFromBin];
        updatedOrderFromBin[orderIndex].items[itemIndex].check = !updatedOrderFromBin[orderIndex].items[itemIndex].check;

        const updatedOrderItemId = [...orderItemId];
        const orderItemIndex = updatedOrderItemId.findIndex((item) => item.itemId === itemId);

        updatedOrderItemId[orderItemIndex].check = !updatedOrderItemId[orderItemIndex].check;

        const updatedBoxs = Boxs.map((b) => {
            const { id, orderItem, selectItems, qty } = b;
            return {
                ...b,
                id,
                orderItem: orderItem.map((o) => o),
                selectItems: [...selectItems],
                qty: qty.map((q) => q),
            };
        });

        if (updatedOrderFromBin[orderIndex].items[itemIndex].check) {
            Boxs.map((box) => {
                const { id, orderItem, selectItems, qty } = box;
                return {
                    ...box,
                    id,
                    orderItem: orderItem.push({ item: description, itemId }),
                    selectItems,
                    qty: qty.push({
                        item: description,
                        qty: quantity,
                        availableQty: quantity,
                        itemId,
                    }),
                };
            });
        } else {
            updatedBoxs.map((box) => {
                let { id, selectItems } = box;
                box["qty"] = box.qty.filter((q) => q.itemId !== itemId);
                box["orderItem"] = box.orderItem.filter((o) => o.itemId !== itemId);
                return {
                    ...box,
                    id,
                    selectItems,
                    orderItem: box["orderItem"],
                    qty: box["qty"],
                };
            });
        }

        setOrderFromBin(updatedOrderFromBin);
        setBoxs(updatedBoxs);
        setOrderItemId(updatedOrderItemId);
    };

    const boxHandler = (id, value ,weight) => {
        const Boxses = [...Boxs];
        Boxses[id].value = value.value;
        Boxses[id].len = value.length;
        Boxses[id].width = value.width;
        Boxses[id].height = value.height;
        Boxses[id].weight = weight;
        Boxses[id].dimensionalWeight = value.dimensionalWeight;
        Boxses[id].cubicFeet = value.cubicFeet;
        Boxses[id].isDimension = !!value.value;
        Boxses[id].boxDimensionId = value.value;
        setBoxs(Boxses)
    };

    const onBoxChangeHandler = (Id, value, name, itemId = "") => {
        const updatedBoxs = [...Boxs];
        const boxIndex = updatedBoxs.findIndex((b) => b.id === Id);
        if (boxIndex !== -1) {
            updatedBoxs[boxIndex][name] = value;
            const selectItmIndex = updatedBoxs[boxIndex].selectItems?.findIndex(
                (item) => (item?.itemId || item?.value) === itemId
            );

            if (selectItmIndex !== -1)
                updatedBoxs[boxIndex].selectItems[selectItmIndex]["qty"] = value;
        }
        setBoxs(updatedBoxs);

            if (
                name === "dimensionalWeight" ||
                name === "cubicFeet" ||
                name === "len" ||
                name === "height" ||
                name === "weight" ||
                name === "width" ||
                name === "rateIds"
            ) {
                return;
            }
            const updatedBoxses = updatedBoxs.map((b) => {
                const { id, orderItem, selectItems, qty } = b;
                return {
                    ...b,
                    id,
                    orderItem: [...orderItem],
                    selectItems: [...selectItems],
                    qty: qty.map((q) => ({
                        availableQty: q.availableQty,
                        item: q.item,
                        qty: q.qty,
                        itemId: q.itemId,
                    })),
                    [name]: b[name],
                };
            });
            let incBox = 0;
            Boxs.forEach((box) => {
                if (name in box) incBox += +box[name];
            });
    
            let newAvailableQty = 0;
            const Boxses = updatedBoxses.map((b) => {
                const { id, orderItem } = b;
                const qty = [...b.qty];
                const selectItems = [...b.selectItems];
                if (id >= Id) {
                    const isHasName = selectItems.findIndex(
                        (selectItm) => (selectItm?.itemId || selectItm?.value) === itemId
                    );
                    if (isHasName !== -1) {
                        const findQtyIndex = qty.findIndex((q) => q.itemId === itemId);
                        if (findQtyIndex !== -1) {
                            if (newAvailableQty) {
                                if (value) {
                                    if (+value > newAvailableQty) {
                                        qty[findQtyIndex].availableQty = -1;
                                        selectItems[isHasName].availableQty = -1;
                                    } else {
                                        qty[findQtyIndex].availableQty = Math.abs(
                                            +value - newAvailableQty
                                        );
                                        selectItems[isHasName].availableQty = Math.abs(
                                            +value - newAvailableQty
                                        );
                                    }
                                } else {
                                    qty[findQtyIndex].availableQty = newAvailableQty;
                                    selectItems[isHasName].availableQty = newAvailableQty;
                                }
                                newAvailableQty = qty[findQtyIndex].availableQty;
                            } else if (!newAvailableQty && value) {
                                if (+value > +qty[findQtyIndex].qty) {
                                    qty[findQtyIndex].availableQty = -1;
                                    selectItems[isHasName].availableQty = -1;
                                    newAvailableQty = qty[findQtyIndex].availableQty;
                                } else {
                                    qty[findQtyIndex].availableQty =
                                        +qty[findQtyIndex].qty - +value;
                                    selectItems[isHasName].availableQty =
                                        +qty[findQtyIndex].qty - +value;
                                    newAvailableQty = qty[findQtyIndex].availableQty;
                                }
                            } else {
                                if (newAvailableQty) {
                                    qty[findQtyIndex].availableQty = newAvailableQty;
                                    selectItems[isHasName].availableQty = newAvailableQty;
                                } else {
                                    qty[findQtyIndex].availableQty = +qty[findQtyIndex].qty;
                                    selectItems[isHasName].availableQty = +qty[findQtyIndex].qty;
                                }
                                newAvailableQty = qty[findQtyIndex].availableQty;
                            }
                        }
                    }
                    return {
                        ...b,
                        id,
                        orderItem: [...orderItem],
                        selectItems: selectItems,
                        qty: qty,
                        [name]: b[name],
                    };
                } else {
                    const isItem = selectItems?.findIndex(
                        (selectItm) => (selectItm?.itemId || selectItm?.value) === itemId
                    );
                    if (isItem !== -1) {
                        const findQtyIndex = qty.findIndex((q) => q.itemId === itemId);
                        if (findQtyIndex !== -1)
                            newAvailableQty = qty[findQtyIndex].availableQty;
                    }
                    return {
                        ...b,
                        id,
                        orderItem: [...orderItem],
                        selectItems: selectItems,
                        qty: qty,
                        [name]: b[name],
                    };
                }
            });
            setBoxs(Boxses)

    };

    const selectedItemsHandler = (id, value) => {
        const Box = Boxs.map((b) => {
            const { id, orderItem, selectItems, qty } = b;
            return {
                ...b,
                id,
                orderItem: [...orderItem],
                selectItems: [...selectItems],
                qty: [...qty],
            };
        });
        const boxIndex = Box.findIndex((b) => b.id === id);
        Box[boxIndex].selectItems = (value || []).map((v) => {
            const selctItem = Box[boxIndex].selectItems.find(
                (item) => item?.value === v?.value
            );
            if (selctItem !== undefined) return selctItem;
            return { id, label: v.label, value: v.value };
        });
        setBoxs(Box)
        if (value.length) {
            onBoxChangeHandler(
                id,
                "",
                value[value.length - 1].label,
                value[value.length - 1].value
            );
        }
    };

    const closeModal = (e) => {
        // e.preventDefault();
        setSelectedPackageBoxDetailsData(null)
        setOrderFromBin([])
        setReceiverId("")
        setfreeBinId("")
        setSkipStatusBin(false)
        setOrderStatus(null)
        setShipmentData(null)
        setOrderItemId([])
        setSkipQuery(false)
        setPackageBoxId("")
        props.closeModal()
    }

    const submitBoxDetails = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add("was-validated");
            toast.error("Please fill the required fields...");
            return false;
        } else {
            let check = false;
            for (const orderBin of orderFromBin) {
                if (check) break;
                for (const item of orderBin.items) {
                    if (item.check) {
                        check = true;
                        break;
                    }
                }
            }
            if (!check) {
                toast.error("Please select atleast one item");
                return false;
            }
            if (!Boxs.length) {
                toast.error("Please add atleast one Box...");
                return false;
            }

            let checkAvalQty = false;
            let BoxItem = [];
            // eslint-disable-next-line no-unused-vars
            for (const box of Boxs) {
                if (checkAvalQty) break;
                // eslint-disable-next-line no-unused-vars
                for (const quantity of box.qty) {
                    if (quantity.availableQty === -1) {
                        checkAvalQty = true;
                        break;
                    }
                    const boxItemIndex = BoxItem.findIndex(
                        (boxI) => boxI.itemId === quantity.itemId
                    );
                    if (boxItemIndex === -1 && quantity.item in box && box[quantity.item])
                        BoxItem.push({
                            availableQty: quantity.availableQty,
                            itemId: quantity.itemId,
                            qty: +box[quantity.item],
                        });
                    else if (quantity.item in box && box[quantity.item])
                        BoxItem[boxItemIndex].availableQty -= +box[quantity.item];
                }
            }
            if (checkAvalQty) {
                toast.error(
                    "Please make sure you only add a quantity thats not greater than available quantity"
                );
                return false;
            }
            BoxItem = BoxItem.filter((box) => box.availableQty !== box.qty);
            let checkQty = false;
            // eslint-disable-next-line no-unused-vars
            for (const box of BoxItem) {
                if (box.availableQty > 0) {
                    checkQty = true;
                    break;
                }
            }

            if (checkQty) {
                toast.error("Please make sure you added all available item quantity");
                return false;
            }
            const hasNullWeight = Boxs.some(box => box.weight === null || box.weight === undefined);
            if (hasNullWeight) {
                toast.error("Please make sure all box weights are filled out");
                return;
            }

            const boxData = Boxs.map((box) => ({
                box_Num: box?.box_Num || null,
                barcode: box?.barcode || null,
                boxDimensionId: box?.boxDimensionId || box?.value || null,
                isDimension: box?.isDimension || false,
                len: box?.len || null,
                width: box?.width || null,
                height: box?.height || null,
                weight: box?.weight || null,
                dimensionalWeight: box?.dimensionalWeight || null,
                cubicFeet: box?.cubicFeet || null,
                rateIds: box?.rateIds || null,
                itemIds: box?.selectItems?.map((item) => ({
                    id: item.value,
                    qty: item.qty,
                })),
            }));
            const receiverInfoData = ReceiverInfo || SelectedPackageBoxDetailsDatas?.receiverInfo;
            const removeTypenameAndCode = (obj) => {
                const newObj = {};
                for (const key in obj) {
                    if (key !== '__typename' && key !== 'code') {
                        if (typeof obj[key] === 'object' && obj[key] !== null) {
                            newObj[key] = removeTypenameAndCode(obj[key]);
                        } else {
                            newObj[key] = obj[key];
                        }
                    }
                }
                return newObj;
            };
            const cleanedReceiverInfo = removeTypenameAndCode(receiverInfoData);

            let udpateObj = {
                id: packageBoxId,
                shipmentId: shipmentData?.shipmentId || selectedShipment?.data?.value,
                shipmentNo: shipmentData?.shipmentNo || selectedShipment?.data?.label,
                binId: freeBinId || SelectedPackageBoxDetailsDatas?.binId,
                binNum: freeBinNum || SelectedPackageBoxDetailsDatas?.binNum,
                itemIds: orderItemId
                    .filter((item) => item.check)
                    .map((item) => item.itemId),
                receiverId: ReceiverId || SelectedPackageBoxDetailsDatas?.receiverId,
                receiverInfo: cleanedReceiverInfo,
                boxDimension: boxData,
                orderIds: orderFromBin.filter(d => d.items.find(e => orderItemId.filter((item) => item.check).map((item) => item.itemId).includes(e.id))).map(d => d.id),
            };

            let log = {
                action: "UPDATE",
                actionOn: "packageboxes",
                actionName: "insertUpdatePackageBox",
                oldValue: "",
            };
            updatePackageBox({ variables: { input: udpateObj, log } }).then(() => {
                form.classList.remove("was-validated");
                form.reset();
                setSelectedPackageBoxDetailsData(null)
                setOrderFromBin([])
                setReceiverId("")
                setfreeBinId("")
                setSkipStatusBin(false)
                setOrderStatus(null)
                setShipmentData(null)
                setOrderItemId([])
                // setSkipQuery(false)
                setPackageBoxId("")
                props.closeModal()
                toast.success("Package updated successfully!!", {
                    autoClose: 2000
                });
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    }

    const handleChangeShipment = (data) => {
        setSelectedShipment({ data })
    }
    const binsStatus = orderStatus || [];
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Package Details</span>
                        </div>
                        <div className="ibox-content">
                            <form
                                id="frm_EditBox"
                                className="needs-validation"
                                noValidate="noValidate"
                                onSubmit={(e) => { submitBoxDetails(e) }}
                            >
                                <div className="form-row">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">BIN No *</label>
                                        <BusyBinsSelect
                                            binId={SelectedPackageBoxDetailsDatas?.oldBinId}
                                            isEdit={true}
                                        />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">
                                            Customer | PMB *
                                        </label>
                                        <input
                                            type="text"
                                            className="form-control ffPoppins"
                                            name="customerPMB"
                                            id="customerPMB"
                                            placeholder="PLEASE SELECT BIN NO"
                                            value={
                                                SelectedPackageBoxDetailsDatas?.customerInfo
                                                    ? `${SelectedPackageBoxDetailsDatas?.customerInfo?.firstname} ${SelectedPackageBoxDetailsDatas?.customerInfo?.lastname} | ${SelectedPackageBoxDetailsDatas?.customerInfo?.pmb_no}` ||
                                                    ""
                                                    : ""
                                            }
                                            disabled
                                        />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Receiver Name *</label>
                                        <ReciverList
                                            customerId={
                                                SelectedPackageBoxDetailsDatas?.customerId || null
                                            }
                                            ReceiverId={
                                                ReceiverId ||
                                                SelectedPackageBoxDetailsDatas?.receiverId
                                            }
                                            getAddresschange={(id) =>
                                                reciverAddressHandler(id)
                                            }
                                        />
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Choose BINS *</label>
                                        <FreeBinsList1
                                            searchBins={searchBins}
                                            setSearchBins={(val) => setSearchBins(val)}
                                            limit={10}
                                            page={1}
                                            selectedOrderData={SelectedPackageBoxDetailsDatas}
                                            binId={
                                                freeBinId || SelectedPackageBoxDetailsDatas?.binId
                                            }
                                            existingBinId={{
                                                existingId: SelectedPackageBoxDetailsDatas?.binId,
                                                existingName:
                                                    SelectedPackageBoxDetailsDatas?.binNum,
                                            }}
                                            customerId={
                                                SelectedPackageBoxDetailsDatas?.customerId
                                            }
                                            getFreeBinsInfo={(id) =>
                                                handelChangeFreeBins(id)
                                            }
                                        />
                                        {binsStatus && (
                                            <>
                                                {binsStatus && binsStatus?.packedBinNos?.length > 0 && (
                                                    <span className="receiverAdd">
                                                        Bin No {binsStatus?.packedBinNos} IS Already Packed
                                                    </span>
                                                )}
                                                {binsStatus && binsStatus?.pendingBinNos?.length > 0 && (
                                                    <span className="receiverAdd">
                                                        And Bin No {binsStatus?.pendingBinNos} Is Pending.
                                                    </span>
                                                )}
                                            </>
                                        )}
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Shipment No</label>
                                        <ShipmentSelectList
                                            limit={10}
                                            page={1}
                                            searchShipment={searchShipment}
                                            setSearchShipment={(val) => setSearchShipment(val)}
                                            selectedShipment={selectedShipment}
                                            getShipmentInfo={(info) => handleChangeShipment(info)}
                                            getShipId={(shipData) => {
                                                setShipmentData({
                                                    shipmentId: shipData && shipData.value,
                                                    shipmentNo: shipData && shipData.label,
                                                })
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">OverPack No</label>
                                        <input
                                            type="text"
                                            className="form-control ffPoppins"
                                            name="overpackNo"
                                            id="overpackNo"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div className="table-responsive">
                                    <table className="table themeTable table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-center">#</th>
                                                <th>Order Date</th>
                                                <th>Customer Email</th>
                                                <th>Mobile</th>
                                                <th>Receiver</th>
                                                <th>Qty</th>
                                                <th>Instruction</th>
                                                <th>Order Item</th>
                                                <th>Status</th>
                                            </tr>
                                        </thead>
                                        {/* {orderFromBin.length > 0 && orderFromBin.map((order, j) => { */}

                                        <tbody className="border-bottom">
                                            {orderFromBin.length ?

                                                orderFromBin.map((order, j) => {
                                                    if (order.receiverId === (ReceiverId || SelectedPackageBoxDetailsDatas?.receiverId)) {
                                                        return order.items.map((item, i) => {
                                                            const statusImg = {
                                                                "pending": "../images/pending.png",
                                                                "received": "../images/Received.png",
                                                                "scamHold": "../images/onHold.png",
                                                                "customerHold": "../images/customerHold.png",
                                                                "shipped": "../images/shipping.png",
                                                                "refuesd": "../images/refused.png",
                                                                "missed": "../images/missed.png",
                                                                "packed": "../images/packed.png",
                                                                "partialRecived": "../images/partial_received.png"
                                                            }[item.itemStatus] || "";
                                                            return (
                                                                <tr key={`${item.itemStatus}-${i}`}>
                                                                    <td className="text-center">
                                                                        <input type="checkbox" checked={item?.check} onChange={() => checkBoxHandler(j, i, item?.description, item?.qty, item?.id)} disabled={item?.itemStatus === 'shipped' || (item.itemStatus === 'packed' && !SelectedPackageBoxDetailsDatas?.itemIds?.includes(item?.id))} />
                                                                    </td>
                                                                    <td>{moment(order.createdAt).format("MM-DD-YYYY")}</td>
                                                                    <td>{order.customerInfo?.email}</td>
                                                                    <td>{order.receiverAddress?.phone}</td>
                                                                    <td>{order.receiverAddress?.name}</td>
                                                                    <td>{item.qty}</td>
                                                                    <td>{item.instruction}</td>
                                                                    <td>{item.description}</td>
                                                                    <td>
                                                                        {item.isPacked && item.itemStatus !== "shipped" ? (
                                                                            <span>
                                                                                <img src="../images/packed.png" alt="packed" className="width18" />
                                                                                <span className="statusLabel text-packed">Packed</span>
                                                                            </span>
                                                                        ) : (
                                                                            <span>
                                                                                <img src={statusImg} alt={item.itemStatus} className="width18" />
                                                                                <span className={`statusLabel text-${item?.itemStatus}`}>{item?.itemStatus?.charAt(0).toUpperCase() + item.itemStatus.slice(1)}</span>
                                                                            </span>
                                                                        )}
                                                                    </td>
                                                                </tr>
                                                            );
                                                        });
                                                    } else {
                                                        return null;
                                                    }
                                                }) : null
                                            }


                                        </tbody>
                                    </table>
                                </div>
                                <div className="col-md-12 p-0 mt-3 mb-3">
                                    <button
                                        className="btn btn-success btn-text bg-lightGreen"
                                        onClick={(e) => addNewBox(e, orderItemId)}
                                    >
                                        <i className="fa fa-plus"></i> Add More Package
                                    </button>
                                </div>
                                {(Boxs || []).map((b_obj, i) => {
                                    return (
                                        <div key={b_obj.id + "-" + i} className="mb-4">
                                            <BoxPanel
                                                id={b_obj.id}
                                                selectedValue={b_obj}
                                                boxChange={boxHandler}
                                                BoxChangeHandler={onBoxChangeHandler}
                                                isDeleteBox={(id) =>
                                                    isSelectedBoxDelete(id)
                                                }
                                                isEdit={props?.isEdit}
                                                shipmentData={shipmentData}
                                                customerInfo={
                                                    SelectedPackageBoxDetailsDatas?.customerInfo
                                                }
                                                receiverInfo={ReceiverInfo}
                                                selectItemsChange={selectedItemsHandler}
                                                selectItems={b_obj.selectItems}
                                                selectQty={b_obj.qty}
                                                itemIds={b_obj.itemIds}
                                            />
                                        </div>
                                    );
                                })}
                                <div className="form-group col-lg-4 col-md-6 col-xs-12 col-sm-8 p-0">
                                    {!SelectedPackageBoxDetailsDatas?.shipmentId ? (
                                        <button
                                            type="submit"
                                            className="btn btn-primary btn-text mt-3 mr-4 border-0 bg-searchBlue"
                                        >
                                            Save
                                        </button>
                                    ) : null}
                                    <button
                                        type="reset"
                                        className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed"
                                        data-dismiss="modal"
                                        onClick={(e) => closeModal(e)}
                                    >
                                        Cancel
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div >
        </div >
    )
}

export default EditBox