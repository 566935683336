import { gql } from "@apollo/client";

const GET_USERS = gql`
query getUsers($page: Int, $limit: Int, $filter: String,$sort:userSort){
  getUsers(page: $page,limit: $limit, filter: $filter,sort:$sort){
    count
    data {
      id
      firstName
      lastName
      email
      phone
      roles
      userType
      isActive
      createdAt
      createdBy
    }
  }
}
`;

export default GET_USERS;