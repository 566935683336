import React, { useState, useEffect, useCallback } from 'react';
import Select from 'react-select';
import _ from 'lodash';
import { OrderStatusType } from './Constant';
import RatesList from './DropdownSelect/RatesSelect';
import { toast } from 'react-toastify';

const ItemList = (props) => {
  const genSettings = JSON.parse(localStorage.getItem('generalSettings'));
  const [state, setState] = useState({
    description: '',
    qty: '',
    price: '',
    total: '',
    instruction: '',
    insureprice: '',
    rate: null,
    isInsure: false,
    insurerate: null,
    itemStatus: OrderStatusType[0]?.value,
    temp_itemStatus: OrderStatusType[0],
    searchRate: ''
  });

  useEffect(() => {
    const settings = JSON.parse(localStorage.getItem('generalSettings'));
    setState((prevState) => ({
      ...prevState,
      insurerate: settings?.orderInsurancePercentage
    }));
    updateState();
  }, []);

  useEffect(() => {
    updateState();
  }, [props.SingleItemData]);

  const updateState = () => {
    const { SingleItemData, id, curdType, Dashboard } = props;
    const { insurerate } = state;

    props.ItemStatus(id, SingleItemData.itemStatus);
    
    if (insurerate) {
      props.ItemInsurerate(insurerate.toString());
    }

    if ((SingleItemData && id && curdType === "Edit") || (SingleItemData && id && Dashboard)) {
      setState((prevState) => ({
        ...prevState,
        description: SingleItemData.description ? SingleItemData.description : '',
        qty: SingleItemData.qty ? SingleItemData.qty : '',
        price: SingleItemData.price ? SingleItemData.price : '',
        total: SingleItemData.total ? SingleItemData.total : '',
        rate: SingleItemData.rate ? SingleItemData.rate : null,
        instruction: SingleItemData.instruction ? SingleItemData.instruction : '',
        isInsure: SingleItemData.isInsure,
        insurerate: SingleItemData.insurerate || genSettings?.orderInsurancePercentage,
        insureprice: SingleItemData.insureprice
      }));
    }
  };

  useEffect(() => {
    if(props.customerReceiverType === 'verified'){
      props.ItemTotal(id, state.total.toString())
          if (state.isInsure) {
            props.ItemInsurerate(id, state.insurerate.toString());
          }
          if (state.isInsure) {
            props.ItemInsurePrice(id, state.insureprice.toString());
          }
    }else{
      props.ItemTotal(id, state.total.toString());
    }

  },[state.total,state.insureprice]);
  const getTotal_INS = (qty,price) => {
    const {insurerate, isInsure } = state;
    const { customerReceiverType, id } = props;
    console.log("state",state)
    if (qty && price) {
      console.log('====qty', qty)
      console.log('====price', price)

      let totalPrice = qty * price;
      
      if (customerReceiverType === "verified") {
        let insureprice = (totalPrice * insurerate) / 100;
        setState((prevState) => ({
          ...prevState,
          total: totalPrice.toFixed(2),
          insureprice: insureprice.toFixed(2)
        }));
         
        // setTimeout(() => {
        //   props.ItemTotal(id, totalPrice.toString())
        //   if (isInsure) {
        //     props.ItemInsurerate(id, insurerate.toString());
        //   }
        //   if (isInsure) {
        //     props.ItemInsurePrice(id, insureprice.toString());
        //   }
        // }, 100);
        
      } else {
        setState((prevState) => ({
          ...prevState,
          total: totalPrice.toFixed(2)
        }));
        // setTimeout(() => {
        //   props.ItemTotal(id, totalPrice.toString());
        // }, 200);
        
      }
    }
  }

  const handleChangeItemStatus = (value) => {
    props.ItemStatus(props.id, value.value);
  };

  const selectItemINS = (e) => {
    const { total, insurerate } = state;
    let value = e.target.checked;
    setState((prevState) => ({
      ...prevState,
      isInsure: value
    }));

      if (value && total) {
        let insureprice = (total * insurerate) / 100;
        setState((prevState) => ({
          ...prevState,
          insureprice: insureprice
        }));
        props.ItemInsurePrice(props.id, insureprice.toString());
      }
      props.ItemSelectINS(props.id, value);
  };

  const updateSelectedItemStatus = (e) => {
    const { id } = props;
    props.UpdateStatusSelection(id, e.target.checked);
  };

  const handelChangeRate = (rateId) => {
    const { id } = props;
    setState((prevState) => ({
      ...prevState,
      rate: rateId
    }));
    props.ItemRateId(id, rateId);
  };

  const deleteItem = (id) => {
    props.isItemDelete(id);
  };

  let { rowBgColor, customerReceiverType, id, curdType, SingleItemData, isCheckValue } = props;
  const { description, qty, price, total, rate, instruction, isInsure, temp_itemStatus, insurerate, insureprice, searchRate } = state;

  let SelectedStatus = temp_itemStatus;
  let SelectedInsure = isInsure;

  if (curdType === "Edit") {
    if (SingleItemData && SingleItemData.isInsure === isInsure) {
      SelectedInsure = isInsure;
    } else {
      SelectedInsure = SingleItemData.isInsure;
    }
  }

  SelectedStatus = _.find(OrderStatusType, (d) => d.value === SingleItemData.itemStatus);

  return (
    <div style={{ position: 'relative' }} className={`row m-0 itemBox mb-2 ` + rowBgColor}>
      <div className="form-group col-md-3">
        <div className="row">
          <div className="col-md-2 p-0 d-flex justify-content-center align-items-center">
            {rowBgColor !== 'isNew' &&
              <input type="checkbox" className="mt-4 ml-2" name="SelectAllBoxToShip" id="SelectAllBoxToShip" checked={isCheckValue} onChange={(e) => updateSelectedItemStatus(e)} />
            }
          </div>
          <div className="col-md-10 p-0">
            <label className="frm-label">Item Description<span className="text-danger">*</span></label>
            <input type="text" className="form-control ffPoppins" placeholder="Enter Item Description" name="description" value={description} onChange={(e) => {
              props.ItemDes(id, e);
              setState((prevState) => ({
                ...prevState,
                description: e.target.value
              }));
            }} required />
          </div>
        </div>
      </div>
      <div className="form-group col-md-1">
        <label className="frm-label">QTY<span className="text-danger">*</span></label>
        <input type="number" className="form-control ffPoppins" placeholder="Enter QTY" name="qty" value={qty} onChange={(e) => {
          props.ItemQty(id, e);
          setState((prevState) => ({
            ...prevState,
            qty: e.target.value
          }));
          getTotal_INS(e.target.value, state.price);
        }} required />
      </div>
      <div className="form-group col-md-1">
        <label className="frm-label">Price<span className="text-danger">*</span></label>
        <input type="number" className="form-control ffPoppins" placeholder="Enter Price" name="price" value={price} onChange={(e) => {
          props.ItemPrice(id, e);
          setState((prevState) => ({
            ...prevState,
            price: e.target.value
          }));
          getTotal_INS(state.qty, e.target.value);
        }} required />
      </div>
      <div className="form-group col-md-1">
        <label className="frm-label">Total</label>
        <input type="text" className="form-control ffPoppins" placeholder="Enter Total" name="total" value={total} />
      </div>
      <div className="form-group col-md-2">
        <label className="frm-label">SP Instruction</label>
        <input type="text" className="form-control ffPoppins" placeholder="Enter SP Instruction" name="instruction" value={instruction} onChange={(e) => {
          props.ItemSPInst(id, e);
          setState((prevState) => ({
            ...prevState,
            instruction: e.target.value
          }));
        }} />
      </div>
      {customerReceiverType === "verified" &&
        <div className="form-group col-md-2">
          <label className="frm-label">Status</label>
          <Select
            className="ffPoppins"
            options={OrderStatusType}
            value={SelectedStatus}
            onChange={(data) => handleChangeItemStatus(data)}
          />
        </div>
      }
      {customerReceiverType === "verified"
        ? <div className="form-group col-md-2">
          <span>
            <input type="checkbox" className="mr-2" name="SelectINS" id="SelectINS" value={SelectedInsure} defaultChecked={SelectedInsure} onChange={(e) => selectItemINS(e)} />
            <label className="frm-label"> INS ({insurerate}%) {(isInsure && insureprice) && `= $ ${insureprice}`}</label>
          </span>
          {curdType === "Edit" &&
            <RatesList
              page={1}
              limit={10}
              searchRate={searchRate}
              RatesId={rate}
              rateType="Order Item"
              getOrderRateId={id => handelChangeRate(id)}
              setSearchRate={(val) => setState((prevState) => ({ ...prevState, searchRate: val }))}
            />
          }
        </div>
        : curdType === "Edit" &&
        <div className="form-group col-md-2">
          <label className="frm-label">Rate Type</label>
          <RatesList
            page={1}
            limit={10}
            searchRate={searchRate}
            RatesId={rate}
            rateType="Order Item"
            getOrderRateId={id => handelChangeRate(id)}
            setSearchRate={(val) => setState((prevState) => ({ ...prevState, searchRate: val }))}
          />
        </div>
      }
      <i className="fa fa-times order-item-close" aria-hidden="true" onClick={() => deleteItem(id)}></i>
    </div>
  );
};

export default ItemList;
