import React from 'react'
import { Link } from 'react-router-dom'

const SideBar = () => {
    const isActivePath = window.location.pathname;
    return (
        <div className="side-content-wrap">
            <section className="ps ps-container sidebar-left open">
                <ul className="navigation-left">
                    {[
                        { id: 'Customer', icon: 'Customer.png', activeIcon: 'ActiveCustomer.png', text: 'Customers', link: '/customer' },
                        { id: 'Orders', icon: 'Orders.png', activeIcon: 'ActiveOrder.png', text: 'Orders', link: '/order' },
                        { id: 'Packing', icon: 'Box.png', activeIcon: 'ActiveBox.png', text: 'Packing', link: '/packing' },
                        { id: 'Shipment', icon: 'Shipment.png', activeIcon: 'ActiveShipment.png', text: 'Shipment', link: '/shipment' },
                        { id: 'Report', icon: 'Report.png', activeIcon: 'ActiveReport.png', text: 'Report', link: '/report' },
                        { id: 'Ticket', icon: 'Ticket.png', activeIcon: 'ActiveTicket.png', text: 'Ticket', link: '/ticket' },
                    ].map(({ id, icon, activeIcon, text, link }) => (
                        <li className="nav-item" key={id}>
                            <Link to={link} className="nav-item-hold" data-id={id}>
                                <img src={`../images/${(isActivePath === link) ? activeIcon : icon}`} alt="missImg" className="nav-icon" />
                                <span className={`nav-text txt-btn ${(isActivePath === link) && 'text-white'}`}>{text}</span>
                            </Link>
                        </li>
                    ))}
                    <li className="nav-item">
                        <Link to="/category" className="nav-item-hold Store" data-id="Store">
                            {
                                isActivePath === "/category" || isActivePath === "/sub-category" || isActivePath === "/product"
                                    ? <img src="../images/ActiveStore.png" alt="missImg" className="nav-icon" />
                                    : <img src="../images/store.png" alt="missImg" className="nav-icon" />
                            }
                            {
                                isActivePath === "/category" || isActivePath === "/sub-category" || isActivePath === "/product"
                                    ? <span className="nav-text txt-btn text-white">Store</span>
                                    : <span className="nav-text txt-btn">Store</span>
                            }
                            <div className="arrow"></div>
                        </Link>
                        <ul className="store-menu">
                            <li className="store-list">
                                <Link to="/category"><span className="linav-text">Category</span></Link>
                            </li>
                            <li className="store-list border-top border-bottom">
                                <Link to="/sub-category"><span className="linav-text">Sub Category</span></Link>
                            </li>
                            <li className="store-list">
                                <Link to="/product"><span className="linav-text">Product</span></Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </section>
        </div>
    )
}

export default SideBar