import { useQuery } from '@apollo/client';
import React from 'react'
import { GET_ALL_RATES_TYPES } from '../../../../graphql/query/RatesQuery';
import { RatesComp } from './RatesComp';
const CustomerRates = () => {
    const { data} = useQuery(GET_ALL_RATES_TYPES, {
        fetchPolicy: 'cache-and-network'
    });
    return (
        <div className="container mrgnTop110">
            <div className='text-center'>
                <p className="headTitle">Rates</p>
            </div>
            <div className="col-md-8 mAuto pb-2 invMain mt-30 extra">
                <div className="mb-3 mt-3">
                    <label className="headText mt-2">Rates List</label>
                </div>
                {
                    data && data?.getAllRatesList.length > 0 ?
                        data?.getAllRatesList.map((rate,key) => {
                            return (
                                <RatesComp rate={rate} key={key}/>
                            )
                        })
                    :
                    <div className="boxShadowRate boxInnerMargin">
                        <div className="text-center">
                            <img src="./images/notFound.png" alt="not found"></img>
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}
export default CustomerRates