import { useMutation } from '@apollo/client'
import React from 'react'
import { INSERTUPDATE_SUBCATEGORY } from '../../../../graphql/mutation/CategoryMutation'
import Loader from '../../../components/Loader/Loader'
import { getS3SignUrl } from '../../../../graphql/query/TicketQuery'
import Categories from '../../../components/DropdownSelect/CategorySelect'
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import { BASE_URL } from '../../../../config'


export const InsertUpdateSubCategory = ({ subCategory, setSubCategory, refetch }) => {
    const [insertUpdateSubCategory, { loading }] = useMutation(INSERTUPDATE_SUBCATEGORY)
    const [getPreSignedUrl] = useMutation(getS3SignUrl);
    const handelChangeCategory = (id) => {
        setSubCategory(preData => ({
            ...preData, temp_categoryId: id,
            subCategoryData: {
                ...preData.subCategoryData,
                categoryId: id
            }
        }))
    }

    const TextChangeHandler = (e) => {
        setSubCategory(preData => ({
            ...preData,
            subCategoryData: {
                ...preData.subCategoryData,
                [e.target.name]: e.target.value
            }

        }))
    }
    const ChangeSubCategoryIcon = (event) => {
        const { target: { validity, files: [file], } } = event;
        if (validity.valid) {
            const fileExtension = file?.type.split("/")[1];
            const key = `ChangeSubCategoryIcon_${+new Date()}.${fileExtension}`;
            setSubCategory(preData => ({ ...preData, isLoading: true }))
            getPreSignedUrl({ variables: { type: key } }).then((data) => {
                if (data?.data?.getPreSignedUrl) {
                    fetch(data?.data?.getPreSignedUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": file?.type,
                        },
                        body: file,
                    }).then((response) => {
                        if (response?.ok && response?.status === 200) {
                            setSubCategory(preData => ({
                                ...preData, isLoading: false, subCategoryData: {
                                    ...preData.subCategoryData,
                                    icon: `${BASE_URL}/${key}`
                                },
                            }))
                        } else {
                            setSubCategory(preData => ({ ...preData, isLoading: false }))
                        }
                    }).catch((errr) => {
                        setSubCategory(preData => ({ ...preData, isLoading: false }))
                    });
                } else {
                    setSubCategory(preData => ({ ...preData, isLoading: false }))
                }
            }).catch((err) => {
                setSubCategory(preData => ({ ...preData, isLoading: false }))
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    }

    const formOnSubmitSubCategory = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const { subCategoryData, Old_Data, openModal, id } = subCategory;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            let input = {};
            let old_value = {};
            let log = {};
            if (openModal === "Edit") {
                for (var obj in Old_Data) {
                    if (subCategoryData[obj] !== Old_Data[obj]) {
                        old_value[obj] = Old_Data[obj]
                        input[obj] = subCategoryData[obj]
                    }
                }
                input.id = id;
            }
            if (openModal === "Add New") {
                input = { ...subCategoryData };
            }
            log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'subCategories',
                actionName: "insertUpdateSubCategory",
                oldValue: openModal === "Edit" ? JSON.stringify(input) : ""
            }
            insertUpdateSubCategory({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                form.classList.remove('was-validated');
                form.reset();
                setSubCategory(preData => ({
                    ...preData, subCategoryData: {},
                    temp_categoryId: null
                }))
                refetch();
                $('#SubCategoryModal').modal('hide');
                if (openModal === "Edit") {
                    toast.success("Sub Category updated successfully!", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("Sub Category added successfully!", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    }
    return (
        <div className="ibox">
            {(loading || subCategory?.isLoading) && <Loader />}
            <div className="ibox-title">
                <span>{subCategory?.openModal} Sub-Category</span>
            </div>
            <div className="ibox-content">
                <form id="frm_SubCategory" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitSubCategory(e)}>
                    <div className="form-row">
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Select Category<span className="text-danger">*</span></label>
                            <Categories categoryId={subCategory?.temp_categoryId} getCategoryId={(id) => handelChangeCategory(id)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">SubCategory Name<span className="text-danger">*</span></label>
                            <input type="text" className="form-control ffPoppins" name="name" value={subCategory?.subCategoryData.name} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">SubCategory Icon<span className="text-danger">*</span></label><br />
                            <span className="btn btn-default btn-file btn-sm btn-danger ffPoppins mt-1">
                                <i className="fa fa-upload"></i> Choose a File <input type="file" onChange={(e) => ChangeSubCategoryIcon(e)} required={subCategory?.openModal === "Edit" ? false : true} />
                            </span>

                            {
                                subCategory?.subCategoryData?.icon
                                    ? <div>
                                        <label className="frm-label">Upload Category Icon</label><br />
                                        <div className="mt-3">
                                            <img src={subCategory?.subCategoryData.icon} alt="missImg" className="width70 img-thumbnail" />
                                        </div>
                                    </div>
                                    : <div className="mt-3">
                                        <img src="../images/noImage.png" alt="img" className="width70 img-thumbnail" />
                                    </div>
                            }
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="mt-3">
                            <button type="submit" className="btn btn-primary btn-text mr-3 border-0 bg-searchBlue" >Save</button>
                            <button type="reset" className="btn btn-danger btn-text bg-resetRed" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
