import React from 'react';
import { useQuery } from '@apollo/client';
import { GET_ME } from '../../../graphql/query/SessionQuery';
import Loader from '../Loader/Loader';

const withSession = Component => props => {
  const { data, refetch } = useQuery(GET_ME);
  if(!data) {
    return <Loader />
  }
  return <Component {...props} session={data} refetch={refetch} />;
};

export default withSession;
