import { gql } from "@apollo/client";

export const GET_ALL_BINS = gql`
  query geAllBinList{
    geAllBinList{
      id
      bin
      isFree
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;


export const GET_BUSY_BINS = gql`
  query getBusyBinList{
    getBusyBinList{
      id
      bin
      isFree
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;

export const GET_FREE_BINS = gql`
  query getFreeBinList($customerId: ID , $page: Int , $limit: Int , $filter: String , $id: ID){
    getFreeBinList(customerId:$customerId, page:$page , limit:$limit , filter:$filter , id:$id){
      id
      bin
    }
  }
`;

export const GET_ASSIGN_BIN_ORDER = gql`
query getCustomerAssignBinOrderDeatils($customerId: String){
  getCustomerAssignBinOrderDeatils(customerId:$customerId){
    customerAssignBin
  }
}
`;
export const GET_BINS = gql`
  query getBins($page: Int, $limit: Int, $filter: String, $sort: Sort){
    getBins(page: $page, limit: $limit, filter: $filter, sort: $sort){
      count
      data {
        id
        bin
        isFree
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;
