import React from 'react'
import { DELETE_ONLINE_STORE } from '../../../../graphql/mutation/OnlineStoresMutation';
import { useMutation } from '@apollo/client';
import confirmDeletion from '../../../components/Common/BootBox';
import { toast } from 'react-toastify';
import * as $ from 'jquery';

export const GetOnlineStore = ({ store, onlineStore, setOnlineStore, refetch }) => {
    const [deleteOnlineStore] = useMutation(DELETE_ONLINE_STORE)
    const sortData = (sortKey) => {
        const newSortType = (onlineStore.sortType === 1) ? -1 : 1;
        setOnlineStore((prevBins) => ({ ...prevBins, sortKey: sortKey, sortType: newSortType }));
    };
    const editOnlineStore = (e, oldStoreData) => {
        e.preventDefault();
        setOnlineStore((prevBins) => ({
            ...prevBins, id: oldStoreData.id,
            storeData: oldStoreData,
            Old_Data: oldStoreData,
            openModal: 'Edit'
        }));
        $('#frm_Store').removeClass('was-validated');
        $('#StoreModal').modal('show');

    }
    const deleteStoreDetails = (event, storeId) => {
        const deleteOnlineStoreData = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'onlinestores',
                actionName: 'deleteOnlineStore',
                oldValue: ""
            }
            deleteOnlineStore({ variables: { "id": storeId, "log": log } }).then(async ({ data }) => {
                refetch();
                $('#StoreModal').modal('hide');
                toast.success("Online Store deleted successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion('Delete OnlineStores', 'Are you sure want to delete this record ?', deleteOnlineStoreData)
    }
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0 tbl-sort">
                <thead>
                    <tr>
                        <th className="text-center">No.</th>
                        <th
                            className={`sortColumn ${(onlineStore?.sortKey === 'name') ? (onlineStore?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('name')} >Store Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        store && store.data && store.data.length > 0
                            ? store.data && store.data.map((d, i) => {
                                return (
                                    <tr key={d.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editOnlineStore(e, d)}>
                                        <td className=" text-center">{i + 1}</td>
                                        <td>{d.name}</td>
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editOnlineStore(e, d)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2"
                                                    onClick={(e) => { deleteStoreDetails(e, d.id); }}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ textAlign: 'center' }} colSpan={5} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
