import React, { useState } from 'react'
import { toast } from 'react-toastify';
import * as $ from 'jquery';

export const ChangeAdminUserPassword = ({ id, insertUpdateUser }) => {
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');

    const formOnSubmitResetAdminUserPassword = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            if (password === confirmPassword) {
                let input = {
                    password: password,
                    id: id
                }
                let log = {
                    action: 'UPDATE',
                    actionOn: 'users',
                    actionName: 'insertUpdateUser',
                    oldValue: JSON.stringify(input)
                };
                insertUpdateUser({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                    form.classList.remove('was-validated');
                    $('#ResetPasswordModal').modal('hide');
                    form.reset();
                    toast.success("Password updated successfully!", {
                        autoClose: 2000
                    })
                }).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                })
            } else {
                toast.error('Passwords do not match.', {
                    autoClose: 2000
                })
            }
            return true;
        }
    }
    return (
        <>
            <div className="">
                <div className="mb-4">
                    <p className="mainTitle m-0">Reset Password</p>
                    <span className="borderBottomOfTitle"></span>
                </div>
                <div className="ibox-title border-0 p-0">
                    <span>Reset Password</span>
                </div>
            </div>
            <form id="frm_ResetUser" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitResetAdminUserPassword(e)}>
                <div className="form-row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label className="frm-label">New Password<span className="text-danger">*</span></label>
                        <input type="password" className="form-control ffPoppins" name="resetPassword" onChange={e => setPassword(e.target.value)} required />
                    </div>
                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                        <label className="frm-label">Confirm Password<span className="text-danger">*</span></label>
                        <input type="password" className="form-control ffPoppins" name="resetConfirmPassword" onChange={e => setConfirmPassword(e.target.value)} required />
                    </div>
                </div>

                <div className="form-group p-0">
                    <button type="submit" className="btn btn-primary btn-text mt-3 mr-4 border-0 bg-searchBlue" >Save</button>
                    <button className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed" data-dismiss="modal" >Cancel</button>
                </div>
            </form>
        </>
    )
}
