import React from 'react'
import { groupBy, uniq } from 'lodash';

export const TotalPackageReceived = ({ reportData, type }) => {
    const { getTotalReceivedPakagesReport } = reportData;
    const filteredData = getTotalReceivedPakagesReport.filter(item => { 
        return Object.values(item).every(value => value !== null);
    });
    let customerGroup = null;
    if (type === 'Summarized') {
        const updateReport = filteredData?.map(report => {
            const { customerInfo, totalBox, verifiedCost,binNo } = report;
            return { customerInfo: customerInfo?.firstname + ' ' + customerInfo?.lastname + ' | ' + customerInfo?.pmb_no, totalBox, verifiedCost ,binNo}
        })
        customerGroup = groupBy(updateReport, 'customerInfo')
    }
  return (
    type === 'Explained' ?
        <div className="col-md-12 no-padding d-inline-block">
            <div id="totalreceived" className="table-responsive">
                <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>DATE</th>
                            <th>CUSTOMER NAME</th>
                            <th>RECEIVER NAME</th>
                            <th>BIN #</th>
                            <th>TOTAL PACKAGES RECEIVED</th>
                            <th>VERIFIED SERVICE COST</th>
                        </tr>
                    </thead>
                    <tbody>
                        {filteredData?.length ?
                            <>{
                                filteredData?.map((report, i) => {
                                    const { date, customerInfo, receiverInfo, totalBox, verifiedCost, binNo } = report;
                                    return <tr className="odd gradeX" key={i}>
                                        <td>{i + 1}</td>
                                        <td>{new Date(date).toLocaleDateString('en-US')}</td>
                                        <td>{customerInfo?.firstname + ' ' + customerInfo?.lastname + ' | ' + customerInfo?.pmb_no}</td>
                                        <td>{receiverInfo?.name}</td>
                                        <td>{binNo}</td>
                                        <td align="center">{totalBox}</td>
                                        <td align="right">{verifiedCost}</td>
                                    </tr>
                                })
                            }
                                <tr className="odd gradeX">
                                    <td colSpan="5"><span className="pull-right"><b>TOTAL</b></span></td>
                                    <td align="center"><b>{filteredData.reduce((prevValue, currValue) => prevValue + currValue.totalBox, 0)}</b></td>
                                    <td align="right"><b>{filteredData.reduce((prevValue, currValue) => prevValue + currValue.verifiedCost, 0)}</b></td>
                                </tr>
                            </>
                            :
                            <tr className="odd gradeX">
                                <td colSpan="8">NO RECORD FOUND</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div> :
        <div className="col-md-12 no-padding">
            <div id="totalreceived" className="table-responsive">
                <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                    <thead>
                        <tr>
                            <th>NO</th>
                            <th>CUSTOMER NAME</th>
                            <th>BIN #</th>
                            <th>TOTAL PACKAGES RECEIVED</th>
                            <th className="text-right">VERIFIED SERVICE COST</th>
                        </tr>
                    </thead>
                    <tbody>
                        {Object.keys(customerGroup).length ?
                            <>{
                                Object.keys(customerGroup).map((custGroup, i) => {
                                    return <tr key={i} className="odd gradeX">
                                        <td>{i + 1}</td>
                                        <td>{custGroup}</td>
                                        <td>{uniq(customerGroup[custGroup].map(d => d.binNo)).join(', ')}</td>
                                        <td align="center">{customerGroup[custGroup].reduce((prevValue, currValue) => prevValue + currValue.totalBox, 0)}</td>
                                        <td align="right">{customerGroup[custGroup].reduce((prevValue, currValue) => prevValue + currValue.verifiedCost, 0)}</td>
                                    </tr>
                                })
                            }
                                <tr className="odd gradeX">
                                    <td colSpan="3"><span className="pull-right"><b>TOTAL</b></span></td>
                                    <td align="center"><b>{Object.keys(customerGroup).reduce((prevValue, currValue) => prevValue + customerGroup[currValue].reduce((prevValue, currValue) => prevValue + currValue.totalBox, 0), 0)}</b></td>
                                    <td align="right"><b>{Object.keys(customerGroup).reduce((prevValue, currValue) => prevValue + customerGroup[currValue].reduce((prevValue, currValue) => prevValue + currValue.verifiedCost, 0), 0)}</b></td>
                                </tr>
                            </>
                            :
                            <tr className="odd gradeX">
                                <td colSpan="8">NO RECORD FOUND</td>
                            </tr>
                        }
                    </tbody>
                </table>
            </div>
        </div>
  )
}
