import React, { useState } from 'react'

export const USAAddress = () => {
    const [genaralSettings ] = useState(JSON.parse(localStorage.getItem('generalSettings')));
  return (
    <div className="container mrgnTop110">
    <div className='text-center'>
      <p className="headTitle">USA Shipping Address</p>
    </div>
    <div className="col-md-6 mAuto pb-2 invMain mt-30 extra">
      <div className="mb-3 mt-3">
        <label className="headText mt-2">Shipping Address</label>
      </div>
      <div className="pb-1 boxShadowRate pl-3 pr-3 mb-4 boxInnerMargin">
        <div className="">
          <label className="mb-0 labelFs14">Address Line 1</label>
          <p className=" inputFs14">{genaralSettings?.amcWhouseAddress1 || ''}</p>
        </div>
        {genaralSettings?.amcWhouseAddress2 &&
          <div className="">
            <label className="mb-0 labelFs14">Address Line 2</label>
            <p className=" inputFs14">{genaralSettings?.amcWhouseAddress2 || ''}</p>
          </div>
        }
        <div className="">
          <label className="mb-0 labelFs14">City</label>
          <p className=" inputFs14">{genaralSettings?.amcWhouseCity || ''}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">State</label>
          <p className=" inputFs14">{genaralSettings?.amcWhouseState || ''}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Country</label>
          <p className=" inputFs14">{genaralSettings?.amcWhouseCountry || ''}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Zipcode</label>
          <p className=" inputFs14">{genaralSettings?.amcWhousezipcode || ''}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Phone</label>
          <p className=" inputFs14">{genaralSettings?.amcWhousePhone || ''}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Working Hours</label>
          <p className=" inputFs14">{genaralSettings?.amcWhouseWorkingHrs || ''}</p>
        </div>
      </div>
    </div>
  </div>
  )
}
