import { gql } from "@apollo/client";

export const GET_COUNTRY = gql`
  query getCountries($page: Int, $limit: Int, $filter: String, $sort: CountrySort){
    getCountries(page: $page,limit: $limit, filter: $filter, sort: $sort){
      count
      data {
        id
        name
        code
        isActive
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;


export const GET_ALL_COUNTRY = gql`
  query getAllCountryList{
    getAllCountryList{
      id
      name
      code
      isActive
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
`;