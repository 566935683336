import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { GET_ALL_SUBCATEGORY } from '../../../graphql/query/CategoryQuery';

const SubCategories = ({ categoryId, subCategoryId, getSubCategoryId }) => {
  const [subCategoryList, setSubCategoryList] = useState([]);
  const [value, setValue] = useState(null);
  const [colorError, setColorError] = useState(undefined);

  const { loading, data } = useQuery(GET_ALL_SUBCATEGORY, {
    variables: { categoryId },
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data && data.getAllSubCategories && data.getAllSubCategories.length > 0) {
      const SubCategory = data?.getAllSubCategories;
      let SubCat_List = [];
      SubCategory.forEach(d => {
        SubCat_List.push({
          value: d.id,
          label: `${d.name}`
        })
      });
      setSubCategoryList(SubCat_List);
      setValue(null);
    } else {
      setSubCategoryList([]);
      setValue(null);
    }
  }, [loading, data, categoryId]);

  useEffect(() => {
    if (subCategoryList.length > 0) {
      defaultSubCategoryValue();
    }
  }, [subCategoryId, subCategoryList]);

  const subCategoryHandleChange = (value) => {
    setColorError(true);
    setValue(value);
    getSubCategoryId(value?.value);
  }
  const defaultSubCategoryValue = () => {
    let subCategoryData = _.find(subCategoryList, (d) => { return d.value === subCategoryId });
    if (subCategoryData) {
      setColorError(true);
      setValue(subCategoryData);
    }
  }
  return (
    <Select
      className="basic-single ffPoppins"
      options={subCategoryList}
      value={value}
      onChange={(value) => subCategoryHandleChange(value)}
      styles={{
        control: (provided) =>
          !colorError && colorError !== undefined
            ? {
              ...provided,
              boxShadow: "0 0 0 1px #dc3545 !important",
              border: "1px solid #dc3545 !important"
            }
            : provided
      }}
    />
  );
};

export default SubCategories;
