import React from 'react'
import { StaticHeader } from '../layouts/StaticHeader'

export const HeaderComp = ({Title,Intro}) => {
  return (
    <div className="container pt-2">
    <div className="pt-4">
      <StaticHeader />
    </div>
    <div className="text-center mt-5 pb-5">
      <h1 className="bigTextEm">{Title}</h1>
      <div className="redDivider mAuto" />
      <p className="inputFs14 white paddPer mt-4">
        {Intro}
      </p>
    </div>
  </div>
  )
}
