import React from 'react'
import CountryList from '../../../components/DropdownSelect/CountrySelect'
import { statusType } from '../../../components/Constant'
import Loader from '../../../components/Loader/Loader'
import { useMutation } from '@apollo/client'
import { INSERT_UPDATE_STATE } from '../../../../graphql/mutation/StateMutation'
import { toast } from 'react-toastify'
import * as $ from 'jquery';
import Select from 'react-select';

export const InsertUpdateState = ({ state, refetch, setStateData, initialState }) => {
    const [insertUpdateState, { loading }] = useMutation(INSERT_UPDATE_STATE)
    const handleState = (e) => {
        setStateData(prevState => ({ ...prevState, isActive: e.value, status: e.value ? statusType[0] : statusType[1] }))
    }
    const frmOnSubmitState = (e) => {
        e.preventDefault();
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return;
        } else {
            const { name, isActive, openModal, id, countryId, Old_Data } = state;
            let oldValues = {};
            let input = {};
            if (openModal === "Edit") {
                for (var obj in state.Old_Data) {
                    if (Old_Data[obj] !== state[obj]) {
                        oldValues[obj] = state.Old_Data[obj]
                        input[obj] = state[obj]
                    }
                }
                if (input && Object.keys(input).length === 0) {
                    $('#StateModal').modal('hide');
                    return
                }
                input.id = id;
            } else {
                input = {
                    name: name,
                    countryId: countryId,
                    isActive: isActive
                }
            }
            let log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'states',
                actionName: 'insertUpdateState',
                oldValue: openModal === "Edit" ? JSON.stringify(oldValues) : ""
            }
            insertUpdateState({ variables: { 'input': input, 'log': log } }).then(async ({ data }) => {
                setStateData(prevState => ({ ...prevState, ...initialState }))
                refetch();
                form.reset();
                $('#StateModal').modal('hide');
                if (openModal === "Edit")
                    toast.success("State updated successfully!", {
                        autoClose: 2000
                    });
                else
                    toast.success("State added successfully!", {
                        autoClose: 2000
                    });
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    }
    return (
        <div className="modal-content ">
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">{state.openModal} State</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>{state.openModal} State</span>
                                </div>
                                {loading && <Loader />}
                                <div className="ibox-content">
                                    <form id="frm_state" className="needs-validation" noValidate="noValidate" onSubmit={(e) => { frmOnSubmitState(e) }} >
                                        <div className="form-row">
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Country<span className="text-danger">*</span></label>
                                                <CountryList countryId={state?.countryId} getCRUDCountryId={(id) => { setStateData(prevState => ({ ...prevState, countryId: id })) }} />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Name<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" value={state.name} onChange={(e) => { setStateData(prevState => ({ ...prevState, name: e.target.value })) }} required />
                                            </div>
                                            <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Status</label>
                                                <Select className="ffPoppins"
                                                    value={state.status}
                                                    options={statusType}
                                                    onChange={(data) => { handleState(data) }}
                                                />
                                            </div>
                                        </div>
                                        <div className="form-group col-xs-12">
                                            <div className="mt-3">
                                                <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" >Cancel</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
