import React from 'react'
import { statusType } from '../../../components/Constant';
import { useMutation } from '@apollo/client';
import { DELETE_COUNTRY } from '../../../../graphql/mutation/CountryMutation';
import confirmDeletion from '../../../components/Common/BootBox';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import { Link, useNavigate } from "react-router-dom";

export const GetCounty = ({ setCountry, country, countries, refetch }) => {
    const navigate = useNavigate();
    const [deleteCountry] = useMutation(DELETE_COUNTRY)
    const sortData = (sortKey) => {
        const newSortType = (country.sortType === 1) ? -1 : 1;
        setCountry((prevBins) => ({ ...prevBins, sortKey: sortKey, sortType: newSortType }));
    };

    const editCountry = (e, oldCountry) => {
        e.preventDefault();
        setCountry(prevCountry => ({
            ...prevCountry, id: oldCountry.id,
            name: oldCountry.name,
            code: oldCountry.code,
            isActive: oldCountry.isActive,
            status: oldCountry.isActive ? statusType[0] : statusType[1],
            Old_Data: { name: oldCountry.name, code: oldCountry.code, isActive: oldCountry.isActive },
            openModal: 'Edit'
        }))
        $('#frm_Country').removeClass('was-validated');
        $('#CountryModal').modal('show');
    }
    const deleteCountryDetails = (e, id) => {
        e.preventDefault()
        const deleteCountryDetails = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'countries',
                actionName: 'deleteCountry',
                oldValue: ""
            }
            deleteCountry({ variables: { "id": id, "log": log } }).then(async ({ data }) => {
                refetch();
                $('#CountryModal').modal('hide');
                toast.success("Country deleted successfully! ", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion("Delete Country", "Are you sure want to delete this record ?", deleteCountryDetails)
    }

    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0">
                <thead>
                    <tr>
                        <th className="text-center">No</th>
                        <th className={`sortColumn ${(country.sortKey === 'name') ? (country.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('name')} >Name</th>
                        <th className={`sortColumn ${(country.sortKey === 'code') ? (country.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('code')} >Code</th>
                        <th className={`sortColumn ${(country.sortKey === 'isActive') ? (country.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('isActive')} >Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        countries && countries?.data && countries?.data?.length > 0
                            ? countries && countries?.data?.map((d, i) => {
                                return (
                                    <tr key={d?.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editCountry(e, d)}>
                                        <td className="text-center">{i + 1}</td>
                                        <td>
                                            <span onClick={() => {
                                                navigate('/state', {
                                                    state: { country: d }
                                                })}} className="textDecorationNone text-primary">
                                                {d?.name}
                                            </span>
                                        </td>
                                        <td>{d?.code}</td>
                                        <td>
                                            {
                                                d?.isActive
                                                    ? <span className="statusStyle activeCustomer">Active</span>
                                                    : <span className="statusStyle inActiveCustomer">InActive</span>
                                            }
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editCountry(e, d)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2"
                                                    onClick={(e) => { deleteCountryDetails(e, d?.id); }}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            :
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={5} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
