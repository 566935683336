import gql from 'graphql-tag';

export const GET_ORDER_FOR_SEARCH_BOX = gql`
query getOrderForSearchBox($page: Int, $limit: Int, $filter: String){
    getOrderForSearchBox(page: $page, limit: $limit, filter: $filter){
      id
      trackingno
      receiverId
      binId
      binNum
      shipmentId
      receiverAddress {
      name
      address1
      address2
      phone
      city
      closestCityId{
        id
        name
      }
      stateId{
        id
        name
      }
      countryId{
        id
        name
      }
      zipCode
      }
      customerId
      customerInfo {
      firstname
      lastname
      email
      phone
      pmb_no
      userType
      }
      orderRateIds
      onlineStoreId {
        name
        id
      }
      orderFromSms
      invoiceId
      isInvoice
      isRecreateInvoice
      insurePer
      statusDate
      isPromoCode
      status
      items {
      id
      qty
      price
      total
      description
      rate
      instruction
      isInsure
      itemStatus
      insurerate
      insureprice
      }
      remark
      invoiceOrReceipt
      trackStatus
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt 
  }
}`