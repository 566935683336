import { ApolloConsumer, useQuery } from '@apollo/client';
import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { CUSTOMER_NOTIFICATION } from '../../graphql/query/NotificationQuery';
import _ from 'lodash';
import moment from 'moment';
import ChangeHeaderProfile from '../pages/CustomerPortal/MyProfile/MyProfileContex';

export const CustomerHeader = ({ routePath}) => {
  const { user } = useContext(ChangeHeaderProfile);
  // const [user, setuser] = useState(JSON.parse(localStorage.getItem('loggedUser')));
  const [genaralSettings] = useState(JSON.parse(localStorage.getItem('generalSettings')));
  const [popupNotification, setPopupNotification] = useState([]);
  const [unreadCount, setUnreadCount] = useState(0);
  const { data } = useQuery(CUSTOMER_NOTIFICATION, {
    variables: {
      customerId: user?.customerId,
      sort: { key: 'createdAt', type: -1 }
    },
    fetchPolicy: 'cache-and-network'
  });
  useEffect(() => {
    if (data && data.getCustomerNotifications) {
      let notificationData = data.getCustomerNotifications
      let popupfilterUnread = _.filter(notificationData, (i) => i.isRead === false)
      setPopupNotification(popupfilterUnread);
      setUnreadCount(popupfilterUnread?.length)
    }
  }, [data])

  const signOut = (client) => {
    localStorage.clear()
    sessionStorage.clear();
    client.resetStore();
    toast.success("user logged out successfully!", {
      autoClose: 2000
    })
  };
  const menuItems = [
    { to: "/my-profile", icon: "profile.png", label: "My Profile" },
    { to: "/contact-us", icon: "contact us.png", label: "Contact Us" },
    { to: "/account-info", icon: "my account info.png", label: "My Account Info" },
    { to: "/contact-info", icon: "our contact info.png", label: "Our Contact Info" },
    { to: "/usa-address", icon: "USA shipping address.png", label: "USA Shipping Address" },
    { to: "/customer-rates", icon: "rates1.png", label: "Rates" },
    { to: "/change-password-cust", icon: "change password.png", label: "Change Password" }
  ];

  let image = false;
  if (window.location.pathname === "/new-order") {
    image = "url(../images/newOrderBG.png)";
  }
  if (window.location.pathname === "/my-order" || window.location.pathname === "/ordertracking") {
    image = "url(../images/myOrderBG.png)";
  }
  if (window.location.pathname === "/my-shipment") {
    image = "url(../images/shipmentBG.png)";
  }
  if (window.location.pathname === "/my-invoice") {
    image = "url(../images/invoicesBG.png)";
  }
  let className = "customerHeader";
  if (image) { className = "aboutImg" }
  return (

    window.location.pathname !== "/" &&
      window.location.pathname !== "/about-us" &&
      window.location.pathname !== "/contact-us-web" &&
      window.location.pathname !== "/faqs-web" &&
      window.location.pathname !== "/prohibited-web" &&
      window.location.pathname !== "/terms-condition" &&
      window.location.pathname !== "/privacy-policy" &&
      window.location.pathname !== "/terms-condition" ?
      <div className={className} style={{ backgroundImage: image, overflow: 'inherit !important' }} >

        <div className="d-flex col-md-12 bg-white">
          <div className="col-md-2 col-sm-4">
            <Link to="/new-order">
              <div className="Logo">
                <img src="../images/smsLogo.png" alt="Logo" width='100px' />
              </div>
            </Link>
          </div>
          <div className="col-md-6 col-sm-8 flex sms-menu mt-1">
            <Link to="/" className="p-3">
              <span className={`${window.location.pathname === '/' ? 'inputFs14 themeRed' : 'inputFs14'}`}>Home</span>
            </Link>
            <Link to="/about-us" className="p-3">
              <span className={`${window.location.pathname === '/about-us' ? 'inputFs14 themeRed' : 'inputFs14'}`}>About Us</span>
            </Link>
            <Link to="/contact-us" className="p-3">
              <span className={`${window.location.pathname === '/contact-us' ? 'inputFs14 themeRed' : 'inputFs14'}`}>Contact Us</span>
            </Link>
            <Link to="/my-order" className="p-3">
              <span className={`${window.location.pathname === '/my-order' ? 'inputFs14 themeRed' : 'inputFs14'}`}>My Portal</span>
            </Link>
            <ApolloConsumer>
              {client => (
                <Link to="/login" className="p-3" onClick={() => signOut(client)}>
                  <span className={`${window.location.pathname === '/login' ? 'inputFs14 themeRed' : 'inputFs14'}`}>Log Out</span>
                </Link>
              )}
            </ApolloConsumer>
          </div>

          <div className="col-md-4 col-sm-8 pr-0">
            <div className="d-flex float-right pt-2">
              {genaralSettings?.headeOffCustomerServicePhone &&
                <div className="menuWidth">
                  <img src="../images/phoneRed.png" alt="Profile" width='15px' className="dropdown-toggle menuImgMrgn" />
                  <label className="mb-0 inputFs14">{genaralSettings?.headeOffCustomerServicePhone}</label>
                </div>
              }

              {genaralSettings?.headeOffCustomerServiceEmail &&
                <div className="menuWidth mr-2">
                  <img src="../images/mailRed.png" alt="Profile" width='15px' className="menuImgMrgn" />
                  <label className="mb-0 inputFs14">{genaralSettings?.headeOffCustomerServiceEmail}</label>
                </div>
              }
              {/* *************** notification *************** */}

              <div className='menuWidth'>
                <div className='menuCircle mr-2' style={{ position: 'relative', cursor: 'pointer' }}>
                  <div className="dropdown">
                    <img src="../images/bell.png" alt="Profile" width='23px' className="mt-2 ml-2" data-toggle="dropdown" />
                    <div className="notifDiv">
                      {unreadCount > 0 &&
                        <div className="notifSubDiv">
                          <span className="" style={{ fontSize: 12, color: '#fff' }}>{unreadCount}</span>
                        </div>
                      }
                    </div>
                    <div className="dropdown-menu notif-dropdown-menu pl-2 pr-2" role="menu">
                      <div className='msgTopTriangle' />
                      <div>
                        <p className="inputFs12 m-2">Notifications</p>
                      </div>
                      <div className="bottomBrdrLight" />
                      {
                        popupNotification && popupNotification?.length > 0 ?
                          popupNotification.slice(0, 3).map((notifi, i) => {
                            return (
                              <React.Fragment key={i}>
                                <Link to="/customer-notif" className="cust-item-list Link">
                                  <div className="flex" >
                                    <div>
                                      <p className="inputFs12 mb-0">{notifi?.tracking_no ? `Order Tracking No. ${notifi?.tracking_no}` : `You got new message.`}</p>
                                      <p className="labelFs10 mb-0">{moment(notifi?.createdAt).format('MMM Do hh:mm a')}</p>
                                    </div>
                                  </div>
                                </Link>
                                <div className="bottomBrdrLight" key={`bottomBorder_${i}`} />
                              </React.Fragment>
                            )
                          })
                          : ''
                      }
                      <Link to="/customer-notif" className="cust-item-list Link">
                        <div className="mt-2 mb-2">
                          <p className="inputFs12 mb-0 themeBlue">View All Notification</p>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div className='menuWidth'>
                <div className='menuCircle mr-2 right'>
                  <img src={user?.userProfile || "../images/user.png"} alt="Profile" width='40px' className="dropdown-toggle menuCircle" />
                </div>
              </div>
              {/* *************** menu *************** */}
              <div className='menuWidth'>
                <div className='menuCircle'>
                  <div className="dropdown">
                    <img src="../images/menu.png" alt="Profile" width='15px' className="dropdown-toggle menuImgMrgn" data-toggle="dropdown" />
                    <div className="dropdown-menu cust-dropdown-menu myMenu" role="menu">
                      <div className='msgTopTriangle' />
                      {menuItems.map((item, index) => (
                        <React.Fragment key={index}>
                          <Link to={item.to} className="cust-item-list Link">
                            <span className='mr-2'> <img src={`../images/${item.icon}`} className="width15" alt="missImg" /></span>
                            <span>{item.label}</span>
                          </Link>
                          <div className="bottomBrdrLight" />
                        </React.Fragment>
                      ))}
                      <div className="bottomBrdrLight" />
                      <ApolloConsumer>
                        {client => (
                          <Link to="/login" className="cust-item-list Link border-bottom-0 " onClick={() => signOut(client)}>
                            <span className='mr-2'> <img src="../images/logout1.png" className="width15" alt="missImg" /></span>
                            <span>Log out</span>
                          </Link>
                        )}
                      </ApolloConsumer>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* </div> */}
        </div>
        {image &&
          <div className="text-center mt-5 pb-5">
            <h1 className="bigTextEm">{routePath?.pathname === '/new-order' ? 'New Order' : routePath?.pathname === '/my-order' || routePath?.pathname === '/ordertracking' ? 'My Order' : routePath?.pathname === '/my-shipment' ? 'Shipment' : 'Invoice'}</h1>
            <div className="redDivider mAuto" />
          </div>
        }

      </div> : null
  )
}
