import React, { useState, useEffect } from 'react';
import {  useMutation } from '@apollo/client';
import { DELETE_RECEIVER } from '../../../graphql/mutation/AddressMutation';
import { GET_RECEIVER_CUSTOMER } from '../../../graphql/query/AddressQuery';

const EditDeletePopup = ({ checkId, PopupCheck, isEdit, isDelete }) => {
    const [isPopup, setIsPopup] = useState(false);
    const [isEditState, setIsEditState] = useState(false);

    const _isPopup = () => {
        setIsPopup(!isPopup);
    };

    const _isEdit = () => {
        setIsEditState(prevIsEditState => !prevIsEditState);
        isEdit(!isEditState);
    };

    return (
        <div>
            {checkId === PopupCheck.id ?
                <div onClick={_isPopup}>
                    <i className="fa fa-ellipsis-h pointer themeBlue" aria-hidden="true"></i>
                    {isPopup &&
                        <div className="menuPopup">
                            <div onClick={_isEdit}>
                                <img src="../images/edit.png" alt="Img" width="13px" className="editColor mr-2 pointer" />
                                <span className="editFont pointer">Edit</span>
                            </div>
                            <DeletePopup isDelete={isDelete} />
                        </div>
                    }
                </div>
                :
                <div>
                    <i className="fa fa-ellipsis-h white" aria-hidden="true"></i>
                </div>
            }
        </div>
    );
};

const DeletePopup = ({ isDelete }) => {
    const loggedUser = localStorage.getItem('loggedUser');
    const [deleteReceiver] = useMutation(DELETE_RECEIVER, {
        refetchQueries: [{ query: GET_RECEIVER_CUSTOMER, variables: { customerId: JSON.parse(loggedUser).customerId } }],
    });

    return (
        <div onClick={(e) => isDelete(e, deleteReceiver)}>
            <img src="../images/bin.png" alt="Img" width="13px" className="editColor mr-2 pointer" />
            <span className="editFont pointer">Delete</span>
        </div>
    );
};

const CustomerAddressPanel = ({ id, popupCheck, isCheck1, selectedReceiverData, deleteReceiverData, checked }) => {
    const [onecall, setOnecall] = useState(false);

    useEffect(() => {
        if (popupCheck && !onecall) {
            setOnecall(true);
            selectedReceiverData(popupCheck);
        }
    }, [popupCheck]);

    const _isCheck = (id, data) => {
        checked(id);
        selectedReceiverData(data);
    };

    return (
        <div className="boxShadowAdd mb-3 boxInnerMargin">
            <div className="flex addressBrdr pt-2 pb-2">
                <div className="flex col-md-11 pointer" onClick={() => _isCheck(id, popupCheck)}>
                    <div className="radio">
                        <div className={isCheck1 === id ? "radioCircle" : "radioDisable"} />
                    </div>
                    <label className="mb-0 labelFs14 pl-3 pointer">{popupCheck.name}</label>
                </div>
                <EditDeletePopup checkId={isCheck1} PopupCheck={popupCheck} isEdit={value => selectedReceiverData({ value, ...popupCheck })}
                    isDelete={(e, deleteReceiver) => deleteReceiverData(e, deleteReceiver, id)} />
            </div>
            <div className="p-3">
                <div className="mb-2">
                    <label className="mb-0 labelFs14">Address</label>
                    <p className="mb-0 inputFs14">{popupCheck?.address1 + ', ' + popupCheck?.closestCityId?.name + ', ' + popupCheck?.stateId?.name + ', ' + popupCheck?.countryId?.name}</p>
                </div>
                <div className="">
                    <label className="mb-0 labelFs14">Mobile</label>
                    <p className="mb-0 inputFs14">{popupCheck?.phone}</p>
                </div>
            </div>
        </div>
    );
};

export default CustomerAddressPanel;
