import React, { useState } from 'react';
import _ from 'lodash';
import moment from 'moment';
import ShipmentComp from '../pages/CustomerPortal/MyShipment/ShipmentCom';

const ShipmentListComp = ({ shipment, showDetail, showOrderDetail, onShowhandler, setOrderDetils }) => {
  const [shipData, setShipData] = useState([]);

  return (
    (showDetail === false)
      ? ((shipment !== undefined) && (shipment !== null) && (shipment?.length))
        ? shipment.map((ship, i) => {
          let box = 0;
          _.map(ship, (j) => box = box + j.boxDimension?.length);
          return ship[0].shipmentId ?
            <div key={ship[0].shipmentId} className="pb-1 boxShadowShip pl-3 pr-3 mb-4 boxInnerMargin">
              <div className="col-md-12 row">
                <div className="col-md-6 mb-2 p-0">
                  <label className="mb-0 labelFs14 mr-2">Shipment No. : </label>
                  <label className="mb-0 inputFs14">{ship[0]?.shipmentNo}</label>
                </div>
                <div className="col-md-6 mb-2 p-0">
                  <div className="float-right">
                    <button onClick={() => {
                      onShowhandler(true);
                      setShipData(ship);
                    }} className="btn text-light" style={{ background: "#0B429A" }}>Details Of Shipment</button>
                  </div>
                </div>
              </div>
              <div className='bottomBrdr' />
              <div className="col-md-12 row pt-2">
                {ship[0]?.shipmentInfo &&
                  <>
                    <div className="col-md-4 mb-2">
                      <label className="mb-0 labelFs14">Shipment Date</label>
                      <p className="mb-0 inputFs14">{moment(ship[0]?.shipmentInfo.createdAt).format('MM/DD/YYYY')}</p>
                    </div>
                    <div className="col-md-4 mb-2">
                      <label className="mb-0 labelFs14">Estimated Delivery Date</label>
                      <p className="mb-0 inputFs14">{moment(ship[0]?.shipmentInfo.estimatedDate).format('MM/DD/YYYY')}</p>
                    </div>
                  </>
                }
                <div className="col-md-4 mb-2">
                  <label className="mb-0 labelFs14">No of Boxes</label>
                  <p className="mb-0 inputFs14">{box}</p>
                </div>
              </div>
            </div>
            : <div className="boxShadowRate boxInnerMargin">
              <div className="text-center">
                <img src="./images/notFound.png" alt="not found"></img>
              </div>
            </div>
        })
        : <div className="boxShadowRate boxInnerMargin">
          <div className="text-center">
            <img src="./images/notFound.png" alt="not found"></img>
          </div>
        </div>
      : <ShipmentComp
        shipment={shipData}
        showDetail={showDetail}
        showOrderDetail={showOrderDetail}
        setOrderDetils={(e) => setOrderDetils(e)}
      />
  );
};

export default ShipmentListComp
