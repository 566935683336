import { gql } from "@apollo/client";

export const INSERT_UPDATE_USERS = gql`
mutation insertUpdateUser($input: userInput!,$log: LogInput){
  insertUpdateUser(input: $input,log: $log){
    id
    firstName
    roles
    lastName
    email
    phone
    isActive
  }
}
`;

export const DELETE_USERS = gql`
mutation deleteAdminUser($id: ID!,$log: LogInput){
  deleteAdminUser(id: $id,log: $log){
    id
    firstName
    roles
    lastName
    email
    phone
    isActive
  }
}
`
export const MY_PROFILE_CHANGE = gql`
mutation updateProfile($input: userInput){
  updateProfile(input:$input){
  token
    user{
      id
      firstName
      lastName
      email
      roles
      customerId
      phone
      userType
      address1
      countryId
      stateId
      city
      zipcode
      userProfile
    }
    customer{
       id
      companyName
      firstName
      lastName
      email
      receivingServiceType
      phone
      idProof
      signature
      address1
      address2
      city
      countryId
      stateId
      pmbNo
      zipcode
      deliveryServiceType
      isActive
    }
  }
}`
export const MY_ADDRESS_CHANGE = gql`
mutation addUpdateAddress($input: addressInput){
  addUpdateAddress(input:$input){
    token
    user{
      id
      firstName
      lastName
      email
      roles
      customerId
      phone
      userType
      address1
      countryId
      stateId
      city
      zipcode
      userProfile
    }
    customer{
       id
      companyName
      firstName
      lastName
      email
      receivingServiceType
      phone
      idProof
      signature
      address1
      address2
      city
      countryId
      stateId
      pmbNo
      zipcode
      deliveryServiceType
      isActive
    }
  }
}`