import { gql } from "@apollo/client";

export const INSERT_UPDATE_RECEIVER = gql`
  mutation insertUpdateReceiver($input: ReceiverInput!,$log: LogInput){
    insertUpdateReceiver(input:$input,log: $log){
      id
      name
      phone
      address1
      address2
      city
      zipCode
      customerId
      countryId
      stateId
      closestCityId
      createdBy
      createdAt
      updatedAt
      updatedBy
    }
  }
`;

export const DELETE_RECEIVER = gql`
  mutation deleteReceiver($id: ID!,$log: LogInput){
    deleteReceiver(id:$id,log: $log){
      id
      name
      phone
      address1
      address2
      city
      zipCode
      customerId
      countryId
      stateId
      closestCityId
      createdBy
      createdAt
      updatedAt
      updatedBy
    }
  }
`;