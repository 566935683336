import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import bootstrap from 'bootstrap'; // eslint-disable-line
import Loader from '../../components/Loader/Loader';
import CountryList from '../../components/DropdownSelect/CountrySelect';
import { RESEND_OTP, SIGN_UP, VERIFY_OTP } from '../../../graphql/mutation/SignUpMutation'

export const SignUp = ({ refetch }) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [phone, setPhone] = useState('');
  const [countryId, setCountryId] = useState(null);
  const [serviceType, setServiceType] = useState(null);
  const [errorFlag, setErrorFlag] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [termsAndConditions, setTermsAndConditions] = useState(false);
  const [otp, setOtp] = useState('');
  const [standardMsg] = useState('When we receive your packages, we will NOT verify the content. Sometimes items sent by sellers do not match what you ordered, hence we recommend verified service.');
  const [verifiedMsg] = useState('When we receive your packages, we will open them to verify the content. We can then notify you immediately if there is any discrepancy. This will cost $1/package. We recommend this service.');
  const [countryCode, setcountryCode] = useState('')

  useEffect(() => {
    if (otpModalOpen) {
      $('#verifyOtp').modal('show');
    } else {
      $('#verifyOtp').modal('hide');
    }
  }, [otpModalOpen]);

  const [signUpMutation, { loading }] = useMutation(SIGN_UP);
  const [verifyOtpMutation, { loading: otpLoading }] = useMutation(VERIFY_OTP);
  const [resendOtpMutation] = useMutation(RESEND_OTP);

  let navigate = useNavigate()

  const onSubmit = (event) => {
    event.preventDefault();
    setErrorFlag(false);
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
      form.classList.add('was-validated');
      toast.error('Please fill the required fields...', {
        autoClose: 2000
      });
    } else {
      if (password !== confirmPassword) {
        toast.error('password and confirm password must be same...', {
          autoClose: 2000
        })
      } else {
        if (countryId) {
          if (termsAndConditions) {
            if (serviceType) {
              const input = { "input": { firstName, lastName, email, password, phone, serviceType, countryId, countryCode } }
              signUpMutation({ variables: input }).then(async ({ data }) => {
                localStorage.setItem('token', data.signUp.token);
                localStorage.setItem('loggedUser', JSON.stringify(data.signUp.user));
                localStorage.setItem('generalSettings', JSON.stringify(data.signUp.generalSettings));
                localStorage.setItem('customer', JSON.stringify(data.signUp.customer));
                await refetch()

                // go for verify OTP
                setOtpModalOpen(true)
                $('#verifyOtp').modal('show');

                toast.success("otp send successfully.", {
                  autoClose: 2000
                })
              }).catch((err) => {
                if (err && !errorFlag) {
                  toast.error(err.message, {
                    autoClose: 2000
                  })
                  setErrorFlag(true)
                }
              })
            } else {
              toast.error('Please select the service type.', {
                autoClose: 2000
              })
            }
          } else {
            toast.error('Please select the terms & conditions.', {
              autoClose: 2000
            })
          }
        } else {
          toast.error('Please select the country.', {
            autoClose: 2000
          })
        }
      }
    }
  };

  const onResendOTPAction = (e) => {
    e.preventDefault();
    if (email) {
      resendOtpMutation({ variables: { email } }).then((data) => {
        if (data) {
          toast.success("Resend OTP success. Please check your mailbox.", {
            autoClose: 2000
          });
        }
      }).catch((err) => {
        toast.error(err.message, {
          autoClose: 2000
        });
      });
    }
  };

  const onVerifyOTP = async (e) => {
    e.preventDefault();
    if (otp) {
      verifyOtpMutation({ variables: { email, otp: parseInt(otp) } }).then(async ({ data }) => {
        localStorage.setItem('token', data.verifyOTP.token);
        localStorage.setItem('loggedUser', JSON.stringify(data.verifyOTP.user));
        localStorage.setItem('generalSettings', JSON.stringify(data.verifyOTP.generalSettings));
        await refetch();
        toast.success('user created successfully', {
          autoClose: 2000
        });
        setOtpModalOpen(false);
        $('body').removeClass('modal-open');
        var elements = document.getElementsByClassName('modal-backdrop fade show');
        for (var i = 0; i < elements.length; i++) {
          var element = elements[i];
          element.className = element.className.replace('modal-backdrop fade show', '');
        }
        if (data.verifyOTP.user.roles === "customer")
          navigate('/new-order');
        else
          navigate('/dashboard');
      }).catch((err) => {
        if (err && !errorFlag) {
          toast.error(err.message, {
            autoClose: 2000
          });
          setErrorFlag(true);
        }
      });
    } else {
      toast.error('Please enter OTP', {
        autoClose: 2000
      });
    }
  };

  return (
    <div className="container">
      <div className="col-md-12 box-cener clearfix">
        <div className="col-lg-6 col-md-9 col-12 ViewCard p-0">
          <div className="logoView">
            <img className="signup-logo" alt="Logo" src="../images/smsLogo.png" height="100" width="260" />
          </div>
          <form className="needs-validation" noValidate="noValidate" onSubmit={event => onSubmit(event)}>
            {loading && <Loader />}
            <div className="form-group frm-Box">
              <input type="text" className="form-control txt-Box" placeholder="First Name" value={firstName} onChange={(e) => { setFirstName(e.target.value) }} required />
              <input type="text" className="form-control txt-Box" placeholder="Last Name" value={lastName} onChange={(e) => { setLastName(e.target.value) }} required />
              <input type="email" className="form-control txt-Box" placeholder="Email" value={email} onChange={(e) => { setEmail(e.target.value) }} required />
              <input type="password" className="form-control txt-Box" placeholder="Password" value={password} onChange={(e) => { setPassword(e.target.value) }} required />
              <input type="password" className="form-control txt-Box" placeholder="Confirm password" value={confirmPassword} onChange={(e) => { setConfirmPassword(e.target.value) }} required />
              <input type="number" minLength="10" maxLength="12" className="form-control txt-Box" placeholder="Mobile" value={phone} onChange={(e) => { setPhone(e.target.value) }} required />
              <CountryList styles={{ marginBottom: '20px' }} countryId={countryId} withCode={true} getCountryCode={(code) => { setcountryCode(code) }} getCRUDCountryId={(id) => { setCountryId(id) }} />
              <div className="col-md-12 p-0" style={{ marginTop: 20 }}>
                <span className="float-left queOptions  font-weight-normal">Choose a service type</span>
                <div className="form-check form-check-inline radio-input1" onClick={() => setModalOpen(true)} onFocus={() => setModalOpen(true)} data-toggle="modal" data-target="#exampleModalCenter">
                  <input className="form-check-input service-radio" type="radio" name="serviceTypes"
                    id="Standard" value="Standard"
                    checked={serviceType === "Standard" ? true : false}
                    onChange={(e) => { setServiceType(e.target.value) }}
                    onFocus={(e) => { setServiceType(e.target.value) }}
                  />
                  <label className="form-check-label" htmlFor="Standard">Standard</label>
                </div>
                <div className="form-check form-check-inline radio-input1" onClick={() => setModalOpen(true)} onFocus={() => setModalOpen(true)} data-toggle="modal" data-target="#exampleModalCenter">
                  <input className="form-check-input service-radio" type="radio" name="serviceTypes"
                    id="Verified" value="Verified"
                    checked={serviceType === "Verified" ? true : false}
                    onChange={(e) => { setServiceType(e.target.value) }}
                    onFocus={(e) => { setServiceType(e.target.value) }}
                  />
                  <label className="form-check-label" htmlFor="Verified">Verified</label>
                </div>
              </div>
              <div className="terms-condition col-md-12 p-0"><span className="float-left queOptions font-weight-normal ph-6 me-5">By signing up you agree to our</span>

                <span> <input type="checkbox" className="mr-1" onChange={(e) => setTermsAndConditions(!termsAndConditions)} /></span>  <Link to="/terms-condition" target='_blank' ><p className="float-left m-0 termCondition d-flex align-items-center">Term & Conditions </p></Link></div>

              <button type="submit" className="btn sign-btn btn-block btn-theme mb-20">SIGN UP</button>
              <div>
                <span className="linkTitle">Already have account ?</span>
                <br />
                <Link to="/login"> <span className="linkLabel">SIGN IN NOW</span></Link>
              </div>
            </div>
          </form>
          {modalOpen &&
            <div className="modal fade" id="exampleModalCenter" tabIndex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header m-header">
                    <img className="signup-logo" alt="Logo" src="../images/smsLogo.png" height="50" width="100" />
                    <h5 style={{ marginLeft: 15, fontWeight: 'bold', fontSize: 'x-large' }} className="modal-title" id="exampleModalLongTitle">{serviceType}</h5>
                  </div>
                  <div style={{ textAlign: 'justify' }} className="modal-body">
                    {serviceType === "Standard" ? standardMsg : verifiedMsg}
                  </div>
                  <div className="modal-footer m-footer">
                    <button type="button" className="btn" style={{ color: '#6c757d' }} data-dismiss="modal" onClick={() => setModalOpen(false)}>OK</button>
                  </div>
                </div>
              </div>
            </div>
          }

          {otpModalOpen &&
            <div className="modal fade" id="verifyOtp" tabIndex="-1" role="dialog" aria-labelledby="verifyOtpTitle" data-backdrop="static" aria-hidden="true">
              <div className="modal-dialog modal-dialog-centered" role="document">
                <div className="modal-content">
                  <div className="modal-header m-header">
                    <img className="signup-logo" alt="Logo" src="../images/smsLogo.png" height="100" width="260" />
                  </div>
                  <div className="modal-body">
                    <div>

                      <form className="needs-validation" noValidate="noValidate" onSubmit={event => onVerifyOTP(event)}>
                        {otpLoading && <Loader />}
                        <div className="form-group frm-Box">
                          <label>Enter OTP:</label>
                          <input type="number" className="form-control txt-Box" placeholder="Enter OTP" minLength={5} maxLength={5} value={otp} onChange={(e) => setOtp(e.target.value)} required />
                          <div style={{ textAlign: "right" }} onClick={(e) => onResendOTPAction(e)}>
                            <label className="form-check-label text-info">Resend OTP?</label>
                          </div>
                        </div>
                        <div className="modal-footer m-footer">
                          <button type="button" className="btn btn-info" style={{ color: '#fff' }} onClick={(e) => onVerifyOTP(e)}>Verify OTP</button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </div>
  );
};
