import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { useLocation } from 'react-router-dom'
import { ADD_NOTIFICATION } from '../../../graphql/mutation/OrderMutation';
import { GetOrderNotification } from './GetOrderNotification';
import { GET_NOTIFICATION } from '../../../graphql/query/orderQuery';
import { toast } from 'react-toastify';
import { Link } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';

export const Notification = () => {
  const location = useLocation();
  let params = location.state;

  const [notification, setNotification] = useState({
    orderId: params.orderId ? params.orderId  : null,
    customerId: params.customerId,
    tracking_no: params.tracking_no,
    orderStatus: params.orderStatus,
    name: params.name,
    msg: '',
    sortKey: 'createdAt',
    sortType: -1,
  });

  const [insertUpdateNotification, { loading }] = useMutation(ADD_NOTIFICATION);
  const {data ,refetch} = useQuery(GET_NOTIFICATION,{
    fetchPolicy:'cache-and-network',
    variables :{customerId: notification?.customerId, orderId: notification?.orderId, sort: { key: notification.sortKey, type: notification.sortType } }
  })

  const submitNotification = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    const { orderId, customerId, msg, tracking_no, orderStatus, name } = notification;
    if (!form.checkValidity()) {
      event.stopPropagation();
      form.classList.add('was-validated');
      toast.error('Please fill the required fields...', {
        autoClose: 2000
      })
      return false;
    } else {
      let input = {
        orderId: orderId,
        customerId: customerId,
        tracking_no: tracking_no,
        orderStatus: orderStatus,
        name: name,
        msg: msg
      };
      let log = {
        action: 'INSERT',
        actionOn: 'notifications',
        actionName: 'insertUpdateNotification',
        oldValue: ""
      };
      insertUpdateNotification({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
        form.classList.remove('was-validated');
        refetch();
        setNotification(preData => ({ ...preData, msg: '' }))
        form.reset();
        toast.success("Notification send successfully!!", {
          autoClose: 2000
        })
      }).catch((err) => {
        toast.error(err.message, {
          autoClose: 2000
        })
      })
      return true;
    }
  }

 
  const { msg} = notification;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="mb-20">
          <p className="mainTitle m-0 mt_-15">Notification</p>
          <span className="borderBottomOfTitle"></span>
        </div>
      </div>

      <div className="row mb-4">
        <div className="col-md-12 p-0">
          {loading && <Loader />}
          <div className="ibox">
            <div className="ibox-title">
              <span>Send Notification To Customers</span>
            </div>
            <div className="ibox-content">
              <form id="frm_Notification" className="needs-validation" noValidate="noValidate" onSubmit={e => submitNotification(e)}>
                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                    <label className="frm-label">Customer Notification<span className="text-danger">*</span></label>
                    <input type="text" className="form-control ffPoppins" placeholder="Enter Notification" value={msg} onChange={(e) => setNotification(preData => ({ ...preData, msg: e.target.value }))} required />
                  </div>
                  <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
                    <div className="mt-30">
                      <button type="submit" className="btn btn-success btn-text bg-lightGreen"> Send</button>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>

        <div className="col-md-12 p-0">
          <div className="ibox">
            <div className="ibox-title">
              <span>All Notification</span>
            </div>
              <GetOrderNotification refetch={refetch}  noti_List={data?.getOrderWiseNotifications} notification={notification} setNotification={setNotification}/>
          </div>
        </div>
      </div>
      <div className="row">
        <div className="col-md-12 p-0">
          <Link to="/order">
            <button
              type="reset"
              className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed"
              data-dismiss="modal"
            >
              Cancel
            </button>
          </Link>
        </div>
      </div>
    </div>
  )
}
