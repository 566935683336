import React, { useEffect, useState } from 'react'
import { AccessDenied } from '../../../components/AccessDenied';
import { useMutation, useQuery } from '@apollo/client';
import GET_USERS from '../../../../graphql/query/UsersQuery';
import Loader from '../../../components/Loader/Loader';
import ReactPaginate from 'react-paginate';
import {  INSERT_UPDATE_USERS } from '../../../../graphql/mutation/UserMutation';
import * as $ from 'jquery';
import { ChangeAdminUserPassword } from './ChangeAdminUserPassword';
import { InserUpdateUser } from './InserUpdateUser';
import { ShowUserComp } from './ShowUserComp';

export const Users = () => {
  const generalSettings = JSON.parse(localStorage.getItem('generalSettings'));
  const user = JSON.parse(localStorage.getItem('loggedUser'));
  const [tempUserType, setTempUserType] = useState('');
  const [userTypeColorError, setUserTypeColorError] = useState(null);
  const [openModal, setOpenModal] = useState('Add New ');
  const [tempIsActive, setTempIsActive] = useState({ value: true, label: 'Active' });
  const [limit, setLimit] = useState(generalSettings?.paginationLimit || 10);
  const [pageNo, setPageNo] = useState(0);
  const [filter, setFilter] = useState("");
  const [filterText, setFilterText] = useState('');
  const [sortKey, setSortKey] = useState('createdAt');
  const [sortType, setSortType] = useState(-1);
  const [id, setId] = useState(null);
  const [usersData, setUsersData] = useState({});
  const [oldData, setOldData] = useState(null);
  const [totalPage, setTotalPage] = useState();
  const [userData, setUserData] = useState([]);

  const { loading, error, data, refetch } = useQuery(GET_USERS, {
    variables: { page: pageNo + 1, limit: limit, filter: filter, sort: { key: sortKey, type: sortType } },
    fetchPolicy: 'cache-and-network',
  });

  const [insertUpdateUser, { loadingUserUpdate }] = useMutation(INSERT_UPDATE_USERS);

  useEffect(() => {
    if (data && data?.getUsers) {
      const user = data && data?.getUsers;
      const totalPage = user && Math.ceil(user.count / limit);
      setTotalPage(totalPage);
      setUserData(user);
    }
  }, [data]);

  useEffect(() => {
    if (user?.roles !== 'standard') {
      setUsersData({
        ...usersData,
        isActive: true,
      })
    }
  }, []);

  const addNewUser = (e) => {
    e.preventDefault();
    setOpenModal('Add New');
    setTempIsActive({ value: true, label: 'Active' });
    setTempUserType('');
    setUserTypeColorError(null);
    setUsersData({});
    $('#frm_User').removeClass('was-validated');
    $('#frm_User')[0].reset();
    $('#UsersModal').modal('show');
  }
  const handleSearch = (e) => {
    if (e.keyCode === 13 || e.which === 13) {
      searchUser()
    }
  }
  const searchUser = () => {
    setPageNo(0);
    setFilter(filterText);
  }
  const onResetSearch = () => {
    setPageNo(0);
    setFilter("");
    setFilterText("");
  }
  const handlePageClick = (data) => {
    setPageNo(data?.selected);
  }

  if (user?.roles === 'standard') return <AccessDenied />
  else
    return (
      <div className="container-fluid">
        <div className="row">
          <div className="col-md-8 p-0">
            <div className="mb-20">
              <p className="mainTitle m-0 mt_-15">Users List</p>
              <span className="borderBottomOfTitle"></span>
            </div>
          </div>
          <div className="col-md-4 p-0">
            <div className="float-right">
              <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewUser(e)}> <i className="fa fa-plus"></i> Add New User</button>
            </div>
          </div>
        </div>
        <div className="row mb-4">
          <div className="col-md-12 p-0">
            <div className="ibox">
              <div className="ibox-title">
                <span>Users Details</span>
              </div>

              <div className="ibox-content p-0">
                {loading && <Loader />}
                <div className="col-md-12 ph-20 mt-3">
                  <div className="form-row">
                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                      <label className="frm-label">Search</label>
                      <input type="text" className="form-control" value={filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setFilterText(e.target.value)} />
                    </div>
                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                      <div className="mt-30">
                        <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchUser()} >Search</button>
                        <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                        <div className="pull-right">
                          <ReactPaginate
                            previousLabel={'Back'}
                            nextLabel={'Next'}
                            pageCount={totalPage}
                            marginPagesDisplayed={2}
                            pageRangeDisplayed={5}
                            initialPage={0}
                            forcePage={pageNo}
                            onPageChange={(data) => handlePageClick(data)}
                            containerClassName={'pagination mb-0'}
                            subContainerClassName={'page-item'}
                            activeClassName={'active'}
                            pageLinkClassName={'pagination-link'}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <ShowUserComp setId={setId} sortKey={sortKey} refetch={refetch} sortType={sortType} setSortKey={setSortKey} setSortType={setSortType} userData={userData} setUserTypeColorError={setUserTypeColorError} setTempUserType={setTempUserType} setTempIsActive={setTempIsActive} setUsersData={setUsersData} setOldData={setOldData} setOpenModal={setOpenModal} />
              </div>
            </div>
          </div>
        </div>
        <div className="modal show" id="ResetPasswordModal" data-backdrop="static" tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
            <div className="modal-content ">
              <div className="modal-body">
                <ChangeAdminUserPassword id={id} insertUpdateUser={insertUpdateUser} />
              </div>
            </div>
          </div>
        </div>

        <div className="modal show" id="UsersModal" data-backdrop="static" tabIndex="-1" role="dialog" >
          <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
            <div className="modal-content ">
              <InserUpdateUser refetch={refetch} loadingUserUpdate={loadingUserUpdate} setTempIsActive={setTempIsActive} oldData={oldData} setTempUserType={setTempUserType} insertUpdateUser={insertUpdateUser} id={id} setUserTypeColorError={setUserTypeColorError} tempUserType={tempUserType} userTypeColorError={userTypeColorError} tempIsActive={tempIsActive} openModal={openModal} usersData={usersData} setUsersData={setUsersData} />
            </div>
          </div>
        </div>
      </div>
    )
}
