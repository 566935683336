import { gql } from "@apollo/client";

export const INSERT_UPDATE_ONLINE_STORE = gql`
  mutation insertUpdateOnlineStore($input: OnlineStoreInput!, $log: LogInput){
    insertUpdateOnlineStore(input: $input, log: $log){
      id
      name
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;


export const DELETE_ONLINE_STORE = gql`
  mutation deleteOnlineStore($id: ID!, $log: LogInput){
    deleteOnlineStore(id: $id, log: $log){
      id
      name
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;
