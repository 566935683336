import React from 'react'
import { statusType } from '../../../components/Constant';
import { useMutation } from '@apollo/client';
import { DELETE_BIN } from '../../../../graphql/mutation/BinsMutation';
import confirmDeletion from '../../../components/Common/BootBox';
import * as $ from 'jquery';
import { toast } from 'react-toastify';

export const GetBinsData = ({setBins,Bins,bins,refetch}) => {
    const [deleteBin] = useMutation(DELETE_BIN);

    const sortData = (sortKey) => {
        const newSortType = (Bins.sortType === 1) ? -1 : 1;
        setBins((prevBins) => ({ ...prevBins, sortKey: sortKey, sortType: newSortType }));
    };
    const editBin = (e, oldBin) => {
        e.preventDefault();
        setBins((prevBins) => ({
            ...prevBins,
            binUpdateId :oldBin?.id,
            updatebin :oldBin?.bin,
            oldData :{ isActive: oldBin.isActive },
            binStatus:oldBin.isActive ? statusType[0] : statusType[1]

        }))
        $('#EditSelectedBinModal').modal('show');
    }

    const deleteBinDetails = (e,binId) => {
        e.preventDefault();
        const deleBin = () => {
            const log = {
                action: 'DELETE',
                actionOn: 'bins',
                actionName: 'deleteBin'
            }
              deleteBin({ variables: { id: binId, log } }).then(async ({ data }) => {
                if (data) {
                    refetch();
                  toast.success("Bin deleted successfully!", {
                    autoClose: 2000
                  })
                }
              }).catch((err) => {
                toast.error(err.message, {
                  autoClose: 2000
                })
              })
        }
        confirmDeletion('Delete Bin','Are you sure want to delete this record ?' ,deleBin)

    }
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0 tbl-sort">
                <thead>
                    <tr>
                        <th className='text-center'>No.</th>
                        <th className={`sortColumn ${(Bins.sortKey === 'bin') ? (Bins?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('bin')}>&emsp;Bins</th>
                        <th className={`sortColumn ${(Bins.sortKey === 'isFree') ? (Bins?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('isFree')} >Available</th>
                        <th className={`sortColumn ${(Bins.sortKey === 'isActive') ? (Bins?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('isActive')} >Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        bins && bins.data && bins.data.length > 0 ?
                            bins && bins.data.map((bin, i) => {
                                return (
                                    <tr key={bin?.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editBin(e, bin)}>
                                        <td className=" text-center">{i + 1}</td>
                                        <td>&emsp;{bin.bin}</td>
                                        {bin.isFree ?
                                            <td><span className="statusStyle activeCustomer">YES</span></td>
                                            :
                                            <td><span className="statusStyle inActiveCustomer">NO</span></td>
                                        }
                                        {bin.isActive ?
                                            <td><span className="statusStyle activeCustomer">Active</span></td>
                                            :
                                            <td><span className="statusStyle inActiveCustomer">InActive</span></td>
                                        }
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editBin(e, bin)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2"
                                                    onClick={(e) => { deleteBinDetails(e, bin.id); }}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            }) :
                            <tr>
                                <td style={{ textAlign: 'center' }} colSpan={4} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
