import { gql } from "@apollo/client";

export const TOTALBOX = gql`
query gettotalBox{
	gettotalBox{
    totalBox
  }
}`

export const GET_PACKAGEBOX = gql`
query getPackageBox($page: Int, $limit: Int,$filter: String, $sort:sortPackageBox, $from:String, $to:String, $binNum: String){
  getPackageBox(page:$page, limit:$limit, filter:$filter, sort:$sort, from:$from, to:$to, binNum:$binNum){
    count
    data{
      id
      createdAt
      itemIds
      orderIds
      binId
      binNum
			boxDimension{
        id
        box_Num
        len
        width
        height
        weight
        dimensionalWeight
        cubicFeet
        rateIds
        boxDimensionId
        isDimension
        barcode
        itemList
        itemIds{
          id
          qty
        }
      }
      customerId
      customerInfo{
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      receiverId
      receiverInfo{
        name
        phone
        address1
        address2
        city
        closestCityId { id name }
        stateId { id name }
        countryId { id name }
        zipCode
      }
      shipmentId
      shipmentNo
      barcode
      oldBinId
      oldBinNum
    }
  }
}
`

export const GET_ORDERSFROMBIN = gql`
query getOrdersFromBin($binId: ID,$curdType: String){
    getOrdersFromBin(binId:$binId,crudType:$curdType){
      order{
        id
        customerId
        customerInfo{
          firstname
          lastname
          pmb_no
          userType
          email
          phone
        }
        binId
        binNum
        receiverId
        items{
          id
          description
          instruction
          qty
          price
          total
          itemRate
          rate
          itemStatus
          isPacked
        }
        receiverAddress{
          name
          phone
        }
        createdAt
      }
        recivers{
          id
          name
          phone
          address1
          address2
          countryId
          stateId
          city
          closestCityId
          zipCode
          customerId
        }
    }
  }
`;

export const GET_STATUSOFBIN = gql`
query getOrderStatus($customerId:String){
  getOrderStatus(customerId:$customerId){
    packedBinNos
    pendingBinNos
  }
}
`

export const GET_PACKAGEBOX_FOR_CUSTOMER = gql`
query getPackageBoxForCustomer($customerId:ID) {
  getPackageBoxForCustomer(customerId:$customerId) {
    id
    binId
    binNum
    boxDimension{
      id
      box_Num
      len
      width
      height
      weight
      dimensionalWeight
      cubicFeet
      rateIds
      boxDimensionId
      isDimension
      barcode
      assign{
        assignTo
        assignFrom
        date
        status
      }
      status{
        status
        date
        type
      }
    }
    shipmentNo
    shipmentId
    itemList
    shipmentInfo {
      id
      wrhsId
      shipmentNo
      shippingDate
      estimatedDate
      shipStatus
      isDeleted
      isActive
      totalBox
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    trackingno
  }
}`
