import React, { useEffect, useState } from 'react'
import { useQuery } from '@apollo/client';
import _ from 'lodash';
import { GET_PACKAGEBOX_FOR_CUSTOMER } from '../../../../graphql/query/packingQuery';
import ShipmentListComp from '../../../components/ShipmentList';

export const MyShipment = () => {
    const [loggedUser] = useState(JSON.parse(localStorage.getItem('loggedUser')) || null)
    const [showDetail, setShowDetail] = useState(false);
    const [showOrderDetail, setShowOrderDetail] = useState(false);

    const onShowhandler = (data) => {
        setShowDetail(data);
    }
    const { data } = useQuery(GET_PACKAGEBOX_FOR_CUSTOMER, {
        variables: { customerId: loggedUser?.customerId },
        fetchPolicy: 'cache-and-network'
    });

    let result = [];
    if (data && data?.getPackageBoxForCustomer) {
        const getPackageBoxForCustomer = data?.getPackageBoxForCustomer;
        const groupedPackageBox = _.groupBy(getPackageBoxForCustomer, 'shipmentId');
        result = Object.values(groupedPackageBox);
    }

    return (
        <div className="container mrgnTop90 pb-5">
            <div className='text-center mb-5'>
                <h4>My Shipment</h4>
            </div>
            <div className="col-md-12 mAuto pb-2 invMain mt-5 mb-5">
                <div className="mt-3 mb-3">
                    <label className="headText mt-2">Shipment Box List</label>
                    {showDetail &&
                        <button onClick={() => {
                            if (showOrderDetail) {
                                setShowDetail(true);
                                setShowOrderDetail(false)
                            }
                            else {
                                setShowDetail(false);
                            }

                        }} className="btn btn-primary btn-text border-0 bg-searchBlue float-right" style={{ marginTop: "8px", marginRight: "16px" }}>Back</button>}
                </div>
                <ShipmentListComp
                    shipment={result}
                    showDetail={showDetail}
                    onShowhandler={onShowhandler}
                    showOrderDetail={showOrderDetail}
                    setOrderDetils={(e) => setShowOrderDetail(e)}
                />
            </div>
        </div>
    )
}
