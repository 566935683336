import gql from 'graphql-tag';

export const GET_SHIPMENT = gql`
query getShipment($page: Int, $limit: Int, $filter: String, $sort: sortShipment,$from: String, $to: String, $dateBy: String){
  getShipment(page:$page,limit:$limit,filter:$filter,sort:$sort,from:$from,to:$to,dateBy:$dateBy){
    count
    data {
      id
      wrhsId {
        id
        name
        code
      }
      shipmentNo
      shippingDate
      estimatedDate
      totalBox
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}`


export const GET_ALL_SHIPMENT = gql`
query getAllShipment {
  getAllShipment {
    id
    wrhsId
    shipmentNo
    shippingDate
    estimatedDate
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}`

export const GET_ALL_NONEXPIREDSHIPMENT = gql`
query getNonExpiredShipment($page: Int, $limit: Int, $filter: String){
  getNonExpiredShipment(page: $page, limit: $limit, filter: $filter){
		id
    wrhsId
    shipmentNo
    shippingDate
    estimatedDate
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}`



export const GET_DATA_FROM_SHIPMENT = gql`
query getPackageBoxFromShipmentId($shipmentId: ID,$filter: String,$status: String,$from: String,$to: String){
  getPackageBoxFromShipmentId(shipmentId:$shipmentId,filter:$filter,status:$status,from:$from,to:$to){
    id
    barcode
    boxDimension{
      id
      box_Num
      len
      width
      height
      weight
      dimensionalWeight
      cubicFeet
      rateIds
      boxDimensionId
      barcode
      assign{
        assignTo
        assignFrom
        date
        status
      }
      status{
        status
        date
        type
      }
    }
    binNum
    receiverInfo{
      name
      phone
      address1
      address2
      city
      stateId{
        name
      }
      countryId{
        name
      }
    }
    customerInfo{
      firstname
      lastname
      email
    }
    assign{
      assignTo
      assignFrom
      date
      status
    }
    createdAt
  }
}`


export const GET_ASSIGNINFO = gql`
query getAssignInfo($packageBoxId:ID){
  getAssignInfo(packageBoxId:$packageBoxId){
 	Box{
    assignTo{
      id
      userType
      firstName
      lastName
    }
    assignFrom{
      id
      userType
      firstName
      lastName
    }
    date
    status
  }
    signature
  }
}`