import React, { useState } from 'react'

export const ContactInfo = () => {
    const [genaralSettings ]= useState(JSON.parse(localStorage.getItem('generalSettings')));
  return (
    <div className="container mrgnTop110">
    <div className='text-center'>
      <p className="headTitle">Our Contact Info</p>
    </div>
    <div className="col-md-6 mAuto pb-2 invMain mt-30 extra">
      <div className="mb-3 mt-3">
        <label className="headText mt-2">Our Contact Information</label>
      </div>
      <div className="pb-1 boxShadowRate pl-3 pr-3 mb-4 boxInnerMargin">
        <div className="">
          <label className="mb-0 labelFs14">Head Office Address</label>
          <p className=" inputFs14">{`${genaralSettings?.headeOffAddress1 || ''}  ${genaralSettings?.headeOffCity || '' } - ${genaralSettings?.headeOffzipcode || ''} ${genaralSettings?.headeOffState || ''}, ${genaralSettings?.headeOffCountry || ''}`}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Head Office Working Time</label>
          <p className=" inputFs14">{genaralSettings?.headeOffWorkingHrs || ''}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Lagos Address</label>
          <p className=" inputFs14">{`${genaralSettings?.lagosOffAddress1 || ''}, ${genaralSettings?.lagosOffAddress2 || ''} ${genaralSettings?.lagosOffCity || ''}, ${genaralSettings?.lagosOffState || ''}`}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Lagos Working Time</label>
          <p className=" inputFs14">{genaralSettings?.lagosOffWorkingHrs || ''}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Email</label>
          <p className=" inputFs14">{genaralSettings?.headeOffCustomerServiceEmail || ''}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Phone</label>
          <p className=" inputFs14">{genaralSettings?.headeOffCustomerServicePhone || ''}</p>
        </div>
        <div className="">
          <label className="mb-0 labelFs14">Website</label>
          <p className=" inputFs14">{genaralSettings?.headeOffWeb || ''}</p>
        </div>
      </div>
    </div>
  </div>
  )
}
