import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { GET_ALL_COUNTRY } from '../../../graphql/query/CountryQuery';

const CountryList = ({ countryId, withCode, getCountryCode, allData, getAllInfo, getCRUDCountryId, getCountry }) => {
  const [countryList, setCountryList] = useState([]);
  const [value, setValue] = useState(null);
  const [colorError, setColorError] = useState(undefined);

  const { loading, data } = useQuery(GET_ALL_COUNTRY, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data && data?.getAllCountryList && data?.getAllCountryList.length > 0) {
      const Countries = data?.getAllCountryList;
      let CountriesList = []
      Countries.forEach(d => {
        CountriesList.push({ value: d.id, label: `${d.name} || ${d.code}` })
      });
      setCountryList(CountriesList);
    }
  }, [loading, data]);

  useEffect(() => {
    if (countryList.length > 0) {
      setDefaultCounty();
    }
  }, [countryId, countryList]);

  const countryHandleChange = (selectedValue) => {
    const { getAllCountryList } = data;
    setValue(selectedValue);
    setColorError(true);

    let code = selectedValue.label.split('||')[1].trim();
    withCode && getCountryCode(code);

    if (allData) {
      let findData = _.find(getAllCountryList, d => { return d.id === selectedValue.value });
      getAllInfo(findData);
    } else {
      getCRUDCountryId(selectedValue?.value);
      if (getCountry)
        getCountry(selectedValue);
    }
  };

  const setDefaultCounty = () => {
    if (countryId && countryList?.length > 0) {
      let countryData = _.find(countryList, (d) => d?.value === countryId);
      setValue(countryData);
    } else {
      setValue(null);
    }
  };

  return (
    <Select
      className="basic-single ffPoppins"
      options={countryList}
      value={value}
      onChange={(value) => countryHandleChange(value)}
      styles={{
        control: (provided, state) =>
          !colorError && colorError !== undefined
            ? {
              ...provided,
              boxShadow: "0 0 0 1px #dc3545 !important",
              border: "1px #dc3545 !important"
            }
            : provided
      }}
    />
  );
};

export default CountryList;
