import { useQuery } from '@apollo/client';
import React, { useEffect, useState } from 'react'
import { GET_ALL_RATES_TYPES } from '../../../graphql/query/RatesQuery';

export const ChargesReport = ({ reportData, type, custName }) => {
    const [rates, setRates] = useState([]);
    const [shipmentNo, setShipmentNo] = useState()
    const { getChargesReport } = reportData;
    const { data } = useQuery(GET_ALL_RATES_TYPES, {
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (data?.getAllRatesList?.length) {
            const newRates = [];
            newRates.push('NO');
            if (type === 'Explained') {
                newRates.push('OrderDate', 'customer_name');
            } else if (type === 'Summarized') {
                newRates.push('Shipment No')
            }
            newRates.push(...data?.getAllRatesList.map(rates => rates.name));
            newRates.push('TOTAL');
            setRates(newRates);
        }
    }, [data, type])

    useEffect(() => {
        if (getChargesReport) {
            getChargesReport?.map((e) => setShipmentNo(e?.shipmentNo))
        }
    }, [getChargesReport])

    const aggregateCharges = (charges) => {
        return charges.reduce((acc, charge) => {
            const rateType = charge.charges.split(', ')[0];
            const amount = parseFloat(charge.amount);

            if (!acc[rateType]) {
                acc[rateType] = 0;
            }
            acc[rateType] += amount;
            return acc;
        }, {});
    };

    const calculateTotalAmounts = (reports) => {
        const totalAmounts = {};

        reports.forEach(report => {
            const aggregatedCharges = aggregateCharges(report.charges);

            for (const [rateType, amount] of Object.entries(aggregatedCharges)) {
                if (!totalAmounts[rateType]) {
                    totalAmounts[rateType] = 0;
                }
                totalAmounts[rateType] += amount;
            }
        });

        return totalAmounts;
    };

    const totalAmounts = calculateTotalAmounts(getChargesReport);

    const sortedRates = rates
        .filter(rate => rate !== 'NO' && rate !== 'customer_name' && rate !== 'OrderDate' && rate !== 'TOTAL' && rate !== 'Shipment No')
        .sort((a, b) => (totalAmounts[b] || 0) - (totalAmounts[a] || 0));

    const displayedRates = ['NO',  ...(type === 'Explained' ? ['customer_name'] : []), ...(type === 'Explained' ? ['OrderDate'] : ['Shipment No']), ...sortedRates, 'TOTAL'];

    return (
        (type === 'Explained') ?
            <div className="col-md-12 no-padding d-inline-block">
                <div id="totalreceived" className="table-responsive">
                    <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                        <thead>
                            <tr>
                                {displayedRates.map(rate => <th key={rate}>{rate}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {getChargesReport.length ?
                                <>{
                                    getChargesReport.map((report, i) => {
                                        const { charges, customerInfo, orderDate, totalAmount, totalAmountNGN } = report;
                                        const aggregatedCharges = aggregateCharges(charges);

                                        return (
                                            <tr key={i} className="odd gradeX">
                                                {displayedRates.map(rate => {
                                                    if (rate === 'NO')
                                                        return <td key={`${i}-no`}>{i + 1}</td>;
                                                    else if (rate === 'customer_name')
                                                        return <td key={`${i}-customer_name`}>{customerInfo?.firstname} {customerInfo?.lastname} | {customerInfo?.pmb_no}</td>;
                                                    else if (rate === 'OrderDate')
                                                        return <td key={`${i}-OrderDate`}>{new Date(orderDate).toLocaleDateString('en-US')}</td>;
                                                    else if (rate === 'TOTAL')
                                                        return <td key={`${i}-TOTAL`} style={{ textAlign: 'end' }}>{parseFloat(totalAmount).toFixed(2)}</td>;
                                                    else {
                                                        const totalAmountForRate = aggregatedCharges[rate] || 0;
                                                        return (
                                                            <td key={`${i}-${rate}`} style={{ textAlign: 'end' }}>
                                                                {totalAmountForRate.toFixed(2)}
                                                            </td>
                                                        );
                                                    }
                                                })}

                                            </tr>
                                        );
                                    })
                                }
                                    < tr className="odd gradeX" >
                                        <td colSpan={3} style={{ textAlign: 'end', fontWeight: 'bold' }}><strong>TOTAL</strong></td>
                                        {
                                            (() => {
                                                const totalAmounts = calculateTotalAmounts(getChargesReport);
                                                return displayedRates.map(rate => {
                                                    if (rate === 'NO' || rate === 'customer_name' || rate === 'OrderDate' || rate === 'TOTAL') {
                                                        return null;
                                                    }
                                                    else {
                                                        const totalAmount = totalAmounts[rate] || 0;
                                                        return <td key={rate} style={{ textAlign: 'end' }}><strong>{totalAmount.toFixed(2)}</strong></td>;
                                                    }
                                                });
                                            })()
                                        }
                                        <td key="totalAmountNGN" style={{ textAlign: 'end' }}>
                                            <strong>
                                                {parseFloat(getChargesReport?.reduce((acc, report) => acc + parseFloat(report.totalAmount), 0)).toFixed(2)}
                                            </strong>
                                        </td>
                                    </tr>
                                </>
                                :
                                <tr className="odd gradeX">
                                    <td colSpan={displayedRates?.length}>NO RECORD FOUND</td>
                                </tr>
                            }
                        </tbody>

                    </table>
                </div>
            </div > :
            <div className="col-md-12 no-padding d-inline-block">
                <div id="totalreceived" className="table-responsive">
                    <table width="100%" className="table table-striped table-bordered table-hover" id="dataTables-example">
                        <thead>
                            <tr>
                                {displayedRates.map(rate => <th key={rate}>{rate}</th>)}
                            </tr>
                        </thead>
                        <tbody>
                            {getChargesReport.length ?
                                <>
                                    <tr>
                                        <td>1</td>
                                        <td>{shipmentNo}</td>
                                        {(() => {
                                            const totalAmounts = calculateTotalAmounts(getChargesReport);
                                            return displayedRates.map(rate => {
                                                if (rate === 'NO' || rate === 'customer_name' || rate === 'OrderDate' || rate === 'TOTAL' || rate === 'Shipment No') {
                                                    return null;
                                                } else {
                                                    const totalAmount = totalAmounts[rate] || 0;
                                                    return <td key={rate} style={{ textAlign: 'end' }}><strong>{totalAmount.toFixed(2)}</strong></td>;
                                                }
                                            });
                                        })()}

                                        <td style={{ textAlign: 'end' }}><strong>{parseFloat(getChargesReport?.reduce((acc, report) => acc + parseFloat(report.totalAmount), 0)).toFixed(2)}</strong></td>

                                    </tr>
                                    <tr className="odd gradeX">
                                        <td colSpan={2} style={{ textAlign: 'end', fontWeight: 'bold' }}><strong>TOTAL</strong></td>
                                        {(() => {
                                            const totalAmounts = calculateTotalAmounts(getChargesReport);
                                            return displayedRates.map(rate => {
                                                if (rate === 'NO' || rate === 'customer_name' || rate === 'OrderDate' || rate === 'TOTAL' || rate === 'Shipment No') {
                                                    return null;
                                                } else {
                                                    const totalAmount = totalAmounts[rate] || 0;
                                                    return <td key={rate} style={{ textAlign: 'end' }}><strong>{totalAmount.toFixed(2)}</strong></td>;
                                                }
                                            });
                                        })()}
                                        <td style={{ textAlign: 'end' }}><strong>{parseFloat(getChargesReport?.reduce((acc, report) => acc + parseFloat(report.totalAmount), 0)).toFixed(2)}</strong></td>
                                    </tr>
                                </>
                                :
                                <tr className="odd gradeX">
                                    <td colSpan={displayedRates?.length}>NO RECORD FOUND</td>
                                </tr>
                            }
                        </tbody>
                    </table>
                </div>
            </div>
    )
}
