import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { useQuery } from '@apollo/client';
import { GET_ALL_ONLINE_STORE } from '../../../../graphql/query/OnlineStoreQuery';
import ReactPaginate from 'react-paginate';
import { useLocation } from 'react-router';
import { OrderStatusList, OrderStatusType } from '../../../components/Constant';
import { GET_ALL_ORDERS_STATUS_WISE } from '../../../../graphql/query/orderQuery';
import { ORDER_CHANGE_SUBSCRIBE } from '../../../../graphql/subscription/MyOrderSubscription';
import { OrderComp } from '../../../components/OrderComp';

const MyOrder = () => {
  const loggedUser = (JSON.parse(localStorage.getItem('loggedUser')) || null);
  const [tabs, setTabs] = useState('pending');
  const [statusColor] = useState('#DC3535');
  const [pageNo, setPageNo] = useState(0);
  const [limit] = useState(10);
  const location = useLocation();

  const { data: getCustomerOrdersStatusWise, refetch, loading: loadingOne } = useQuery(GET_ALL_ORDERS_STATUS_WISE, {
    variables: {
      page: pageNo + 1,
      limit: limit,
      orderStatus: tabs,
      customerId: loggedUser.customerId
    },
    fetchPolicy: 'cache-and-network'
  })
  const { data: getAllOnlineStores, loading: loadingTwo } = useQuery(GET_ALL_ONLINE_STORE);

  useEffect(() => {
    if (location) {
      const { state } = location;
      if (state && state?.orderstatus !== undefined) {
        let tabName = localStorage.getItem('tab');
        if (tabName !== state.orderstatus) {
          setTabs(state?.orderstatus);
        } else {
          setTabs(tabName);
        }
      }
    }

    return () => {
      localStorage.removeItem('tab');
    };
  }, []);

  const _tabClick = (tabs) => {
    setTabs(tabs?.value);
    localStorage.setItem('tab', tabs.value);
  };

  const handlePageClick = (data) => {
    setPageNo(data?.selected);
  };

  // const subscriptionCall = () => {
  //   subscribeToMore({
  //     document: ORDER_CHANGE_SUBSCRIBE,
  //     updateQuery: (previousResult, { subscriptionData }) => {
  //       if (!subscriptionData.data) {
  //         return previousResult;
  //       }
  //       const { orderChange } = subscriptionData.data;
  //       if (orderChange && orderChange.keyType === "UPDATE") {
  //         let updateRatesData = orderChange && orderChange.data;
  //         let AllRatesData = previousResult?.getCustomerOrdersStatusWise?.data;
  //         let updatedRatesData = _.map(AllRatesData, (d) => {
  //           if (d.id === updateRatesData.id) {
  //             return updateRatesData;
  //           }
  //           else return d;
  //         });
  //         return {
  //           ...previousResult,
  //           getCustomerOrdersStatusWise: {
  //             ...previousResult.getCustomerOrdersStatusWise,
  //             count: previousResult.getCustomerOrdersStatusWise.count,
  //             data: [...updatedRatesData]
  //           }
  //         };
  //       } else if (orderChange && orderChange.keyType === "DELETE") {
  //         let updateRatesData = orderChange && orderChange.data;
  //         let AllRatesData = previousResult.getCustomerOrdersStatusWise.data;
  //         let updatedRatesData = _.reject(AllRatesData, d => {
  //           return d.id === updateRatesData.id;
  //         });
  //         return {
  //           ...previousResult,
  //           getCustomerOrdersStatusWise: {
  //             ...previousResult.getCustomerOrdersStatusWise,
  //             count: parseInt(previousResult.getCustomerOrdersStatusWise.count) - 1,
  //             data: [...updatedRatesData]
  //           }
  //         };
  //       } else if (orderChange && orderChange.keyType === "INSERT") {
  //         let bd = [
  //           ...[orderChange.data],
  //           ...previousResult.getCustomerOrdersStatusWise.data,
  //         ];
  //         return {
  //           ...previousResult,
  //           getCustomerOrdersStatusWise: {
  //             ...previousResult.getCustomerOrdersStatusWise,
  //             count: parseInt(previousResult.getCustomerOrdersStatusWise.count) + 1,
  //             data: bd.slice(0, limit),
  //           },
  //         };
  //       }
  //     },
  //   });
  // };

  return (
    <div className="container mrgnTop90 pb-5">
      <div className='text-center mb-5'>
        <h4>My Orders</h4>
      </div>
      <div className='dFlex bottomBrdr col-md-12 p-0 justify-content-between'>
        {OrderStatusList.map((b) => {
          return (
            <div key={b?.value}
              onClick={() => _tabClick(b)}
              className='orderTabBtn pointer'
              style={{ backgroundColor: tabs === b.value ? b.btnColor : 'transparent', position: "relative" }}
            >
              <label
                className='mb-0 pointer'
                style={{ color: tabs === b.value ? '#fff' : b.btnColor }}
              >{b?.label}</label>
              {tabs === b?.value &&
                <div className="triangle-down-order" style={{ borderBlockColor: b.btnColor }}></div>
              }
            </div>
          )
        })}
      </div>
      <div className="col-md-12 mAuto pb-2 invMain mt-5 mb-3" style={{ borderColor: statusColor }} >
        <>
          {( getCustomerOrdersStatusWise && getAllOnlineStores) &&
            <>
              <div className="mt-3 mb-3">
                <label className="headText mt-2">My Order list</label>
                <div className="pull-right mt-2">
                  <ReactPaginate
                    previousLabel={'Back'}
                    nextLabel={'Next'}
                    pageCount={Math.ceil(getCustomerOrdersStatusWise?.count / limit)}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={5}
                    forcePage={pageNo}
                    onPageChange={handlePageClick}
                    containerClassName={'pagination mb-0'}
                    subContainerClassName={'page-item'}
                    activeClassName={'active'}
                    pageLinkClassName={'pagination-link'}
                  />
                </div>
              </div>
              <OrderComp
                orderData={getCustomerOrdersStatusWise}
                onlineStoreData={getAllOnlineStores}
                borderColor={statusColor}
                statusType={OrderStatusType}
                pageNo={pageNo}
                limit={limit}
                orderStatus={tabs}
                customerId={loggedUser.customerId}
                isLoading={loadingOne || loadingTwo}
                refetchData={() => refetch()}
              />
            </>
          }
        </>
      </div>
    </div>
  );
};

export default MyOrder;
