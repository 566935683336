import { Children, useState } from "react"
import ChangeHeaderProfile from "./MyProfileContex";
import { MyProfile } from "./MyProfile";

const ProfileChange = ({children}) => {
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('loggedUser')));
    return(
        
        <ChangeHeaderProfile.Provider value={{ user, setUser }}>
            {children}
        </ChangeHeaderProfile.Provider>
        
    )
}
export default ProfileChange