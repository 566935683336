import { gql } from "@apollo/client";

export const GET_ONLINE_STORE = gql`
  query getOnlineStores($page: Int, $limit: Int, $filter: String, $sort: OnlineStoreSort){
    getOnlineStores(page: $page, limit: $limit, filter: $filter, sort: $sort){
      count
      data{
        id
        name
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
    }
  }
`;

export const GET_ALL_ONLINE_STORE = gql`
  query getAllOnlineStores($page: Int, $limit: Int, $filter: String, $id: String){
    getAllOnlineStores(page: $page, limit: $limit, filter: $filter, id: $id){
        id
        name
        isActive
        createdBy
        updatedBy
        createdAt
        updatedAt
    }
  }
`;