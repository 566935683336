import { gql } from "@apollo/client";

export const ADD_ORDER = gql`
mutation addOrder($input: OrderInput!,$log: LogInput){
  addOrder(input:$input,log: $log){
    id
    customerId
    customerInfo{
      firstname
      lastname
      email
      phone
      pmb_no
      userType
    }
    trackingno
    orderRateIds
    binId
    items {
      id
      description
      instruction
      qty
      price
      total
      isInsure
      insurerate
      insureprice
      itemStatus
      rate
      itemRate
      createdby
      updatedBy
      isPacked
    }
    receiverAddress{
      name
      phone
      address1
      address2
      city
      closestCityId{
        id
        name
      }
      stateId{
        id
        name
      }
      countryId{
        id
        name
      }
    }
  }
}

`;