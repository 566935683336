import React, { useState } from 'react'
import { HeaderComp } from '../../../components/HeaderComp';
import { useQuery } from '@apollo/client';
import GET_FAQs from '../../../../graphql/query/FAQsQuery';
import { Footer } from '../../../layouts/Footer';
import Collapsible from 'react-collapsible';


export const CollapsibleView = ({loop}) => {
    const [isOpen ,setisOpen] = useState(false);

    const _isOpenClose = () => {
        setisOpen(!isOpen);
    }
    return (
        <Collapsible trigger={
            <div onClick={() => {_isOpenClose()}}>
              <i className="fa fa-question-circle-o pr-3 align-middle themeBlack fs22" aria-hidden="true"></i>
              <span className="align-middle">{loop.question}</span>
              {isOpen ?
                <i className="fa fa-angle-up float-right themeRed fs25" aria-hidden="true"></i>
                :
                <i className="fa fa-angle-down float-right themeRed fs25" aria-hidden="true"></i>
              }
            </div>
        } className="Collapsible-head" >
        <p className="labelFs14 mb-0">{loop?.answer}</p>
        </Collapsible>
    )
}

const FAQsWeb = () => {
    const { data} = useQuery(GET_FAQs, {
        variables: {
            filter: "",
            sort: {}
        },
        fetchPolicy: "cache-and-network"
    });
    return (
        <div className="container-fluid p-0 bgWhite">
            <div className="aboutImg" style={{ backgroundImage: "url(../images/sliderFAQ.png)", }} >
                <HeaderComp Title={'FAQ\'s'} />
            </div>
            <div className="pb-5">
                <div className='container mt-5'>
                    {data && data?.getFaqs ? (data?.getFaqs || []).map((faq) => {
                        return (
                                <CollapsibleView key={faq?.id} loop={faq} />
                        )
                    })
                        : <div>No Records Found!</div>
                    }
                </div>
            </div>
            <Footer />
        </div >
    )
}

export default FAQsWeb