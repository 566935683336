import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { DELETE_BIN, INSERT_UPDATE_BIN, RELEASE_BIN } from '../../../../graphql/mutation/BinsMutation';
import { GET_BINS } from '../../../../graphql/query/binsQuery';
import confirmDeletion from '../../../components/Common/BootBox';
import ReactPaginate from 'react-paginate';
import Loader from '../../../components/Loader/Loader';
import { GetBinsData } from './GetBinsData';
import { InsertUpdateBins } from './InsertUpdateBins';
import { statusType } from '../../../components/Constant';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import { AccessDenied } from '../../../components/AccessDenied';
import { EditBins } from './EditBins';
import Pagination from '../../../components/Common/Pagination';

export const Bins = () => {
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'));
    const user = JSON.parse(localStorage.getItem('loggedUser'));

    const [Bins, setBins] = useState({
        id: null,
        bin: null,
        isFree: null,
        isActive: true,
        newBinStatus: statusType[0],
        createdBy: null,
        modifiedBy: null,
        limit: genSettings?.paginationLimit || 10,
        pageNo: 0,
        filter: "",
        filterText: '',
        sortKey: 'createdAt',
        sortType: -1,
        updatebin: "",
        binStatus: true,
        oldData: null,
        binUpdateId: '',
        prefix: '',
        numberOfBins: ''
    });
    const [releaseBin] = useMutation(RELEASE_BIN, {
        refetchQueries: [{ query: GET_BINS, variables: { "page": Bins?.pageNo + 1, "limit": Bins?.limit, "filter": Bins?.filter, "sort": { key: Bins.sortKey, type: Bins.sortType } } }]
    })
    const { loading, data, refetch } = useQuery(GET_BINS, {
        variables: { "page": Bins?.pageNo + 1, "limit": Bins?.limit, "filter": Bins?.filter, "sort": { key: Bins.sortKey, type: Bins.sortType } },
        fetchPolicy: 'cache-and-network'
    });
    const [insertUpdateBin, { inserUpdateLoading }] = useMutation(INSERT_UPDATE_BIN);

    const addNewBin = (e) => {
        e.preventDefault();
        $('#AddNewBinModal').modal('show');
    }

    const releaseBins = (e) => {
        e.preventDefault();
        const releaseBins = () => {
            releaseBin({ variables: {} }).then(async ({ data }) => {
                if (data) {
                    toast.success("Bin released successfully!", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion("Release Bin", "Are you sure want to Release Bins ?", releaseBins);
    }

    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchBins()
        }
    }
    const searchBins = (e) => {
        setBins((prevBins) => ({ ...prevBins, pageNo: 0, filter: Bins.filterText }));
    }
    const onResetSearch = (e) => {
        setBins((prevBins) => ({ ...prevBins, pageNo: 0, filter: "", filterText: '' }));
    }
    const handlePageClick = (data) => {
        setBins((prevBins) => ({ ...prevBins, pageNo: data?.selected }));
    };

    const onSubmit = (event, flag) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            let input, log;
            const { prefix, numberOfBins, newBinStatus, binUpdateId, binStatus, oldData } = Bins;
            if (flag) {
                input = { prefix, numberOfBins: numberOfBins && parseInt(numberOfBins), isActive: newBinStatus.value ? true : false }
            } else {
                let status = binStatus.value ? true : false;
                input = { id: binUpdateId, isActive: status };
                if (input.isActive === oldData.isActive) {
                    $('#EditSelectedBinModal').modal('hide');
                    return
                }
            }
            log = {
                action: flag ? 'INSERT' : 'UPDATE',
                actionOn: 'bins',
                actionName: 'insertUpdateBin',
                oldValue: flag ? "" : JSON.stringify(oldData)
            }
            insertUpdateBin({ variables: { input, log } }).then(async ({ data }) => {
                if (data && data.insertUpdateBin) {
                    refetch();
                    form.classList.remove('was-validated');
                    setBins((preBins) => ({ ...preBins, newBinStatus: null, binStatus: null }))
                    if (flag) {
                        toast.success("Bins added successfully!", {
                            autoClose: 2000
                        })
                        $('#AddNewBinModal').modal('hide');
                    } else {
                        toast.success("Bins Update successfully!", {
                            autoClose: 2000
                        })
                        $('#EditSelectedBinModal').modal('hide');
                    }
                    form.reset()
                } else
                    toast.error("error, try again later! ", {
                        autoClose: 2000
                    })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    };
    if (user.roles === 'standard') return <AccessDenied />
    else
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 p-0">
                        <div className="mb-20">
                            <p className="mainTitle m-0 mt_-15">Bins List</p>
                            <span className="borderBottomOfTitle"></span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="float-right">
                            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewBin(e)}> <i className="fa fa-plus"></i> Add New Bin</button>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12 p-0">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span>Bins Details</span>
                                <div className="pull-right">
                                    <button className="btn btn-success btn-text bg-lightGreen" style={{ padding: '3px 25px' }} onClick={(e) => { releaseBins(e) }} >Release Bins </button>
                                </div>
                            </div>
                            <div className="ibox-content p-0">
                                {loading && <Loader />}
                                <div className="col-md-12 ph-20 mt-3">
                                    <div className="form-row">
                                        <div className="form-group col-lg-3 col-md-3 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Search</label>
                                            <input type="text" value={Bins?.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => { setBins((prevBins) => ({ ...prevBins, filterText: e.target.value })) }} className="form-control" />
                                        </div>
                                        <div className="form-group col-lg-9 col-md-9 col-sm-6 col-xs-12">
                                            <div className="mt-30">
                                                <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchBins()} >Search</button>
                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                                <div className="pull-right">
                                                    <Pagination
                                                        pageCount={data?.getBins && Math.ceil(data?.getBins?.count / Bins?.limit)}
                                                        pageNo={Bins?.pageNo}
                                                        handlePageClick={(data) => handlePageClick(data)}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <GetBinsData Bins={Bins} setBins={setBins} bins={data?.getBins} refetch={refetch} />
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal show" id="AddNewBinModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                        <div className="modal-content ">
                            <InsertUpdateBins inserUpdateLoading={inserUpdateLoading} Bins={Bins} setBins={setBins} onSubmit={onSubmit} />
                        </div>
                    </div>
                </div>

                <div className="modal show" id="EditSelectedBinModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                        <div className="modal-content ">
                            <EditBins inserUpdateLoading={inserUpdateLoading} setBins={setBins} Bins={Bins} onSubmit={onSubmit} />
                        </div>
                    </div>
                </div>
            </div >
        )
}
