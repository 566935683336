import { useMutation } from '@apollo/client';
import React, { useEffect } from 'react'
import { DELETE_RATES } from '../../../../graphql/mutation/RatesMutation';
import confirmDeletion from '../../../components/Common/BootBox';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import { PerItems, Percentage, RateType } from '../../../components/Constant';

export const GetRates = ({ rateData, rate, setRates ,refetch }) => {
    const [deleteRates] = useMutation(DELETE_RATES);
    const editRate = (e, oldRate) => {
        e.preventDefault();
        let amountValue, perValue, rateValue;
        if (oldRate.amountType) {
            amountValue = Percentage.filter(d => {
                return d.value === oldRate.amountType
            })
        }
        if (oldRate.per) {
            perValue = PerItems.filter(d => {
                return d.value === oldRate.per
            })
        }
        if (oldRate.rateType) {
            rateValue = RateType.filter(d => {
                return d.value === oldRate.rateType
            })
        }
        setRates(prevRates => ({
            ...prevRates, id: oldRate.id,
            rateData: oldRate,
            Old_Data: oldRate,
            amountColorError: true,
            perColorError: true,
            rateColorError: true,
            temp_amountType: amountValue,
            temp_per: perValue,
            temp_rateType: rateValue,
            openModal: 'Edit'
        }))
        $('#frm_Store').removeClass('was-validated');
        $('#RateModal').modal('show');
    }
    const deleteRateDetails = (e, rateId) => {
        e.preventDefault();
        const deleteRatesData = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'rates',
                actionName: 'deleteRates',
                oldValue: ""
            }
            deleteRates({ variables: { "id": rateId, "log": log } }).then(async ({ data }) => {
                refetch();
                $('#RateModal').modal('hide');
                toast.success("Rate deleted successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion('Delete Rates' , 'Are you sure want to delete this record ?',deleteRatesData)
    }
    const sortData = (sortKey) => {
        const newSortType = (rate.sortType === 1) ? -1 : 1;
        setRates((preRates) => ({ ...preRates, sortKey: sortKey, sortType: newSortType }));
    };
    return (
        <>
            <div className="table-responsive">
                <table className="table themeTable table-nowrap mb-0 tbl-sort">
                    <thead>
                        <tr>
                            <th className="text-center">No.</th>
                            <th
                                className={`sortColumn ${(rate?.sortKey === 'name') ? (rate?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                onClick={(e) => sortData('name')} >Name</th>
                            <th
                                className={`sortColumn ${(rate?.sortKey === 'rateType') ? (rate?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                onClick={(e) => sortData('rateType')} >Rate Type</th>
                            <th
                                className={`sortColumn ${(rate?.sortKey === 'amount') ? (rate?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                onClick={(e) => sortData('amount')} >Amount</th>
                            <th
                                className={`sortColumn ${(rate?.sortKey === 'per') ? (rate?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                onClick={(e) => sortData('per')} >Per Item</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            rateData && rateData.data && rateData.data.length > 0
                                ? rateData.data && rateData.data.map((d, i) => {
                                    return (
                                        <tr key={d.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editRate(e, d)}>
                                            <td className=" text-center">{i + 1}</td>
                                            <td>{d.name}</td>
                                            <td>{d.rateType}</td>
                                            <td>{d.amountType}{d.amount}</td>
                                            <td>{d.per}</td>
                                            <td>
                                                <div className="d-flex flex-row">
                                                    <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editRate(e, d)}>
                                                        <img src="../images/edit.png" alt="Img" className="width15" />
                                                    </span>
                                                    <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => { deleteRateDetails(e, d.id)}}>
                                                        <img src="../images/bin.png" alt="Img" className="width15" />
                                                    </span>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })
                                : <tr>
                                    <td style={{ textAlign: 'center' }} colSpan={5} >No Records Found!</td>
                                </tr>
                        }
                    </tbody>
                </table>
            </div>
        </>
    )
}
