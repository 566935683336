import { useMutation } from '@apollo/client'
import React, { useContext, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { SIGN_IN } from '../../graphql/mutation/loginMutation'
import { toast } from 'react-toastify';
import Loader from '../components/Loader/Loader';
import ChangeHeaderProfile from './CustomerPortal/MyProfile/MyProfileContex';


const Login = ({refetch}) => {
  const {user,setUser} = useContext(ChangeHeaderProfile);
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [passwordType, setPasswordType] = useState("password")
  const [errorFlag, setErrorFlag] = useState(false)
  let navigate = useNavigate()

  const [signIn ,{loading}] = useMutation(SIGN_IN);

  const togglePassword = () => {
    if (passwordType === "password") {
      setPasswordType("text")
      return;
    }
    setPasswordType("password")
  }

  const onSubmit = (e) => {
    e.preventDefault()
    setErrorFlag(false)
    const form = e.currentTarget;
    if (!form.checkValidity()) {
      e.stopPropagation();
      form.classList.add('was-validated');
      toast.error('Please fill the required fields...', {
        autoClose: 2000
      })
    } else {
      localStorage.removeItem('token')
      signIn({
        variables: {
          email: email,
          password: password
        }
      }).then(async({ data }) => {
        localStorage.setItem('token', data?.signIn?.token);
        localStorage.setItem('loggedUser', JSON.stringify(data?.signIn?.user));
        localStorage.setItem('generalSettings', JSON.stringify(data?.signIn?.generalSettings));
        localStorage.setItem('customer', JSON.stringify(data?.signIn?.customer));
        setUser(data?.signIn?.user);
        await refetch();
        if (data?.signIn?.user?.roles === "customer") {
          navigate("/new-order");
        } else {
          navigate("/dashboard");
        }
        toast.success("user logged in successfully!", {
          autoClose: 2000
        })
        return;
      }).catch((err) => {
        if (err && !errorFlag) {
          toast.error(err.message, {
            autoClose: 2000
          })
          setErrorFlag(true)
        }
      })
    }
  }

  return (
    <div className="container">
      <div className="col-md-12 box-cener">
        <div className="col-lg-6 col-md-9 col-12 ViewCard p-0">
          <div className="logoView">
            <img className="signup-logo" alt="Logo" src="../images/smsLogo.png" height="100" width="260" />
          </div>
          <form className="needs-validation" noValidate="noValidate" onSubmit={(e) => onSubmit(e)}>
            {loading && <Loader />}
            <div className="form-group frm-Box">
              <input type="email" className="form-control txt-Box" placeholder="Email" required value={email} onChange={(e) => setEmail(e.target.value)} />
              <span className="txt-Box" style={{ display: "flex", justifyContent: "space-around", alignItems: "center" }}>
                <input className="form-control txt-Box" placeholder="Password" required type={passwordType} value={password} onChange={(e) => setPassword(e.target.value)} />
                <div className='eye-icons' onClick={() => togglePassword()} style={{ position: "absolute", right: "60px", cursor: "pointer" }}>
                  {passwordType !== "password" ? <i className="fa fa-eye"></i> : <i className="fa fa-eye-slash"></i>}
                </div>
              </span>
              <div>
                <Link to="/forgot-password">
                  <span className="float-right forgotPass">Forgot Password ?</span>
                </Link>
              </div>
              <button type="submit" className="btn btn-block btn-theme mb-20">SIGN IN</button>
              <div>
                <span className="linkTitle">Don't have account ?</span>
                <br />
                <Link to="/signup" >
                  <span className="linkLabel">SIGN UP NOW</span>
                </Link>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  )
}

export default Login