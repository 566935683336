import { useMutation } from '@apollo/client';
import React from 'react'
import confirmDeletion from '../../components/Common/BootBox';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import { DELETE_CATEGORY } from '../../../graphql/mutation/CategoryMutation';

export const GetCategory = ({ category, setCategory, CategoryData, refetch }) => {
    const [deleteCategory] = useMutation(DELETE_CATEGORY);

    const sortData = (sortKey) => {
        const newSortType = (category.sortType === 1) ? -1 : 1;
        setCategory((prevData) => ({ ...prevData, sortKey: sortKey, sortType: newSortType }));
    }
    const editCategory = (e, oldCategoryyData) => {
        e.preventDefault();
        setCategory(preData => ({
            ...preData,
            id: oldCategoryyData.id,
            categoryData: oldCategoryyData,
            Old_Data: oldCategoryyData,
            openModal: 'Edit'
        }))
        $('#frm_Category').removeClass('was-validated');
        $('#CategoryModal').modal('show');
    }
    const deleteCategoryDetails = (e, categoryId) => {
        e.preventDefault();
        const DeleteCategory = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'categories',
                actionName: 'deleteCategory',
                oldValue: ""
            }

            deleteCategory({ variables: { "id": categoryId, "log": log } }).then(async ({ data }) => {
                $('#CategoryModal').modal('hide');
                refetch();
                toast.success("Category deleted successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion("Delete CATEGORY", "Are you sure want to delete this record ?" ,DeleteCategory)
       
    }
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0 tbl-sort">
                <thead>
                    <tr>
                        <th className="text-center">No.</th>
                        <th>Icon</th>
                        <th
                            className={`sortColumn ${(category.sortKey === 'name') ? (category.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('name')}>Category Name</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        CategoryData && CategoryData.data && CategoryData.data.length > 0
                            ? CategoryData.data && CategoryData.data.map((d, i) => {
                                return (
                                    <tr key={d.id}>
                                        <td className=" text-center">{i + 1}</td>
                                        <td><img src={d.icon} alt="img" className="width55 img-thumbnail" /></td>
                                        <td>{d.name}</td>
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editCategory(e, d)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2"
                                                    onClick={(e) => { deleteCategoryDetails(e, d.id); }}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ textAlign: 'center' }} colSpan={4} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
