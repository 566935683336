import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Loader from '../../components/Loader/Loader';
import moment from 'moment';
import { GET_SHIPMENT, GET_ALL_NONEXPIREDSHIPMENT } from '../../../graphql/query/shipmentQuery';
import WarehouseList from '../../components/DropdownSelect/warehouseSelect'
import { INSERT_UPDATE_SHIPMENT } from '../../../graphql/mutation/shipmentMutation';

const CRUDShipment = ({ curdType, oldShipData, closeModel, refetch }) => {
  const [oldShipDataState, setOldShipData] = useState({
    id: null,
    wrhsId: null,
    shipmentNo: '',
    shippingDate: null,
    estimatedDate: null
  });

  useEffect(() => {
    $('.react-datepicker-wrapper').addClass('d-flex')
    if (oldShipData) {
      let shipData = oldShipData;
      setOldShipData(preData => ({
        ...preData, id: shipData.id,
        wrhsId: shipData.wrhsId?.id,
        shipmentNo: shipData.shipmentNo,
        shippingDate: new Date(moment(shipData.shippingDate).format('YYYY/MM/DD')),
        estimatedDate: new Date(moment(shipData.estimatedDate).format('YYYY/MM/DD')),
        oldShipData: {
          id: shipData.id,
          wrhsId: shipData.wrhsId?.id,
          shipmentNo: shipData.shipmentNo,
          shippingDate: new Date(moment(shipData.shippingDate).format('YYYY/MM/DD')),
          estimatedDate: new Date(moment(shipData.estimatedDate).format('YYYY/MM/DD')),
        }
      }))
    }
  }, [oldShipData]);

  const shippingDateChange = (date) => {
    setOldShipData(preData => ({
      ...preData, shippingDate: date
    }))
  }

  const estimatedDateChange = (date) => {
    setOldShipData(preData => ({
      ...preData, estimatedDate: date
    }))
  }

  const [insertUpdateShipment, { loading }] = useMutation(INSERT_UPDATE_SHIPMENT, {
    refetchQueries: [
      { query: GET_ALL_NONEXPIREDSHIPMENT, variables: { page: 1, limit: 10, serach: "" } },
    ],
    fetchPolicy: "no-cache"
  });

  const onSubmitShipment = (event) => {
    event.preventDefault();
    const form = event.currentTarget;
    if (!form.checkValidity()) {
      event.stopPropagation();
      form.classList.add('was-validated');
      toast.error('Please fill the required fields...', {
        autoClose: 2000
      })
      return false;
    } else {
      let { wrhsId, shipmentNo, shippingDate, estimatedDate, id } = oldShipDataState
      let input = {};
      let oldValue = {};

      if (curdType === "Edit" && oldShipDataState.oldShipData) {
        input.id = id;
        for (var obj in oldShipDataState.oldShipData) {
          if (oldShipDataState.oldShipData[obj] !== oldShipDataState[obj]) {
            oldValue[obj] = oldShipDataState.oldShipData[obj]
            input[obj] = oldShipDataState[obj]
          }
        }
      } else {
        input = {
          wrhsId: wrhsId,
          shipmentNo: shipmentNo,
          shippingDate: shippingDate,
          estimatedDate: estimatedDate,
        }
      }

      let log = {
        action: curdType === "Edit" ? "UPDATE" : "INSERT",
        actionOn: 'shipments',
        actionName: 'insertUpdateShipment',
        oldValue: curdType === "Edit" ? JSON.stringify(oldValue) : ""
      }
      insertUpdateShipment({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
        form.classList.remove('was-validated');
        form.reset();
        refetch();
        $('#AddNewShipmentModal').modal('hide');
        closeModel();
        if (curdType === "Edit") {
          toast.success("Shipment updated successfully!", {
            autoClose: 2000
          })
        } else {
          toast.success("Shipment added successfully!", {
            autoClose: 2000
          })
        }
      }).catch((err) => {
        toast.error(err.message, {
          autoClose: 2000
        })
      })
    }
  };
  let { wrhsId, shipmentNo, shippingDate, estimatedDate } = oldShipDataState;
  return (
    <div className="container-fluid">
      <div className="row">
        <div className="col-md-12 p-0">
          {loading && <Loader />}
          <div className="ibox">
            <div className="ibox-title">
              <span>Shipment Details</span>
            </div>
            <div className="ibox-content">
              <form id="frm_shipment" className="needs-validation" noValidate="noValidate" onSubmit={onSubmitShipment}>
                <div className="form-row">
                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                    <label className="frm-label">Shipment No <span className="text-danger">*</span></label>
                    <input type="text" className="form-control ffPoppins" name="shipmentNo" placeholder="Enter Shipment No" value={shipmentNo} onChange={(e) => setOldShipData(preData => ({...preData , shipmentNo : e.target.value})) } required />
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                    <label className="frm-label">Warehouse<span className="text-danger">*</span></label>
                    <WarehouseList wrhsId={wrhsId} getCRUDshipmentId={(wrhsId) => setOldShipData(preData => ({...preData , wrhsId: wrhsId}))} />
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                    <label className="frm-label">Shipping Date<span className="text-danger">*</span></label>
                    <DatePicker
                      className="form-control ffPoppins d-flex"
                      placeholderText="mm/dd/yyyy"
                      selected={shippingDate}
                      onChange={shippingDateChange}
                      minDate={new Date()}
                    />
                  </div>
                  <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                    <label className="frm-label">Estimated Date<span className="text-danger">*</span></label>
                    <DatePicker className="form-control ffPoppins d-flex" placeholderText="mm/dd/yyyy" selected={estimatedDate} onChange={estimatedDateChange} minDate={shippingDate} />
                  </div>
                </div>
                <div className="mt-3 mb-1">
                  <button type="submit" className="btn btn-primary btn-text border-0 bg-searchBlue mr-3">Save</button>
                  <button type="reset" className="btn btn-danger btn-text bg-resetRed" data-dismiss="modal" onClick={() => closeModel()}>Cancel</button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CRUDShipment;
