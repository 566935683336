import React from "react";
import { generateXmlExample, generateBoxLabel } from '../../components/Constant'
import moment from "moment";
import { toast } from "react-toastify";

const PrintLabelDemo = ({ orderDetailsData, selectedPrinter, orderDetailsBoxData }) => {
  const getPrinterName = (printerName) => {
    try {
      // select printer to print on
      // for simplicity sake just use the first LabelWriter printer
      var printers = window.dymo.label.framework.getPrinters();
      if (printers.length === 0)
        toast.error( "No DYMO printers are installed. Install DYMO printers.");

      var printerGetName = "";
      for (var i = 0; i < printers.length; ++i) {
        var printer = printers[i];

        //alert(printer.printerType)

        if (printer.printerType === "LabelWriterPrinter") {
          if (printer.name === printerName) {
            printerGetName = printer.name;
            break;
          }

        }
      }

      return printerGetName;
    } catch (e) {
      alert(e.message || e);
    }
  }

  const handlePrint = (e) => {
    e.preventDefault();
    let xmlMemo, printerName, label;
    if (orderDetailsData && Object.keys(orderDetailsData).length > 0) {
      const { customerInfo, trackingno, binNum, items, createdAt } = orderDetailsData;
      xmlMemo = generateXmlExample({ customerInfo, trackingno, binNum, items, createdAt });
      label = window.dymo.label.framework.openLabelXml(xmlMemo);
      const customerName = customerInfo.firstname + ' ' + customerInfo.lastname;
      label.setObjectText("TEXT__1", customerName || "");
      label.setObjectText("TEXT_2", trackingno || "");
      label.setObjectText("TEXT", binNum || "");
      label.setObjectText("TEXT_1", items?.length?.toString() || "");
      label.setObjectText("TEXT__2", moment(createdAt).format('MM/DD/YYYY') || "");
      printerName = getPrinterName("DYMO LabelWriter 450")
    } else if (orderDetailsBoxData) {
      const { receiverInfo, customerInfo, selectedValue } = orderDetailsBoxData; // customerInfo IS NOT PRESENT HERE, PLZ CHECK
      xmlMemo = generateBoxLabel({ receiverInfo, customerInfo, selectedValue });
      label = window.dymo.label.framework.openLabelXml(xmlMemo);

      const customerName = customerInfo?.firstname + ' ' + customerInfo?.lastname;
      const receiverName = receiverInfo?.name
      const receiverCity = receiverInfo?.city

      //label.setObjectText("TEXT", trackingno);
      label.setObjectText("TEXT_2", customerName || "");
      label.setObjectText("TEXT_3", receiverName || "");
      label.setObjectText("TEXT_4", selectedValue?.box_Num?.toString() || "");
      label.setObjectText("TEXT_5", receiverCity || "");
      //label.setObjectText("DATE-TIME", date_time);
      label.setObjectText("QRCODE", selectedValue?.barcode || "");
      label.setObjectText("BARCODE", selectedValue?.barcode || "");
      printerName = getPrinterName("DYMO LabelWriter 4XL")
    }
    // try {
      if (!printerName)
      toast.error( "No DYMO printers are installed. Install DYMO printers.");

      else {
        // finally print the label
        label.print(printerName);
      }
    // } catch (error) {
    //   alert(error.message || e);
    // }

  }



  return (
    <div>
      <button onClick={(e) => handlePrint(e)} className="btn btn-primary btn-text border-0 bg-searchBlue" style={{ marginTop: "6px" }}>
        Print label
      </button>
    </div>
  );
}

export default PrintLabelDemo;