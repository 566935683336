import React from 'react'

export const ServiceTypeSection = ({label, options, selectedOption, setOption, msgs }) => {
  return (
    <div className="boxShadowDiv pl-0 pr-0 pt-2 pb-2">
      <div className="pl-0 pr-0">
        <div className="col-md-12 mb-2">
          <label className="mb-0 labelFs14">{label}<span className="text-danger">*</span></label>
          <div className='mt-2 mb-2 d-flex'>
            {options.map((option, index) => (
              <div key={index} className="col-md-6">
                <div
                  onClick={() => setOption(option.value)}
                  className='flex pointer'
                  data-toggle="modal" data-target={option.modalTarget}
                >
                  <div className="radio mr-2 pointer">
                    <div className={selectedOption === option.value ? "radioCircle" : "radioDisable"} />
                  </div>
                  <label className="mb-0 inputFs14 mr-4 pointer">{option.label}</label>
                </div>
                <div className="mb-2 mt-2 text-justify text-muted">
                  {`${option.label} : ${msgs[option.value]}`}
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}
