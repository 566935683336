import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { GET_RECEIVER_CUSTOMER } from '../../../graphql/query/AddressQuery';

const ReciverList = ({ customerId, ReceiverId, getAddresschange }) => {
    const [addressList, setAddressList] = useState([]);
    const [value, setValue] = useState(null);
    const [colorError, setColorError] = useState(undefined);
    const [oneCall, setOneCall] = useState(false);

    const { loading, error, data } = useQuery(GET_RECEIVER_CUSTOMER, {
        variables: { customerId: customerId || null },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if(data?.ReceiverId !== ReceiverId)
        {
            setAddressList([]);
            setValue(null);
        }
        if (data?.getReceiversOfCustomer?.length) {

            let ReciverAddress = data?.getReceiversOfCustomer
            let getReciverAddress = []
            ReciverAddress.forEach(d => {
                getReciverAddress.push({ value: d.id, label: `${d.name} | ${d.address1} | ${d.city} | ${d.stateId?.name} | ${d.countryId?.name}`, name: d.name, address1: d.address1, address2: d.address2, city: d.city, closestCityId: d.closestCityId, stateId: d.stateId, countryId: d.countryId, zipCode: d.zipCode, phone: d.phone, customerId: d.customerId })
            });
            setAddressList(getReciverAddress);
            setAddressList(getReciverAddress);
        }
    }, [data]);

    useEffect(() => {
        setDefaultAddress();
    }, [addressList, ReceiverId]);

    const reciverAddressHandleChange = (value) => {
        setColorError(true);
        setValue(value);
        let info = {
            id: value.value,
            name: value.label,
            name1: value.name,
            address1: value.address1,
            address2: value.address2,
            city: value.city,
            closestCityId: value.closestCityId,
            stateId: value.stateId,
            countryId: value.countryId,
            zipCode: value.zipCode,
            phone: value.phone,
            customerId: value.customerId
        }
        getAddresschange(info);
    };

    const setDefaultAddress = () => {
        if (ReceiverId) {
            const ReciverData = _.find(addressList, (d) => d.value === ReceiverId);
            if (ReciverData) {
                ReciverData.id = ReceiverId;
                ReciverData.name1 = ReciverData?.name;
                setValue(ReciverData);
                if (!oneCall) {
                    getAddresschange(ReciverData);
                    setOneCall(true);
                }
            }
        } else {
            setValue(null);
        }
    };

    return (
        <Select
            className="basic-single ffPoppins"
            options={addressList}
            value={value}
            onChange={(value) => reciverAddressHandleChange(value)}
            isLoading={loading}
            isDisabled={loading || error}
            styles={{
                control: (provided, state) =>
                    !colorError && colorError !== undefined
                        ? { ...provided, boxShadow: "0 0 0 1px #dc3545 !important", border: "1px #dc3545 !important" }
                        : provided,
            }}
        />
    );
};

export default ReciverList;

