import { gql } from "@apollo/client";

export const ORDER_DASHBOARD = gql`
query getOrdersForDashBoard{
  getOrdersForDashBoard{
    id
    trackingno
    receiverId
    binId
    binNum
    shipmentId
    receiverAddress {
      name
      address1
      address2
      phone
      city
      closestCityId{
        id
        name
      }
      stateId{
        id
        name
      }
      countryId{
        id
        name
      }
      zipCode
    }
    customerId
    customerInfo {
      firstname
      lastname
      email
      phone
      pmb_no
      userType
    }
    orderRateIds
   onlineStoreId {
        name
        id
      }
    orderFromSms
    invoiceId
    isInvoice
    isRecreateInvoice
    insurePer
    statusDate
    isPromoCode
    status
    items {
      id
      qty
      price
      total
      description
      rate
      instruction
      isInsure
      itemStatus
      insurerate
      insureprice
    }
    remark
    invoiceOrReceipt
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt
  }
}`

export const GRAPH_DATA = gql`
query getMonthlyOrder{
  getMonthlyOrder{
    _id
    count
  }
}
`
export const GET_ALL_ORDERS_STATUS_WISE = gql`
query getCustomerOrdersStatusWise($page: Int, $limit: Int,$orderStatus: String, $customerId: ID){
  getCustomerOrdersStatusWise(page:$page,limit:$limit,orderStatus:$orderStatus,customerId: $customerId){
    count
    data {
      id
      trackingno
      receiverId
      binId
      binNum
      shipmentId {
        shipmentNo
        estimatedDate
      }
      receiverAddress {
        name
        address1
        address2
        phone
        city
        closestCityId{
          id
          name
        }
        stateId{
          id
          name
        }
        countryId{
          id
          name
        }
        zipCode
      }
      customerId
      customerInfo {
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      orderRateIds
      onlineStoreId {
        name
        id
      }
      orderFromSms
      invoiceId
      isInvoice
      isRecreateInvoice
      insurePer
      statusDate
      isPromoCode
      status
      items {
        id
        qty
        price
        total
        description
        rate
        instruction
        isInsure
        itemStatus
        insurerate
        insureprice
      }
      remark
      invoiceOrReceipt
      trackStatus
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;


export const SEARCHBOX_LIST = gql`
query getOrderForSearchBox($page: Int, $limit: Int, $filter: String){
  getOrderForSearchBox(page: $page, limit: $limit, filter: $filter){
    id
    customerInfo{
      firstname
      lastname
      email
      phone
      pmb_no
    }
    onlineStoreId {
      name
      id
    }
    trackingno
  }
}
`
export const GET_ORDER_FROM_ID = gql`
query getOrderFromId($id:ID!){
  getOrderFromId(id:$id){
    id
    trackingno
    receiverId
    binId
    binNum
    shipmentId
    receiverAddress {
    name
    address1
    address2
    phone
    city
    closestCityId{
      id
      name
    }
    stateId{
      id
      name
    }
    countryId{
      id
      name
    }
    zipCode
    }
    customerId
    customerInfo {
    firstname
    lastname
    email
    phone
    pmb_no
    userType
    }
    orderRateIds
    onlineStoreId {
      name
      id
    }
    orderFromSms
    invoiceId
    isInvoice
    isRecreateInvoice
    insurePer
    statusDate
    isPromoCode
    status
    items {
    id
    qty
    price
    total
    description
    rate
    instruction
    isInsure
    itemStatus
    insurerate
    insureprice
    }
    remark
    invoiceOrReceipt
    trackStatus
    isActive
    createdBy
    updatedBy
    createdAt
    updatedAt

}
}`
export const GET_ORDERS = gql`
query getOrders($page: Int, $limit: Int, $filter: String, $sort: orderSort, $from: String, $to: String, $orderStatus: String, $binNum: String){
  getOrders(page:$page,limit:$limit,filter:$filter,sort:$sort, from:$from, to:$to, orderStatus:$orderStatus, binNum:$binNum){
    count
    data {
      id
      trackingno
      receiverId
      binId
      binNum
      shipmentId
      receiverAddress {
        name
        address1
        address2
        phone
        city
        closestCityId{
          id
          name
        }
        stateId{
          id
          name
        }
        countryId{
          id
          name
        }
        zipCode
      }
      customerId
      customerInfo {
        firstname
        lastname
        email
        phone
        pmb_no
        userType
      }
      orderRateIds
      onlineStoreId {
        name
        id
      }
      orderFromSms
      invoiceId
      isInvoice
      isRecreateInvoice
      insurePer
      statusDate
      isPromoCode
      status
      items {
        id
      _id:id
        qty
        price
        total
        description
        rate
        instruction
        isInsure
        itemStatus
        insurerate
        insureprice
      }
      remark
      invoiceOrReceipt
      trackStatus
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
}
`;

export const GET_NOTIFICATION = gql`
query getOrderWiseNotifications($customerId: String, $orderId: String, $sort:sortNotification){
  getOrderWiseNotifications(customerId:$customerId,orderId:$orderId,sort:$sort){
      id
      customerId
      orderId
      tracking_no
      orderStatus
      name
      msg
      isDeleted
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
  }
}
`;