import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { Link } from "react-router-dom";
import moment from 'moment';
import { InvoiceDetails } from './InvoiceDetails';
import { InvoiceBreakdown } from './InvoiceBreakdown';

export const ViewInvoice = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const invoice = location?.state?.invoice;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="mb-20">
                    <p className="mainTitle m-0 mt_-15">View Invoice</p>
                    <span className="borderBottomOfTitle"></span>
                </div>
            </div>

            <div className="row mb-4">
                <InvoiceDetails invoice={invoice}/>

                <InvoiceBreakdown invoice={invoice}/>
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Invoice Orders List</span>
                        </div>
                        <div className="ibox-content p-0">
                            <table className="table themeTable table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>&emsp;Sr No</th>
                                        <th>Order Date</th>
                                        <th>Tracking No</th>
                                        <th>Status</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoice && invoice?.orderDetails && invoice?.orderDetails?.length > 0 ?
                                        invoice.orderDetails.map((order, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>&emsp;{i + 1}</td>
                                                    <td>{moment(order?.orderDate).format('MM/DD/YYYY')}</td>
                                                    <td>{order?.trackingno}</td>
                                                    <td>
                                                        <span>
                                                            <img src="../images/shipping.png" alt="pending" className="width18" />
                                                            <span className="statusLabel text-ship">{order?.orderStatus}</span>
                                                        </span>
                                                    </td>
                                                    <td>
                                                        <span onClick={() => {navigate('/order',{ state : { id: order?.orderId }})}}>
                                                            <span className="btn BoxImg bg-Green rounded mr-2">
                                                                <img src="../images/eye.png" alt="Img" className="width15" />
                                                            </span>
                                                        </span>
                                                    </td>
                                                </tr>
                                            )
                                        }) :
                                        (
                                            <tr key={1}>
                                                <td>&emsp;1</td>
                                                <td>{moment(invoice?.orderDate).format('MM/DD/YYYY')}</td>
                                                <td>{invoice?.trackingno}</td>
                                                <td>
                                                    <span>
                                                        <img src="../images/shipping.png" alt="pending" className="width18" />
                                                        <span className="statusLabel text-ship">{invoice?.orderStatus}</span>
                                                    </span>
                                                </td>
                                                <td>
                                                    <span onClick={() => {
                                                        navigate('/order',{
                                                            state : { id : invoice?.orderId }
                                                        })
                                                    }}>
                                                        <span className="btn BoxImg bg-Green rounded mr-2">
                                                            <img src="../images/eye.png" alt="Img" className="width15" />
                                                        </span>
                                                    </span>
                                                </td>
                                            </tr>
                                        )
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Invoice Box List</span>
                        </div>
                        <div className="ibox-content p-0">
                            <table className="table themeTable table-nowrap mb-0">
                                <thead>
                                    <tr>
                                        <th>&emsp;Sr No</th>
                                        <th>Packing Date</th>
                                        <th>Box No</th>
                                        <th>Shipment No</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {invoice && invoice?.boxDimension && invoice?.boxDimension?.length > 0 &&
                                        invoice?.boxDimension.map((d, i) => {
                                            return (
                                                <tr key={i}>
                                                    <td>&emsp;{i + 1}</td>
                                                    <td>{moment(invoice?.packageDate).format('MM/DD/YYYY')}</td>
                                                    <td>{d?.box_Num}</td>
                                                    <td>{invoice?.shipmentNo}</td>
                                                    <td>
                                                        <Link to="/packing" target="_blank">
                                                            <span className="btn BoxImg bg-Green rounded mr-2">
                                                                <img src="../images/eye.png" alt="Img" className="width15" />
                                                            </span>
                                                        </Link>
                                                    </td>
                                                </tr>
                                            )
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>

            {invoice?.uploadEvidence && <div className="modal show" id="showPaymentReceipt" data-backdrop="static" tabIndex="-1" role="dialog" >
                <div className="modal-dialog  modal-dialog-centered " role="document">
                    <div className="modal-content ">
                        <div className="modal-header" style={{ paddingTop: "0px" }}>
                            <div>
                                <p className=" m-0 mt_-15" style={{ color: "#547ab8", fontFamily: "Poppins", fontSize: "18px", fontWeight: "500" }}>View Evidence</p>
                            </div>
                            <div>
                                <button data-dismiss="modal"
                                    type="button" className="close" aria-label="Close" style={{ marginTop: "8px", marginRight: "2px" }}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        </div>
                        <div className="modal-body">
                            <div className="container-fluid">
                                <img src={invoice?.uploadEvidence} alt="Img" className='w-100 h-100 cursor-pointer' onClick={() => window.open(invoice?.uploadEvidence, '_blank')} />
                            </div>
                        </div>
                    </div>
                </div>
            </div>}
        </div>
    )
}
