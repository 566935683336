import { gql } from "@apollo/client";

export const MANUAL_ADD_EDIT_TRACK_STATUS = gql`
mutation manualAddEditTrackStatus($orderId: ID!,$oldStatus: String,$status: String!, $date: String){
    manualAddEditTrackStatus(orderId:$orderId,oldStatus:$oldStatus,status:$status, date: $date){
      id
      customerId
      customerInfo{
        firstname
        lastname
        email
        pmb_no
        userType
      }
      status
      trackStatus
    }
  }
`;

export const DELETE_TRACK_STATUS = gql`
mutation deleteTrackStatus($orderId: ID!,$oldStatus: String!){
  deleteTrackStatus(orderId:$orderId,oldStatus:$oldStatus){
    id
    customerId
    customerInfo{
      firstname
      lastname
    }
    trackingno
    trackStatus
  }
}`