import { useMutation } from '@apollo/client'
import React from 'react'
import { INSERT_UPDATE_FAQs } from '../../../../graphql/mutation/FAQsMutation'
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import Loader from '../../../components/Loader/Loader';

export const InsertUpdateFaq = ({ faq, setFaq ,refetch }) => {
    const [insert_update_faq, { loading }] = useMutation(INSERT_UPDATE_FAQs);

    const TextChangeHandler = (e) => {
        setFaq(prevFaq => ({ ...prevFaq, faqData: { ...faq?.faqData, [e.target.name]: e.target.value } }))
    }
    const formOnSubmitFAQs = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            const { faqData, openModal, id, Old_Data } = faq;
            let input = {};
            let old_value = {};
            let log = {};
            if (openModal === "Edit") {
                for (var obj in Old_Data) {
                    if (Old_Data[obj] !== faqData[obj]) {
                        old_value[obj] = Old_Data[obj]
                        input[obj] = faqData[obj]
                    }
                }
                input.id = id
            } else {
                input = { ...faqData }
            }
            log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'faqs',
                actionName: 'insertUpdateFaqs',
                oldValue: openModal === "Edit" ? JSON.stringify(old_value) : ""
            }
            insert_update_faq({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                setFaq(prevFaq => ({ ...prevFaq, faqData: {} }))
                refetch();
                form.reset();
                $('#FAQsModal').modal('hide');
                if (openModal === "Edit") {
                    toast.success("FAQ updated successfully!", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("FAQ added successfully!", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    }
    return (
        <div className="modal-content">
            <div className="modal-header" style={{ paddingTop: "0px" }}>
                <div>
                    <p className="mainTitle m-0 mt_-15">{faq?.openModal} FAQs</p>
                    <span className="borderBottomOfTitle "></span>
                </div>
                <div>
                    <button data-dismiss="modal"
                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
            </div>
            <div className="modal-body">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12 p-0">
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>{faq?.openModal} FAQs</span>
                                </div>
                                {loading && <Loader />}
                                <div className="ibox-content">
                                    <form id="frm_Faq" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitFAQs(e)}>
                                        <div className="form-row">
                                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Question<span className="text-danger">*</span></label>
                                                <input type="text" className="form-control ffPoppins" name="question" value={faq.faqData.question} onChange={e => TextChangeHandler(e)} required />
                                            </div>
                                            <div className="form-group col-lg-12 col-md-12 col-sm-12 col-xs-12 ">
                                                <label className="frm-label">Answer<span className="text-danger">*</span></label>
                                                <textarea className="form-control ffPoppins" name="answer" id="Answer" value={faq.faqData.answer} rows="5" onChange={e => TextChangeHandler(e)} required />
                                            </div>

                                            <div className="form-group col-xs-12">
                                                <div className="mt-3">
                                                    <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                    <button type="reset" className="btn btn-danger btn-text mr-2  bg-resetRed" data-dismiss="modal" >Cancel</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
