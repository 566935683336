import { useMutation } from '@apollo/client'
import React from 'react'
import { INSERTUPDATE_PRODUCT } from '../../../../graphql/mutation/CategoryMutation'
import Loader from '../../../components/Loader/Loader';
import { getS3SignUrl } from '../../../../graphql/query/TicketQuery';
import { BASE_URL } from '../../../../config';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import Categories from '../../../components/DropdownSelect/CategorySelect';
import SubCategories from '../../../components/DropdownSelect/SubCategorySelect';

export const InsertUpdateProduct = ({ product, setProduct, refetch }) => {
    const [insertUpdateProduct] = useMutation(INSERTUPDATE_PRODUCT);
    const [getPreSignedUrl] = useMutation(getS3SignUrl)

    const formOnSubmitProduct = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        const { ProductData, Old_Data, openModal, id } = product;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            let input = {};
            let old_value = {};
            let log = {};
            if (openModal === "Edit") {
                for (var obj in Old_Data) {
                    if (ProductData[obj] !== Old_Data[obj]) {
                        old_value[obj] = Old_Data[obj]
                        input[obj] = ProductData[obj]
                    }
                }
                input.id = id;
            }
            if (openModal === "Add New") {
                if (!ProductData.desc || !ProductData.categoryId || !ProductData.subCategoryId) {
                    toast.error('Please fill the required fields...', {
                        autoClose: 2000
                    }) 
                    return false;
                } else {
                    input = { ...ProductData };
                }
                
            }
            log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'products',
                actionName: "insertUpdateProduct",
                oldValue: openModal === "Edit" ? JSON.stringify(input) : ""
            }
            insertUpdateProduct({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                refetch();
                form.classList.remove('was-validated');
                form.reset();
                setProduct(preData => ({
                    ...preData, ProductData: {},
                    temp_categoryId: null,
                    temp_subCategoryId: null
                }))
                $('#ProductModal').modal('hide');
                if (openModal === "Edit") {
                    toast.success("Product updated successfully!", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("Product added successfully!", {
                        autoClose: 2000
                    })
                }
            })
            return true;
        }
    }

    const handelChangeCategory = (id) => {
        setProduct({
            ...product, temp_categoryId: id,
            ProductData: {
                ...product.ProductData,
                categoryId: id
            }
        });

    }

    const TextChangeHandler = (e) => {
        setProduct({
            ...product,
            ProductData: {
                ...product.ProductData,
                [e.target.name]: e.target.value
            }
        });
    }
    const ChangePromotional = (e) => {
        setProduct({
            ...product,
            ProductData: {
                ...product.ProductData,
                isPromotional: e.target.checked
            }
        });
    }
    const handelChangeSubCategory = (id) => {
        setProduct({
            ...product, temp_subCategoryId: id,
            ProductData: {
                ...product.ProductData,
                subCategoryId: id
            }
        });

    }

    const ChangeSubCategoryIcon = (event) => {
        const { target: { validity, files: [file], } } = event;
        if (validity.valid) {
            const fileExtension = file?.type.split("/")[1];
            const key = `ChangeSubCategoryIcon_${+new Date()}.${fileExtension}`;
            setProduct(preData => ({
                ...preData,
                isLoading: true
            }))
            getPreSignedUrl({ variables: { type: key } }).then((data) => {
                if (data?.data?.getPreSignedUrl) {
                    fetch(data?.data?.getPreSignedUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": file?.type,
                        },
                        body: file,
                    }).then((response) => {
                        if (response?.ok && response?.status === 200) {
                            setProduct(preData => ({
                                ...preData,
                                isLoading: false,
                                ProductData: {
                                    ...preData.ProductData,
                                    icon: `${BASE_URL}/${key}`
                                }
                            }))
                        } else {
                            setProduct(preData => ({ ...preData, isLoading: false }))
                        }
                    }).catch((errr) => {
                        setProduct(preData => ({ ...preData, isLoading: false }));
                    });
                } else {
                    setProduct(preData => ({ ...preData, isLoading: false }));
                }
            }).catch((err) => {
                setProduct(preData => ({ ...preData, isLoading: false }));
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
    }

    const { ProductData, openModal, temp_categoryId, temp_subCategoryId, isLoading } = product;
    return (
        <div className="ibox">
            {(isLoading) && <Loader />}
            <div className="ibox-title">
                <span>{openModal} Product</span>
            </div>
            <div className="ibox-content">
                <form id="frm_Product" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitProduct(e)}>
                    <div className="form-row">
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Select Category<span className="text-danger">*</span></label>
                            <Categories categoryId={temp_categoryId} getCategoryId={(id) => handelChangeCategory(id)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Select Sub Category<span className="text-danger">*</span></label>
                            <SubCategories categoryId={temp_categoryId} subCategoryId={temp_subCategoryId} getSubCategoryId={(id) => handelChangeSubCategory(id)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Product Name<span className="text-danger">*</span></label>
                            <input type="text" className="form-control ffPoppins" name="name" value={ProductData && ProductData.name} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Price<span className="text-danger">*</span></label>
                            <input type="number" className="form-control ffPoppins" name="price" value={ProductData && ProductData.price} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Quantity<span className="text-danger">*</span></label>
                            <input type="number" className="form-control ffPoppins" name="qty" value={ProductData && ProductData.qty} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Description<span className="text-danger">*</span></label>
                            <textarea rows="1" className="form-control ffPoppins" name="desc" value={ProductData && ProductData?.desc} onChange={(e) => TextChangeHandler(e)}></textarea>
                        </div>
                        <div className="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Product Icon<span className="text-danger">*</span></label><br />
                            <span className="btn btn-default btn-file btn-sm btn-danger ffPoppins mt-1">
                                <i className="fa fa-upload"></i> Choose a File <input type="file" onChange={(e) => ChangeSubCategoryIcon(e)} required={openModal === "Edit" ? false : true} />
                            </span>
                            {
                                ProductData.icon
                                    ? <div>
                                        <label className="frm-label">Upload Category Icon</label><br />
                                        <div className="mt-3">
                                            <img src={ProductData.icon} alt="missImg" className="width70 img-thumbnail" />
                                        </div>
                                    </div>
                                    : <div className="mt-3">
                                        <img src="../images/noImage.png" alt="img" className="width70 img-thumbnail" />
                                    </div>
                            }
                        </div>
                        <div className="form-group col-lg-3 col-md-3 col-sm-12 col-xs-12">
                            <input type="checkbox" name="isPromotional" id="isPromotional" checked={ProductData && ProductData.isPromotional} onChange={(e) => ChangePromotional(e)} />
                            <label htmlFor="isPromotional" className="frm-label chkAll">is Promotional</label>
                        </div>
                    </div>
                    <div className="form-group">
                        <div className="mt-3">
                            <button type="submit" className="btn btn-primary btn-text mr-3 border-0 bg-searchBlue" >Save</button>
                            <button type="reset" className="btn btn-danger btn-text bg-resetRed" data-dismiss="modal">Cancel</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    )
}
