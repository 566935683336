import React, { useEffect, useState } from 'react'
import GET_GENERAL_SETTINGS from '../../../../graphql/query/GeneralSettingQuery';
import { AccessDenied } from '../../../components/AccessDenied';
import { useMutation, useQuery } from '@apollo/client';
import { GENERAL_SETTINGS, Migration } from '../../../../graphql/mutation/GeneralSettingMutation';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../components/Loader/Loader';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';


export const GeneralSetting = () => {
    const user = JSON.parse(localStorage.getItem('loggedUser'));
    const [verifiedUserCharge, setVerifiedUserCharge] = useState(0);
    const [orderInsurancePercentage, setOrderInsurancePercentage] = useState(0.0);
    const [paginationLimit, setPaginationLimit] = useState(10);
    const [appVersionAndroid, setAppVersionAndroid] = useState(0.1);
    const [appVersionIos, setAppVersionIos] = useState(0.1);
    const [amcWhouseDimensiopnCalc, setAmcWhouseDimensiopnCalc] = useState(0);
    const [generalData, setGeneralData] = useState({});
    const [oldSettings, setOldSettings] = useState({});
    const navigate =  useNavigate();
    
    const { data , refetch } = useQuery(GET_GENERAL_SETTINGS, {
        fetchPolicy: 'cache-and-network',
    });
    const [generalSettings, { loading: generalSettingLoading }] = useMutation(GENERAL_SETTINGS);
    const [startMigration] = useMutation(Migration);

    useEffect(() => {
        if (data && data.getGeneralSettings) {
          const { getGeneralSettings } = data;
          const formData = { ...getGeneralSettings };
          delete formData.createdBy;
          delete formData.updatedBy;
          delete formData.createdAt;
          delete formData.updatedAt;
          delete formData.__typename;
          setGeneralData(formData);
          setOldSettings(formData);
        }
      }, [data]); 

    const fromOnSubmintGeneralSettings = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity() || !generalData.orderReceiptAuth || !generalData.terms || !generalData.prohibitedList) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
        } else {
            let input = { ...generalData }
            input.verifiedUserCharge = parseFloat(generalData.verifiedUserCharge);
            input.orderInsurancePercentage = parseFloat(generalData.orderInsurancePercentage);
            input.paginationLimit = parseFloat(generalData.paginationLimit);
            input.appVersionAndroid = parseFloat(generalData.appVersionAndroid);
            input.appVersionIos = parseFloat(generalData.appVersionIos);
            input.amcWhouseDimensiopnCalc = parseInt(generalData.amcWhouseDimensiopnCalc)
            let newInput = {}, oldInput = {}
            if (generalData.id) {
                input['id'] = generalData.id
                newInput['id'] = generalData.id
            }
            if (Object.keys(oldSettings).length > 0) {
                for (var key in oldSettings) {
                    if (oldSettings[key] !== generalData[key]) {
                        newInput[key] = input[key]
                        oldInput[key] = oldSettings[key]
                    }
                }
            } else {
                newInput = input;
            }
            const log = {
                action: generalData.id ? 'UPDATE' : 'INSERT',
                actionOn: 'generalsettings',
                actionName: 'insertUpdateGeneralSettings',
                oldValue: generalData.id ? JSON.stringify(oldInput) : ""
            }

            if (Object.keys(oldSettings).length > 0 && Object.keys(oldInput).length === 0) {
                toast.error(`no changes found!`, {
                    autoClose: 2000
                })
            } else {
                generalSettings({ variables: { "input": newInput, "log": log } }).then(async ({ data }) => {
                    if (data?.insertUpdateGeneralSettings) {
                        localStorage.removeItem('generalSettings');
                        localStorage.setItem('generalSettings', JSON.stringify(data.insertUpdateGeneralSettings));
                    }
                    toast.success(`General settings ${generalData.id ? 'updated' : 'inserted'} successfully!`, {
                        autoClose: 2000
                    })
                }).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                })
            }
        }
    };
    const TextChangeHandler = (e) => {
        setGeneralData({
            ...generalData,
            [e.target.name]: e.target.value
        });
    };
    const changeHandleProhibitedList = (e ,editor) => {
        setGeneralData({
            ...generalData,
            prohibitedList: editor.getData()
        })
    }
    const changeHandleTerms = (e,editor) => {
        setGeneralData({
            ...generalData,
            terms: editor.getData()
        })
    }
    const changeHandleOrderReceipt = (e ,editor) => {
        setGeneralData({
            ...generalData,
            orderReceiptAuth: editor.getData()
        })
    }
    const cancelButtonHandler = (e) => {
        e.preventDefault();
        setGeneralData({}); setOldSettings({})
        navigate('/dashboard');
    }
    const startMigrationFlow = (e) => {
        e.preventDefault();
        startMigration({}).then(({data}) => {
         console.log('data :: ', data);
          toast.success(`Migrate successfully!`, {
            autoClose: 2000
          })
        }).catch((err) => {
          toast.error(err.message, {
            autoClose: 2000
          })
        })
      }


    if (user?.roles === 'standard') return <AccessDenied />
    else
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="mb-20">
                        <p className="mainTitle m-0 mt_-15">General Settings</p>
                        <span className="borderBottomOfTitle"></span>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 p-0">
                        <form className="needs-validation" noValidate="noValidate" onSubmit={(e) => { fromOnSubmintGeneralSettings(e) }} >
                            {generalSettingLoading && <Loader />}
                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>App Settings</span>
                                </div>
                                <div className="ibox-content">
                                    <div className="form-row">
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Verified User Charges<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" name="verifiedUserCharge" value={generalData?.verifiedUserCharge} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Order Insurance Percentage<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" name="orderInsurancePercentage" value={generalData?.orderInsurancePercentage} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Pagination Limit<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" name="paginationLimit" value={generalData?.paginationLimit} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">App Version Android<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" name="appVersionAndroid" value={generalData?.appVersionAndroid} onChange={(e) => TextChangeHandler(e)} required />

                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">App Version IOS<span className="text-danger">*</span></label>
                                            <input type="number" className="form-control" name="appVersionIos" value={generalData?.appVersionIos} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>Head Office Address</span>
                                </div>
                                <div className="ibox-content">
                                    <div className="form-row">
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Address Line 1<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="headeOffAddress1" value={generalData?.headeOffAddress1} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Address Line 2</label>
                                            <input type="text" className="form-control ffPoppins" name="headeOffAddress2" value={generalData?.headeOffAddress2} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Country<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="headeOffCountry" value={generalData?.headeOffCountry} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">State<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="headeOffState" value={generalData?.headeOffState} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">City<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="headeOffCity" value={generalData?.headeOffCity} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">ZIP/Postal Code</label>
                                            <input type="text" className="form-control ffPoppins" name="headeOffzipcode" value={generalData?.headeOffzipcode} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Customer Service Email<span className="text-danger">*</span></label>
                                            <input type="email" className="form-control ffPoppins" name="headeOffCustomerServiceEmail" value={generalData?.headeOffCustomerServiceEmail} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Customer Service Phone<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="headeOffCustomerServicePhone" value={generalData?.headeOffCustomerServicePhone} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Web<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="headeOffWeb" value={generalData?.headeOffWeb} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Working Hour</label>
                                            <input type="text" className="form-control ffPoppins" name="headeOffWorkingHrs" value={generalData?.headeOffWorkingHrs} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>American Warehouse Address</span>
                                </div>
                                <div className="ibox-content">
                                    <div className="form-row">
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Address Line 1<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="amcWhouseAddress1" value={generalData?.amcWhouseAddress1} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Address Line 2</label>
                                            <input type="text" className="form-control ffPoppins" name="amcWhouseAddress2" value={generalData?.amcWhouseAddress2} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Country<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="amcWhouseCountry" value={generalData?.amcWhouseCountry} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">State<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="amcWhouseState" value={generalData?.amcWhouseState} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">City<span className="text-danger">*</span></label>
                                            <input type="text" className="form-control ffPoppins" name="amcWhouseCity" value={generalData?.amcWhouseCity} onChange={(e) => TextChangeHandler(e)} required />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">ZIP/Postal Code</label>
                                            <input type="text" className="form-control ffPoppins" name="amcWhousezipcode" value={generalData?.amcWhousezipcode} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Phone</label>
                                            <input type="text" className="form-control ffPoppins" name="amcWhousePhone" value={generalData?.amcWhousePhone} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Dimension Calc</label>
                                            <input type="number" className="form-control ffPoppins" name="amcWhouseDimensiopnCalc" value={generalData?.amcWhouseDimensiopnCalc} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Working Hour</label>
                                            <input type="text" className="form-control ffPoppins" name="amcWhouseWorkingHrs" value={generalData?.amcWhouseWorkingHrs} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>Lagos Office Address</span>
                                </div>
                                <div className="ibox-content">
                                    <div className="form-row">
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Address Line 1</label>
                                            <input type="text" className="form-control ffPoppins" name="lagosOffAddress1" value={generalData.lagosOffAddress1} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Address Line 2</label>
                                            <input type="text" className="form-control ffPoppins" name="lagosOffAddress2" value={generalData.lagosOffAddress2} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Country</label>
                                            <input type="text" className="form-control ffPoppins" name="lagosOffCountry" value={generalData.lagosOffCountry} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">State</label>
                                            <input type="text" className="form-control ffPoppins" name="lagosOffState" value={generalData.lagosOffState} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">City</label>
                                            <input type="text" className="form-control ffPoppins" name="lagosOffCity" value={generalData.lagosOffCity} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">ZIP/Postal Code</label>
                                            <input type="text" className="form-control ffPoppins" name="lagosOffzipcode" value={generalData.lagosOffzipcode} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Phone</label>
                                            <input type="text" className="form-control ffPoppins" name="lagosOffPhone" value={generalData.lagosOffPhone} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Working Hour</label>
                                            <input type="text" className="form-control ffPoppins" name="lagosOffWorkingHrs" value={generalData.lagosOffWorkingHrs} onChange={(e) => TextChangeHandler(e)} />
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>Online Order Receipe Autorization<span className="text-danger">*</span></span>
                                </div>
                                <div className="ibox-content">
                                    {generalData?.orderReceiptAuth !== undefined &&
                                        <CKEditor
                                            editor={ClassicEditor} 
                                            onChange={ changeHandleOrderReceipt}
                                            data={generalData?.orderReceiptAuth}
                                        />
                                    }
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>Term And Condition<span className="text-danger">*</span></span>
                                </div>
                                <div className="ibox-content">
                                    {generalData?.terms !== undefined &&
                                        <CKEditor
                                            editor={ClassicEditor} 
                                            onChange={changeHandleTerms}
                                            data={generalData?.terms}
                                        />
                                    }
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>Prohibited List<span className="text-danger">*</span></span>
                                </div>
                                <div className="ibox-content">
                                    {generalData?.prohibitedList !== undefined &&
                                        <CKEditor
                                            editor={ClassicEditor} 
                                            onChange={changeHandleProhibitedList}
                                            data={generalData?.prohibitedList}
                                        />
                                    }
                                </div>
                            </div>

                            <div className="ibox">
                                <div className="ibox-title">
                                    <span>Migration</span>
                                </div>
                                <div className="ibox-content">
                                 <button className='btn btn-info btn-text mt-3 mr-4 border-0 bg-searchBlue' type='button' onClick={(e) => startMigrationFlow(e)} > Start Migration </button>
                                </div>
                            </div>
                            <div className="ibox">
                                <div className="ibox-content">
                                    <button type="submit" className="btn btn-primary btn-text mt-3 mr-4 border-0 bg-searchBlue">Save</button>
                                    <button type="button" className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed" data-dismiss="modal" onClick={(e) => cancelButtonHandler(e)}>Cancel</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div >
        )
}
