import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import { GET_ALL_NONEXPIREDSHIPMENT } from '../../../graphql/query/shipmentQuery';
import { delayedFunction } from './utils';

const ShipmentSelectList = ({ selectedShipment, getShipId, setSearchShipment, getShipmentInfo, limit, page }) => {
    const [shipments, setShipments] = useState([]);
    const [value, setValue] = useState(null);
    const [colorError, setColorError] = useState(undefined);
    const [searchKey, setSearchKey] = useState('');

    const { data } = useQuery(GET_ALL_NONEXPIREDSHIPMENT, {
        variables: {
            page: page, // replace with your default values
            limit: limit, // replace with your default values
            filter: searchKey,
        },
        fetchPolicy: 'cache-and-network',
    });

    useEffect(() => {
        if (data) {
            if (data.getNonExpiredShipment && data.getNonExpiredShipment.length) {
                const ships = data.getNonExpiredShipment.map((d) => ({
                    value: d.id,
                    label: `${d.shipmentNo}`,
                    shippingDate: d.shippingDate,
                    estimatedDate: d.estimatedDate,
                }));
                setShipments(ships);
                setValue(null);
                // setDefaultShipment(selectedShipment);
            } else {
                setShipments([]);
                setValue(null);
            }
        }
    }, [data, selectedShipment]);

    const setDefaultShipment = () => {
        if (selectedShipment) {
            setValue(selectedShipment?.data || selectedShipment);
        }
    };
    useEffect(() => {
    setDefaultShipment(selectedShipment);
    },[shipments,getShipId ,selectedShipment])

    const shipmentHandleChange = (selectedValue) => {
        setColorError(true);
        setValue(selectedValue);

        if (selectedValue === null) {
            setSearchShipment('');
            getShipmentInfo(null);
            setValue(null);
            setSearchKey('');
        } else {
            const shipmentInfo = {
                value: selectedValue?.value,
                label: selectedValue?.label,
            };
            setValue(shipmentInfo);

            if (getShipmentInfo) {
                getShipmentInfo(shipmentInfo);
            }
        }

        getShipId(selectedValue);
    };

    return (
        <Select
            className="basic-single ffPoppins"
            options={shipments}
            value={value}
            onInputChange={(inputValue) => {
                setSearchKey(inputValue);
                delayedFunction(setSearchShipment(inputValue));
            }}
            onChange={(selectedValue) => shipmentHandleChange(selectedValue)}
            styles={{
                control: (provided, state) =>
                    !colorError && colorError !== undefined
                        ? {
                            ...provided,
                            boxShadow: '0 0 0 1px #dc3545 !important',
                            border: '1px #dc3545 !important',
                        }
                        : provided,
            }}
        />
    );
};

export default ShipmentSelectList;
