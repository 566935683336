import { toast } from "react-toastify";
import { BASE_URL } from "../../../config";
import { useMutation } from "@apollo/client";
import { getS3SignUrl } from "../../../graphql/query/TicketQuery";

export const useFileUpload = () => {
    const [getSignUrl] = useMutation(getS3SignUrl);

    const handleFileUpload = async(event, keyPrefix, setStateCallback, setIsLoading) => {
        const { target: { validity, files: [file], } } = event;
        if (validity.valid) {
            const fileExtension = file?.type.split("/")[1];
            const key = `${keyPrefix}_${+new Date()}.${fileExtension}`;
            setIsLoading(true);
            try {
                const { data } = await getSignUrl({ variables: { type: key } });
                if (data?.getPreSignedUrl) {
                    const response = await fetch(data.getPreSignedUrl, {
                        method: "PUT",
                        headers: {
                            "Content-Type": file?.type,
                        },
                        body: file,
                    });
                    if (response.ok && response.status === 200) {
                        setStateCallback(`${BASE_URL}/${key}`);
                        setIsLoading(false);
                    } else {
                        setIsLoading(false);
                    }
                } else {
                    setIsLoading(false);
                }
            } catch (error) {
                setIsLoading(false);
                toast.error(error.message || "An error occurred", {
                    autoClose: 2000
                });
            }
        }
    };

    return handleFileUpload;
};
