import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";
import { useApolloClient, useQuery } from '@apollo/client';
import { toast } from 'react-toastify';
import { GET_ORDER_FOR_SEARCH_BOX } from '../../graphql/query/Header';

const Header = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [dropdownVisible, setDropdownVisible] = useState(false);
    const navigate = useNavigate();
    const client = useApolloClient();
    const user = JSON.parse(localStorage.getItem('loggedUser'));

    const signOut = () => {
        localStorage.clear();
        sessionStorage.clear();
        client.resetStore();
        toast.success("user logged out successfully!", {
            autoClose: 2000
        });
        navigate("/login");
    };

    const goToOrderDetails = (orderId, data) => {
        if (data.status === 'pending') {
            data.status = "received";
        }
        navigate('/order', { state: { id: orderId, orderData: data } });

    };

    const handleInputChange = (event) => {
        const value = event.target.value;
        setSearchTerm(value);
    };

    const handleInputFocus = () => {
        setDropdownVisible(true);
    };

    const handleInputBlur = () => {
        setTimeout(() => setDropdownVisible(false), 200);
    };

    const { data } = useQuery(GET_ORDER_FOR_SEARCH_BOX, {
        variables: { page: 1, limit: 10, filter: searchTerm },
        fetchPolicy: 'no-cache'
    });

    const menuItems = [
        { to: "/general-settings", icon: "gear.png", label: "General Setting" },
        { to: "/users", icon: "users.png", label: "Users" },
        { to: "/bins", icon: "bins.png", label: "Bins" },
        { to: "/rates", icon: "rates.png", label: "Rates" },
        { to: "/online-stores", icon: "onlinestores.png", label: "Online Stores" },
        { to: "/faqs", icon: "faqs.png", label: "FAQs" },
        { to: "/country", icon: "country.png", label: "Country" },
        { to: "/state", icon: "state.png", label: "States" },
        { to: "/closest-city", icon: "closestcity.png", label: "Closest City" },
        { to: "/destination-warehouse", icon: "destinationwarehouse.png", label: "Destination Warehouses" },
        { to: "/invoice-list", icon: "invoice.png", label: "Invoice" },
        { to: "/box-dimension", icon: "boxdimension.png", label: "Box Dimension" },
        { to: "/promotionalcount", icon: "promotional.png", label: "Promotional Coupon" },
        { to: "/how-its-work", icon: "closestcity.png", label: "How Its Work" }
    ];

    // // if (loading) return <p>Loading...</p>;
    // if (error) return <p>Error! ${error.message}</p>;

    return (
        <div className="mainHeader">
            <div className="d-flex m-10">
                <Link to="/">
                    <div className="Logo">
                        <img src="../images/smsLogo.png" alt="Logo" className="LogoImg" />
                    </div>
                </Link>
                <div className="SearchBox">
                    <div style={{ width: '450px' }}>
                        <input
                            type="text"
                            value={searchTerm}
                            onChange={handleInputChange}
                            onFocus={handleInputFocus}
                            onBlur={handleInputBlur}
                            className="form-control border-0 searchInput"
                            placeholder="Search..."
                        />
                        {dropdownVisible && data?.getOrderForSearchBox?.length ? (
                            <div className="open-menu search-down">
                                {data.getOrderForSearchBox.map((item) => {
                                    const { customerInfo, trackingno, id } = item;
                                    let searchKey;
                                    if (item.customerInfo.firstname.match(new RegExp(searchTerm, 'ig'))) {
                                        searchKey = 'firstname';
                                    } else if (item.customerInfo.lastname.match(new RegExp(searchTerm, 'ig'))) {
                                        searchKey = 'lastname';
                                    } else if (item.customerInfo.pmb_no.match(new RegExp(searchTerm, 'ig'))) {
                                        searchKey = 'pmb_no';
                                    } else if (item.trackingno.match(new RegExp(searchTerm, 'ig'))) {
                                        searchKey = 'trackingno';
                                    } else if (item.customerInfo.phone.match(new RegExp(searchTerm, 'ig'))) {
                                        searchKey = 'phone';
                                    } else if (item.customerInfo.email.match(new RegExp(searchTerm, 'ig'))) {
                                        searchKey = 'email';
                                    }
                                    return (
                                        <div key={id} onClick={() => goToOrderDetails(id, item)} className="menu-data">
                                            {searchKey !== "" && (
                                                <>
                                                    {searchKey === "firstname" && (
                                                        <>
                                                            <b> {customerInfo.firstname} </b>
                                                            {customerInfo.lastname + ' | ' + trackingno + ' | ' + customerInfo.pmb_no + ' | ' + customerInfo.phone + ' | ' + customerInfo.email}
                                                        </>
                                                    )}
                                                    {searchKey === "lastname" && (
                                                        <>
                                                            {customerInfo.firstname + ' '}
                                                            <b> {customerInfo.lastname} </b>
                                                            {' | ' + trackingno + ' | ' + customerInfo.pmb_no + ' | ' + customerInfo.phone + ' | ' + customerInfo.email}
                                                        </>
                                                    )}
                                                    {searchKey === "trackingno" && (
                                                        <>
                                                            {customerInfo.firstname + ' ' + customerInfo.lastname + ' | '}
                                                            <b> {trackingno} </b>
                                                            {' | ' + customerInfo.pmb_no + ' | ' + customerInfo.phone + ' | ' + customerInfo.email}
                                                        </>
                                                    )}
                                                    {searchKey === "pmb_no" && (
                                                        <>
                                                            {customerInfo.firstname + ' ' + customerInfo.lastname + ' | ' + trackingno + ' | '}
                                                            <b> {customerInfo.pmb_no} </b>
                                                            {' | ' + customerInfo.phone + ' | ' + customerInfo.email}
                                                        </>
                                                    )}
                                                    {searchKey === "phone" && (
                                                        <>
                                                            {customerInfo.firstname + ' ' + customerInfo.lastname + ' | ' + trackingno + ' | ' + customerInfo.pmb_no + ' | '}
                                                            <b> {customerInfo.phone} </b>
                                                            {' | ' + customerInfo.email}
                                                        </>
                                                    )}
                                                    {searchKey === "email" && (
                                                        <>
                                                            {customerInfo.firstname + ' ' + customerInfo.lastname + ' | ' + trackingno + ' | ' + customerInfo.pmb_no + ' | ' + customerInfo.phone + ' | '}
                                                            <b>{customerInfo.email}</b>
                                                        </>
                                                    )}
                                                </>
                                            )}
                                        </div>
                                    );
                                })}
                            </div>
                        ) : null}
                    </div>
                    <div className="searchIcon float-right">
                        <i className="fa fa-search" aria-hidden="true"></i>
                    </div>
                </div>
                <div className="m-auto"></div>
                <div className="dropdown">
                    <img src="../images/user.png" alt="Profile" className="dropdown-toggle rounded-circle" data-toggle="dropdown" />
                    <div className="dropdown-menu myMenu" role="menu">
                        {(user?.roles === 'admin' || user?.roles === 'special') && (
                            menuItems.map((item, index) => (
                                <Link to={item.to} key={index} className="item-list">
                                    <span className='imgCircle'> <img src={`../images/${item.icon}`} className="width10 " alt="missImg" /></span>
                                    <span>{item.label}</span>
                                </Link>
                            ))
                        )}
                        <Link to="/login" className="item-list border-bottom-0 " onClick={signOut}>
                            <span className='imgCircle'> <img src="../images/signOut.png" className="width10 " alt="missImg" /></span>
                            <span>Logout</span>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Header;