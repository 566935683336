import React from 'react'
import confirmDeletion from '../../../components/Common/BootBox';
import { useMutation } from '@apollo/client';
import { DELETE_PRODUCT } from '../../../../graphql/mutation/CategoryMutation';
import * as $ from 'jquery';
import { toast } from 'react-toastify';

export const GetProduct = ({ product, setProduct, productData ,refetch }) => {
    const [deleteProduct] = useMutation(DELETE_PRODUCT);
    const sortData = (sortKey) => {
        const newSortType = (product.sortType === 1) ? -1 : 1;
        setProduct((prevData) => ({ ...prevData, sortKey: sortKey, sortType: newSortType }));
    }

    const editProductCategory = (e, oldProductData) => {
        e.preventDefault();
        setProduct((prevData) => ({
            ...prevData,
            id: oldProductData.id,
            temp_categoryId: oldProductData.categoryId.id,
            temp_subCategoryId: oldProductData.subCategoryId.id,
            ProductData: oldProductData,
            Old_Data: oldProductData,
            openModal: 'Edit'
        }));
        $('#frm_Product').removeClass('was-validated');
        $('#ProductModal').modal('show');

    }
    const deleteProductDetails = (e, productId) => {
        e.preventDefault();
        const deleteProductData = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'products',
                actionName: 'deleteProduct',
                oldValue: ""
              }
    
              deleteProduct({ variables: { "id": productId, "log": log } }).then(async ({ data }) => {
                $('#ProductModal').modal('hide');
                refetch();
                toast.success("Product deleted successfully!", {
                  autoClose: 2000
                });
              }).catch((err) => {
                toast.error(err.message, {
                  autoClose: 2000
                })
              }) 
        }
        confirmDeletion("Delete PRODUCT" ,"Are you sure want to delete this record ?", deleteProductData);
      }
    const { sortKey, sortType } = product
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0 tbl-sort">
                <thead>
                    <tr>
                        <th className="text-center">No.</th>
                        <th>Icon</th>
                        <th>Category</th>
                        <th>Sub Category</th>
                        <th
                            className={`sortColumn ${(sortKey === 'name') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('sub-category')}>Product Name</th>
                        <th
                            className={`sortColumn ${(sortKey === 'price') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('price')}>Price</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        productData && productData?.data && productData?.data?.length > 0
                            ? productData?.data && productData?.data.map((d, i) => {
                                return (
                                    <tr key={d?.id}>
                                        <td className=" text-center">{i + 1}</td>
                                        <td>
                                            <img src={d.icon} alt="img" className="width55 img-thumbnail" />
                                        </td>
                                        <td>{d.categoryId && d.categoryId.name}</td>
                                        <td>{d.subCategoryId && d.subCategoryId.name}</td>
                                        <td>{d.name}</td>
                                        <td>{d.price}</td>
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => { editProductCategory(e, d); }}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => { deleteProductDetails(e, d.id)}}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ textAlign: 'center' }} colSpan={7} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
