import { gql } from "@apollo/client";

export const GET_STATE = gql`
  query getStates($page: Int, $limit: Int, $filter: String, $sort: sortState, $filterCountryId: String){
    getStates(page: $page,limit: $limit, filter: $filter,sort: $sort, filterCountryId: $filterCountryId){
      count
      data {
        id
        name
        countryId {
          id
          name
          code
        }
        isActive
        createdBy
        createdAt
        updatedBy
        updatedAt
      }
    }
  }
`;

export const GET_ALL_STATE = gql`
  query getAllStateCountryWiseList($countryId:ID){
    getAllStateCountryWiseList(countryId:$countryId){
      id
      name
      countryId {
        id
        name
        code
      }
      isActive
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
  }
`;