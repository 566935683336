import { gql } from "@apollo/client";

export const GET_PROMOTIONS = gql`
query getPromotions($page: Int, $limit: Int, $filter: String,$sort:promotionSort){
  getPromotions(page: $page,limit: $limit, filter: $filter,sort:$sort){
    count
    data {
      id
      code
      promoCodeType
      percentage
      minOrderAmount
      maxCashBackAmount
      maxUsage
      startDate
      endDate
      fixedAmount
      note
      isActive
      createdBy
      createdAt
      updatedBy
      updatedAt
    }
  }
}
`;

export default GET_PROMOTIONS;