import { useMutation } from '@apollo/client'
import React from 'react'
import { INSERT_UPDATE_WAREHOUSE_USER } from '../../../../../graphql/mutation/WareHouseMutation'
import Loader from '../../../../components/Loader/Loader';
import { statusType, warehouseUsersRole as userType  } from '../../../../components/Constant';
import StatesList from '../../../../components/DropdownSelect/StateSelect';
import CountryList from '../../../../components/DropdownSelect/CountrySelect';
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import Select from 'react-select';
import ClosestCity from '../../../../components/DropdownSelect/ClosestCitySelect';


export const InsertUpdateWarehouseUser = ({ warehouseUser, setWarehouseUser, refetch }) => {
    const [insertUpdateWarehouseUser, { loading }] = useMutation(INSERT_UPDATE_WAREHOUSE_USER);

    const formOnSubmitWHUser = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity() || !warehouseUser?.userTypeColorError) {
            event.stopPropagation();
            form.classList.add('was-validated');
            setWarehouseUser(prevData => ({ ...prevData, userTypeColorError: warehouseUser?.temp_userType ? true : false }))
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            const { warehouseId, WHUserData, temp_isActive, openModal, Old_Data, id } = warehouseUser;

            let input = {};
            let old_value = {};
            let log = {};
            if (openModal === "Edit") {
                for (var obj in Old_Data) {
                    if (Old_Data[obj] !== WHUserData[obj]) {
                        old_value[obj] = Old_Data[obj]
                        input[obj] = WHUserData[obj]
                    }
                }
                input.id = id
            } else {
                if (WHUserData.password !== WHUserData.con_password) {
                    toast.error('Passwords do not match.', {
                        autoClose: 2000
                    })
                    return
                }
                input = { ...WHUserData };
                input.warehouseId = warehouseId
                input.isActive = temp_isActive.value;
                delete input.con_password
                // warehouseId: warehouseId,
            }
            log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'warehouseusers',
                actionName: "insertUpdateWarehouseUser",
                oldValue: openModal === "Edit" ? JSON.stringify(old_value) : ""
            }
            insertUpdateWarehouseUser({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                form.classList.remove('was-validated');
                setWarehouseUser(prevData => ({ ...prevData, WHUserData: {} }))
                refetch();
                form.reset();
                $('#WarehouseUserModal').modal('hide');
                if (openModal === "Edit") {
                    toast.success("Destination warehouse user updated successfully", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("Destination warehouse user added successfully", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true
        }
    }

    const handleChangeRole = (userRole) => {
        setWarehouseUser(prevData => ({
            ...prevData, WHUserData: {
                ...warehouseUser.WHUserData,
                userType: userRole.value,
            },
            temp_userType: userRole,
            userTypeColorError: userRole ? true : false
        }))

    }
    const handleChangeStatus = (userStatus) => {
        setWarehouseUser(prevData => ({
            ...prevData,
            WHUserData: {
                ...warehouseUser.WHUserData,
                isActive: userStatus.value,
            },
            temp_isActive: userStatus,
        }))
    }

    const handleChangeCountryId = (id) => {
        setWarehouseUser(prevData => ({
            ...prevData,
            WHUserData: {
                ...warehouseUser?.WHUserData,
                countryId: id,
                stateId: null,
                assignClosestCity: null
            },
            temp_countryId: id,
            temp_stateId: null,
            temp_closestCityId: null,
        }))
    }
    const handleChangeStateId = (id) => {
        setWarehouseUser(prevData => ({
            ...prevData,
            WHUserData: {
                ...warehouseUser?.WHUserData,
                stateId: id,
                assignClosestCity: null
            },
            temp_stateId: id,
            temp_closestCityId: null,
        }))
    }
    const handleChangeClosestCityId = (id) => {
        setWarehouseUser(prevData => ({
            ...prevData,
            WHUserData: {
                ...warehouseUser?.WHUserData,
                assignClosestCity: id
              },
              temp_closestCityId: id
        }))
    }
    const TextChangeHandler = (e) => {
        setWarehouseUser(prevData => ({
            ...prevData,
            WHUserData: {
                ...warehouseUser.WHUserData,
                [e.target.name]: (e.target.name === "email") ? e.target.value.toLowerCase() : e.target.value
            }
        }))
    }
    const { WHUserData, temp_closestCityId, temp_countryId, temp_stateId, openModal, temp_userType, userTypeColorError, temp_isActive, } = warehouseUser;
    console.log("🚀 ~ InsertUpdateWarehouseUser ~ temp_closestCityId:::::::::::::::::::", temp_closestCityId ,temp_countryId)
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="mb-20">
                    <p className="mainTitle m-0 mt_-15">{warehouseUser?.openModal} Warehouses User</p>
                    <span className="borderBottomOfTitle"></span>
                </div>
            </div>
            <div className="row">
                {loading && <Loader />}
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>{warehouseUser?.openModal} Destination Warehouses User</span>
                        </div>
                        <div className="ibox-content">
                            <form id="frm_wareHouseUser" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitWHUser(e)} >
                                <div className="form-row">
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <label className="frm-label">First Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control ffPoppins" name="firstName" value={WHUserData.firstName} onChange={(e) => { TextChangeHandler(e) }} required />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <label className="frm-label">Last Name<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control ffPoppins" name="lastName" value={WHUserData.lastName} onChange={(e) => { TextChangeHandler(e) }} required />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <label className="frm-label">Email<span className="text-danger">*</span></label>
                                        <input type="text" disabled={openModal === "Add New" ? false : true} className="form-control ffPoppins" name="email" value={WHUserData?.email} onChange={(e) => { TextChangeHandler(e) }} required />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">Phone</label>
                                        <input type="text" className="form-control ffPoppins" name="phone" value={WHUserData.phone} onChange={(e) => { TextChangeHandler(e) }} />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">Address Line 1<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control ffPoppins" name="addLine1" value={WHUserData.addLine1} onChange={(e) => { TextChangeHandler(e) }} required />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">Address Line 2</label>
                                        <input type="text" className="form-control ffPoppins" name="addLine2" value={WHUserData.addLine2} onChange={(e) => { TextChangeHandler(e) }} required />
                                    </div>
                                    {
                                        openModal === "Add New" &&
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <label className="frm-label">Password<span className="text-danger">*</span></label>
                                            <input type="password" className="form-control ffPoppins" name="password" value={WHUserData.password} onChange={(e) => { TextChangeHandler(e) }} required />
                                        </div>
                                    }
                                    {
                                        openModal === "Add New" &&
                                        <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                            <label className="frm-label">Confirm Password<span className="text-danger">*</span></label>
                                            <input type="password" className="form-control ffPoppins" name="con_password" value={WHUserData.con_password} onChange={(e) => { TextChangeHandler(e) }} required />
                                        </div>
                                    }
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">Country<span className="text-danger">*</span></label>
                                        <CountryList countryId={temp_countryId} getCRUDCountryId={(id) => { handleChangeCountryId(id) }} />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">State </label>
                                        <StatesList countryId={temp_countryId} stateId={temp_stateId} getCRUDStateId={(id) => { handleChangeStateId(id) }} />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">City</label>
                                        <input type="text" className="form-control ffPoppins" name="city" value={WHUserData.city} onChange={(e) => { TextChangeHandler(e) }} />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">Closest City</label>
                                        <ClosestCity countryId={temp_countryId} stateId={temp_stateId} closetCityId={temp_closestCityId} getCRUDClosestCity={(id) => { handleChangeClosestCityId(id)}} />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">ZIP/Postal Code</label>
                                        <input type="text" className="form-control ffPoppins" name="zipCode" value={WHUserData.zipCode} onChange={(e) => { TextChangeHandler(e) }} />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <label className="frm-label">User Type<span className="text-danger">*</span></label>
                                        <Select
                                            className="ffPoppins"
                                            options={userType}
                                            value={temp_userType}
                                            onChange={(data) => handleChangeRole(data)}
                                            styles={{
                                                control: (provided, state) =>
                                                    !userTypeColorError && userTypeColorError !== null
                                                        ? {
                                                            ...provided,
                                                            boxShadow: "0 0 0 1px #dc3545 !important",
                                                            border: "1px #dc3545 !important"
                                                        }
                                                        : provided
                                            }} />
                                    </div>
                                    <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                        <label className="frm-label">Status<span className="text-danger">*</span></label>
                                        <Select
                                            className="ffPoppins"
                                            options={statusType}
                                            value={temp_isActive}
                                            onChange={(data) => handleChangeStatus(data)}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2 mb-2">
                                    <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                    <button type="reset" className="btn btn-danger btn-text bg-resetRed" data-dismiss="modal" >Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
