import React, { useState, useEffect } from 'react';
import { useMutation } from '@apollo/client';
import { toast } from 'react-toastify';
// import { INSERT_UPDATE_RECEIVER } from './AddressMutation';
import CountryList from '../DropdownSelect/CountrySelect';
import StatesList from '../DropdownSelect/StateSelect';
import ClosestCity from '../DropdownSelect/ClosestCitySelect';
import Loader from '../Loader/Loader';
import { INSERT_UPDATE_RECEIVER } from '../../../graphql/mutation/AddressMutation';
import * as $ from 'jquery';

const AddressAdd = (props) => {
    const [tempReceiverData, setTempReceiverData] = useState({});
    const [tempSameAsAddress, setTempSameAsAddress] = useState({});
    const [tempCountryId, setTempCountryId] = useState(null);
    const [tempStateId, setTempStateId] = useState(null);
    const [tempClosestCityId, setTempClosestCityId] = useState(null);

    const [insertUpdateReceiverMutation ,{loading}] = useMutation(INSERT_UPDATE_RECEIVER);

    useEffect(() => {
        if (props?.ReceiverData && !props?.sameAddress) {
            setTempReceiverData(props?.ReceiverData);
            setTempCountryId(props?.ReceiverData.countryId?.id || null);
            setTempStateId(props?.ReceiverData.stateId?.id || null);
            setTempClosestCityId(props?.ReceiverData.closestCityId?.id || null);
        }
        if (props?.sameAddress && props?.CustomerDetailsData) {
            const { countryId, stateId } = props?.CustomerDetailsData;
            let addressData = props?.CustomerDetailsData

            setTempCountryId(countryId?.id || null);
            setTempStateId(stateId?.id || null);
            setTempSameAsAddress({
                ...tempSameAsAddress,
                address1: addressData.address1,
                address2: addressData.address2,
                city: addressData.city,
                zipCode: addressData.zipcode,
                countryId: addressData?.countryId && addressData?.countryId?.id || null,
                stateId: addressData?.stateId && addressData?.stateId?.id || null,
            });
        }
    }, [props?.ReceiverData, props?.sameAddress, props?.CustomerDetailsData]);

    const handleChangeCountry = (id) => {
        if (props?.sameAddress) {
            setTempSameAsAddress({ ...tempSameAsAddress, countryId: id });
            setTempCountryId(id);
        } else {
            setTempReceiverData({ ...tempReceiverData, countryId: id });
            setTempCountryId(id);
        }
    };

    const hideAddressArea = () => {
        setTempReceiverData({});
        setTempSameAsAddress({});
        setTempCountryId(null);
        setTempStateId(null);
        setTempClosestCityId(null);
        $("#NewAddress").css('display', 'none');
    };

    const TextChangeHandler = (e) => {
        if (props.sameAddress) {
            setTempSameAsAddress({ ...tempSameAsAddress, [e.target.name]: e.target.value });
        } else {
            setTempReceiverData({ ...tempReceiverData, [e.target.name]: e.target.value });
        }
    };

    const handleChangeState = (id) => {
        if (props.sameAddress) {
            setTempSameAsAddress({ ...tempSameAsAddress, stateId: id });
            setTempStateId(id);
        } else {
            setTempReceiverData({ ...tempReceiverData, stateId: id });
            setTempStateId(id);
        }
    };

    const handleChangeClosestCity = (id) => {
        if (props.sameAddress) {
            setTempSameAsAddress({ ...tempSameAsAddress, closestCityId: id });
            setTempClosestCityId(id);
        } else {
            setTempReceiverData({ ...tempReceiverData, closestCityId: id });
            setTempClosestCityId(id);
        }
    };

    const formOnSubmitReceiverofCustomer1 = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            });
            return false;
        } else {
            const { CustomerUserId, openModal, ReceiverData, sameAddress } = props;
            let input = {};
            let log = {};
            let old_value = {};
            if (openModal === "Edit") {
                for (var obj in ReceiverData) {
                    if (ReceiverData[obj] !== tempReceiverData[obj]) {
                        old_value[obj] = ReceiverData[obj];
                        input[obj] = tempReceiverData[obj];
                    }
                }
                input.id = ReceiverData.id;
            } else {
                if (sameAddress) {
                    input = { ...tempSameAsAddress };
                } else {
                    input = { ...tempReceiverData };
                    if (!input.countryId) {
                        toast.error('Please select country ', {
                            autoClose: 2000
                        });
                        return false;
                    } else if (!input.stateId) {
                        toast.error('Please select state ', {
                            autoClose: 2000
                        });
                        return false;
                    }
                }
                input.customerId = CustomerUserId;
            }
            log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'receivers',
                actionName: "insertUpdateReceiver",
                oldValue: openModal === "Edit" ? JSON.stringify(input) : ""
            };
            insertUpdateReceiverMutation({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                form.reset();
                props.refetch();
                setTempReceiverData({});
                setTempCountryId(null);
                setTempStateId(null);
                setTempClosestCityId(null);
                if (sameAddress) {
                    $("#NewAddress").css('display', 'none');
                    toast.success("Receiver Address added successfully!!", {
                        autoClose: 2000
                    });
                    setTempSameAsAddress({});
                    props.updateAddressFlag();
                } else {
                    if (openModal === "Edit") {
                        $("#NewAddress").css('display', 'none');
                        toast.success("Receiver Address updated successfully!!", {
                            autoClose: 2000
                        });
                    } else {
                        $("#NewAddress").css('display', 'none');
                        // this.props.closeModal();
                        toast.success("Receiver Address added successfully!!", {
                            autoClose: 2000
                        });
                    }
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                });
            });
            return true;
        }
    };

    const formOnSubmitReceiverofCustomer = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            });
            return false;
        } else {
            if (props.isOrder && props.curdType === "Edit" && props.receiverId) {
                tempReceiverData.id = props.receiverId;
                props.updateOrderAddress(tempReceiverData);
                $("#NewAddress").css('display', 'none');
                toast.success("Receiver Address updated successfully!!", {
                    autoClose: 2000
                });
            } else if (props.sameAddress) {
                let input = { ...tempSameAsAddress };
                input.customerId = props.CustomerUserId;
                let log = {
                    action: props.openModal === "Edit" ? 'UPDATE' : 'INSERT',
                    actionOn: 'receivers',
                    actionName: "insertUpdateReceiver",
                    oldValue: props.openModal === "Edit" ? JSON.stringify(input) : ""
                };
                insertUpdateReceiverMutation({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                    form.reset();
                    props.refetch();
                    setTempReceiverData({});
                    setTempCountryId(null);
                    setTempStateId(null);
                    setTempClosestCityId(null);
                    if (props.sameAddress) {
                        $("#NewAddress").css('display', 'none');
                        toast.success("Receiver Address added successfully!!", {
                            autoClose: 2000
                        });
                        setTempSameAsAddress({});
                        props.updateAddressFlag();
                    }
                }).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    });
                });
            }
            return true;
        }
    };

    return (
        <div>
            <div className="mb-4">
                <p className="SubTitle font-weight-bold  m-0">{props?.openModal} Address</p>
                <span className="borderBottomOfTitle width27"></span>
            </div>
            {loading && <Loader />}
            {props && props.sameAddress ?
                <form id="frm_Receiver" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitReceiverofCustomer(e)}>
                    <div className="form-row">
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Receiver Name<span className="text-danger">*</span></label>
                            <input type="text" className="form-control ffPoppins" name="name" defaultValue={tempSameAsAddress && tempSameAsAddress.name} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Receiver Phone<span className="text-danger">*</span></label>
                            <input type="number" className="form-control ffPoppins" name="phone" defaultValue={tempSameAsAddress && tempSameAsAddress.phone} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Address Line 1<span className="text-danger">*</span></label>
                            <input type="text" className="form-control ffPoppins" name="address1" defaultValue={tempSameAsAddress && tempSameAsAddress.address1} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Address Line 2</label>
                            <input type="text" className="form-control ffPoppins" name="address2" defaultValue={tempSameAsAddress && tempSameAsAddress.address2} onChange={(e) => TextChangeHandler(e)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Country<span className="text-danger">*</span></label>
                            <CountryList countryId={tempCountryId} name="countryId" getCRUDCountryId={(id) => handleChangeCountry(id)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">State<span className="text-danger">*</span></label>
                            <StatesList countryId={tempCountryId} stateId={tempStateId} name="stateId" getCRUDStateId={(id) => handleChangeState(id)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">City</label>
                            <input type="text" className="form-control ffPoppins" name="city" defaultValue={tempSameAsAddress && tempSameAsAddress.city} onChange={(e) => TextChangeHandler(e)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Closest City</label>
                            <ClosestCity countryId={tempCountryId} stateId={tempStateId} closestCityId={tempClosestCityId} name="closestCityId" getCRUDClosestCityId={(id) => handleChangeClosestCity(id)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">ZIP/Postal Code</label>
                            <input type="number" className="form-control ffPoppins" name="zipCode" defaultValue={tempSameAsAddress && tempSameAsAddress.zipCode} onChange={(e) => TextChangeHandler(e)} />
                        </div>
                        <div className="form-group p-0 mt-4">
                            <button type="submit" className="btn btn-primary btn-text ml-2 mt-3 mr-4 border-0 bg-searchBlue" >Save Address</button>
                            <button type="reset" className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed" onClick={() => hideAddressArea()} >Cancel</button>
                        </div>
                    </div>
                </form>
                : <form id="frm_Receiver" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitReceiverofCustomer1(e)}>
                    <div className="form-row">
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Receiver Name<span className="text-danger">*</span></label>
                            <input type="text" className="form-control ffPoppins" name="name" defaultValue={props?.ReceiverData && props?.ReceiverData.name} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Receiver Phone<span className="text-danger">*</span></label>
                            <input type="number" className="form-control ffPoppins" name="phone" defaultValue={props?.ReceiverData && props?.ReceiverData.phone} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Address Line 1<span className="text-danger">*</span></label>
                            <input type="text" className="form-control ffPoppins" name="address1" defaultValue={props?.ReceiverData && props?.ReceiverData.address1} onChange={(e) => TextChangeHandler(e)} required />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Address Line 2</label>
                            <input type="text" className="form-control ffPoppins" name="address2" defaultValue={props?.ReceiverData && props?.ReceiverData.address2} onChange={(e) => TextChangeHandler(e)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Country<span className="text-danger">*</span></label>
                            <CountryList countryId={tempCountryId} name="countryId" getCRUDCountryId={(id) => handleChangeCountry(id)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">State<span className="text-danger">*</span></label>
                            <StatesList countryId={tempCountryId} stateId={tempStateId} name="stateId" getCRUDStateId={(id) => handleChangeState(id)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">City</label>
                            <input type="text" className="form-control ffPoppins" name="city" defaultValue={props?.ReceiverData && props?.ReceiverData.city} onChange={(e) => TextChangeHandler(e)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">Closest City</label>
                            <ClosestCity countryId={tempCountryId} stateId={tempStateId} closestCityId={tempClosestCityId} name="closestCityId" getCRUDClosestCityId={(id) => handleChangeClosestCity(id)} />
                        </div>
                        <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                            <label className="frm-label">ZIP/Postal Code</label>
                            <input type="number" className="form-control ffPoppins" name="zipCode" defaultValue={props?.ReceiverData && props?.ReceiverData.zipCode} onChange={(e) => TextChangeHandler(e)} />
                        </div>
                        <div className="form-group p-0 mt-4">
                            <button type="submit" className="btn btn-primary btn-text ml-2 mt-3 mr-4 border-0 bg-searchBlue" >Save Address</button>
                            <button type="reset" className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed" onClick={() => hideAddressArea()} >Cancel</button>
                        </div>
                    </div>
                </form>}
        </div>
    );
};

export default AddressAdd;
