import { useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { GET_WAREHOUSES } from '../../../../graphql/query/WarehouseQuery';
import Pagination from '../../../components/Common/Pagination';
import { AccessDenied } from '../../../components/AccessDenied';
import { GetDestinationWarehouse } from './GetDestinationWarehouse';
import { InsetUpdateWareHouse } from './InsetUpdateWareHouse';
import * as $ from 'jquery';
import Loader from '../../../components/Loader/Loader';

export const DestinationWarehouse = () => {
    const genSettings = JSON.parse(localStorage.getItem('generalSettings'));
    const user = JSON.parse(localStorage.getItem('loggedUser'));

    const [destinationWarehouse, setDestinationWarehouse] = useState({
        limit: genSettings?.paginationLimit || 10,
        pageNo: 0,
        filter: "",
        filterText: '',
        sortKey: 'createdAt',
        sortType: -1,
        temp_countryId: null,
        temp_stateId: null,
        isActive: true,
        temp_isActive: { value: true, label: 'Active' },
        WHData: {},
        openModal: 'Add New',
        id: null,
        Old_Data: null,
    });
    const { data, loading, refetch } = useQuery(GET_WAREHOUSES, {
        variables: { "page": destinationWarehouse?.pageNo + 1, "limit": destinationWarehouse?.limit, "filter": destinationWarehouse?.filter, "sort": { key: destinationWarehouse?.sortKey, type: destinationWarehouse?.sortType } },
        fetchPolicy: 'cache-and-network'
    })

    const addNewDestinationWarehouse = (e) => {
        e.preventDefault();
        setDestinationWarehouse(prevData => ({
            ...prevData, openModal: 'Add New',
            isActive: true,
            temp_countryId: null,
            temp_stateId: null,
            temp_isActive: { value: true, label: 'Active' },
            WHData: {},
        }))
        $('#frm_des_warehouse').removeClass('was-validated');
        $('#frm_des_warehouse')[0].reset();
        $('#DestinationWarehouseModal').modal('show');

    }
    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchDestWarehouse()
        }
    }
    const searchDestWarehouse = () => {
        setDestinationWarehouse(preData => ({ ...preData, pageNo: 0, filter: destinationWarehouse.filterText }))
    }
    const onResetSearch = () => {
        setDestinationWarehouse(preData => ({ ...preData, pageNo: 0, filter: "", filterText: "" }))
    }
    const handlePageClick = data => {
        setDestinationWarehouse(preData => ({ ...preData, pageNo:data?.selected }))
    }

    if (user.roles === 'standard') return <AccessDenied />
    else
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 p-0">
                        <div className="mb-20">
                            <p className="mainTitle m-0 mt_-15">Destination Warehouses</p>
                            <span className="borderBottomOfTitle"></span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="float-right">
                            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewDestinationWarehouse(e)}>
                                <i className="fa fa-plus"></i>Add New Destination Warehouse
                            </button>
                        </div>
                    </div>
                </div>

                <div className="row mb-4">
                    <div className="col-md-12 p-0">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span>Destination Warehouses Details</span>
                            </div>
                            <div className="ibox-content p-0">
                                {loading && <Loader />}
                                <div className="col-md-12 ph-20 mt-3">
                                    <div className="form-row">
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Search</label>
                                            <input type="text" className="form-control" value={destinationWarehouse?.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setDestinationWarehouse(prvData => ({ ...prvData, filterText: e.target.value }))} />
                                        </div>
                                        <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                            <div className="mt-30">
                                                <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchDestWarehouse()} >Search</button>
                                                <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                                <div className="pull-right">
                                                    <Pagination pageCount={data && data?.getWareHouses && Math.ceil(data?.getWareHouses?.count / destinationWarehouse?.limit)}
                                                        pageNo={destinationWarehouse?.pageNo}
                                                        handlePageClick={(data) => handlePageClick(data)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                              <GetDestinationWarehouse destinationWarehouse={destinationWarehouse} setDestinationWarehouse={setDestinationWarehouse} refetch={refetch} wareHouses={data?.getWareHouses}/>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="modal show" id="DestinationWarehouseModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                      <InsetUpdateWareHouse destinationWarehouse={destinationWarehouse} setDestinationWarehouse={setDestinationWarehouse} refetch={refetch}/>
                    </div>
                </div>
            </div>
        )
}
