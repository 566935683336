import React, { useState } from 'react';
import { AuthenticatedLink } from './AuthenticatedLink';

const InvoiceComp = ({ invoice, packageId }) => {
  const [detailIndex, setDetailIndex] = useState(0);
  const [toggle, setToggle] = useState(false);

  const onDetails = (i) => {
    if (detailIndex === i) {
      setToggle(!toggle);
    } else {
      setToggle(true); 
    }
    setDetailIndex(i);
  };

  return (
    invoice && invoice.data.length ? invoice.data.map((data, i) => {
      const { customerInfo, invoiceNo, status, totalAmount, totalAmountNGN, packageBoxId, paymentUrl, createdInvoiceData } = data;
      return (
        <div key={invoiceNo} className="pb-1 boxShadowInv pl-3 pr-3 mb-4"
          style={{ borderColor: packageId === packageBoxId && status === 'paid' ? '#DC3535' : packageId === packageBoxId && status === 'unpaid' ? '#0b429a' : status === 'paid' ? '#0b429a' : '#DC3535' }}
        >
          <div className="col-md-12 mb-2 p-0">
            <label className="mb-0 labelFs14 mr-2">Name : </label>
            <label className="mb-0 inputFs14"> {customerInfo?.firstname + ' ' + customerInfo?.lastname}</label>
          </div>
          <div className='bottomBrdr' />
          <div className="col-md-12 row p-0 pt-2">
            <div className="col-md-2 col-sm-6 mb-2">
              <label className="mb-0 labelFs14">Date</label>
              <p className="mb-0 inputFs14">30/01/2020</p>
            </div>
            <div className="col-md-2 col-sm-6 mb-2">
              <label className="mb-0 labelFs14">Invoice No.</label>
              <p className="mb-0 inputFs14">{invoiceNo}</p>
            </div>
            <div className="col-md-2 col-sm-6 mb-2">
              <label className="mb-0 labelFs14">Amount($)</label>
              <p className="mb-0 inputFs14">{totalAmount}</p>
            </div>
            <div className="col-md-2 col-sm-6 mb-2">
              <label className="mb-0 labelFs14">Amount(₦)</label>
              <p className="mb-0 inputFs14">{totalAmountNGN}</p>
            </div>
            <div className="col-md-2 col-sm-6 mb-2">
              <label className="mb-0 labelFs14">Status</label>
              <p className={`${status === 'paid' ? 'themeGreen' : 'themeRed'} mb-0 inputFs14`}>{status}</p>
            </div>
            <div className="col-md-2 col-sm-6">
              <label
                onClick={() => onDetails(i)}
                className="mb-0 labelFs14 float-right mt-3 themeBlue pointer">
                {i === detailIndex && toggle ? 'Hide' : 'Details'}
              </label>
            </div>
          </div>
          {i === detailIndex && toggle &&
            <div>
              <div className='bottomBrdrLight' />
              <div className='col-md-12 row justify-content-end'>
                {
                  status !== "paid" && paymentUrl?.data?.authorization_url &&
                  <div onClick={() => window.location.href = paymentUrl.data.authorization_url} className='cursor-pointer btn btn-primary btn-text border-0 bg-searchBlue float-right'
                    style={{ margin: "10px", cursor: 'pointer' }}>
                    Pay Now
                  </div>
                }
                <AuthenticatedLink
                  url={createdInvoiceData?.data?.pdf_url}
                  filename={`Invoice-${+new Date()}.pdf`}
                  className='btn btn-primary btn-text border-0 bg-searchBlue float-right'
                  style={{ margin: '10px 0', color: '#fff', cursor: 'pointer' }}>
                  Download Invoice (.pdf)
                </AuthenticatedLink>
              </div>
            </div>
          }
        </div>
      );
    }) : <div className="boxShadowRate boxInnerMargin">
      <div className="text-center">
        <img src="./images/notFound.png" alt="not found"></img>
      </div>
    </div>
  );
};

export default InvoiceComp;
