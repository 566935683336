import React, { useState, useEffect } from 'react';
import { Link, useParams } from "react-router-dom";
import { useQuery, useMutation } from '@apollo/client';
import Select from 'react-select';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Barcode from 'react-barcode';
import bootbox from 'bootbox';
import { toast } from 'react-toastify';
import moment from 'moment';
import Loader from '../../components/Loader/Loader';
import _ from 'lodash';
import { shipmentStatus } from '../../components/Constant';
import * as $ from 'jquery';
import Title from '../../components/Common/Title';
import { GET_DATA_FROM_SHIPMENT } from '../../../graphql/query/shipmentQuery';
// import { DELETE_BOX } from '../../../graphql/mutation/shipmentMutation';
import { DELETE_BOX, UPDATE_SHIPMENT_STATUS } from '../../../graphql/mutation/shipmentMutation'

const ViewShipment = () => {
    const { shipmentId } = useParams();
    const [state, setState] = useState({
        fromDate: null,
        toDate: null,
        filterFrom: null,
        filterTo: null,
        showBarcode: false,
        isCheckAll: false,
        selectedPackages: [],
        status: null,
        changeStatus: null,
        remark: null,
        shipmentId: null,
        filterText: '',
        filter: "",
        PackageBoxData:[]
    });

    useEffect(() => {
        $('.react-datepicker-wrapper').addClass('d-flex')
        if (shipmentId) {
            setState(prevState => ({ ...prevState, shipmentId: shipmentId }));
        }
    }, [shipmentId]);

    const [deleteBoxMutation] = useMutation(DELETE_BOX);
    const [updateShipmentStatusMutation] = useMutation(UPDATE_SHIPMENT_STATUS);
    const { data, refetch ,loading } = useQuery(GET_DATA_FROM_SHIPMENT ,{
        variables : {shipmentId: state?.shipmentId, status: state?.filterStatus, filter: state?.filter, from: state?.filterFrom, to: state?.filterTo }
    })

    useEffect(() => {
        if(data && data?.getPackageBoxFromShipmentId){
        setState(prevState => ({ ...prevState, PackageBoxData : data?.getPackageBoxFromShipmentId}));
        }
    },[data])

    const fromDateChange = (date) => {
        setState(prevState => ({ ...prevState, fromDate: date, filterFrom: moment(date).format('YYYY/MM/DD') }));
    };

    const toDateChange = (date) => {
        setState(prevState => ({ ...prevState, toDate: date, filterTo: moment(date).format('YYYY/MM/DD') }));
    };

    const deleteShipmentDetails = (e, id, deleteBox) => {
        e.preventDefault();

        bootbox.confirm({
            title: "Delete ORDER",
            message: "Are you sure want to delete this record ?",
            buttons: {
                cancel: {
                    label: 'NO',
                    className: 'btn btn-sm bg-Red ffPoppins text-white'
                },
                confirm: {
                    label: 'YES',
                    className: 'btn btn-sm bg-Green ffPoppins text-white'
                }
            },
            callback: function (result) {
                if (result) {
                    let log = {
                        action: 'DELETE',
                        actionOn: 'packageboxes',
                        actionName: 'deleteBox',
                        oldValue: ""
                    };

                    deleteBoxMutation({ variables: { "id": id, "log": log } }).then(async ({ data }) => {
                        toast.success("Box deleted successfully!", {
                            autoClose: 2000
                        });
                        refetch();
                    }).catch((err) => {
                        toast.error(err.message, {
                            autoClose: 2000
                        });
                    });
                }
            }
        });
    };

    const showBarcode = (e, value) => {
        e.preventDefault();
        setState(prevState => ({ ...prevState, showBarcode: true, barcode: value }));
        $('#Barcode').modal('show');
    };

    const selectAllPackage = async (allPackageData) => {
        let getAllPackageIds = [];
        let { selectedPackages } = state;
        if (allPackageData && allPackageData.length > 0) {
            await allPackageData.map(d => d.boxDimension.map(i => getAllPackageIds.push(i.id)));
            let getMatch = (selectedPackages.length > 0 && _.intersection(selectedPackages, getAllPackageIds)) || false;
            if (getMatch && getMatch.length === selectedPackages.length) {
                setState(prevState => ({ ...prevState, selectedPackages: [], isCheckAll: false }));
            } else {
                setState(prevState => ({ ...prevState, selectedPackages: getAllPackageIds, isCheckAll: true }));
            }
        } else {
            setState(prevState => ({ ...prevState, selectedPackages: [], isCheckAll: false }));
        }
    };

    const selectedPackageForChangeStatus = (packageId) => {
        let { selectedPackages } = state;
        let itemArray = selectedPackages.slice(); // Create a shallow copy
        if (itemArray && itemArray.length > 0) {
            let index = itemArray.indexOf(packageId);
            if (index === -1) {
                itemArray.push(packageId);
            } else {
                itemArray.splice(index, 1);
            }
        } else {
            itemArray.push(packageId);
        }
        setState(prevState => ({ ...prevState, selectedPackages: itemArray, isCheckAll: false }));
    };

    const handleChangeStatus = (shipmentStatus) => {
        setState(prevState => ({ ...prevState, status: shipmentStatus, filterStatus: shipmentStatus.value }));
    };

    const handleChangeUpdateStatus = (shipmentStatus) => {
        setState(prevState => ({ ...prevState, changeStatus: shipmentStatus }));
    };

    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    };

    const handleChangeRemark = (e) => {
        setState(prevState => ({ ...prevState, remark: e.target.value }));
    };

    const updateStatus = (event, PackageBoxData) => {
        event.preventDefault();
        const form = event.currentTarget;

        if (!form.checkValidity()) {
            event.preventDefault();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            });
            return false;
        } else {
            let packageBoxIds = _.map(PackageBoxData, "id")
            const { remark, changeStatus, selectedPackages, shipmentId } = state;
            const input = {
                packageBoxIds: packageBoxIds,
                remark: remark || changeStatus.remark,
                status: changeStatus.value,
                selectedPackages: selectedPackages
            };

            if (changeStatus && selectedPackages.length > 0) {
                updateShipmentStatusMutation({ variables: { input: input } }).then(async ({ data }) => {
                    if (data) {
                        refetch();
                        setState(prevState => ({ ...prevState, selectedPackages: [] }))
                            setState(prevState => ({ ...prevState, shipmentId: shipmentId }));
                            toast.success("Status updated successfully!", {
                                autoClose: 2000
                            });
                    }
                }).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    });
                });
            } else {
                toast.error("Please fill required fields", {
                    autoClose: 2000
                });
            }
            return true;
        }
    };

    const searchShipmentView = () => {
        const { filterText } = state;
        setState(prevState => ({ ...prevState, pageNo: 0, filter: filterText }));
    };

    const onResetSearch = () => {
        setState(prevState => ({
            ...prevState,
            fromDate: null,
            toDate: null,
            filterFrom: null,
            filterTo: null,
            showBarcode: false,
            isCheckAll: false,
            selectedPackages: [],
            status: null,
            remark: null,
            filterText: '',
            filter: "",
            filterStatus: null
        }));
    };

    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchShipmentView();
        }
    };
    let index = 0
    const {PackageBoxData} = state;
    
    return (
        <div className="container-fluid">
            <div className="row">
                <Title
                    name="Shipment"
                />
            </div>
            {loading && <Loader />}
            <div className="row">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Shipment Details</span>
                        </div>
                        <div className="ibox-content">
                            <form>
                                <div className="form-row">
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Form</label>
                                        <DatePicker
                                            className="form-control ffPoppins d-flex"
                                            placeholderText="mm/dd/yyyy"
                                            selected={state.fromDate}
                                            maxDate={new Date()}
                                            onChange={(e) => fromDateChange(e)}
                                            onChangeRaw={(e) => handleDateChangeRaw(e)}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">To</label>
                                        <DatePicker
                                            className="form-control ffPoppins d-flex"
                                            placeholderText="mm/dd/yyyy"
                                            selected={state.toDate}
                                            maxDate={new Date()}
                                            onChange={(e) => toDateChange(e)}
                                            onChangeRaw={(e) => handleDateChangeRaw(e)}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Status</label>
                                        <Select
                                            className="ffPoppins"
                                            options={shipmentStatus}
                                            value={state.status}
                                            onChange={(data) =>
                                                handleChangeStatus(data)
                                            }
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12 ">
                                        <label className="frm-label">Search</label>
                                        <input type="text" className="form-control ffPoppins" value={state.filterText} onKeyDown={(e) => handleSearch(e)}
                                            onChange={(e) => setState({ filterText: e.currentTarget.value })}
                                        />
                                    </div>
                                    <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                        <div className="btn-Groups mt-30">
                                            <button type="button" className="btn btn-primary btn-text border-0 bg-searchBlue" onClick={() => searchShipmentView()}>Search</button>
                                            <button type="button" className="btn btn-danger btn-text bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div className="well m-0">
                                <form onSubmit={(e) => updateStatus(e,PackageBoxData)}>
                                    <div className="form-row">
                                        <div className="form-group m-0 col-lg-2 col-md-2 col-sm-6 col-xs-12 d-flex justify-content-center align-items-center">
                                            <input type="checkbox" name="SelectAllBoxToShip" id="SelectAllBoxToShipChangeStatus" checked={state.isCheckAll} onChange={() => selectAllPackage(PackageBoxData)} />
                                            <label htmlFor="SelectAllBoxToShip" className="frm-label chkAll">Check All</label>
                                        </div>
                                        <div className="form-group m-0 col-lg-3 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Status</label>
                                            <Select className="ffPoppins" options={shipmentStatus} value={state.changeStatus} onChange={(data) => handleChangeUpdateStatus(data)} required />
                                        </div>
                                        <div className="form-group m-0 col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                            <label className="frm-label">Remark</label>
                                            <textarea name="remark" id="remark" className="form-control ffPoppins" defaultValue={state.changeStatus?.remark} rows="2" onChange={(e) => handleChangeRemark(e)} required />
                                        </div>
                                        <div className="form-group m-0 col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                            <div className="mt-30 ml-3">
                                                <button type="submit" className="btn btn-success btn-text bg-lightGreen"> Change Status</button>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                            <div className="row mhm-20">
                                <div className="ibox-title border-0 mt-2 mb-2">
                                    <span> List</span>
                                </div>

                                <div className="table-responsive">
                                    <table className="table themeTable table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-center"></th>
                                                <th>No.</th>
                                                <th>Date</th>
                                                <th>Customer info</th>
                                                <th>Receiver info</th>
                                                <th>Box No</th>
                                                <th>Length</th>
                                                <th>Width</th>
                                                <th>Height</th>
                                                <th>Weight</th>
                                                <th>Dimensional Weight</th>
                                                <th>Bin No</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                           
                                            {
                                                PackageBoxData && PackageBoxData.length ?
                                                    PackageBoxData && PackageBoxData.length > 0 &&
                                                    PackageBoxData.map((d, i) => {
                                                        
                                                        return (
                                                            d.boxDimension.map((b, j) => {
                                                                index = index + 1
                                                                let isChecked = !!_.find(state.selectedPackages, pkId => pkId === b.id);
                                                                return (
                                                                    <tr key={b?.id}>
                                                                        <td className=" text-center"><input type="checkbox" checked={isChecked} onChange={() => selectedPackageForChangeStatus(b.id)} /></td>
                                                                        <td>{index}</td>
                                                                        <td>{moment(d?.createdAt).format('MM/DD/YYYY')} <br />
                                                                            <span className="barcode" onClick={(e) => showBarcode(e, b.barcode)}>{b.barcode}</span>
                                                                        </td>
                                                                        <td>{`${d.customerInfo.firstname} ${d.customerInfo.lastname}`}<br />
                                                                            <span>{d.customerInfo.email}</span>
                                                                        </td>
                                                                        <td className='text-wrap'>{`${d.receiverInfo.name} ${d.receiverInfo.phone}`} < br />{`${d?.receiverInfo?.address1} ${d?.receiverInfo?.address2 ? `,${d?.receiverInfo?.address2}` : ''},${d?.receiverInfo?.city},${d?.receiverInfo?.stateId?.name},${d?.receiverInfo?.countryId?.name}`}</td>
                                                                        <td>{b?.box_Num}</td>
                                                                        <td>{b?.len}</td>
                                                                        <td>{b?.width}</td>
                                                                        <td>{b?.height}</td>
                                                                        <td>{b?.weight}</td>
                                                                        <td>{b?.dimensionalWeight}</td>
                                                                        <td>{d?.binNum}</td>
                                                                        <td className='text-wrap'>{b?.status?.status}</td>
                                                                        <td>
                                                                            <div className="d-flex flex-row">
                                                                                <Link to={{ pathname: `/track-package/${b.id}` }}>
                                                                                    <span className="btn BoxImg bg-blue rounded mr-2">
                                                                                        <img src="../images/Truck.png" alt="Img" className="width15" />
                                                                                    </span>
                                                                                </Link>

                                                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteShipmentDetails(e, b.id)}>
                                                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                                                </span>
                                                                            </div>
                                                                        </td>
                                                                    </tr>
                                                                )
                                                            })
                                                        )
                                                    })
                                                    :
                                                    <tr>
                                                        <td style={{ textAlign: 'center' }} colSpan={10} >No Records Found!</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {
                state.showBarcode &&
                <div className="modal show" id="Barcode" tabIndex="-1" role="dialog" data-keyboard="false">
                    <div className="modal-dialog modal-md modal-dialog-centered " role="document">
                        <div className="modal-content">
                            <div className="modal-body barcodeBox">
                                <Barcode key={Math.random().toString()} value={state.barcode} width="2" />
                            </div>
                        </div>
                    </div>
                </div>
            }
        </div>

    );
};

export default ViewShipment