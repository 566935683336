import { gql } from "@apollo/client";


export const SIGN_UP = gql`
  mutation($input: signUpInput!) {
    signUp(input:$input){
    token
    user {
      id
      firstName
      lastName
      email
      roles
      customerId
      phone
      userType
      address1
      countryId
      stateId
      city
      zipcode
      userProfile
      verified
    }
    generalSettings {
      id
      verifiedUserCharge
      orderInsurancePercentage
      paginationLimit
      appVersionAndroid
      appVersionIos
      headeOffAddress1
      headeOffAddress2
      headeOffCountry
      headeOffState
      headeOffCity
      headeOffzipcode
      headeOffCustomerServiceEmail
      headeOffCustomerServicePhone
      headeOffWeb
      headeOffWorkingHrs
      amcWhouseAddress1
      amcWhouseAddress2
      amcWhouseCountry
      amcWhouseState
      amcWhouseCity
      amcWhousezipcode
      amcWhousePhone
      amcWhouseDimensiopnCalc
      amcWhouseWorkingHrs
      lagosOffAddress1
      lagosOffAddress2
      lagosOffCountry
      lagosOffState
      lagosOffCity
      lagosOffzipcode
      lagosOffPhone
      lagosOffWorkingHrs
      orderReceiptAuth
      terms
      prohibitedList
      createdBy
      updatedBy
      createdAt
      updatedAt
    }
    customer{
      id
      companyName
      firstName
      lastName
      email
      receivingServiceType
      phone
      idProof
      signature
      address1
      address2
      city
      countryId
      stateId
      pmbNo
      zipcode
      deliveryServiceType
      isActive
    }
   }
  }
`;

export const FORGET_PASSWORD = gql`
mutation forgetPassword($email: String){
  forgetPassword(email:$email)
}
`

export const RESET_PASSWORD = gql`
mutation resetPassword($token: String, $password: String){
  resetPassword(token:$token,password:$password)
}
`
export const VERIFY_OTP = gql`
mutation verifyOTP($email: String, $otp: Int){
  verifyOTP(email:$email,otp: $otp){
    token
    user{
      id
      userType
      firstName
      lastName
      email
      phone
      serviceType
      customerId
      warehouseId
      roles
    }
    customer{
      id
      companyName
      firstName
      lastName
      email
      receivingServiceType
      phone
      idProof
      signature
      address1
      address2
      city
      countryId
      stateId
      pmbNo
      zipcode
      deliveryServiceType
      isActive
    }
    generalSettings{
      headeOffCustomerServiceEmail
      headeOffCustomerServicePhone
      headeOffWeb
      amcWhouseAddress1
      amcWhouseAddress2
      amcWhouseCity
      amcWhouseCountry
      amcWhouseState
      amcWhousezipcode
      amcWhousePhone
      amcWhouseDimensiopnCalc
      amcWhouseWorkingHrs
      lagosOffAddress1
      lagosOffAddress2
      lagosOffCountry
      lagosOffState
      lagosOffCity
      lagosOffzipcode
      lagosOffPhone
      lagosOffWorkingHrs
      prohibitedList
     }
  }
}`
export const CONTACT_US = gql`
mutation InsertContactUs($input: contactUsInput!) {
  insertContactUs(input: $input)
}
`

export const RESEND_OTP = gql`
  mutation reSendOTP($email: String){
    reSendOTP(email:$email)
  }`;