import { useMutation } from '@apollo/client';
import React from 'react'
import { DELETE_TRACK_STATUS } from '../../../graphql/mutation/TrackMutation';
import confirmDeletion from '../../components/Common/BootBox';
import { useNavigate } from 'react-router-dom';
import * as $ from "jquery";
import { toast } from "react-toastify";

export const OrderTrackingDetails = ({ setTrackOrder , trackOrder , statusMessages}) => {
    const navigate = useNavigate();
    const [onMutateDelete] = useMutation(DELETE_TRACK_STATUS);

    const deleteTrack = (e, oldStatus) => {
        e.preventDefault();
        const deleteTrackData = () => {
            onMutateDelete({
                variables: { orderId: trackOrder?.customerInfo.orderId, oldStatus: oldStatus },
            }).then(() => {
                $("#AddTrackingOrderModal").modal("hide");
                navigate('/order', {
                    state: { trackId: trackOrder?.customerInfo.orderId },
                })
                toast.success("Track deleted successfully!", {
                    autoClose: 2000
                });
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion("DELETE TRACKING DETAILS", "Are you sure want to delete this record ?", deleteTrackData);
    }

    const EditTrackingOrder = (e, date, status, remark) => {
        e.preventDefault();
        setTrackOrder(preData => ({
            ...preData, trackingDate: new Date(date),
            trackingTime: new Date(date),
            selectedTrack: status,
            remark,
            oldStatus: remark,
            isEdit: true,
        }));
        $("#AddTrackingOrderModal").modal("show");
    };

    const { track } = trackOrder;
    const newTrack = track.map((t) => ({ status: t.status, date: t.date }));
    const sortTrack = newTrack.sort(
        (a, b) => new Date(b.date) - new Date(a.date)
    );
    const { wareHouse, packageProcess, airlineCarrier, destinationAirport,lagosWarehouse, pickedUp, delivered, delayed} = statusMessages
    return (
        <div className="ibox-content p-0">
            <div className="table-responsive">
                <table className="table themeTable table-nowrap mb-0">
                    <thead>
                        <tr>
                            <th className="text-center">No.</th>
                            <th>Date</th>
                            <th>Time</th>
                            <th>Status</th>
                            <th>Remark</th>
                            <th>Order Status</th>
                            <th>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {sortTrack.map((t, i) => {
                            const date = new Date(t.date).toLocaleDateString(
                                "en-US"
                            );
                            const time = new Date(t.date).toLocaleString("en-US", {
                                hour: "numeric",
                                minute: "numeric",
                                hour12: true,
                            });
                            let newStatus = "";
                            if (t.status === wareHouse)
                                newStatus = "PACKAGE RECEIVED AT WAREHOUSE";
                            else if (t.status === packageProcess)
                                newStatus = "PACKAGE PROCESSED FOR SHIPPING";
                            else if (t.status === airlineCarrier)
                                newStatus = "SHIPMENT DELIVERED TO AIRLINE CARRIER";
                            else if (t.status === destinationAirport)
                                newStatus = "SHIPMENT ARRIVED DESTINATION AIRPORT";
                            else if (t.status === lagosWarehouse)
                                newStatus = "SHIPMENT RECEIVED AT LAGOS, NIGERIA";
                            else if (t.status === pickedUp)
                                newStatus = "SHIPMENT PICKED UP";
                            else if (t.status === delivered)
                                newStatus = "SHIPMENT DELIVERED";
                            else if (t.status === delayed) newStatus = "DELAYED";
                            return (
                                <tr key={t.status + " - " + i}>
                                    <td className="text-center">{i + 1}</td>
                                    <td>{date}</td>
                                    <td>{time}</td>
                                    <td>{newStatus}</td>
                                    <td>{t.status}</td>
                                    <td>
                                        <span>
                                            {t.status === wareHouse ? (
                                                <>
                                                    <img
                                                        src="../images/Received.png"
                                                        alt="received"
                                                        className="width18"
                                                    />
                                                    <span className="statusLabel text-received">
                                                        Received
                                                    </span>
                                                </>
                                            ) : t.status === "YOUR ORDER WAS PACKED" ? (
                                                <>
                                                    <img
                                                        src="../images/packed.png"
                                                        alt="packed"
                                                        className="width18"
                                                    />
                                                    <span className="statusLabel text-packed">
                                                        Packed
                                                    </span>
                                                </>
                                            ) : t.status === packageProcess ? (
                                                <>
                                                    <img
                                                        src="../images/packed.png"
                                                        alt="packed"
                                                        className="width18"
                                                    />
                                                    <span className="statusLabel text-packed">
                                                        Packed
                                                    </span>
                                                </>
                                            ) : t.status === airlineCarrier ||
                                                t.status === destinationAirport ||
                                                t.status === lagosWarehouse ? (
                                                <>
                                                    <img
                                                        src="../images/shipping.png"
                                                        alt="shipped"
                                                        className="width18"
                                                    />
                                                    <span className="statusLabel text-ship">
                                                        Shipped
                                                    </span>
                                                </>
                                            ) : t.status === pickedUp ? (
                                                <>
                                                    <img
                                                        src="../images/picked-up.png"
                                                        alt="picked up"
                                                        className="width18"
                                                    />
                                                    <span className="statusLabel text-packed">
                                                        Picked Up
                                                    </span>
                                                </>
                                            ) : t.status === delivered ? (
                                                <>
                                                    <img
                                                        src="../images/Delivered.png"
                                                        alt="delivered"
                                                        className="width18"
                                                    />
                                                    <span className="statusLabel text-refused">
                                                        Delivered
                                                    </span>
                                                </>
                                            ) : (
                                                t.status === delayed && (
                                                    <>
                                                        <img
                                                            src="../images/partial_received.png"
                                                            alt="delayed"
                                                            className="width18"
                                                        />
                                                        <span className="statusLabel text-PReceived">
                                                            Delayed
                                                        </span>
                                                    </>
                                                )
                                            )}
                                        </span>
                                    </td>
                                    <td>
                                        <div className="d-flex flex-row">
                                            <span
                                                className="btn BoxImg bg-skyBlue rounded mr-2"
                                                onClick={(e) =>
                                                    EditTrackingOrder(
                                                        e,
                                                        t.date,
                                                        { label: newStatus, value: t.status },
                                                        t.status
                                                    )
                                                }
                                            >
                                                <img
                                                    src="../images/edit.png"
                                                    alt="Img"
                                                    className="width15"
                                                />
                                            </span>

                                            <span
                                                className="btn BoxImg boxBackColorRed rounded mr-2"
                                                onClick={(e) =>
                                                    deleteTrack(
                                                        e,
                                                        t.status,
                                                    )
                                                }
                                            >
                                                <img
                                                    src="../images/bin.png"
                                                    alt="Img"
                                                    className="width15"
                                                />
                                            </span>

                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
            </div>
        </div>
    )
}
