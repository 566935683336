import { useMutation, useQuery } from '@apollo/client';
import React, { useState } from 'react'
import { CUSTOMER_NOTIFICATION } from '../../../../graphql/query/NotificationQuery';
import { READ_NOTIFICATION } from '../../../../graphql/mutation/CustomerNotif';
import { toast } from 'react-toastify';
import { NotificationComp } from '../../../components/NotificationComp';


const CustomerNotif = () => {
  const [user, setUser] = useState(JSON.parse(localStorage.getItem('loggedUser')));
  const { data } = useQuery(CUSTOMER_NOTIFICATION, {
    variables: {
      customerId: user?.customerId,
      sort: { key: 'createdAt', type: -1 }
    },
    fetchPolicy: 'cache-and-network'
  })

  const [readNotification] = useMutation(READ_NOTIFICATION, {
    refetchQueries: [{ query: CUSTOMER_NOTIFICATION, variables: { customerId: user?.customerId, sort: { key: 'createdAt', type: -1 }} }]
  });
  const updateNotification = () => {
    readNotification({
      variables: {
        customerId: user?.customerId
      }
    }).then((data) => {
    }).catch((err) => {
      toast.error(err.message, {
        autoClose: 2000
      })
    })
  }
  return (
    <div className="container mrgnTop110">
      <div className='text-center mb-2'>
        <p className="headTitle">Notification</p>
      </div>
      <div className="col-md-12 mAuto pb-2 invMain mt-5">
        <div className="mb-2 mt-3 d-flex align-items-center justify-content-between">
          <label className="headText mt-2">Notification</label>
          <button className="btn btn-primary pull-right mt-3" onClick={updateNotification}>Mark all read</button>
        </div>
        <NotificationComp NotificationData={data?.getCustomerNotifications} />
      </div>
    </div>
  )
}
export default CustomerNotif
