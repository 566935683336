import { gql } from "@apollo/client";

export const SIGN_IN = gql`
  mutation($email: String!, $password: String!) {
    signIn(email: $email, password: $password) {
      token
      user {
        id
        firstName
        lastName
        email
        roles
        customerId
        phone
        userType
        address1
        countryId
        stateId
        city
        zipcode
        userProfile
        verified
      }
      generalSettings {
        id
        verifiedUserCharge
        orderInsurancePercentage
        paginationLimit
        appVersionAndroid
        appVersionIos
        headeOffAddress1
        headeOffAddress2
        headeOffCountry
        headeOffState
        headeOffCity
        headeOffzipcode
        headeOffCustomerServiceEmail
        headeOffCustomerServicePhone
        headeOffWeb
        headeOffWorkingHrs
        amcWhouseAddress1
        amcWhouseAddress2
        amcWhouseCountry
        amcWhouseState
        amcWhouseCity
        amcWhousezipcode
        amcWhousePhone
        amcWhouseDimensiopnCalc
        amcWhouseWorkingHrs
        lagosOffAddress1
        lagosOffAddress2
        lagosOffCountry
        lagosOffState
        lagosOffCity
        lagosOffzipcode
        lagosOffPhone
        lagosOffWorkingHrs
        orderReceiptAuth
        terms
        prohibitedList
        createdBy
        updatedBy
        createdAt
        updatedAt
      }
      customer{
        id
        companyName
        firstName
        lastName
        email
        receivingServiceType
        phone
        idProof
        signature
        address1
        address2
        city
        countryId
        stateId
        pmbNo
        zipcode
        deliveryServiceType
        isActive
      }
    }
  }
`;


