import React from 'react'
import Loader from '../../../components/Loader/Loader';
import Pagination from '../../../components/Common/Pagination';
import { useMutation } from '@apollo/client';
import { DELETE_SUBCATEGORY } from '../../../../graphql/mutation/CategoryMutation';
import confirmDeletion from '../../../components/Common/BootBox';
import * as $ from 'jquery';
import { toast } from 'react-toastify';

export const GetSubCategory = ({ refetch,loading, subCategoryData, subCategory, setSubCategory }) => {
    const [deleteSubCategory] = useMutation(DELETE_SUBCATEGORY);
    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchSubCategory()
        }
    }
    const searchSubCategory = () => {
        setSubCategory(preData => ({ ...preData, pageNo: 0, filter: subCategory?.filterText }))
    }
    const onResetSearch = () => {
        setSubCategory(preData => ({ ...preData, pageNo: 0, filter: "", filterText: "" }))
    }
    const handlePageClick = (data) => {
        setSubCategory(preData => ({ ...preData, pageNo: data?.selected }))
    };
    const sortData = (sortKey) => {
        const newSortType = (subCategory.sortType === 1) ? -1 : 1;
        setSubCategory((prevData) => ({ ...prevData, sortKey: sortKey, sortType: newSortType }));
    }
    const editSubCategory = (e, oldSubCategoryData) => {
        e.preventDefault();
        setSubCategory(preData => ({
            ...preData, id: oldSubCategoryData.id,
            temp_categoryId: oldSubCategoryData.categoryId.id,
            subCategoryData: oldSubCategoryData,
            Old_Data: oldSubCategoryData,
            openModal: 'Edit'
        }))
        $('#frm_SubCategory').removeClass('was-validated');
        $('#SubCategoryModal').modal('show');
    }
    const deleteSubCategoryDetails = (e, subCategoryId) => {
        e.preventDefault();
        const deleteSubCategoryData = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'subCategories',
                actionName: 'deleteSubCategory',
                oldValue: ""
              }
    
              deleteSubCategory({ variables: { "id": subCategoryId, "log": log } }).then(async ({ data }) => {
                $('#SubCategoryModal').modal('hide');
                refetch();
                toast.success("Sub Category deleted successfully!", {
                  autoClose: 2000
                })
              }).catch((err) => {
                toast.error(err.message, {
                  autoClose: 2000
                })
              })
        }
        confirmDeletion("Delete SUB-CATEGORY" , "Are you sure want to delete this record ?" ,deleteSubCategoryData)
      }
    return (
        
                    <div className="ibox-content p-0">
                        {loading && <Loader />}
                        <div className="col-md-12 ph-20 mt-3">
                            <div className="form-row">
                                <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12 ">
                                    <label className="frm-label">Search</label>
                                    <input type="text" className="form-control" value={subCategory?.filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setSubCategory(preData => ({ ...preData, filterText: e.target.value }))} />
                                </div>
                                <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                    <div className="mt-30">
                                        <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" onClick={() => searchSubCategory()} >Search</button>
                                        <button className="btn btn-danger btn-text mr-2  bg-resetRed" onClick={() => onResetSearch()} >Reset</button>
                                        <div className="pull-right">
                                            <Pagination
                                                pageCount={subCategoryData && Math.ceil(subCategoryData.count / subCategory?.limit)}
                                                pageNo={subCategory?.pageNo}
                                                handlePageClick={(data) => handlePageClick(data)}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="table-responsive">
                            <table className="table themeTable table-nowrap mb-0 tbl-sort">
                                <thead>
                                    <tr>
                                        <th className="text-center">No.</th>
                                        <th>Icon</th>
                                        <th>Category</th>
                                        <th
                                            className={`sortColumn ${(subCategory?.sortKey === 'subCategory') ? (subCategory?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                            onClick={(e) => sortData('subCategory')}>Sub-Category</th>
                                        <th>Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        subCategoryData && subCategoryData.data && subCategoryData.data.length > 0
                                            ? subCategoryData.data && subCategoryData.data.map((d, i) => {
                                                return (
                                                    <tr key={d?.id}>
                                                        <td className=" text-center">{i + 1}</td>
                                                        <td>
                                                            <img src={d.icon} alt="img" className="width55 img-thumbnail" />
                                                        </td>
                                                        <td>{d.categoryId && d.categoryId.name}</td>
                                                        <td>{d.name}</td>
                                                        <td>
                                                            <div className="d-flex flex-row">
                                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => { editSubCategory(e, d); }}>
                                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                                </span>
                                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => { deleteSubCategoryDetails(e, d.id); }}>
                                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                )
                                            })
                                            : <tr>
                                                <td style={{ textAlign: 'center' }} colSpan={5} >No Records Found!</td>
                                            </tr>
                                    }
                                </tbody>
                            </table>
                        </div>
                    </div>
           
    )
}
