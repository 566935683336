import { useMutation, useQuery } from '@apollo/client'
import React, { useEffect, useState } from 'react'
import { GET_CUTOMERUSER } from '../../../graphql/query/CustomerQuery'
import "react-datepicker/dist/react-datepicker.css";
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import Select from 'react-select';
import { receivingServices, statusType } from '../../components/Constant';
import moment from 'moment';
import { DELETE_CUSTOMER, RESETPASSWORD_CUSTOMER } from '../../../graphql/mutation/CustomerMutation';
import { toast } from 'react-toastify';
// import '../../../css/custom.css'
import * as $ from 'jquery';
import CURDCustomer from './Customer';
import Title from '../../components/Common/Title';
import Pagination from '../../components/Common/Pagination';
import confirmDeletion from '../../components/Common/BootBox';
import { DateInput, SearchInput, SelectInput } from '../../components/Common/Filter'

const Customers = () => {

    const genSettings = JSON.parse(localStorage.getItem('generalSettings'));

    const [filterStatus, setFilterStatus] = useState(null)
    const [CustomerModel, setCustomerModel] = useState('Add');
    const [serviceType, setServiceType] = useState(null);
    const [filterService, setFilterService] = useState(null);
    const [status, setStatus] = useState(null);
    const [fromDate, setFromDate] = useState(null);
    const [filterFrom, setFilterFrom] = useState(null);
    const [filterTo, setFilterTo] = useState(null);
    const [toDate, setToDate] = useState(null);
    const [limit, setLimit] = useState((genSettings?.paginationLimit) ? (genSettings?.paginationLimit) : 10);
    const [pageNo, setPageNo] = useState(0);
    const [filter, setFilter] = useState("");
    const [filterText, setFilterText] = useState('');
    const [sortKey, setSortKey] = useState('createdAt');
    const [sortType, setSortType] = useState(-1);
    const [id, setId] = useState(null);
    const [pmbNo, setPmbNo] = useState(null);
    const [CustomerDetailsData, setCustomerDetailsData] = useState({});
    const [modalOpen, setModalOpen] = useState(false)
    const [password, setPassword] = useState(null)
    const [confirmPassword, setConfirmPassword] = useState(null)

    const sort = { key: sortKey, type: sortType };

    const { data: customerUser ,refetch } = useQuery(GET_CUTOMERUSER, {
        variables: { "page": pageNo + 1, "limit": limit, "filter": filter, "sort": sort, from: filterFrom, to: filterTo, serviceType: filterService, status: filterStatus },
        fetchPolicy: 'cache-and-network'
    })

    const [deleteCustomerByAdmin] = useMutation(DELETE_CUSTOMER)
    const [setPasswordOfCustomerByAdmin] = useMutation(RESETPASSWORD_CUSTOMER)

    useEffect(() => {
        $('[data-toggle="tooltip"]').tooltip()
        $('.react-datepicker-wrapper').addClass('d-flex');
    },[]);
    
    const handleDateChangeRaw = (e) => {
        e.preventDefault();
    }

    const fromDateChange = (date) => {
        setFromDate(date)
        setFilterFrom(moment(date).format('YYYY/MM/DD'))
    }

    const toDateChange = (date) => {
        setToDate(date);
        setFilterTo(moment(date).format('YYYY/MM/DD'))
    }

    const handleReceiveServiceType = (receiveType) => {
        setServiceType(receiveType)
        setFilterService(receiveType.value)
    }

    const handleChangeStatus = (statusType) => {
        setStatus(statusType)
        setFilterStatus(statusType.value)
    }

    const searchCustomerUser = () => {
        setPageNo(0)
        setFilter(filterText)
    }

    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchCustomerUser()
        }
    }

    const onResetSearch = () => {
        setPageNo(0)
        setFilter("")
        setFilterText("")
        setServiceType(null)
        setStatus(null)
        setFilterService(null)
        setFilterStatus(null)
        setFromDate(null)
        setFilterFrom(null)
        setFilterTo(null)
        setToDate(null)
    }

    const handlePageClick = data => {
        setPageNo(data.selected)
    }

    const sortData = (sortKey) => {
        const newSortType = (sortType === 1) ? -1 : 1;
        setSortKey(sortKey)
        setSortType(newSortType)
    }

    const EditCustomer = (e, oldCustomerData) => {
        e.preventDefault();
        setCustomerModel('Edit')
        setModalOpen(true)
        setId(oldCustomerData.id)
        setPmbNo(oldCustomerData.pmbNo)
        setCustomerDetailsData(oldCustomerData)
    }

    const deleteCustomerDetails = async (e, customerId) => {
        e.preventDefault()
        const onDeleteReceiverAction = () => {
            deleteCustomerByAdmin({ variables: { "id": customerId } }).then(async ({ data }) => {
                $('#CustomerModal').modal('hide');
                toast.success("Customer User deleted successfully!", {
                    autoClose: 2000
                });
                refetch();
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        };
        confirmDeletion("Delete CUSTOMER ", "Are you sure want to delete this record ?", onDeleteReceiverAction);
    }

    const AddnewCustomer = (e) => {
        e.preventDefault();
        setCustomerModel('Add New')
        setModalOpen(true)
    }

    const toggleModal = (e) => {
        $('#CustomerModal').modal('hide')
        setModalOpen(false)
        setCustomerDetailsData(null)
    }

    const ResetPasswordModal = (e, customerId) => {
        e.preventDefault();
        setId(customerId)
        $('#ResetPasswordModal').modal('show');
    }

    const formOnSubmitResetAdminCustomerPassword = (e) => {
        e.preventDefault()
        const form = e.currentTarget;
        if (!form.checkValidity()) {
            e.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            if (password === confirmPassword) {
                let input = {
                    customerId: id,
                    password: password,
                }
                let log = {
                    action: 'UPDATE',
                    actionOn: 'users',
                    actionName: 'setPasswordOfCustomerByAdmin',
                    oldValue: JSON.stringify(input)
                };
                setPasswordOfCustomerByAdmin({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                    form.classList.remove('was-validated');
                    $('#ResetPasswordModal').modal('hide');
                    form.reset();
                    toast.success("Password updated successfully!", {
                        autoClose: 2000
                    })
                }).catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                })
            } else {
                toast.error('Passwords do not match.', {
                    autoClose: 2000
                })
            }
            return true;
        }
    }

    return (
        <div className="container-fluid">
            <div className="row">
                <Title
                    name="Customers List"
                />
                <div className="col-md-4 p-0">
                    <div className="float-right">
                        <button className="btn btn-success btn-text bg-lightGreen"
                            onClick={(e) => AddnewCustomer(e)}
                        > <i className="fa fa-plus"></i> Add New Customer</button>
                    </div>
                </div>
            </div>
            <div className="row mb-4">
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Customers Filters</span>
                        </div>
                        <div className="ibox-content">
                            <div className="form-row">
                                {["From", "To"].map((label, index) => (
                                    <DateInput
                                        key={index}
                                        label={label}
                                        selected={index === 0 ? fromDate : toDate}
                                        maxDate={new Date()}
                                        onChange={(date) => {
                                            const selectedDate = index === 0 ? date : fromDate;
                                            toDateChange(index === 0 ? null : date);
                                            fromDateChange(selectedDate);
                                        }}
                                        onChangeRaw={handleDateChangeRaw}
                                        minDate={label === "To" ? fromDate : null}
                                    />
                                ))}
                                <SelectInput
                                    label="Service Type"
                                    options={receivingServices}
                                    value={serviceType}
                                    onChange={(data) => handleReceiveServiceType(data)}
                                />
                                <SelectInput
                                    label="Status"
                                    options={statusType}
                                    value={status}
                                    onChange={(data) => handleChangeStatus(data)}
                                />
                                <SearchInput
                                    filterText={filterText}
                                    setFilterText={setFilterText}
                                    handleSearch={handleSearch}
                                    search={searchCustomerUser}
                                    onResetSearch={onResetSearch}
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>Customers Details</span>
                            <div className="pull-right">
                                <Pagination
                                    pageCount={customerUser?.getCustomers && Math.ceil(customerUser?.getCustomers.count / limit)}
                                    pageNo={pageNo}
                                    handlePageClick={handlePageClick}
                                />
                            </div>
                        </div>
                        <div className="ibox-content p-0">
                            <div className="table-responsive">
                                <table className="table themeTable table-nowrap mb-0 tbl-sort">
                                    <thead>
                                        <tr>
                                            <th className="text-center">No.</th>
                                            {[
                                                { key: 'createdAt', label: 'Date' },
                                                { key: 'firstName', label: 'Name' },
                                                { key: 'pmbNo', label: 'PMB No.' },
                                                { key: 'email', label: 'Email' },
                                                { key: 'phone', label: 'Phone' },
                                                { key: 'serviceType', label: 'Service Type' ,sortable: false},
                                                { key: 'isActive', label: 'Status' },
                                            ].map((column, index) => (
                                                <th
                                                    key={index}
                                                    className={`sortColumn ${column.sortable !== false && ( sortKey === column.key ?
                                                        sortType === 1 ? 'sorting_asc' : 'sorting_desc'
                                                        : 'sorting')
                                                        }`}
                                                    onClick={() => {column.sortable !== false &&  sortData(column.key)}}
                                                >
                                                    {column.label}
                                                </th>
                                            ))}
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            customerUser?.getCustomers?.data?.length ?
                                                customerUser.getCustomers.data.map((d, i) => {
                                                    let c_address = d.address1 + (d.address2 && ' ,' + d.address2) + (d.address2 && d.city && ' ,' + d.city) + (d.stateId && d.stateId !== null && ' ,' + d.stateId.name) + (d.countryId && ' ,' + d.countryId.name) + (d.zipcode && ' ,' + d.zipcode);
                                                    return (
                                                        <tr key={d.id} onDoubleClick={(e) => EditCustomer(e, d)} style={{ cursor: "pointer" }}>
                                                            <td className=" text-center">{i + 1}</td>
                                                            <td>{moment(d.createdAt).format('MM/DD/YYYY')}</td>
                                                            <td>{`${d.firstName} ${d.lastName ? d.lastName : ''}`}</td>
                                                            <td>{d.pmbNo && d.pmbNo}</td>
                                                            <td>{d.email}</td>
                                                            <td data-toggle="tooltip" data-placement="bottom" title={c_address}>{d.phone}</td>
                                                            <td>{d.receivingServiceType && d.receivingServiceType.charAt(0).toUpperCase() + d.receivingServiceType.slice(1)}</td>
                                                            <td>
                                                                <span className={`statusStyle ${d.isActive ? 'activeCustomer' : 'inActiveCustomer'}`}>
                                                                    {d.isActive ? 'Active' : 'InActive'}
                                                                </span>
                                                            </td>
                                                            <td>
                                                                <div className="d-flex flex-row">
                                                                    <span className="btn BoxImg bg-Orange rounded mr-2" onClick={(e) => ResetPasswordModal(e, d.id)}>
                                                                        <img src="../images/key.png" alt="Img" className="width15" />
                                                                    </span>
                                                                    <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => EditCustomer(e, d)}>
                                                                        <img src="../images/edit.png" alt="Img" className="width15" />
                                                                    </span>
                                                                    <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => deleteCustomerDetails(e, d.id)}>
                                                                        <img src="../images/bin.png" alt="Img" className="width15" />
                                                                    </span>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    );
                                                })
                                                : <tr>
                                                    <td style={{ textAlign: 'center' }} colSpan={9} >No Records Found!</td>
                                                </tr>
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div className="modal show" id="ResetPasswordModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                    <div className="modal-content ">
                        <div className="modal-body">
                            <div className="">
                                <div className="mb-4">
                                    <p className="mainTitle m-0">Reset Password</p>
                                    <span className="borderBottomOfTitle"></span>
                                </div>
                                <div className="ibox-title border-0 p-0">
                                    <span>Reset Password</span>
                                </div>
                            </div>
                            <form id="frm_ResetUser" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitResetAdminCustomerPassword(e)}>
                                <div className="form-row">
                                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label className="frm-label">New Password<span className="text-danger">*</span></label>
                                        <input type="password" className="form-control ffPoppins" onChange={e => setPassword(e.target.value)} required />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12">
                                        <label className="frm-label">Confirm Password<span className="text-danger">*</span></label>
                                        <input type="password" className="form-control ffPoppins" onChange={e => setConfirmPassword(e.target.value)} required />
                                    </div>
                                </div>

                                <div className="form-group p-0">
                                    <button type="submit" className="btn btn-primary btn-text mt-3 mr-4 border-0 bg-searchBlue" >Save</button>
                                    <button className="btn btn-danger btn-text mt-3 mr-4 bg-resetRed" data-dismiss="modal" >Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            {
                modalOpen &&
                <CURDCustomer
                    // key={Math.random().toString()}
                    CustomerDetailsData={CustomerDetailsData}
                    CustomerUserId={id}
                    curdType={CustomerModel}
                    modalOpen={modalOpen}
                    pmbNo={pmbNo}
                    closeModal={(e) => toggleModal(e)}
                    refetch={refetch}
                />
            }
        </div>
    )
}

export default Customers