import { useMutation } from '@apollo/client';
import moment from 'moment';
import React from 'react'
import { DELETE_WAREHOUSE_USER } from '../../../../../graphql/mutation/WareHouseMutation';
import { toast } from 'react-toastify';
import confirmDeletion from '../../../../components/Common/BootBox';
import * as $ from 'jquery';
import { warehouseUsersRole as userType  } from '../../../../components/Constant';

export const GetWarehouseUser = ({ getWHUser, warehouseUser, setWarehouseUser, refetch }) => {
    const [deleteWarehouseUser] = useMutation(DELETE_WAREHOUSE_USER);

    const sortData = (sortKey) => {
        const newSortType = (warehouseUser.sortType === 1) ? -1 : 1;
        setWarehouseUser((prevData) => ({ ...prevData, sortKey: sortKey, sortType: newSortType }));
    };
    const ResetPasswordModal = (e, warehouseUserId) => {
        e.preventDefault();
        setWarehouseUser((prevData) => ({ ...prevData, id: warehouseUserId }));
        $('#ResetPasswordModal').modal('show');
    }
    const editWarehouseUser = (e, oldWHUserDetails) => {
        e.preventDefault();
        let userValue;
        if (oldWHUserDetails.userType) {
            userValue = userType.filter(d => {
                return d.value === oldWHUserDetails.userType
            })
        }
        let statusValue = oldWHUserDetails.isActive ? { value: true, label: 'Active' } : { value: false, label: 'Inactive' };
        let selectedCities = []
        if (oldWHUserDetails && oldWHUserDetails.assignClosestCity && oldWHUserDetails.assignClosestCity.length > 0) {
            oldWHUserDetails.assignClosestCity.map((d) => {
                selectedCities.push(d.id)
                return d;
            });
        }
        setWarehouseUser((prevData) => ({
            ...prevData, id: oldWHUserDetails.id,
            temp_countryId: oldWHUserDetails.countryId && oldWHUserDetails.countryId.id,
            temp_stateId: oldWHUserDetails.stateId && oldWHUserDetails.stateId.id,
            temp_closestCityId: oldWHUserDetails && oldWHUserDetails?.assignClosestCity ? selectedCities : [],
            temp_isActive: statusValue,
            temp_userType: userValue,
            userTypeColorError: true,
            WHUserData: oldWHUserDetails,
            Old_Data: oldWHUserDetails,
            openModal: 'Edit'
        }));
        $('#WarehouseUserModal').modal('show');
        $('frm_wareHouseUser').removeClass('was-validated');

    }

    const deleteWHUser = (e, WHUserId) => {
        e.preventDefault();
        const deleteuser = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'warehouseusers',
                actionName: 'deleteWarehouseUser',
                oldValue: ""
            };
            deleteWarehouseUser({ variables: { "id": WHUserId, "log": log } }).then(async ({ data }) => {
                refetch();
                $('#WarehouseLocationModal').modal('hide');
                toast.success("Destination Warehouse User deleted successfully!", {
                    autoClose: 2000
                })
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
        }
        confirmDeletion('Delete DestinationWarehouseUser' , 'Are you sure want to delete this record ?' , deleteuser);
    }
    return (
        <div className="table-responsive">
            <table className="table themeTable table-nowrap mb-0 tbl-sort">
                <thead>
                    <tr>
                        <th className="text-center">No</th>
                        <th
                            className={`sortColumn ${(warehouseUser?.sortKey === 'createdAt') ? (warehouseUser?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('createdAt')}>Date</th>
                        <th
                            className={`sortColumn ${(warehouseUser?.sortKey === 'firstName') ? (warehouseUser?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('firstName')}>Name</th>
                        <th
                            className={`sortColumn ${(warehouseUser?.sortKey === 'addLine1') ? (warehouseUser?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('addLine1')}>Address</th>
                        <th>Closest City</th>
                        <th>Type</th>
                        <th>Added By</th>
                        <th
                            className={`sortColumn ${(warehouseUser?.sortKey === 'isActive') ? (warehouseUser?.sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                            onClick={(e) => sortData('isActive')}>Status</th>
                        <th>Action</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        getWHUser && getWHUser.data && getWHUser.data.length > 0
                            ? getWHUser.data && getWHUser.data.map((d, i) => {
                                let closestCities = "";
                                if (d && d.assignClosestCity && d.assignClosestCity.length > 0) {
                                    d.assignClosestCity.map((city, i) => {
                                        let cityName = `${city.name} || ${city.code}`;
                                        if (i !== 0 && (i + 1) === d.assignClosestCity.length) {
                                            cityName = `, ${city.name} || ${city.code}`;
                                        }
                                        return closestCities = closestCities + cityName;
                                    })
                                } else {
                                    closestCities = "Not yet added"
                                }
                                return (
                                    <tr key={d?.id}>
                                        <td className=" text-center">{i + 1}</td>
                                        <td>{moment(d.createdAt).format('MM/DD/YYYY')}</td>
                                        <td>{d.firstName} {d.lastName} <br /> <span>{d.email}</span> </td>
                                        <td>{d.addLine1 && d.addLine1}{d.addLine1 && d.addLine2 && ', '}{d.addLine2 && d.addLine2}{d.addLine2 && d.city && ', '}{d.city && d.city}{d.city && d.stateId && d.stateId.name && ', '}{d.stateId && d.stateId.name} </td>
                                        <td>{closestCities}</td>
                                        <td>{d.userType}</td>
                                        <td>{d.reference && d.reference.firstName} {d.reference && d.reference.lastName}<br />
                                            <span className="shipStaus">{d.reference && d.reference.userType}</span>
                                        </td>
                                        <td>
                                            {
                                                d.isActive
                                                    ? <span className="statusStyle activeCustomer">Active</span>
                                                    : <span className="statusStyle inActiveCustomer">InActive</span>
                                            }
                                        </td>
                                        <td>
                                            <div className="d-flex flex-row">
                                                <span className="btn BoxImg bg-Orange rounded mr-2" onClick={(e) => { ResetPasswordModal(e, d.id); }}>
                                                    <img src="../images/key.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editWarehouseUser(e, d)}>
                                                    <img src="../images/edit.png" alt="Img" className="width15" />
                                                </span>
                                                <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => { deleteWHUser(e, d.id); }}>
                                                    <img src="../images/bin.png" alt="Img" className="width15" />
                                                </span>
                                            </div>
                                        </td>
                                    </tr>
                                )
                            })
                            : <tr>
                                <td style={{ textAlign: 'center' }} colSpan={9} >No Records Found!</td>
                            </tr>
                    }
                </tbody>
            </table>
        </div>
    )
}
