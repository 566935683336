import React, {useEffect, useState } from 'react'
import { AccessDenied } from '../../../components/AccessDenied'
import { GET_BOX_DIMENSIONS } from '../../../../graphql/query/BoxDimesionQuery';
import Loader from '../../../components/Loader/Loader';
import ReactPaginate from 'react-paginate';
import * as $ from 'jquery';
import { toast } from 'react-toastify';
import { useMutation, useQuery } from '@apollo/client';
import { DELETE_BOX_DIMENSIONS, INSERT_UPDATE_BOX_DIMENSIONS } from '../../../../graphql/mutation/BoxDimensionMutation';
import confirmDeletion from '../../../components/Common/BootBox';

export const BoxDimension = () => {
    const [genSettings, setGenSettings] = useState(JSON.parse(localStorage.getItem('generalSettings')));
    const [user, setUser] = useState(JSON.parse(localStorage.getItem('loggedUser')));
    const [limit, setLimit] = useState(genSettings?.paginationLimit || 10);
    const [openModal, setOpenModal] = useState('Add New');
    const [BoxDimensionData, setBoxDimensionData] = useState({});
    const [pageNo, setPageNo] = useState(1);
    const [filter, setFilter] = useState('');
    const [filterText, setFilterText] = useState('');
    const [sortKey, setSortKey] = useState('createdAt');
    const [sortType, setSortType] = useState(-1);
    const [oldValue, setOldValue] = useState({});
    const [dimensionalCalc, setDimensionalCalc] = useState(genSettings?.amcWhouseDimensiopnCalc || 166);

    const { loading, data, refetch } = useQuery(GET_BOX_DIMENSIONS, {
        variables: { page: pageNo, limit: limit, filter: filter, sort: { key: sortKey, type: sortType } },
        fetchPolicy: 'cache-and-network',
    });

    const [deleteBoxDimensions] = useMutation(DELETE_BOX_DIMENSIONS);
    const [insert_Update_BoxDimensions, { loading: insertUpdateLoading }] = useMutation(INSERT_UPDATE_BOX_DIMENSIONS);
    
    const addNewDimension = (e) => {
        e.preventDefault();
        setOpenModal("Add New")
        $('frm_BoxDimension').removeClass('was-validated');
        $('#BoxDimensionModal').modal('show');
    }
    const searchBoxDimension = (e) => {
        e.preventDefault()
        setFilter(filterText)
    }
    const onResetSearch = (e) => {
        e.preventDefault()
        setFilter("");
        setFilterText("");
    }
    const handleSearch = (e) => {
        if (e.keyCode === 13 || e.which === 13) {
            searchBoxDimension(e)
        }
    }
    const handlePageClick = (data) => {
        setPageNo(data?.selected + 1);
    }
    const sortData = (sortKey) => {
        const newSortType = (sortType === 1) ? -1 : 1;
        setSortKey(sortKey);
        setSortType(newSortType);
    };
    const editDimension = (e, oldBoxDimension) => {
        e.preventDefault();
        setOpenModal("Edit");
        setBoxDimensionData(oldBoxDimension);
        setOldValue(oldBoxDimension);
        $('frm_BoxDimension').removeClass('was-validated');
        $('#BoxDimensionModal').modal('show');
    }
    const formOnSubmitBoxDimension = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            let input = {};
            let old_value = {};
            let log = {};
            if (openModal === "Edit") {
                for (var obj in oldValue) {
                    if (oldValue[obj] !== BoxDimensionData[obj]) {
                        input[obj] = BoxDimensionData[obj]
                        old_value[obj] = oldValue[obj]
                    }
                }
                input.id = BoxDimensionData.id
            } else {
                input = {
                    ...BoxDimensionData
                }
            }
            log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'boxdimensions',
                actionName: "insertUpdateBoxDimensions",
                oldValue: openModal === "Edit" ? JSON.stringify(old_value) : ""
            }
            insert_Update_BoxDimensions({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                $('#BoxDimensionModal').modal('hide');
                form.reset()
                refetch();
                setBoxDimensionData({});
                if (openModal === "Edit") {
                    toast.success("Box Dimension updated successfully", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("Box Dimension added successfully", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true
        }
    }

    const deleteBoxDimensionDetails = (e, id) => {
        e.preventDefault();
        const deleteBoxDiamension = () => {
            let log = {
                action: 'DELETE',
                actionOn: 'boxdimensions',
                actionName: 'deleteBoxDimensions',
                oldValue: ""
            };

            deleteBoxDimensions({ variables: { "id": id, "log": log } })
                .then(async ({ data }) => {
                    refetch();
                    toast.success("Box Dimension deleted successfully", {
                        autoClose: 2000
                    });
                })
                .catch((err) => {
                    toast.error(err.message, {
                        autoClose: 2000
                    })
                })
        }
        confirmDeletion('Delete Box Dimension Details' , 'Are you sure want to delete this record ?',deleteBoxDiamension);
    };

    const TextChangeHandler = (e) => {
        const { name, value } = e.target;
        setBoxDimensionData(prevData => ({
          ...prevData,
          [name]: value,
          dimensionalWeight: calculateDimensionalWeight({...prevData, [name]: value}),
          cubicFeet: calculateCubicFeet({...prevData, [name]: value}),
        }));
      };
      
      const calculateDimensionalWeight = (data) => {
        const { length, width, height } = data;
        const getMultiplication = parseFloat(length) * parseFloat(width) * parseFloat(height) || 0;
        const dimensionalWeight = getMultiplication / dimensionalCalc;
        return dimensionalWeight.toFixed(2);
      };
      
      const calculateCubicFeet = (data) => {
        const { length, width, height } = data;
        const getMultiplication = parseFloat(length) * parseFloat(width) * parseFloat(height) || 0;
        const cubicFeet = getMultiplication * 0.0006;
        return cubicFeet.toFixed(2);
      };


    if (user.roles === 'standard') return <AccessDenied />
    else
        return (
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-8 p-0">
                        <div className="mb-20">
                            <p className="mainTitle m-0 mt_-15">Box Dimension</p>
                            <span className="borderBottomOfTitle"></span>
                        </div>
                    </div>
                    <div className="col-md-4 p-0">
                        <div className="float-right">
                            <button className="btn btn-success btn-text bg-lightGreen" onClick={(e) => addNewDimension(e)}> <i className="fa fa-plus"></i> Add New Dimension</button>
                        </div>
                    </div>
                </div>
                <div className="row mb-4">
                    <div className="col-md-12 p-0">
                        <div className="ibox">
                            <div className="ibox-title">
                                <span>Dimension Details</span>
                            </div>
                            <div className="ibox-content p-0">
                                {loading && <Loader />}
                                <div className="col-md-12 ph-20 mt-3">
                                    <form >
                                        <div className="form-row">

                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12 ">
                                                <label className="frm-label">Search</label>
                                                <input type="text" className="form-control ffPoppins" value={filterText} onKeyDown={(e) => handleSearch(e)} onChange={(e) => setFilterText(e.target.value)} />
                                            </div>
                                            <div className="form-group col-lg-2 col-md-2 col-sm-6 col-xs-12">
                                                <div className="btn-Groups mt-30">
                                                    <button className="btn btn-primary btn-text border-0 bg-searchBlue" onClick={(e) => searchBoxDimension(e)}>Search</button>
                                                    <button className="btn btn-danger btn-text bg-resetRed" onClick={(e) => onResetSearch(e)}>Reset</button>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="ibox-title border-0">
                                    <span> List</span>
                                    <div className="pull-right">
                                        <ReactPaginate
                                            previousLabel={'Back'}
                                            nextLabel={'Next'}
                                            pageCount={data?.getBoxDimensions && Math.ceil(data?.getBoxDimensions.count / limit)}
                                            marginPagesDisplayed={2}
                                            pageRangeDisplayed={5}
                                            onPageChange={(data) => handlePageClick(data)}
                                            containerClassName={'pagination mb-0'}
                                            subContainerClassName={'page-item'}
                                            activeClassName={'active'}
                                            pageLinkClassName={'pagination-link'}
                                        />
                                    </div>
                                </div>

                                <div className="table-responsive">
                                    <table className="table themeTable table-nowrap mb-0">
                                        <thead>
                                            <tr>
                                                <th className="text-center">No</th>
                                                <th className={`sortColumn ${(sortKey === 'name') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('name')} >Name</th>
                                                <th className={`sortColumn ${(sortKey === 'height') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('height')} >Height</th>
                                                <th className={`sortColumn ${(sortKey === 'width') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('width')} >Width</th>
                                                <th className={`sortColumn ${(sortKey === 'length') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('length')} >Length</th>
                                                <th className={`sortColumn ${(sortKey === 'dimensionalWeight') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('dimensionalWeight')} >Dimensional Weight</th>
                                                <th className={`sortColumn ${(sortKey === 'cubicFeet') ? (sortType === 1) ? 'sorting_asc' : 'sorting_desc' : 'sorting'}`}
                                                    onClick={(e) => sortData('cubicFeet')} >Cubic Feet</th>
                                                <th  >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                data?.getBoxDimensions && data?.getBoxDimensions?.data && data?.getBoxDimensions?.data?.length > 0
                                                    ? data?.getBoxDimensions && data?.getBoxDimensions?.data.map((d, i) => {
                                                        return (
                                                            <tr key={d?.id} style={{ cursor: "pointer" }} onDoubleClick={(e) => editDimension(e, d)}>
                                                                <td className=" text-center">{i + [pageNo - 1] * 10 + 1}</td>
                                                                <td>{d?.name}</td>
                                                                <td>{d?.height}</td>
                                                                <td>{d?.width}</td>
                                                                <td>{d?.length}</td>
                                                                <td>{d?.dimensionalWeight}</td>
                                                                <td>{d?.cubicFeet}</td>
                                                                <td>
                                                                    <div className="d-flex flex-row">
                                                                        <span className="btn BoxImg bg-skyBlue rounded mr-2" onClick={(e) => editDimension(e, d)}>
                                                                            <img src="../images/edit.png" alt="Img" className="width15" />
                                                                        </span>
                                                                        <span className="btn BoxImg boxBackColorRed rounded mr-2" onClick={(e) => { deleteBoxDimensionDetails(e, d.id) }}>
                                                                            <img src="../images/bin.png" alt="Img" className="width15" />
                                                                        </span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                    : <tr>
                                                        <td style={{ textAlign: 'center' }} colSpan={8} >No Records Found!</td>
                                                    </tr>
                                            }
                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
                <div className="modal show" id="BoxDimensionModal" data-backdrop="static" tabIndex="-1" role="dialog" >
                    <div className="modal-dialog modal-xl modal-dialog-centered " role="document">
                        <div className="modal-content ">
                            <div className="modal-header" style={{ paddingTop: "0px" }}>
                                <div>
                                    <p className="mainTitle m-0 mt_-15">{openModal} Dimension</p>
                                    <span className="borderBottomOfTitle "></span>
                                </div>
                                <div>
                                    <button data-dismiss="modal" onClick={() => { setBoxDimensionData([]) }}
                                        type="button" className="close" aria-label="Close" style={{ marginTop: "20px", marginRight: "2px" }}>
                                        <span aria-hidden="true">&times;</span>
                                    </button>
                                </div>
                            </div>
                            <div className="modal-body">
                                <div className="container-fluid">
                                    <div className="row">
                                        {insertUpdateLoading && <Loader />}
                                        <div className="col-md-12 p-0">
                                            <div className="ibox">
                                                <div className="ibox-title">
                                                    <span>{openModal} Dimension</span>
                                                </div>
                                                <div className="ibox-content">
                                                    <form id="frm_BoxDimension" className="needs-validation" noValidate="noValidate" onSubmit={e => formOnSubmitBoxDimension(e)}>
                                                        <div className="form-row">
                                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                                <label className="frm-label">Name<span className="text-danger">*</span></label>
                                                                <input type="text" name="name" defaultValue={BoxDimensionData && (BoxDimensionData.name)} className="form-control ffPoppins" onChange={(e) => { TextChangeHandler(e) }} placeholder="Enter Name" required />
                                                            </div>
                                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                                <label className="frm-label">Length<span className="text-danger">*</span></label>
                                                                <input type="number" name="length" defaultValue={BoxDimensionData && BoxDimensionData.length} className="form-control ffPoppins" onChange={(e) => { TextChangeHandler(e) }} placeholder="Enter Length" required />
                                                            </div>
                                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                                <label className="frm-label">Width<span className="text-danger">*</span></label>
                                                                <input type="number" name="width" defaultValue={BoxDimensionData && BoxDimensionData.width} className="form-control ffPoppins" onChange={(e) => { TextChangeHandler(e) }} placeholder="Enter Width" required />
                                                            </div>
                                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                                <label className="frm-label">Height<span className="text-danger">*</span></label>
                                                                <input type="number" name="height" defaultValue={BoxDimensionData && BoxDimensionData.height} className="form-control ffPoppins" onChange={(e) => { TextChangeHandler(e) }} placeholder="Enter Height" required />
                                                            </div>
                                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                                <label className="frm-label">Dimensional Weight<span className="text-danger">*</span></label>
                                                                <input type="number" name="dimensionalWeight" defaultValue={BoxDimensionData && BoxDimensionData.dimensionalWeight} className="form-control ffPoppins" placeholder="Enter Dimensional Weight" required readOnly />
                                                            </div>
                                                            <div className="form-group col-lg-4 col-md-4 col-sm-6 col-xs-12">
                                                                <label className="frm-label">Cubic Feet<span className="text-danger">*</span></label>
                                                                <input type="number" name="cubicFeet" defaultValue={BoxDimensionData && BoxDimensionData.cubicFeet} className="form-control ffPoppins" placeholder="Enter Cubic Feet" required readOnly />
                                                            </div>

                                                        </div>
                                                        <div className="mt-2 mb-2">
                                                            <button className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                                            <button className="btn btn-danger btn-text bg-resetRed" data-dismiss="modal" onClick={() => { setBoxDimensionData([]) }}>Cancel</button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        )
}
