import React, { useState, useEffect } from 'react';
import { useQuery } from '@apollo/client';
import Select from 'react-select';
import _ from 'lodash';
import { GET_ALL_CATEGORY } from '../../../graphql/query/CategoryQuery';

const Categories = ({ categoryId, isdisabled, getCategoryId }) => {
  const [categoryList, setCategoryList] = useState([]);
  const [allCategoryDetails, setAllCategoryDetails] = useState([]);
  const [value, setValue] = useState(null);
  const [colorError, setColorError] = useState(undefined);

  const { loading, data } = useQuery(GET_ALL_CATEGORY, {
    variables: {},
    fetchPolicy: 'cache-and-network',
  });

  useEffect(() => {
    if (!loading && data && data.getAllCategories) {
      const category = data?.getAllCategories;
      let getCategoryList = []
        category.forEach(d => {
          getCategoryList.push({ value: d.id, label: d.name })
        });
      // const getCategoryList = category.map(d => ({ value: d.id, label: d.name }));
      setCategoryList(getCategoryList);
      setAllCategoryDetails(category);
    } else {
      setCategoryList([]);
    }
  }, [loading, data]);

  useEffect(() => {
    if(categoryList?.length > 0){
      setDefaultCategory();
    }
  },[categoryList,categoryId])

  const categoryHandleChange = (value) => {
    setColorError(true);
    setValue(value);
    getCategoryId(value.value);
  };

  const setDefaultCategory = () => {
    if (categoryId) {
      const categoryIdData = _.find(categoryList, (d) => d.value === categoryId);
      setValue(categoryIdData);
    } else {
      setValue(null);
    }
  };

  return (
    <Select
      className="basic-single ffPoppins"
      options={categoryList}
      isDisabled={isdisabled}
      value={value}
      onChange={(value) => categoryHandleChange(value)}
      styles={{
        control: (provided, state) =>
          !colorError && colorError !== undefined
            ? {
              ...provided,
              boxShadow: "0 0 0 1px #dc3545 !important",
              border: "1px #dc3545 !important"
            }
            : provided
      }}
    />
  );
};

export default Categories;
