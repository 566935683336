import React from 'react'
import Loader from '../../../../components/Loader/Loader'
import { useMutation } from '@apollo/client'
import { INSERT_UPDATE_WAREHOUSE_LOCATION } from '../../../../../graphql/mutation/WareHouseMutation'
import { statusType } from '../../../../components/Constant'
import { toast } from 'react-toastify';
import * as $ from 'jquery';
import Select from 'react-select';

export const InsertUpdateLocation = ({ warehouseLocation, setwarehouseLocation, refetch }) => {
    const [insertUpdateWareHouseLocation, { loading }] = useMutation(INSERT_UPDATE_WAREHOUSE_LOCATION);

    const handleChangeStatus = (userStatus) => {
        setwarehouseLocation((prevData) => ({
            ...prevData, WHLocationData: {
                ...warehouseLocation?.WHLocationData,
                isActive: userStatus.value,
            },
            temp_isActive: userStatus
        }));
    }
    const TextChangeHandler = (e) => {
        setwarehouseLocation((prevData) => ({
            ...prevData, WHLocationData: {
                ...warehouseLocation?.WHLocationData,
                [e.target.name]: e.target.value
            }
        }));
    }
    const onSubmitWHLocation = (event) => {
        event.preventDefault();
        const form = event.currentTarget;
        if (!form.checkValidity()) {
            event.stopPropagation();
            form.classList.add('was-validated');
            toast.error('Please fill the required fields...', {
                autoClose: 2000
            })
            return false;
        } else {
            const { warehouseId, WHLocationData, openModal, Old_Data, id } = warehouseLocation;
            let input = {};
            let old_value = {};
            if (openModal === "Edit") {
                for (var obj in Old_Data) {
                    if (Old_Data[obj] !== WHLocationData[obj]) {
                        old_value[obj] = Old_Data[obj]
                        input[obj] = WHLocationData[obj]
                    }
                }
                input.id = id;
                input.warehouseId = WHLocationData?.warehouseId;
            } else {
                input = { ...WHLocationData };
                input.warehouseId = warehouseId;
                input.isActive = warehouseLocation?.temp_isActive.value
            }
            let log = {
                action: openModal === "Edit" ? 'UPDATE' : 'INSERT',
                actionOn: 'warehouselocations',
                actionName: 'insertUpdateWareHouseLocation',
                oldValue: openModal === "Edit" ? JSON.stringify(old_value) : ""
            }
            insertUpdateWareHouseLocation({ variables: { "input": input, "log": log } }).then(async ({ data }) => {
                form.classList.remove('was-validated');
                form.reset();
                refetch();
                setwarehouseLocation((prevData) => ({
                    ...prevData,WHLocationData: {}
                }));
                $('#WarehouseLocationModal').modal('hide');
                if (openModal === "Edit") {
                    toast.success("Warehouse Location updated successfully!", {
                        autoClose: 2000
                    })
                } else {
                    toast.success("Warehouse Location added successfully!", {
                        autoClose: 2000
                    })
                }
            }).catch((err) => {
                toast.error(err.message, {
                    autoClose: 2000
                })
            })
            return true;
        }
    }

    const { WHLocationData } = warehouseLocation;
    return (
        <div className="container-fluid">
            <div className="row">
                <div className="mb-20">
                    <p className="mainTitle m-0 mt_-15">{warehouseLocation?.openModal} Destination Warehouses</p>
                    <span className="borderBottomOfTitle"></span>
                </div>
            </div>
            <div className="row">
                {loading && <Loader />}
                <div className="col-md-12 p-0">
                    <div className="ibox">
                        <div className="ibox-title">
                            <span>{warehouseLocation?.openModal} Destination Warehouses</span>
                        </div>
                        <div className="ibox-content">
                            <form id="frm_wh_location" className="needs-validation" noValidate="noValidate" onSubmit={e => onSubmitWHLocation(e)}>
                                <div className="form-row">
                                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label className="frm-label">BOX RAC No<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control ffPoppins" name="boxRacNo" value={WHLocationData.boxRacNo} onChange={e => TextChangeHandler(e)} required />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">Height<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control ffPoppins" name="height" value={WHLocationData.height} onChange={e => TextChangeHandler(e)} required />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-12 col-xs-12 ">
                                        <label className="frm-label">Width<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control ffPoppins" name="width" value={WHLocationData.width} onChange={e => TextChangeHandler(e)} required />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label className="frm-label">Length<span className="text-danger">*</span></label>
                                        <input type="text" className="form-control ffPoppins" name="length" value={WHLocationData.length} onChange={e => TextChangeHandler(e)} required />
                                    </div>
                                    <div className="form-group col-lg-6 col-md-6 col-sm-6 col-xs-12">
                                        <label className="frm-label">Status<span className="text-danger">*</span></label>
                                        <Select
                                            className="ffPoppins"
                                            options={statusType}
                                            value={warehouseLocation?.temp_isActive}
                                            onChange={(data) => handleChangeStatus(data)}
                                        />
                                    </div>
                                </div>
                                <div className="mt-2 mb-2">
                                    <button type="submit" className="btn btn-primary btn-text mr-2 border-0 bg-searchBlue" >Save</button>
                                    <button type="reset" className="btn btn-danger btn-text bg-resetRed" data-dismiss="modal">Cancel</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
